<template>
  <header role="banner" id="cve-primary-header">
    <nav id="cve-main-nav" class="navbar is-transparent is-fixed-top"  role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a href="/" class="navbar-item">
          <img src="@/assets/cveLogoR.svg" alt="CVE logo" class="cve-logo">
        </a>
        <div v-if="true" class="mr-2 has-text-weight-bold cve-non-prod-label">
          <span class="icon-text">
            <span class="icon is-small">
              <font-awesome-icon  icon="exclamation-triangle" role="alert"
                aria-labelledby="alertIconTest" aria-hidden="false" />
            </span>
            <span id="alertIconTest" style="font-size: 1.5rem !important">{{this.$store.state.apiInstance}}</span>
          </span>
        </div>
        <button ref="burger" role="button" class="navbar-burger button is-text cve-button-submenu-toggle" aria-label="menu" aria-expanded="false"
          @click="toggleBurgerMenu" v-bind:class="{'is-active': isOpen}">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="navbar-menu" v-bind:class="{'is-active': isOpen}">
        <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable cve-navbar-item" v-for="(menuObj, menuItemLabel) in cvenavs" :key="menuObj.id">
            <a v-if="menuObj.hasOwnProperty('primaryNavPath') && menuItemLabel !==  'Report/Request'" :href="'' + $sanitize(menuObj.primaryNavPath)"
              class="navbar-link is-arrowless" :class="menuObj.hasOwnProperty('submenu') ? 'is-hidden-touch' : ''"
              aria-haspopup="true">{{menuItemLabel}}</a>
            <span class="is-flex is-hidden-desktop" style="justify-content: space-between; width: inherit"
              v-if="menuItemLabel !==  'Report/Request' && menuObj.hasOwnProperty('primaryNavPath') && menuObj.hasOwnProperty('submenu')">
              <a :href="'' + $sanitize(menuObj.primaryNavPath)" class="navbar-link is-arrowless">{{menuItemLabel}}</a>
              <button v-if="menuItemLabel != 'Downloads'" class="button is-text cve-button-submenu-toggle" @click="toggleSubmenu(menuItemLabel)">
                <span class="icon is-small">
                  <font-awesome-icon class="icon cve-icon-xxs" :icon="selectedMenu.includes(menuItemLabel) ? 'minus' : 'plus'"/>
                </span>
              </button>
            </span>
            <div class="navbar-dropdown" v-bind:class="{'is-hidden-touch': !selectedMenu.includes(menuItemLabel) }"
              v-if="menuItemLabel !==  'Report/Request' && menuItemLabel != 'Downloads' && menuObj.hasOwnProperty('submenu')">
              <router-link :to="`/${$sanitize(menuObj.path)}/${$sanitize(submenuObj.path)}`" v-slot="{ href, isActive }"
                v-for="(submenuObj, submenuLabel) in menuObj.submenu"  :key="submenuObj.id">
                <a class="navbar-item cve-submenu-item" :href="$sanitize(href)" v-bind:class="{'is-active': isActive}">{{submenuLabel}}</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="navbar-end" id="cve-navbar-end">
          <div class="navbar-item has-dropdown is-hoverable cve-navbar-item" v-for="(menuObj, menuItemLabel) in cvenavs['Right Menu']"
            :key="menuObj.id">
            <a v-if="menuObj.hasOwnProperty('primaryNavPath')" :href="'' + $sanitize(menuObj.primaryNavPath)" class="navbar-link is-arrowless
              cve-navbar-link cve-right-submenu-item" :class="menuObj.hasOwnProperty('submenu') ? 'is-hidden-touch' : ''"
              aria-haspopup="true">{{menuItemLabel}}</a>
            <span class="is-flex is-hidden-desktop" style="justify-content: space-between; width: inherit"
              v-if="menuObj.hasOwnProperty('primaryNavPath') && menuObj.hasOwnProperty('submenu')">
              <a :href="'' + $sanitize(menuObj.primaryNavPath)" class="navbar-link is-arrowless">{{menuItemLabel}}</a>
              <button v-if="menuItemLabel != 'Downloads'" class="button is-text cve-button-submenu-toggle" @click="toggleSubmenu(menuItemLabel)">
                <span class="icon is-small">
                  <font-awesome-icon class="icon cve-icon-xxs" :icon="selectedMenu.includes(menuItemLabel) ? 'minus' : 'plus'"/>
                </span>
              </button>
            </span>
            <div class="navbar-dropdown is-right" v-bind:class="{'is-hidden-touch': !selectedMenu.includes(menuItemLabel) }"
              v-if="menuItemLabel != 'Downloads' && menuObj.hasOwnProperty('submenu')">
              <router-link :to="`/${$sanitize(menuObj.path)}/${$sanitize(submenuObj.path)}`" v-slot="{ href, isActive }"
                v-for="(submenuObj, submenuLabel) in menuObj.submenu"  :key="submenuObj.id">
                <a class="navbar-item cve-submenu-item cve-right-submenu-item" :href="$sanitize(href)" v-bind:class="{'is-active': isActive}">
                  {{submenuLabel}}
                </a>
              </router-link>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable cve-navbar-item is-hidden-desktop">
            <a href="/SiteSearch" class="navbar-link is-arrowless">
              <span>Site Search</span>
              <span class="icon">
                <font-awesome-icon icon="search" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>

    <nav id="cve-secondary-navbar" class="navbar" aria-label="navigation">
      <div class="navbar-menu cve-secondary-navbar-menu is-active">
        <div class="navbar-start" style="flex-grow: 1 !important; align-items: center !important; justify-content: center !important;
          flex-direction: column !important">
          <div class="navbar-item cve-secondary-navbar-item">
            <cveRecordLookup/>
          </div>
          <div class="navbar-item cve-secondary-navbar-item cve-keyword-search">
            <span class="icon-text mr-2" style="font-size: 14px">
              <router-link to="/ResourcesSupport/FAQs#pc_cve_list_basicssearch_cve" class="cve-dark-blue-text">
                Find CVE Records by keyword.
              </router-link>
            </span>
          </div>
        </div>
        <div class="navbar-end is-hidden-touch">
          <div class="navbar-item cve-secondary-navbar-item">
            <div class="buttons">
              <router-link to="/SiteSearch" class="button cve-button cve-button-outline">
                <span>Site Search</span>
                <span class="icon">
                  <font-awesome-icon icon="search" />
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import cveRecordLookup from './cveRecordLookupModule.vue';

export default {
  name: 'PrimaryNavigation',
  components: { cveRecordLookup },
  props: {
    cvenavs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedMenu: [],
    };
  },

  computed: {
    apiInstance() {
      return this.$store.getters.getApiInstance;
    }
  },

  methods: {
    toggleSubmenu(menuOption) {
      if (this.selectedMenu.includes(menuOption)) {
        this.selectedMenu = this.selectedMenu.filter(
          (item) => item !== menuOption,
        );
      } else {
        this.selectedMenu.push(menuOption);
      }
    },
    toggleBurgerMenu() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/style/globals.scss';
/* Components - Navbar customization starts here */
#cve-main-nav {
  background-image: linear-gradient(to right, $cve-color-dark-purple, $theme-color-primary-darker);
}

.cve-non-prod-label {
  color: yellow;
}

.navbar-brand {
  align-items: center !important;
  justify-content: center !important;
}

.navbar-item {
  padding: 0rem 0.5rem;
}

.cve-logo {
  min-height: 40px;
  min-width: 100px;
}

.navbar#cve-secondary-navbar {
  z-index: 2; // puts secondary behind primary navbar dropdown
  background-color: $theme-color-accent-cool-light;
  border-bottom: 1px solid $theme-color-primary-darker;
}

.navbar-item {
  text-align: left;
}

.cve-is-arrowless::after{
  content: none !important;
}

@media screen and (min-width: $desktop){
  div.navbar-dropdown > a.navbar-item:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: $desktop){
  div.navbar-item.has-dropdown.is-hoverable.cve-navbar-item:hover, a.navbar-item.cve-navbar-item:hover {
    border:1px solid white;
  }

  .navbar-item {
    border: 1px solid transparent;
  }
}

@media screen and (max-width: $desktop){
  a.navbar-item.is-active {
    color: $theme-color-accent-cool-light;
  }

  a.cve-submenu-item {
    margin-bottom: 2rem;
  }

  .cve-secondary-navbar-menu {
    background-color: $theme-color-accent-cool-light;
  }

  .cve-keyword-search {
    font-size: 14px;
    text-align: center;
  }
}

.navbar-burger:hover {
  color: white;
}
/* Components customization ends here */

/* Elements - Button customization starts here */
.button.is-info, .button.is-info:hover {
    background-color: $theme-color-accent-warm;
    border: 1px solid black;
    color: $theme-color-primary-darker;
}

.cve-button-submenu-toggle {
  background: unset !important;
  color: $white !important;
}
.cve-button-submenu-toggle:hover {
  color: $theme-color-accent-warm !important;
}

.cve-button-submenu-toggle:active {
  color: $theme-color-accent-warm !important;
}
/* Elements customization ends here */
</style>
