<template>
  <div>
    <label>
      <input type="checkbox" v-model="omitSSVC" @change="updateCookie">
      Always omit SSVC?
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      omitSSVC: false // Initialize based on the presence of the cookie
    };
  },
  mounted() {
    this.checkCookie(); // Check the cookie status when the component is mounted
  },
  methods: {
    checkCookie() {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('omitSSVC='))
        ?.split('=')[1];
      this.omitSSVC = cookieValue === 'true';
    },
    updateCookie() {
      if (this.omitSSVC) {
        document.cookie = "omitSSVC=true; path=/; max-age=31536000"; // Set the cookie for 1 year
      } else {
        document.cookie = "omitSSVC=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // Remove the cookie
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
label {
  font-size: 24px;
}
</style>
