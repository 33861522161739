import Vue from 'vue';
import Vuex from 'vuex';

import NotificationData from '@/assets/data/NotificationBanner.json';
import PartnerData from '@/assets/data/CNAsList.json';

Vue.use(Vuex);

const populatePartnerCounts = () => {
  const partnerCounts = {
    type: {}, role: {}, totalPartners: 0, countries: {}, countriesCount: 0,
  };

  PartnerData.forEach((partner) => {
    if (partner.CNA.roles.length > 0) {
      partner.CNA.roles.forEach((roleObj) => {
        if (roleObj.role.toLowerCase() !== 'secretariat') {
          if (Object.prototype.hasOwnProperty.call(partnerCounts.role, roleObj.role)) {
            partnerCounts.role[roleObj.role] += 1;
          } else {
            partnerCounts.role[roleObj.role] = 1;
          }
        }
      });
    }

    if (partner.CNA.type.length > 0) {
      partner.CNA.type.forEach((type) => {
        let newType = '';
        if (new RegExp(/^(Vulnerability Researcher)/, 'i').test(type)) { newType = 'Vulnerability Researcher(s)'; } else { newType = type; }

        if (newType.toLowerCase() !== 'n/a') {
          if (Object.prototype.hasOwnProperty.call(partnerCounts.type, newType)) {
            partnerCounts.type[newType] += 1;
          } else {
            partnerCounts.type[newType] = 1;
          }
        }
      });
    }

    if (Object.prototype.hasOwnProperty.call(partnerCounts.countries, partner.country) && partner.country !== 'n/a') {
      partnerCounts.countries[partner.country] += 1;
    } else {
      let country = partner.country == 'n/a' ? 'No country affiliation' : partner.country;
      partnerCounts.countries[country] = 1;
    }
  });

  partnerCounts.totalPartners = PartnerData.length;
  Object.keys(partnerCounts.countries).forEach((country)=>{
    if (country !== 'No country affiliation') partnerCounts.countriesCount += 1;
  })

  return partnerCounts;
};

export default new Vuex.Store({
  state: {
    apiInstance: 'cveawg.mitre.org',
    API_BASE: process.env.VUE_APP_API_BASE_URL,
    CVE_SERVICES_API_BASE: process.env.VUE_APP_CVE_SERVICES_BASE_URL,
    WEBSITE_ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT,
    collapsed: NotificationData.collapsed,
    publishCount: NotificationData.publishCount,
    cveId: '',
    isArecord: undefined,
    idData: {},
    recordData: {},
    isSearching: false,
    isIdOrRecordFound: true,
    isPublished: false,
    isReserved: false,
    isRejected: false,
    serverError: false,
    showHelpText: false,
    showJsonRecord: false,
    partnerCounts: {},
  },
  mutations: {
    setApiInstance(state, payload) {
      state.apiInstance = payload;
    }
  },
  actions: {
    updateApiInstance({ commit }, payload) {
      commit('setApiInstance', payload);
      // Set a cookie
      document.cookie = `apiInstance=${payload}; path=/;`;
    },
    initializeStore({ commit }) {
      // Check for the cookie and initialize the state
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('apiInstance='))
        ?.split('=')[1];
      if (cookieValue) {
        commit('setApiInstance', cookieValue);
      }
    }
  },
  getters: {
    getApiInstance: (state) => state.apiInstance
  },
  mutations: {
     setApiInstance(state, payload) {
	 state.apiInstance = payload;
     },
     initializeStore(state) {
      if (localStorage.getItem('collapsed') === true || localStorage.getItem('collapsed') === 'true') {
        state.collapsed = true;
      } else {
        state.collapsed = false;
      }

      state.partnerCounts = populatePartnerCounts();
    },
    showNotificationBanner() {
      if (localStorage.getItem('publishCount') !== NotificationData.publishCount.toString()) {
        localStorage.setItem('collapsed', false);
        if (localStorage.getItem('publishCount') > NotificationData.publishCount) {
          // Catch edge case where the publishCount in localstorage is higher than what we send
          localStorage.removeItem('publishCount');
        }
      }
    },
    toggleNotificationBanner(state) {
      // Reveal notification
      if (localStorage.getItem('collapsed') === 'true') {
        localStorage.setItem('collapsed', false);
        state.collapsed = false;
        localStorage.setItem('publishCount', NotificationData.publishCount);
      } else {
        localStorage.setItem('collapsed', true);
        state.collapsed = true;
        localStorage.setItem('publishCount', NotificationData.publishCount);
      }
    },
    updateState(state, changed) {
      Object.entries(changed)
        .forEach(([name, value]) => {
          state[name] = value;
          localStorage.setItem(name, (typeof value === 'string') ? value : JSON.stringify(value));
        });
    },
  },
});
