<template>
  <div class="content">
    <nav class="level mb-0">
    <div class="level-left">
        <div class="level-item">
          <div class="columns is-multiline">
             <div class="column is-full">
                 <label for="api-select">Choose an API instance:</label>
                 <select id="api-select" v-model="apiInstance" @change="updateApi">
                   <option value="">Select an API instance</option>
                   <option value="cveawg.mitre.org">cveawg.mitre.org</option>
                   <option value="cveawg-test.mitre.org">cveawg-test.mitre.org</option>
                   <option value="cveawg-adp-test.mitre.org">cveawg-adp-test.mitre.org</option>
                   <option value="cveawg-prod-staging.mitre.org">cveawg-prod-staging.mitre.org</option>
                   <option value="cveawg-dev.mitre.org">cveawg-dev.mitre.org</option>
                   <option value="cveawg-int.mitre.org">cveawg-int.mitre.org</option>
                   <option value="a.vulnerability.info">a.vulnerability.info</option>		   
                </select>
            </div>
      	    <div class="column is-full">
               <ToggleOmitSSVC />
            </div>

      	    <div class="column is-full">
              <div class="level-left">
                  <div class="level-item">
                    <h1 class="title">{{this.$store.state.cveId}}</h1>
                  </div>
                  <div v-if="cveFieldList.state.length > 0" class="level-item">
                    <span class="tag is-info is-light has-text-weight-bold cve-state-tag">{{cveFieldList.state}}</span>
                  </div>
              </div>	
            </div>
          </div>
        </div>
      </div>
      <div class="level-right ml-1">
        <div class="level-item">
          <a id="cve-view-json" :href="`https://${this.$store.state.apiInstance}/api/cve/${this.$store.state.cveId}`" target="_blank">
            View JSON
          </a>
        </div>
      </div>
    </nav>
    <div class="content"><span v-if="cveFieldList.title.length > 0" class="has-text-grey-dark is-size-6">{{cveFieldList.title}}</span></div>
    <div role="information" class="notification is-info is-light p-3">
      <div class="is-flex" style="justify-content: center;">
          <section class="cve-accordian" style="flex: 0 0 100%;">
              <div class="message" style="background: transparent !important;">
                <div class="message-header p-0"
                  style="background: transparent !important;border-bottom: unset !important">
                  <span class="icon-text">
                    <span class="icon">
                      <p id="infoIconVariousFormts" class="is-hidden">information</p>
                      <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="alert"
                        aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                    </span>
                    <span class="mr-1">Important CVE JSON 5 Information</span>
                  </span>
                  <button class="button message-header-button" style="background: transparent !important;"
                    @click="isMessageExpanded = !isMessageExpanded">
                    <span class="icon is-small">
                      <p id="expandCollapseAltText" class="is-hidden">
                        {{isMessageExpanded ? 'expand': 'collapse'}}
                      </p>
                      <font-awesome-icon :icon="isMessageExpanded ? 'minus' : 'plus'"
                        aria-hidden="false" focusable="true" aria-labelledby="expandCollapseAltText"/>
                    </span>
                  </button>
                </div>
              </div>
          </section>
      </div>
      <div v-if="isMessageExpanded" class="p-4 has-text-justified">
        <p>
          <router-link to='/ResourcesSupport/Glossary?activeTerm=glossaryRecord'>CVE Records</router-link>
          on this CVE.ORG website are displayed in
          <router-link to='/AllResources/CveServices#cve-json-5'>CVE JSON 5.0</router-link>.
          Downloads in this format are available on the <router-link to='/Downloads'>Downloads</router-link>
          page. Learn more about
          <router-link to='/AllResources/CveServices#cve-json-5'>CVE JSON 5.0 here</router-link>.
        </p>
      </div>
    </div>
    <div id="cve-cna-container" class="content">
      <p v-if="cveFieldList.assigner.length > 0" class="mb-0">
        <span class="has-text-weight-bold">Assigner: </span>
        <span style="text-transform: capitalize;">{{cveFieldList.assigner}}</span>
      </p>
      <div id="cve-record-dates-and-tags-container" class="has-text-grey-dark has-text-left">

<div class="info-lines">
  <div v-if="cveFieldList.datePublishedCveMetadata.length > 0">
    <span class="has-text-weight-semibold">Published: </span>
    <time>{{cveFieldList.datePublishedCveMetadata}}</time>
  </div>
  <div v-if="cveFieldList.dateUpdatedCveMetadata.length > 0">
    <span class="has-text-weight-semibold">Updated: </span>
    <time>{{cveFieldList.dateUpdatedCveMetadata}}</time>
  </div>
   <div v-if="cveFieldList.ref_zero.length > 0">
     <span class="has-text-weight-semibold">URL FROM ANOTHER CNA: </span>
     <span v-html="cveFieldList.ref_zero"></span>
   </div>
  <div v-if="shouldRenderOptF">
    <span class="cisa-ssvc">CISA ADP SSVC: </span>
    <span class="cisa-ssvc">{{cveFieldList.opt_f}}</span>
  </div>

  <div v-if="cveFieldList.b64_cpeas.length > 0">
    <span class="has-text-weight-semibold">CNA CPE APPLICABILITY STATEMENT:</span>
    <pre>{{cveFieldList.b64_cpeas}}</pre>
  </div>

  <div v-if="cveFieldList.gen_cpeas.length > 0">
    <span class="has-text-weight-semibold">GENERATED CPE APPLICABILITY STATEMENT:</span>
    <pre>{{cveFieldList.gen_cpeas}}</pre>
  </div>


  <div v-if="cveFieldList.nvd_cpeas.length > 0">
    <span class="has-text-weight-semibold">NVD CPE APPLICABILITY STATEMENT:</span>
    <pre>{{cveFieldList.nvd_cpeas}}</pre>
  </div>

   <div v-if="cveFieldList.vcvss_cna.length > 0">
     <span class="has-text-weight-semibold">CNA CVSS: </span>
     <span>{{cveFieldList.acvss_cna}}</span>
   </div>
   <div v-if="cveFieldList.cwe_cna.length > 0">
     <span class="has-text-weight-semibold">CNA CWE: </span>
     <span>{{cveFieldList.cwe_cna}}</span>
   </div>
   <div v-if="cveFieldList.aff_cpe_cna.length > 0">
     <span class="has-text-weight-semibold">CNA CPE: </span>
     <span>{{cveFieldList.aff_cpe_cna}}</span>
   </div>

  <div v-if="cveFieldList.nvd_vcvss.length > 0">
    <span class="has-text-weight-semibold">NVD CVSS: </span>
    <span>{{cveFieldList.nvd_acvss}}</span>
  </div>
  <div v-if="cveFieldList.nvd_cwe.length > 0">
    <span class="has-text-weight-semibold">NVD CWE: </span>
    <span>{{cveFieldList.nvd_cwe}}</span>
  </div>
  <div v-if="cveFieldList.nvd_aff_cpe.length > 0">
    <span class="has-text-weight-semibold">NVD CPE: </span>
    <span>{{cveFieldList.nvd_aff_cpe}}</span>
  </div>

  <div v-if="cveFieldList.vcvss.length > 0">
    <span class="has-text-weight-semibold">CISA ADP CVSS: </span>
    <span>{{cveFieldList.acvss}}</span>
  </div>
  <div v-if="cveFieldList.cwe.length > 0">
    <span class="has-text-weight-semibold">CISA ADP CWE: </span>
    <span>{{cveFieldList.cwe}}</span>
  </div>
  <div v-if="cveFieldList.aff_cpe.length > 0">
    <span class="has-text-weight-semibold">CISA ADP CPE(s): </span>
    <span>{{cveFieldList.aff_cpe}}</span>
  </div>
  <div v-if="cveFieldList.aff_all.length > 0">
    <span class="has-text-weight-semibold">CISA ADP AFFECTED: </span>
    <span>{{cveFieldList.aff_all}}</span>
  </div>


   <div v-if="cveFieldList.cisaref.length > 0">
     <span class="has-text-weight-semibold">CISA ADP Reference(s): </span>
     <span>{{cveFieldList.cisaref}}</span>
   </div>
   <div v-if="cveFieldList.mitreref.length > 0">
     <span class="has-text-weight-semibold">MITRE ADP Reference(s): </span>
     <span>{{cveFieldList.mitreref}}</span>
   </div>

</div>



        <span id="cve-tags" v-if="cveFieldList.tags.length > 0">
          <span class="mr-2">|</span>
          <span class="tag is-info is-light cve-tag" v-for="tag in cveFieldList.tags" :key="tag">{{tag}}</span>
        </span>
      </div>
    </div>
    <div id="cve-desciption" class="content has-text-justified">
      <p v-for="description in cveFieldList.descriptions" :key="description" style="white-space: break-spaces">{{description}}</p>
    </div>
    <div id="cve-product-status-container" class="content">
      <h2 class="title is-size-5">Product Status</h2>
      <div role="information" class="notification is-info is-light p-3">
        <div class="is-flex" style="justify-content: center;">
            <section class="cve-accordian" style="flex: 0 0 100%;">
                <div class="message" style="background: transparent !important;">
                  <div class="message-header p-0"
                    style="background: transparent !important;border-bottom: unset !important">
                    <span class="icon-text">
                      <span class="icon">
                        <p id="infoIconVariousFormts" class="is-hidden">information</p>
                        <font-awesome-icon style="flex: 0 0 40px;" size="1x"  icon="info-circle" role="alert"
                          aria-labelledby="alertIconVariousFormts" aria-hidden="false" />
                      </span>
                      <p class="mr-1">Learn About the Versions Section</p>
                    </span>
                    <button class="button message-header-button" style="background: transparent !important;"
                      @click="isHelpTestShown = !isHelpTestShown">
                        <span class="icon is-small">
                          <p id="expandCollapseVersionsAltText" class="is-hidden">
                            {{isHelpTestShown ? 'expand': 'collapse'}}
                          </p>
                          <font-awesome-icon :icon="isHelpTestShown ? 'minus' : 'plus'"
                            aria-hidden="false" focusable="true" aria-labelledby="expandCollapseVersionsAltText"/>
                        </span>
                    </button>
                  </div>
                </div>
            </section>
        </div>
        <div v-if="isHelpTestShown" class="p-4 has-text-justified">
          <p>
            The <span class="has-text-weight-bold">Versions</span> section may contain a <span class="has-text-weight-bold">Default Status</span>
            field and one or more of these version status categories:
          </p>
          <div class="columns">
              <div v-for="status in ['Affected', 'Unaffected', 'Unknown']" :key="status.key"
                class="column cve-top-margin cve-versions-column">
                <div class="menu has-text-centered" style="border: unset !important">
                  <span class="has-text-weight-bold">{{status}}</span> Versions List
                </div>
              </div>
            </div>
          <p>
            Each category contains a list with the following format:
            <ul>
              <li>
                Top-level
                <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#versions-and-version-ranges"
                target="_blank">version/version range</a> is marked with a solid circle bullet.
                <ul>
                  <li>
                    <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-changes"
                    target="_blank">Version changes</a> are marked with an outlined circle bullet.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p><span class="cve-version">Purple text</span> denotes the version provided by the user inputting the CVE JSON 5.0 data.</p>
         <!-- <h4 class="title">Version Details</h4> -->
          <p>
            In the following section you will find the descriptions, format information and examples for the following fields: default status,
            top-level version/version range and version changes.
          </p>
          <p class="is-size-5">Default Status</p>
          <p>
            This status applies to all versions not explicitly mentioned elsewhere.
            <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-decisions" target="_blank">
            Learn more.</a>
          </p>
          <p class="is-size-5">Top-level Version/Version Range</p>
          <p>The single version being described, or the version range. By convention, 0 typically denotes the earliest possible version.</p>
          <p class="is-size-6 has-text-weight-bold">Formats:</p>
          <ul>
            <li>
              &lt;status: affected, unaffected, unknown&gt; at <span class="cve-version">&lt;version&gt;</span>
            </li>
            <li>
              &lt;status: affected, unaffected, unknown&gt; from <span class="cve-version">&lt;start version&gt;</span> &lt;at, before, through&gt;
              <span class="cve-version">&lt;end version&gt;</span>
            </li>
          </ul>
          <p class="is-size-6 has-text-weight-bold">Examples:</p>
          <ul>
            <li>
              <span class="has-text-weight-bold">unknown at <span class="cve-version">5.0</span></span>
              <span class="is-italic"> (unknown version is 5.0)</span>
            </li>
            <li>
              <span class="has-text-weight-bold">
                affected from <span class="cve-version">5.0</span> before <span class="cve-version">6.0</span></span>
              <span class="is-italic"> (affected version doesn’t include 6.0)</span>
            </li>
            <li>
              <span class="has-text-weight-bold">
                unaffected from <span class="cve-version">5.0</span> through <span class="cve-version">6.0</span>
              </span>
              <span class="is-italic"> (unaffected version range includes 5.0 and 6.0)</span>
            </li>
          </ul>
          <p class="is-size-5">Version changes</p>
          <p>
            A list of status changes that take place during a range. The list should be sorted in (linearly or topologically) increasing order by the
            'at' field, according to the <span class="is-italic">versionType</span>, but the user must not assume this. Instead, users must re-sort
            the list themselves before using it.
          </p>
          <p class="is-size-6 has-text-weight-bold">Format:</p>
          <p>&lt;status: affected, unaffected, unknown&gt; from <span class="cve-version">&lt;version&gt;</span>
          <p class="is-size-6 has-text-weight-bold">Examples:</p>
          <ul>
            <li>
              <span class="has-text-weight-bold">affected from <span class="cve-version">1.0</span> before <span class="cve-version">4.0</span></span>
              <span class="is-italic"> (affected version doesn't include 4.0)</span>
              <ul>
                <li>
                  <span class="has-text-weight-bold">unaffected from <span class="cve-version">1.0</span></span>
                  <span class="is-italic"> (version affected changed and it is unaffected from 1.0 to 2.0 — the next range right below)</span>
                </li>
                <li>
                  <span class="has-text-weight-bold">affected from <span class="cve-version">2.0</span></span>
                  <span class="is-italic"> (version remain affected from 2.0 to before 4.0, not including 4.0)</span>
                </li>
              </ul>
            </li>
            <li>
              For another linear version example view the
              <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-changes" target="_blank">
                Version Status Changes
              </a> section
            </li>
            <li>
              For a non-linear version example view the
              <a href="https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/docs/versions.md#version-status-changes" target="_blank">
                Source Control Versions
              </a> section
            </li>
          </ul>
        </div>
      </div>
      <div v-if="cveFieldList.showProductStatus"><p class="is-italic cve-help-text">Information not provided</p></div>
      <div v-else>
        <div class="columns mb-4" v-for="(product, index) in cveFieldList.productsStatus" :key="`${index}-${product.vendor}`">
          <div  id="cve-vendor-product-platforms" class="column cve-row cve-light-gray-bg"
            :class="(Object.keys(product.versionsColumns.twoDTable).length > 0) ? 'is-3' : ''">
            <p class="has-text-weight-bold mb-1">Vendor</p>
            <span id="cve-vendor" v-if="product.vendor.length > 0">{{product.vendor}}</span>
            <p class="has-text-weight-bold mb-1 pt-3">Product</p>
            <span id="cve-product" v-if="product.product.length > 0">{{product.product}}</span>
            <div id="cve-platforms" class="pt-3" v-if="product.platforms.length > 0">
              <p class="has-text-weight-bold mb-1">Platforms</p>
              <p>{{product.platforms.join(', ')}}</p>
            </div>
          </div>
          <div class="column cve-left-margin cve-top-margin cve-light-gray-bg">
            <p class="has-text-weight-bold mb-1">Versions</p>
            <p v-if="product.defaultStatus" class="cve-help-text is-italic">
              <span class="has-text-weight-bold">Default Status: </span>{{product.defaultStatus}}
            </p>
            <p v-else class="cve-help-text is-italic"><span class="has-text-weight-bold">Default Status: </span>unknown</p>
            <div class="columns mr-1 mb-1 cve-versions-columns">
              <!-- Loop through the column headers so the affected/unaffected/unknown columns will display in alphabetical order -->
              <div v-for="header in product.versionsColumns.headers" :key="header.key"
                class="column pt-0 pr-0 pl-1 cve-left-margin cve-top-margin cve-versions-column">
                <div class="menu p-0" style="border: unset !important">
                  <ul class="menu-list mt-1" v-for="row in product.versionsColumns.twoDTable[header]" :key="row.key">
                    <li>
                      <span v-if="row.versionsChanges.length > 0">
                        <p>
                          {{row.parentVersionStatus}}
                          <span v-for="word in row.parentVersionRange" :key="word.key" :class="isAversion(word) ? 'cve-version' : ''">{{word}} </span>
                        </p>
                        <ul style="border-left: none !important;">
                          <li v-for="change in row.versionsChanges" :key="change.key">
                            <span v-for="word in change" :key="word.key" :class="isAversion(word) ? 'cve-version' : ''">{{word}} </span>
                          </li>
                        </ul>
                      </span>
                      <span v-else>
                        <span v-if="row.parentVersionRange.length > 0">{{row.parentVersionStatus}}
                          <span v-for="word in row.parentVersionRange" :key="word.key" :class="isAversion(word) ? 'cve-version' : ''">{{word}} </span>
                        </span>
                        <span v-else>
                          {{row.parentVersionStatus}} {{row.parentVersionRange}}
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="cve-credits" class="content" v-if="cveFieldList.credits.length > 0">
      <h2 class="title is-size-5">Credits</h2>
      <ul>
        <li v-for="(credit, index) in cveFieldList.credits" :key="`${credit}-${index}`">
          {{credit.value}}
          <span class="tag ml-2" v-if="typeof credit.type !== 'undefined' && credit.type.length > 0">{{credit.type}}</span>
        </li>
      </ul>
    </div>
    <div id="cve-references" class="content" v-if="cveFieldList.references.length > 0">
      <h2 class="title is-size-5">References</h2>
      <ul>
        <li v-for="(reference, index) in cveFieldList.references" :key="`link-${index}`" class="cve-word-wrap">
          <span class="icon-text">
            <a :href="reference.url" target="_blank">
              {{ (typeof reference.name !== 'undefined' && reference.name.length > 0) ? `${reference.hostname}: ${reference.name}` : reference.url }}
              <span class="icon cve-icon-xxs">
                <p id="enewsletter" class="is-hidden">external site</p>
                <font-awesome-icon icon="external-link-alt" aria-labelledby="enewsletter"></font-awesome-icon>
              </span>
            </a>
          </span>
          <span v-for="tag in reference.tags" :key="tag">
            <span class="tag ml-2" v-if="tag.length > 0">{{tag}}</span>
          </span>
        </li>
      </ul>
    </div>
    <div id="cve-nvd-link">
      <span>View additional information about
        <a :href="`${NVDBaseUrl}${this.$store.state.cveId}`" target='_blank'>
          {{ this.$store.state.recordData.cveMetadata.cveId }}
          <span class='icon cve-icon-xxs'>
            <p id='nvd' class='is-hidden'>external site</p>
            <font-awesome-icon icon='external-link-alt' aria-labelledby='nvd'></font-awesome-icon>
          </span>
        </a>
        on NVD.
      </span>
      <p class='cve-help-text'>(Note: The NVD is not operated by the CVE Program)</p>
    </div>
  </div>
</template>

<script>
import yaml from 'js-yaml';
import Vue from 'vue';
import _ from 'lodash';
import axios from 'axios';
import ToggleOmitSSVC from '@/components/ToggleOmitSSVC.vue';

export default ({
  name: 'PublishedRecord',
  props: {
    NVDBaseUrl: {
      type: String,
      required: true,
    },
  },
    components: {
    ToggleOmitSSVC
  },

  data() {
    return {




      isMessageExpanded: false,
      isHelpTestShown: false,
      cveRecordFields: ['ID', 'CNA', 'Credits', 'Description', 'References', 'State', 'Tags', 'Title', 'VendorsProductsVersions',
        'RecordPublishedDate', 'RecordUpdatedDate'],
      cveFieldList: {
        cveId: '',
        credits: [],
        descriptions: [],
        productsStatus: [],
        title: '',
        state: '',
        assigner: '',
        vcvss: '',
        ecvss: '',
        ycvss: '',
        acvss: '',
        b64_cpeas: '',
        gen_cpeas: '',
	        nvd_cpeas: '',
        nvd_vcvss: '',	
        nvd_ecvss: '',
        nvd_ycvss: '',
        nvd_acvss: '',
	vcvss_cna: '',
        ecvss_cna: '',
        ycvss_cna: '',
        acvss_cna: '',
        ref_zero: '',	
	opt_e: '',
	opt_a: '',
	opt_t: '',
	opt_f: '',
	aff_cpe: '',
		nvd_aff_cpe: '',
		aff_cpe_cna: '',
	aff_ven: '',
	aff_pro: '',
	aff_ver: '',
		aff_sym: '',
			aff_lim: '',
	aff_all: '',
	cwe: '',
		nvd_cwe: '',
		cwe_cna: '',
        cisaref: '',
	        mitreref: '',
        dateUpdatedCveMetadata: '',
        datePublishedCveMetadata: '',
        references: [],
        tags: [],
      },
      originalRecordData: this.$store.state.recordData || {},
    };
  },
  computed: {
    apiInstance() {
      return this.$store.getters.getApiInstance;
    }
  },
  
  methods: {
    updateApi(event) {
      this.$store.dispatch('updateApiInstance', event.target.value);
    },
    initializeFields() {
      this.cveRecordFields.forEach((field) => {
        this.getContentForField(field);
      });
    },

checkCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    checkRenderOptF() {
      const optF = this.cveFieldList.opt_f;
      const omitSSVC = this.checkCookie('omitSSVC');
      this.shouldRenderOptF = optF.length > 0 && !omitSSVC;
    },
    
    getCNA() {

const tcpe = {
"01-Scripts=01ACP":"cpe:2.3:a:01-scripts:01acp:*:*:*:*:*:*:*:*",
"07FLY=CRM":"cpe:2.3:a:07fly:customer_relationship_management:*:*:*:*:*:*:*:*",
"Qidianbang=qdbcrm":"cpe:2.3:a:100296:qdbcrm:*:*:*:*:*:*:*:*",
"10 Quality=Post Gallery":"cpe:2.3:a:10quality:post_gallery:*:*:*:*:*:*:*:*",
"Guizhou=115cms":"cpe:2.3:a:115cms:115cms:*:*:*:*:*:*:*:*",
"onedotprojects=cdn":"cpe:2.3:a:1cdn_project:1cdn:*:*:*:*:*:*:*:*",
"1E=1E Platform":"cpe:2.3:a:1e:platform:*:*:*:*:*:*:*:*",
"1E=Platform":"cpe:2.3:a:1e:platform:*:*:*:*:*:*:*:*",
"1up-lab=oneup/uploader-bundle":"cpe:2.3:a:1up:oneupuploaderbundle:*:*:*:*:*:*:*:*",
"Bubka=2FAuth":"cpe:2.3:a:2fauth:2fauth:*:*:*:*:*:*:*:*",
"iberezansky=3D FlipBook     PDF Flipbook WordPress":"cpe:2.3:a:3dflipbook:3d_flipbook:*:*:*:*:*:*:*:*",
"Dassault Syst  mes=3DEXPERIENCE":"cpe:2.3:a:3ds:3dexperience:*:*:*:*:*:*:*:*",
"Dassault Syst  mes=SOLIDWORKS Desktop":"cpe:2.3:a:3ds:3dexperience_solidworks:*:*:*:*:*:*:*:*",
"Dassault Syst  mes=BIOVIA Materials Studio products":"cpe:2.3:a:3ds:biovia_materials_studio:*:*:*:*:*:*:*:*",
"Dassault Syst  mes=DELMIA Apriso":"cpe:2.3:a:3ds:delmia_apriso:*:*:*:*:*:*:*:*",
"Dassault Syst  mes=ENOVIA Live Collaboration":"cpe:2.3:a:3ds:enovia_live_collaboration:*:*:*:*:*:*:*:*",
"Detcon=Sitewatch Gateway":"cpe:2.3:a:3m:detcon_sitewatch_gateway:*:*:*:*:*:*:*:*",
"4MOSAn=GCB Doctor":"cpe:2.3:a:4mosan:gcb_doctor:*:*:*:*:*:*:*:*",
"7Twenty=BOT":"cpe:2.3:a:7-twenty:bot:*:*:*:*:*:*:*:*",
"jitsi=jitsi-meet":"cpe:2.3:a:8x8:jitsi_meet:*:*:*:*:*:*:*:*",
"a2=Camera Trap Tracking System":"cpe:2.3:a:a2technology:camera_trap_tracking_system:*:*:*:*:*:*:*:*",
"a2=License Portal System":"cpe:2.3:a:a2technology:license_portal_system:*:*:*:*:*:*:*:*",
"AASync.com=AASync":"cpe:2.3:a:aasync:aasync:*:*:*:*:*:*:*:*",
"Voovi Social Networking Script=Voovi Social Networking Script":"cpe:2.3:a:aatifaneeq:voovi:*:*:*:*:*:*:*:*",
"ABB=System 800xA Base":"cpe:2.3:a:abb:800xa_base_system:*:*:*:*:*:*:*:*",
"ABB=800xA Batch Management":"cpe:2.3:a:abb:800xa_batch_management:*:*:*:*:*:*:*:*",
"ABB=800xA Information Management":"cpe:2.3:a:abb:800xa_information_management:*:*:*:*:*:*:*:*",
"ABB=System 800xA Information Manager":"cpe:2.3:a:abb:800xa_information_manager:*:*:*:*:*:*:*:*",
"ABB=800xA RNRP":"cpe:2.3:a:abb:800xa_rnrp:*:*:*:*:*:*:*:*",
"ABB=AO-OPC":"cpe:2.3:a:abb:ao-opc:*:*:*:*:*:*:*:*",
"ABB=ABB Device Library Wizard":"cpe:2.3:a:abb:device_library_wizard:*:*:*:*:*:*:*:*",
"ABB= e-Design":"cpe:2.3:a:abb:e-design:*:*:*:*:*:*:*:*",
"ABB=Mint WorkBench":"cpe:2.3:a:abb:mint_workbench:*:*:*:*:*:*:*:*",
"ABB=ABB Panel Builder 800":"cpe:2.3:a:abb:panel_builder_800:*:*:*:*:*:*:*:*",
"ABB=ABB PB610 Panel Builder 600":"cpe:2.3:a:abb:pb610_panel_builder_600:*:*:*:*:*:*:*:*",
"ABB=PB610 Panel Builder 600":"cpe:2.3:a:abb:pb610_panel_builder_600:*:*:*:*:*:*:*:*",
"ABB=ABB Ability    zenon":"cpe:2.3:a:abb:zenon:*:*:*:*:*:*:*:*",
"ABB=ABB Zenon":"cpe:2.3:a:abb:zenon:*:*:*:*:*:*:*:*",
"ABBS Software=Audio Media Player":"cpe:2.3:a:abbs_software_audio_media_player_project:abbs_software_audio_media_player:*:*:*:*:*:*:*:*",
"Abstrium=Pydio Cells":"cpe:2.3:a:abstrium:pydio_cells:*:*:*:*:*:*:*:*",
"accel-ppp=accel-ppp":"cpe:2.3:a:accel-ppp:accel-ppp:*:*:*:*:*:*:*:*",
"https://accel-ppp.org/=Accel-PPP":"cpe:2.3:a:accel-ppp:accel-ppp:*:*:*:*:*:*:*:*",
"https://accel-ppp.org/=accel-ppp":"cpe:2.3:a:accel-ppp:accel-ppp:*:*:*:*:*:*:*:*",
"Accellion=File Transfer Appliance":"cpe:2.3:a:accellion:file_transfer_appliance:*:*:*:*:*:*:*:*",
"Accusoft=ImageGear":"cpe:2.3:a:accusoft:imagegear:*:*:*:*:*:*:*:*",
"ACDSee=ACDSee Ultimate":"cpe:2.3:a:acdsee:ultimate:*:*:*:*:*:*:*:*",
"ACD Systems=Canvas Draw":"cpe:2.3:a:acdsystems:canvas_draw:*:*:*:*:*:*:*:*",
"AttorneyOnline=akashi":"cpe:2.3:a:aceattorneyonline:akashi:*:*:*:*:*:*:*:*",
"Aceka=Company Management":"cpe:2.3:a:acekaholding:company_management:*:*:*:*:*:*:*:*",
"Acilia =Widestand CMS":"cpe:2.3:a:acilia:widestand:*:*:*:*:*:*:*:*",
"ACME=Ultra Mini HTTPd":"cpe:2.3:a:acme:ultra_mini_httpd:*:*:*:*:*:*:*:*",
"Mautic=Mautic":"cpe:2.3:a:acquia:mautic:*:*:*:*:*:*:*:*",
"Acquisition, Technology & Logistics Agency=Installer of electronic tendering and bid opening system":"cpe:2.3:a:acquisition_technology_and_logistics_agency:installer_of_electronic_tendering:*:*:*:*:*:*:*:*",
"Acronis=Acronis Cyber Infrastructure":"cpe:2.3:a:acronis:cyber_infrastructure:*:*:*:*:*:*:*:*",
"Acronis=True Image":"cpe:2.3:a:acronis:true_image:*:*:*:*:*:*:*:*",
"Acronis=True Image 2021":"cpe:2.3:a:acronis:true_image:*:*:*:*:*:*:*:*",
"cgriego=active_attr":"cpe:2.3:a:active_attr_project:active_attr:*:*:*:*:*:*:*:*",
"Active It Zone=Active eCommerce CMS":"cpe:2.3:a:activeitzone:active_ecommerce_cms:*:*:*:*:*:*:*:*",
"ActiveITzone=Active Super Shop CMS":"cpe:2.3:a:activeitzone:active_super_shop:*:*:*:*:*:*:*:*",
"Acumos=Design Studio":"cpe:2.3:a:acumos:design_studio:*:*:*:*:*:*:*:*",
"Adam Retail Automation Systems=Mobilmen Terminal Software":"cpe:2.3:a:adampos:mobilmen_el_terminali_yazilimi:*:*:*:*:*:*:*:*",
"ADiTaaS=Allied Digital Integrated Tool-as-a-Service":"cpe:2.3:a:aditaas:allied_digital_integrated_tool-as-a-service:*:*:*:*:*:*:*:*",
"Kashipara=Online Furniture Shopping Ecommerce Website":"cpe:2.3:a:aditya88:online_furniture_shopping_ecommerce_website:*:*:*:*:*:*:*:*",
"Admidio=admidio":"cpe:2.3:a:admidio:admidio:*:*:*:*:*:*:*:*",
"admidio=admidio/admidio":"cpe:2.3:a:admidio:admidio:*:*:*:*:*:*:*:*",
"vrana=adminer":"cpe:2.3:a:adminer:adminer:*:*:*:*:*:*:*:*",
"Chris92de=AdminServ":"cpe:2.3:a:adminserv_project:adminserv:*:*:*:*:*:*:*:*",
"code-projects=Admission Management System":"cpe:2.3:a:admission_management_system_project:admission_management_system:*:*:*:*:*:*:*:*",
"Adobe=Experience Manager":"cpe:2.3:a:adobe:acs_aem_commons:*:*:*:*:*:*:*:*",
"Adobe=Experience Manager":"cpe:2.3:a:adobe:adobe_consulting_services_commons:*:*:*:*:*:*:*:*",
"Adobe=Adobe After Effects":"cpe:2.3:a:adobe:after_effects:*:*:*:*:*:*:*:*",
"Adobe=After Effects":"cpe:2.3:a:adobe:after_effects:*:*:*:*:*:*:*:*",
"Adobe=Animate":"cpe:2.3:a:adobe:animate:*:*:*:*:*:*:*:*",
"Adobe=Application Manager":"cpe:2.3:a:adobe:application_manager:*:*:*:*:*:*:*:*",
"Adobe=Bridge":"cpe:2.3:a:adobe:bridge:*:*:*:*:*:*:*:*",
"Adobe=Captivate":"cpe:2.3:a:adobe:captivate:*:*:*:*:*:*:*:*",
"Adobe=Adobe Character Animator":"cpe:2.3:a:adobe:character_animator:*:*:*:*:*:*:*:*",
"Adobe=Adobe Connect":"cpe:2.3:a:adobe:connect:*:*:*:*:*:*:*:*",
"Adobe=Connect":"cpe:2.3:a:adobe:connect:*:*:*:*:*:*:*:*",
"Adobe=Creative Cloud (desktop component)":"cpe:2.3:a:adobe:creative_cloud:*:*:*:*:*:*:*:*",
"Adobe=Creative Cloud (desktop component)":"cpe:2.3:a:adobe:creative_cloud_desktop_application:*:*:*:*:*:*:*:*",
"Adobe=Prelude":"cpe:2.3:a:adobe:creative_cloud_desktop_application:*:*:*:*:*:*:*:*",
"Adobe Systems Incorporated=Digital Editions":"cpe:2.3:a:adobe:digital_editions:*:*:*:*:*:*:*:*",
"Adobe=Adobe Digital Editions":"cpe:2.3:a:adobe:digital_editions:*:*:*:*:*:*:*:*",
"Adobe=Digital Editions":"cpe:2.3:a:adobe:digital_editions:*:*:*:*:*:*:*:*",
"Adobe=Dimension":"cpe:2.3:a:adobe:dimension:*:*:*:*:*:*:*:*",
"Unknown=Download Manager":"cpe:2.3:a:adobe:download_manager:*:*:*:*:*:*:*:*",
"Adobe Systems Incorporated=Experience Manager":"cpe:2.3:a:adobe:experience_manager:*:*:*:*:*:*:*:*",
"Adobe=Adobe Experience Manager":"cpe:2.3:a:adobe:experience_manager:*:*:*:*:*:*:*:*",
"Adobe=Adobe Experience Manager Forms":"cpe:2.3:a:adobe:experience_manager:*:*:*:*:*:*:*:*",
"Adobe=Experience Manager":"cpe:2.3:a:adobe:experience_manager:*:*:*:*:*:*:*:*",
"Adobe=Adobe Experience Manager Forms":"cpe:2.3:a:adobe:experience_manager_forms:*:*:*:*:*:*:*:*",
"Adobe=Experience Manager":"cpe:2.3:a:adobe:experience_manager_forms_add-on:*:*:*:*:*:*:*:*",
"Adobe=Adobe Framemaker":"cpe:2.3:a:adobe:framemaker:*:*:*:*:*:*:*:*",
"Adobe=FrameMaker":"cpe:2.3:a:adobe:framemaker:*:*:*:*:*:*:*:*",
"Adobe=Helix":"cpe:2.3:a:adobe:git-server:*:*:*:*:*:*:*:*",
"Adobe=Illustrator":"cpe:2.3:a:adobe:illustrator:*:*:*:*:*:*:*:*",
"Adobe=Marketo":"cpe:2.3:a:adobe:marketo_sales_insight:*:*:*:*:*:*:*:*",
"Adobe=Adobe Media Encoder":"cpe:2.3:a:adobe:media_encoder:*:*:*:*:*:*:*:*",
"Adobe=Media Encoder":"cpe:2.3:a:adobe:media_encoder:*:*:*:*:*:*:*:*",
"Adobe=Ops-CLI":"cpe:2.3:a:adobe:ops-cli:*:*:*:*:*:*:*:*",
"Adobe=Photoshop":"cpe:2.3:a:adobe:photoshop:*:*:*:*:*:*:*:*",
"Adobe=Adobe Photoshop CC":"cpe:2.3:a:adobe:photoshop_cc:*:*:*:*:*:*:*:*",
"Adobe=Photoshop Elements":"cpe:2.3:a:adobe:photoshop_elements:*:*:*:*:*:*:*:*",
"Adobe=Adobe Prelude CC":"cpe:2.3:a:adobe:prelude_cc:*:*:*:*:*:*:*:*",
"Adobe=Adobe Premiere Pro CC":"cpe:2.3:a:adobe:premiere_pro_cc:*:*:*:*:*:*:*:*",
"Adobe=RoboHelp":"cpe:2.3:a:adobe:robohelp:*:*:*:*:*:*:*:*",
"Adobe=RoboHelp Server":"cpe:2.3:a:adobe:robohelp_server:*:*:*:*:*:*:*:*",
"Adobe=Adobe Shockwave Player":"cpe:2.3:a:adobe:shockwave_player:*:*:*:*:*:*:*:*",
"Adobe=Substance3D - Designer":"cpe:2.3:a:adobe:substance_3d_designer:*:*:*:*:*:*:*:*",
"Adobe=Substance3D - Painter":"cpe:2.3:a:adobe:substance_3d_painter:*:*:*:*:*:*:*:*",
"Adobe=Substance3D - Sampler":"cpe:2.3:a:adobe:substance_3d_sampler:*:*:*:*:*:*:*:*",
"Adobe=Adobe Technical Communications Suite":"cpe:2.3:a:adobe:technical_communications_suite:*:*:*:*:*:*:*:*",
"Adobe=XMP Toolkit":"cpe:2.3:a:adobe:xmp_toolkit_software_development_kit:*:*:*:*:*:*:*:*",
"Campcodes=Advanced Online Voting System":"cpe:2.3:a:advanced_online_voting_system_project:advanced_online_voting_system:*:*:*:*:*:*:*:*",
"Advantech iView=iView":"cpe:2.3:a:advantech:iview:*:*:*:*:*:*:*:*",
"Advantech =R-SeeNet":"cpe:2.3:a:advantech:r-seenet:*:*:*:*:*:*:*:*",
"Advantech=R-SeeNet":"cpe:2.3:a:advantech:r-seenet:*:*:*:*:*:*:*:*",
"Advantech=Advantech WebAccess":"cpe:2.3:a:advantech:webaccess:*:*:*:*:*:*:*:*",
"Advantech=WebAccess":"cpe:2.3:a:advantech:webaccess:*:*:*:*:*:*:*:*",
"Advantech=WebAccess/SCADA":"cpe:2.3:a:advantech:webaccess:*:*:*:*:*:*:*:*",
"ICS-CERT=Advantech WebAccess Versions 8.1 and prior.":"cpe:2.3:a:advantech:webaccess:*:*:*:*:*:*:*:*",
"WebAccess=WebAccess/SCADA":"cpe:2.3:a:advantech:webaccess:*:*:*:*:*:*:*:*",
"Advantech=WebAccess/NMS":"cpe:2.3:a:advantech:webaccess\/nms:*:*:*:*:*:*:*:*",
"Advantech=WebAccess/SCADA":"cpe:2.3:a:advantech:webaccess\/scada:*:*:*:*:*:*:*:*",
"Advantech=HMI Designer":"cpe:2.3:a:advantech:webaccess_hmi_designer:*:*:*:*:*:*:*:*",
"ICS-CERT=Advantech WebAccess HMI Designer":"cpe:2.3:a:advantech:webaccess_hmi_designer:*:*:*:*:*:*:*:*",
"Advantech=WebAccess SCADA":"cpe:2.3:a:advantech:webaccess_scada:*:*:*:*:*:*:*:*",
"HackerOne=aedes":"cpe:2.3:a:aedes_project:aedes:*:*:*:*:*:*:*:*",
"aEnrich=a+HRD":"cpe:2.3:a:aenrich:a\+hrd:*:*:*:*:*:*:*:*",
"aerospike=aerospike-client-java":"cpe:2.3:a:aerospike:aerospike_java_client:*:*:*:*:*:*:*:*",
"Aethon=TUG Home Base Server":"cpe:2.3:a:aethon:tug_home_base_server:*:*:*:*:*:*:*:*",
"afichet=openexr-viewer":"cpe:2.3:a:afichet:openexr_viewer:*:*:*:*:*:*:*:*",
"Arthmoor=QSF-Portal":"cpe:2.3:a:afkmods:qsf-portal:*:*:*:*:*:*:*:*",
"Pylons=Waitress":"cpe:2.3:a:agendaless:waitress:*:*:*:*:*:*:*:*",
"Pylons=waitress":"cpe:2.3:a:agendaless:waitress:*:*:*:*:*:*:*:*",
"cockpit-hq=cockpit-hq/cockpit":"cpe:2.3:a:agentejo:cockpit:*:*:*:*:*:*:*:*",
"microweber=microweber":"cpe:2.3:a:agentejo:cockpit:*:*:*:*:*:*:*:*",
"AGG Software=Web Server (webserver.dll)":"cpe:2.3:a:aggsoft:webserver:*:*:*:*:*:*:*:*",
"Agile Point=Agile Point NX":"cpe:2.3:a:agilepoint:agilepoint_nx:*:*:*:*:*:*:*:*",
"Significant-Gravitas=Auto-GPT":"cpe:2.3:a:agpt:auto-gpt:*:*:*:*:*:*:*:*",
"code-projects=Agro-School Management System":"cpe:2.3:a:agro-school_management_system_project:agro-school_management_system:*:*:*:*:*:*:*:*",
"AGT Tech=Ceppatron":"cpe:2.3:a:agtteknik:ceppatron:*:*:*:*:*:*:*:*",
"Ahwxorg=LibreY":"cpe:2.3:a:ahwx:librey:*:*:*:*:*:*:*:*",
" AIFU Information Technology Co.=CASH":"cpe:2.3:a:aifu:cashier_accounting_management_system:*:*:*:*:*:*:*:*",
"aio-libs=aiohttp":"cpe:2.3:a:aiohttp:aiohttp:*:*:*:*:*:*:*:*",
"SourceCodester=Air Cargo Management System":"cpe:2.3:a:air_cargo_management_system_project:air_cargo_management_system:*:*:*:*:*:*:*:*",
"airbnb=optica":"cpe:2.3:a:airbnb:optica:*:*:*:*:*:*:*:*",
"unspecified=Airfield Online":"cpe:2.3:a:airfield_online_project:airfield_online:*:*:*:*:*:*:*:*",
"polterguy=Phosphorus Five":"cpe:2.3:a:aista:phosphorus_five:*:*:*:*:*:*:*:*",
"Aiko-IT-Systems=DisCatSharp":"cpe:2.3:a:aitsys:discatsharp:*:*:*:*:*:*:*:*",
"Aiven-Open=journalpump":"cpe:2.3:a:aiven:journalpump:*:*:*:*:*:*:*:*",
"michaelschwarz=Ajax.NET-Professional":"cpe:2.3:a:ajax.net_professional_project:ajax.net_professional:*:*:*:*:*:*:*:*",
"Cups Easy=Cups Easy (Purchase & Inventory)":"cpe:2.3:a:ajaysharma:cups_easy:*:*:*:*:*:*:*:*",
"unspecified=ajenti":"cpe:2.3:a:ajenti:ajenti:*:*:*:*:*:*:*:*",
"Akaunting=Akaunting":"cpe:2.3:a:akaunting:akaunting:*:*:*:*:*:*:*:*",
"Akbim Computer=Panon":"cpe:2.3:a:akbim:panon:*:*:*:*:*:*:*:*",
"Akeo Consulting=Rufus":"cpe:2.3:a:akeo:rufus:*:*:*:*:*:*:*:*",
"Akinsoft=Wolvox":"cpe:2.3:a:akinsoft:wolvox:*:*:*:*:*:*:*:*",
"Akky=Self-extracting archive files created by 7-ZIP32.DLL":"cpe:2.3:a:akky:7-zip32.dll:*:*:*:*:*:*:*:*",
"AlCoda=NetBiblio WebOPAC":"cpe:2.3:a:alcoda:netbiblio:*:*:*:*:*:*:*:*",
"alerta=alerta":"cpe:2.3:a:alerta_project:alerta:*:*:*:*:*:*:*:*",
"AlexanderLivanov=FotosCMS2":"cpe:2.3:a:alexanderlivanov:fotoscms2:*:*:*:*:*:*:*:*",
"alfio-event=alfio-event/alf.io":"cpe:2.3:a:alf:alf:*:*:*:*:*:*:*:*",
"Alfasado Inc.=PowerCMS":"cpe:2.3:a:alfasado:powercms:*:*:*:*:*:*:*:*",
"Alfasado Inc.=PowerCMS XMLRPC API":"cpe:2.3:a:alfasado:powercms:*:*:*:*:*:*:*:*",
"Algan Software=Prens Student Information System":"cpe:2.3:a:algan:prens_student_information_system:*:*:*:*:*:*:*:*",
"Algosec=Algosec FireFlow":"cpe:2.3:a:algosec:fireflow:*:*:*:*:*:*:*:*",
"alibaba=nacos":"cpe:2.3:a:alibaba:nacos:*:*:*:*:*:*:*:*",
"Alinto=SOGo":"cpe:2.3:a:alinto:sogo:*:*:*:*:*:*:*:*",
"tu6ge=oss-rs":"cpe:2.3:a:aliyun-oss-client_project:aliyun-oss-client:*:*:*:*:*:*:*:*",
"Alkacon=Open CMS":"cpe:2.3:a:alkacon:opencms:*:*:*:*:*:*:*:*",
"org.opencms=opencms-core":"cpe:2.3:a:alkacon:opencms:*:*:*:*:*:*:*:*",
"cachethq=cachet":"cpe:2.3:a:all-three:cachet:*:*:*:*:*:*:*:*",
"Allegro=RomPager":"cpe:2.3:a:allegrosoft:rompager:*:*:*:*:*:*:*:*",
"Rudloff=alltube":"cpe:2.3:a:alltube_project:alltube:*:*:*:*:*:*:*:*",
"rudloff=rudloff/alltube":"cpe:2.3:a:alltube_project:alltube:*:*:*:*:*:*:*:*",
"rudloff=rudloff/alltube":"cpe:2.3:a:alltubedownload:alltube:*:*:*:*:*:*:*:*",
"Alpata=Licensed Warehousing Automation System":"cpe:2.3:a:alpatateknoloji:licensed_warehousing_automation_system:*:*:*:*:*:*:*:*",
"SourceCodester=Alphaware Simple E-Commerce System":"cpe:2.3:a:alphaware_-_simple_e-commerce_system_project:alphaware_-_simple_e-commerce_system:*:*:*:*:*:*:*:*",
"SourceCodester=Alphaware Simple E-Commerce System":"cpe:2.3:a:alphaware_e-commerce_system_project:alphaware_e-commerce_system:*:*:*:*:*:*:*:*",
"SourceCodester=Alphaware Simple E-Commerce System":"cpe:2.3:a:alphaware_simple_e-commerce_system_project:alphaware_simple_e-commerce_system:*:*:*:*:*:*:*:*",
"stevespringett=alpine":"cpe:2.3:a:alpine_project:alpine:*:*:*:*:*:*:*:*",
"Altair=HyperView Player":"cpe:2.3:a:altair:hyperview_player:*:*:*:*:*:*:*:*",
"ALSong=Estsoft":"cpe:2.3:a:altools:alsong:*:*:*:*:*:*:*:*",
"Amazon=Amazon Music Player":"cpe:2.3:a:amazon:amazon_music:*:*:*:*:*:*:*:*",
"awslabs=aws-dataall":"cpe:2.3:a:amazon:aws-dataall:*:*:*:*:*:*:*:*",
"aws=aws-sdk-java":"cpe:2.3:a:amazon:aws-sdk-java:*:*:*:*:*:*:*:*",
"aws=aws-cdk":"cpe:2.3:a:amazon:aws_cloud_development_kit:*:*:*:*:*:*:*:*",
"Amazon=AWS SDK":"cpe:2.3:a:amazon:aws_encryption_sdk:*:*:*:*:*:*:*:*",
"awslabs=sandbox-accounts-for-events":"cpe:2.3:a:amazon:awslabs_sandbox_accounts_for_events:*:*:*:*:*:*:*:*",
"awslabs=fhir-works-on-aws-authz-smart":"cpe:2.3:a:amazon:fhir-works-on-aws-authz-smart:*:*:*:*:*:*:*:*",
"amazon-ion=ion-java":"cpe:2.3:a:amazon:ion:*:*:*:*:*:*:*:*",
"opensearch-project=OpenSearch":"cpe:2.3:a:amazon:opensearch:*:*:*:*:*:*:*:*",
"Itech=Movie Portal Script":"cpe:2.3:a:ambit:movie_portal_script:*:*:*:*:*:*:*:*",
"AMD=AMD Radeon Software ":"cpe:2.3:a:amd:radeon_software:*:*:*:*:*:*:*:*",
"AMI=MegaRAC_SPx":"cpe:2.3:a:ami:megarac_sp-x:*:*:*:*:*:*:*:*",
"AMI=MegaRAC_SPx":"cpe:2.3:a:ami:megarac_spx:*:*:*:*:*:*:*:*",
"Amodat =Amodat":"cpe:2.3:a:amodat:amodat:*:*:*:*:*:*:*:*",
"Amodat =Amodat":"cpe:2.3:a:amodat:mobile_application_gateway:*:*:*:*:*:*:*:*",
"ampache=ampache":"cpe:2.3:a:ampache:ampache:*:*:*:*:*:*:*:*",
"ampache=ampache/ampache":"cpe:2.3:a:ampache:ampache:*:*:*:*:*:*:*:*",
"AMTT=HiBOS":"cpe:2.3:a:amttgroup:hibos:*:*:*:*:*:*:*:*",
"anchore=syft":"cpe:2.3:a:anchore:syft:*:*:*:*:*:*:*:*",
"Google LLC=Android Play Core":"cpe:2.3:a:android:play_core_library:*:*:*:*:*:*:*:*",
"ANGLERSNET Co,.Ltd.=Access analysis CGI An-Analyzer":"cpe:2.3:a:anglers-net:cgi_an-anlyzer:*:*:*:*:*:*:*:*",
"kyubisation=angular-server-side-configuration":"cpe:2.3:a:angular-server-side-configuration_project:angular-server-side-configuration:*:*:*:*:*:*:*:*",
"Angus Johnson=Resource Hacker":"cpe:2.3:a:angusj:resource_hacker:*:*:*:*:*:*:*:*",
"Tomohiro Nakamura=ansible-vault":"cpe:2.3:a:ansible-vault_project:ansible-vault:*:*:*:*:*:*:*:*",
"Ansible Community=Community Collections":"cpe:2.3:a:ansible_collections_project:community.crypto:*:*:*:*:*:*:*:*",
"answerdev=answerdev/answer":"cpe:2.3:a:answer:answer:*:*:*:*:*:*:*:*",
"antfu=antfu/utils":"cpe:2.3:a:antfu:utils:*:*:*:*:*:*:*:*",
"nahsra=antisamy":"cpe:2.3:a:antisamy_project:antisamy:*:*:*:*:*:*:*:*",
"anuko=timetracker":"cpe:2.3:a:anuko:time_tracker:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Accumulo":"cpe:2.3:a:apache:accumulo:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ActiveMQ":"cpe:2.3:a:apache:activemq:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airavata Django Portal":"cpe:2.3:a:apache:airavata_django_portal:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow":"cpe:2.3:a:apache:airflow:*:*:*:*:*:*:*:*",
"Apache=Airflow":"cpe:2.3:a:apache:airflow:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow CNCF Kubernetes Provider":"cpe:2.3:a:apache:airflow_cncf_kubernetes:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow HDFS Provider":"cpe:2.3:a:apache:airflow_hdfs_provider:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Hive Provider":"cpe:2.3:a:apache:airflow_hive_provider:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Spark Provider":"cpe:2.3:a:apache:airflow_spark_provider:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Sqoop Provider":"cpe:2.3:a:apache:airflow_sqoop_provider:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Allura":"cpe:2.3:a:apache:allura:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Ambari":"cpe:2.3:a:apache:ambari:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Answer":"cpe:2.3:a:apache:answer:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Any23":"cpe:2.3:a:apache:any23:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow AWS Provider":"cpe:2.3:a:apache:apache-airflow-providers-amazon:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Drill Provider":"cpe:2.3:a:apache:apache-airflow-providers-apache-drill:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Apache Hive Provider":"cpe:2.3:a:apache:apache-airflow-providers-apache-hive:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Hive Provider":"cpe:2.3:a:apache:apache-airflow-providers-apache-hive:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Spark Provider":"cpe:2.3:a:apache:apache-airflow-providers-apache-spark:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Sqoop Provider":"cpe:2.3:a:apache:apache-airflow-providers-apache-sqoop:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow":"cpe:2.3:a:apache:apache-airflow-providers-docker:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow Google Provider":"cpe:2.3:a:apache:apache-airflow-providers-google:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow JDBC Provider":"cpe:2.3:a:apache:apache-airflow-providers-jdbc:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Airflow ODBC Provider":"cpe:2.3:a:apache:apache-airflow-providers-odbc:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Calcite Avatica":"cpe:2.3:a:apache:apache_calcite_avatica:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache APISIX":"cpe:2.3:a:apache:apisix:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache APISIX Dashboard":"cpe:2.3:a:apache:apisix_dashboard:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Archiva":"cpe:2.3:a:apache:archiva:*:*:*:*:*:*:*:*",
"Apache=Apache Archiva":"cpe:2.3:a:apache:archiva:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Arrow":"cpe:2.3:a:apache:arrow:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache AsterixDB":"cpe:2.3:a:apache:asterixdb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Atlas":"cpe:2.3:a:apache:atlas:*:*:*:*:*:*:*:*",
"Apache=Atlas":"cpe:2.3:a:apache:atlas:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Axis":"cpe:2.3:a:apache:axis:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Batik":"cpe:2.3:a:apache:batik:*:*:*:*:*:*:*:*",
"Apache=Beam":"cpe:2.3:a:apache:beam:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Brooklyn":"cpe:2.3:a:apache:brooklyn:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache bRPC":"cpe:2.3:a:apache:brpc:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Calcite":"cpe:2.3:a:apache:calcite:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Camel":"cpe:2.3:a:apache:camel:*:*:*:*:*:*:*:*",
"Apache=Apache Camel":"cpe:2.3:a:apache:camel:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Cassandra":"cpe:2.3:a:apache:cassandra:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Cayenne":"cpe:2.3:a:apache:cayenne:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache CloudStack":"cpe:2.3:a:apache:cloudstack:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Cocoon":"cpe:2.3:a:apache:cocoon:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Commons Compress":"cpe:2.3:a:apache:commons_compress:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Commons Email":"cpe:2.3:a:apache:commons_email:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Commons Jelly":"cpe:2.3:a:apache:commons_jelly:*:*:*:*:*:*:*:*",
"jxpath=jxpath":"cpe:2.3:a:apache:commons_jxpath:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache CouchDB":"cpe:2.3:a:apache:couchdb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache CXF":"cpe:2.3:a:apache:cxf:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache CXF Fediz":"cpe:2.3:a:apache:cxf_fediz:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache DB ddlutils":"cpe:2.3:a:apache:ddlutils:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache DeltaSpike":"cpe:2.3:a:apache:deltaspike:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Derby":"cpe:2.3:a:apache:derby:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Directory":"cpe:2.3:a:apache:directory_ldap_api:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache DolphinScheduler":"cpe:2.3:a:apache:dolphinscheduler:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Doris":"cpe:2.3:a:apache:doris:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Doris(Incubating)":"cpe:2.3:a:apache:doris:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Drill":"cpe:2.3:a:apache:drill:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Druid":"cpe:2.3:a:apache:druid:*:*:*:*:*:*:*:*",
"Apache=Apache Druid":"cpe:2.3:a:apache:druid:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Dubbo":"cpe:2.3:a:apache:dubbo:*:*:*:*:*:*:*:*",
"Apache=Apache Dubbo":"cpe:2.3:a:apache:dubbo:*:*:*:*:*:*:*:*",
"Apache=Dubbo":"cpe:2.3:a:apache:dubbo:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Felix Healthcheck Webconsole Plugin":"cpe:2.3:a:apache:felix_health_check_webconsole_plugin:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Fineract":"cpe:2.3:a:apache:fineract:*:*:*:*:*:*:*:*",
"Apache Software Foundation=apache fineract":"cpe:2.3:a:apache:fineract:*:*:*:*:*:*:*:*",
"Apache=Apache Fineract":"cpe:2.3:a:apache:fineract:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Flink":"cpe:2.3:a:apache:flink:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Flink Stateful Functions":"cpe:2.3:a:apache:flink_stateful_functions:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Flume":"cpe:2.3:a:apache:flume:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache FOP":"cpe:2.3:a:apache:formatting_objects_processor:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Geode":"cpe:2.3:a:apache:geode:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Gobblin":"cpe:2.3:a:apache:gobblin:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Guacamole":"cpe:2.3:a:apache:guacamole:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Hadoop":"cpe:2.3:a:apache:hadoop:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Hama":"cpe:2.3:a:apache:hama:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache HBase":"cpe:2.3:a:apache:hbase:*:*:*:*:*:*:*:*",
"Apache=Apache HBase":"cpe:2.3:a:apache:hbase:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Helix":"cpe:2.3:a:apache:helix:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Heron (Incubating)":"cpe:2.3:a:apache:heron:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Hive":"cpe:2.3:a:apache:hive:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache HTTP Server":"cpe:2.3:a:apache:http_server:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Kerby LDAP Backend":"cpe:2.3:a:apache:identity_backend:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Ignite":"cpe:2.3:a:apache:ignite:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Impala":"cpe:2.3:a:apache:impala:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Impala":"cpe:2.3:a:apache:impala:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache InLong":"cpe:2.3:a:apache:inlong:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache IoTDB":"cpe:2.3:a:apache:iotdb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache IoTDB Workbench":"cpe:2.3:a:apache:iotdb:*:*:*:*:*:*:*:*",
"Apache=IoTDB":"cpe:2.3:a:apache:iotdb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache IoTDB Workbench":"cpe:2.3:a:apache:iotdb_web_workbench:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Ivy":"cpe:2.3:a:apache:ivy:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Jackrabbit Oak":"cpe:2.3:a:apache:jackrabbit_oak:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache James":"cpe:2.3:a:apache:james:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache James server":"cpe:2.3:a:apache:james:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache James":"cpe:2.3:a:apache:james_server:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ServiceComb-Java-Chassis":"cpe:2.3:a:apache:java_chassis:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Jena":"cpe:2.3:a:apache:jena:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Jena Fuseki":"cpe:2.3:a:apache:jena_fuseki:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Jena SDB":"cpe:2.3:a:apache:jena_sdb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Portals":"cpe:2.3:a:apache:jetspeed:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache JMeter":"cpe:2.3:a:apache:jmeter:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Johnzon":"cpe:2.3:a:apache:johnzon:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache JSPWiki":"cpe:2.3:a:apache:jspwiki:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache jUDDI":"cpe:2.3:a:apache:juddi:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Kafka":"cpe:2.3:a:apache:kafka:*:*:*:*:*:*:*:*",
"Apache=Kafka":"cpe:2.3:a:apache:kafka:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Kafka Connect API":"cpe:2.3:a:apache:kafka_connect:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Karaf":"cpe:2.3:a:apache:karaf:*:*:*:*:*:*:*:*",
"Apache=Apache Karaf":"cpe:2.3:a:apache:karaf:*:*:*:*:*:*:*:*",
"Apache=Karaf":"cpe:2.3:a:apache:karaf:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Knox":"cpe:2.3:a:apache:knox:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Kylin":"cpe:2.3:a:apache:kylin:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Linkis":"cpe:2.3:a:apache:linkis:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Linkis (incubating)":"cpe:2.3:a:apache:linkis:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Livy (Incubating)":"cpe:2.3:a:apache:livy:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Log4cxx":"cpe:2.3:a:apache:log4cxx:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Log4j":"cpe:2.3:a:apache:log4j:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ManifoldCF":"cpe:2.3:a:apache:manifoldcf:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Mesos":"cpe:2.3:a:apache:mesos:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache MINA ":"cpe:2.3:a:apache:mina:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache MXNet":"cpe:2.3:a:apache:mxnet:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache NiFi":"cpe:2.3:a:apache:nifi:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache NiFi - MiNiFi C++":"cpe:2.3:a:apache:nifi_minifi_c\+\+:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache NiFi MiNiFi C++":"cpe:2.3:a:apache:nifi_minifi_c\+\+:*:*:*:*:*:*:*:*",
"Apache=Apache NiFi Registry":"cpe:2.3:a:apache:nifi_registry:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache NuttX":"cpe:2.3:a:apache:nuttx:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache NuttX (incubating)":"cpe:2.3:a:apache:nuttx:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache OFBiz":"cpe:2.3:a:apache:ofbiz:*:*:*:*:*:*:*:*",
"Apache=Apache OFBiz":"cpe:2.3:a:apache:ofbiz:*:*:*:*:*:*:*:*",
"Apache=OFBiz":"cpe:2.3:a:apache:ofbiz:*:*:*:*:*:*:*:*",
"apache=ofbiz-framework":"cpe:2.3:a:apache:ofbiz:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Olingo":"cpe:2.3:a:apache:olingo:*:*:*:*:*:*:*:*",
"Apache=Olingo":"cpe:2.3:a:apache:olingo:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Oozie":"cpe:2.3:a:apache:oozie:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache OpenMeetings":"cpe:2.3:a:apache:openmeetings:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache OpenNLP":"cpe:2.3:a:apache:opennlp:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache OpenOffice":"cpe:2.3:a:apache:openoffice:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Docker Skeleton Runtime for Apache OpenWhisk":"cpe:2.3:a:apache:openwhisk:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ORC":"cpe:2.3:a:apache:orc:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Ozone":"cpe:2.3:a:apache:ozone:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Parquet":"cpe:2.3:a:apache:parquet-mr:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Pinot":"cpe:2.3:a:apache:pinot:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache PLC4X":"cpe:2.3:a:apache:plc4x:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Pluto":"cpe:2.3:a:apache:pluto:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Portals":"cpe:2.3:a:apache:pluto:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache POI":"cpe:2.3:a:apache:poi:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Pony Mail":"cpe:2.3:a:apache:pony_mail:*:*:*:*:*:*:*:*",
"The Apache Software Foundation=Apache Pony Mail (incubating)":"cpe:2.3:a:apache:pony_mail:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Portable Runtime (APR)":"cpe:2.3:a:apache:portable_runtime:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Portable Runtime":"cpe:2.3:a:apache:portable_runtime_utility:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Portable Runtime Utility (APR-util)":"cpe:2.3:a:apache:portable_runtime_utility:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Pulsar":"cpe:2.3:a:apache:pulsar:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Pulsar WebSocket Proxy":"cpe:2.3:a:apache:pulsar:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Qpid Broker-J":"cpe:2.3:a:apache:qpid_broker-j:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Qpid Dispatch Router":"cpe:2.3:a:apache:qpid_dispatch:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Qpid Proton-J":"cpe:2.3:a:apache:qpid_proton-j:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Ranger":"cpe:2.3:a:apache:ranger:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache RocketMQ":"cpe:2.3:a:apache:rocketmq:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Roller":"cpe:2.3:a:apache:roller:*:*:*:*:*:*:*:*",
"Apache=Apache Roller":"cpe:2.3:a:apache:roller:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Santuario":"cpe:2.3:a:apache:santuario_xml_security_for_java:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sentry":"cpe:2.3:a:apache:sentry:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ServiceComb":"cpe:2.3:a:apache:servicecomb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ServiceComb Service-Center":"cpe:2.3:a:apache:servicecomb:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ShardingSphere-UI":"cpe:2.3:a:apache:shardingsphere-ui:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ShardingSphere-Proxy":"cpe:2.3:a:apache:shardingsphere:*:*:*:*:*:*:*:*",
"Apache Software Foundation=ShardingSphere-Agent":"cpe:2.3:a:apache:shardingsphere:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ShenYu":"cpe:2.3:a:apache:shenyu:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ShenYu (incubating) ":"cpe:2.3:a:apache:shenyu:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache ShenYu Admin":"cpe:2.3:a:apache:shenyu:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Shiro":"cpe:2.3:a:apache:shiro:*:*:*:*:*:*:*:*",
"Apache=Shiro":"cpe:2.3:a:apache:shiro:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling Engine":"cpe:2.3:a:apache:sling:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling":"cpe:2.3:a:apache:sling_authentication_service:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling App CMS":"cpe:2.3:a:apache:sling_cms:*:*:*:*:*:*:*:*",
"Apache Software Foundation=org.apache.sling.commons.json":"cpe:2.3:a:apache:sling_commons_json:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling Commons Messaging Mail":"cpe:2.3:a:apache:sling_commons_messaging_mail:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling":"cpe:2.3:a:apache:sling_i18n:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling JCR Base":"cpe:2.3:a:apache:sling_jcr_base:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling Resource Merger":"cpe:2.3:a:apache:sling_resource_merger:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Sling Servlets Resolver":"cpe:2.3:a:apache:sling_servlets_resolver:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache SOAP":"cpe:2.3:a:apache:soap:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Solr":"cpe:2.3:a:apache:solr:*:*:*:*:*:*:*:*",
"Apache=Apache Solr":"cpe:2.3:a:apache:solr:*:*:*:*:*:*:*:*",
"Apache=Solr":"cpe:2.3:a:apache:solr:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache SpamAssassin":"cpe:2.3:a:apache:spamassassin:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Spark":"cpe:2.3:a:apache:spark:*:*:*:*:*:*:*:*",
"Apache=Apache Spark":"cpe:2.3:a:apache:spark:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache MINA SSHD":"cpe:2.3:a:apache:sshd:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Storm":"cpe:2.3:a:apache:storm:*:*:*:*:*:*:*:*",
"Apache=Storm":"cpe:2.3:a:apache:storm:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache StreamPark (incubating)":"cpe:2.3:a:apache:streampark:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache StreamPipes":"cpe:2.3:a:apache:streampipes:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Struts":"cpe:2.3:a:apache:struts:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Submarine":"cpe:2.3:a:apache:submarine:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Superset":"cpe:2.3:a:apache:superset:*:*:*:*:*:*:*:*",
"unspecified=Superset":"cpe:2.3:a:apache:superset:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Syncope":"cpe:2.3:a:apache:syncope:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache SystemDS":"cpe:2.3:a:apache:systemds:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Tapestry":"cpe:2.3:a:apache:tapestry:*:*:*:*:*:*:*:*",
"Apache=Apache Tapestry":"cpe:2.3:a:apache:tapestry:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Tika":"cpe:2.3:a:apache:tika:*:*:*:*:*:*:*:*",
"Apache=Apache Tika":"cpe:2.3:a:apache:tika:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Tiles":"cpe:2.3:a:apache:tiles:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Tomcat":"cpe:2.3:a:apache:tomcat:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Tomcat Connectors":"cpe:2.3:a:apache:tomcat_connectors:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Tomcat Connectors":"cpe:2.3:a:apache:tomcat_jk_connector:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache TomEE":"cpe:2.3:a:apache:tomee:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Traffic Control":"cpe:2.3:a:apache:traffic_control:*:*:*:*:*:*:*:*",
"Apache=Traffic Control":"cpe:2.3:a:apache:traffic_control:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Traffic Server":"cpe:2.3:a:apache:traffic_server:*:*:*:*:*:*:*:*",
"Apache=Apache UIMA DUCC":"cpe:2.3:a:apache:uimaducc:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache UIMA":"cpe:2.3:a:apache:uimaj:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Unomi":"cpe:2.3:a:apache:unomi:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache UIMA DUCC":"cpe:2.3:a:apache:unstructured_information_management_architecture:*:*:*:*:*:*:*:*",
"Apache=VCL":"cpe:2.3:a:apache:virtual_computing_lab:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Wicket":"cpe:2.3:a:apache:wicket:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Xerces C++":"cpe:2.3:a:apache:xerces-c\+\+:*:*:*:*:*:*:*:*",
"Apache Software Foundation=Apache Zeppelin":"cpe:2.3:a:apache:zeppelin:*:*:*:*:*:*:*:*",
"Apachefriends=XAMPP":"cpe:2.3:a:apachefriends:xampp:*:*:*:*:*:*:*:*",
"unspecified=XAMPP":"cpe:2.3:a:apachefriends:xampp:*:*:*:*:*:*:*:*",
"SourceCodester=Apartment Visitor Management System":"cpe:2.3:a:apartment_visitors_management_system_project:apartment_visitors_management_system:*:*:*:*:*:*:*:*",
"Apereo=CAS":"cpe:2.3:a:apereo:central_authentication_service:*:*:*:*:*:*:*:*",
"opencast=opencast":"cpe:2.3:a:apereo:opencast:*:*:*:*:*:*:*:*",
"PrivateSky=apersistence":"cpe:2.3:a:apersistence_project:apersistence:*:*:*:*:*:*:*:*",
"api-platform=core":"cpe:2.3:a:api-platform:core:*:*:*:*:*:*:*:*",
"dwisiswant0=apkleaks":"cpe:2.3:a:apkleaks_project:apkleaks:*:*:*:*:*:*:*:*",
"iBotPeaches=Apktool":"cpe:2.3:a:apktool:apktool:*:*:*:*:*:*:*:*",
"kylebrowning=APNSwift":"cpe:2.3:a:apnswift_project:apnswift:*:*:*:*:*:*:*:*",
"apolloconfig=apollo":"cpe:2.3:a:apolloconfig:apollo:*:*:*:*:*:*:*:*",
"apollographql=router":"cpe:2.3:a:apollographql:apollo_router:*:*:*:*:*:*:*:*",
"Apostrophe =Apostrophe ":"cpe:2.3:a:apostrophecms:apostrophecms:*:*:*:*:*:*:*:*",
"appium=appium/appium-desktop":"cpe:2.3:a:appium:appium-desktop:*:*:*:*:*:*:*:*",
"Apple=Boot Camp":"cpe:2.3:a:apple:boot_camp:*:*:*:*:*:*:*:*",
"Apple=GarageBand":"cpe:2.3:a:apple:garageband:*:*:*:*:*:*:*:*",
"Apple=iMovie":"cpe:2.3:a:apple:imovie:*:*:*:*:*:*:*:*",
"Apple=iTunes U":"cpe:2.3:a:apple:itunes_u:*:*:*:*:*:*:*:*",
"Apple=macOS":"cpe:2.3:a:apple:mac_os_x:*:*:*:*:*:*:*:*",
"Swift=SwiftNIO Extras":"cpe:2.3:a:apple:nioextras:*:*:*:*:*:*:*:*",
"Apple=Pro Video Formats":"cpe:2.3:a:apple:pro_video_formats:*:*:*:*:*:*:*:*",
"Apple=Apple Remote Desktop":"cpe:2.3:a:apple:remote_desktop:*:*:*:*:*:*:*:*",
"Apple=Safari":"cpe:2.3:a:apple:safari:*:*:*:*:*:*:*:*",
"Swift Project=SwiftNIO Extras":"cpe:2.3:a:apple:swift-nio-extras:*:*:*:*:*:*:*:*",
"Swift Project=Swift Foundation":"cpe:2.3:a:apple:swift_foundation:*:*:*:*:*:*:*:*",
"Swift Project=SwiftNIO":"cpe:2.3:a:apple:swiftnio:*:*:*:*:*:*:*:*",
"Apple=Swift-Ubuntu":"cpe:2.3:a:apple:swiftnio_ssl:*:*:*:*:*:*:*:*",
"Apple=Windows Migration Assistant":"cpe:2.3:a:apple:windows_migration_assistant:*:*:*:*:*:*:*:*",
"Apple=Xcode":"cpe:2.3:a:apple:xcode:*:*:*:*:*:*:*:*",
"appleple inc.=a-blog cms":"cpe:2.3:a:appleple:a-blog_cms:*:*:*:*:*:*:*:*",
"appsmithorg=appsmithorg/appsmith":"cpe:2.3:a:appsmith:appsmith:*:*:*:*:*:*:*:*",
"Appwrite=Appwrite":"cpe:2.3:a:appwrite:appwrite:*:*:*:*:*:*:*:*",
"appwrite=appwrite/appwrite":"cpe:2.3:a:appwrite:appwrite:*:*:*:*:*:*:*:*",
"Aqua eSolutions=Aqua Drive":"cpe:2.3:a:aquaesolutions:aqua_drive:*:*:*:*:*:*:*:*",
"Aquaforest=TIFF Server":"cpe:2.3:a:aquaforest:tiff_server:*:*:*:*:*:*:*:*",
"Aquaverde GmbH=Aquarius CMS":"cpe:2.3:a:aquaverde:aquarius_cms:*:*:*:*:*:*:*:*",
"arangodb=arangodb":"cpe:2.3:a:arangodb:arangodb:*:*:*:*:*:*:*:*",
"Arborator=Server":"cpe:2.3:a:arborator_server_project:arborator_server:*:*:*:*:*:*:*:*",
"hhyo=Archery":"cpe:2.3:a:archerydms:archery:*:*:*:*:*:*:*:*",
"archesproject=arches":"cpe:2.3:a:archesproject:arches:*:*:*:*:*:*:*:*",
"JustArchiNET=ArchiSteamFarm":"cpe:2.3:a:archisteamfarm_project:archisteamfarm:*:*:*:*:*:*:*:*",
"ArchiveBox=ArchiveBox":"cpe:2.3:a:archivebox:archivebox:*:*:*:*:*:*:*:*",
"golang=archiver":"cpe:2.3:a:archiver_project:archiver:*:*:*:*:*:*:*:*",
"borg-reducer=borg-reducer":"cpe:2.3:a:archivesunleashed:graphpass:*:*:*:*:*:*:*:*",
"Archivista GmbH=ArchivistaBox webclient":"cpe:2.3:a:archivista:archivistabox:*:*:*:*:*:*:*:*",
"archivy=archivy/archivy":"cpe:2.3:a:archivy_project:archivy:*:*:*:*:*:*:*:*",
"ARC Informatique=PcVue":"cpe:2.3:a:arcinformatique:pcvue:*:*:*:*:*:*:*:*",
"Arcserve=D2D":"cpe:2.3:a:arcserve:d2d:*:*:*:*:*:*:*:*",
"Arcserve=Arcserve UDP":"cpe:2.3:a:arcserve:udp:*:*:*:*:*:*:*:*",
"ARDEREG=Sistemas SCADA":"cpe:2.3:a:ardereg:sistemas_scada:*:*:*:*:*:*:*:*",
"ArduPilot=APWeb":"cpe:2.3:a:ardupilot:apweb:*:*:*:*:*:*:*:*",
"area17=area17/twill":"cpe:2.3:a:area17:twill:*:*:*:*:*:*:*:*",
"AREAL SAS=Topkapi Vision (Server)":"cpe:2.3:a:areal-topkapi:vision_server:*:*:*:*:*:*:*:*",
"AREAL SAS=Webserv1":"cpe:2.3:a:areal-topkapi:webserv1:*:*:*:*:*:*:*:*",
"argoproj=argo-events":"cpe:2.3:a:argo_events_project:argo_events:*:*:*:*:*:*:*:*",
"Ariadne=Component Library":"cpe:2.3:a:ariadne-cms:ariadne_component_library:*:*:*:*:*:*:*:*",
"Arista Networks=CloudVision Portal":"cpe:2.3:a:arista:cloudvision_portal:*:*:*:*:*:*:*:*",
"Arista Networks=Terminattr":"cpe:2.3:a:arista:terminattr:*:*:*:*:*:*:*:*",
"ARM=mbed TLS":"cpe:2.3:a:arm:mbed_tls:*:*:*:*:*:*:*:*",
"ARM=mbed-ualloc memory library":"cpe:2.3:a:arm:mbed_ualloc:*:*:*:*:*:*:*:*",
"Arm Ltd=Valhall GPU Kernel Driver":"cpe:2.3:a:arm:valhall_gpu_kernel_driver:*:*:*:*:*:*:*:*",
"Dev Blog=Dev Blog":"cpe:2.3:a:armanidrisi:dev_blog:*:*:*:*:*:*:*:*",
"Armcode=AlienIP":"cpe:2.3:a:armcode:alienip:*:*:*:*:*:*:*:*",
"Armoli Technology=Cargo Tracking System":"cpe:2.3:a:armoli:cargo_tracking_system:*:*:*:*:*:*:*:*",
"ArmorX=LisoMail":"cpe:2.3:a:armorx:lisomail:*:*:*:*:*:*:*:*",
"ArmorX Global Technology Corporation=ArmorX Spam ":"cpe:2.3:a:armorxgt:spamtrap:*:*:*:*:*:*:*:*",
"FlaFla...=ArsenoL":"cpe:2.3:a:arsenol_project:arsenol:*:*:*:*:*:*:*:*",
"ArslanSoft=Education Portal":"cpe:2.3:a:arslansoft_education_portal_project:arslansoft_education_portal:*:*:*:*:*:*:*:*",
"  rtica=Integria IMS":"cpe:2.3:a:artica:integria_ims:*:*:*:*:*:*:*:*",
"Artica PFMS=Pandora FMS":"cpe:2.3:a:artica:pandora_fms:*:*:*:*:*:*:*:*",
"Pandora FMS=Pandora FMS":"cpe:2.3:a:artica:pandora_fms:*:*:*:*:*:*:*:*",
"artifacthub=hub":"cpe:2.3:a:artifacthub:hub:*:*:*:*:*:*:*:*",
"unspecified=GhostPCL":"cpe:2.3:a:artifex:ghostpcl:*:*:*:*:*:*:*:*",
"MuJS=MuJS":"cpe:2.3:a:artifex:mujs:*:*:*:*:*:*:*:*",
"ARTWARE=CMS":"cpe:2.3:a:artware_cms_project:artware_cms:*:*:*:*:*:*:*:*",
"HewarHewlett Packard Enterprise (HPE)=Aruba AirWave Management Platform":"cpe:2.3:a:arubanetworks:airwave:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Aruba ClearPass":"cpe:2.3:a:arubanetworks:clearpass:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=Aruba ClearPass Policy Manager":"cpe:2.3:a:arubanetworks:clearpass_policy_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Aruba ClearPass Policy Manager":"cpe:2.3:a:arubanetworks:clearpass_policy_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=Aruba EdgeConnect Enterprise Software":"cpe:2.3:a:arubanetworks:edgeconnect_enterprise:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=EdgeConnect SD-WAN Orchestrator":"cpe:2.3:a:arubanetworks:edgeconnect_sd-wan_orchestrator:*:*:*:*:*:*:*:*",
"unspecified=as":"cpe:2.3:a:as_project:as:*:*:*:*:*:*:*:*",
"jirutka=asciidoctor-include-ext":"cpe:2.3:a:asciidoctor-include-ext_project:asciidoctor-include-ext:*:*:*:*:*:*:*:*",
"ASH-AIO=ASH-AIO":"cpe:2.3:a:ash-aio_project:ash-aio:*:*:*:*:*:*:*:*",
"Ashlar-Vellum=Graphite":"cpe:2.3:a:ashlar:graphite:*:*:*:*:*:*:*:*",
"As Koc Energy=Web Report System":"cpe:2.3:a:askoc:web_report_system:*:*:*:*:*:*:*:*",
"ASOS Information Technologies=Book Cites":"cpe:2.3:a:asosegitim:bookcites:*:*:*:*:*:*:*:*",
"ASOS Information Technologies=SOBIAD":"cpe:2.3:a:asosegitim:sobiad:*:*:*:*:*:*:*:*",
"Talos=Aspose":"cpe:2.3:a:aspose:aspose.pdf_for_c\+\+:*:*:*:*:*:*:*:*",
"Talos=Aspose.PDF for C++":"cpe:2.3:a:aspose:aspose.pdf_for_c\+\+:*:*:*:*:*:*:*:*",
"Control iD=Gerencia Web":"cpe:2.3:a:assaabloy:control_id_gerencia_web:*:*:*:*:*:*:*:*",
"Control iD=iDSecure":"cpe:2.3:a:assaabloy:control_id_idsecure:*:*:*:*:*:*:*:*",
"Control iD=RHiD":"cpe:2.3:a:assaabloy:control_id_rhid:*:*:*:*:*:*:*:*",
"ASUS=Armoury Crate":"cpe:2.3:a:asus:armoury_crate:*:*:*:*:*:*:*:*",
"ASUS=Armoury Crate Service":"cpe:2.3:a:asus:armoury_crate_service:*:*:*:*:*:*:*:*",
"ASUS=Control Center":"cpe:2.3:a:asus:control_center:*:*:*:*:*:*:*:*",
"ASUS=Armoury Crate & Aura Creator Installer (ROG Live Service)":"cpe:2.3:a:asus:rog_live_service:*:*:*:*:*:*:*:*",
"ASUSTOR=ADM":"cpe:2.3:a:asustor:adm:*:*:*:*:*:*:*:*",
"Swift Project=Async HTTP Client":"cpe:2.3:a:asynchttpclient_project:async-http-client:*:*:*:*:*:*:*:*",
"AsynkronIT=Wire":"cpe:2.3:a:asynkron:wire:*:*:*:*:*:*:*:*",
"Acquisition, Technology & Logistics Agency=Installer of electronic tendering and bid opening system":"cpe:2.3:a:atla:electronic_tendering_and_bid_opening_system:*:*:*:*:*:*:*:*",
"Talos=Atlantis Word Processor":"cpe:2.3:a:atlantiswordprocessor:atlantis_word_processor:*:*:*:*:*:*:*:*",
"The Atlantis Word Processor Team=Atlantis Word Processor":"cpe:2.3:a:atlantiswordprocessor:atlantis_word_processor:*:*:*:*:*:*:*:*",
"Atlassian=Activity Streams":"cpe:2.3:a:atlassian:activity_streams:*:*:*:*:*:*:*:*",
"Atlassian=Application Links":"cpe:2.3:a:atlassian:application_links:*:*:*:*:*:*:*:*",
"Atlassian=Atlassian Application Links":"cpe:2.3:a:atlassian:application_links:*:*:*:*:*:*:*:*",
"Atlassian=Atlasboard":"cpe:2.3:a:atlassian:atlasboard:*:*:*:*:*:*:*:*",
"Atlassian=Automation for Jira":"cpe:2.3:a:atlassian:automation_for_jira:*:*:*:*:*:*:*:*",
"Atlassian=Bamboo":"cpe:2.3:a:atlassian:bamboo:*:*:*:*:*:*:*:*",
"Atlassian=Bitbucket Data Center":"cpe:2.3:a:atlassian:bitbucket:*:*:*:*:*:*:*:*",
"Atlassian=Bitbucket Server":"cpe:2.3:a:atlassian:bitbucket:*:*:*:*:*:*:*:*",
"Atlassian=Bitbucket Data Center":"cpe:2.3:a:atlassian:bitbucket_data_center:*:*:*:*:*:*:*:*",
"Atlassian=cloudtoken":"cpe:2.3:a:atlassian:cloudtoken:*:*:*:*:*:*:*:*",
"Atlassian=Companion App":"cpe:2.3:a:atlassian:companion:*:*:*:*:*:*:*:*",
"Atlassian=Confluence":"cpe:2.3:a:atlassian:confluence:*:*:*:*:*:*:*:*",
"Atlassian=Confluence Server":"cpe:2.3:a:atlassian:confluence:*:*:*:*:*:*:*:*",
"Atlassian=Confluence Server":"cpe:2.3:a:atlassian:confluence_server:*:*:*:*:*:*:*:*",
"Atlassian=Atlassian Connect Spring Boot (ACSB)":"cpe:2.3:a:atlassian:connect_spring_boot:*:*:*:*:*:*:*:*",
"Atlassian=Crowd":"cpe:2.3:a:atlassian:crowd:*:*:*:*:*:*:*:*",
"Atlassian=Crucible":"cpe:2.3:a:atlassian:crucible:*:*:*:*:*:*:*:*",
"Atlassian=Atlassian Fisheye":"cpe:2.3:a:atlassian:fisheye:*:*:*:*:*:*:*:*",
"Atlassian=Fisheye":"cpe:2.3:a:atlassian:fisheye:*:*:*:*:*:*:*:*",
"Atlassian=atlassian-http":"cpe:2.3:a:atlassian:http_library:*:*:*:*:*:*:*:*",
"Atlassian=Atlassian Jira":"cpe:2.3:a:atlassian:jira:*:*:*:*:*:*:*:*",
"Atlassian=Jira":"cpe:2.3:a:atlassian:jira:*:*:*:*:*:*:*:*",
"Atlassian=Jira Server":"cpe:2.3:a:atlassian:jira:*:*:*:*:*:*:*:*",
"Atlassian=Jira Align":"cpe:2.3:a:atlassian:jira_align:*:*:*:*:*:*:*:*",
"Atlassian=gajira-comment":"cpe:2.3:a:atlassian:jira_comment:*:*:*:*:*:*:*:*",
"Atlassian=gajira-create":"cpe:2.3:a:atlassian:jira_create:*:*:*:*:*:*:*:*",
"Atlassian=Jira":"cpe:2.3:a:atlassian:jira_server:*:*:*:*:*:*:*:*",
"Atlassian=Jira Server":"cpe:2.3:a:atlassian:jira_server:*:*:*:*:*:*:*:*",
"Atlassian=atlassian-renderer":"cpe:2.3:a:atlassian:jira_server:*:*:*:*:*:*:*:*",
"Atlassian=Jira Server for Slack":"cpe:2.3:a:atlassian:jira_server_for_slack:*:*:*:*:*:*:*:*",
"Atlassian=Confluence Questions":"cpe:2.3:a:atlassian:questions_for_confluence:*:*:*:*:*:*:*:*",
"Atlassian=Universal Plugin Manager":"cpe:2.3:a:atlassian:universal_plugin_manager:*:*:*:*:*:*:*:*",
"ATM Consulting=dolibarr_module_quicksupplierprice":"cpe:2.3:a:atm-consulting:dolibarr_module_quicksupplierprice:*:*:*:*:*:*:*:*",
"AtroCore=AtroPIM":"cpe:2.3:a:atrocore:atropim:*:*:*:*:*:*:*:*",
"Bachmann Visutec GmbH=Atvise":"cpe:2.3:a:atvise:atvise:*:*:*:*:*:*:*:*",
"advplyr=audiobookshelf":"cpe:2.3:a:audiobookshelf:audiobookshelf:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=augeas":"cpe:2.3:a:augeas:augeas:*:*:*:*:*:*:*:*",
"auth0=auth0.js":"cpe:2.3:a:auth0:auth0.js:*:*:*:*:*:*:*:*",
"auth0=node-auth0":"cpe:2.3:a:auth0:auth0.js:*:*:*:*:*:*:*:*",
"auth0=lock":"cpe:2.3:a:auth0:lock:*:*:*:*:*:*:*:*",
"auth0=passport-wsfed-saml2":"cpe:2.3:a:auth0:passport-wsfed-saml2:*:*:*:*:*:*:*:*",
"authconfig=authconfig":"cpe:2.3:a:authconfig_project:authconfig:*:*:*:*:*:*:*:*",
"authelia=authelia":"cpe:2.3:a:authelia:authelia:*:*:*:*:*:*:*:*",
"authzed=spicedb":"cpe:2.3:a:authzed:spicedb:*:*:*:*:*:*:*:*",
"SourceCodester=Auto Dealer Management System":"cpe:2.3:a:auto_dealer_management_system_project:auto_dealer_management_system:*:*:*:*:*:*:*:*",
"Autodesk=Customer Portal":"cpe:2.3:a:autodesk:customer_portal:*:*:*:*:*:*:*:*",
"Autodesk=Desktop Connector":"cpe:2.3:a:autodesk:desktop_connector:*:*:*:*:*:*:*:*",
"autolab=Autolab":"cpe:2.3:a:autolabproject:autolab:*:*:*:*:*:*:*:*",
"autolab=autolab/autolab":"cpe:2.3:a:autolabproject:autolab:*:*:*:*:*:*:*:*",
"unspecified=automad":"cpe:2.3:a:automad:automad:*:*:*:*:*:*:*:*",
"SourceCodester=Automated Beer Parlour Billing System":"cpe:2.3:a:automated_beer_parlour_billing_system_project:automated_beer_parlour_billing_system:*:*:*:*:*:*:*:*",
"Automated Logic=WebCtrl Server":"cpe:2.3:a:automatedlogic:webctrl_server:*:*:*:*:*:*:*:*",
"SourceCodester=Automatic Question Paper Generator System":"cpe:2.3:a:automatic_question_paper_generator_system_project:automatic_question_paper_generator_system:*:*:*:*:*:*:*:*",
"unspecified=Automatic Question Paper Generator":"cpe:2.3:a:automatic_question_paper_generator_system_project:automatic_question_paper_generator_system:*:*:*:*:*:*:*:*",
"unspecified=Automatic Question Paper Generator System":"cpe:2.3:a:automatic_question_paper_generator_system_project:automatic_question_paper_generator_system:*:*:*:*:*:*:*:*",
"Automox=Automox Agent":"cpe:2.3:a:automox:automox:*:*:*:*:*:*:*:*",
"MichaelAquilina=zsh-autoswitch-virtualenv":"cpe:2.3:a:autoswitch_python_virtualenv_project:autoswitch_python_virtualenv:*:*:*:*:*:*:*:*",
"AUVESY=Versiondog":"cpe:2.3:a:auvesy:versiondog:*:*:*:*:*:*:*:*",
"Avast/AVG=Avast/Avg Antivirus":"cpe:2.3:a:avast:avg_antivirus:*:*:*:*:*:*:*:*",
"Avast=Premium Security":"cpe:2.3:a:avast:premium_security:*:*:*:*:*:*:*:*",
"NortonLifelock=Avast Antivirus":"cpe:2.3:a:avast:script_shield:*:*:*:*:*:*:*:*",
"Avaya=Avaya Aura Appliance Virtualization Platform Utilities":"cpe:2.3:a:avaya:aura_appliance_virtualization_platform:*:*:*:*:*:*:*:*",
"Avaya=Avaya Aura Application Enablement Services":"cpe:2.3:a:avaya:aura_application_enablement_services:*:*:*:*:*:*:*:*",
"Avaya=Avaya Aura Communication Manager":"cpe:2.3:a:avaya:aura_communication_manager:*:*:*:*:*:*:*:*",
"Avaya=Communication Manager":"cpe:2.3:a:avaya:aura_communication_manager:*:*:*:*:*:*:*:*",
"Avaya=Equinox Conferencing Management (iView)":"cpe:2.3:a:avaya:aura_conferencing:*:*:*:*:*:*:*:*",
"Avaya=Aura Device Services":"cpe:2.3:a:avaya:aura_device_services:*:*:*:*:*:*:*:*",
"Avaya=Avaya Aura Devices Services":"cpe:2.3:a:avaya:aura_device_services:*:*:*:*:*:*:*:*",
"Avaya=Avaya Experience Portal":"cpe:2.3:a:avaya:aura_experience_portal:*:*:*:*:*:*:*:*",
"Avaya=Experience Portal Manager":"cpe:2.3:a:avaya:aura_experience_portal:*:*:*:*:*:*:*:*",
"Avaya=Product":"cpe:2.3:a:avaya:aura_experience_portal:*:*:*:*:*:*:*:*",
"Avaya=Aura Orchestration Designer":"cpe:2.3:a:avaya:aura_orchestration_designer:*:*:*:*:*:*:*:*",
"Avaya=Orchestration Designer":"cpe:2.3:a:avaya:aura_orchestration_designer:*:*:*:*:*:*:*:*",
"Avaya=Avaya Aura Utility Services":"cpe:2.3:a:avaya:aura_utility_services:*:*:*:*:*:*:*:*",
"Avaya=Avaya Call Management System":"cpe:2.3:a:avaya:call_management_system:*:*:*:*:*:*:*:*",
"Avaya=Call Management System Supervisor":"cpe:2.3:a:avaya:call_management_system_supervisor:*:*:*:*:*:*:*:*",
"Avaya=Avaya Control Manager":"cpe:2.3:a:avaya:control_manager:*:*:*:*:*:*:*:*",
"Avaya=Avaya Equinox Conferencing":"cpe:2.3:a:avaya:equinox_conferencing:*:*:*:*:*:*:*:*",
"Avaya=Avaya Meetings Management":"cpe:2.3:a:avaya:equinox_conferencing:*:*:*:*:*:*:*:*",
"Avaya=Avaya Meetings Server":"cpe:2.3:a:avaya:equinox_conferencing:*:*:*:*:*:*:*:*",
"Avaya=IP Office":"cpe:2.3:a:avaya:ip_office:*:*:*:*:*:*:*:*",
"Avaya=IP Office Application Server ":"cpe:2.3:a:avaya:ip_office_application_server:*:*:*:*:*:*:*:*",
"Avaya=IP Office Contact Center":"cpe:2.3:a:avaya:ip_office_contact_center:*:*:*:*:*:*:*:*",
"Avaya=IX Workforce Engagement":"cpe:2.3:a:avaya:ix_workforce_engagement:*:*:*:*:*:*:*:*",
"Avaya=Orchestration Designer":"cpe:2.3:a:avaya:orchestration_designer:*:*:*:*:*:*:*:*",
"Avdor CIS=crystal quality":"cpe:2.3:a:avdorcis:crystal_quality:*:*:*:*:*:*:*:*",
"AVEVA=Edge":"cpe:2.3:a:aveva:aveva_edge:*:*:*:*:*:*:*:*",
"Schneider Electric SE=ClearSCADA":"cpe:2.3:a:aveva:clearscada:*:*:*:*:*:*:*:*",
"awesomized=libmemcached":"cpe:2.3:a:awesome:libmemcached:*:*:*:*:*:*:*:*",
"Axiell=Iguana":"cpe:2.3:a:axiell:iguana:*:*:*:*:*:*:*:*",
"Axios Italia=Axios RE":"cpe:2.3:a:axiositalia:registro_elettronico:*:*:*:*:*:*:*:*",
"Axiomatic=Bento4":"cpe:2.3:a:axiosys:bento4:*:*:*:*:*:*:*:*",
"Axis Communications AB=AXIS License Plate Verifier":"cpe:2.3:a:axis:license_plate_verifier:*:*:*:*:*:*:*:*",
"Axis Communications AB=AXIS License Plate Verifier ":"cpe:2.3:a:axis:license_plate_verifier:*:*:*:*:*:*:*:*",
"Yasutaka ATARASHI=axpdfium":"cpe:2.3:a:axpdfium_project:axpdfium:*:*:*:*:*:*:*:*",
"IBERMATICA=IBERMATICA RPS 2019":"cpe:2.3:a:ayesa:ibermatica_rps:*:*:*:*:*:*:*:*",
"azuracast=azuracast/azuracast":"cpe:2.3:a:azuracast:azuracast:*:*:*:*:*:*:*:*",
"Microsoft=azure-iot-cli-extension":"cpe:2.3:a:azure-iot-cli-extension:-:*:*:*:*:*:*:*:*",
"npm=vditor":"cpe:2.3:a:b3log:vditor:*:*:*:*:*:*:*:*",
"vanessa219=vanessa219/vditor":"cpe:2.3:a:b3log:vditor:*:*:*:*:*:*:*:*",
"backstage=backstage":"cpe:2.3:a:backstage:backstage:*:*:*:*:*:*:*:*",
"s::can=moni::tools":"cpe:2.3:a:badgermeter:moni\:*:*:*:*:*:*:*:*",
"s::can=moni:tools":"cpe:2.3:a:badgermeter:moni\:*:*:*:*:*:*:*:*",
"unspecified=Badminton Center Management System":"cpe:2.3:a:badminton_center_management_system_project:badminton_center_management_system:*:*:*:*:*:*:*:*",
"Baidu Japan Inc.=Installer of Baidu IME":"cpe:2.3:a:baidu:baidu_ime:*:*:*:*:*:*:*:*",
"Baidu, Inc.=Baidu Browser":"cpe:2.3:a:baidu:spark_browser:*:*:*:*:*:*:*:*",
"ecomfe=zrender":"cpe:2.3:a:baidu:zrender:*:*:*:*:*:*:*:*",
"itsourcecode=Bakery Online Ordering System":"cpe:2.3:a:bakery_online_ordering_system_project:bakery_online_ordering_system:*:*:*:*:*:*:*:*",
"ballcat-projects=ballcat-codegen":"cpe:2.3:a:ballcat:codegen:*:*:*:*:*:*:*:*",
"Bandisoft International Inc.=ark library":"cpe:2.3:a:bandisoft:ark_library:*:*:*:*:*:*:*:*",
"SourceCodester=Bank Management System":"cpe:2.3:a:bank_management_system_project:bank_management_system:*:*:*:*:*:*:*:*",
"Yomguithereal=Baobab":"cpe:2.3:a:baobab_project:baobab:*:*:*:*:*:*:*:*",
"Baremetrics=baremetrics-calendar":"cpe:2.3:a:baremetrics:date_range_picker:*:*:*:*:*:*:*:*",
"bareos=bareos":"cpe:2.3:a:bareos:bareos:*:*:*:*:*:*:*:*",
"barrelstrength=Sprout Forms":"cpe:2.3:a:barrelstrengthdesign:sprout_forms:*:*:*:*:*:*:*:*",
"baserCMS Users Community=baserCMS":"cpe:2.3:a:basercms:basercms:*:*:*:*:*:*:*:*",
"baserproject=basercms":"cpe:2.3:a:basercms:basercms:*:*:*:*:*:*:*:*",
"Baserow B.V.=Baserow":"cpe:2.3:a:baserow:baserow:*:*:*:*:*:*:*:*",
"morpheus65535=bazarr":"cpe:2.3:a:bazarr:bazarr:*:*:*:*:*:*:*:*",
"bblfsh=bblfshd":"cpe:2.3:a:bblfshd_project:bblfshd:*:*:*:*:*:*:*:*",
"B. Braun Melsungen AG=OnlineSuite":"cpe:2.3:a:bbraun:onlinesuite_application_package:*:*:*:*:*:*:*:*",
"Becton Dickinson & Co=BD Alaris        Guardrails        Editor":"cpe:2.3:a:bd:alaris_guardrails_editor:*:*:*:*:*:*:*:*",
"Becton Dickinson & Co=BD Alaris        Systems Manager":"cpe:2.3:a:bd:alaris_systems_manager:*:*:*:*:*:*:*:*",
"Becton Dickinson & Co=CQI Reporter":"cpe:2.3:a:bd:guardrails_cqi_reporter:*:*:*:*:*:*:*:*",
"Unknown=Woohoo":"cpe:2.3:a:bdaia:woohoo_newspaper_magazine_theme:*:*:*:*:*:*:*:*",
"bustle=bustle/mobiledoc-kit":"cpe:2.3:a:bdg:mobiledoc_kit:*:*:*:*:*:*:*:*",
"ptrofimov=ptrofimov/beanstalk_console":"cpe:2.3:a:beanstalk_console_project:beanstalk_console:*:*:*:*:*:*:*:*",
"BEAppsMobile=PC Keyboard WiFi & Bluetooth":"cpe:2.3:a:beappsmobile:pc_keyboard_wifi\&bluetooth:*:*:*:*:*:*:*:*",
"Telepad=PC Keyboard WiFi & Bluetooth":"cpe:2.3:a:beappsmobile:pc_keyboard_wifi_\&_bluetooth:*:*:*:*:*:*:*:*",
"ICS-CERT=Beckhoff TwinCAT":"cpe:2.3:a:beckhoff:twincat:*:*:*:*:*:*:*:*",
"Beckhoff=TwinCat XAR 3.1":"cpe:2.3:a:beckhoff:twincat_extended_automation_runtime:*:*:*:*:*:*:*:*",
"Beekeeper Studio, Inc.=Beekeeper Studio":"cpe:2.3:a:beekeeperstudio:beekeeper-studio:*:*:*:*:*:*:*:*",
"xiandafu=beetl-bbs":"cpe:2.3:a:beetl-bbs_project:beetl-bbs:*:*:*:*:*:*:*:*",
"benbusby=whoogle-search":"cpe:2.3:a:benbusby:whoogle_search:*:*:*:*:*:*:*:*",
"Bentley=ContextCapture":"cpe:2.3:a:bentley:contextcapture_viewer:*:*:*:*:*:*:*:*",
"Bentley Systems=MicroStation Connect":"cpe:2.3:a:bentley:microstation_connect:*:*:*:*:*:*:*:*",
"Bentley=MicroStation CONNECT":"cpe:2.3:a:bentley:microstation_connect:*:*:*:*:*:*:*:*",
"SourceCodester=Best Fee Management System":"cpe:2.3:a:best_fee_management_system_project:best_fee_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Best Online News Portal":"cpe:2.3:a:best_online_news_portal_project:best_online_news_portal:*:*:*:*:*:*:*:*",
"SourceCodester=Best POS Management System":"cpe:2.3:a:best_pos_management_system_project:best_pos_management_system:*:*:*:*:*:*:*:*",
"Besttem=Network Marketing Software":"cpe:2.3:a:besttem_network_marketing_project:besttem_network_marketing:*:*:*:*:*:*:*:*",
"BeyondTrust=BeyondInsight":"cpe:2.3:a:beyondtrust:beyondinsight:*:*:*:*:*:*:*:*",
"sah-comp=bienlein":"cpe:2.3:a:bienlein_project:bienlein:*:*:*:*:*:*:*:*",
"bigbluebutton=bigbluebutton":"cpe:2.3:a:bigbluebutton:bigbluebutton:*:*:*:*:*:*:*:*",
"bigbluebutton=bigbluebutton/bigbluebutton":"cpe:2.3:a:bigbluebutton:bigbluebutton:*:*:*:*:*:*:*:*",
"bigbluebutton=greenlight":"cpe:2.3:a:bigbluebutton:greenlight:*:*:*:*:*:*:*:*",
"Bullwark=Bullwark":"cpe:2.3:a:biges:bullwark_momentum_series:*:*:*:*:*:*:*:*",
"bigfork=silverstripe-form-capture":"cpe:2.3:a:bigfork:silverstripe_form_capture:*:*:*:*:*:*:*:*",
"BigProf =Online Clinic Management System":"cpe:2.3:a:bigprof:online_clinic_management_system:*:*:*:*:*:*:*:*",
"BigProf =Online Inventory Manager":"cpe:2.3:a:bigprof:online_invoicing_system:*:*:*:*:*:*:*:*",
"BigProf =Online Invoicing System":"cpe:2.3:a:bigprof:online_invoicing_system:*:*:*:*:*:*:*:*",
"bigprof-software=online-invoicing-system":"cpe:2.3:a:bigprof:online_invoicing_system:*:*:*:*:*:*:*:*",
"SourceCodester=Billing Management System":"cpe:2.3:a:billing_management_system_project:billing_management_system:*:*:*:*:*:*:*:*",
"Biltay Technology=Kayisi":"cpe:2.3:a:biltay:kayisi:*:*:*:*:*:*:*:*",
"Biltay Technology=Procost":"cpe:2.3:a:biltay:procost:*:*:*:*:*:*:*:*",
"Biltay Technology=Scienta":"cpe:2.3:a:biltay:scienta:*:*:*:*:*:*:*:*",
"sileht=bird-lg":"cpe:2.3:a:bird-lg_project:bird-lg:*:*:*:*:*:*:*:*",
"Biscom=Secure File Transfer":"cpe:2.3:a:biscom:secure_file_transfer:*:*:*:*:*:*:*:*",
"Bitdefender=Bitdefender Antivirus for Mac":"cpe:2.3:a:bitdefender:antivirus:*:*:*:*:*:*:*:*",
"Bitdefender=Antivirus Plus":"cpe:2.3:a:bitdefender:antivirus_plus:*:*:*:*:*:*:*:*",
"Bitdefender=EPSecurityService.exe":"cpe:2.3:a:bitdefender:endpoint_security_tools:*:*:*:*:*:*:*:*",
"Bitdefender=Bitdefender Engines":"cpe:2.3:a:bitdefender:engines:*:*:*:*:*:*:*:*",
"Bitdefender=Engines":"cpe:2.3:a:bitdefender:engines:*:*:*:*:*:*:*:*",
"Bitdefender=GravityZone":"cpe:2.3:a:bitdefender:gravityzone:*:*:*:*:*:*:*:*",
"Bitdefender=GravityZone Update Server":"cpe:2.3:a:bitdefender:gravityzone:*:*:*:*:*:*:*:*",
"BItdefender=GravityZone Business Security":"cpe:2.3:a:bitdefender:gravityzone_business_security:*:*:*:*:*:*:*:*",
"Bitdefender=Hypervisor Introspection":"cpe:2.3:a:bitdefender:hypervisor_introspection:*:*:*:*:*:*:*:*",
"Bitdefender=Bitdefender Internet Security":"cpe:2.3:a:bitdefender:internet_security_2018:*:*:*:*:*:*:*:*",
"Bitdefender=SafePay":"cpe:2.3:a:bitdefender:safepay:*:*:*:*:*:*:*:*",
"Bitdefender=Safepay":"cpe:2.3:a:bitdefender:safepay:*:*:*:*:*:*:*:*",
"Zero Day Initiative=Bitdefender Total Security":"cpe:2.3:a:bitdefender:total_security:*:*:*:*:*:*:*:*",
"Bitdefender=Bitdefender Total Security 2020":"cpe:2.3:a:bitdefender:total_security_2020:*:*:*:*:*:*:*:*",
"Bitdefender=bdserviceshost.exe":"cpe:2.3:a:bitdefender:total_security_2020:*:*:*:*:*:*:*:*",
"Bitdefender=Bitdefender Update Server":"cpe:2.3:a:bitdefender:update_server:*:*:*:*:*:*:*:*",
"Bitrix24=Bitrix24":"cpe:2.3:a:bitrix24:bitrix24:*:*:*:*:*:*:*:*",
"Bitrix=Site Manager":"cpe:2.3:a:bitrix24:bitrix_site_manager:*:*:*:*:*:*:*:*",
"unspecified=uTorrent":"cpe:2.3:a:bittorrent:utorrent:*:*:*:*:*:*:*:*",
"BlackBerry=AtHoc":"cpe:2.3:a:blackberry:athoc:*:*:*:*:*:*:*:*",
"BlackBerry=BlackBerry Enterprise Mobility Server (BEMS)":"cpe:2.3:a:blackberry:enterprise_mobility_server:*:*:*:*:*:*:*:*",
"BlackBerry=QNX Software Development Platform":"cpe:2.3:a:blackberry:qnx_software_development_platform:*:*:*:*:*:*:*:*",
"BlackBerry=QNX Software Development Platform (QNX SDP)":"cpe:2.3:a:blackberry:qnx_software_development_platform:*:*:*:*:*:*:*:*",
"BlackBerry=BlackBerry UEM":"cpe:2.3:a:blackberry:unified_endpoint_manager:*:*:*:*:*:*:*:*",
"BlackBerry=UEM Management Console":"cpe:2.3:a:blackberry:unified_endpoint_manager:*:*:*:*:*:*:*:*",
"BlackBerry=BlackBerry Workspaces Server; WatchDox by BlackBerry Server":"cpe:2.3:a:blackberry:workspaces:*:*:*:*:*:*:*:*",
"blockmason=credit-protocol":"cpe:2.3:a:blockmason:credit-protocol:*:*:*:*:*:*:*:*",
"m1k1o=blog":"cpe:2.3:a:blog_project:blog:*:*:*:*:*:*:*:*",
"BlogEngine.NET=BlogEngine.NET":"cpe:2.3:a:blogengine:blogengine.net:*:*:*:*:*:*:*:*",
"SourceCodester=Blood Bank Management System":"cpe:2.3:a:blood_bank_management_system_project:blood_bank_management_system:*:*:*:*:*:*:*:*",
"unspecified=Bludit":"cpe:2.3:a:bludit:bludit:*:*:*:*:*:*:*:*",
"BlueZ=BlueZ":"cpe:2.3:a:bluez:bluez:*:*:*:*:*:*:*:*",
"Linux=Kernel":"cpe:2.3:a:bluez:bluez:*:*:*:*:*:*:*:*",
"Blynk=Blynk-Library":"cpe:2.3:a:blynk:blynk-library:*:*:*:*:*:*:*:*",
"BMA=Personnel Tracking System":"cpe:2.3:a:bma:personnel_tracking_system:*:*:*:*:*:*:*:*",
"BMC=Track-It!":"cpe:2.3:a:bmc:track-it\!:*:*:*:*:*:*:*:*",
"bolt=bolt":"cpe:2.3:a:boltcms:bolt:*:*:*:*:*:*:*:*",
"bonitasoft=bonita-connector-webservice":"cpe:2.3:a:bonitasoft:webservice_connector:*:*:*:*:*:*:*:*",
"SourceCodester=Book Store Management System":"cpe:2.3:a:book_store_management_system_project:book_store_management_system:*:*:*:*:*:*:*:*",
"Unisign=Bookreen":"cpe:2.3:a:bookreen:bookreen:*:*:*:*:*:*:*:*",
"BookStack=BookStack":"cpe:2.3:a:bookstackapp:bookstack:*:*:*:*:*:*:*:*",
"BookStackApp=BookStack":"cpe:2.3:a:bookstackapp:bookstack:*:*:*:*:*:*:*:*",
"bookstackapp=bookstackapp/bookstack":"cpe:2.3:a:bookstackapp:bookstack:*:*:*:*:*:*:*:*",
"Red Hat=bootstrap3-typeahead.js":"cpe:2.3:a:bootstrap-3-typeahead_project:bootstrap-3-typeahead:*:*:*:*:*:*:*:*",
"wenzhixin=wenzhixin/bootstrap-table":"cpe:2.3:a:bootstrap-table:bootstrap_table:*:*:*:*:*:*:*:*",
"borgbackup=borg":"cpe:2.3:a:borgbackup:borg:*:*:*:*:*:*:*:*",
"Bosch=BIS":"cpe:2.3:a:bosch:building_integration_system:*:*:*:*:*:*:*:*",
"Bosch=Configuration Manager":"cpe:2.3:a:bosch:configuration_manager:*:*:*:*:*:*:*:*",
"Bosch=IP Helper":"cpe:2.3:a:bosch:ip_helper:*:*:*:*:*:*:*:*",
"Bosch=Monitor Wall":"cpe:2.3:a:bosch:monitor_wall:*:*:*:*:*:*:*:*",
"RTS=VLink Virtual Matrix Software":"cpe:2.3:a:bosch:rts_vlink_virtual_matrix:*:*:*:*:*:*:*:*",
"Bosch=Video Client":"cpe:2.3:a:bosch:video_client:*:*:*:*:*:*:*:*",
"Bosch=Video Recording Manager":"cpe:2.3:a:bosch:video_recording_manager:*:*:*:*:*:*:*:*",
"Bosch=Video Streaming Gateway":"cpe:2.3:a:bosch:video_streaming_gateway:*:*:*:*:*:*:*:*",
"Boston Sleep=slice":"cpe:2.3:a:bostonsleep:slice:*:*:*:*:*:*:*:*",
"Randombit=Botan":"cpe:2.3:a:botan_project:botan:*:*:*:*:*:*:*:*",
"Botanik Software=Pharmacy Automation":"cpe:2.3:a:botanikyazilim:pharmacy_automation:*:*:*:*:*:*:*:*",
"Legion of the Bouncy Castle=BouncyCastle TLS":"cpe:2.3:a:bouncycastle:legion-of-the-bouncy-castle-java-crytography-api:*:*:*:*:*:*:*:*",
"boxbilling=boxbilling/boxbilling":"cpe:2.3:a:boxbilling:boxbilling:*:*:*:*:*:*:*:*",
"Hex Workshop=Hex Workshop":"cpe:2.3:a:bpsoft:hex_workshop:*:*:*:*:*:*:*:*",
"B&R Automation=Automation Runtime webserver":"cpe:2.3:a:br-automation:automation_runtime:*:*:*:*:*:*:*:*",
"B&R Industrial Automation=Automation Runtime":"cpe:2.3:a:br-automation:automation_runtime:*:*:*:*:*:*:*:*",
"B&R Industrial Automation=B&R Automation Runtime":"cpe:2.3:a:br-automation:automation_runtime:*:*:*:*:*:*:*:*",
"B&R=Automation Runtime":"cpe:2.3:a:br-automation:automation_runtime:*:*:*:*:*:*:*:*",
"B&R Industrial Automation=Automation Studio":"cpe:2.3:a:br-automation:automation_studio:*:*:*:*:*:*:*:*",
"B&R=Automation Studio":"cpe:2.3:a:br-automation:automation_studio:*:*:*:*:*:*:*:*",
"B&R Industrial Automation=B&R APROL":"cpe:2.3:a:br-automation:industrial_automation_aprol:*:*:*:*:*:*:*:*",
"B&R=SiteManager":"cpe:2.3:a:br-automation:sitemanager:*:*:*:*:*:*:*:*",
"B&R Industrial Automation=Automation Studio":"cpe:2.3:a:br-automation:studio:*:*:*:*:*:*:*:*",
"B&R Industrial Automation=B&R VC4":"cpe:2.3:a:br-automation:vc4:*:*:*:*:*:*:*:*",
"Bradley B. Dalina=Image Tag Manager":"cpe:2.3:a:bradleybdalina:image_tag_manager:*:*:*:*:*:*:*:*",
"unspecified=Brandbugle":"cpe:2.3:a:brandbugle:brandbugle:*:*:*:*:*:*:*:*",
"inunosinsi=soycms":"cpe:2.3:a:brassica:soy_cms:*:*:*:*:*:*:*:*",
"brave=brave-browser":"cpe:2.3:a:brave:brave:*:*:*:*:*:*:*:*",
"Brave Software=Brave Browser Android":"cpe:2.3:a:brave:browser:*:*:*:*:*:*:*:*",
"Woorank=robots-txt-guard":"cpe:2.3:a:bridgeline:robots-txt-guard:*:*:*:*:*:*:*:*",
"Brizy.io=Brizy - Page Builder":"cpe:2.3:a:brizy:brizy-page_builder:*:*:*:*:*:*:*:*",
"CA Technologies - A Broadcom Company=CA Automic Workload Automation":"cpe:2.3:a:broadcom:automic_workload_automation:*:*:*:*:*:*:*:*",
"Brocade Communications Systems, Inc.=Brocade SANnav":"cpe:2.3:a:broadcom:brocade_sannav:*:*:*:*:*:*:*:*",
"Brocade=Brocade SANnav":"cpe:2.3:a:broadcom:brocade_sannav:*:*:*:*:*:*:*:*",
"Brocade=SANnav":"cpe:2.3:a:broadcom:brocade_sannav:*:*:*:*:*:*:*:*",
"ca technologies=CA API Developer Portal":"cpe:2.3:a:broadcom:ca_api_developer_portal:*:*:*:*:*:*:*:*",
"CA Technologies=CA Privileged Access Manager":"cpe:2.3:a:broadcom:privileged_access_manager:*:*:*:*:*:*:*:*",
"CA Technologies=Release Automation":"cpe:2.3:a:broadcom:release_automation:*:*:*:*:*:*:*:*",
"Symantec Corporation=SSL Visibility (SSLV)":"cpe:2.3:a:broadcom:ssl_visibility_appliance:*:*:*:*:*:*:*:*",
"Symantec=Data Loss Prevention":"cpe:2.3:a:broadcom:symantec_data_center_security_server:*:*:*:*:*:*:*:*",
"Symantec=Deployment Solution":"cpe:2.3:a:broadcom:symantec_deployment_solutions:*:*:*:*:*:*:*:*",
"Symantec Corporation=IntelligenceCenter":"cpe:2.3:a:broadcom:symantec_intelligencecenter:*:*:*:*:*:*:*:*",
"Symantec=Messaging Gateway":"cpe:2.3:a:broadcom:symantec_messaging_gateway:*:*:*:*:*:*:*:*",
"Symantec=Server Management Suite":"cpe:2.3:a:broadcom:symantec_server_management_suite:*:*:*:*:*:*:*:*",
"CA Technologies - A Broadcom Company=CA Unified Infrastructure Management (Nimsoft/UIM)":"cpe:2.3:a:broadcom:unified_infrastructure_management:*:*:*:*:*:*:*:*",
"browserup=browserup-proxy":"cpe:2.3:a:browserup:browserup_proxy:*:*:*:*:*:*:*:*",
"btcpayserver=btcpayserver/btcpayserver":"cpe:2.3:a:btcpayserver:btcpay_server:*:*:*:*:*:*:*:*",
"btcpayserver=btcpayserver/btcpayserver":"cpe:2.3:a:btcpayserver:btcpayserver:*:*:*:*:*:*:*:*",
"fxbin=bubble-fireworks":"cpe:2.3:a:bubble_fireworks_project:bubble_fireworks:*:*:*:*:*:*:*:*",
"Budibase=budibase":"cpe:2.3:a:budibase:budibase:*:*:*:*:*:*:*:*",
"budibase=budibase/budibase":"cpe:2.3:a:budibase:budibase:*:*:*:*:*:*:*:*",
"Bug Finder=ChainCity Real Estate Investment Platform":"cpe:2.3:a:bugfinder:chaincity:*:*:*:*:*:*:*:*",
"Bug Finder=EX-RATE":"cpe:2.3:a:bugfinder:ex-rate:*:*:*:*:*:*:*:*",
"Bug Finder=Finounce":"cpe:2.3:a:bugfinder:finounce:*:*:*:*:*:*:*:*",
"Bug Finder=Foody Friend":"cpe:2.3:a:bugfinder:foody_friend:*:*:*:*:*:*:*:*",
"Bug Finder=ICOGenie":"cpe:2.3:a:bugfinder:icogenie:*:*:*:*:*:*:*:*",
"Bug Finder=Listplace Directory Listing Platform":"cpe:2.3:a:bugfinder:listplace_directory_listing_platform:*:*:*:*:*:*:*:*",
"Bug Finder=MineStack":"cpe:2.3:a:bugfinder:minestack:*:*:*:*:*:*:*:*",
"Bug Finder=Montage":"cpe:2.3:a:bugfinder:montage:*:*:*:*:*:*:*:*",
"Bug Finder=SASS BILLER":"cpe:2.3:a:bugfinder:sass_biller:*:*:*:*:*:*:*:*",
"Bug Finder=Wedding Wonders":"cpe:2.3:a:bugfinder:wedding_wonders:*:*:*:*:*:*:*:*",
"builderio=builderio/qwik":"cpe:2.3:a:builder:qwik:*:*:*:*:*:*:*:*",
"Buildroot=Buildroot":"cpe:2.3:a:buildroot:buildroot:*:*:*:*:*:*:*:*",
"Bulutses Information Technologies=Bulutdesk Callcenter":"cpe:2.3:a:bulutses:bulutdesk_callcenter:*:*:*:*:*:*:*:*",
"unilogies=unilogies/bumsys":"cpe:2.3:a:bumsys_project:bumsys:*:*:*:*:*:*:*:*",
"BKG=NTRIP Professional Caster":"cpe:2.3:a:bund:bkg_professional_ntripcaster:*:*:*:*:*:*:*:*",
"code-projects=Bus Dispatch and Information System":"cpe:2.3:a:bus_dispatch_and_information_system_project:bus_dispatch_and_information_system:*:*:*:*:*:*:*:*",
" EisBaer Scada= EisBaer Scada":"cpe:2.3:a:busbaer:eisbaer_scada:*:*:*:*:*:*:*:*",
" EisBaer Scada=v3.0.6433.1964":"cpe:2.3:a:busbaer:eisbaer_scada:*:*:*:*:*:*:*:*",
"Business-DNA Solutions GmbH=TopEase":"cpe:2.3:a:business-dnasolutions:topease:*:*:*:*:*:*:*:*",
"unilogies=unilogies/bumsys":"cpe:2.3:a:business_management_system_project:business_management_system:*:*:*:*:*:*:*:*",
"Business-DNA Solutions GmbH=TopEase":"cpe:2.3:a:businessdnasolutions:topease:*:*:*:*:*:*:*:*",
"FrankEnderman=Butter":"cpe:2.3:a:butter_project:butter:*:*:*:*:*:*:*:*",
"BUTTERFLY BUTTON PROJECT=BUTTERFLY BUTTON":"cpe:2.3:a:butterfly-button:butterfly_button:*:*:*:*:*:*:*:*",
"ByDemes Group=Airspace CCTV Web Service":"cpe:2.3:a:bydemes:airspace_cctv_web_service:*:*:*:*:*:*:*:*",
"Bylancer=QuickAI OpenAI":"cpe:2.3:a:bylancer:quickai_openai:*:*:*:*:*:*:*:*",
"Bylancer=QuickJob":"cpe:2.3:a:bylancer:quickjob:*:*:*:*:*:*:*:*",
"Bylancer=QuickOrder":"cpe:2.3:a:bylancer:quickorder:*:*:*:*:*:*:*:*",
"Bylancer=QuickQR":"cpe:2.3:a:bylancer:quickqr:*:*:*:*:*:*:*:*",
"Bylancer=QuickVCard":"cpe:2.3:a:bylancer:quickvcard:*:*:*:*:*:*:*:*",
"bytebase=bytebase":"cpe:2.3:a:bytebase:bytebase:*:*:*:*:*:*:*:*",
"Konloch=bytecode-viewer":"cpe:2.3:a:bytecode_viewer_project:bytecode_viewer:*:*:*:*:*:*:*:*",
"bytedeco=javacpp-presets":"cpe:2.3:a:bytedeco:javacpp_presets:*:*:*:*:*:*:*:*",
"Byzoro=Smart S85F Management Platform":"cpe:2.3:a:byzoro:smart_s85f:*:*:*:*:*:*:*:*",
"Byzoro=Smart S85F Management Platform":"cpe:2.3:a:byzoro:smart_s85f_management_platform:*:*:*:*:*:*:*:*",
"CA Technologies=CA Privileged Access Manager":"cpe:2.3:a:ca:ca_privileged_access_manager:*:*:*:*:*:*:*:*",
"CA Technologies=CA Identity Governance":"cpe:2.3:a:ca:identity_governance:*:*:*:*:*:*:*:*",
"CA Technologies=CA Spectrum":"cpe:2.3:a:ca:spectrum:*:*:*:*:*:*:*:*",
"CA Technologies=Unified Infrastructure Management":"cpe:2.3:a:ca:unified_infrastructure_management:*:*:*:*:*:*:*:*",
"CA Technologies=Workload Control Center":"cpe:2.3:a:ca:workload_control_center:*:*:*:*:*:*:*:*",
"Cacti=cacti":"cpe:2.3:a:cacti:cacti:*:*:*:*:*:*:*:*",
"Cainor=Calendarinho":"cpe:2.3:a:cainor:calendarinho:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=Cairo":"cpe:2.3:a:cairographics:cairo:*:*:*:*:*:*:*:*",
"cakephp=cakephp":"cpe:2.3:a:cakephp:cakephp:*:*:*:*:*:*:*:*",
"calcom=cal.com":"cpe:2.3:a:cal:cal.com:*:*:*:*:*:*:*:*",
"calcom=calcom/cal.com":"cpe:2.3:a:cal:cal.com:*:*:*:*:*:*:*:*",
"Teleopti=WFM":"cpe:2.3:a:calabrio:teleopti_workforce_management:*:*:*:*:*:*:*:*",
"unspecified=CalendarXP":"cpe:2.3:a:calendarxp:calendarxp:*:*:*:*:*:*:*:*",
"OzzieIsaacs=calibreweb":"cpe:2.3:a:calibre-web_project:calibre-web:*:*:*:*:*:*:*:*",
"calibreweb=calibreweb":"cpe:2.3:a:calibre-web_project:calibre-web:*:*:*:*:*:*:*:*",
"janeczku=janeczku/calibre-web":"cpe:2.3:a:calibre-web_project:calibre-web:*:*:*:*:*:*:*:*",
"Callback technologies=CBFS Filter":"cpe:2.3:a:callback:cbfs_filter:*:*:*:*:*:*:*:*",
"Campcodes=Beauty Salon Management System":"cpe:2.3:a:campcodes:beauty_salon_management_system:*:*:*:*:*:*:*:*",
"Campcodes=Chic Beauty Salon":"cpe:2.3:a:campcodes:chic_beauty_salon:*:*:*:*:*:*:*:*",
"Campcodes=Online College Library System":"cpe:2.3:a:campcodes:online_college_library_system:*:*:*:*:*:*:*:*",
"Campcodes=Student Information System":"cpe:2.3:a:campcodes:simple_student_information_system:*:*:*:*:*:*:*:*",
"Campcodes=Web-Based Student Clearance System":"cpe:2.3:a:campcodes:student_clearance_system:*:*:*:*:*:*:*:*",
"Campcodes=Advanced Online Voting System":"cpe:2.3:a:campcodes_advanced_online_voting_system_project:campcodes_advanced_online_voting_system:*:*:*:*:*:*:*:*",
"Campcodes=Online Traffic Offense Management System":"cpe:2.3:a:campcodes_online_traffic_offense_management_system_project:campcodes_online_traffic_offense_management_system:*:*:*:*:*:*:*:*",
"Campcodes=Video Sharing Website":"cpe:2.3:a:campcodes_video_sharing_website_project:campcodes_video_sharing_website:*:*:*:*:*:*:*:*",
"camptocamp=camptocamp/terraboard":"cpe:2.3:a:camptocamp:terraboard:*:*:*:*:*:*:*:*",
"Canonical=add-apt-repository":"cpe:2.3:a:canonical:add-apt-repository:*:*:*:*:*:*:*:*",
"Canonical=apport":"cpe:2.3:a:canonical:apport:*:*:*:*:*:*:*:*",
"Canonical=pc-kernel":"cpe:2.3:a:canonical:c-kernel:*:*:*:*:*:*:*:*",
"Canonical Ltd.=cloud-init":"cpe:2.3:a:canonical:cloud-init:*:*:*:*:*:*:*:*",
"Canonical=cloud-init":"cpe:2.3:a:canonical:cloud-init:*:*:*:*:*:*:*:*",
"Canonical Ltd.=Landscape":"cpe:2.3:a:canonical:landscape:*:*:*:*:*:*:*:*",
"Canonical=MicroK8s":"cpe:2.3:a:canonical:microk8s:*:*:*:*:*:*:*:*",
"Canonical Ltd.=subiquity":"cpe:2.3:a:canonical:subiquity:*:*:*:*:*:*:*:*",
"Canonical=Subiquity":"cpe:2.3:a:canonical:subiquity:*:*:*:*:*:*:*:*",
"Canonical Ltd.=Ubuntu pipewire-pulse":"cpe:2.3:a:canonical:ubuntu_pipewire-pulse:*:*:*:*:*:*:*:*",
"SourceCodester=Canteen Management System":"cpe:2.3:a:canteen_management_system_project:canteen_management_system:*:*:*:*:*:*:*:*",
"Talos=ACD Systems":"cpe:2.3:a:canvasgfx:canvas_draw:*:*:*:*:*:*:*:*",
"Caphyon=Advanced Installer":"cpe:2.3:a:caphyon:advanced_installer:*:*:*:*:*:*:*:*",
"capnproto=capnproto":"cpe:2.3:a:capnproto:capnproto:*:*:*:*:*:*:*:*",
"SourceCodester=Card Holder Management System":"cpe:2.3:a:card_holder_management_system_project:card_holder_management_system:*:*:*:*:*:*:*:*",
"careteditor=caret":"cpe:2.3:a:caret:caret:*:*:*:*:*:*:*:*",
"code-projects=College Notes Gallery":"cpe:2.3:a:carmelogarcia:college_notes_gallery:*:*:*:*:*:*:*:*",
"code-projects=Employee Profile Management System":"cpe:2.3:a:carmelogarcia:employee_profile_management_system:*:*:*:*:*:*:*:*",
"code-projects=Faculty Management System":"cpe:2.3:a:carmelogarcia:faculty_management_system:*:*:*:*:*:*:*:*",
"code-projects=Intern Membership Management System":"cpe:2.3:a:carmelogarcia:intern_membership_management_system:*:*:*:*:*:*:*:*",
"code-projects=Matrimonial Site":"cpe:2.3:a:carmelogarcia:matrimonial_site:*:*:*:*:*:*:*:*",
"PoS/ Dienstleistung, Entwicklung & Vertrieb GmbH=cashIT! - serving solutions.":"cpe:2.3:a:cashit:cashit\!:*:*:*:*:*:*:*:*",
"Cassia Networks=Access Controller":"cpe:2.3:a:cassianetworks:access_controller:*:*:*:*:*:*:*:*",
"Catalis=CMS360":"cpe:2.3:a:catalisgov:cms360:*:*:*:*:*:*:*:*",
"fiveai=Cachet":"cpe:2.3:a:catchethq:catchet:*:*:*:*:*:*:*:*",
"Caton=Live":"cpe:2.3:a:catontechnology:caton_live:*:*:*:*:*:*:*:*",
"Caton=Prime":"cpe:2.3:a:catontechnology:caton_prime:*:*:*:*:*:*:*:*",
"Caton=CTP Relay Server":"cpe:2.3:a:catontechnology:ctp_relay_server:*:*:*:*:*:*:*:*",
"Cayin Technology=Cayin xPost":"cpe:2.3:a:cayintech:xpost:*:*:*:*:*:*:*:*",
"Control de Ciber=Control de Ciber":"cpe:2.3:a:cbm:control_de_ciber:*:*:*:*:*:*:*:*",
"C-DATA=Web Management System":"cpe:2.3:a:cdatatec:c-data_web_management_system:*:*:*:*:*:*:*:*",
"C-DATA=Web Management System":"cpe:2.3:a:cdatatec:web_management_system:*:*:*:*:*:*:*:*",
"Chengdu=Flash Flood Disaster Monitoring and Warning System":"cpe:2.3:a:cdwanjiang:flash_flood_disaster_monitoring_and_warning_system:*:*:*:*:*:*:*:*",
"cecilapp=cecilapp/cecil":"cpe:2.3:a:cecil:cecil:*:*:*:*:*:*:*:*",
"CentOS Web Panel=CentOS Web Panel":"cpe:2.3:a:centos-webpanel:centos_web_panel:*:*:*:*:*:*:*:*",
"LINE Corporation=Central Dogma":"cpe:2.3:a:central_dogma_project:central_dogma:*:*:*:*:*:*:*:*",
"SourceCodester=Centralized Covid Vaccination Records System":"cpe:2.3:a:centralized_covid_vaccination_records_system_project:centralized_covid_vaccination_records_system:*:*:*:*:*:*:*:*",
"Centreon=Centreon":"cpe:2.3:a:centreon:centreon:*:*:*:*:*:*:*:*",
"unspecified=centreon":"cpe:2.3:a:centreon:centreon:*:*:*:*:*:*:*:*",
"indico=indico":"cpe:2.3:a:cern:indico:*:*:*:*:*:*:*:*",
"CERT/CC=VINCE - The Vulnerability Information and Coordination Environment":"cpe:2.3:a:cert:vince:*:*:*:*:*:*:*:*",
"CERT/CC=VINCE - The Vulnerability Information and Coordination Environment ":"cpe:2.3:a:cert:vince:*:*:*:*:*:*:*:*",
"certifi=python-certifi":"cpe:2.3:a:certifi_project:certifi:*:*:*:*:*:*:*:*",
"Cesanta=Mongoose":"cpe:2.3:a:cesanta:mongoose:*:*:*:*:*:*:*:*",
"CESNET=perun":"cpe:2.3:a:cesnet:perun:*:*:*:*:*:*:*:*",
"Cevik Informatics=Online Payment System":"cpe:2.3:a:cevik:informatics_online_payment_system:*:*:*:*:*:*:*:*",
"fiveai=Cachet":"cpe:2.3:a:chachethq:cachet:*:*:*:*:*:*:*:*",
"hpjansson=hpjansson/chafa":"cpe:2.3:a:chafa_project:chafa:*:*:*:*:*:*:*:*",
"ChainSafe=lodestar":"cpe:2.3:a:chainsafe:lodestar:*:*:*:*:*:*:*:*",
"challenge=website":"cpe:2.3:a:challenge_website_project:challenge_website:*:*:*:*:*:*:*:*",
"ChamaNet=MemoCGI":"cpe:2.3:a:chama:memocgi:*:*:*:*:*:*:*:*",
"Chamilo=Chamilo":"cpe:2.3:a:chamilo:chamilo:*:*:*:*:*:*:*:*",
"Chamilo=Chamilo":"cpe:2.3:a:chamilo:chamilo_lms:*:*:*:*:*:*:*:*",
"dgtlmoon=changedetection.io":"cpe:2.3:a:changedetection:changedetection:*:*:*:*:*:*:*:*",
"ChangingTec=MOTP":"cpe:2.3:a:changingtec:mobile_one_time_password:*:*:*:*:*:*:*:*",
"Changing=MOTP(Mobile One Time Password)":"cpe:2.3:a:changingtec:motp:*:*:*:*:*:*:*:*",
"Changing Information Technology Inc.=RAVA certificate validation system":"cpe:2.3:a:changingtec:rava_certificate_validation_system:*:*:*:*:*:*:*:*",
"ChangingTec=ServiSign":"cpe:2.3:a:changingtec:servisign:*:*:*:*:*:*:*:*",
"ChangingTec=ServiSign ":"cpe:2.3:a:changingtec:servisign:*:*:*:*:*:*:*:*",
"ChanGate EnterPrise Co., Ltd=property management system":"cpe:2.3:a:changjia_property_management_system_project:changjia_property_management_system:*:*:*:*:*:*:*:*",
"Chanzhaoyu=chatgpt-web":"cpe:2.3:a:chanzhaoyu:chatgpt_web:*:*:*:*:*:*:*:*",
"SmokeDetector=SmokeDetector":"cpe:2.3:a:charcoal-se:smokedetector:*:*:*:*:*:*:*:*",
"charmbracelet=charm":"cpe:2.3:a:charm:charm:*:*:*:*:*:*:*:*",
"chaskiq=chaskiq/chaskiq":"cpe:2.3:a:chaskiq:chaskiq:*:*:*:*:*:*:*:*",
"ramank775=chat-server":"cpe:2.3:a:chat_server_project:chat_server:*:*:*:*:*:*:*:*",
"chatter-social=Creeper":"cpe:2.3:a:chatter-social:creeper:*:*:*:*:*:*:*:*",
"chatwoot=chatwoot/chatwoot":"cpe:2.3:a:chatwoot:chatwoot:*:*:*:*:*:*:*:*",
"check-spelling=check-spelling":"cpe:2.3:a:check-spelling:check-spelling:*:*:*:*:*:*:*:*",
"Checkbox=Survey":"cpe:2.3:a:checkbox:survey:*:*:*:*:*:*:*:*",
"checkpoint=Harmony Endpoint.":"cpe:2.3:a:checkpoint:endpoint_security:*:*:*:*:*:*:*:*",
"Check Point=Check Point IPsec VPN":"cpe:2.3:a:checkpoint:ipsec_vpn:*:*:*:*:*:*:*:*",
"Check Point Software Technologies Ltd.=ZoneAlarm":"cpe:2.3:a:checkpoint:zonealarm:*:*:*:*:*:*:*:*",
"Progress Software Corporation=Chef Automate":"cpe:2.3:a:chef:automate:*:*:*:*:*:*:*:*",
"Progress Software Corporation=Chef InSpec":"cpe:2.3:a:chef:inspec:*:*:*:*:*:*:*:*",
"Cherokee Webserver=Latest Cherokee Web server":"cpe:2.3:a:cherokee-project:cherokee_web_server:*:*:*:*:*:*:*:*",
"chiefonboarding=chiefonboarding/chiefonboarding":"cpe:2.3:a:chiefonboarding:chiefonboarding:*:*:*:*:*:*:*:*",
"Chain Sea Information Integration Co., Ltd=ai chatbot system":"cpe:2.3:a:chinasea:qb_smart_service_robot:*:*:*:*:*:*:*:*",
"Chitora soft=Installer of Lhaz":"cpe:2.3:a:chitora:lhaz:*:*:*:*:*:*:*:*",
"Chitora soft=Self-extracting archive files created by Lhaz":"cpe:2.3:a:chitora:lhaz:*:*:*:*:*:*:*:*",
"Chitora soft=Installer of Lhaz+":"cpe:2.3:a:chitora:lhaz\+:*:*:*:*:*:*:*:*",
"Chitora soft=Self-extracting archive files created by Lhaz+":"cpe:2.3:a:chitora:lhaz\+:*:*:*:*:*:*:*:*",
"chocolatey=boxstarter":"cpe:2.3:a:chocolatey:boxstarter:*:*:*:*:*:*:*:*",
"chromiumembedded=cef":"cpe:2.3:a:chromiumembedded:chromium_embedded_framework:*:*:*:*:*:*:*:*",
"SourceCodester=Church Management System":"cpe:2.3:a:church_management_system_project:church_management_system:*:*:*:*:*:*:*:*",
"cilium=cilium-cli":"cpe:2.3:a:cilium:cilium-cli:*:*:*:*:*:*:*:*",
"cilium=cilium":"cpe:2.3:a:cilium:cilium:*:*:*:*:*:*:*:*",
"CircuitVerse=CircuitVerse":"cpe:2.3:a:circuitverse:circuitverse:*:*:*:*:*:*:*:*",
"Cires21=C21 Live Encoder and Live Mosaic":"cpe:2.3:a:cires21:live_encoder:*:*:*:*:*:*:*:*",
"Cisco=Cisco ACI Multi-Site Orchestrator Software ":"cpe:2.3:a:cisco:aci_multi-site_orchestrator:*:*:*:*:*:*:*:*",
"Cisco=Cisco Adaptive Security Appliance (ASA) Software ":"cpe:2.3:a:cisco:adaptive_security_appliance_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Adaptive Security Appliance (ASA) Software ":"cpe:2.3:a:cisco:adaptive_security_device_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Adaptive Security Device Manager (ASDM) ":"cpe:2.3:a:cisco:adaptive_security_device_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Aironet Access Point Software ":"cpe:2.3:a:cisco:aironet_access_point_software:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=AnyConnect WebLaunch":"cpe:2.3:a:cisco:anyconnect_secure_mobility_client:*:*:*:*:*:*:*:*",
"Cisco=Cisco AnyConnect Secure Mobility Client ":"cpe:2.3:a:cisco:anyconnect_secure_mobility_client:*:*:*:*:*:*:*:*",
"Cisco=Cisco AppDynamics":"cpe:2.3:a:cisco:appdynamics:*:*:*:*:*:*:*:*",
"Cisco=Cisco AppDynamics ":"cpe:2.3:a:cisco:appdynamics_controller:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Application Policy Infrastructure Controller (APIC)":"cpe:2.3:a:cisco:application_policy_infrastructure_controller:*:*:*:*:*:*:*:*",
"Cisco=Cisco Application Policy Infrastructure Controller (APIC)":"cpe:2.3:a:cisco:application_policy_infrastructure_controller:*:*:*:*:*:*:*:*",
"Cisco=Cisco Application Policy Infrastructure Controller (APIC) ":"cpe:2.3:a:cisco:application_policy_infrastructure_controller:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Digital Network Architecture (DNA) Center":"cpe:2.3:a:cisco:application_policy_infrastructure_controller_enterprise_module:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=StarOS for ASR 5000 Series Aggregated Services Routers":"cpe:2.3:a:cisco:asr_5000_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco ASR 900 Series Aggregation Services Routers ":"cpe:2.3:a:cisco:asr_900_series_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco BroadWorks ":"cpe:2.3:a:cisco:broadworks:*:*:*:*:*:*:*:*",
"Cisco=Cisco BroadWorks ":"cpe:2.3:a:cisco:broadworks_application_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco BroadWorks":"cpe:2.3:a:cisco:broadworks_commpilot_application:*:*:*:*:*:*:*:*",
"Cisco=Cisco BroadWorks ":"cpe:2.3:a:cisco:broadworks_commpilot_application_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco BroadWorks":"cpe:2.3:a:cisco:broadworks_messaging_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco BroadWorks ":"cpe:2.3:a:cisco:broadworks_messaging_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Business Process Automation (BPA) ":"cpe:2.3:a:cisco:business_process_automation:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage":"cpe:2.3:a:cisco:catalyst_sd-wan_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage ":"cpe:2.3:a:cisco:catalyst_sd-wan_manager:*:*:*:*:*:*:*:*",
"OpenIDC=cjose":"cpe:2.3:a:cisco:cjose:*:*:*:*:*:*:*:*",
"Cisco=Cisco Cloud Web Security ":"cpe:2.3:a:cisco:cloud_web_security:*:*:*:*:*:*:*:*",
"Cisco=Cisco Common Services Platform Collector Software ":"cpe:2.3:a:cisco:common_services_platform_collector:*:*:*:*:*:*:*:*",
"Cisco=Cisco Connected Mobile Experiences ":"cpe:2.3:a:cisco:connected_mobile_experiences:*:*:*:*:*:*:*:*",
"Cisco=Cisco Content Security Management Appliance (SMA) ":"cpe:2.3:a:cisco:content_security_management_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco CX Cloud Agent":"cpe:2.3:a:cisco:cx_cloud_agent:*:*:*:*:*:*:*:*",
"Cisco=Cisco Cyber Vision ":"cpe:2.3:a:cisco:cyber_vision_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Data Center Analytics Framework ":"cpe:2.3:a:cisco:data_center_analytics_framework:*:*:*:*:*:*:*:*",
"Cisco=Cisco Data Center Network Manager ":"cpe:2.3:a:cisco:data_center_network_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Digital Network Architecture Center (DNA Center) ":"cpe:2.3:a:cisco:digital_network_architecture_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Digital Network Architecture Center (DNA Center)":"cpe:2.3:a:cisco:dna_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Digital Network Architecture Center (DNA Center) ":"cpe:2.3:a:cisco:dna_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco DNA Spaces ":"cpe:2.3:a:cisco:dna_spaces\:*:*:*:*:*:*:*:*",
"Cisco=Cisco DNA Spaces Connector ":"cpe:2.3:a:cisco:dna_spaces\:*:*:*:*:*:*:*:*",
"Cisco=Duo Authentication for Windows Logon and RDP":"cpe:2.3:a:cisco:duo_authentication_for_windows_logon_and_rdp:*:*:*:*:*:*:*:*",
"Cisco=Duo Network Gateway (DNG) ":"cpe:2.3:a:cisco:duo_network_gateway:*:*:*:*:*:*:*:*",
"Duo Security=Duo Network Gateway":"cpe:2.3:a:cisco:duo_network_gateway:*:*:*:*:*:*:*:*",
"Cisco=Cisco Edge Fog Fabric ":"cpe:2.3:a:cisco:edge_fog_fabric:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Elastic Services Controller":"cpe:2.3:a:cisco:elastic_services_controller:*:*:*:*:*:*:*:*",
"Cisco=Cisco Elastic Services Controller ":"cpe:2.3:a:cisco:elastic_services_controller:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Email Security Appliance (ESA)":"cpe:2.3:a:cisco:email_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Email Security Appliance (ESA)":"cpe:2.3:a:cisco:email_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Email Security Appliance (ESA) ":"cpe:2.3:a:cisco:email_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Secure Email":"cpe:2.3:a:cisco:email_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Emergency Responder":"cpe:2.3:a:cisco:emergency_responder:*:*:*:*:*:*:*:*",
"Cisco=Cisco Emergency Responder ":"cpe:2.3:a:cisco:emergency_responder:*:*:*:*:*:*:*:*",
"Cisco=Cisco Energy Management Suite ":"cpe:2.3:a:cisco:energy_management_suite_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise Chat and Email":"cpe:2.3:a:cisco:enterprise_chat_and_email:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise Chat and Email ":"cpe:2.3:a:cisco:enterprise_chat_and_email:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software ":"cpe:2.3:a:cisco:enterprise_network_function_virtualization_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=NA":"cpe:2.3:a:cisco:enterprise_network_function_virtualization_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software ":"cpe:2.3:a:cisco:enterprise_network_function_virtualization_infrastructure_sofware:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software ":"cpe:2.3:a:cisco:enterprise_network_functions_virtualization_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software ":"cpe:2.3:a:cisco:enterprise_network_virtualization_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software":"cpe:2.3:a:cisco:enterprise_nfv_infrastructure_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software ":"cpe:2.3:a:cisco:enterprise_nfv_infrastructure_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Evolved Programmable Network Manager (EPNM) ":"cpe:2.3:a:cisco:evolved_programmable_network_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco TelePresence Video Communication Server (VCS) Expressway":"cpe:2.3:a:cisco:expressway:*:*:*:*:*:*:*:*",
"Cisco=Cisco Finesse ":"cpe:2.3:a:cisco:finesse:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Contact Center Express ":"cpe:2.3:a:cisco:finesse:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Threat Defense Software ":"cpe:2.3:a:cisco:firepower_device_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Threat Defense Software ":"cpe:2.3:a:cisco:firepower_device_manager_on-box:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Management Center":"cpe:2.3:a:cisco:firepower_management_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Management Center ":"cpe:2.3:a:cisco:firepower_management_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Management Center 6.1.0":"cpe:2.3:a:cisco:firepower_management_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Threat Defense (FTD) Software ":"cpe:2.3:a:cisco:firepower_management_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Threat Defense Software ":"cpe:2.3:a:cisco:firepower_management_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco FireSIGHT System Software ":"cpe:2.3:a:cisco:firepower_system_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Management Center ":"cpe:2.3:a:cisco:firepower_system_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco FireSIGHT System Software ":"cpe:2.3:a:cisco:firepower_threat_defense:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Threat Defense Software":"cpe:2.3:a:cisco:firepower_threat_defense:*:*:*:*:*:*:*:*",
"Cisco=Cisco Firepower Threat Defense Software ":"cpe:2.3:a:cisco:firepower_threat_defense:*:*:*:*:*:*:*:*",
"Cisco=Cisco Hosted Collaboration Mediation Fulfillment ":"cpe:2.3:a:cisco:hosted_collaboration_mediation_fulfillment:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Communications Domain Manager ":"cpe:2.3:a:cisco:hosted_collaboration_solution:*:*:*:*:*:*:*:*",
"Cisco=Cisco HyperFlex HX Data Platform ":"cpe:2.3:a:cisco:hyperflex_hx-series_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco HyperFlex HX Data Platform ":"cpe:2.3:a:cisco:hyperflex_hx_data_platform:*:*:*:*:*:*:*:*",
"Cisco=Cisco HyperFlex HX-Series ":"cpe:2.3:a:cisco:hyperflex_hx_data_platform:*:*:*:*:*:*:*:*",
"Cisco=Cisco Identity Services Engine Software":"cpe:2.3:a:cisco:identity_services_engine:*:*:*:*:*:*:*:*",
"Cisco=Cisco Identity Services Engine Software ":"cpe:2.3:a:cisco:identity_services_engine:*:*:*:*:*:*:*:*",
"Cisco=Cisco Identity Services Engine Software ":"cpe:2.3:a:cisco:identity_services_engine_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Industrial Network Director":"cpe:2.3:a:cisco:industrial_network_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco Industrial Network Director ":"cpe:2.3:a:cisco:industrial_network_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco Integrated Management Controller (IMC) Supervisor ":"cpe:2.3:a:cisco:integrated_management_controller:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Computing System (Standalone) ":"cpe:2.3:a:cisco:integrated_management_controller:*:*:*:*:*:*:*:*",
"Cisco=Cisco Intersight Virtual Appliance":"cpe:2.3:a:cisco:intersight_private_virtual_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Intersight Virtual Appliance ":"cpe:2.3:a:cisco:intersight_virtual_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco IoT Control Center ":"cpe:2.3:a:cisco:iot_control_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco IoT Field Network Director (IoT-FND) ":"cpe:2.3:a:cisco:iot_field_network_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOx ":"cpe:2.3:a:cisco:iox:*:*:*:*:*:*:*:*",
"Cisco=Cisco Jabber Guest":"cpe:2.3:a:cisco:jabber_guest:*:*:*:*:*:*:*:*",
"Cisco=Cisco Managed Services Accelerator ":"cpe:2.3:a:cisco:managed_services_accelerator:*:*:*:*:*:*:*:*",
"Cisco=Cisco Directory Connector ":"cpe:2.3:a:cisco:meeting_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Meeting App ":"cpe:2.3:a:cisco:meeting_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Meeting Server":"cpe:2.3:a:cisco:meeting_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Meeting Server ":"cpe:2.3:a:cisco:meeting_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Modeling Labs ":"cpe:2.3:a:cisco:modeling_labs:*:*:*:*:*:*:*:*",
"Cisco=Cisco Network Assurance Engine ":"cpe:2.3:a:cisco:network_assurance_engine:*:*:*:*:*:*:*:*",
"Cisco=Cisco Enterprise NFV Infrastructure Software ":"cpe:2.3:a:cisco:network_functions_virtualization_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=Cisco Industrial Network Director ":"cpe:2.3:a:cisco:network_level_service:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Network Registrar ":"cpe:2.3:a:cisco:network_registrar:*:*:*:*:*:*:*:*",
"Cisco=Cisco Network Services Orchestrator":"cpe:2.3:a:cisco:network_services_orchestrator:*:*:*:*:*:*:*:*",
"Cisco=Cisco Network Services Orchestrator ":"cpe:2.3:a:cisco:network_services_orchestrator:*:*:*:*:*:*:*:*",
"Cisco=Cisco Nexus Dashboard":"cpe:2.3:a:cisco:nexus_dashboard:*:*:*:*:*:*:*:*",
"Cisco=Cisco Nexus Dashboard ":"cpe:2.3:a:cisco:nexus_dashboard:*:*:*:*:*:*:*:*",
"Cisco=Cisco Nexus Data Broker ":"cpe:2.3:a:cisco:nexus_data_broker:*:*:*:*:*:*:*:*",
"Cisco=Cisco Nexus Insights ":"cpe:2.3:a:cisco:nexus_insights:*:*:*:*:*:*:*:*",
"Cisco=Cisco Orbital ":"cpe:2.3:a:cisco:orbital:*:*:*:*:*:*:*:*",
"Cisco=Cisco Packaged Contact Center Enterprise ":"cpe:2.3:a:cisco:packaged_contact_center_enterprise:*:*:*:*:*:*:*:*",
"Cisco=Cisco Packet Tracer ":"cpe:2.3:a:cisco:packet_tracer:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Policy Suite":"cpe:2.3:a:cisco:policy_suite:*:*:*:*:*:*:*:*",
"Cisco=Cisco Policy Suite (CPS) Software ":"cpe:2.3:a:cisco:policy_suite:*:*:*:*:*:*:*:*",
"Cisco=Cisco Policy Suite (CPS) Software ":"cpe:2.3:a:cisco:policy_suite_for_mobile:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Access Registrar ":"cpe:2.3:a:cisco:prime_access_registrar:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Collaboration Assurance ":"cpe:2.3:a:cisco:prime_collaboration:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Collaboration Provisioning ":"cpe:2.3:a:cisco:prime_collaboration:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Collaboration Assurance ":"cpe:2.3:a:cisco:prime_collaboration_assurance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Collaboration Provisioning ":"cpe:2.3:a:cisco:prime_collaboration_provisioning:*:*:*:*:*:*:*:*",
"Cisco=Cisco Data Center Network Manager ":"cpe:2.3:a:cisco:prime_data_center_network_manager:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Prime Infrastructure":"cpe:2.3:a:cisco:prime_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Infrastructure ":"cpe:2.3:a:cisco:prime_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Network Control System ":"cpe:2.3:a:cisco:prime_infrastructure:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime License Manager ":"cpe:2.3:a:cisco:prime_license_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Network Registrar ":"cpe:2.3:a:cisco:prime_network_registrar:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Service Catalog ":"cpe:2.3:a:cisco:prime_service_catalog:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Registered Envelope Service":"cpe:2.3:a:cisco:registered_envelope_service:*:*:*:*:*:*:*:*",
"Cisco=Cisco Registered Envelope Service ":"cpe:2.3:a:cisco:registered_envelope_service:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE SD-WAN Software ":"cpe:2.3:a:cisco:sd-wan:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN Solution ":"cpe:2.3:a:cisco:sd-wan:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage":"cpe:2.3:a:cisco:sd-wan:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage ":"cpe:2.3:a:cisco:sd-wan:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage":"cpe:2.3:a:cisco:sd-wan_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage":"cpe:2.3:a:cisco:sd-wan_vmanage:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage ":"cpe:2.3:a:cisco:sd-wan_vmanage:*:*:*:*:*:*:*:*",
"Cisco=Cisco Secure Network Analytics ":"cpe:2.3:a:cisco:secure_network_analytics:*:*:*:*:*:*:*:*",
"Cisco=Cisco Stealthwatch Enterprise ":"cpe:2.3:a:cisco:secure_network_analytics:*:*:*:*:*:*:*:*",
"Cisco=Cisco Secure Workload":"cpe:2.3:a:cisco:secure_workload:*:*:*:*:*:*:*:*",
"Cisco=Cisco Security Manager ":"cpe:2.3:a:cisco:security_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Security Manager  ":"cpe:2.3:a:cisco:security_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Smart Software Manager On-Prem ":"cpe:2.3:a:cisco:smart_software_manager_on-prem:*:*:*:*:*:*:*:*",
"Cisco=Cisco Smart Software Manager On-Prem ":"cpe:2.3:a:cisco:smart_software_manager_satellite:*:*:*:*:*:*:*:*",
"Cisco=Cisco SocialMiner ":"cpe:2.3:a:cisco:socialminer:*:*:*:*:*:*:*:*",
"Cisco=Cisco Stealthwatch Enterprise ":"cpe:2.3:a:cisco:stealthwatch_enterprise:*:*:*:*:*:*:*:*",
"Cisco=Cisco TelePresence Advanced Media Gateway":"cpe:2.3:a:cisco:telepresence_advanced_media_gateway:*:*:*:*:*:*:*:*",
"Cisco=Cisco RoomOS Software ":"cpe:2.3:a:cisco:telepresence_collaboration_endpoint:*:*:*:*:*:*:*:*",
"Cisco=Cisco TelePresence Endpoint Software (TC/CE) ":"cpe:2.3:a:cisco:telepresence_collaboration_endpoint:*:*:*:*:*:*:*:*",
"Cisco=Cisco TelePresence TC Software ":"cpe:2.3:a:cisco:telepresence_collaboration_endpoint:*:*:*:*:*:*:*:*",
"Cisco=Cisco TelePresence Management Suite (TMS) ":"cpe:2.3:a:cisco:telepresence_management_suite:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=TelePresence Video Communication Server (VCS)":"cpe:2.3:a:cisco:telepresence_video_communication_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Expressway ":"cpe:2.3:a:cisco:telepresence_video_communication_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco TelePresence Video Communication Server (VCS) ":"cpe:2.3:a:cisco:telepresence_video_communication_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Secure Workload ":"cpe:2.3:a:cisco:tetration:*:*:*:*:*:*:*:*",
"Cisco=Cisco Tetration Analytics ":"cpe:2.3:a:cisco:tetration_analytics:*:*:*:*:*:*:*:*",
"Cisco=Cisco ThousandEyes Recorder Application":"cpe:2.3:a:cisco:thousandeyes_enterprise_agent:*:*:*:*:*:*:*:*",
"Cisco=Cisco ThousandEyes Recorder Application ":"cpe:2.3:a:cisco:thousandeyes_recorder:*:*:*:*:*:*:*:*",
"Cisco=Cisco UCS Director ":"cpe:2.3:a:cisco:ucs_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Computing System (Management Software) ":"cpe:2.3:a:cisco:ucs_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Computing System Director ":"cpe:2.3:a:cisco:ucs_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco Ultra Cloud Core - Subscriber Microservices Infrastructure ":"cpe:2.3:a:cisco:ultra_cloud_core_-_subscriber_microservices_infrastructure:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Ultra Services Framework":"cpe:2.3:a:cisco:ultra_services_framework:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Ultra Services Platform":"cpe:2.3:a:cisco:ultra_services_platform:*:*:*:*:*:*:*:*",
"Cisco=Cisco Umbrella":"cpe:2.3:a:cisco:umbrella:*:*:*:*:*:*:*:*",
"Cisco=Cisco Umbrella ":"cpe:2.3:a:cisco:umbrella:*:*:*:*:*:*:*:*",
"Cisco=Cisco Umbrella Dashboard":"cpe:2.3:a:cisco:umbrella:*:*:*:*:*:*:*:*",
"Cisco=Cisco Umbrella Insights Virtual Appliance ":"cpe:2.3:a:cisco:umbrella:*:*:*:*:*:*:*:*",
"Cisco=Cisco Umbrella Insights Virtual Appliance ":"cpe:2.3:a:cisco:umbrella_secure_web_gateway:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Unified Communications Manager":"cpe:2.3:a:cisco:unified_communications_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Communications Manager ":"cpe:2.3:a:cisco:unified_communications_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unity Connection ":"cpe:2.3:a:cisco:unified_communications_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Communications Manager IM and Presence Service":"cpe:2.3:a:cisco:unified_communications_manager_im_and_presence_service:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Communications Manager IM and Presence Service ":"cpe:2.3:a:cisco:unified_communications_manager_im_and_presence_service:*:*:*:*:*:*:*:*",
"Cisco=Cisco UCS B-Series Blade Server Software ":"cpe:2.3:a:cisco:unified_computing_system:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Computing System E-Series Software (UCSE) ":"cpe:2.3:a:cisco:unified_computing_system:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Computing System Central Software ":"cpe:2.3:a:cisco:unified_computing_system_central_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Contact Center Enterprise ":"cpe:2.3:a:cisco:unified_contact_center_enterprise:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Contact Center Express":"cpe:2.3:a:cisco:unified_contact_center_express:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Contact Center Express ":"cpe:2.3:a:cisco:unified_contact_center_express:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Customer Voice Portal (CVP) ":"cpe:2.3:a:cisco:unified_customer_voice_portal:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified IP Interactive Voice Response (IVR) ":"cpe:2.3:a:cisco:unified_customer_voice_portal:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Intelligence Center ":"cpe:2.3:a:cisco:unified_intelligence_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unity Connection":"cpe:2.3:a:cisco:unity_connection:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unity Connection ":"cpe:2.3:a:cisco:unity_connection:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unity Express ":"cpe:2.3:a:cisco:unity_express:*:*:*:*:*:*:*:*",
"Cisco=Cisco Video Surveillance Manager ":"cpe:2.3:a:cisco:video_surveillance_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Video Surveillance Media Server Software ":"cpe:2.3:a:cisco:video_surveillance_media_server:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Virtual Network Function (VNF) Element Manager":"cpe:2.3:a:cisco:virtual_network_function_element_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Virtualized Infrastructure Manager ":"cpe:2.3:a:cisco:virtualized_infrastructure_manager:*:*:*:*:*:*:*:*",
"Cisco=Cisco Virtualized Voice Browser ":"cpe:2.3:a:cisco:virtualized_voice_browser:*:*:*:*:*:*:*:*",
"Cisco=Cisco Vision Dynamic Signage Director ":"cpe:2.3:a:cisco:vision_dynamic_signage_director:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN Solution ":"cpe:2.3:a:cisco:vsmart_controller:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=AsyncOS Software for Cisco Web Security Appliances":"cpe:2.3:a:cisco:web_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Web Security Appliance (WSA)":"cpe:2.3:a:cisco:web_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Web Security Appliance (WSA) ":"cpe:2.3:a:cisco:web_security_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco Web Security Appliance (WSA) ":"cpe:2.3:a:cisco:web_security_virtual_appliance:*:*:*:*:*:*:*:*",
"Cisco=Cisco WebEx Meeting Center ":"cpe:2.3:a:cisco:webex_business_suite:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Business Suite":"cpe:2.3:a:cisco:webex_business_suite_39:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings":"cpe:2.3:a:cisco:webex_meetings:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings ":"cpe:2.3:a:cisco:webex_meetings:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Network Recording Player ":"cpe:2.3:a:cisco:webex_meetings:*:*:*:*:*:*:*:*",
"Cisco=Cisco WebEx WRF Player ":"cpe:2.3:a:cisco:webex_meetings_online:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings":"cpe:2.3:a:cisco:webex_meetings_online:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings ":"cpe:2.3:a:cisco:webex_meetings_online:*:*:*:*:*:*:*:*",
"Cisco=Cisco WebEx Meetings Server ":"cpe:2.3:a:cisco:webex_meetings_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings Multimedia Viewer":"cpe:2.3:a:cisco:webex_meetings_server:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings Desktop App ":"cpe:2.3:a:cisco:webex_teams:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Teams ":"cpe:2.3:a:cisco:webex_teams:*:*:*:*:*:*:*:*",
"Cisco=Cisco Webex Meetings ":"cpe:2.3:a:cisco:webex_training:*:*:*:*:*:*:*:*",
"Cisco=Cisco Wide Area Application Services (WAAS) ":"cpe:2.3:a:cisco:wide_area_application_services:*:*:*:*:*:*:*:*",
"Cisco=Cisco Wireless LAN Controller (WLC) ":"cpe:2.3:a:cisco:wireless_lan_controller_software:*:*:*:*:*:*:*:*",
"Citadel=Citadel":"cpe:2.3:a:citadel:citadel:*:*:*:*:*:*:*:*",
"Citrix=Citrix Application Delivery Management (Citrix ADM)":"cpe:2.3:a:citrix:application_delivery_management:*:*:*:*:*:*:*:*",
"Citrix=Federated Authentication Service (FAS)":"cpe:2.3:a:citrix:federated_authentication_service:*:*:*:*:*:*:*:*",
"Citrix=Citrix ShareFile Storage Zones Controller":"cpe:2.3:a:citrix:sharefile_storage_zones_controller:*:*:*:*:*:*:*:*",
"Cityboss=E-municipality":"cpe:2.3:a:cityboss:e-municipality:*:*:*:*:*:*:*:*",
"cjvnjde=google-translate-api-browser":"cpe:2.3:a:cjvnjde:google_translate_api_browser:*:*:*:*:*:*:*:*",
"ckeditor=ckeditor5":"cpe:2.3:a:ckeditor:ckeditor5:*:*:*:*:*:*:*:*",
"ckeditor=ckeditor4":"cpe:2.3:a:ckeditor:ckeditor:*:*:*:*:*:*:*:*",
"Cisco=Clam AntiVirus (ClamAV)":"cpe:2.3:a:clamav:clamav:*:*:*:*:*:*:*:*",
"Cisco=ClamAV":"cpe:2.3:a:clamav:clamav:*:*:*:*:*:*:*:*",
"Claroty=Secure Remote Access (SRA) Site":"cpe:2.3:a:claroty:secure_remote_access:*:*:*:*:*:*:*:*",
"SourceCodester=Class and Exam Timetabling System":"cpe:2.3:a:class_and_exam_timetabling_system_project:class_and_exam_timetabling_system:*:*:*:*:*:*:*:*",
"SourceCodester=Class Scheduling System":"cpe:2.3:a:class_scheduling_system_project:class_scheduling_system:*:*:*:*:*:*:*:*",
"clastix=capsule-proxy":"cpe:2.3:a:clastix:capsule-proxy:*:*:*:*:*:*:*:*",
"projectcapsule=capsule-proxy":"cpe:2.3:a:clastix:capsule-proxy:*:*:*:*:*:*:*:*",
"clastix=capsule":"cpe:2.3:a:clastix:capsule:*:*:*:*:*:*:*:*",
"Allegro.AI=ClearML":"cpe:2.3:a:clear:clearml:*:*:*:*:*:*:*:*",
"allegroai=allegroai/clearml-server":"cpe:2.3:a:clear:clearml_server:*:*:*:*:*:*:*:*",
"Clever=saml2-js":"cpe:2.3:a:clever:saml2-js:*:*:*:*:*:*:*:*",
"GoPlace!=Click Ranker":"cpe:2.3:a:click-ranker:click_ranker:*:*:*:*:*:*:*:*",
"SourceCodester=Clinics Patient Management System":"cpe:2.3:a:clinic\'s_patient_management_system_project:clinic\'s_patient_management_system:*:*:*:*:*:*:*:*",
"clinical-genomics=clinical-genomics/scout":"cpe:2.3:a:clinical-genomics:scout:*:*:*:*:*:*:*:*",
"SourceCodester=Clinics Patient Management System":"cpe:2.3:a:clinics_patient_management_system_project:clinics_patient_management_system:*:*:*:*:*:*:*:*",
"ClipSoft=REXPERT":"cpe:2.3:a:clipsoft:rexpert:*:*:*:*:*:*:*:*",
"koush=scrypted":"cpe:2.3:a:clockworkmod:scrypted:*:*:*:*:*:*:*:*",
"Cloud Foundry=Bits Service Release":"cpe:2.3:a:cloud_foundry:bits_service:*:*:*:*:*:*:*:*",
"Cloud Foundry=BOSH":"cpe:2.3:a:cloud_foundry:bosh:*:*:*:*:*:*:*:*",
"Cloud Foundry=BOSH System Metrics Server":"cpe:2.3:a:cloud_foundry:bosh_system_metrics_server:*:*:*:*:*:*:*:*",
"Cloud Foundry= CF Networking Release":"cpe:2.3:a:cloud_foundry:cf-networking:*:*:*:*:*:*:*:*",
"Cloudflare=cloudflared":"cpe:2.3:a:cloudflare:cloudflared:*:*:*:*:*:*:*:*",
"Cloudflare=goflow":"cpe:2.3:a:cloudflare:goflow:*:*:*:*:*:*:*:*",
"Cloudflare=lua-resty-json":"cpe:2.3:a:cloudflare:lua-resty-json:*:*:*:*:*:*:*:*",
"Cloudflare=OctoRPKI":"cpe:2.3:a:cloudflare:octorpki:*:*:*:*:*:*:*:*",
"Cloudflare=quiche":"cpe:2.3:a:cloudflare:quiche:*:*:*:*:*:*:*:*",
"Cloudflare=zlib":"cpe:2.3:a:cloudflare:zlib:*:*:*:*:*:*:*:*",
"code.cloudfoundry.org/archiver=code.cloudfoundry.org/archiver/extractor":"cpe:2.3:a:cloudfoundry:archiver:*:*:*:*:*:*:*:*",
"Cloud Foundry=BOSH Backup and Restore":"cpe:2.3:a:cloudfoundry:bosh_backup_and_restore:*:*:*:*:*:*:*:*",
"Cloud Foundry=CAPI":"cpe:2.3:a:cloudfoundry:capi-release:*:*:*:*:*:*:*:*",
"Cloud Foundry=CAPI-release":"cpe:2.3:a:cloudfoundry:capi-release:*:*:*:*:*:*:*:*",
"Cloud Foundry=CF CLI":"cpe:2.3:a:cloudfoundry:command_line_interface:*:*:*:*:*:*:*:*",
"Cloud Foundry=Cloud Foundry Container Runtime (CFCR)":"cpe:2.3:a:cloudfoundry:container_runtime:*:*:*:*:*:*:*:*",
"Cloud Foundry=CredHub CLI":"cpe:2.3:a:cloudfoundry:credhub_cli:*:*:*:*:*:*:*:*",
"Cloud Foundry=Garden-runC":"cpe:2.3:a:cloudfoundry:garden-runc:*:*:*:*:*:*:*:*",
"Cloud Foundry=Loggregator":"cpe:2.3:a:cloudfoundry:loggregator:*:*:*:*:*:*:*:*",
"Cloud Foundry=CF Routing":"cpe:2.3:a:cloudfoundry:routing_release:*:*:*:*:*:*:*:*",
"Cloud Foundry=Routing":"cpe:2.3:a:cloudfoundry:routing_release:*:*:*:*:*:*:*:*",
"Cloud Foundry=Stratos":"cpe:2.3:a:cloudfoundry:stratos:*:*:*:*:*:*:*:*",
"Cloud Foundry=UAA Release (OSS)":"cpe:2.3:a:cloudfoundry:uaa_release:*:*:*:*:*:*:*:*",
"Cloud Foundry=UAA Release (OSS)":"cpe:2.3:a:cloudfoundry:user_account_and_authentication:*:*:*:*:*:*:*:*",
"Cloudlinux OS=cagefs":"cpe:2.3:a:cloudlinux:cagefs:*:*:*:*:*:*:*:*",
"CloudLinux Inc=Imunify360":"cpe:2.3:a:cloudlinux:imunify360:*:*:*:*:*:*:*:*",
"Cloudreve=Cloudreve":"cpe:2.3:a:cloudreve:cloudreve:*:*:*:*:*:*:*:*",
"Cloudways=Breeze (WordPress plugin)":"cpe:2.3:a:cloudways:breeze:*:*:*:*:*:*:*:*",
"unspecified=CLTPHP":"cpe:2.3:a:cltphp:cltphp:*:*:*:*:*:*:*:*",
"ClusterLabs=pcs":"cpe:2.3:a:clusterlabs:pcs:*:*:*:*:*:*:*:*",
"clusternet=clusternet":"cpe:2.3:a:clusternet:clusternet:*:*:*:*:*:*:*:*",
"CMONOS Co. Ltd.=CMONOS.JP":"cpe:2.3:a:cmonos:cmonos:*:*:*:*:*:*:*:*",
"crossplane=crossplane":"cpe:2.3:a:cncf:crossplane:*:*:*:*:*:*:*:*",
"Cnesty=Helpcom":"cpe:2.3:a:cnesty:helpcom:*:*:*:*:*:*:*:*",
"cnoa=OA":"cpe:2.3:a:cnoa_oa_project:cnoa_oa:*:*:*:*:*:*:*:*",
"cocos=cocos-engine":"cpe:2.3:a:cocos:cocos-engine:*:*:*:*:*:*:*:*",
"code-projects=Automated Voting System":"cpe:2.3:a:code-projects:automated_voting_system:*:*:*:*:*:*:*:*",
"code-projects=Client Details System":"cpe:2.3:a:code-projects:client_details_system:*:*:*:*:*:*:*:*",
"code-projects=Dormitory Management System":"cpe:2.3:a:code-projects:dormitory_management_system:*:*:*:*:*:*:*:*",
"code-projects=Employee Profile Management System":"cpe:2.3:a:code-projects:employee_profile_management_system:*:*:*:*:*:*:*:*",
"code-projects=Fighting Cock Information System":"cpe:2.3:a:code-projects:fighting_cock_information_system:*:*:*:*:*:*:*:*",
"code-projects=Human Resource Integrated System":"cpe:2.3:a:code-projects:human_resource_integrated_system:*:*:*:*:*:*:*:*",
"code-projects=Online Faculty Clearance":"cpe:2.3:a:code-projects:online_faculty_clearance:*:*:*:*:*:*:*:*",
"code-projects=Point of Sales and Inventory Management System":"cpe:2.3:a:code-projects:point_of_sales_and_inventory_management_system:*:*:*:*:*:*:*:*",
"code-projects=QR Code Generator":"cpe:2.3:a:code-projects:qr_code_generator:*:*:*:*:*:*:*:*",
"code-projects=Record Management System":"cpe:2.3:a:code-projects:record_management_system:*:*:*:*:*:*:*:*",
"code-projects=Simple Art Gallery":"cpe:2.3:a:code-projects:simple_art_gallery:*:*:*:*:*:*:*:*",
"code-projects=Simple Online Hotel Reservation System":"cpe:2.3:a:code-projects:simple_online_hotel_reservation_system:*:*:*:*:*:*:*:*",
"code-projects=Social Networking Site":"cpe:2.3:a:code-projects:social_networking_site:*:*:*:*:*:*:*:*",
"code-projects=Voting System":"cpe:2.3:a:code-projects:voting_system:*:*:*:*:*:*:*:*",
"CodeAstro=Internet Banking System":"cpe:2.3:a:codeastro:internet_banking_system:*:*:*:*:*:*:*:*",
"CodeAstro=Online Food Ordering System":"cpe:2.3:a:codeastro:online_food_ordering_system:*:*:*:*:*:*:*:*",
"CodeAstro=POS and Inventory Management System":"cpe:2.3:a:codeastro:pos_and_inventory_management_system:*:*:*:*:*:*:*:*",
"CodeAstro=Real Estate Management System":"cpe:2.3:a:codeastro:real_estate_management_system:*:*:*:*:*:*:*:*",
"CodeAstro=Restaurant POS System":"cpe:2.3:a:codeastro:restaurant_pos_system:*:*:*:*:*:*:*:*",
"CodeAstro=Simple Banking System":"cpe:2.3:a:codeastro:simple_banking_system:*:*:*:*:*:*:*:*",
"CodeAstro=University Management System":"cpe:2.3:a:codeastro:university_management_system:*:*:*:*:*:*:*:*",
"codehaus-plexus=plexus-archiver":"cpe:2.3:a:codehaus-plexus:plexus-archiver:*:*:*:*:*:*:*:*",
"codeigniter4=CodeIgniter4":"cpe:2.3:a:codeigniter:codeigniter:*:*:*:*:*:*:*:*",
"codelyfe=Stupid Simple CMS":"cpe:2.3:a:codelyfe:stupid_simple_cms:*:*:*:*:*:*:*:*",
"codenotary=immudb":"cpe:2.3:a:codenotary:immudb:*:*:*:*:*:*:*:*",
"csliuwy=coder-chain_gdut":"cpe:2.3:a:coder-chain_gdut_project:coder-chain_gdut:*:*:*:*:*:*:*:*",
"cdr=cdr/code-server":"cpe:2.3:a:coder:code-server:*:*:*:*:*:*:*:*",
"coderd-repos=Eva":"cpe:2.3:a:coderd-repos:eva:*:*:*:*:*:*:*:*",
"CODESYS=CODESYS V2":"cpe:2.3:a:codesys:codesys:*:*:*:*:*:*:*:*",
"CODESYS=CODESYS Development System":"cpe:2.3:a:codesys:development_system:*:*:*:*:*:*:*:*",
"CODESYS=CODESYS Development System V3":"cpe:2.3:a:codesys:development_system_v3:*:*:*:*:*:*:*:*",
"CODESYS=CODESYS Gateway Server V2":"cpe:2.3:a:codesys:gateway:*:*:*:*:*:*:*:*",
"CODESYS=CODESYS Visualization":"cpe:2.3:a:codesys:visualization:*:*:*:*:*:*:*:*",
"Campcodes=Coffee Shop POS System":"cpe:2.3:a:coffee_shop_pos_system_project:coffee_shop_pos_system:*:*:*:*:*:*:*:*",
"Cog-Creators=Red-DiscordBot":"cpe:2.3:a:cogboard:red_discord_bot:*:*:*:*:*:*:*:*",
"Cogites=eReserv":"cpe:2.3:a:cogites:ereserv:*:*:*:*:*:*:*:*",
"Cognex=In-Sight OPC Server":"cpe:2.3:a:cognex:in-sight_opc_server:*:*:*:*:*:*:*:*",
"CollaboraOnline=online":"cpe:2.3:a:collabora:online:*:*:*:*:*:*:*:*",
"CollaboraOnline=online":"cpe:2.3:a:collaboraoffice:collabora_online:*:*:*:*:*:*:*:*",
"unspecified=collective.contact.widget":"cpe:2.3:a:collective.contact.widget_project:collective.contact.widget:*:*:*:*:*:*:*:*",
"collectiveaccess=collectiveaccess/providence":"cpe:2.3:a:collectiveaccess:providence:*:*:*:*:*:*:*:*",
"College Management=College Management System v1.0":"cpe:2.3:a:college_management_system_project:college_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=College Website Management System":"cpe:2.3:a:college_website_management_system_project:college_website_management_system:*:*:*:*:*:*:*:*",
"unspecified=College Website Management System":"cpe:2.3:a:college_website_management_system_project:college_website_management_system:*:*:*:*:*:*:*:*",
"Combodo=iTop":"cpe:2.3:a:combodo:itop:*:*:*:*:*:*:*:*",
"cometbft=cometbft":"cpe:2.3:a:cometbft:cometbft:*:*:*:*:*:*:*:*",
"cometd=cometd":"cpe:2.3:a:cometd:cometd:*:*:*:*:*:*:*:*",
"COMMAX=Wallpad":"cpe:2.3:a:commax:cdp-1020mb_firmware:*:*:*:*:*:*:*:*",
"SourceCodester=Comment System":"cpe:2.3:a:comment_system_project:comment_system:*:*:*:*:*:*:*:*",
"common-workflow-language=cwlviewer":"cpe:2.3:a:commonwl:cwlviewer:*:*:*:*:*:*:*:*",
"Commvault=CommCell":"cpe:2.3:a:commvault:commcell:*:*:*:*:*:*:*:*",
"Comodo=Comodo Antivirus":"cpe:2.3:a:comodo:antivirus:*:*:*:*:*:*:*:*",
"SourceCodester=Company Website CMS":"cpe:2.3:a:company_website\/cms_project:company_website\/cms:*:*:*:*:*:*:*:*",
"SourceCodester=Company Website CMS":"cpe:2.3:a:company_website_cms_project:company_website_cms:*:*:*:*:*:*:*:*",
"SourceCodester=Complaint Management System":"cpe:2.3:a:complaint_management_system_project:complaint_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Computer Parts Sales and Inventory System":"cpe:2.3:a:computer_parts_sales_and_inventory_system_project:computer_parts_sales_and_inventory_system:*:*:*:*:*:*:*:*",
"Concrete CMS =Concrete CMS ":"cpe:2.3:a:concretecms:concrete_cms:*:*:*:*:*:*:*:*",
"Concrete CMS=Concrete CMS":"cpe:2.3:a:concretecms:concrete_cms:*:*:*:*:*:*:*:*",
"Concrete CMS=Concrete CMS ":"cpe:2.3:a:concretecms:concrete_cms:*:*:*:*:*:*:*:*",
"SuperMartijn642=SuperMartijn642sConfigLib":"cpe:2.3:a:config_lib_project:config_lib:*:*:*:*:*:*:*:*",
"ConnectWise=ScreenConnect":"cpe:2.3:a:connectwise:screenconnect:*:*:*:*:*:*:*:*",
"Consensys=gnark":"cpe:2.3:a:consensys:gnark:*:*:*:*:*:*:*:*",
"SourceCodester=Contact Manager App":"cpe:2.3:a:contact_manager_app_project:contact_manager_app:*:*:*:*:*:*:*:*",
"contao=contao":"cpe:2.3:a:contao:contao:*:*:*:*:*:*:*:*",
"Contec Co., Ltd.=CONPROSYS HMI System (CHS)":"cpe:2.3:a:contec:conprosys_hmi_system:*:*:*:*:*:*:*:*",
"Contec=CONPROSYS HMI System":"cpe:2.3:a:contec:conprosys_hmi_system:*:*:*:*:*:*:*:*",
"AlexRed=contentmap":"cpe:2.3:a:contentmap_project:contentmap:*:*:*:*:*:*:*:*",
"sidekiq=sidekiq/sidekiq":"cpe:2.3:a:contribsys:sidekiq:*:*:*:*:*:*:*:*",
"CentOS Web Panel=CentOS Web Panel":"cpe:2.3:a:control-webpanel:webpanel:*:*:*:*:*:*:*:*",
"Control iD=Gerencia Web":"cpe:2.3:a:control_id_panel_project:control_id_panel:*:*:*:*:*:*:*:*",
"Control iD=iDSecure":"cpe:2.3:a:controlid:idsecure:*:*:*:*:*:*:*:*",
"Control iD=RHiD":"cpe:2.3:a:controlid:rhid:*:*:*:*:*:*:*:*",
"convos-chat=convos":"cpe:2.3:a:convos:convos:*:*:*:*:*:*:*:*",
"9001=copyparty":"cpe:2.3:a:copyparty_project:copyparty:*:*:*:*:*:*:*:*",
"tsolucio=tsolucio/corebos":"cpe:2.3:a:corebos:corebos:*:*:*:*:*:*:*:*",
"Corel=CorelDRAW Graphics Suite":"cpe:2.3:a:corel:coreldraw:*:*:*:*:*:*:*:*",
"Corel=PHOTO-PAINT":"cpe:2.3:a:corel:coreldraw_photo_paint_x8:*:*:*:*:*:*:*:*",
"Talos=coTURN":"cpe:2.3:a:coturn_project:coturn:*:*:*:*:*:*:*:*",
"blevesearch=bleve":"cpe:2.3:a:couchbase:bleve:*:*:*:*:*:*:*:*",
"Countly=countly-server":"cpe:2.3:a:count:countly_server:*:*:*:*:*:*:*:*",
"Kozea=CairoSVG":"cpe:2.3:a:courtbouillon:cairosvg:*:*:*:*:*:*:*:*",
"SourceCodester=Covid-19 Contact Tracing System":"cpe:2.3:a:covid-19_contact_tracing_system_project:covid-19_contact_tracing_system:*:*:*:*:*:*:*:*",
"SourceCodester=COVID 19 Testing Management System":"cpe:2.3:a:covid_19_testing_management_system_project:covid_19_testing_management_system:*:*:*:*:*:*:*:*",
"COWELL INFORMATION SYSTEM CO., LTD.=enterprise travel management system":"cpe:2.3:a:cowell_enterprise_travel_management_system_project:cowell_enterprise_travel_management_system:*:*:*:*:*:*:*:*",
"Coyav Travel=Proagent":"cpe:2.3:a:coyavtravel:proagent:*:*:*:*:*:*:*:*",
"CP Plus=KVMS Pro":"cpe:2.3:a:cpplusworld:kvms_pro:*:*:*:*:*:*:*:*",
"crafatar=crafatar":"cpe:2.3:a:crafatar:crafatar:*:*:*:*:*:*:*:*",
"craftcms=cms":"cpe:2.3:a:craftcms:craft_cms:*:*:*:*:*:*:*:*",
"Crafter Software=Crafter CMS":"cpe:2.3:a:craftercms:crafter_cms:*:*:*:*:*:*:*:*",
"Crafter Software=Crafter CMS":"cpe:2.3:a:craftercms:studio:*:*:*:*:*:*:*:*",
"Arcadia Technology, LLC=Crafty Controller":"cpe:2.3:a:craftycontrol:crafty_controller:*:*:*:*:*:*:*:*",
"crate=crate":"cpe:2.3:a:cratedb:cratedb:*:*:*:*:*:*:*:*",
"crater-invoice=crater-invoice/crater":"cpe:2.3:a:craterapp:crater:*:*:*:*:*:*:*:*",
"Crealogix=EBICS":"cpe:2.3:a:crealogix:ebics_server:*:*:*:*:*:*:*:*",
"Academy=LMS":"cpe:2.3:a:creativeitem:academy_lms:*:*:*:*:*:*:*:*",
"Creativeitem=Academy LMS":"cpe:2.3:a:creativeitem:academy_lms:*:*:*:*:*:*:*:*",
"Creativeitem=Atlas Business Directory Listing":"cpe:2.3:a:creativeitem:atlas:*:*:*:*:*:*:*:*",
"Creativeitem=Ekushey Project Manager CRM":"cpe:2.3:a:creativeitem:ekushey_project_manager:*:*:*:*:*:*:*:*",
"Creativeitem=Mastery LMS":"cpe:2.3:a:creativeitem:mastery_lms:*:*:*:*:*:*:*:*",
"Codecanyon=Credit Lite":"cpe:2.3:a:credit_lite_project:credit_lite:*:*:*:*:*:*:*:*",
"tholum=crm42":"cpe:2.3:a:crm42_project:crm42:*:*:*:*:*:*:*:*",
"Zhong Bang=CRMEB":"cpe:2.3:a:crmeb:crmeb:*:*:*:*:*:*:*:*",
"Zhong Bang=CRMEB Java":"cpe:2.3:a:crmeb:crmeb_java:*:*:*:*:*:*:*:*",
"CRM Perks=Database for Contact Form 7, WPforms, Elementor forms":"cpe:2.3:a:crmperks:database_for_contact_form_7\,_wpforms\,_elementor_forms:*:*:*:*:*:*:*:*",
"CRM Perks=Integration for HubSpot and Contact Form 7, WPForms, Elementor, Ninja Forms":"cpe:2.3:a:crmperks:database_for_contact_form_7\,_wpforms\,_elementor_forms:*:*:*:*:*:*:*:*",
"Unknown=Contact Form Entries":"cpe:2.3:a:crmperks:database_for_contact_form_7\,_wpforms\,_elementor_forms:*:*:*:*:*:*:*:*",
"CRM Perks=Integration for WooCommerce and Zoho CRM, Books, Invoice, Inventory, Bigin":"cpe:2.3:a:crmperks:integration_for_woocommerce_and_zoho_crm\,_books\,_invoice\,_inventory\,_bigin:*:*:*:*:*:*:*:*",
"luckyshot=CRMx":"cpe:2.3:a:crmx_project:crmx:*:*:*:*:*:*:*:*",
"jmrozanec=cron-utils":"cpe:2.3:a:cron-utils_project:cron-utils:*:*:*:*:*:*:*:*",
"crossbeam-rs=crossbeam":"cpe:2.3:a:crossbeam_project:crossbeam:*:*:*:*:*:*:*:*",
"crossplane=crossplane-runtime":"cpe:2.3:a:crossplane:crossplane-runtime:*:*:*:*:*:*:*:*",
"crossplane=crossplane":"cpe:2.3:a:crossplane:crossplane:*:*:*:*:*:*:*:*",
"CrowdStrike=Falcon":"cpe:2.3:a:crowdstrike:falcon:*:*:*:*:*:*:*:*",
"SourceCodester=CRUD without Page Reload":"cpe:2.3:a:crud_without_page_reload_project:crud_without_page_reload:*:*:*:*:*:*:*:*",
"CrushFTP=CrushFTP":"cpe:2.3:a:crushftp:crushftp:*:*:*:*:*:*:*:*",
"brix=crypto-js":"cpe:2.3:a:crypto-js_project:crypto-js:*:*:*:*:*:*:*:*",
"cryptomator=cryptomator":"cpe:2.3:a:cryptomator:cryptomator:*:*:*:*:*:*:*:*",
"Secvisogram=csaf-validator-lib":"cpe:2.3:a:csaf-validator-lib_project:csaf-validator-lib:*:*:*:*:*:*:*:*",
"Secvisogram=csaf-validator-service":"cpe:2.3:a:csaf-validator-lib_project:csaf-validator-lib:*:*:*:*:*:*:*:*",
"DeShang=DSCMS":"cpe:2.3:a:csdeshang:dscms:*:*:*:*:*:*:*:*",
"DeShang=DSKMS":"cpe:2.3:a:csdeshang:dskms:*:*:*:*:*:*:*:*",
"DeShang=DSMall":"cpe:2.3:a:csdeshang:dsmall:*:*:*:*:*:*:*:*",
"DeShang=DSO2O":"cpe:2.3:a:csdeshang:dso2o:*:*:*:*:*:*:*:*",
"DeShang=DSShop":"cpe:2.3:a:csdeshang:dsshop:*:*:*:*:*:*:*:*",
"SmartStar Software=CWS Web-Base":"cpe:2.3:a:csharp:cws_collaborative_development_platform:*:*:*:*:*:*:*:*",
"Plus one=CSV+":"cpe:2.3:a:csv\+_project:csv\+:*:*:*:*:*:*:*:*",
"redpwn=rctf":"cpe:2.3:a:ctfd:rctf:*:*:*:*:*:*:*:*",
"Carinal Tien Hospital=Health Report System":"cpe:2.3:a:cth:carinal_tien_hospital_health_report_system:*:*:*:*:*:*:*:*",
"Shanghai CTI Navigation=CTI Monitoring and Early Warning System":"cpe:2.3:a:cti_monitoring_and_early_warning_system_project:cti_monitoring_and_early_warning_system:*:*:*:*:*:*:*:*",
"ctripcorp=apollo":"cpe:2.3:a:ctrip:apollo:*:*:*:*:*:*:*:*",
"ctrlo=lenio":"cpe:2.3:a:ctrlo:lenio:*:*:*:*:*:*:*:*",
"CubeCart Limited=CubeCart":"cpe:2.3:a:cubecart:cubecart:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Garoon":"cpe:2.3:a:cubecart:cubecart:*:*:*:*:*:*:*:*",
"CutePHP.com=CuteNews":"cpe:2.3:a:cutephp:cutenews:*:*:*:*:*:*:*:*",
"CuyZ=Valinor":"cpe:2.3:a:cuyz:valinor:*:*:*:*:*:*:*:*",
"cvat-ai=cvat":"cpe:2.3:a:cvat:cvat:*:*:*:*:*:*:*:*",
"CVEProject=cve-services":"cpe:2.3:a:cve:cve-services:*:*:*:*:*:*:*:*",
"CXBSoft=Post-Office":"cpe:2.3:a:cxbsoft:post-office:*:*:*:*:*:*:*:*",
"CXBSoft=Url-shorting":"cpe:2.3:a:cxbsoft:url-shorting:*:*:*:*:*:*:*:*",
"cxong=tinydir":"cpe:2.3:a:cxong:tinydir:*:*:*:*:*:*:*:*",
"CyberArk=Conjur OSS Helm Chart":"cpe:2.3:a:cyberark:conjur_oss_helm_chart:*:*:*:*:*:*:*:*",
"CyberArk=Identity":"cpe:2.3:a:cyberark:identity:*:*:*:*:*:*:*:*",
"CyberSolutions Inc.=CyberMail":"cpe:2.3:a:cybersolutions:cybermail:*:*:*:*:*:*:*:*",
"Cybonet=PineApp - Mail Secure":"cpe:2.3:a:cybonet:mail_secure:*:*:*:*:*:*:*:*",
"Cybonet=PineApp":"cpe:2.3:a:cybonet:pineapp_mail_secure:*:*:*:*:*:*:*:*",
"Cybonet=Pineapp Mail Relay":"cpe:2.3:a:cybonet:pineapp_mail_secure:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Remote Service":"cpe:2.3:a:cybozu:cybozu_remote_service:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Dezie":"cpe:2.3:a:cybozu:dezie:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Garoon":"cpe:2.3:a:cybozu:garoon:*:*:*:*:*:*:*:*",
"Inc.=Cybozu Garoon":"cpe:2.3:a:cybozu:garoon:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Mailwise":"cpe:2.3:a:cybozu:mailwise:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Office":"cpe:2.3:a:cybozu:office:*:*:*:*:*:*:*:*",
"Cybozu, Inc.=Cybozu Remote Service":"cpe:2.3:a:cybozu:remote_service_manager:*:*:*:*:*:*:*:*",
"CycloneDX=cyclonedx-bom-repo-server":"cpe:2.3:a:cyclonedx:bill_of_materials_repository_server:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=cygwin":"cpe:2.3:a:cygwin:cygwin:*:*:*:*:*:*:*:*",
"me-and=Cygwin-Git":"cpe:2.3:a:cygwin:git:*:*:*:*:*:*:*:*",
"justingit=dada-mail":"cpe:2.3:a:dadamailproject:dada_mail:*:*:*:*:*:*:*:*",
"Dahua=Smart Park Management":"cpe:2.3:a:dahuasecurity:smart_parking_management:*:*:*:*:*:*:*:*",
"Dahua=Smart Parking Management":"cpe:2.3:a:dahuasecurity:smart_parking_management:*:*:*:*:*:*:*:*",
"daifukuya.com=Kagemai":"cpe:2.3:a:daifukuya:kagemai:*:*:*:*:*:*:*:*",
"Digital Arts Inc.=i-FILTER":"cpe:2.3:a:daj:i-filter:*:*:*:*:*:*:*:*",
"Digital Arts Inc.=i-filter 6.0 install program":"cpe:2.3:a:daj:i-filter_installer:*:*:*:*:*:*:*:*",
"Digital Arts Inc.=i-filter 6.0 installer":"cpe:2.3:a:daj:i-filter_installer:*:*:*:*:*:*:*:*",
"Digital Arts Inc.=m-FILTER Ver.5 Series and Ver.4 Series":"cpe:2.3:a:daj:m-filter:*:*:*:*:*:*:*:*",
"lirantal=daloradius":"cpe:2.3:a:daloradius:daloradius:*:*:*:*:*:*:*:*",
"lirantal=lirantal/daloradius":"cpe:2.3:a:daloradius:daloradius:*:*:*:*:*:*:*:*",
"Dancer::Plugin::SimpleCRUD=Dancer::Plugin::SimpleCRUD":"cpe:2.3:a:dancer\:\:*:*:*:*:*:*:*:*",
"DarrenOfficial=dpaste":"cpe:2.3:a:darrennathanael:dpaste:*:*:*:*:*:*:*:*",
"Google LLC=Dart SDK":"cpe:2.3:a:dart:dart_software_development_kit:*:*:*:*:*:*:*:*",
"Dasan Networks=Dasan Networks":"cpe:2.3:a:dasannetworks:w-web:*:*:*:*:*:*:*:*",
"asrashley=dash-live":"cpe:2.3:a:dash-live_project:dash-live:*:*:*:*:*:*:*:*",
"Sub-IoT=DASH 7 Alliance Protocol stack implementation":"cpe:2.3:a:dash7-alliance:dash7_alliance_protcol:*:*:*:*:*:*:*:*",
"erohtar=Dasherr":"cpe:2.3:a:dasherr_project:dasherr:*:*:*:*:*:*:*:*",
"Lissy93=Dashy":"cpe:2.3:a:dashy:dashy:*:*:*:*:*:*:*:*",
"Database Software=Database Software":"cpe:2.3:a:databank:accreditation_tracking\/presentation_module:*:*:*:*:*:*:*:*",
"martinjw=dbschemareader":"cpe:2.3:a:databaseschemareader_project:dbschemareader:*:*:*:*:*:*:*:*",
"vran-dev=databasir":"cpe:2.3:a:databasir:databasir:*:*:*:*:*:*:*:*",
"vran-dev=databasir":"cpe:2.3:a:databasir_project:databasir:*:*:*:*:*:*:*:*",
"dataease=dataease":"cpe:2.3:a:dataease:dataease:*:*:*:*:*:*:*:*",
"datahub-project=datahub":"cpe:2.3:a:datahub_project:datahub:*:*:*:*:*:*:*:*",
"Datakit=CrossCAD/Ware_x64 library":"cpe:2.3:a:datakit:crosscadware:*:*:*:*:*:*:*:*",
"Datakit=CrossCAD/Ware_x64 library 0":"cpe:2.3:a:datakit:crosscadware:*:*:*:*:*:*:*:*",
"simonw=datasette":"cpe:2.3:a:datasette:datasette:*:*:*:*:*:*:*:*",
"WeiYe-Jing=datax-web":"cpe:2.3:a:datax-web_project:datax-web:*:*:*:*:*:*:*:*",
"HumanTalk Co,Ltd=DaviewIndy":"cpe:2.3:a:datools:daviewindy:*:*:*:*:*:*:*:*",
"Dav-Git=Dav-Cogs":"cpe:2.3:a:dav-cogs_project:dav-cogs:*:*:*:*:*:*:*:*",
"Bottelet=DaybydayCRM":"cpe:2.3:a:daybydaycrm:daybyday:*:*:*:*:*:*:*:*",
"datacharmer=dbdeployer":"cpe:2.3:a:dbdeployer:dbdeployer:*:*:*:*:*:*:*:*",
"dbeaver=dbeaver/dbeaver":"cpe:2.3:a:dbeaver:dbeaver:*:*:*:*:*:*:*:*",
"HackerOne=decamelize node module":"cpe:2.3:a:decamelize_project:decamelize:*:*:*:*:*:*:*:*",
"DECE Software=Geodi":"cpe:2.3:a:dece:geodi:*:*:*:*:*:*:*:*",
"DECE Software=Geodi":"cpe:2.3:a:decesoftware:geodi:*:*:*:*:*:*:*:*",
"Muyun=DedeBIZ":"cpe:2.3:a:dedebiz:dedebiz:*:*:*:*:*:*:*:*",
"linuxdeepin=developer-center":"cpe:2.3:a:deepin:deepin-compressor:*:*:*:*:*:*:*:*",
"linuxdeepin=developer-center":"cpe:2.3:a:deepin:deepin_reader:*:*:*:*:*:*:*:*",
"RebeccaStevens=deepmerge-ts":"cpe:2.3:a:deepmerge-ts_project:deepmerge-ts:*:*:*:*:*:*:*:*",
"deepset-ai=deepset-ai/haystack":"cpe:2.3:a:deepset:haystack:*:*:*:*:*:*:*:*",
"Degamisu=open-irs":"cpe:2.3:a:degamisu:open-irs:*:*:*:*:*:*:*:*",
"deislabs=oras":"cpe:2.3:a:deislabs:oras:*:*:*:*:*:*:*:*",
"Delinea=Secret Server":"cpe:2.3:a:delinea:secret_server:*:*:*:*:*:*:*:*",
"Dell=Alienware Command Center (AWCC)":"cpe:2.3:a:dell:alienware_command_center:*:*:*:*:*:*:*:*",
"Dell EMC=Avamar":"cpe:2.3:a:dell:avamar_data_migration_enabler_web_interface:*:*:*:*:*:*:*:*",
"Dell=BSAFE Micro Edition Suite":"cpe:2.3:a:dell:bsafe_micro-edition-suite:*:*:*:*:*:*:*:*",
"Dell=RSA BSAFE MES":"cpe:2.3:a:dell:bsafe_micro-edition-suite:*:*:*:*:*:*:*:*",
"Dell EMC=RSA BSAFE SSL-J ":"cpe:2.3:a:dell:bsafe_ssl-j:*:*:*:*:*:*:*:*",
"Dell=BSAFE SSL-J":"cpe:2.3:a:dell:bsafe_ssl-j:*:*:*:*:*:*:*:*",
"Dell=Dell BSAFE SSL-J":"cpe:2.3:a:dell:bsafe_ssl-j:*:*:*:*:*:*:*:*",
"Dell=Dell BSAFE SSL-J ":"cpe:2.3:a:dell:bsafe_ssl-j:*:*:*:*:*:*:*:*",
"Dell=Cloud Mobility for Dell Storage":"cpe:2.3:a:dell:cloud_mobility_for_dell_emc_storage:*:*:*:*:*:*:*:*",
"Dell=CloudIQ Collector":"cpe:2.3:a:dell:cloudiq_collector:*:*:*:*:*:*:*:*",
"Dell=CloudLink":"cpe:2.3:a:dell:cloudlink:*:*:*:*:*:*:*:*",
"Dell=Dell Command Configure (DCC)":"cpe:2.3:a:dell:command\|configure:*:*:*:*:*:*:*:*",
"Dell=Dell Command Monitor (DCM)":"cpe:2.3:a:dell:command\|monitor:*:*:*:*:*:*:*:*",
"Dell=CPG SW":"cpe:2.3:a:dell:command_\|_integration_suite_for_system_center:*:*:*:*:*:*:*:*",
"Dell=Dell Command Integration Suite for System Center (DCIS)":"cpe:2.3:a:dell:command_\|_integration_suite_for_system_center:*:*:*:*:*:*:*:*",
"Dell=Dell Command Intel vPro Out of Band (DCIV)":"cpe:2.3:a:dell:command_\|_intel_vpro_out_of_band:*:*:*:*:*:*:*:*",
"Dell=Dell Command Update (DCU)":"cpe:2.3:a:dell:command_\|_intel_vpro_out_of_band:*:*:*:*:*:*:*:*",
"Dell=Dell Command Monitor (DCM)":"cpe:2.3:a:dell:command_\|_monitor:*:*:*:*:*:*:*:*",
"Dell=Dell Command Update (DCU)":"cpe:2.3:a:dell:command_update:*:*:*:*:*:*:*:*",
"Dell=Dell Container Storage Modules":"cpe:2.3:a:dell:container_storage_modules:*:*:*:*:*:*:*:*",
"Dell=Data Protection Central":"cpe:2.3:a:dell:data_protection_central:*:*:*:*:*:*:*:*",
"Dell=Data Protection Search":"cpe:2.3:a:dell:data_protection_search:*:*:*:*:*:*:*:*",
"Dell=dbutil":"cpe:2.3:a:dell:dbutil_2_3.sys:*:*:*:*:*:*:*:*",
"Dell EMC=Dell Digital Delivery":"cpe:2.3:a:dell:digital_delivery:*:*:*:*:*:*:*:*",
"Dell=Dell Digital Delivery":"cpe:2.3:a:dell:digital_delivery:*:*:*:*:*:*:*:*",
"Dell=Dell Digital Delivery (Cirrus)":"cpe:2.3:a:dell:digital_delivery:*:*:*:*:*:*:*:*",
"Dell=Dell Display Manager":"cpe:2.3:a:dell:display_manager:*:*:*:*:*:*:*:*",
"Dell=Mobility - E-Lab Navigator":"cpe:2.3:a:dell:e-lab_navigator:*:*:*:*:*:*:*:*",
"Dell=ECS Streamer":"cpe:2.3:a:dell:ecs_streamer:*:*:*:*:*:*:*:*",
"Dell=ECS":"cpe:2.3:a:dell:elastic_cloud_storage:*:*:*:*:*:*:*:*",
"Dell=AppSync":"cpe:2.3:a:dell:emc_appsync:*:*:*:*:*:*:*:*",
"Dell=Avamar":"cpe:2.3:a:dell:emc_avamar_server:*:*:*:*:*:*:*:*",
"Dell=CloudLink":"cpe:2.3:a:dell:emc_cloud_link:*:*:*:*:*:*:*:*",
"Dell=Data Protection Advisor":"cpe:2.3:a:dell:emc_data_protection_advisor:*:*:*:*:*:*:*:*",
"Dell=Data Protection Central":"cpe:2.3:a:dell:emc_data_protection_central:*:*:*:*:*:*:*:*",
"Dell=Elastic Cloud Storage":"cpe:2.3:a:dell:emc_elastic_cloud_storage:*:*:*:*:*:*:*:*",
"DELL EMC=Dell EMC Enterprise Copy Data Management (eCDM)":"cpe:2.3:a:dell:emc_enterprise_copy_data_management:*:*:*:*:*:*:*:*",
"Dell=iDRAC Service Module (iSM)":"cpe:2.3:a:dell:emc_idrac_service_module:*:*:*:*:*:*:*:*",
"Dell EMC=Integrated Data Protection Appliance":"cpe:2.3:a:dell:emc_integrated_data_protection_appliance:*:*:*:*:*:*:*:*",
"Dell=Integrated Data Protection Appliance":"cpe:2.3:a:dell:emc_integrated_data_protection_appliance:*:*:*:*:*:*:*:*",
"Dell EMC=Isilon OneFS":"cpe:2.3:a:dell:emc_isilon:*:*:*:*:*:*:*:*",
"Dell EMC=Isilon OneFS":"cpe:2.3:a:dell:emc_isilon_onefs:*:*:*:*:*:*:*:*",
"Dell=Isilon OneFS":"cpe:2.3:a:dell:emc_isilon_onefs:*:*:*:*:*:*:*:*",
"Dell EMC=Dell EMC IsilonSD Management Server":"cpe:2.3:a:dell:emc_isilonsd_management_server:*:*:*:*:*:*:*:*",
"Dell EMC=Metro node":"cpe:2.3:a:dell:emc_metro_node:*:*:*:*:*:*:*:*",
"Dell EMC=Dell EMC NetWorker":"cpe:2.3:a:dell:emc_networker:*:*:*:*:*:*:*:*",
"Dell EMC=Networker":"cpe:2.3:a:dell:emc_networker:*:*:*:*:*:*:*:*",
"Dell=Dell NetWorker, NVE":"cpe:2.3:a:dell:emc_networker:*:*:*:*:*:*:*:*",
"Dell=NetWorker":"cpe:2.3:a:dell:emc_networker:*:*:*:*:*:*:*:*",
"Dell=NetWorker Management Console":"cpe:2.3:a:dell:emc_networker:*:*:*:*:*:*:*:*",
"Dell=Dell OpenManage Enterprise Modular":"cpe:2.3:a:dell:emc_openmanage_enterprise-modular:*:*:*:*:*:*:*:*",
"Dell=OpenManage Enterprise":"cpe:2.3:a:dell:emc_openmanage_enterprise:*:*:*:*:*:*:*:*",
"Dell EMC=Open Manage System Administrator":"cpe:2.3:a:dell:emc_openmanage_server_administrator:*:*:*:*:*:*:*:*",
"Dell EMC=OpenManage Server Administrator":"cpe:2.3:a:dell:emc_openmanage_server_administrator:*:*:*:*:*:*:*:*",
"Dell=Dell Open Manage Server Administrator":"cpe:2.3:a:dell:emc_openmanage_server_administrator:*:*:*:*:*:*:*:*",
"Dell=Dell OpenManage Server Administrator":"cpe:2.3:a:dell:emc_openmanage_server_administrator:*:*:*:*:*:*:*:*",
"Dell=Cyber Recovery":"cpe:2.3:a:dell:emc_powerprotect_cyber_recovery:*:*:*:*:*:*:*:*",
"Dell=Dell Repository Manager (DRM)":"cpe:2.3:a:dell:emc_repository_manager:*:*:*:*:*:*:*:*",
"Dell EMC=ScaleIO":"cpe:2.3:a:dell:emc_scaleio:*:*:*:*:*:*:*:*",
"Dell EMC=Secure Connect Gateway (SCG) Policy Manager":"cpe:2.3:a:dell:emc_secure_connect_gateway_policy_manager:*:*:*:*:*:*:*:*",
"Dell=Secure Connect Gateway (SCG) Policy Manager":"cpe:2.3:a:dell:emc_secure_connect_gateway_policy_manager:*:*:*:*:*:*:*:*",
"Dell=SRS Policy Manager":"cpe:2.3:a:dell:emc_srs_policy_manager:*:*:*:*:*:*:*:*",
"Dell=EMC Storage M&R":"cpe:2.3:a:dell:emc_storage_monitoring_and_reporting:*:*:*:*:*:*:*:*",
"Dell=Dell EMC Streaming Data Platform":"cpe:2.3:a:dell:emc_streaming_data_platform:*:*:*:*:*:*:*:*",
"Dell=DellEMC System Update - DSU":"cpe:2.3:a:dell:emc_system_update:*:*:*:*:*:*:*:*",
"DELL EMC=VPlex Software: GeoSynchrony":"cpe:2.3:a:dell:emc_vplex_geosynchrony:*:*:*:*:*:*:*:*",
"Dell=GeoDrive":"cpe:2.3:a:dell:geodrive:*:*:*:*:*:*:*:*",
"Dell=Dell Hybrid Client (DHC)":"cpe:2.3:a:dell:hybrid_client:*:*:*:*:*:*:*:*",
"Dell=Integrated Dell Remote Access Controller 9":"cpe:2.3:a:dell:idrac9:*:*:*:*:*:*:*:*",
"Dell=ImageAssist":"cpe:2.3:a:dell:imageassist:*:*:*:*:*:*:*:*",
"Dell=Dell Multifunction Printer E525w Driver and Software Suite":"cpe:2.3:a:dell:multifunction_printer_e525w_driver_and_software_suite:*:*:*:*:*:*:*:*",
"Dell=NetWorker":"cpe:2.3:a:dell:networker:*:*:*:*:*:*:*:*",
"Dell=NetWorker Module for Databases and Applications - Oracle":"cpe:2.3:a:dell:networker:*:*:*:*:*:*:*:*",
"Dell=OpenManage Enterprise":"cpe:2.3:a:dell:openmanage_enterprise-modular:*:*:*:*:*:*:*:*",
"Dell=Dell OpenManage Enterprise":"cpe:2.3:a:dell:openmanage_enterprise:*:*:*:*:*:*:*:*",
"Dell=OpenManage Enterprise":"cpe:2.3:a:dell:openmanage_enterprise:*:*:*:*:*:*:*:*",
"Dell=OpenManage Network Manager":"cpe:2.3:a:dell:openmanage_network_manager:*:*:*:*:*:*:*:*",
"Dell=Dell Open Manage Server Administrator":"cpe:2.3:a:dell:openmanage_server_administrator:*:*:*:*:*:*:*:*",
"Dell=OpenManage Server Administrator (OMSA)":"cpe:2.3:a:dell:openmanage_server_administrator:*:*:*:*:*:*:*:*",
"Dell=Dell Pair":"cpe:2.3:a:dell:pair:*:*:*:*:*:*:*:*",
"Dell=Dell Peripheral Manager":"cpe:2.3:a:dell:peripheral_manager:*:*:*:*:*:*:*:*",
"Dell=Dell Power Manager (DPM)":"cpe:2.3:a:dell:power_manager:*:*:*:*:*:*:*:*",
"Dell=PowerFlex":"cpe:2.3:a:dell:powerflex_presentation_server:*:*:*:*:*:*:*:*",
"Dell=PowerPath Management Appliance":"cpe:2.3:a:dell:powerpath_management_appliance:*:*:*:*:*:*:*:*",
"Dell=PowerProtect Agent for File System":"cpe:2.3:a:dell:powerprotect_agent_for_file_system:*:*:*:*:*:*:*:*",
"Dell=Cyber Recovery":"cpe:2.3:a:dell:powerprotect_cyber_recovery:*:*:*:*:*:*:*:*",
"Dell=PowerProtect Cyber Recovery":"cpe:2.3:a:dell:powerprotect_cyber_recovery:*:*:*:*:*:*:*:*",
"Dell=PPDM Reporting (PowerProtect Data Manager)":"cpe:2.3:a:dell:powerprotect_data_manager:*:*:*:*:*:*:*:*",
"Dell=PowerProtect Data Manager":"cpe:2.3:a:dell:powerprotect_data_manager:*:*:*:*:*:*:*:*",
"Dell= PowerScale OneFS":"cpe:2.3:a:dell:powerscale_onefs:*:*:*:*:*:*:*:*",
"Dell=PowerScale OneFS":"cpe:2.3:a:dell:powerscale_onefs:*:*:*:*:*:*:*:*",
"dell=Dell":"cpe:2.3:a:dell:precision_optimizer:*:*:*:*:*:*:*:*",
"Dell=Dell Repository Manager (DRM)":"cpe:2.3:a:dell:repository_manager:*:*:*:*:*:*:*:*",
"Dell=Dell Rugged Control Center":"cpe:2.3:a:dell:rugged_control_center:*:*:*:*:*:*:*:*",
"Dell=Rugged Control Center (RCC)":"cpe:2.3:a:dell:rugged_control_center:*:*:*:*:*:*:*:*",
"Dell=SD ROM Utility":"cpe:2.3:a:dell:sd_rom_utility:*:*:*:*:*:*:*:*",
"Dell=Secure Connect Gateway":"cpe:2.3:a:dell:secure_connect_gateway:*:*:*:*:*:*:*:*",
"Dell=Secure Connect Gateway (SCG) 5.0 Appliance - SRS":"cpe:2.3:a:dell:secure_connect_gateway:*:*:*:*:*:*:*:*",
"Dell=Secure Connect Gateway (SCG) Policy Manager":"cpe:2.3:a:dell:secure_connect_gateway_policy_manager:*:*:*:*:*:*:*:*",
"Dell=Dell Encryption Enterprise":"cpe:2.3:a:dell:security_management_server:*:*:*:*:*:*:*:*",
"Dell=Dell SmartFabric Storage Software":"cpe:2.3:a:dell:smartfabric_storage_software:*:*:*:*:*:*:*:*",
"Dell=Smart Fabric Storage Software":"cpe:2.3:a:dell:smartfabric_storage_software:*:*:*:*:*:*:*:*",
"Dell EMC=Dell Storage Manager":"cpe:2.3:a:dell:storage_manager:*:*:*:*:*:*:*:*",
"Zero Day Initiative=Dell Storage Manager":"cpe:2.3:a:dell:storage_manager_2016:*:*:*:*:*:*:*:*",
"Dell=Streaming Data Platform":"cpe:2.3:a:dell:streaming_data_platform:*:*:*:*:*:*:*:*",
"Dell=SupportAssist Client":"cpe:2.3:a:dell:supportassist:*:*:*:*:*:*:*:*",
"Dell=SupportAssist Client Consumer":"cpe:2.3:a:dell:supportassist_client_consumer:*:*:*:*:*:*:*:*",
"Dell=SupportAssist":"cpe:2.3:a:dell:supportassist_for_home_pcs:*:*:*:*:*:*:*:*",
"Dell=SupportAssist Client Consumer":"cpe:2.3:a:dell:supportassist_for_home_pcs:*:*:*:*:*:*:*:*",
"Dell=Dell OS Recovery Tool":"cpe:2.3:a:dell:supportassist_os_recovery:*:*:*:*:*:*:*:*",
"Dell=DellEMC System Update - DSU":"cpe:2.3:a:dell:system_update:*:*:*:*:*:*:*:*",
"Dell=System Update":"cpe:2.3:a:dell:system_update:*:*:*:*:*:*:*:*",
"Dell= Dell Trusted Device Client":"cpe:2.3:a:dell:trusted_device_agent:*:*:*:*:*:*:*:*",
"Dell=Unity":"cpe:2.3:a:dell:unity_operating_environment:*:*:*:*:*:*:*:*",
"Dell=DUP Framework ":"cpe:2.3:a:dell:update_package_framework:*:*:*:*:*:*:*:*",
"Dell=Dell VxRail HCI":"cpe:2.3:a:dell:vxrail_hyperconverged_infrastructure:*:*:*:*:*:*:*:*",
"Dell=Dell Wyse Device Agent":"cpe:2.3:a:dell:wyse_device_agent:*:*:*:*:*:*:*:*",
"Dell=Wyse Management Suite":"cpe:2.3:a:dell:wyse_management_suite:*:*:*:*:*:*:*:*",
"Dell=Wyse Management Suite ":"cpe:2.3:a:dell:wyse_management_suite:*:*:*:*:*:*:*:*",
"Dell=Wyse Password Encoder":"cpe:2.3:a:dell:wyse_thinlinux:*:*:*:*:*:*:*:*",
"Dell=XtremIO":"cpe:2.3:a:dell:xtremio_management_server:*:*:*:*:*:*:*:*",
"Dell EMC=ECS":"cpe:2.3:a:dellemc:elastic_cloud_storage:*:*:*:*:*:*:*:*",
"Vangene=deltaFlow E-platform":"cpe:2.3:a:deltaflow_project:deltaflow:*:*:*:*:*:*:*:*",
"Delta Electronics=ASDA-Soft":"cpe:2.3:a:deltaww:asda_soft:*:*:*:*:*:*:*:*",
"Delta Electronics=CNCSoft-B DOPSoft":"cpe:2.3:a:deltaww:cncsoft-b:*:*:*:*:*:*:*:*",
"Delta Electronics=CNCSoft":"cpe:2.3:a:deltaww:cncsoft:*:*:*:*:*:*:*:*",
"Delta Electronics=CNCSoft":"cpe:2.3:a:deltaww:cncsoft_screeneditor:*:*:*:*:*:*:*:*",
"Delta Electronics=CNCSoft ScreenEditor":"cpe:2.3:a:deltaww:cnssoft_screeneditor:*:*:*:*:*:*:*:*",
"Delta Electronics=Delta Industrial Automation DOPSoft":"cpe:2.3:a:deltaww:delta_industrial_automation_dopsoft:*:*:*:*:*:*:*:*",
"Delta Electronics=Delta Industrial Automation PMSoft":"cpe:2.3:a:deltaww:delta_industrial_automation_pmsoft:*:*:*:*:*:*:*:*",
"Delta Electronics=Delta Robot Automation Studio (DRAS)":"cpe:2.3:a:deltaww:delta_robot_automation_studio:*:*:*:*:*:*:*:*",
"DIAEnerrgie=DIAEnergie":"cpe:2.3:a:deltaww:diaenergie:*:*:*:*:*:*:*:*",
"Delta Electronics =DIAEnergie ":"cpe:2.3:a:deltaww:diaenergie:*:*:*:*:*:*:*:*",
"Delta Electronics=DIAEnergie":"cpe:2.3:a:deltaww:diaenergie:*:*:*:*:*:*:*:*",
"Delta Electronics=DIAEnergy":"cpe:2.3:a:deltaww:diaenergie:*:*:*:*:*:*:*:*",
"Delta Electronics=DIALink":"cpe:2.3:a:deltaww:dialink:*:*:*:*:*:*:*:*",
"Delta Electronics=Industrial Automation DIALink":"cpe:2.3:a:deltaww:dialink:*:*:*:*:*:*:*:*",
"Delta Electronics=DIAScreen":"cpe:2.3:a:deltaww:diascreen:*:*:*:*:*:*:*:*",
"Delta Electronics=DMARS ":"cpe:2.3:a:deltaww:dmars:*:*:*:*:*:*:*:*",
"Delta Electronics=DOPSoft":"cpe:2.3:a:deltaww:dopsoft:*:*:*:*:*:*:*:*",
"Delta Electronics=DOPSoft 2":"cpe:2.3:a:deltaww:dopsoft:*:*:*:*:*:*:*:*",
"Delta Industrial Automation=DOPSoft":"cpe:2.3:a:deltaww:dopsoft:*:*:*:*:*:*:*:*",
"Delta Electronics=InfraSuite Device Master":"cpe:2.3:a:deltaww:infrasuite_device_master:*:*:*:*:*:*:*:*",
"Delta Electronics=Infrasuite Device Master":"cpe:2.3:a:deltaww:infrasuite_device_master:*:*:*:*:*:*:*:*",
"Delta Electronics=ISPSoft":"cpe:2.3:a:deltaww:ispsoft:*:*:*:*:*:*:*:*",
"ICS-CERT=Delta Industrial Automation CNCSoft":"cpe:2.3:a:deltaww:screeneditor:*:*:*:*:*:*:*:*",
"Delta Electronics=Automation TPEditor":"cpe:2.3:a:deltaww:tpeditor:*:*:*:*:*:*:*:*",
"Delta Electronics=Delta Industrial Automation TPEditor":"cpe:2.3:a:deltaww:tpeditor:*:*:*:*:*:*:*:*",
"Delta Electronics=TPEditor":"cpe:2.3:a:deltaww:tpeditor:*:*:*:*:*:*:*:*",
"Delta Electronics=WPLSoft":"cpe:2.3:a:deltaww:wplsoft:*:*:*:*:*:*:*:*",
"ICS-CERT=Delta Electronics WPLSoft":"cpe:2.3:a:deltaww:wplsoft:*:*:*:*:*:*:*:*",
"unspecified=Demokratian":"cpe:2.3:a:demokratian:demokratian:*:*:*:*:*:*:*:*",
"DEMON1A=Discord-Recon":"cpe:2.3:a:demon1a:discord-recon:*:*:*:*:*:*:*:*",
"Kazuho Oku=H2O":"cpe:2.3:a:dena:h2o:*:*:*:*:*:*:*:*",
"h2o=h2o":"cpe:2.3:a:dena:h2o:*:*:*:*:*:*:*:*",
"spinacms=spinacms/spina":"cpe:2.3:a:denkgroot:spina:*:*:*:*:*:*:*:*",
"denoland=deno":"cpe:2.3:a:deno:deno:*:*:*:*:*:*:*:*",
"denosaurs=emoji":"cpe:2.3:a:denosaurs:emoji:*:*:*:*:*:*:*:*",
"SourceCodester=Dental Clinic Appointment Reservation System":"cpe:2.3:a:dental_clinic_appointment_reservation_system_project:dental_clinic_appointment_reservation_system:*:*:*:*:*:*:*:*",
"Das=U-Boot":"cpe:2.3:a:denx:u-boot:*:*:*:*:*:*:*:*",
"Uboot=Uboot":"cpe:2.3:a:denx:u-boot:*:*:*:*:*:*:*:*",
"SourceCodester=Design and Implementation of Covid-19 Directory on Vaccination System":"cpe:2.3:a:design_and_implementation_of_covid-19_directory_on_vaccination_system_project:design_and_implementation_of_covid-19_directory_on_vaccination_system:*:*:*:*:*:*:*:*",
"destiny.gg=chat":"cpe:2.3:a:destiny:chat:*:*:*:*:*:*:*:*",
"detekt=detekt/detekt":"cpe:2.3:a:detekt:detekt:*:*:*:*:*:*:*:*",
"DevExpress=DevExpress":"cpe:2.3:a:devexpress:devexpress:*:*:*:*:*:*:*:*",
"Device42=CMDB":"cpe:2.3:a:device42:cmdb:*:*:*:*:*:*:*:*",
"Devolutions=Gateway":"cpe:2.3:a:devolutions:devolutions_gateway:*:*:*:*:*:*:*:*",
"Devolutions=Devolutions Server":"cpe:2.3:a:devolutions:devolutions_server:*:*:*:*:*:*:*:*",
"Devolutions=Server":"cpe:2.3:a:devolutions:devolutions_server:*:*:*:*:*:*:*:*",
"Devolutions=Remote Desktop Manager":"cpe:2.3:a:devolutions:remote_desktop_manager:*:*:*:*:*:*:*:*",
"Devolutions=Remote Desktop Manager PowerShell Module":"cpe:2.3:a:devolutions:remote_desktop_manager:*:*:*:*:*:*:*:*",
"DEXMA=DexGate":"cpe:2.3:a:dexma:dexgate:*:*:*:*:*:*:*:*",
"Raonwiz=DEXT5Editor":"cpe:2.3:a:dext5:dext5_editor:*:*:*:*:*:*:*:*",
"Devpia=DEXTUploadX5":"cpe:2.3:a:dextsolution:dextuploadx5:*:*:*:*:*:*:*:*",
"dfir-iris=iris-web":"cpe:2.3:a:dfir-iris:iris:*:*:*:*:*:*:*:*",
"DFIRKuiper=Kuiper":"cpe:2.3:a:dfirkuiper:kuiper:*:*:*:*:*:*:*:*",
"DGLogik Inc=DGLux Server":"cpe:2.3:a:dglogik:dglux_server:*:*:*:*:*:*:*:*",
"dhis2=dhis2-core":"cpe:2.3:a:dhis2:dhis_2:*:*:*:*:*:*:*:*",
"Diagon=Diagon":"cpe:2.3:a:diagon_project:diagon:*:*:*:*:*:*:*:*",
"jgraph=jgraph/drawio":"cpe:2.3:a:diagrams:drawio:*:*:*:*:*:*:*:*",
"didi=KnowSearch":"cpe:2.3:a:didiglobal:knowsearch:*:*:*:*:*:*:*:*",
"Digital Ant=E-Commerce Software":"cpe:2.3:a:digital-ant:digital_ant:*:*:*:*:*:*:*:*",
"DigitalDruid.Net=HOTELDRUID":"cpe:2.3:a:digitaldruid:hoteldruid:*:*:*:*:*:*:*:*",
"DigitalPersona=FPSensor":"cpe:2.3:a:digitalpersona_fpsensor_project:digitalpersona_fpsensor:*:*:*:*:*:*:*:*",
"Digita Information Technology=Smartrise Document Management System":"cpe:2.3:a:digitatek:smartrise_document_management_system:*:*:*:*:*:*:*:*",
"Data Systems Consulting Co., Ltd.=BPM":"cpe:2.3:a:digiwin:business_process_management:*:*:*:*:*:*:*:*",
"Digital Strategy=Zekiweb":"cpe:2.3:a:dijital:zekiweb:*:*:*:*:*:*:*:*",
"DIRACGrid=DIRAC":"cpe:2.3:a:diracgrid:dirac:*:*:*:*:*:*:*:*",
"DEMON1A=Discord-Recon":"cpe:2.3:a:discord:discord-recon:*:*:*:*:*:*:*:*",
"discourse=discourse-calendar":"cpe:2.3:a:discourse:calendar:*:*:*:*:*:*:*:*",
"discourse=DiscoTOC":"cpe:2.3:a:discourse:discotoc:*:*:*:*:*:*:*:*",
"discourse=discourse":"cpe:2.3:a:discourse:discourse:*:*:*:*:*:*:*:*",
"discourse=discourse-group-membership-ip-block":"cpe:2.3:a:discourse:group_membership_ip_blocks:*:*:*:*:*:*:*:*",
"discourse=discourse-mermaid-theme-component":"cpe:2.3:a:discourse:mermaid:*:*:*:*:*:*:*:*",
"discourse=discourse-patreon":"cpe:2.3:a:discourse:patreon:*:*:*:*:*:*:*:*",
"discourse=discourse-reactions":"cpe:2.3:a:discourse:reactions:*:*:*:*:*:*:*:*",
"Talos=Walt Disney Animation Studios PTEX":"cpe:2.3:a:disneyanimation:ptex:*:*:*:*:*:*:*:*",
"Virames=Vira-Investing":"cpe:2.3:a:dizayn:vira-investing:*:*:*:*:*:*:*:*",
"tm-kn=django-basic-auth-ip-whitelist":"cpe:2.3:a:django-basic-auth-ip-whitelist_project:django-basic-auth-ip-whitelist:*:*:*:*:*:*:*:*",
"django-helpdesk=django-helpdesk/django-helpdesk":"cpe:2.3:a:django-helpdesk_project:django-helpdesk:*:*:*:*:*:*:*:*",
"xi=django-mfa3":"cpe:2.3:a:django-mfa3_project:django-mfa3:*:*:*:*:*:*:*:*",
"unspecified=django-openipam":"cpe:2.3:a:django-openipam_project:django-openipam:*:*:*:*:*:*:*:*",
"unspecified=django-photologue":"cpe:2.3:a:django-photologue_project:django-photologue:*:*:*:*:*:*:*:*",
"codingjoe=django-s3file":"cpe:2.3:a:django-s3file_project:django-s3file:*:*:*:*:*:*:*:*",
"django-ses=django-ses":"cpe:2.3:a:django-ses_project:django-ses:*:*:*:*:*:*:*:*",
"Jazzband=django-user-sessions":"cpe:2.3:a:django-user-sessions_project:django-user-sessions:*:*:*:*:*:*:*:*",
"Django-wiki=Django-wiki":"cpe:2.3:a:django-wiki_project:django-wiki:*:*:*:*:*:*:*:*",
"cyface=Terms and Conditions Module":"cpe:2.3:a:django_terms_and_conditions_project:django_terms_and_conditions:*:*:*:*:*:*:*:*",
"Bouke=django-two-factor-auth":"cpe:2.3:a:django_two-factor_authentication_project:django_two-factor_authentication:*:*:*:*:*:*:*:*",
"liangliangyy=liangliangyy/djangoblog":"cpe:2.3:a:djangoblog_project:djangoblog:*:*:*:*:*:*:*:*",
"D-Link=D-View 8":"cpe:2.3:a:dlink:d-view_8:*:*:*:*:*:*:*:*",
"DMTF=libspdm":"cpe:2.3:a:dmtf:libspdm:*:*:*:*:*:*:*:*",
"DNNSoftware=DNN Platform":"cpe:2.3:a:dnnsoftware:dotnetnuke:*:*:*:*:*:*:*:*",
"DotNetNuke=DotNetNuke CMS Fixed in 9.1.1":"cpe:2.3:a:dnnsoftware:dotnetnuke:*:*:*:*:*:*:*:*",
"dnnsoftware=dnnsoftware/dnn.platform":"cpe:2.3:a:dnnsoftware:dotnetnuke:*:*:*:*:*:*:*:*",
"dns-stats=hedgehog":"cpe:2.3:a:dns-stats:hedgehog:*:*:*:*:*:*:*:*",
"Docker Inc.=Docker Desktop":"cpe:2.3:a:docker:docker_desktop:*:*:*:*:*:*:*:*",
"RainyGao=DocSys":"cpe:2.3:a:docsys_project:docsys:*:*:*:*:*:*:*:*",
"SourceCodester=Doctors Appointment System":"cpe:2.3:a:doctors_appointment_system_project:doctors_appointment_system:*:*:*:*:*:*:*:*",
"DocuTrac=DTISQLInstaller.exe":"cpe:2.3:a:docutracinc:dtisqlinstaller:*:*:*:*:*:*:*:*",
"Dogecoin=Node":"cpe:2.3:a:dogecoin:dogecoin:*:*:*:*:*:*:*:*",
"The pki-core Project=pki-core":"cpe:2.3:a:dogtagpki:dogtagpki:*:*:*:*:*:*:*:*",
"[UNKNOWN]=pki-core":"cpe:2.3:a:dogtagpki:dogtagpki:*:*:*:*:*:*:*:*",
"DogukanUrker=flaskBlog":"cpe:2.3:a:dogukanurker:flaskblog:*:*:*:*:*:*:*:*",
"Dokan=Open Source File System":"cpe:2.3:a:dokan_project:dokan:*:*:*:*:*:*:*:*",
"Dolibarr=dolibarr":"cpe:2.3:a:dolibarr:dolibarr:*:*:*:*:*:*:*:*",
"Dolibarr ERP & CRM=Dolibarr":"cpe:2.3:a:dolibarr:dolibarr_erp\/crm:*:*:*:*:*:*:*:*",
"Dolibarr=Dolibarr ERP CRM":"cpe:2.3:a:dolibarr:dolibarr_erp\/crm:*:*:*:*:*:*:*:*",
"Dolibarr=dolibarr":"cpe:2.3:a:dolibarr:dolibarr_erp\/crm:*:*:*:*:*:*:*:*",
"dolibarr=dolibarr/dolibarr":"cpe:2.3:a:dolibarr:dolibarr_erp\/crm:*:*:*:*:*:*:*:*",
"unspecified=DolphinPHP":"cpe:2.3:a:dolphinphp_project:dolphinphp:*:*:*:*:*:*:*:*",
"DomainMOD=DomainMOD":"cpe:2.3:a:domainmod:domainmod:*:*:*:*:*:*:*:*",
"dompdf=php-svg-lib":"cpe:2.3:a:dompdf:php-svg-lib:*:*:*:*:*:*:*:*",
"dompdf=dompdf":"cpe:2.3:a:dompdf_project:dompdf:*:*:*:*:*:*:*:*",
"dompdf=dompdf/dompdf":"cpe:2.3:a:dompdf_project:dompdf:*:*:*:*:*:*:*:*",
"docCMS=Administration Panel":"cpe:2.3:a:dotcms:dotcms:*:*:*:*:*:*:*:*",
"dotCMS=dotCMS core":"cpe:2.3:a:dotcms:dotcms:*:*:*:*:*:*:*:*",
"OmniSharp=csharp-language-server-protocol":"cpe:2.3:a:dotnetfoundation:c\#_language_server_protocol:*:*:*:*:*:*:*:*",
"PiranhaCMS=Piranha":"cpe:2.3:a:dotnetfoundation:piranha_cms:*:*:*:*:*:*:*:*",
"DotNetZip=DotNetZip.Semvered":"cpe:2.3:a:dotnetzip.semverd_project:dotnetzip.semverd:*:*:*:*:*:*:*:*",
"Dot Tech=Smart Campus System":"cpe:2.3:a:dottech:smart_campus_system:*:*:*:*:*:*:*:*",
"TAGAWA Takao=TransmitMail":"cpe:2.3:a:dounokouno:transmitmail:*:*:*:*:*:*:*:*",
"National Institute for Land and Infrastructure Management=Douroshisetu Kihon Data Sakusei System":"cpe:2.3:a:douroshisetu:kihon_data_sakusei_system:*:*:*:*:*:*:*:*",
"DRD Fleet Leasing=DRDrive":"cpe:2.3:a:drd:drdrive:*:*:*:*:*:*:*:*",
"Dreamer=CMS":"cpe:2.3:a:dreamer_cms_project:dreamer_cms:*:*:*:*:*:*:*:*",
"isoftforce=Dreamer CMS":"cpe:2.3:a:dreamer_cms_project:dreamer_cms:*:*:*:*:*:*:*:*",
"Dream Technology=mica":"cpe:2.3:a:dreamlu:mica:*:*:*:*:*:*:*:*",
"Dream Security=MagicLine4NX":"cpe:2.3:a:dreamsecurity:magicline_4.0:*:*:*:*:*:*:*:*",
"drEryk sp. z o.o.=drEryk Gabinet":"cpe:2.3:a:dreryk:gabinet:*:*:*:*:*:*:*:*",
"unspecified=drogon":"cpe:2.3:a:drogon:drogon:*:*:*:*:*:*:*:*",
"dromara=hertzbeat":"cpe:2.3:a:dromara:hertzbeat:*:*:*:*:*:*:*:*",
"Dromara=HuTool":"cpe:2.3:a:dromara:hutool:*:*:*:*:*:*:*:*",
"PX4=MAVLink":"cpe:2.3:a:dronecode:micro_air_vehicle_link:*:*:*:*:*:*:*:*",
"unspecified=MAVLink":"cpe:2.3:a:dronecode:micro_air_vehicle_link:*:*:*:*:*:*:*:*",
"[UNKNOWN]=dropbear":"cpe:2.3:a:dropbear_ssh_project:dropbear_ssh:*:*:*:*:*:*:*:*",
"Dropbox=merou":"cpe:2.3:a:dropbox:merou:*:*:*:*:*:*:*:*",
"dropwizard=dropwizard":"cpe:2.3:a:dropwizard:dropwizard_validation:*:*:*:*:*:*:*:*",
"Drupal.org=Drupal Core":"cpe:2.3:a:drupal:drupal:*:*:*:*:*:*:*:*",
"Drupal=Core":"cpe:2.3:a:drupal:drupal:*:*:*:*:*:*:*:*",
"Drupal=Drupal Core":"cpe:2.3:a:drupal:drupal:*:*:*:*:*:*:*:*",
"Drupal=Drupal core":"cpe:2.3:a:drupal:drupal:*:*:*:*:*:*:*:*",
"dstar2018=Agency":"cpe:2.3:a:dstar2018:agency:*:*:*:*:*:*:*:*",
"Dulldusk=PHP File Manager":"cpe:2.3:a:dulldusk:phpfilemanager:*:*:*:*:*:*:*:*",
"duncanmcclean=guest-entries":"cpe:2.3:a:duncanmcclean:guest_entries:*:*:*:*:*:*:*:*",
"Cisco=Cisco Duo Authentication Proxy":"cpe:2.3:a:duo:authentication_proxy:*:*:*:*:*:*:*:*",
"Cisco=DUO Connect":"cpe:2.3:a:duo:duoconnect:*:*:*:*:*:*:*:*",
"DSpace=DSpace":"cpe:2.3:a:duraspace:dspace:*:*:*:*:*:*:*:*",
"annyshow=DuxCMS":"cpe:2.3:a:duxcms_project:duxcms:*:*:*:*:*:*:*:*",
"barronwaffles=dwc_network_server_emulator":"cpe:2.3:a:dwc_network_server_emulator_project:dwc_network_server_emulator:*:*:*:*:*:*:*:*",
"SourceCodester=E-Commerce System":"cpe:2.3:a:e-commerce_system_project:e-commerce_system:*:*:*:*:*:*:*:*",
"EDM Informatics=E-invoice":"cpe:2.3:a:e-invoice_project:e-invoice:*:*:*:*:*:*:*:*",
"Weaver=E-Office":"cpe:2.3:a:e-office:e-office:*:*:*:*:*:*:*:*",
"National Tax Agency=Installer of Setup file of advance preparation (jizen_setup.exe)":"cpe:2.3:a:e-tax.nta:e-tax:*:*:*:*:*:*:*:*",
"SourceCodester=Earnings and Expense Tracker App":"cpe:2.3:a:earnings_and_expense_tracker_app_project:earnings_and_expense_tracker_app:*:*:*:*:*:*:*:*",
"EaseUS=Todo Backup":"cpe:2.3:a:easeus:todo_backup:*:*:*:*:*:*:*:*",
"EFS Software=Easy Address Book Web Server":"cpe:2.3:a:easy_address_book_web_server_project:easy_address_book_web_server:*:*:*:*:*:*:*:*",
"EFS Software=Easy Chat Server":"cpe:2.3:a:easy_chat_server_project:easy_chat_server:*:*:*:*:*:*:*:*",
"EFS=Easy Chat Server":"cpe:2.3:a:easy_chat_server_project:easy_chat_server:*:*:*:*:*:*:*:*",
"EFS=Easy File Sharing FTP":"cpe:2.3:a:easy_file_sharing_ftp_server_project:easy_file_sharing_ftp_server:*:*:*:*:*:*:*:*",
"HWA JIUH DIGITAL TECHNOLOGY LTD.=EasyTest":"cpe:2.3:a:easy_test_project:easy_test:*:*:*:*:*:*:*:*",
"alextselegidis=alextselegidis/easyappointments":"cpe:2.3:a:easyappointments:easyappointments:*:*:*:*:*:*:*:*",
"easybuilders=easybuild-framework":"cpe:2.3:a:easybuild_project:easybuild:*:*:*:*:*:*:*:*",
"EasyCorp=ZenTao Pro":"cpe:2.3:a:easycorp:zentao_pro:*:*:*:*:*:*:*:*",
"easyii=CMS":"cpe:2.3:a:easyiicms:easyiicms:*:*:*:*:*:*:*:*",
"icret=EasyImages":"cpe:2.3:a:easyimages2.0_project:easyimages2.0:*:*:*:*:*:*:*:*",
"icret=icret/easyimages2.0":"cpe:2.3:a:easyimages2.0_project:easyimages2.0:*:*:*:*:*:*:*:*",
"EasyPHP=Webserver":"cpe:2.3:a:easyphp:webserver:*:*:*:*:*:*:*:*",
"EasyTor Applications=EasyTor Applications":"cpe:2.3:a:easytor:easytor:*:*:*:*:*:*:*:*",
"EasyUse Digital Technology=MailHunter Ultimate":"cpe:2.3:a:easyuse:mailhunter_ultimate:*:*:*:*:*:*:*:*",
"EasyUse=MailHunter Ultimate":"cpe:2.3:a:easyuse:mailhunter_ultimate:*:*:*:*:*:*:*:*",
"Eaton=9000x Programming and Configuration Software":"cpe:2.3:a:eaton:9000x_programming_and_configuration_software:*:*:*:*:*:*:*:*",
"Eaton=easySoft":"cpe:2.3:a:eaton:easysoft:*:*:*:*:*:*:*:*",
"Eaton=easySoft Software":"cpe:2.3:a:eaton:easysoft:*:*:*:*:*:*:*:*",
"Eaton=Eaton ELCSoft":"cpe:2.3:a:eaton:elcsoft:*:*:*:*:*:*:*:*",
"Eaton=Foreseer EPMS":"cpe:2.3:a:eaton:foreseer_electrical_power_monitoring_system:*:*:*:*:*:*:*:*",
"Eaton=Intelligent Power Manager (IPM 1)":"cpe:2.3:a:eaton:intelligent_power_manager:*:*:*:*:*:*:*:*",
"Eaton=Intelligent Power Manager Infrastructure (IPM Infrastructure)":"cpe:2.3:a:eaton:intelligent_power_manager:*:*:*:*:*:*:*:*",
"Eaton=Intelligent Power manager (IPM)":"cpe:2.3:a:eaton:intelligent_power_manager:*:*:*:*:*:*:*:*",
"Eaton=Intelligent Power Manager Infrastructure (IPM Infrastructure)":"cpe:2.3:a:eaton:intelligent_power_manager_infrastructure:*:*:*:*:*:*:*:*",
"Eaton=Eaton Intelligent Power Protector (IPP)":"cpe:2.3:a:eaton:intelligent_power_protector:*:*:*:*:*:*:*:*",
"Eaton=Intelligent Power Protector":"cpe:2.3:a:eaton:intelligent_power_protector:*:*:*:*:*:*:*:*",
"Eaton=UPS Companion Software":"cpe:2.3:a:eaton:ups_companion:*:*:*:*:*:*:*:*",
" ebics-java=ebics-java-client ":"cpe:2.3:a:ebics_java_project:ebics_java:*:*:*:*:*:*:*:*",
"LTD.=EC-CUBE plugin 'Amazon Pay Plugin 2.12,2.13'":"cpe:2.3:a:ec-cube:amazon_pay:*:*:*:*:*:*:*:*",
"EC-CUBE CO.,LTD.=EC-CUBE":"cpe:2.3:a:ec-cube:ec-cube:*:*:*:*:*:*:*:*",
"EC-CUBE CO.,LTD.=EC-CUBE 2 series":"cpe:2.3:a:ec-cube:ec-cube:*:*:*:*:*:*:*:*",
"EC-CUBE CO.,LTD.=EC-CUBE 4 series":"cpe:2.3:a:ec-cube:ec-cube:*:*:*:*:*:*:*:*",
"LOCKON CO.,LTD.=EC-CUBE":"cpe:2.3:a:ec-cube:ec-cube:*:*:*:*:*:*:*:*",
"ecies=go":"cpe:2.3:a:ecies:go:*:*:*:*:*:*:*:*",
"BroadLearning=eclass":"cpe:2.3:a:eclass:eclass_ip:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Buildship":"cpe:2.3:a:eclipse:buildship:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse BIRT":"cpe:2.3:a:eclipse:business_intelligence_and_reporting_tools:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse BIRT (Business Intelligence Reporting Tool)":"cpe:2.3:a:eclipse:business_intelligence_and_reporting_tools:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Californium":"cpe:2.3:a:eclipse:californium:*:*:*:*:*:*:*:*",
"eclipse-californium=californium":"cpe:2.3:a:eclipse:californium:*:*:*:*:*:*:*:*",
"Red Hat=Eclipse Che":"cpe:2.3:a:eclipse:che:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Che":"cpe:2.3:a:eclipse:che:*:*:*:*:*:*:*:*",
"Eclipse=CycloneDDS":"cpe:2.3:a:eclipse:cyclonedds:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Equinox":"cpe:2.3:a:eclipse:equinox:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Equinox p2":"cpe:2.3:a:eclipse:equinox_p2:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Glassfish":"cpe:2.3:a:eclipse:glassfish:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse GlassFish":"cpe:2.3:a:eclipse:glassfish:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Hono":"cpe:2.3:a:eclipse:hono:*:*:*:*:*:*:*:*",
"Check Point Software Technologies Ltd.=Eclipse":"cpe:2.3:a:eclipse:ide:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Jetty":"cpe:2.3:a:eclipse:jetty:*:*:*:*:*:*:*:*",
"eclipse=jetty.project":"cpe:2.3:a:eclipse:jetty:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Eclipse JGit":"cpe:2.3:a:eclipse:jgit:*:*:*:*:*:*:*:*",
"eclipse=keti":"cpe:2.3:a:eclipse:keti:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Eclipse Kura Installer":"cpe:2.3:a:eclipse:kura:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Kura":"cpe:2.3:a:eclipse:kura:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Lyo":"cpe:2.3:a:eclipse:lyo:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Eclipse Memory Analyzer (tools.mat)":"cpe:2.3:a:eclipse:memory_analyzer:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Memory Analyzer":"cpe:2.3:a:eclipse:memory_analyzer:*:*:*:*:*:*:*:*",
"Eclipse=Mosquitto":"cpe:2.3:a:eclipse:mosquitto:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Mosquitto":"cpe:2.3:a:eclipse:mosquitto:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse OMR":"cpe:2.3:a:eclipse:omr:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Eclipse OpenJ9":"cpe:2.3:a:eclipse:openj9:*:*:*:*:*:*:*:*",
"Eclipse Foundation=OpenJ9":"cpe:2.3:a:eclipse:openj9:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse OMR":"cpe:2.3:a:eclipse:openj9:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse OpenJ9":"cpe:2.3:a:eclipse:openj9:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Paho":"cpe:2.3:a:eclipse:paho_java_client:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Paho MQTT C Client":"cpe:2.3:a:eclipse:paho_mqtt_c\/c\+\+_client:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Parsson":"cpe:2.3:a:eclipse:parsson:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Platform":"cpe:2.3:a:eclipse:platform:*:*:*:*:*:*:*:*",
"Eclipse Foundation=Eclipse RAP":"cpe:2.3:a:eclipse:remote_application_platform:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Sphinx":"cpe:2.3:a:eclipse:sphinx:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=@theia/plugin-ext":"cpe:2.3:a:eclipse:theia:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Theia":"cpe:2.3:a:eclipse:theia:*:*:*:*:*:*:*:*",
"vert-x3=vertx-web":"cpe:2.3:a:eclipse:vert.x-web:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Vert.x":"cpe:2.3:a:eclipse:vert.x:*:*:*:*:*:*:*:*",
"vert-x3=vertx-stomp":"cpe:2.3:a:eclipse:vert.x_stomp:*:*:*:*:*:*:*:*",
"The Eclipse Foundation=Eclipse Vorto":"cpe:2.3:a:eclipse:vorto:*:*:*:*:*:*:*:*",
"Econolite=EOS":"cpe:2.3:a:econolite:eos:*:*:*:*:*:*:*:*",
"econosys system=php_mailform":"cpe:2.3:a:econosys-system:php_mailform:*:*:*:*:*:*:*:*",
"eCosCentric=eCosPro RTOS":"cpe:2.3:a:ecoscentric:ecospro:*:*:*:*:*:*:*:*",
"e-Excellence Inc.=U-Office Force":"cpe:2.3:a:edetw:u-office_force:*:*:*:*:*:*:*:*",
"e-Excellence=U-Office Force":"cpe:2.3:a:edetw:u-office_force:*:*:*:*:*:*:*:*",
"GitSquared=edex-ui":"cpe:2.3:a:edex-ui_project:edex-ui:*:*:*:*:*:*:*:*",
"edgexfoundry=edgex-go":"cpe:2.3:a:edgexfoundry:edgex_foundry:*:*:*:*:*:*:*:*",
"Financial Services Agency=XBRL data create application":"cpe:2.3:a:edinet-fsa:xbrl_data_create:*:*:*:*:*:*:*:*",
"EditorConfig=EditorConfig C Core":"cpe:2.3:a:editorconfig:editorconfig:*:*:*:*:*:*:*:*",
"openedx=edx-platform":"cpe:2.3:a:edx:edx-platform:*:*:*:*:*:*:*:*",
"European Environment Agency=eionet.contreg":"cpe:2.3:a:eea:eionet_content_registry:*:*:*:*:*:*:*:*",
"effectindex=tripreporter":"cpe:2.3:a:effectindex:tripreporter:*:*:*:*:*:*:*:*",
"seccome=Ehoney":"cpe:2.3:a:ehoney_project:ehoney:*:*:*:*:*:*:*:*",
"ehuacui=bbs":"cpe:2.3:a:ehuacui-bbs_project:ehuacui-bbs:*:*:*:*:*:*:*:*",
"Excellent Infotek Corporation=e-document system":"cpe:2.3:a:eic:e-document_system:*:*:*:*:*:*:*:*",
"EikiSoft=Archive collectively operation utility":"cpe:2.3:a:eikisoft:archive_collectively_operation_utility:*:*:*:*:*:*:*:*",
"EKAKIN=Shihonkanri Plus":"cpe:2.3:a:ekakin:shihonkanri_plus:*:*:*:*:*:*:*:*",
"Ekol Informatics=Website Template":"cpe:2.3:a:ekolbilisim:web_sablonu_yazilimi:*:*:*:*:*:*:*:*",
"elabftw=elabftw":"cpe:2.3:a:elabftw:elabftw:*:*:*:*:*:*:*:*",
"Elastic=Elastic APM .NET Agent":"cpe:2.3:a:elastic:apm_.net_agent:*:*:*:*:*:*:*:*",
"Elastic=Elastic APM Java Agent":"cpe:2.3:a:elastic:apm_java_agent:*:*:*:*:*:*:*:*",
"Elastic=APM Server":"cpe:2.3:a:elastic:apm_server:*:*:*:*:*:*:*:*",
"Elastic=Elastic Agent":"cpe:2.3:a:elastic:elastic_agent:*:*:*:*:*:*:*:*",
"Elastic=Elastic App Search":"cpe:2.3:a:elastic:elastic_app_search:*:*:*:*:*:*:*:*",
"Elastic=Beats":"cpe:2.3:a:elastic:elastic_beats:*:*:*:*:*:*:*:*",
"Elastic=Elastic Cloud Enterprise":"cpe:2.3:a:elastic:elastic_cloud_enterprise:*:*:*:*:*:*:*:*",
"Elastic=Elastic Cloud Enterprise (ECE)":"cpe:2.3:a:elastic:elastic_cloud_enterprise:*:*:*:*:*:*:*:*",
"Elastic=Elastic Cloud on Kubernetes":"cpe:2.3:a:elastic:elastic_cloud_on_kubernetes:*:*:*:*:*:*:*:*",
"Elastic=Elastic Sharepoint Online Python Connector":"cpe:2.3:a:elastic:elastic_sharepoint_online_python_connector:*:*:*:*:*:*:*:*",
"Elastic=Elasticsearch":"cpe:2.3:a:elastic:elasticsearch:*:*:*:*:*:*:*:*",
"Elastic=Elasticsearch-Hadoop":"cpe:2.3:a:elastic:elasticsearch:*:*:*:*:*:*:*:*",
"Elastic=elasticsearch":"cpe:2.3:a:elastic:elasticsearch:*:*:*:*:*:*:*:*",
"Elastic=Endpoint":"cpe:2.3:a:elastic:endpoint:*:*:*:*:*:*:*:*",
"Elastic=Elastic Enterprise Search":"cpe:2.3:a:elastic:enterprise_search:*:*:*:*:*:*:*:*",
"Elastic=Enterprise Search":"cpe:2.3:a:elastic:enterprise_search:*:*:*:*:*:*:*:*",
"Elastic=Enterprisesearch":"cpe:2.3:a:elastic:enterprise_search:*:*:*:*:*:*:*:*",
"Elastic=Filebeat":"cpe:2.3:a:elastic:filebeat:*:*:*:*:*:*:*:*",
"Elastic=Fleet Server":"cpe:2.3:a:elastic:fleet_server:*:*:*:*:*:*:*:*",
"Elastic=Elastic Code":"cpe:2.3:a:elastic:kibana:*:*:*:*:*:*:*:*",
"Elastic=Elastic X-Pack Security":"cpe:2.3:a:elastic:kibana:*:*:*:*:*:*:*:*",
"Elastic=Kibana":"cpe:2.3:a:elastic:kibana:*:*:*:*:*:*:*:*",
"Elastic=Kibana X-Pack Security":"cpe:2.3:a:elastic:kibana:*:*:*:*:*:*:*:*",
"Elastic=kibana":"cpe:2.3:a:elastic:kibana:*:*:*:*:*:*:*:*",
"Elastic=Elasticsearch":"cpe:2.3:a:elastic:logstash:*:*:*:*:*:*:*:*",
"Elastic=Logstash":"cpe:2.3:a:elastic:logstash:*:*:*:*:*:*:*:*",
"Elastic=Elastic Network Drive Connector":"cpe:2.3:a:elastic:network_drive_connector:*:*:*:*:*:*:*:*",
"Elastic=Logstash":"cpe:2.3:a:elastic:winlogbeat:*:*:*:*:*:*:*:*",
"Elastic=Elastic X-Pack Alerting":"cpe:2.3:a:elastic:x-pack:*:*:*:*:*:*:*:*",
"Elastic=Elastic X-Pack Security":"cpe:2.3:a:elastic:x-pack:*:*:*:*:*:*:*:*",
"Elastic=Elasticsearch X-Pack Security":"cpe:2.3:a:elastic:x-pack:*:*:*:*:*:*:*:*",
"Elastic=X-Pack Security":"cpe:2.3:a:elastic:x-pack:*:*:*:*:*:*:*:*",
"Elastic=Elastic Cloud Enterprise":"cpe:2.3:a:elasticsearch:cloud_enterprise:*:*:*:*:*:*:*:*",
"Elastic=Packetbeat":"cpe:2.3:a:elasticsearch:packetbeat:*:*:*:*:*:*:*:*",
"SourceCodester=eLearning System":"cpe:2.3:a:elearning_system_project:elearning_system:*:*:*:*:*:*:*:*",
"ELECOM CO.,LTD.=ELECOM File Manager":"cpe:2.3:a:elecom:file_manager:*:*:*:*:*:*:*:*",
"Election Services Co. (ESC)=Internet Election Service":"cpe:2.3:a:electionservicesco:internet_election_service:*:*:*:*:*:*:*:*",
"Electra=OSK201":"cpe:2.3:a:electra-air:smart_kit_for_split_ac:*:*:*:*:*:*:*:*",
"SourceCodester=Electronic Medical Records System":"cpe:2.3:a:electronic_medical_records_system_project:electronic_medical_records_system:*:*:*:*:*:*:*:*",
"electron=electron":"cpe:2.3:a:electronjs:electron:*:*:*:*:*:*:*:*",
"MrChuckomo=poddycast":"cpe:2.3:a:electronjs:poddycast:*:*:*:*:*:*:*:*",
"flar2=ElementalX":"cpe:2.3:a:elementalx:elementalx:*:*:*:*:*:*:*:*",
"finixbit=elf-parser":"cpe:2.3:a:elf-parser_project:elf-parser:*:*:*:*:*:*:*:*",
"liyansong2018=elfspirit":"cpe:2.3:a:elfspirit_project:elfspirit:*:*:*:*:*:*:*:*",
"elgg=elgg/elgg":"cpe:2.3:a:elgg:elgg:*:*:*:*:*:*:*:*",
"yahoo=elide":"cpe:2.3:a:elide:elide:*:*:*:*:*:*:*:*",
"PHPMemcachedAdmin =PHPMemcachedAdmin ":"cpe:2.3:a:elijaa:phpmemcachedadmin:*:*:*:*:*:*:*:*",
"ELITE=Web Fax":"cpe:2.3:a:elite:webfax:*:*:*:*:*:*:*:*",
"Ellucian=Ethos Identity":"cpe:2.3:a:ellucian:ethos_identity:*:*:*:*:*:*:*:*",
"Elra=Parkmatik":"cpe:2.3:a:elra:parkmatik:*:*:*:*:*:*:*:*",
"ElrondNetwork=elrond-go":"cpe:2.3:a:elrond:elrond_go:*:*:*:*:*:*:*:*",
"elves=elvish":"cpe:2.3:a:elv:elvish:*:*:*:*:*:*:*:*",
"elvexys=StreamX":"cpe:2.3:a:elvexys:streamx:*:*:*:*:*:*:*:*",
"EmailGPT=EmailGPT":"cpe:2.3:a:emailgpt:emailgpt:*:*:*:*:*:*:*:*",
"embedthis=goahead":"cpe:2.3:a:embedthis:goahead:*:*:*:*:*:*:*:*",
"EmbySupport=security":"cpe:2.3:a:emby:emby.releases:*:*:*:*:*:*:*:*",
"Media Browser=Emby Server":"cpe:2.3:a:emby:emby.releases:*:*:*:*:*:*:*:*",
"MediaBrowser=Emby.Releases":"cpe:2.3:a:emby:emby.releases:*:*:*:*:*:*:*:*",
"Dell=Dell EMC AppSync":"cpe:2.3:a:emc:appsync:*:*:*:*:*:*:*:*",
"Dell EMC=Dell EMC Data Protection Advisor":"cpe:2.3:a:emc:data_protection_advisor:*:*:*:*:*:*:*:*",
"Dell EMC=EMC Data Protection Advisor":"cpe:2.3:a:emc:data_protection_advisor:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=Lenovo ElanTech Touchpad driver":"cpe:2.3:a:emc:elan_touchpad_driver:*:*:*:*:*:*:*:*",
"DELL EMC=ESRS Policy Manager":"cpe:2.3:a:emc:esrs_policy_manager:*:*:*:*:*:*:*:*",
"RSA=Certificate Manager Path Traversal Vulnerability":"cpe:2.3:a:emc:rsa_certificate_manager:*:*:*:*:*:*:*:*",
"RSA=RSA Identity Governance and Lifecycle":"cpe:2.3:a:emc:rsa_identity_governance_and_lifecycle:*:*:*:*:*:*:*:*",
"DELL EMC=ESRS Virtual Edition":"cpe:2.3:a:emc:secure_remote_services:*:*:*:*:*:*:*:*",
"Dell EMC=ViPR Controller":"cpe:2.3:a:emc:vipr_controller:*:*:*:*:*:*:*:*",
"Emerson=AMS Device Manager":"cpe:2.3:a:emerson:ams_device_manager:*:*:*:*:*:*:*:*",
"Emerson=DeltaV DCS":"cpe:2.3:a:emerson:deltav:*:*:*:*:*:*:*:*",
"Emerson=Emerson DeltaV":"cpe:2.3:a:emerson:deltav:*:*:*:*:*:*:*:*",
"ICS-CERT=DeltaV":"cpe:2.3:a:emerson:deltav:*:*:*:*:*:*:*:*",
"Emerson=Open Enterprise":"cpe:2.3:a:emerson:openenterprise_scada_server:*:*:*:*:*:*:*:*",
"Emerson=OpenEnterprise SCADA Software":"cpe:2.3:a:emerson:openenterprise_scada_server:*:*:*:*:*:*:*:*",
"unspecified=emlog":"cpe:2.3:a:emlog:emlog:*:*:*:*:*:*:*:*",
"SourceCodester=Employee and Visitor Gate Pass Logging System":"cpe:2.3:a:employee_and_visitor_gate_pass_logging_system_project:employee_and_visitor_gate_pass_logging_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Employee Leaves Management System":"cpe:2.3:a:employee_leaves_management_system_project:employee_leaves_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Employee Management System":"cpe:2.3:a:employee_management_system_project:employee_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Employee Payslip Generator":"cpe:2.3:a:employee_payslip_generator_system_project:employee_payslip_generator_system:*:*:*:*:*:*:*:*",
"SourceCodester=Employee Payslip Generator with Sending Mail":"cpe:2.3:a:employee_payslip_generator_system_project:employee_payslip_generator_system:*:*:*:*:*:*:*:*",
"CodeAstro=Employee Task Management System":"cpe:2.3:a:employee_task_management_system_project:employee_task_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Employee Task Management System":"cpe:2.3:a:employee_task_management_system_project:employee_task_management_system:*:*:*:*:*:*:*:*",
"EMQ Technologies=EMQ X Broker":"cpe:2.3:a:emqx:emq_x_broker:*:*:*:*:*:*:*:*",
"Emuse=eServices / eNvoice":"cpe:2.3:a:emuse_-_eservices_\/_envoice_project:emuse_-_eservices_\/_envoice:*:*:*:*:*:*:*:*",
"Enbra=EWM 1.7.29":"cpe:2.3:a:enbra:ewm:*:*:*:*:*:*:*:*",
"Agency for Natural Resources and Energy of Ministry of Economy,Trade and Industry (METI)=Installer for Shin Kikan Toukei Houkoku Data Nyuryokuyou Program (program released on 2013 September 30)":"cpe:2.3:a:enecho.meti:shin_kikan_toukei_houkoku_data_nyuryokuyou_program:*:*:*:*:*:*:*:*",
"Agency for Natural Resources and Energy of Ministry of Economy,Trade and Industry (METI)=Installer for Shin Kinkyuji Houkoku Data Nyuryoku Program (program released on 2011 March 10)":"cpe:2.3:a:enecho.meti:shin_kinkyuji_houkoku_data_nyuryoku_program:*:*:*:*:*:*:*:*",
"Agency for Natural Resources and Energy of Ministry of Economy,Trade and Industry (METI)=Installer for Shin Sekiyu Yunyu Chousa Houkoku Data Nyuryoku Program (program released on 2013 September 30)":"cpe:2.3:a:enecho.meti:shin_sekiyu_yunyu_chousa_houkoku_data_nyuryoku_program:*:*:*:*:*:*:*:*",
"Agency for Natural Resources and Energy of Ministry of Economy,Trade and Industry (METI)=Teikihoukokusho Sakuseishien Tool":"cpe:2.3:a:enecho.meti:teikihoukokusho_sakuseishien_tool:*:*:*:*:*:*:*:*",
"KnowageLabs=Knowage-Server":"cpe:2.3:a:eng:knowage:*:*:*:*:*:*:*:*",
"engelsystem=engelsystem":"cpe:2.3:a:engelsystem:engelsystem:*:*:*:*:*:*:*:*",
"SourceCodester=Engineers Online Portal":"cpe:2.3:a:engineers_online_portal_project:engineers_online_portal:*:*:*:*:*:*:*:*",
"osticket=osticket/osticket":"cpe:2.3:a:enhancesoft:osticket:*:*:*:*:*:*:*:*",
"Enphase =Enphase Installer Toolkit":"cpe:2.3:a:enphase:installer_toolkit:*:*:*:*:*:*:*:*",
"Morgan-Phoenix=EnroCrypt":"cpe:2.3:a:enrocrypt_project:enrocrypt:*:*:*:*:*:*:*:*",
"ensdomains=@ensdomains/ens":"cpe:2.3:a:ens.domains:ethereum_name_service:*:*:*:*:*:*:*:*",
"ensdomains=ens-contracts":"cpe:2.3:a:ens.domains:ethereum_name_service:*:*:*:*:*:*:*:*",
"EnTech=Monitor Asset Manager":"cpe:2.3:a:entechtaiwan:monitor_asset_manager:*:*:*:*:*:*:*:*",
"EnterpriseDT=CompleteFTP":"cpe:2.3:a:enterprisedt:completeftp_server:*:*:*:*:*:*:*:*",
"Brondahl=EnumStringValues":"cpe:2.3:a:enumstringvalues_project:enumstringvalues:*:*:*:*:*:*:*:*",
"envoyproxy=envoy":"cpe:2.3:a:envoyproxy:envoy:*:*:*:*:*:*:*:*",
"eolinker=apinto-dashboard":"cpe:2.3:a:eolink:apinto-dashboard:*:*:*:*:*:*:*:*",
"eolinker=goku_lite":"cpe:2.3:a:eolink:goku_lite:*:*:*:*:*:*:*:*",
"Epic Games=Epic Games Launcher":"cpe:2.3:a:epicgames:launcher:*:*:*:*:*:*:*:*",
"eProsima=Fast DDS":"cpe:2.3:a:eprosima:fast_dds:*:*:*:*:*:*:*:*",
"eProsima=Fast-DDS":"cpe:2.3:a:eprosima:fast_dds:*:*:*:*:*:*:*:*",
"Epson=Epson AirPrint":"cpe:2.3:a:epson:airprint:*:*:*:*:*:*:*:*",
"Ericsson=Ericsson Network Manager":"cpe:2.3:a:ericsson:network_manager:*:*:*:*:*:*:*:*",
"Ericsson-LG=iPCES UCM":"cpe:2.3:a:ericssonlg:ipecs:*:*:*:*:*:*:*:*",
"Erikoglu Technology=ErMon":"cpe:2.3:a:erikogluteknoloji:energy_monitoring:*:*:*:*:*:*:*:*",
"jerryhanjj=ERP":"cpe:2.3:a:erp_project:erp:*:*:*:*:*:*:*:*",
"Sourcecodehero=ERP System Project":"cpe:2.3:a:erp_system_project_project:erp_system_project:*:*:*:*:*:*:*:*",
"Talos=ERPNext":"cpe:2.3:a:erpnext:erpnext:*:*:*:*:*:*:*:*",
"erudika=erudika/para":"cpe:2.3:a:erudika:para:*:*:*:*:*:*:*:*",
"erudika=erudika/scoold":"cpe:2.3:a:erudika:scoold:*:*:*:*:*:*:*:*",
"npm=erxes":"cpe:2.3:a:erxes:erxes:*:*:*:*:*:*:*:*",
"esdoc=esdoc-publish-html-plugin":"cpe:2.3:a:esdoc:esdoc-publish-html-plugin:*:*:*:*:*:*:*:*",
"ESDS=Emagic Data Center Management Suite":"cpe:2.3:a:esds.co:emagic_data_center_management:*:*:*:*:*:*:*:*",
"ESKOM Computer=e-municipality module":"cpe:2.3:a:eskom:e-belediye:*:*:*:*:*:*:*:*",
"Eskom=e-Belediye":"cpe:2.3:a:eskom:e-belediye:*:*:*:*:*:*:*:*",
"Eskom=Water Metering Software":"cpe:2.3:a:eskom:el_terminali_\(su_okuma\)_uygulamalarimiz:*:*:*:*:*:*:*:*",
"earclink=ESPCMS":"cpe:2.3:a:espcms:espcms:*:*:*:*:*:*:*:*",
"EspoCRM=EspoCRM":"cpe:2.3:a:espocrm:espocrm:*:*:*:*:*:*:*:*",
"espocrm=espocrm":"cpe:2.3:a:espocrm:espocrm:*:*:*:*:*:*:*:*",
"espressif=esp-idf":"cpe:2.3:a:espressif:esp-idf:*:*:*:*:*:*:*:*",
"Esri=ArcGIS Earth":"cpe:2.3:a:esri:arcgis_earth:*:*:*:*:*:*:*:*",
"Esri=ArcGIS Server":"cpe:2.3:a:esri:arcgis_enterprise:*:*:*:*:*:*:*:*",
"Esri=ArcGIS GeoEvent Server ":"cpe:2.3:a:esri:arcgis_geoevent_server:*:*:*:*:*:*:*:*",
"Esri=ArcGIS Insights":"cpe:2.3:a:esri:arcgis_insights:*:*:*:*:*:*:*:*",
"Esri =ArcGIS Server":"cpe:2.3:a:esri:arcgis_server:*:*:*:*:*:*:*:*",
"Esri=ArcGIS Server":"cpe:2.3:a:esri:arcgis_server:*:*:*:*:*:*:*:*",
"Esri=Server":"cpe:2.3:a:esri:arcgis_server:*:*:*:*:*:*:*:*",
"Esri =ArcReader":"cpe:2.3:a:esri:arcreader:*:*:*:*:*:*:*:*",
"Esri=ArcReader":"cpe:2.3:a:esri:arcreader:*:*:*:*:*:*:*:*",
"Esri =Portal for ArcGIS":"cpe:2.3:a:esri:portal_for_arcgis:*:*:*:*:*:*:*:*",
"Esri=ArcGIS  Quickcapture":"cpe:2.3:a:esri:portal_for_arcgis:*:*:*:*:*:*:*:*",
"Esri=ArcGIS Enterprise":"cpe:2.3:a:esri:portal_for_arcgis:*:*:*:*:*:*:*:*",
"Esri=Portal for ArcGIS":"cpe:2.3:a:esri:portal_for_arcgis:*:*:*:*:*:*:*:*",
"Esri=Portal sites":"cpe:2.3:a:esri:portal_for_arcgis:*:*:*:*:*:*:*:*",
"Estomed Sp. z o.o.=Simple Care":"cpe:2.3:a:estomed:simple_care:*:*:*:*:*:*:*:*",
"Simplesite=Payment EX":"cpe:2.3:a:estore-wss:payment_ex:*:*:*:*:*:*:*:*",
"ESTSOFT=ALTOOLS Update Service":"cpe:2.3:a:estsoft:altools:*:*:*:*:*:*:*:*",
"ESTsoft=Alyac":"cpe:2.3:a:estsoft:alyac:*:*:*:*:*:*:*:*",
"Eatan=CMS":"cpe:2.3:a:etan:etan_cms:*:*:*:*:*:*:*:*",
"etcd-io=etcd":"cpe:2.3:a:etcd:etcd:*:*:*:*:*:*:*:*",
"Jason Gauci=Eternal Terminal":"cpe:2.3:a:eternal_terminal_project:eternal_terminal:*:*:*:*:*:*:*:*",
"ethercreative=logs":"cpe:2.3:a:ethercreative:logs:*:*:*:*:*:*:*:*",
"Talos=CPP-Ethereum":"cpe:2.3:a:ethereum:aleth:*:*:*:*:*:*:*:*",
"Talos=CPP-Ethereum":"cpe:2.3:a:ethereum:cpp-ethereum:*:*:*:*:*:*:*:*",
"Talos=CPP-Ethereum":"cpe:2.3:a:ethereum:ethereum_virtual_machine:*:*:*:*:*:*:*:*",
"ethereum=go-ethereum":"cpe:2.3:a:ethereum:go_ethereum:*:*:*:*:*:*:*:*",
"ether=etherpad-lite":"cpe:2.3:a:etherpad:etherpad:*:*:*:*:*:*:*:*",
"Ethex=Contracts":"cpe:2.3:a:ethex:ethex_contracts:*:*:*:*:*:*:*:*",
"ethyca=fides":"cpe:2.3:a:ethyca:fides:*:*:*:*:*:*:*:*",
"Repox=Repox":"cpe:2.3:a:europeana:repox:*:*:*:*:*:*:*:*",
"EuroSoft Sp. z o. o.=Eurosoft Przychodnia":"cpe:2.3:a:eurosoft:przychodnia:*:*:*:*:*:*:*:*",
"paxswill=EVE Ship Replacement Program":"cpe:2.3:a:eve_ship_replacement_program_project:eve_ship_replacement_program:*:*:*:*:*:*:*:*",
"SourceCodester=Royale Event Management System":"cpe:2.3:a:event_management_system_project:event_management_system:*:*:*:*:*:*:*:*",
"URVE=Web Manager":"cpe:2.3:a:eveo:urve_web_manager:*:*:*:*:*:*:*:*",
"Everywhere=CMS":"cpe:2.3:a:everywhere:everywhere_cms:*:*:*:*:*:*:*:*",
"imgproxy=imgproxy/imgproxy":"cpe:2.3:a:evilmartians:imgproxy:*:*:*:*:*:*:*:*",
"tharsis=evmos":"cpe:2.3:a:evmos:evmos:*:*:*:*:*:*:*:*",
"Evolution Events=Artaxerxes":"cpe:2.3:a:evolution-events:artaxerxes:*:*:*:*:*:*:*:*",
"Exadel=Flamingo amf-serializer":"cpe:2.3:a:exadel:flamingo:*:*:*:*:*:*:*:*",
"Exadel=Flamingo amf-serializer":"cpe:2.3:a:exadel:flamingo_amf-serializer:*:*:*:*:*:*:*:*",
"Kajitori Co.,Ltd=Exment":"cpe:2.3:a:exceedone:exment:*:*:*:*:*:*:*:*",
"monitorjbl=excel-streaming-reader":"cpe:2.3:a:excel_streaming_reader_project:excel_streaming_reader:*:*:*:*:*:*:*:*",
"Executable Books=markdown-it-py":"cpe:2.3:a:executablebooks:markdown-it-py:*:*:*:*:*:*:*:*",
"Exiv2=exiv2":"cpe:2.3:a:exiv2:exiv2:*:*:*:*:*:*:*:*",
"eXo=Chat Application":"cpe:2.3:a:exoplatform:chat_application:*:*:*:*:*:*:*:*",
"SourceCodester=Expense Management System":"cpe:2.3:a:expense_management_system_project:expense_management_system:*:*:*:*:*:*:*:*",
"explorerplusplus=Explorer++.exe":"cpe:2.3:a:explorerplusplus:explorer\+\+:*:*:*:*:*:*:*:*",
"Expo.io=Expo AuthSession module":"cpe:2.3:a:expo:expo_software_development_kit:*:*:*:*:*:*:*:*",
"soerennb=eXtplorer":"cpe:2.3:a:extplorer:extplorer:*:*:*:*:*:*:*:*",
"Bflysoft=EyeSurfer BflyInstallerX.ocx":"cpe:2.3:a:eyesurfer:bflyinstallerx.ocx:*:*:*:*:*:*:*:*",
"EZB Systems=UltraISO":"cpe:2.3:a:ezbsystems:ultraiso:*:*:*:*:*:*:*:*",
"Thinkingreed Inc. =F-RevoCRM ":"cpe:2.3:a:f-revocrm:f-revocrm:*:*:*:*:*:*:*:*",
"Thinkingreed Inc.=F-RevoCRM":"cpe:2.3:a:f-revocrm:f-revocrm:*:*:*:*:*:*:*:*",
"F-Secure=F-Secure Internet Gatekeeper":"cpe:2.3:a:f-secure:internet_gatekeeper:*:*:*:*:*:*:*:*",
"F5=F5 Access for Android":"cpe:2.3:a:f5:access_for_android:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP (APM)":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP APM":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP APM; Enterprise Manager":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP (LTM, AAM, AFM, Analytics, APM, ASM, DNS, Edge Gateway, FPS, GTM, Link Controller, PEM, WebAccelerator)":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP APM":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP APM Edge Client":"cpe:2.3:a:f5:big-ip_access_policy_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP APM client for Windows":"cpe:2.3:a:f5:big-ip_access_policy_manager_client:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP AFM":"cpe:2.3:a:f5:big-ip_advanced_firewall_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP":"cpe:2.3:a:f5:big-ip_advanced_firewall_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP (AFM)":"cpe:2.3:a:f5:big-ip_advanced_firewall_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP AFM":"cpe:2.3:a:f5:big-ip_advanced_firewall_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP (AAM)":"cpe:2.3:a:f5:big-ip_application_acceleration_manager:*:*:*:*:*:*:*:*",
"BIG-IP=BIG-IP (ASM)":"cpe:2.3:a:f5:big-ip_application_security_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP (ASM)":"cpe:2.3:a:f5:big-ip_application_security_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP ASM":"cpe:2.3:a:f5:big-ip_application_security_manager:*:*:*:*:*:*:*:*",
"F5 Networks=BIG-IP ASM":"cpe:2.3:a:f5:big-ip_application_security_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP (ASM)":"cpe:2.3:a:f5:big-ip_application_security_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP ASM":"cpe:2.3:a:f5:big-ip_application_security_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP":"cpe:2.3:a:f5:big-ip_domain_name_system:*:*:*:*:*:*:*:*",
"F5=BIG-IP DNS":"cpe:2.3:a:f5:big-ip_domain_name_system:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP (LTM)":"cpe:2.3:a:f5:big-ip_local_traffic_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP":"cpe:2.3:a:f5:big-ip_local_traffic_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP Next SPK":"cpe:2.3:a:f5:big-ip_next_service_proxy_for_kubernetes:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP (PEM)":"cpe:2.3:a:f5:big-ip_policy_enforcement_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IP PEM":"cpe:2.3:a:f5:big-ip_policy_enforcement_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=F5 BIG-IP PEM":"cpe:2.3:a:f5:big-ip_policy_enforcement_manager:*:*:*:*:*:*:*:*",
"F5=BIG-IP PEM":"cpe:2.3:a:f5:big-ip_policy_enforcement_manager:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=BIG-IQ Centralized Management":"cpe:2.3:a:f5:big-iq_centralized_management:*:*:*:*:*:*:*:*",
"F5=BIG-IQ":"cpe:2.3:a:f5:big-iq_centralized_management:*:*:*:*:*:*:*:*",
"F5=F5OS - Appliance":"cpe:2.3:a:f5:f5os-a:*:*:*:*:*:*:*:*",
"F5=NGINX Ingress Controller":"cpe:2.3:a:f5:nginx_ingress_controller:*:*:*:*:*:*:*:*",
"F5=NGINX Instance Manager":"cpe:2.3:a:f5:nginx_instance_manager:*:*:*:*:*:*:*:*",
"F5=NGINX Service Mesh":"cpe:2.3:a:f5:nginx_service_mesh:*:*:*:*:*:*:*:*",
"F5 Networks=SSL Intercept iApp version":"cpe:2.3:a:f5:ssl_intercept_iapp:*:*:*:*:*:*:*:*",
"F5=F5 SSL Orchestrator":"cpe:2.3:a:f5:ssl_orchestrator:*:*:*:*:*:*:*:*",
"F5=SSL Orchestrator":"cpe:2.3:a:f5:ssl_orchestrator:*:*:*:*:*:*:*:*",
"F5=Traffix SDC":"cpe:2.3:a:f5:traffix_signaling_delivery_controller:*:*:*:*:*:*:*:*",
"F5 Networks, Inc.=F5 WebSafe Alert Server":"cpe:2.3:a:f5:websafe_alert_server:*:*:*:*:*:*:*:*",
"code-projects=E-Commerce Site":"cpe:2.3:a:fabianros:e-commerce_site:*:*:*:*:*:*:*:*",
"code-projects=Library Management System":"cpe:2.3:a:fabianros:library_management_system:*:*:*:*:*:*:*:*",
"code-projects=Online Faculty Clearance":"cpe:2.3:a:fabianros:online_faculty_clearance_system:*:*:*:*:*:*:*:*",
"code-projects=Simple Online Hotel Reservation System":"cpe:2.3:a:fabianros:simple_online_hotel_reservation_system:*:*:*:*:*:*:*:*",
"code-projects=Water Billing System":"cpe:2.3:a:fabianros:water_billing_system:*:*:*:*:*:*:*:*",
"FabulaTech=USB for Remote Desktop":"cpe:2.3:a:fabulatech:usb_for_remote_desktop:*:*:*:*:*:*:*:*",
"Facebook=Buck":"cpe:2.3:a:facebook:buck:*:*:*:*:*:*:*:*",
"Facebook=fizz":"cpe:2.3:a:facebook:fizz:*:*:*:*:*:*:*:*",
"Facebook=folly":"cpe:2.3:a:facebook:folly:*:*:*:*:*:*:*:*",
"Facebook=Facebook Gameroom":"cpe:2.3:a:facebook:gameroom:*:*:*:*:*:*:*:*",
"Facebook=Hermes":"cpe:2.3:a:facebook:hermes:*:*:*:*:*:*:*:*",
"Facebook=HHVM":"cpe:2.3:a:facebook:hhvm:*:*:*:*:*:*:*:*",
"Facebook=HHVM":"cpe:2.3:a:facebook:hiphop_virtual_machine:*:*:*:*:*:*:*:*",
"Facebook=Katran":"cpe:2.3:a:facebook:katran:*:*:*:*:*:*:*:*",
"Meta Platforms, Inc=Lexical":"cpe:2.3:a:facebook:lexical:*:*:*:*:*:*:*:*",
"Facebook=Mcrouter":"cpe:2.3:a:facebook:mcrouter:*:*:*:*:*:*:*:*",
"Meta Platforms, Inc=Meta Spark Studio":"cpe:2.3:a:facebook:meta_spark_studio:*:*:*:*:*:*:*:*",
"Facebook=netconsd":"cpe:2.3:a:facebook:netconsd:*:*:*:*:*:*:*:*",
"Facebook=Nuclide":"cpe:2.3:a:facebook:nuclide:*:*:*:*:*:*:*:*",
"Facebook=ParlAI":"cpe:2.3:a:facebook:parlai:*:*:*:*:*:*:*:*",
"facebookresearch=ParlAI":"cpe:2.3:a:facebook:parlai:*:*:*:*:*:*:*:*",
"Facebook=Proxygen":"cpe:2.3:a:facebook:proxygen:*:*:*:*:*:*:*:*",
"Facebook=proxygen":"cpe:2.3:a:facebook:proxygen:*:*:*:*:*:*:*:*",
"Facebook=react-dev-utils":"cpe:2.3:a:facebook:react-dev-utils:*:*:*:*:*:*:*:*",
"Meta=React Developer Tools Extension":"cpe:2.3:a:facebook:react-devtools:*:*:*:*:*:*:*:*",
"Facebook=react-native":"cpe:2.3:a:facebook:react-native:*:*:*:*:*:*:*:*",
"Facebook=react-dom":"cpe:2.3:a:facebook:react:*:*:*:*:*:*:*:*",
"Facebook=Redex":"cpe:2.3:a:facebook:redex:*:*:*:*:*:*:*:*",
"Facebook=Facebook Thrift":"cpe:2.3:a:facebook:thrift:*:*:*:*:*:*:*:*",
"Facebook=Wangle":"cpe:2.3:a:facebook:wangle:*:*:*:*:*:*:*:*",
"Facebook=Zstandard":"cpe:2.3:a:facebook:zstandard:*:*:*:*:*:*:*:*",
"unspecified=Facepay":"cpe:2.3:a:facepay_project:facepay:*:*:*:*:*:*:*:*",
"WillyXJ=facileManager":"cpe:2.3:a:facilemanager:facilemanager:*:*:*:*:*:*:*:*",
"FactoMineR=FactoInvestigate":"cpe:2.3:a:factominer:factoinvestigate:*:*:*:*:*:*:*:*",
"neorazorx=neorazorx/facturascripts":"cpe:2.3:a:facturascripts:facturascripts:*:*:*:*:*:*:*:*",
"SourceCodester=Faculty Evaluation System":"cpe:2.3:a:faculty_evaluation_system_project:faculty_evaluation_system:*:*:*:*:*:*:*:*",
"Shipping 100=Fahuo100":"cpe:2.3:a:fahuo100:fahuo100:*:*:*:*:*:*:*:*",
"CodeCanyon=RISE Rise Ultimate Project Manager":"cpe:2.3:a:fairsketch:rise_ultimate_project_manager:*:*:*:*:*:*:*:*",
"unspecified=falling-fruit":"cpe:2.3:a:fallingfruit:falling-fruit:*:*:*:*:*:*:*:*",
"sea75300=FanPress CM":"cpe:2.3:a:fanpress_cm_project:fanpress_cm:*:*:*:*:*:*:*:*",
"FANUC=ROBOGUIDE":"cpe:2.3:a:fanuc:roboguide:*:*:*:*:*:*:*:*",
"CodeAstro=Simple Student Result Management System":"cpe:2.3:a:farahkharrat:simple_student_result_management_system:*:*:*:*:*:*:*:*",
"codeprojects=Farmacia":"cpe:2.3:a:farmacia_project:farmacia:*:*:*:*:*:*:*:*",
"Farmakom=Remote Administration Console":"cpe:2.3:a:farmakom:remote_administration_console:*:*:*:*:*:*:*:*",
"FarmBot=Farmbot-Web-App":"cpe:2.3:a:farmbot:farmbot_web_app:*:*:*:*:*:*:*:*",
"Farsight Tech Nordic=AB ProVide":"cpe:2.3:a:farsight:provide_server:*:*:*:*:*:*:*:*",
"oretnom23=Fast Food Ordering System":"cpe:2.3:a:fast_food_ordering_system_project:fast_food_ordering_system:*:*:*:*:*:*:*:*",
"unspecified=Fast Food Ordering System":"cpe:2.3:a:fast_food_ordering_system_project:fast_food_ordering_system:*:*:*:*:*:*:*:*",
"FasterXML= jackson-dataformats-text":"cpe:2.3:a:fasterxml:jackson-dataformats-text:*:*:*:*:*:*:*:*",
"fastify=github-action-merge-dependabot":"cpe:2.3:a:fastify:github_action_merge_dependabot:*:*:*:*:*:*:*:*",
"npm=@fastify/oauth2":"cpe:2.3:a:fastify:oauth2:*:*:*:*:*:*:*:*",
"FATEK Automation=FvDesigner":"cpe:2.3:a:fatek:fvdesigner:*:*:*:*:*:*:*:*",
"FATEK Automation=PLC WinProladder":"cpe:2.3:a:fatek:winproladder:*:*:*:*:*:*:*:*",
"FATEK Automation=WinProladder":"cpe:2.3:a:fatek:winproladder:*:*:*:*:*:*:*:*",
"Faturamatik=Bircard":"cpe:2.3:a:faturamatik:bircard:*:*:*:*:*:*:*:*",
"beancount=beancount/fava":"cpe:2.3:a:fava_project:fava:*:*:*:*:*:*:*:*",
"cloudfavorites=favorites-web":"cpe:2.3:a:favorites-web_project:favorites-web:*:*:*:*:*:*:*:*",
"ityouknow=favorites-web":"cpe:2.3:a:favorites-web_project:favorites-web:*:*:*:*:*:*:*:*",
"faye=faye-websocket":"cpe:2.3:a:faye-websocket_project:faye-websocket:*:*:*:*:*:*:*:*",
"faye=faye":"cpe:2.3:a:faye_project:faye:*:*:*:*:*:*:*:*",
"389 Directory Server=389-ds-base":"cpe:2.3:a:fedoraproject:389_directory_server:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=389-ds-base":"cpe:2.3:a:fedoraproject:389_directory_server:*:*:*:*:*:*:*:*",
"Red Hat=389-ds-base":"cpe:2.3:a:fedoraproject:389_directory_server:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=fedora-arm-installer":"cpe:2.3:a:fedoraproject:arm_installer:*:*:*:*:*:*:*:*",
"Fedora Project Infrastructure=Python-Fedora":"cpe:2.3:a:fedoraproject:python-fedora:*:*:*:*:*:*:*:*",
"[UNKNOWN]=sssd":"cpe:2.3:a:fedoraproject:sssd:*:*:*:*:*:*:*:*",
"unspecified=FeehiCMS":"cpe:2.3:a:feehi:feehicms:*:*:*:*:*:*:*:*",
"Fernus Informatics=LMS":"cpe:2.3:a:fernus:learning_management_systems:*:*:*:*:*:*:*:*",
"lanyulei=ferry":"cpe:2.3:a:ferry_project:ferry:*:*:*:*:*:*:*:*",
"FFMPEG=FFMPEG":"cpe:2.3:a:ffmpeg:ffmpeg:*:*:*:*:*:*:*:*",
"unspecified=ffmpeg":"cpe:2.3:a:ffmpeg:ffmpeg:*:*:*:*:*:*:*:*",
"fhs-opensource=iparking":"cpe:2.3:a:fhs-opensource:iparking:*:*:*:*:*:*:*:*",
"SourceCodester=File Tracker Manager System":"cpe:2.3:a:file_tracker_manager_system_project:file_tracker_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=File Tracker Manager System":"cpe:2.3:a:file_tracker_manager_system_project:file_tracker_manager_system:*:*:*:*:*:*:*:*",
"filecoin-project=lotus":"cpe:2.3:a:filecoin:lotus:*:*:*:*:*:*:*:*",
"filegator=filegator/filegator":"cpe:2.3:a:filegator:filegator:*:*:*:*:*:*:*:*",
"Deytek Informatics=FileOrbis File Management System":"cpe:2.3:a:fileorbis:fileorbis:*:*:*:*:*:*:*:*",
"Filseclab=Twister Antivirus":"cpe:2.3:a:filseclab:twister_antivirus:*:*:*:*:*:*:*:*",
"Twister=Antivirus":"cpe:2.3:a:filseclab:twister_antivirus:*:*:*:*:*:*:*:*",
"FineUploader=FineUploader php-traditional-server":"cpe:2.3:a:fineuploader:php-traditional-server:*:*:*:*:*:*:*:*",
"Finex Media=Competition Management System":"cpe:2.3:a:finexmedia:competition_management_system:*:*:*:*:*:*:*:*",
"FireEye =FireEye Central Management":"cpe:2.3:a:fireeye:central_management:*:*:*:*:*:*:*:*",
"FireEye =FireEye Endpoint Security":"cpe:2.3:a:fireeye:endpoint_security:*:*:*:*:*:*:*:*",
"FireEye =FireEye HXTool":"cpe:2.3:a:fireeye:hxtool:*:*:*:*:*:*:*:*",
"FireEye =FireEye Malware Analysis (AX)":"cpe:2.3:a:fireeye:malware_analysis:*:*:*:*:*:*:*:*",
"firefly-iii=firefly-iii/firefly-iii":"cpe:2.3:a:firefly-iii:firefly_iii:*:*:*:*:*:*:*:*",
"wixtoolset=issues":"cpe:2.3:a:firegiant:wix_toolset:*:*:*:*:*:*:*:*",
"Firmanet Software and Technology=Customer Relation Manager":"cpe:2.3:a:firmanet:customer_relation_manager:*:*:*:*:*:*:*:*",
"Firmanet Software and Technology=Customer Relation Manager":"cpe:2.3:a:firmanet:technology_customer_relation_manager:*:*:*:*:*:*:*:*",
"fish-shell=fish-shell":"cpe:2.3:a:fishshell:fish:*:*:*:*:*:*:*:*",
"1Panel-dev=1Panel":"cpe:2.3:a:fit2cloud:1panel:*:*:*:*:*:*:*:*",
"CloudExplorer-Dev=CloudExplorer-Lite":"cpe:2.3:a:fit2cloud:cloudexplorer_lite:*:*:*:*:*:*:*:*",
"cloudexplorer-dev=cloudexplorer-dev/cloudexplorer-lite":"cpe:2.3:a:fit2cloud:cloudexplorer_lite:*:*:*:*:*:*:*:*",
"halo-dev=halo":"cpe:2.3:a:fit2cloud:halo:*:*:*:*:*:*:*:*",
"jumpserver=jumpserver":"cpe:2.3:a:fit2cloud:jumpserver:*:*:*:*:*:*:*:*",
"KubeOperator=KubeOperator":"cpe:2.3:a:fit2cloud:kubeoperator:*:*:*:*:*:*:*:*",
"1Panel-dev=KubePi":"cpe:2.3:a:fit2cloud:kubepi:*:*:*:*:*:*:*:*",
"KubeOperator=KubePi":"cpe:2.3:a:fit2cloud:kubepi:*:*:*:*:*:*:*:*",
"flarum=core":"cpe:2.3:a:flarum:flarum:*:*:*:*:*:*:*:*",
"flarum=framework":"cpe:2.3:a:flarum:flarum:*:*:*:*:*:*:*:*",
"dpgaspar=Flask-AppBuilder":"cpe:2.3:a:flask-appbuilder_project:flask-appbuilder:*:*:*:*:*:*:*:*",
"Flask-Middleware=flask-security-too":"cpe:2.3:a:flask-security-too_project:flask-security-too:*:*:*:*:*:*:*:*",
"Flask-Middleware=flask-security":"cpe:2.3:a:flask-security_project:flask-security:*:*:*:*:*:*:*:*",
"Tethik=flask-session-captcha":"cpe:2.3:a:flask-session-captcha_project:flask-session-captcha:*:*:*:*:*:*:*:*",
"hughsk=flat":"cpe:2.3:a:flat_project:flat:*:*:*:*:*:*:*:*",
"flatcore=flatcore/flatcore-cms":"cpe:2.3:a:flatcore:flatcore-cms:*:*:*:*:*:*:*:*",
"flatpak=flatpak":"cpe:2.3:a:flatpak:flatpak:*:*:*:*:*:*:*:*",
"flatpressblog=flatpressblog/flatpress":"cpe:2.3:a:flatpress:flatpress:*:*:*:*:*:*:*:*",
"fleetdm=fleet":"cpe:2.3:a:fleetdm:fleet:*:*:*:*:*:*:*:*",
"NIPPON TELEGRAPH AND TELEPHONE WEST CORPORATION=Installer of Flets Easy Setup Tool":"cpe:2.3:a:flets-w:flets_easy_setup_tool:*:*:*:*:*:*:*:*",
"NIPPON TELEGRAPH AND TELEPHONE WEST CORPORATION=FLET'S v4 / v6 address selection tool":"cpe:2.3:a:flets:address_selection_tool:*:*:*:*:*:*:*:*",
"NIPPON TELEGRAPH AND TELEPHONE EAST CORPORATION=FLET'S Azukeru Backup Tool":"cpe:2.3:a:flets:azukeru_backup_tool:*:*:*:*:*:*:*:*",
"Solar=FTP Server":"cpe:2.3:a:flexbyte:solar_ftp_server:*:*:*:*:*:*:*:*",
"Flexera Software LLC=FlexNet Manager Suite":"cpe:2.3:a:flexerasoftware:flexnet_manager_suite:*:*:*:*:*:*:*:*",
"flink-extended=ai-flow":"cpe:2.3:a:flink-extended:aiflow:*:*:*:*:*:*:*:*",
"flitto=express-param":"cpe:2.3:a:flitto:express-param:*:*:*:*:*:*:*:*",
"secure-software-engineering=FlowDroid":"cpe:2.3:a:flowdroid_project:flowdroid:*:*:*:*:*:*:*:*",
"FLOWRING=Agentflow BPM":"cpe:2.3:a:flowring:agentflow:*:*:*:*:*:*:*:*",
"fluent=fluentd":"cpe:2.3:a:fluentd:fluentd:*:*:*:*:*:*:*:*",
"WP Manage Ninja=WP Fluent Forms":"cpe:2.3:a:fluentforms:contact_form:*:*:*:*:*:*:*:*",
"zephyrkul=FluffyCogs":"cpe:2.3:a:fluffycogs_project:fluffycogs:*:*:*:*:*:*:*:*",
"flusity=CMS":"cpe:2.3:a:fluisity:fluisity:*:*:*:*:*:*:*:*",
"flusity=CMS":"cpe:2.3:a:flusity:flusity:*:*:*:*:*:*:*:*",
"fluxcd=flux2":"cpe:2.3:a:fluxcd:flux2:*:*:*:*:*:*:*:*",
"fluxcd=kustomize-controller":"cpe:2.3:a:fluxcd:kustomize-controller:*:*:*:*:*:*:*:*",
"flyteorg=flyteconsole":"cpe:2.3:a:flyte:flyte_console:*:*:*:*:*:*:*:*",
"flyteorg=flyteadmin":"cpe:2.3:a:flyte:flyteadmin:*:*:*:*:*:*:*:*",
"fobybus=social-media-skeleton":"cpe:2.3:a:fobybus:social-media-skeleton:*:*:*:*:*:*:*:*",
"FocalScope=Focalscope":"cpe:2.3:a:focalscope:focalscope:*:*:*:*:*:*:*:*",
"FOGProject=fogproject":"cpe:2.3:a:fogproject:fogproject:*:*:*:*:*:*:*:*",
"SourceCodester=Food Ordering Management System":"cpe:2.3:a:food_ordering_management_system_project:food_ordering_management_system:*:*:*:*:*:*:*:*",
"Sakshi2610=Food Ordering Website":"cpe:2.3:a:food_ordering_website_project:food_ordering_website:*:*:*:*:*:*:*:*",
"foodcoopshop=foodcoopshop":"cpe:2.3:a:foodcoopshop:foodcoopshop:*:*:*:*:*:*:*:*",
"Forcepoint=Forcepoint Email Security":"cpe:2.3:a:forcepoint:email_security:*:*:*:*:*:*:*:*",
"Forcepoint=Forcepoint Next Generation Firewall":"cpe:2.3:a:forcepoint:next_generation_firewall:*:*:*:*:*:*:*:*",
"Forcepoint=NGFW Security Management Center":"cpe:2.3:a:forcepoint:next_generation_firewall_security_management_center:*:*:*:*:*:*:*:*",
"Forcepoint=F":"cpe:2.3:a:forcepoint:one_smartedge_agent:*:*:*:*:*:*:*:*",
"Forcepoint .=Forcepoint User ID (FUID) server":"cpe:2.3:a:forcepoint:user_id:*:*:*:*:*:*:*:*",
" ForeScout=NAC SecureConnector":"cpe:2.3:a:forescout:secureconnector:*:*:*:*:*:*:*:*",
"ForeScout =eServices / eNvoice":"cpe:2.3:a:forescout:secureconnector:*:*:*:*:*:*:*:*",
"saysky=ForestBlog":"cpe:2.3:a:forestblog_project:forestblog:*:*:*:*:*:*:*:*",
"ForgeRock Inc.=Access Management":"cpe:2.3:a:forgerock:access_management:*:*:*:*:*:*:*:*",
"ForgeRock=Access Management":"cpe:2.3:a:forgerock:access_management:*:*:*:*:*:*:*:*",
"ForgeRock=Access Management Java Policy Agent":"cpe:2.3:a:forgerock:java_policy_agents:*:*:*:*:*:*:*:*",
"ForgeRock Inc.=OpenIDM and Java Remote Connector Server (RCS)":"cpe:2.3:a:forgerock:ldap_connector:*:*:*:*:*:*:*:*",
"ForgeRock=LDAP Connector":"cpe:2.3:a:forgerock:ldap_connector:*:*:*:*:*:*:*:*",
"ForgeRock=Access Management Web Policy Agent":"cpe:2.3:a:forgerock:web_policy_agents:*:*:*:*:*:*:*:*",
"forkcms=forkcms/forkcms":"cpe:2.3:a:fork-cms:fork_cms:*:*:*:*:*:*:*:*",
"Forma=Forma LMS":"cpe:2.3:a:formalms:formalms:*:*:*:*:*:*:*:*",
"getformwork=formwork":"cpe:2.3:a:formwork_project:formwork:*:*:*:*:*:*:*:*",
"Fortect=Fortect ":"cpe:2.3:a:fortect:fortect:*:*:*:*:*:*:*:*",
"Brave UX=for-the-badge":"cpe:2.3:a:forthebadge:for_the_badge:*:*:*:*:*:*:*:*",
"Fortinet=FortiADC":"cpe:2.3:a:fortinet:fortiadc:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiADC":"cpe:2.3:a:fortinet:fortiadc:*:*:*:*:*:*:*:*",
"Fortinet=FortiAnalyzer":"cpe:2.3:a:fortinet:fortianalyzer:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiAnalyzer":"cpe:2.3:a:fortinet:fortianalyzer:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiAP-C":"cpe:2.3:a:fortinet:fortiap-c:*:*:*:*:*:*:*:*",
"Fortinet=FortiAP-U":"cpe:2.3:a:fortinet:fortiap-u:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiAP-U":"cpe:2.3:a:fortinet:fortiap-u:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiAuthenticator":"cpe:2.3:a:fortinet:fortiauthenticator:*:*:*:*:*:*:*:*",
"Fortinet=FortiAuthenticator":"cpe:2.3:a:fortinet:fortiauthenticator:*:*:*:*:*:*:*:*",
"Fortinet=FortiAuthenticator WEB UI":"cpe:2.3:a:fortinet:fortiauthenticator:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiAuthenticator":"cpe:2.3:a:fortinet:fortiauthenticator:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiAuthenticator OutlookAgent":"cpe:2.3:a:fortinet:fortiauthenticator_agent_for_microsoft_outlook_web_access:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiClient EMS":"cpe:2.3:a:fortinet:forticlient_emergency_management_server:*:*:*:*:*:*:*:*",
"Fortinet=FortiClientEMS":"cpe:2.3:a:fortinet:forticlient_endpoint_management_server:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiClientEMS":"cpe:2.3:a:fortinet:forticlient_endpoint_management_server:*:*:*:*:*:*:*:*",
"Fortinet=FortiClientEMS":"cpe:2.3:a:fortinet:forticlient_enterprise_management_server:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiClientEMS":"cpe:2.3:a:fortinet:forticlient_enterprise_management_server:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiDDoS":"cpe:2.3:a:fortinet:fortiddos:*:*:*:*:*:*:*:*",
"Fortinet=FortiDeceptor":"cpe:2.3:a:fortinet:fortideceptor:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiDeceptor":"cpe:2.3:a:fortinet:fortideceptor:*:*:*:*:*:*:*:*",
"Fortinet=FortiEDR":"cpe:2.3:a:fortinet:fortiedr:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiEDR":"cpe:2.3:a:fortinet:fortiedr:*:*:*:*:*:*:*:*",
"Fortinet=FortiGuest":"cpe:2.3:a:fortinet:fortiguest:*:*:*:*:*:*:*:*",
"Fortinet=FortiIsolator":"cpe:2.3:a:fortinet:fortiisolator:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiIsolator":"cpe:2.3:a:fortinet:fortiisolator:*:*:*:*:*:*:*:*",
"Fortinet=FortiMail":"cpe:2.3:a:fortinet:fortimail:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiMail":"cpe:2.3:a:fortinet:fortimail:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiManager":"cpe:2.3:a:fortinet:fortimanager:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=Fortinet FortiManager":"cpe:2.3:a:fortinet:fortimanager:*:*:*:*:*:*:*:*",
"Fortinet=FortiManager":"cpe:2.3:a:fortinet:fortimanager:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiManager":"cpe:2.3:a:fortinet:fortimanager:*:*:*:*:*:*:*:*",
"Fortinet=FortiNAC":"cpe:2.3:a:fortinet:fortinac:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiNAC":"cpe:2.3:a:fortinet:fortinac:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FSSO Windows DC Agent, FSSO Windows CA":"cpe:2.3:a:fortinet:fortinet_single_sign-on:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiOS":"cpe:2.3:a:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=Fortinet FortiPortal":"cpe:2.3:a:fortinet:fortiportal:*:*:*:*:*:*:*:*",
"Fortinet=FortiPortal":"cpe:2.3:a:fortinet:fortiportal:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiPortal":"cpe:2.3:a:fortinet:fortiportal:*:*:*:*:*:*:*:*",
"Fortinet=FortiPresence":"cpe:2.3:a:fortinet:fortipresence:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiPresence":"cpe:2.3:a:fortinet:fortipresence:*:*:*:*:*:*:*:*",
"Fortinet=FortiPresence":"cpe:2.3:a:fortinet:fortiproxy:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiProxy":"cpe:2.3:a:fortinet:fortiproxy:*:*:*:*:*:*:*:*",
"Fortinet=FortiSandbox":"cpe:2.3:a:fortinet:fortisandbox:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiSandbox":"cpe:2.3:a:fortinet:fortisandbox:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiSDNConnector":"cpe:2.3:a:fortinet:fortisdnconnector:*:*:*:*:*:*:*:*",
"Fortinet=FortiSIEM":"cpe:2.3:a:fortinet:fortisiem:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiSIEM":"cpe:2.3:a:fortinet:fortisiem:*:*:*:*:*:*:*:*",
"Fortinet=FortiSOAR":"cpe:2.3:a:fortinet:fortisoar:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiSOAR":"cpe:2.3:a:fortinet:fortisoar:*:*:*:*:*:*:*:*",
"Fortinet=FortiSwitchManager":"cpe:2.3:a:fortinet:fortiswitchmanager:*:*:*:*:*:*:*:*",
"Fortinet=FortiTester":"cpe:2.3:a:fortinet:fortitester:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiTester":"cpe:2.3:a:fortinet:fortitester:*:*:*:*:*:*:*:*",
"Fortinet=FortiVoice":"cpe:2.3:a:fortinet:fortivoice:*:*:*:*:*:*:*:*",
"Fortinet=FortiWAN":"cpe:2.3:a:fortinet:fortiwan:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiWAN":"cpe:2.3:a:fortinet:fortiwan:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiWeb":"cpe:2.3:a:fortinet:fortiweb:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=Fortinet FortiWeb":"cpe:2.3:a:fortinet:fortiweb:*:*:*:*:*:*:*:*",
"Fortinet=FortiWeb":"cpe:2.3:a:fortinet:fortiweb:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiWeb":"cpe:2.3:a:fortinet:fortiweb:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiWebManager":"cpe:2.3:a:fortinet:fortiweb:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiWebManager":"cpe:2.3:a:fortinet:fortiweb_manager:*:*:*:*:*:*:*:*",
"Fortinet=FortiWebManager":"cpe:2.3:a:fortinet:fortiwebmanager:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiWLC":"cpe:2.3:a:fortinet:fortiwlc:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiWLC":"cpe:2.3:a:fortinet:fortiwlc:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=Fortinet FortiWLM":"cpe:2.3:a:fortinet:fortiwlm:*:*:*:*:*:*:*:*",
"Fortinet=FortiWLM":"cpe:2.3:a:fortinet:fortiwlm:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiWLM":"cpe:2.3:a:fortinet:fortiwlm:*:*:*:*:*:*:*:*",
"Fortra=Digital Guardian Agent ":"cpe:2.3:a:fortra:digital_guardian_agent:*:*:*:*:*:*:*:*",
"Fortra=GoAnywhere MFT":"cpe:2.3:a:fortra:goanywhere_managed_file_transfer:*:*:*:*:*:*:*:*",
"Fortra=Goanywhere MFT":"cpe:2.3:a:fortra:goanywhere_managed_file_transfer:*:*:*:*:*:*:*:*",
"ForU=CMS":"cpe:2.3:a:foru_cms_project:foru_cms:*:*:*:*:*:*:*:*",
"fossasia=susi_server":"cpe:2.3:a:fossasia:susi.ai:*:*:*:*:*:*:*:*",
"alextselegidis=alextselegidis/easyappointments":"cpe:2.3:a:fossbilling:fossbilling:*:*:*:*:*:*:*:*",
"fossbilling=fossbilling/fossbilling":"cpe:2.3:a:fossbilling:fossbilling:*:*:*:*:*:*:*:*",
"Xiamen Four Letter=Video Surveillance Management System":"cpe:2.3:a:four-faith:video_surveillance_management_system:*:*:*:*:*:*:*:*",
"Xiamen Four-Faith=Video Surveillance Management System":"cpe:2.3:a:four-faith:video_surveillance_management_system:*:*:*:*:*:*:*:*",
"Foxit=Foxit Reader":"cpe:2.3:a:foxit:pdf_reader:*:*:*:*:*:*:*:*",
"Talos=Foxit":"cpe:2.3:a:foxit:pdf_reader:*:*:*:*:*:*:*:*",
"Foxit=Foxit Reader":"cpe:2.3:a:foxitsoftware:foxit_reader:*:*:*:*:*:*:*:*",
"Talos=Foxit":"cpe:2.3:a:foxitsoftware:foxit_reader:*:*:*:*:*:*:*:*",
"Zero Day Initiative=Foxit Reader":"cpe:2.3:a:foxitsoftware:foxit_reader:*:*:*:*:*:*:*:*",
"Foxit=Studio Photo":"cpe:2.3:a:foxitsoftware:foxit_studio_photo:*:*:*:*:*:*:*:*",
"Foxit=PhantomPDF":"cpe:2.3:a:foxitsoftware:phantompdf:*:*:*:*:*:*:*:*",
"fraction=oasis":"cpe:2.3:a:fraction:oasis:*:*:*:*:*:*:*:*",
"chocobozzz=chocobozzz/peertube":"cpe:2.3:a:framasoft:peertube:*:*:*:*:*:*:*:*",
"frappe=frappe":"cpe:2.3:a:frappe:erpnext:*:*:*:*:*:*:*:*",
"frappe=frappe":"cpe:2.3:a:frappe:frappe:*:*:*:*:*:*:*:*",
"unspecified=Frappe":"cpe:2.3:a:frappe:frappe:*:*:*:*:*:*:*:*",
"frappe=frappe/lms":"cpe:2.3:a:frappe:frappe_lms:*:*:*:*:*:*:*:*",
"frappe=lms":"cpe:2.3:a:frappe:frappe_lms:*:*:*:*:*:*:*:*",
"Free5Gc=Open5Gc":"cpe:2.3:a:free5gc:free5gc:*:*:*:*:*:*:*:*",
"SourceCodester=Free Hospital Management System for Small Practices":"cpe:2.3:a:free_hospital_management_system_for_small_practices_project:free_hospital_management_system_for_small_practices:*:*:*:*:*:*:*:*",
"FreeBSD=FreeBSD":"cpe:2.3:a:freebsd:freebsd:*:*:*:*:*:*:*:*",
"Freedesktop=accountsservice":"cpe:2.3:a:freedesktop:accountsservice:*:*:*:*:*:*:*:*",
"Poppler=Poppler":"cpe:2.3:a:freedesktop:poppler:*:*:*:*:*:*:*:*",
"Poppler=poppler":"cpe:2.3:a:freedesktop:poppler:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=poppler":"cpe:2.3:a:freedesktop:poppler:*:*:*:*:*:*:*:*",
"freedomofpress=dangerzone":"cpe:2.3:a:freedom:dangerzone:*:*:*:*:*:*:*:*",
"Freedom of the Press=SecureDrop":"cpe:2.3:a:freedom:securedrop:*:*:*:*:*:*:*:*",
"FreeFloat=FTP Server":"cpe:2.3:a:freefloat_ftp_server_project:freefloat_ftp_server:*:*:*:*:*:*:*:*",
"FreeIPA=ipa":"cpe:2.3:a:freeipa:freeipa:*:*:*:*:*:*:*:*",
"open source=OpenClinic GA":"cpe:2.3:a:freemedsoftware:openclinic_ga:*:*:*:*:*:*:*:*",
"FreeRDP=FreeRDP":"cpe:2.3:a:freerdp:freerdp:*:*:*:*:*:*:*:*",
"signalwire=freeswitch":"cpe:2.3:a:freeswitch:freeswitch:*:*:*:*:*:*:*:*",
"OpenOLAT=OpenOLAT":"cpe:2.3:a:frentix:openolat:*:*:*:*:*:*:*:*",
"FreshRSS=FreshRSS":"cpe:2.3:a:freshrss:freshrss:*:*:*:*:*:*:*:*",
"SourceCodester=Friendly Island Pizza Website and Ordering System":"cpe:2.3:a:friendly_island_pizza_website_and_ordering_system_project:friendly_island_pizza_website_and_ordering_system:*:*:*:*:*:*:*:*",
"FriendsOfFlarum=upload":"cpe:2.3:a:friendsofflarum:upload:*:*:*:*:*:*:*:*",
"Frog CMS=Frog CMS":"cpe:2.3:a:frog_cms_project:frog_cms:*:*:*:*:*:*:*:*",
"Froxlor=Froxlor":"cpe:2.3:a:froxlor:froxlor:*:*:*:*:*:*:*:*",
"froxlor=froxlor/froxlor":"cpe:2.3:a:froxlor:froxlor:*:*:*:*:*:*:*:*",
"zbl1996=FS-Blog":"cpe:2.3:a:fs-blog_project:fs-blog:*:*:*:*:*:*:*:*",
"Sentex=FTPDMIN":"cpe:2.3:a:ftpdmin_project:ftpdmin:*:*:*:*:*:*:*:*",
"Fuji Electric=D300win":"cpe:2.3:a:fujielectric:d300win:*:*:*:*:*:*:*:*",
"Fuji Electric=Energy Savings Estimator":"cpe:2.3:a:fujielectric:energy_savings_estimator:*:*:*:*:*:*:*:*",
"FUJI ELECTRIC CO., LTD.=FRENIC RHC Loader":"cpe:2.3:a:fujielectric:frenic_rhc_loader:*:*:*:*:*:*:*:*",
"FUJI ELECTRIC CO., LTD. / Hakko Electronics Co., Ltd.=V-SFT":"cpe:2.3:a:fujielectric:monitouch_v-sft:*:*:*:*:*:*:*:*",
"Fuji Electric=Monitouch V-SFT":"cpe:2.3:a:fujielectric:monitouch_v-sft:*:*:*:*:*:*:*:*",
"FUJI ELECTRIC CO., LTD. and Hakko Electronics Co., Ltd.=TELLUS Simulator":"cpe:2.3:a:fujielectric:tellus:*:*:*:*:*:*:*:*",
"Fuji Electric=Tellus Lite V-Simulator":"cpe:2.3:a:fujielectric:tellus_lite_v-simulator:*:*:*:*:*:*:*:*",
"FUJI ELECTRIC CO., LTD. and Hakko Electronics Co., Ltd.=V-Server":"cpe:2.3:a:fujielectric:v-server:*:*:*:*:*:*:*:*",
"FUJI ELECTRIC CO., LTD. / Hakko Electronics Co., Ltd.=V-SFT":"cpe:2.3:a:fujielectric:v-sft:*:*:*:*:*:*:*:*",
"FUJIFILM Business Innovation Corp.=Driver Distributor":"cpe:2.3:a:fujifilm:driver_distributor:*:*:*:*:*:*:*:*",
"Fujitsu =Arconte   urea":"cpe:2.3:a:fujitsu:arconte_aurea:*:*:*:*:*:*:*:*",
"FUJITSU LIMITED and PFU LIMITED=The installers of ScanSnap Manager and the Software Download Installer":"cpe:2.3:a:fujitsu:scansnap_manager:*:*:*:*:*:*:*:*",
"Fuji Xerox Co.,Ltd.=Self-extracting document generated by DocuWorks":"cpe:2.3:a:fujixerox:docuworks:*:*:*:*:*:*:*:*",
"getsentry=sentry":"cpe:2.3:a:functional:sentry:*:*:*:*:*:*:*:*",
"FusionPBX=FusionPBX":"cpe:2.3:a:fusionpbx:fusionpbx:*:*:*:*:*:*:*:*",
"Weitong=Mall":"cpe:2.3:a:fuyanglipengjun:wetong_mall:*:*:*:*:*:*:*:*",
"Gabriels=FTP Server":"cpe:2.3:a:gabriels_ftp_server_project:gabriels_ftp_server:*:*:*:*:*:*:*:*",
"SourceCodester=Gadget Works Online Ordering System":"cpe:2.3:a:gadget_works_online_ordering_system_project:gadget_works_online_ordering_system:*:*:*:*:*:*:*:*",
"gaizhenbiao=gaizhenbiao/chuanhuchatgpt":"cpe:2.3:a:gaizhenbiao:chuanhuchatgpt:*:*:*:*:*:*:*:*",
"Gaku=Tablacus Explorer":"cpe:2.3:a:gaku:tablacus_explorer:*:*:*:*:*:*:*:*",
"galaxyproject=galaxy":"cpe:2.3:a:galaxyproject:galaxy:*:*:*:*:*:*:*:*",
"galette=galette":"cpe:2.3:a:galette:galette:*:*:*:*:*:*:*:*",
"Gallagher=Command Center":"cpe:2.3:a:gallagher:command_centre:*:*:*:*:*:*:*:*",
"Gallagher=Command Centre":"cpe:2.3:a:gallagher:command_centre:*:*:*:*:*:*:*:*",
"Gallagher=Command Centre Server":"cpe:2.3:a:gallagher:command_centre:*:*:*:*:*:*:*:*",
"SourceCodester=Game Result Matrix System":"cpe:2.3:a:game_result_matrix_system_project:game_result_matrix_system:*:*:*:*:*:*:*:*",
"HackerOne=gaoxuyan node module":"cpe:2.3:a:gaoxuyan_project:gaoxuyan:*:*:*:*:*:*:*:*",
"SourceCodester=Garage Management System":"cpe:2.3:a:garage_management_system_project:garage_management_system:*:*:*:*:*:*:*:*",
"SAP=project    Gardener   ":"cpe:2.3:a:gardener:gardener:*:*:*:*:*:*:*:*",
"            =mldong":"cpe:2.3:a:garethhk:mldong:*:*:*:*:*:*:*:*",
"SourceCodester=Gas Agency Management System":"cpe:2.3:a:gas_agency_management_system_project:gas_agency_management_system:*:*:*:*:*:*:*:*",
"Computing For Good=Basic Laboratory Information System":"cpe:2.3:a:gatech:computing_for_good\'s_basic_laboratory_information_system:*:*:*:*:*:*:*:*",
"Carlo Gavazzi=Powersoft":"cpe:2.3:a:gavazzionline:powersoft:*:*:*:*:*:*:*:*",
"GE Healthcare=GE Centricity PACS RA1000":"cpe:2.3:a:ge:centricity_pacs_ra1000:*:*:*:*:*:*:*:*",
"GE Digital=CIMPLICITY":"cpe:2.3:a:ge:cimplicity:*:*:*:*:*:*:*:*",
"GE=CIMPLICITY":"cpe:2.3:a:ge:cimplicity:*:*:*:*:*:*:*:*",
"General Electric=Proficy CIMPLICITY":"cpe:2.3:a:ge:cimplicity:*:*:*:*:*:*:*:*",
"GE Healthcare=GE GEMNet License server aka. (EchoServer)":"cpe:2.3:a:ge:gemnet_license_server:*:*:*:*:*:*:*:*",
"GE Digital =Proficy iFIX":"cpe:2.3:a:ge:ifix:*:*:*:*:*:*:*:*",
"GE=iFix":"cpe:2.3:a:ge:ifix:*:*:*:*:*:*:*:*",
"General Electric=MiCOM S1 Agile":"cpe:2.3:a:ge:micom_s1_agile:*:*:*:*:*:*:*:*",
"General Electric=Proficy CIMPLICITY":"cpe:2.3:a:ge:proficy_cimplicitiy:*:*:*:*:*:*:*:*",
"GE Digital =Proficy Historian":"cpe:2.3:a:ge:proficy_historian:*:*:*:*:*:*:*:*",
"GE Digital=Proficy Historian":"cpe:2.3:a:ge:proficy_historian:*:*:*:*:*:*:*:*",
"GE Gas Power=ToolBoxST":"cpe:2.3:a:ge:toolboxst:*:*:*:*:*:*:*:*",
"GE Gas Power=ToolboxST":"cpe:2.3:a:ge:toolboxst:*:*:*:*:*:*:*:*",
"GE=UR bootloader binary":"cpe:2.3:a:ge:ur_bootloader_binary:*:*:*:*:*:*:*:*",
"GE Gas Power=WorkstationST":"cpe:2.3:a:ge:workstationst:*:*:*:*:*:*:*:*",
"GE Healthcare=GE Xeleris":"cpe:2.3:a:ge:xeleris:*:*:*:*:*:*:*:*",
"Gemalto=Gemalto's Sentinel LDK RTE":"cpe:2.3:a:gemalto:sentinel_ldk_rte:*:*:*:*:*:*:*:*",
"Gemalto=Sentinel UltraPro":"cpe:2.3:a:gemalto:sentinel_ultrapro_client_library:*:*:*:*:*:*:*:*",
"GENEREX SYSTEMS Computervertriebsgesellschaft mbH=RCCMD":"cpe:2.3:a:generex:rccmd:*:*:*:*:*:*:*:*",
"GENI=Portal":"cpe:2.3:a:geni:geni-portal:*:*:*:*:*:*:*:*",
"GENIVI=dlt-daemon":"cpe:2.3:a:genivi:diagnostic_log_and_trace:*:*:*:*:*:*:*:*",
"gentoo=soko":"cpe:2.3:a:gentoo:soko:*:*:*:*:*:*:*:*",
"math-geon=Geon":"cpe:2.3:a:geon_project:geon:*:*:*:*:*:*:*:*",
"GeoServer=GeoWebCache":"cpe:2.3:a:geoserver:geowebcache:*:*:*:*:*:*:*:*",
"GeoWebCache=geowebcache":"cpe:2.3:a:geoserver:geowebcache:*:*:*:*:*:*:*:*",
"GeoNode=geonode":"cpe:2.3:a:geosolutionsgroup:geonode:*:*:*:*:*:*:*:*",
"geosolutions-it=jai-ext":"cpe:2.3:a:geosolutionsgroup:jai-ext:*:*:*:*:*:*:*:*",
"geotools=geotools":"cpe:2.3:a:geotools:geotools:*:*:*:*:*:*:*:*",
"Gerapy=Gerapy":"cpe:2.3:a:gerapy:gerapy:*:*:*:*:*:*:*:*",
"Gerapy=gerapy":"cpe:2.3:a:gerapy:gerapy:*:*:*:*:*:*:*:*",
"Gerbv=gerbv":"cpe:2.3:a:gerbv_project:gerbv:*:*:*:*:*:*:*:*",
"Gesio (GESTI  N INTEGRAL ONLINE, SL)=GESIO ERP":"cpe:2.3:a:gesio:erp:*:*:*:*:*:*:*:*",
"GetSimple=CMS":"cpe:2.3:a:get-simple:getsimple_cms:*:*:*:*:*:*:*:*",
"composer=composer":"cpe:2.3:a:getcomposer:composer:*:*:*:*:*:*:*:*",
"getgrav=getgrav/grav-plugin-admin":"cpe:2.3:a:getgrav:grav-plugin-admin:*:*:*:*:*:*:*:*",
"getgrav=grav-plugin-admin":"cpe:2.3:a:getgrav:grav-plugin-admin:*:*:*:*:*:*:*:*",
"getgrav=getgrav/grav":"cpe:2.3:a:getgrav:grav:*:*:*:*:*:*:*:*",
"getgrav=grav":"cpe:2.3:a:getgrav:grav:*:*:*:*:*:*:*:*",
"getgrav=grav-plugin-admin":"cpe:2.3:a:getgrav:grav_admin:*:*:*:*:*:*:*:*",
"Harvest=Chosen":"cpe:2.3:a:getharvest:chosen:*:*:*:*:*:*:*:*",
"getkirby=kirby":"cpe:2.3:a:getkirby:kirby:*:*:*:*:*:*:*:*",
"laminas=laminas-diactoros":"cpe:2.3:a:getlaminas:laminas-diactoros:*:*:*:*:*:*:*:*",
"https://github.com/sabelnikov=Epee":"cpe:2.3:a:getmonero:monero:*:*:*:*:*:*:*:*",
"motor-admin=motor-admin":"cpe:2.3:a:getmotoradmin:motor_admin:*:*:*:*:*:*:*:*",
"outline=outline/outline":"cpe:2.3:a:getoutline:outline:*:*:*:*:*:*:*:*",
"GeyserMC=Geyser":"cpe:2.3:a:geysermc:geyser:*:*:*:*:*:*:*:*",
"some-natalie=ghas-to-csv":"cpe:2.3:a:ghas-to-csv_project:ghas-to-csv:*:*:*:*:*:*:*:*",
"bradleyfalzon=ghinstallation":"cpe:2.3:a:ghinstallation_project:ghinstallation:*:*:*:*:*:*:*:*",
"github.com/gin-gonic/gin=github.com/gin-gonic/gin":"cpe:2.3:a:gin-gonic:gin:*:*:*:*:*:*:*:*",
"flipped-aurora=gin-vue-admin":"cpe:2.3:a:gin-vue-admin_project:gin-vue-admin:*:*:*:*:*:*:*:*",
"gin-vue-admin=gin-vue-admin":"cpe:2.3:a:gin-vue-admin_project:gin-vue-admin:*:*:*:*:*:*:*:*",
"Curiosity-org=Gipsy":"cpe:2.3:a:gipsy_project:gipsy:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Git":"cpe:2.3:a:git-scm:git:*:*:*:*:*:*:*:*",
"git=git":"cpe:2.3:a:git-scm:git:*:*:*:*:*:*:*:*",
"ericcornelissen=git-tag-annotation-action":"cpe:2.3:a:git-tag-annotation-action_project:git-tag-annotation-action:*:*:*:*:*:*:*:*",
"git=git":"cpe:2.3:a:git:git:*:*:*:*:*:*:*:*",
"git-for-windows=git":"cpe:2.3:a:git_for_windows_project:git_for_windows:*:*:*:*:*:*:*:*",
"git-lfs=git-lfs":"cpe:2.3:a:git_large_file_storage_project:git_large_file_storage:*:*:*:*:*:*:*:*",
"Gitea=Gitea":"cpe:2.3:a:gitea:gitea:*:*:*:*:*:*:*:*",
"go-gitea=go-gitea/gitea":"cpe:2.3:a:gitea:gitea:*:*:*:*:*:*:*:*",
"rlespinasse=github-slug-action":"cpe:2.3:a:github-slug-action_project:github-slug-action:*:*:*:*:*:*:*:*",
"github=cmark-gfm":"cpe:2.3:a:github:cmark-gfm:*:*:*:*:*:*:*:*",
"github=codeql-action":"cpe:2.3:a:github:codeql_action:*:*:*:*:*:*:*:*",
"GitHub=Enterprise Server":"cpe:2.3:a:github:enterprise_server:*:*:*:*:*:*:*:*",
"GitHub=GitHub Enterprise Server":"cpe:2.3:a:github:enterprise_server:*:*:*:*:*:*:*:*",
"actions=runner":"cpe:2.3:a:github:runner:*:*:*:*:*:*:*:*",
"GitLab=DAST API scanner":"cpe:2.3:a:gitlab:dast_api_scanner:*:*:*:*:*:*:*:*",
"GitLab=DAST":"cpe:2.3:a:gitlab:dynamic_application_security_testing_analyzer:*:*:*:*:*:*:*:*",
"GitLab=GitLab":"cpe:2.3:a:gitlab:dynamic_application_security_testing_analyzer:*:*:*:*:*:*:*:*",
"GitLab=GitLab DAST API scanner":"cpe:2.3:a:gitlab:dynamic_application_security_testing_analyzer:*:*:*:*:*:*:*:*",
"GitLab=Gitaly":"cpe:2.3:a:gitlab:gitaly:*:*:*:*:*:*:*:*",
"GitLab=gitlab-vscode-extension":"cpe:2.3:a:gitlab:gitlab-vscode-extension:*:*:*:*:*:*:*:*",
"GitLab=GitLab":"cpe:2.3:a:gitlab:gitlab:*:*:*:*:*:*:*:*",
"GitLab=GitLab Runner":"cpe:2.3:a:gitlab:gitlab_runner:*:*:*:*:*:*:*:*",
"GitLab=GitLab Runner":"cpe:2.3:a:gitlab:runner:*:*:*:*:*:*:*:*",
"Gitpod=Gitpod":"cpe:2.3:a:gitpod:gitpod:*:*:*:*:*:*:*:*",
"devent=globalpom-utils":"cpe:2.3:a:globalpom-utils_project:globalpom-utils:*:*:*:*:*:*:*:*",
"Global Scape=CuteFTP":"cpe:2.3:a:globalscape:cuteftp:*:*:*:*:*:*:*:*",
"Fortra=Globalscape EFT":"cpe:2.3:a:globalscape:eft_server:*:*:*:*:*:*:*:*",
"Glox Technology=Useroam Hotspot":"cpe:2.3:a:glox:useroam_hotspot:*:*:*:*:*:*:*:*",
"pluginsGLPI=barcode":"cpe:2.3:a:glpi-project:barcode:*:*:*:*:*:*:*:*",
"GLPI=GLPI":"cpe:2.3:a:glpi-project:glpi:*:*:*:*:*:*:*:*",
"GLPI=GLPI Product":"cpe:2.3:a:glpi-project:glpi:*:*:*:*:*:*:*:*",
"glpi-project=GLPI":"cpe:2.3:a:glpi-project:glpi:*:*:*:*:*:*:*:*",
"glpi-project=glpi":"cpe:2.3:a:glpi-project:glpi:*:*:*:*:*:*:*:*",
"glpi=glpi":"cpe:2.3:a:glpi-project:glpi:*:*:*:*:*:*:*:*",
"glpi-project=glpi-agent":"cpe:2.3:a:glpi-project:glpi_agent:*:*:*:*:*:*:*:*",
"glpi-project=glpi-inventory-plugin":"cpe:2.3:a:glpi-project:glpi_inventory:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=GlusterFS":"cpe:2.3:a:gluster:glusterfs:*:*:*:*:*:*:*:*",
"Red Hat=glusterfs":"cpe:2.3:a:gluster:glusterfs:*:*:*:*:*:*:*:*",
"unspecified=glusterfs":"cpe:2.3:a:gluster:glusterfs:*:*:*:*:*:*:*:*",
"GM Information Technologies=MDO":"cpe:2.3:a:gmbilisim:multi-disciplinary_design_optimization:*:*:*:*:*:*:*:*",
"Generic Model Organism Database Project=GBrowse":"cpe:2.3:a:gmod:gbrowse:*:*:*:*:*:*:*:*",
"glib=glib":"cpe:2.3:a:gnome:glib:*:*:*:*:*:*:*:*",
"GNOME=gdm":"cpe:2.3:a:gnome:gnome_display_manager:*:*:*:*:*:*:*:*",
"Gnome=GDM3":"cpe:2.3:a:gnome:gnome_display_manager:*:*:*:*:*:*:*:*",
"GNOME=gvdb":"cpe:2.3:a:gnome:gvariant_database:*:*:*:*:*:*:*:*",
"[UNKNOWN]=gvfs":"cpe:2.3:a:gnome:gvfs:*:*:*:*:*:*:*:*",
"GNU=cflow":"cpe:2.3:a:gnu:cflow:*:*:*:*:*:*:*:*",
"GNU C Library=glibc":"cpe:2.3:a:gnu:glibc:*:*:*:*:*:*:*:*",
"GNU Libc=glibc":"cpe:2.3:a:gnu:glibc:*:*:*:*:*:*:*:*",
"GNU=C Library":"cpe:2.3:a:gnu:glibc:*:*:*:*:*:*:*:*",
"GnuTLS=gnutls":"cpe:2.3:a:gnu:gnutls:*:*:*:*:*:*:*:*",
"[UNKNOWN]=gnutls":"cpe:2.3:a:gnu:gnutls:*:*:*:*:*:*:*:*",
"GNU Project=GNU GRUB":"cpe:2.3:a:gnu:grub2:*:*:*:*:*:*:*:*",
"GNU Guix=guix-daemon":"cpe:2.3:a:gnu:guix:*:*:*:*:*:*:*:*",
"The GNU Projec=GNU Wget":"cpe:2.3:a:gnu:wget:*:*:*:*:*:*:*:*",
"gnuboard=gnuboard/gnuboard5":"cpe:2.3:a:gnuboard:gnuboard5:*:*:*:*:*:*:*:*",
"unspecified=gnuboard5":"cpe:2.3:a:gnuboard:gnuboard5:*:*:*:*:*:*:*:*",
"sjqzhang=go-fastdfs":"cpe:2.3:a:go-fastdfs_project:go-fastdfs:*:*:*:*:*:*:*:*",
"go-vela=server":"cpe:2.3:a:go-vela:vela:*:*:*:*:*:*:*:*",
"goauthentik=authentik":"cpe:2.3:a:goauthentik:authentik:*:*:*:*:*:*:*:*",
"vegaprotocol=vega":"cpe:2.3:a:gobalsky:vega:*:*:*:*:*:*:*:*",
"gofiber=fiber":"cpe:2.3:a:gofiber:fiber:*:*:*:*:*:*:*:*",
"GOG.COM=GOG Galaxy":"cpe:2.3:a:gog:galaxy:*:*:*:*:*:*:*:*",
"Talos=GOG Galaxy":"cpe:2.3:a:gog:galaxy:*:*:*:*:*:*:*:*",
"gogs=gogs":"cpe:2.3:a:gogs:gogs:*:*:*:*:*:*:*:*",
"gogs=gogs/gogs":"cpe:2.3:a:gogs:gogs:*:*:*:*:*:*:*:*",
"Go=SSH library":"cpe:2.3:a:golang:crypto:*:*:*:*:*:*:*:*",
"Go standard library=archive/tar":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=archive/zip":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=compress/gzip":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=crypto/internal/nistec":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=crypto/tls":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=encoding/gob":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=encoding/xml":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=go/parser":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=go/scanner":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=html/template":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=io/fs":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=math/big":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=net/http":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=net/http/httputil":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=net/http/internal":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=net/netip":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=net/textproto":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=os/exec":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=path/filepath":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go standard library=regexp/syntax":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"Go toolchain=cmd/go":"cpe:2.3:a:golang:go:*:*:*:*:*:*:*:*",
"golang.org/x/text=golang.org/x/text/language":"cpe:2.3:a:golang:text:*:*:*:*:*:*:*:*",
"Irina Sokolovskaya=Goods Catalog":"cpe:2.3:a:goods_catalog_project:goods_catalog:*:*:*:*:*:*:*:*",
"Google LLC=Asylo":"cpe:2.3:a:google:asylo:*:*:*:*:*:*:*:*",
"Google LLC=Bindiff":"cpe:2.3:a:google:bindiff:*:*:*:*:*:*:*:*",
"Google=Chrome":"cpe:2.3:a:google:chrome:*:*:*:*:*:*:*:*",
"Google=Closure-Library":"cpe:2.3:a:google:closure_library:*:*:*:*:*:*:*:*",
"Google LLC=Google Cloud IoT Device SDK for Embedded C":"cpe:2.3:a:google:cloud_iot_device_sdk_for_embedded_c:*:*:*:*:*:*:*:*",
"Google Chrome Labs=Critters":"cpe:2.3:a:google:critters:*:*:*:*:*:*:*:*",
"Google LLC=Data-Transfer-Project":"cpe:2.3:a:google:data_transfer_project:*:*:*:*:*:*:*:*",
"GoogleCloudPlatform=esp-v2":"cpe:2.3:a:google:espv2:*:*:*:*:*:*:*:*",
"Google LLC=Google Exposure-notifications-verification-server":"cpe:2.3:a:google:exposure_notification_verification_server:*:*:*:*:*:*:*:*",
"Google LLC=Exposure Notifications Verification Server":"cpe:2.3:a:google:exposure_notifications_verification_server:*:*:*:*:*:*:*:*",
"cloudendpoints=esp":"cpe:2.3:a:google:extensible_service_proxy:*:*:*:*:*:*:*:*",
"Google LLC=fscrypt":"cpe:2.3:a:google:fscrypt:*:*:*:*:*:*:*:*",
"The fscrypt Project=fscrypt":"cpe:2.3:a:google:fscrypt:*:*:*:*:*:*:*:*",
"Gerrit=Gerrit":"cpe:2.3:a:google:gerrit:*:*:*:*:*:*:*:*",
"Google LLC=Gerrit":"cpe:2.3:a:google:gerrit:*:*:*:*:*:*:*:*",
"Google LLC=go-attestation":"cpe:2.3:a:google:go-attestation:*:*:*:*:*:*:*:*",
"Google LLC=google/go-tpm library":"cpe:2.3:a:google:go-tpm:*:*:*:*:*:*:*:*",
"Google LLC=Google Play Services SDK":"cpe:2.3:a:google:google_play_services_software_development_kit:*:*:*:*:*:*:*:*",
"Google LLC=Play Services SDK":"cpe:2.3:a:google:google_play_services_software_development_kit:*:*:*:*:*:*:*:*",
"Google=Guava":"cpe:2.3:a:google:guava:*:*:*:*:*:*:*:*",
"google=kctf":"cpe:2.3:a:google:kctf:*:*:*:*:*:*:*:*",
"Google LLC=Google-oauth-java-client":"cpe:2.3:a:google:oauth_client_library_for_java:*:*:*:*:*:*:*:*",
"Google LLC=Perfetto Dev Scripts":"cpe:2.3:a:google:perfetto:*:*:*:*:*:*:*:*",
"Google LLC=Rendertron":"cpe:2.3:a:google:rendertron:*:*:*:*:*:*:*:*",
"Google LLC=google/sa360-webquery-bigquery":"cpe:2.3:a:google:sa360_webquery_to_bigquery_exporter:*:*:*:*:*:*:*:*",
"Google LLC=SLO Generator":"cpe:2.3:a:google:slo_generator:*:*:*:*:*:*:*:*",
"TensorFlow=TensorFlow":"cpe:2.3:a:google:tensorflow:*:*:*:*:*:*:*:*",
"tensorflow=tensorflow":"cpe:2.3:a:google:tensorflow:*:*:*:*:*:*:*:*",
"Google LLC=Tink":"cpe:2.3:a:google:tink:*:*:*:*:*:*:*:*",
"Voice Builder=Voice Builder":"cpe:2.3:a:google:voice_builder:*:*:*:*:*:*:*:*",
"Google=Web Stories for WordPress":"cpe:2.3:a:google:web_stories:*:*:*:*:*:*:*:*",
"Google=YouTube Android Player API SDK":"cpe:2.3:a:google:youtube_android_player_api:*:*:*:*:*:*:*:*",
"goreleaser=nfpm":"cpe:2.3:a:goreleaser:nfpm:*:*:*:*:*:*:*:*",
"russellhaering=gosaml2":"cpe:2.3:a:gosaml2_project:gosaml2:*:*:*:*:*:*:*:*",
"gost-engine=engine":"cpe:2.3:a:gost_engine_project:gost_engine:*:*:*:*:*:*:*:*",
"gotify=server":"cpe:2.3:a:gotify:server:*:*:*:*:*:*:*:*",
"Guangdong=Pythagorean OA Office System":"cpe:2.3:a:gougucms:pythagorean_oa_office_system:*:*:*:*:*:*:*:*",
"gpac=gpac/gpac":"cpe:2.3:a:gpac:gpac:*:*:*:*:*:*:*:*",
"unspecified=GPAC":"cpe:2.3:a:gpac:gpac:*:*:*:*:*:*:*:*",
"binary-husky=gpt_academic":"cpe:2.3:a:gpt_academic_project:gpt_academic:*:*:*:*:*:*:*:*",
"SourceCodester=Grade Point Average GPA Calculator":"cpe:2.3:a:grade_point_average_\(gpa\)_calculator_project:grade_point_average_\(gpa\)_calculator:*:*:*:*:*:*:*:*",
"gradle=gradle-build-action":"cpe:2.3:a:gradle:build_action:*:*:*:*:*:*:*:*",
"gradle=gradle":"cpe:2.3:a:gradle:gradle:*:*:*:*:*:*:*:*",
"grafana=agent":"cpe:2.3:a:grafana:agent:*:*:*:*:*:*:*:*",
"grafana=grafana":"cpe:2.3:a:grafana:grafana:*:*:*:*:*:*:*:*",
"Grafana=OnCall":"cpe:2.3:a:grafana:oncall:*:*:*:*:*:*:*:*",
"grafana=synthetic-monitoring-agent":"cpe:2.3:a:grafana:synthetic_monitoring_agent:*:*:*:*:*:*:*:*",
"grails=grails-core":"cpe:2.3:a:grails:grails:*:*:*:*:*:*:*:*",
"GRANDIT CORPORATION=GRANDIT":"cpe:2.3:a:grandit:grandit:*:*:*:*:*:*:*:*",
"GraniteDS=Framework":"cpe:2.3:a:graniteds:graniteds:*:*:*:*:*:*:*:*",
"graphhopper=graphhopper":"cpe:2.3:a:graphhopper:graphhopper:*:*:*:*:*:*:*:*",
"Graphisoft=BIMx Desktop Viewer":"cpe:2.3:a:graphisoft:bimx_desktop_viewer:*:*:*:*:*:*:*:*",
"Graphite=Web":"cpe:2.3:a:graphite_project:graphite:*:*:*:*:*:*:*:*",
"graph-gophers=graphql-go":"cpe:2.3:a:graphql-go_project:graphql-go:*:*:*:*:*:*:*:*",
"grassrootza=grassroot-platform":"cpe:2.3:a:grassroot:grassroot_platform:*:*:*:*:*:*:*:*",
"gravitl=gravitl/netmaker":"cpe:2.3:a:gravitl:netmaker:*:*:*:*:*:*:*:*",
"gravitl=netmaker":"cpe:2.3:a:gravitl:netmaker:*:*:*:*:*:*:*:*",
"Graylog2=graylog2-server":"cpe:2.3:a:graylog:graylog:*:*:*:*:*:*:*:*",
"luchua-bc=GreenBrowser":"cpe:2.3:a:greenbrowser_project:greenbrowser:*:*:*:*:*:*:*:*",
"ewxrjk=sftpserver":"cpe:2.3:a:greenend:sftpserver:*:*:*:*:*:*:*:*",
"Green Rocket Security=GreenRADIUS":"cpe:2.3:a:greenrocketsecurity:greenradius:*:*:*:*:*:*:*:*",
"grocy=grocy":"cpe:2.3:a:grocy:grocy:*:*:*:*:*:*:*:*",
"Intermesh=groupoffice":"cpe:2.3:a:group-office:group_office:*:*:*:*:*:*:*:*",
"Japan Total System Co.,Ltd.=GroupSession":"cpe:2.3:a:groupsession:groupsession:*:*:*:*:*:*:*:*",
"growthbook=growthbook":"cpe:2.3:a:growthbook:growthbook:*:*:*:*:*:*:*:*",
"Google=gRPC":"cpe:2.3:a:grpc:grpc:*:*:*:*:*:*:*:*",
"Grundfos=CIM 500":"cpe:2.3:a:grundfos:cim_500:*:*:*:*:*:*:*:*",
"Group Arge Energy and Control Systems=Smartpower Web":"cpe:2.3:a:gruparge:smartpower:*:*:*:*:*:*:*:*",
"Group Arge Energy and Control Systems=Smartpower Web":"cpe:2.3:a:gruparge:smartpower_web:*:*:*:*:*:*:*:*",
"Grupo Alumne=Alumne LMS":"cpe:2.3:a:grupoalumne:alumne_lms:*:*:*:*:*:*:*:*",
"Geospatial Information Authority of Japan (GSI)=PatchJGD (Hyoko) (PatchJGDh101.EXE)":"cpe:2.3:a:gsi:patchjgd:*:*:*:*:*:*:*:*",
"Geospatial Information Authority of Japan (GSI)=PatchJGD (PatchJGD101.EXE)":"cpe:2.3:a:gsi:patchjgd:*:*:*:*:*:*:*:*",
"Geospatial Information Authority of Japan (GSI)=SemiDynaEXE (SemiDynaEXE2008.EXE)":"cpe:2.3:a:gsi:semidynaexe:*:*:*:*:*:*:*:*",
"Geospatial Information Authority of Japan (GSI)=TKY2JGD (TKY2JGD1379.EXE)":"cpe:2.3:a:gsi:tky2jgd:*:*:*:*:*:*:*:*",
"gssapi=gss-ntlmssp":"cpe:2.3:a:gss-ntlmssp_project:gss-ntlmssp:*:*:*:*:*:*:*:*",
"Galaxy Software Services=Vitals ESP":"cpe:2.3:a:gss:vitals_enterprise_social_platform:*:*:*:*:*:*:*:*",
"Galaxy Software Services=Vitals ESP ":"cpe:2.3:a:gss:vitals_enterprise_social_platform:*:*:*:*:*:*:*:*",
"gturri=aXMLRPC":"cpe:2.3:a:gturri:axmlrpc:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.skywise:guardian":"cpe:2.3:a:guardiansoft:guardian:*:*:*:*:*:*:*:*",
"SourceCodester=Guest Management System":"cpe:2.3:a:guest_management_system_project:guest_management_system:*:*:*:*:*:*:*:*",
"GullsEye=GullsEye terminal operating system":"cpe:2.3:a:gullseye:gullseye_terminal_operating_system:*:*:*:*:*:*:*:*",
"GurumNetworks=GurumDDS":"cpe:2.3:a:gurum:gurumdds:*:*:*:*:*:*:*:*",
"Kashipara Group=Hotel Management":"cpe:2.3:a:gvnpatidar:hotel_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Gym Management System":"cpe:2.3:a:gym_management_system_project:gym_management_system:*:*:*:*:*:*:*:*",
"GZ Scripts=Availability Booking Calendar PHP":"cpe:2.3:a:gzscripts:availability_booking_calendar_php:*:*:*:*:*:*:*:*",
"GZ Scripts=Car Listing Script PHP":"cpe:2.3:a:gzscripts:car_listing_script_php:*:*:*:*:*:*:*:*",
"GZ Scripts=Car Rental Script":"cpe:2.3:a:gzscripts:car_rental_php_script:*:*:*:*:*:*:*:*",
"GZ Scripts=Event Booking Calendar":"cpe:2.3:a:gzscripts:event_booking_calendar:*:*:*:*:*:*:*:*",
"GZ Scripts=GZ E Learning Platform":"cpe:2.3:a:gzscripts:gz_e_learning_platform:*:*:*:*:*:*:*:*",
"GZ Scripts=GZ Forum Script":"cpe:2.3:a:gzscripts:gz_forum_script:*:*:*:*:*:*:*:*",
"GZ Scripts=GZ Multi Hotel Booking System":"cpe:2.3:a:gzscripts:gz_multi_hotel_booking_system:*:*:*:*:*:*:*:*",
"GZ Scripts=PHP CRM Platform":"cpe:2.3:a:gzscripts:php_crm_platform:*:*:*:*:*:*:*:*",
"GZ Scripts=PHP GZ Appointment Scheduling Script":"cpe:2.3:a:gzscripts:php_gz_appointment_scheduling_script:*:*:*:*:*:*:*:*",
"GZ Scripts=PHP GZ Hotel Booking Script":"cpe:2.3:a:gzscripts:php_gz_hotel_booking_script:*:*:*:*:*:*:*:*",
"GZ Scripts=PHP Vacation Rental Script":"cpe:2.3:a:gzscripts:php_vacation_rental_script:*:*:*:*:*:*:*:*",
"GZ Scripts=Property Listing Script":"cpe:2.3:a:gzscripts:property_listing_script:*:*:*:*:*:*:*:*",
"GZ Scripts=Ticket Booking Script":"cpe:2.3:a:gzscripts:ticket_booking_script:*:*:*:*:*:*:*:*",
"GZ Scripts=Time Slot Booking Calendar PHP":"cpe:2.3:a:gzscripts:time_slot_booking_calendar_php:*:*:*:*:*:*:*:*",
"GZ Scripts=Vacation Rental Website":"cpe:2.3:a:gzscripts:vacation_rental_website:*:*:*:*:*:*:*:*",
"h2oai=h2oai/h2o-3":"cpe:2.3:a:h2o:h2o:*:*:*:*:*:*:*:*",
"Haas=Haas CNC Controller":"cpe:2.3:a:haascnc:haas_controller:*:*:*:*:*:*:*:*",
"habitica=habitica":"cpe:2.3:a:habitica:habitica:*:*:*:*:*:*:*:*",
"ghostlander=Halcyon":"cpe:2.3:a:halcyon_project:halcyon:*:*:*:*:*:*:*:*",
"Hallo Welt! GmbH=BlueSpice":"cpe:2.3:a:hallowelt:bluespice:*:*:*:*:*:*:*:*",
"halo-dev=halo":"cpe:2.3:a:halo:halo:*:*:*:*:*:*:*:*",
"Hammock Corporation=AssetView":"cpe:2.3:a:hammock:assetview:*:*:*:*:*:*:*:*",
"Hanbiro=Hanbiro groupware":"cpe:2.3:a:hanbiro:groupware:*:*:*:*:*:*:*:*",
"Hancom=Hancom Office 2020":"cpe:2.3:a:hancom:hancom_office_2020:*:*:*:*:*:*:*:*",
"Hancom=Hancom office Hword NEO":"cpe:2.3:a:hancom:thinkfree_office_neo:*:*:*:*:*:*:*:*",
"HangfireIO=Hangfire":"cpe:2.3:a:hangfire:hangfire:*:*:*:*:*:*:*:*",
"HaoKeKeJi=YiQiNiu":"cpe:2.3:a:haokekeji:yiqiniu:*:*:*:*:*:*:*:*",
"HAProxy Technologies=HAProxy":"cpe:2.3:a:haproxy:haproxy:*:*:*:*:*:*:*:*",
"HashiCorp=Boundary":"cpe:2.3:a:hashicorp:boundary:*:*:*:*:*:*:*:*",
"HashiCorp=go-getter":"cpe:2.3:a:hashicorp:go-getter:*:*:*:*:*:*:*:*",
"HashiCorp=Terraform Enterprise":"cpe:2.3:a:hashicorp:terraform_enterprise:*:*:*:*:*:*:*:*",
"HashiCorp=Vagrant":"cpe:2.3:a:hashicorp:vagrant:*:*:*:*:*:*:*:*",
"unspecified=xml-conduit":"cpe:2.3:a:haskell:xml-conduit:*:*:*:*:*:*:*:*",
"hasura=graphql-engine":"cpe:2.3:a:hasura:graphql_engine:*:*:*:*:*:*:*:*",
"[UNKNOWN]=hawtio":"cpe:2.3:a:hawt.io:hawtio:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=hawtio":"cpe:2.3:a:hawt:hawtio:*:*:*:*:*:*:*:*",
"unspecified=hawtio":"cpe:2.3:a:hawt:hawtio:*:*:*:*:*:*:*:*",
"CURL=curl":"cpe:2.3:a:haxx:curl:*:*:*:*:*:*:*:*",
"curl=curl":"cpe:2.3:a:haxx:curl:*:*:*:*:*:*:*:*",
"[UNKNOWN]=curl":"cpe:2.3:a:haxx:libcurl:*:*:*:*:*:*:*:*",
"curl=curl":"cpe:2.3:a:haxx:libcurl:*:*:*:*:*:*:*:*",
"hayageek= jQuery Upload File":"cpe:2.3:a:hayageek:jquery_upload_file:*:*:*:*:*:*:*:*",
"HCL Software=Domino AppDev Pack":"cpe:2.3:a:hcl:domino_appdev_pack:*:*:*:*:*:*:*:*",
"HCL Software=HCL AppScan Presence":"cpe:2.3:a:hcltech:appscan_presence:*:*:*:*:*:*:*:*",
"HCL=AppScan Source":"cpe:2.3:a:hcltech:appscan_source:*:*:*:*:*:*:*:*",
"HCL Software=BigFix Insights for Vulnerability Remediation":"cpe:2.3:a:hcltech:bigfix_insights_for_vulnerability_remediation:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix Inventory":"cpe:2.3:a:hcltech:bigfix_inventory:*:*:*:*:*:*:*:*",
"HCL Software =HCL BigFix Mobile":"cpe:2.3:a:hcltech:bigfix_mobile:*:*:*:*:*:*:*:*",
"HCL Software=BigFix Mobile":"cpe:2.3:a:hcltech:bigfix_mobile:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix Mobile / Modern Client Management":"cpe:2.3:a:hcltech:bigfix_modern_client_management:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix OSD Bare Metal Server":"cpe:2.3:a:hcltech:bigfix_osd_bare_metal_server:*:*:*:*:*:*:*:*",
"HCL Software=BigFix Patch Management":"cpe:2.3:a:hcltech:bigfix_patch_management:*:*:*:*:*:*:*:*",
"HCL Software=BigFix Platform":"cpe:2.3:a:hcltech:bigfix_platform:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix":"cpe:2.3:a:hcltech:bigfix_platform:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix Platform":"cpe:2.3:a:hcltech:bigfix_platform:*:*:*:*:*:*:*:*",
"HCL Software=BigFix ServiceNow Data Flow":"cpe:2.3:a:hcltech:bigfix_servicenow_data_flow:*:*:*:*:*:*:*:*",
"HCL Software=BigFix WebUI":"cpe:2.3:a:hcltech:bigfix_webui:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix WebUI":"cpe:2.3:a:hcltech:bigfix_webui:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix WebUI ":"cpe:2.3:a:hcltech:bigfix_webui:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix WebUI API":"cpe:2.3:a:hcltech:bigfix_webui:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix WebUI Software Distribution":"cpe:2.3:a:hcltech:bigfix_webui:*:*:*:*:*:*:*:*",
"HCL=HCL BigFix WebUI":"cpe:2.3:a:hcltech:bigfix_webui:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix WebUI Insights":"cpe:2.3:a:hcltech:bigfix_webui_insights:*:*:*:*:*:*:*:*",
"HCL Software=HCL Commerce":"cpe:2.3:a:hcltech:commerce:*:*:*:*:*:*:*:*",
"HCL Software=HCL Connections":"cpe:2.3:a:hcltech:connections:*:*:*:*:*:*:*:*",
"HCL Software=HCL Connections":"cpe:2.3:a:hcltech:connections:*:*:*:*:*:*:*:*",
"HCL=HCL Connections":"cpe:2.3:a:hcltech:connections:*:*:*:*:*:*:*:*",
"HCL Software=Digital Experience":"cpe:2.3:a:hcltech:digital_experience:*:*:*:*:*:*:*:*",
"HCL Software=HCL Digital Experience":"cpe:2.3:a:hcltech:digital_experience:*:*:*:*:*:*:*:*",
"HCL Software=HCL Domino ":"cpe:2.3:a:hcltech:domino:*:*:*:*:*:*:*:*",
"HCL Software=HCL DRYiCE iAutomate":"cpe:2.3:a:hcltech:dryice_iautomate:*:*:*:*:*:*:*:*",
"HCL Software=HCL DRYiCE MyCloud ":"cpe:2.3:a:hcltech:dryice_mycloud:*:*:*:*:*:*:*:*",
"HCL Software=DRYiCE MyXalytics":"cpe:2.3:a:hcltech:dryice_myxalytics:*:*:*:*:*:*:*:*",
"HCL Software=HCL Compass":"cpe:2.3:a:hcltech:hcl_compass:*:*:*:*:*:*:*:*",
"HCL Software=HCL Compass2.0 ":"cpe:2.3:a:hcltech:hcl_compass:*:*:*:*:*:*:*:*",
"HCL Software=HCL Digital Experience":"cpe:2.3:a:hcltech:hcl_digital_experience:*:*:*:*:*:*:*:*",
"HCL Software=Leap":"cpe:2.3:a:hcltech:hcl_leap:*:*:*:*:*:*:*:*",
"HCL=HCL Notes":"cpe:2.3:a:hcltech:notes:*:*:*:*:*:*:*:*",
"HCL Software=HCL OneTest Server":"cpe:2.3:a:hcltech:onetest_server:*:*:*:*:*:*:*:*",
"HCL Software=HCL Sametime":"cpe:2.3:a:hcltech:sametime:*:*:*:*:*:*:*:*",
"HCL Software=Sametime":"cpe:2.3:a:hcltech:sametime:*:*:*:*:*:*:*:*",
"IBM Corporation=HCL BigFix Self-Service Application (SSA)":"cpe:2.3:a:hcltech:self-service_application:*:*:*:*:*:*:*:*",
"HCL Software=HCL Traveler":"cpe:2.3:a:hcltech:traveler:*:*:*:*:*:*:*:*",
"HCL=HCL Traveler":"cpe:2.3:a:hcltech:traveler:*:*:*:*:*:*:*:*",
"HCL Software=HCL Unica":"cpe:2.3:a:hcltech:unica:*:*:*:*:*:*:*:*",
"HCL Software=HCL Unica Campaign":"cpe:2.3:a:hcltech:unica:*:*:*:*:*:*:*:*",
"HCL Software=HCL Unica Platform":"cpe:2.3:a:hcltech:unica:*:*:*:*:*:*:*:*",
"HCL Software=HCL Verse":"cpe:2.3:a:hcltech:verse:*:*:*:*:*:*:*:*",
"HCL Software=HCL VersionVault Express":"cpe:2.3:a:hcltech:versionvault_express:*:*:*:*:*:*:*:*",
"HCL Software=HCL BigFix OSD Bare Metal Server WebUI":"cpe:2.3:a:hcltechsw:bigfix_bare_osd_metal_server_webui:*:*:*:*:*:*:*:*",
"HCL Software=BigFix Insights for Vulnerability Remediation":"cpe:2.3:a:hcltechsw:bigfix_insights_for_vulnerability_remediation:*:*:*:*:*:*:*:*",
"HCL Software=HCL Commerce":"cpe:2.3:a:hcltechsw:hcl_commerce:*:*:*:*:*:*:*:*",
"HCL Software=HCL Launch":"cpe:2.3:a:hcltechsw:hcl_launch:*:*:*:*:*:*:*:*",
"HCL Software=HCL Workload Automation":"cpe:2.3:a:hcltechsw:hcl_workload_automation:*:*:*:*:*:*:*:*",
"HDF5 Group=libhdf5":"cpe:2.3:a:hdfgroup:hdf5:*:*:*:*:*:*:*:*",
"HimitZH=HOJ":"cpe:2.3:a:hdoi:hcode_online_judge:*:*:*:*:*:*:*:*",
"SourceCodester=Health Center Patient Record Management System":"cpe:2.3:a:health_center_patient_record_management_system_project:health_center_patient_record_management_system:*:*:*:*:*:*:*:*",
"healthchecks=healthchecks/healthchecks":"cpe:2.3:a:healthchecks:healthchecks:*:*:*:*:*:*:*:*",
"ONC=code-validator-api":"cpe:2.3:a:healthit:code-validator-api:*:*:*:*:*:*:*:*",
"Hedef Tracking=Admin Panel":"cpe:2.3:a:hedeftakip:admin_portal:*:*:*:*:*:*:*:*",
"hedgedoc=hedgedoc":"cpe:2.3:a:hedgedoc:hedgedoc:*:*:*:*:*:*:*:*",
"HEIMAVISTA INC.=Rpage":"cpe:2.3:a:heimavista:dark_horse_rpage:*:*:*:*:*:*:*:*",
"HackerOne=hekto":"cpe:2.3:a:hekto_project:hekto:*:*:*:*:*:*:*:*",
"The Helm Project=Helm":"cpe:2.3:a:helm:helm:*:*:*:*:*:*:*:*",
"helm=Helm":"cpe:2.3:a:helm:helm:*:*:*:*:*:*:*:*",
"helm=helm":"cpe:2.3:a:helm:helm:*:*:*:*:*:*:*:*",
"HelpDezk=HelpDezk Community":"cpe:2.3:a:helpdezk:helpdezk:*:*:*:*:*:*:*:*",
"Henschen & Associates=court document management software":"cpe:2.3:a:henschen:court_document_management:*:*:*:*:*:*:*:*",
"heroiclabs=heroiclabs/nakama":"cpe:2.3:a:heroiclabs:nakama:*:*:*:*:*:*:*:*",
"hestiacp=hestiacp/hestiacp":"cpe:2.3:a:hestiacp:control_panel:*:*:*:*:*:*:*:*",
"hestiacp=hestiacp/hestiacp":"cpe:2.3:a:hestiacp:hestiacp:*:*:*:*:*:*:*:*",
"HGiga=C&Cmail":"cpe:2.3:a:hgiga:oaklouds_ccm\@il:*:*:*:*:*:*:*:*",
"HGiga=MailSherlock":"cpe:2.3:a:hgiga:oaklouds_mailsherlock:*:*:*:*:*:*:*:*",
"OAKlouds =MailSherlock":"cpe:2.3:a:hgiga:oaklouds_mailsherlock:*:*:*:*:*:*:*:*",
"HGIGA INC.=HGiga OAKlouds":"cpe:2.3:a:hgiga:oaklouds_portal:*:*:*:*:*:*:*:*",
"tsruban=HHIMS":"cpe:2.3:a:hhims_project:hhims:*:*:*:*:*:*:*:*",
"HiBARA Software=AttacheCase":"cpe:2.3:a:hibara:attachecase:*:*:*:*:*:*:*:*",
"HiBARA Software=Self-extracting encrypted files created by AttacheCase":"cpe:2.3:a:hibara:attachecase:*:*:*:*:*:*:*:*",
"HID Global=EasyLobby Solo":"cpe:2.3:a:hidglobal:easylobby_solo:*:*:*:*:*:*:*:*",
"HID Global=SAFE":"cpe:2.3:a:hidglobal:safe:*:*:*:*:*:*:*:*",
"Honor=HonorBoardApp":"cpe:2.3:a:hihonor:honorboardapp:*:*:*:*:*:*:*:*",
"Honor=Magic UI":"cpe:2.3:a:hihonor:magic_ui:*:*:*:*:*:*:*:*",
"Honor=com.hihonor.magichome ":"cpe:2.3:a:hihonor:magichome:*:*:*:*:*:*:*:*",
"Honor=com.hihonor.vmall ":"cpe:2.3:a:hihonor:vmall:*:*:*:*:*:*:*:*",
"Hikvision=HikCentral Professional":"cpe:2.3:a:hikvision:hikcentral_professional:*:*:*:*:*:*:*:*",
"Hikvision=LocalServiceComponents":"cpe:2.3:a:hikvision:localservicecomponents:*:*:*:*:*:*:*:*",
"unspecified=Hindu Matrimonial Script":"cpe:2.3:a:hindu_matrimonial_script_project:hindu_matrimonial_script:*:*:*:*:*:*:*:*",
"hisiphp=hisiphp":"cpe:2.3:a:hisiphp:hisiphp:*:*:*:*:*:*:*:*",
"Hitachi Vantara=Hitachi Content Platform":"cpe:2.3:a:hitach:vantara:*:*:*:*:*:*:*:*",
"Hitachi Solutions, Ltd.=HIBUN Confidential File Decryption program":"cpe:2.3:a:hitachi-solutions:confidential_file_decryption:*:*:*:*:*:*:*:*",
"Hitachi Solutions, Ltd.=Installer of HIBUN Confidential File Viewer":"cpe:2.3:a:hitachi-solutions:confidential_file_viewer:*:*:*:*:*:*:*:*",
"Webdetails=cpf":"cpe:2.3:a:hitachi:community_plugin_framework:*:*:*:*:*:*:*:*",
"Hitachi=EH-VIEW":"cpe:2.3:a:hitachi:eh-view:*:*:*:*:*:*:*:*",
"Hitachi Energy=LinkOne":"cpe:2.3:a:hitachi:linkone:*:*:*:*:*:*:*:*",
"Hitachi Vantara=Pentaho Data Integration & Analytics":"cpe:2.3:a:hitachi:pentaho_data_integration_and_analytics:*:*:*:*:*:*:*:*",
"Hitachi=Hitachi Tuning Manager":"cpe:2.3:a:hitachi:tuning_manager:*:*:*:*:*:*:*:*",
"Hitachi Vantara=Pentaho Business Analytics Server":"cpe:2.3:a:hitachi:vantara_pentaho:*:*:*:*:*:*:*:*",
"Hitachi Vantara =Pentaho Business Analytics Server":"cpe:2.3:a:hitachi:vantara_pentaho_business_analytics_server:*:*:*:*:*:*:*:*",
"Hitachi Vantara=Pentaho Business Analytics Server":"cpe:2.3:a:hitachi:vantara_pentaho_business_analytics_server:*:*:*:*:*:*:*:*",
"ABB=Asset Suite":"cpe:2.3:a:hitachienergy:asset_suite:*:*:*:*:*:*:*:*",
"Hitachi Energy=Asset Suite 9":"cpe:2.3:a:hitachienergy:asset_suite:*:*:*:*:*:*:*:*",
"Hitachi ABB Power Grids=Ellipse Enterprise Asset Management (EAM)":"cpe:2.3:a:hitachienergy:ellipse_enterprise_asset_management:*:*:*:*:*:*:*:*",
"ABB=eSOMS":"cpe:2.3:a:hitachienergy:esoms:*:*:*:*:*:*:*:*",
"Hitachi ABB Power Grids=eSOMS":"cpe:2.3:a:hitachienergy:esoms:*:*:*:*:*:*:*:*",
"Hitachi Energy=eSOMS":"cpe:2.3:a:hitachienergy:esoms:*:*:*:*:*:*:*:*",
"ICS-CERT=ABB eSOMS":"cpe:2.3:a:hitachienergy:esoms:*:*:*:*:*:*:*:*",
"Hitachi Energy=MACH System Software":"cpe:2.3:a:hitachienergy:modular_advanced_control_for_hvdc:*:*:*:*:*:*:*:*",
"Hitachi Energy=RTU500 Scripting Interface":"cpe:2.3:a:hitachienergy:rtu500_scripting_interface:*:*:*:*:*:*:*:*",
"Hitachi Energy=SDM600":"cpe:2.3:a:hitachienergy:sdm600:*:*:*:*:*:*:*:*",
"Hitachi Vantara=Pentaho Business Analytics Server":"cpe:2.3:a:hitachivantara:pentaho_business_analytics:*:*:*:*:*:*:*:*",
"Hitsteps=Hitsteps Web Analytics":"cpe:2.3:a:hitsteps:web_analytics:*:*:*:*:*:*:*:*",
"HIWIN=HIWIN Robot System Software":"cpe:2.3:a:hiwin:robot_system_software:*:*:*:*:*:*:*:*",
"HMS Industrial Networks AB=eCatcher":"cpe:2.3:a:hms-networks:ecatcher:*:*:*:*:*:*:*:*",
"Pingkon=HMS-PHP":"cpe:2.3:a:hms-php_project:hms-php:*:*:*:*:*:*:*:*",
"HumanTalk Co,Ltd=DaviewIndy":"cpe:2.3:a:hmtalk:daviewindy:*:*:*:*:*:*:*:*",
"HumanTalk=DaviewIndy":"cpe:2.3:a:hmtalk:daviewindy:*:*:*:*:*:*:*:*",
"HumanTolk Co.Ltd=DaviewIndy":"cpe:2.3:a:hmtalk:daviewindy:*:*:*:*:*:*:*:*",
"ChewKeanHo=AutomataCI":"cpe:2.3:a:hollowaykeanho:automataci:*:*:*:*:*:*:*:*",
"home-assistant=core":"cpe:2.3:a:home-assistant:home-assistant:*:*:*:*:*:*:*:*",
"unspecified=Home Clean Services Management System":"cpe:2.3:a:home_clean_services_management_system_project:home_clean_services_management_system:*:*:*:*:*:*:*:*",
"Matrikon, a subsidiary of Honeywell=Matrikon OPC Server":"cpe:2.3:a:honeywell:matrikon_opc_server:*:*:*:*:*:*:*:*",
"Honeywell=Notifier Web Server (NWS) Version 3.50 and earlier":"cpe:2.3:a:honeywell:notifier_webserver:*:*:*:*:*:*:*:*",
"Honeywell=ProWatch":"cpe:2.3:a:honeywell:prowatch:*:*:*:*:*:*:*:*",
"Honeywell=SoftMaster":"cpe:2.3:a:honeywell:softmaster:*:*:*:*:*:*:*:*",
"hongmaple=octopus":"cpe:2.3:a:hongmaple:octopus:*:*:*:*:*:*:*:*",
"hoppscotch=hoppscotch":"cpe:2.3:a:hoppscotch:hoppscotch:*:*:*:*:*:*:*:*",
"hoppscotch=hoppscotch/hoppscotch":"cpe:2.3:a:hoppscotch:hoppscotch:*:*:*:*:*:*:*:*",
"jwise=HoRNDIS":"cpe:2.3:a:horndis_project:horndis:*:*:*:*:*:*:*:*",
"Horner Automation=Cscape Envision RV":"cpe:2.3:a:hornerautomation:cscape_envision_rv:*:*:*:*:*:*:*:*",
"Horner Automation=Cscape EnvisionRV":"cpe:2.3:a:hornerautomation:cscape_envisionrv:*:*:*:*:*:*:*:*",
"horovod=horovod/horovod":"cpe:2.3:a:horovod:horovod:*:*:*:*:*:*:*:*",
"unspecified=Hospital Management Center":"cpe:2.3:a:hospital_management_center_project:hospital_management_center:*:*:*:*:*:*:*:*",
"SourceCodester=Hospital Management System":"cpe:2.3:a:hospital_management_system_project:hospital_management_system:*:*:*:*:*:*:*:*",
"unspecified=Hostel Searching Project":"cpe:2.3:a:hostel_searching_project:hostel_searching_project:*:*:*:*:*:*:*:*",
"SourceCodester=Hotel Management System":"cpe:2.3:a:hotel_management_system_project:hotel_management_system:*:*:*:*:*:*:*:*",
"hotjar=Hotjar":"cpe:2.3:a:hotjar:hotjar:*:*:*:*:*:*:*:*",
"SourceCodester=House Rental and Property Listing System":"cpe:2.3:a:house_rental_and_property_listing_php_project:house_rental_and_property_listing_php:*:*:*:*:*:*:*:*",
"unspecified=House Rental System":"cpe:2.3:a:house_rental_system_project:house_rental_system:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE 3PAR StoreServ Management and Core Software Media":"cpe:2.3:a:hp:3par_storeserv_management_console:*:*:*:*:*:*:*:*",
"Micro Focus=HPE Application Performance Management (APM)":"cpe:2.3:a:hp:application_performance_management:*:*:*:*:*:*:*:*",
"Micro Focus=ArcSight Logger":"cpe:2.3:a:hp:arcsight_logger:*:*:*:*:*:*:*:*",
"Micro Focus=ArcSight Management Center":"cpe:2.3:a:hp:arcsight_management_center:*:*:*:*:*:*:*:*",
"Micro Focus=Arcsight Security Management Center":"cpe:2.3:a:hp:arcsight_management_center:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Aruba ClearPass":"cpe:2.3:a:hp:aruba_clearpass_policy_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Aruba ClearPass Policy Manager":"cpe:2.3:a:hp:aruba_clearpass_policy_manager:*:*:*:*:*:*:*:*",
"Bromium=Bromium client":"cpe:2.3:a:hp:bromium:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Business Process Monitor":"cpe:2.3:a:hp:business_process_monitor:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE CentralView Fraud Risk Management":"cpe:2.3:a:hp:centralview_fraud_risk_management:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Cloud Optimizer":"cpe:2.3:a:hp:cloud_optimizer:*:*:*:*:*:*:*:*",
"Micro Focus=HPE Content Manager Workgroup Service":"cpe:2.3:a:hp:content_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Data Protector":"cpe:2.3:a:hp:data_protector:*:*:*:*:*:*:*:*",
"HPE=HPE enhanced Internet Usage Manager (eIUM)":"cpe:2.3:a:hp:enhanced_internet_usage_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Fortify Software Security Center ":"cpe:2.3:a:hp:fortify_software_security_center:*:*:*:*:*:*:*:*",
"HP Inc.=HPSFViewer":"cpe:2.3:a:hp:hpsfviewer:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=IceWall Federation Agent":"cpe:2.3:a:hp:icewall_federation_agent:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Intelligent Management Center (iMC) PLAT":"cpe:2.3:a:hp:intelligent_management_center:*:*:*:*:*:*:*:*",
"HP Inc.=HP JetAdvantage Security Manager":"cpe:2.3:a:hp:jetadvantage_security_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Matrix Operating Environment":"cpe:2.3:a:hp:matrix_operating_environment:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE Moonshot Provisioning Manager":"cpe:2.3:a:hp:moonshot_provisioning_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Moonshot Provisioning Manager Appliance":"cpe:2.3:a:hp:moonshot_provisioning_manager_appliance:*:*:*:*:*:*:*:*",
"HPE=HPE MSE Msg Gw application E-LTU":"cpe:2.3:a:hp:mse_msg_gw_application_e-ltu:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Network Automation":"cpe:2.3:a:hp:network_automation:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Network Node Manager i (NNMi) Software":"cpe:2.3:a:hp:network_node_manager_i:*:*:*:*:*:*:*:*",
"HPE=NetBatch-Plus software":"cpe:2.3:a:hp:nonstop_netbatch-plus:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE OneView":"cpe:2.3:a:hp:oneview:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE OneView":"cpe:2.3:a:hp:oneview:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE OneView for VMware vCenter,":"cpe:2.3:a:hp:oneview_for_vmware_vcenter:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=OpenCall Media Platform (OCMP)":"cpe:2.3:a:hp:opencall_media_platform:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Operations Bridge Analytics":"cpe:2.3:a:hp:operations_bridge_analytics:*:*:*:*:*:*:*:*",
"Micro Focus=HPE Operations Orchestration":"cpe:2.3:a:hp:operations_orchestration:*:*:*:*:*:*:*:*",
"Micro Focus=Operations Orchestration Software":"cpe:2.3:a:hp:operations_orchestration:*:*:*:*:*:*:*:*",
"Micro Focus=HPE Performance Center":"cpe:2.3:a:hp:performance_center:*:*:*:*:*:*:*:*",
"HP=HP Remote Graphics Software":"cpe:2.3:a:hp:remote_graphics_software:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE RESTful Interface Tool":"cpe:2.3:a:hp:restful_interface_tool:*:*:*:*:*:*:*:*",
"HP Inc.=HP Security Manager":"cpe:2.3:a:hp:security_manager:*:*:*:*:*:*:*:*",
"HP Inc.=HP Security Manager ":"cpe:2.3:a:hp:security_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=SiteScope":"cpe:2.3:a:hp:sitescope:*:*:*:*:*:*:*:*",
"HP Inc.=HP Support Assistant":"cpe:2.3:a:hp:support_assistant:*:*:*:*:*:*:*:*",
"HP=HP Support Assistant":"cpe:2.3:a:hp:support_assistant:*:*:*:*:*:*:*:*",
"HP=ThinPro Linux":"cpe:2.3:a:hp:thinpro_linux:*:*:*:*:*:*:*:*",
"HP=Touchpoint Analytics":"cpe:2.3:a:hp:touchpoint_analytics:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=UCMDB":"cpe:2.3:a:hp:ucmdb_configuration_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE UIoT":"cpe:2.3:a:hp:universal_internet_of_things:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Version Control Repository Manager (VCRM)":"cpe:2.3:a:hp:version_control_repository_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Vertica Analytics Platform":"cpe:2.3:a:hp:vertica:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=XP Storage using HGLM":"cpe:2.3:a:hp:xp_storage_hitachi_global_link_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE Device Entitlement Gateway (DEG)":"cpe:2.3:a:hpe:device_entitlement_gateway:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE Insight Remote Support ":"cpe:2.3:a:hpe:insight_remote_support:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE Intelligent Provisioning (IP) for Gen9":"cpe:2.3:a:hpe:intelligent_provisioning:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE OneView":"cpe:2.3:a:hpe:oneview:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE OneView Global Dashboard":"cpe:2.3:a:hpe:oneview_global_dashboard:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE OneView Global Dashboard (OVGD)":"cpe:2.3:a:hpe:oneview_global_dashboard:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HPE Serviceguard for Linux":"cpe:2.3:a:hpe:serviceguard_for_linux:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Smart Update Manager (SUM)":"cpe:2.3:a:hpe:smart_update_manager:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE StorageWorks XP7 Automation Director (AutoDir)":"cpe:2.3:a:hpe:storageworks_xp7_automation_director:*:*:*:*:*:*:*:*",
"Soar Cloud System Co., Ltd.=HR Portal":"cpe:2.3:a:hr_portal_project:hr_portal:*:*:*:*:*:*:*:*",
"Hongjing=e-HR":"cpe:2.3:a:hrp2000:e-hr:*:*:*:*:*:*:*:*",
"michaelrsweet=htmldoc":"cpe:2.3:a:htmldoc_project:htmldoc:*:*:*:*:*:*:*:*",
"mganss=HtmlSanitizer":"cpe:2.3:a:htmlsanitizer_project:htmlsanitizer:*:*:*:*:*:*:*:*",
"HtmlUnit=htmlunit":"cpe:2.3:a:htmlunit:htmlunit:*:*:*:*:*:*:*:*",
"unspecified=htmly":"cpe:2.3:a:htmly:htmly:*:*:*:*:*:*:*:*",
"swaggo=http-swagger":"cpe:2.3:a:http-swagger_project:http-swagger:*:*:*:*:*:*:*:*",
"Jasper=httpdx":"cpe:2.3:a:httpdx_project:httpdx:*:*:*:*:*:*:*:*",
"httpie=httpie/httpie":"cpe:2.3:a:httpie:httpie:*:*:*:*:*:*:*:*",
"RamseyK=httpserver":"cpe:2.3:a:httpserver_project:httpserver:*:*:*:*:*:*:*:*",
"hu60t=hu60wap6":"cpe:2.3:a:hu60:hu60wap6:*:*:*:*:*:*:*:*",
"Huachu Digital Technology Co.,Ltd.=Easytest":"cpe:2.3:a:huaju:easytest_online_learning_test_platform:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=AppGallery":"cpe:2.3:a:huawei:appgallery:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=eSpace Desktop":"cpe:2.3:a:huawei:espace_desktop:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=Files":"cpe:2.3:a:huawei:files:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=Files &#xa3;&#xa8;Files is the smartphone APP&#xa3;&#xa9;":"cpe:2.3:a:huawei:files:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=FusionCompute":"cpe:2.3:a:huawei:fusioncompute:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=FusionSphere OpenStack":"cpe:2.3:a:huawei:fusionsphere_openstack:*:*:*:*:*:*:*:*",
"Huawei=GaussDB 200":"cpe:2.3:a:huawei:gaussdb_200:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=HedEx Lite":"cpe:2.3:a:huawei:hedex_lite:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=HUAWEI HiLink APP (for IOS)":"cpe:2.3:a:huawei:hilink:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=HiWallet":"cpe:2.3:a:huawei:hiwallet:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=Huawei HiWallet App":"cpe:2.3:a:huawei:hiwallet:*:*:*:*:*:*:*:*",
"Huawei=iManager NetEco 6000":"cpe:2.3:a:huawei:imanager_neteco_6000:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=iReader":"cpe:2.3:a:huawei:ireader:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=Mate 9 Pro":"cpe:2.3:a:huawei:mate_9_pro:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=MHA-AL00A":"cpe:2.3:a:huawei:mha-al00a:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=iManager NetEco":"cpe:2.3:a:huawei:neteco:*:*:*:*:*:*:*:*",
"Huawei=PC Manager":"cpe:2.3:a:huawei:pcmanager:*:*:*:*:*:*:*:*",
"Huawei=PCManager":"cpe:2.3:a:huawei:pcmanager:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=SmartCare":"cpe:2.3:a:huawei:smartcare:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=UMA":"cpe:2.3:a:huawei:uma:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=HwVmall":"cpe:2.3:a:huawei:vmall:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=Vmall":"cpe:2.3:a:huawei:vmall:*:*:*:*:*:*:*:*",
"Huaxia=ERP":"cpe:2.3:a:huaxiaerp:huaxia_erp:*:*:*:*:*:*:*:*",
"huggingface=huggingface/transformers":"cpe:2.3:a:huggingface:transformers:*:*:*:*:*:*:*:*",
"HuiRan=Host Reseller System":"cpe:2.3:a:huiran_host_reseller_system_project:huiran_host_reseller_system:*:*:*:*:*:*:*:*",
"HumanSignal=label-studio":"cpe:2.3:a:humansignal:label_studio:*:*:*:*:*:*:*:*",
"humhub=humhub":"cpe:2.3:a:humhub:humhub:*:*:*:*:*:*:*:*",
"unspecified=HumHub":"cpe:2.3:a:humhub:humhub:*:*:*:*:*:*:*:*",
"Hundred Plus=101EIP":"cpe:2.3:a:hundredplus:101eip:*:*:*:*:*:*:*:*",
"Hunesion=i-oneNet":"cpe:2.3:a:hunesion:i-onenet:*:*:*:*:*:*:*:*",
"The hunter2 Contributors=hunter2":"cpe:2.3:a:hunter2_project:hunter2:*:*:*:*:*:*:*:*",
"Dromara=HuTool":"cpe:2.3:a:hutool:hutool:*:*:*:*:*:*:*:*",
"Hybrid Software=Cloudflow":"cpe:2.3:a:hybridsoftware:cloudflow:*:*:*:*:*:*:*:*",
"jshmrtn=hygeia":"cpe:2.3:a:hygeia_project:hygeia:*:*:*:*:*:*:*:*",
"Hyland=Nuxeo":"cpe:2.3:a:hyland:nuxeo:*:*:*:*:*:*:*:*",
"Hyland Software, Inc.=Perceptive Document Filters":"cpe:2.3:a:hyland:perceptive_document_filters:*:*:*:*:*:*:*:*",
"HyperaDev=Dragonfly":"cpe:2.3:a:hypera:dragonfly:*:*:*:*:*:*:*:*",
"hyperledger=fabric":"cpe:2.3:a:hyperledger:fabric:*:*:*:*:*:*:*:*",
"Chameleon Power=Chameleon Power":"cpe:2.3:a:hyphensolutions:chameleon_power:*:*:*:*:*:*:*:*",
"HYPR=HYPR Server":"cpe:2.3:a:hypr:hypr_server:*:*:*:*:*:*:*:*",
"HYPR=Keycloak Authenticator Extension":"cpe:2.3:a:hypr:keycloak_authenticator:*:*:*:*:*:*:*:*",
"HYPR=HYPR Windows WFA":"cpe:2.3:a:hypr:workforce_access:*:*:*:*:*:*:*:*",
"HYPR=Workforce Access":"cpe:2.3:a:hypr:workforce_access:*:*:*:*:*:*:*:*",
"Hiroshi Yuki=YukiWiki":"cpe:2.3:a:hyuki:yukiwiki:*:*:*:*:*:*:*:*",
"Hyweb=HyCMS-J1":"cpe:2.3:a:hyweb:hycms-j1:*:*:*:*:*:*:*:*",
"Synetics GmbH=I-doit":"cpe:2.3:a:i-doit:i-doit:*:*:*:*:*:*:*:*",
"i-PRO Co., Ltd.=VI Web Client":"cpe:2.3:a:i-pro:video_insight:*:*:*:*:*:*:*:*",
"iagona=ScrutisWeb":"cpe:2.3:a:iagona:scrutisweb:*:*:*:*:*:*:*:*",
"IBAX=go-ibax":"cpe:2.3:a:ibax:go-ibax:*:*:*:*:*:*:*:*",
"IBM Corporation=Algo One":"cpe:2.3:a:ibm:algo_one:*:*:*:*:*:*:*:*",
"IBM Corporation=API Connect":"cpe:2.3:a:ibm:api_connect:*:*:*:*:*:*:*:*",
"IBM=API Connect":"cpe:2.3:a:ibm:api_connect:*:*:*:*:*:*:*:*",
"IBM=App Connect Enterprise":"cpe:2.3:a:ibm:app_connect_enterprise:*:*:*:*:*:*:*:*",
"IBM=App Connect Enterprise Certified Container":"cpe:2.3:a:ibm:app_connect_enterprise_certified_container:*:*:*:*:*:*:*:*",
"IBM=Application Gateway":"cpe:2.3:a:ibm:application_gateway:*:*:*:*:*:*:*:*",
"IBM=Aspera Connect":"cpe:2.3:a:ibm:aspera_connect:*:*:*:*:*:*:*:*",
"IBM=Aspera Faspex":"cpe:2.3:a:ibm:aspera_faspex:*:*:*:*:*:*:*:*",
"IBM=Aspera":"cpe:2.3:a:ibm:aspera_on_cloud:*:*:*:*:*:*:*:*",
"IBM=Aspera Shares":"cpe:2.3:a:ibm:aspera_shares:*:*:*:*:*:*:*:*",
"IBM=Atlas eDiscovery Process Management":"cpe:2.3:a:ibm:atlas_ediscovery_process_management:*:*:*:*:*:*:*:*",
"IBM=BigFix Compliance":"cpe:2.3:a:ibm:bigfix_compliance:*:*:*:*:*:*:*:*",
"IBM=BigFix Platform":"cpe:2.3:a:ibm:bigfix_platform:*:*:*:*:*:*:*:*",
"IBM=BigFix family":"cpe:2.3:a:ibm:bigfix_platform:*:*:*:*:*:*:*:*",
"IBM=BigFix Remote Control":"cpe:2.3:a:ibm:bigfix_remote_control:*:*:*:*:*:*:*:*",
"IBM=BigFix Compliance Analytics":"cpe:2.3:a:ibm:bigfix_security_compliance_analytics:*:*:*:*:*:*:*:*",
"IBM=Endpoint Manager for Security and Compliance":"cpe:2.3:a:ibm:bigfix_security_compliance_analytics:*:*:*:*:*:*:*:*",
"IBM=Business Automation Content Analyzer on Cloud":"cpe:2.3:a:ibm:business_automation_content_analyzer_on_cloud:*:*:*:*:*:*:*:*",
"IBM=Business Automation Workflow":"cpe:2.3:a:ibm:business_automation_workflow:*:*:*:*:*:*:*:*",
"IBM=Business Process Manager":"cpe:2.3:a:ibm:business_process_manager:*:*:*:*:*:*:*:*",
"IBM=Business Process Manager Advanced":"cpe:2.3:a:ibm:business_process_manager:*:*:*:*:*:*:*:*",
"IBM=Campaign":"cpe:2.3:a:ibm:campaign:*:*:*:*:*:*:*:*",
"IBM=Case Manager":"cpe:2.3:a:ibm:case_manager:*:*:*:*:*:*:*:*",
"IBM=CICS Transaction Gateway Containers":"cpe:2.3:a:ibm:cics_transaction_gateway:*:*:*:*:*:*:*:*",
"IBM=Cloud App Management":"cpe:2.3:a:ibm:cloud_app_management:*:*:*:*:*:*:*:*",
"IBM=Cloud Automation Manager":"cpe:2.3:a:ibm:cloud_automation_manager:*:*:*:*:*:*:*:*",
"IBM=Cloud Object Storage System":"cpe:2.3:a:ibm:cloud_object_storage_system:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak for Applications":"cpe:2.3:a:ibm:cloud_pak_for_applications:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak for Data":"cpe:2.3:a:ibm:cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak for Multicloud Management":"cpe:2.3:a:ibm:cloud_pak_for_multicloud_management:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak for Multicloud Management Monitoring":"cpe:2.3:a:ibm:cloud_pak_for_multicloud_management_monitoring:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak for Security":"cpe:2.3:a:ibm:cloud_pak_for_security:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak System":"cpe:2.3:a:ibm:cloud_pak_system:*:*:*:*:*:*:*:*",
"IBM=Cloud Pak System Software Suite":"cpe:2.3:a:ibm:cloud_pak_system:*:*:*:*:*:*:*:*",
"IBM=Cloud Private":"cpe:2.3:a:ibm:cloud_private:*:*:*:*:*:*:*:*",
"IBM=Cloud Transformation Advisor":"cpe:2.3:a:ibm:cloud_transformation_advisor:*:*:*:*:*:*:*:*",
"IBM=Cognos Analytics":"cpe:2.3:a:ibm:cognos_analytics:*:*:*:*:*:*:*:*",
"IBM=Cognos Analytics Cartridge for Cloud Pak for Data":"cpe:2.3:a:ibm:cognos_analytics_cartridge_for_ibm_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Cognos Business Intelligence":"cpe:2.3:a:ibm:cognos_business_intelligence:*:*:*:*:*:*:*:*",
"IBM=Cognos Business Intelligence":"cpe:2.3:a:ibm:cognos_business_intelligence_server:*:*:*:*:*:*:*:*",
"IBM=Cognos Command Center":"cpe:2.3:a:ibm:cognos_command_center:*:*:*:*:*:*:*:*",
"IBM=Cognos Controller":"cpe:2.3:a:ibm:cognos_controller:*:*:*:*:*:*:*:*",
"IBM=Cognos Dashboards on Cloud Pak for Data":"cpe:2.3:a:ibm:cognos_dashboards_on_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Cognos TM1":"cpe:2.3:a:ibm:cognos_tm1:*:*:*:*:*:*:*:*",
"IBM=Connections":"cpe:2.3:a:ibm:connections:*:*:*:*:*:*:*:*",
"IBM=Connections":"cpe:2.3:a:ibm:connections_engagement_center:*:*:*:*:*:*:*:*",
"IBM Corporation=Content Navigator":"cpe:2.3:a:ibm:content_navigator:*:*:*:*:*:*:*:*",
"IBM=Content Navigator":"cpe:2.3:a:ibm:content_navigator:*:*:*:*:*:*:*:*",
"IBM=Control Center":"cpe:2.3:a:ibm:control_center:*:*:*:*:*:*:*:*",
"IBM=Control Desk":"cpe:2.3:a:ibm:control_desk:*:*:*:*:*:*:*:*",
"IBM=Cram Social Program Management":"cpe:2.3:a:ibm:curam_social_program_management:*:*:*:*:*:*:*:*",
"IBM=Curam SPM":"cpe:2.3:a:ibm:curam_social_program_management:*:*:*:*:*:*:*:*",
"IBM=Curam Social Program Management":"cpe:2.3:a:ibm:curam_social_program_management:*:*:*:*:*:*:*:*",
"IBM=Data Risk Manager":"cpe:2.3:a:ibm:data_risk_manager:*:*:*:*:*:*:*:*",
"IBM=Data Virtualization on Cloud Pak for Data":"cpe:2.3:a:ibm:data_virtualization_on_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Datacap":"cpe:2.3:a:ibm:datacap:*:*:*:*:*:*:*:*",
"IBM=DataPower Gateway":"cpe:2.3:a:ibm:datapower_gateway:*:*:*:*:*:*:*:*",
"IBM=DataPower Gateways":"cpe:2.3:a:ibm:datapower_gateway:*:*:*:*:*:*:*:*",
"IBM=Db2 Mirror for i":"cpe:2.3:a:ibm:db2_mirror_for_i:*:*:*:*:*:*:*:*",
"IBM=Disconnected Log Collector":"cpe:2.3:a:ibm:disconnected_log_collector:*:*:*:*:*:*:*:*",
"IBM=Domino":"cpe:2.3:a:ibm:domino:*:*:*:*:*:*:*:*",
"IBM=Rational DOORS Next Generation":"cpe:2.3:a:ibm:doors_next_generation:*:*:*:*:*:*:*:*",
"IBM=Edge":"cpe:2.3:a:ibm:edge_application_manager:*:*:*:*:*:*:*:*",
"IBM=Elastic Storage Server":"cpe:2.3:a:ibm:elastic_storage_server:*:*:*:*:*:*:*:*",
"IBM=Emptoris Contract Management":"cpe:2.3:a:ibm:emptoris_contract_management:*:*:*:*:*:*:*:*",
"IBM=Emptoris Services Procurement":"cpe:2.3:a:ibm:emptoris_services_procurement:*:*:*:*:*:*:*:*",
"IBM=Emptoris Sourcing":"cpe:2.3:a:ibm:emptoris_sourcing:*:*:*:*:*:*:*:*",
"IBM=Emptoris Spend Analysis":"cpe:2.3:a:ibm:emptoris_spend_analysis:*:*:*:*:*:*:*:*",
"IBM=Emptoris Sourcing":"cpe:2.3:a:ibm:emptoris_strategic_supply_management:*:*:*:*:*:*:*:*",
"IBM=Emptoris Strategic Supply Management":"cpe:2.3:a:ibm:emptoris_strategic_supply_management:*:*:*:*:*:*:*:*",
"IBM=Engineering Lifecycle Optimization - Publishing":"cpe:2.3:a:ibm:engineering_lifecycle_optimization:*:*:*:*:*:*:*:*",
"IBM=Rational Rhapsody Design Manager":"cpe:2.3:a:ibm:engineering_requirements_management_doors_next:*:*:*:*:*:*:*:*",
"IBM=Engineering Requirements Quality Assistant":"cpe:2.3:a:ibm:engineering_requirements_quality_assistant_on-premises:*:*:*:*:*:*:*:*",
"IBM=Engineering Requirements Quality Assistant On-Premises":"cpe:2.3:a:ibm:engineering_requirements_quality_assistant_on-premises:*:*:*:*:*:*:*:*",
"IBM=Rational Rhapsody Design Manager":"cpe:2.3:a:ibm:engineering_test_management:*:*:*:*:*:*:*:*",
"IBM=Event Streams":"cpe:2.3:a:ibm:event_streams:*:*:*:*:*:*:*:*",
"IBM=FileNet Content Manager":"cpe:2.3:a:ibm:filenet_content_manager:*:*:*:*:*:*:*:*",
"IBM=Financial Transaction Manager":"cpe:2.3:a:ibm:financial_transaction_manager:*:*:*:*:*:*:*:*",
"IBM=Financial Transaction Manager":"cpe:2.3:a:ibm:financial_transaction_manager_for_multiplatform:*:*:*:*:*:*:*:*",
"IBM=Financial Transaction Manager for SWIFT Services":"cpe:2.3:a:ibm:financial_transaction_manager_for_multiplatform:*:*:*:*:*:*:*:*",
"IBM=Spectrum Scale":"cpe:2.3:a:ibm:gpfs.tct.server:*:*:*:*:*:*:*:*",
"IBM=Guardium Cloud Key Manager":"cpe:2.3:a:ibm:guardium_cloud_key_manager:*:*:*:*:*:*:*:*",
"IBM=Guardium Data Encryption":"cpe:2.3:a:ibm:guardium_data_encryption:*:*:*:*:*:*:*:*",
"IBM=Security Guardium Data Encryption":"cpe:2.3:a:ibm:guardium_data_encryption:*:*:*:*:*:*:*:*",
"IBM=Power HMC":"cpe:2.3:a:ibm:hardware_management_console:*:*:*:*:*:*:*:*",
"IBM=Host Access Transformation Services":"cpe:2.3:a:ibm:host_access_transformation_services:*:*:*:*:*:*:*:*",
"IBM=GSKit":"cpe:2.3:a:ibm:http_server:*:*:*:*:*:*:*:*",
"IBM=i2 Analyst's Notebook":"cpe:2.3:a:ibm:i2_analysts_notebook:*:*:*:*:*:*:*:*",
"IBM=i2 Enterprise Insight Analysis":"cpe:2.3:a:ibm:i2_enterprise_insight_analysis:*:*:*:*:*:*:*:*",
"IBM=i2 iBase":"cpe:2.3:a:ibm:i2_ibase:*:*:*:*:*:*:*:*",
"IBM=i":"cpe:2.3:a:ibm:i:*:*:*:*:*:*:*:*",
"IBM=i Access Client Solutions":"cpe:2.3:a:ibm:i_access_client_solutions:*:*:*:*:*:*:*:*",
"IBM=Informix Dynamic Server":"cpe:2.3:a:ibm:informix_dynamic_server:*:*:*:*:*:*:*:*",
"IBM=Informix Servers":"cpe:2.3:a:ibm:informix_dynamic_server:*:*:*:*:*:*:*:*",
"IBM=Informix JDBC":"cpe:2.3:a:ibm:informix_jdbc:*:*:*:*:*:*:*:*",
"IBM=Informix JDBC":"cpe:2.3:a:ibm:informix_jdbc_driver:*:*:*:*:*:*:*:*",
"IBM Corporation=Informix Servers":"cpe:2.3:a:ibm:informix_open_admin_tool:*:*:*:*:*:*:*:*",
"IBM=BigInsights":"cpe:2.3:a:ibm:infosphere_biginsights:*:*:*:*:*:*:*:*",
"IBM=InfoSphere Information Server":"cpe:2.3:a:ibm:infosphere_information_server:*:*:*:*:*:*:*:*",
"IBM=InfoSphere Master Data Management":"cpe:2.3:a:ibm:infosphere_master_data_management_server:*:*:*:*:*:*:*:*",
"IBM=InfoSphere Information Server":"cpe:2.3:a:ibm:infosphere_metadata_asset_manager:*:*:*:*:*:*:*:*",
"IBM=Streams":"cpe:2.3:a:ibm:infosphere_streams:*:*:*:*:*:*:*:*",
"IBM=Notes":"cpe:2.3:a:ibm:inotes:*:*:*:*:*:*:*:*",
"IBM=iNotes":"cpe:2.3:a:ibm:inotes:*:*:*:*:*:*:*:*",
"IBM Corporation=IBM Insights Foundation for Energy":"cpe:2.3:a:ibm:insights_foundation_for_energy:*:*:*:*:*:*:*:*",
"IBM=Insights Foundation for Energy":"cpe:2.3:a:ibm:insights_foundation_for_energy:*:*:*:*:*:*:*:*",
"IBM=Integration Bus":"cpe:2.3:a:ibm:integration_bus:*:*:*:*:*:*:*:*",
"IBM=Integration Bus for z/OS":"cpe:2.3:a:ibm:integration_bus:*:*:*:*:*:*:*:*",
"IBM=Java":"cpe:2.3:a:ibm:java:*:*:*:*:*:*:*:*",
"IBM=Jazz for Service Management":"cpe:2.3:a:ibm:jazz_for_service_management:*:*:*:*:*:*:*:*",
"IBM=Tivoli Components":"cpe:2.3:a:ibm:jazz_for_service_management:*:*:*:*:*:*:*:*",
"IBM=Rational Rhapsody Design Manager":"cpe:2.3:a:ibm:jazz_foundation:*:*:*:*:*:*:*:*",
"IBM=Jazz Reporting Service":"cpe:2.3:a:ibm:jazz_reporting_service:*:*:*:*:*:*:*:*",
"IBM=Jazz Reporting Service (JRS)":"cpe:2.3:a:ibm:jazz_reporting_service:*:*:*:*:*:*:*:*",
"IBM=Jazz Team Server":"cpe:2.3:a:ibm:jazz_team_server:*:*:*:*:*:*:*:*",
"IBM Corporation=Kenexa LCMS Premier on Cloud":"cpe:2.3:a:ibm:kenexa_lcms_premier:*:*:*:*:*:*:*:*",
"IBM=License Metric Tool":"cpe:2.3:a:ibm:license_metric_tool:*:*:*:*:*:*:*:*",
"Loopback=Loopback":"cpe:2.3:a:ibm:loopback:*:*:*:*:*:*:*:*",
"IBM=MaaS360":"cpe:2.3:a:ibm:maas360:*:*:*:*:*:*:*:*",
"IBM=MaaS360 DTM":"cpe:2.3:a:ibm:maas360_dtm:*:*:*:*:*:*:*:*",
"IBM=Marketing Operations":"cpe:2.3:a:ibm:marketing_operations:*:*:*:*:*:*:*:*",
"IBM=Marketing Platform":"cpe:2.3:a:ibm:marketing_platform:*:*:*:*:*:*:*:*",
"IBM=Maximo Anywhere":"cpe:2.3:a:ibm:maximo_anywhere:*:*:*:*:*:*:*:*",
"IBM=Maximo Application Suite":"cpe:2.3:a:ibm:maximo_application_suite:*:*:*:*:*:*:*:*",
"IBM=Maximo Asset Management":"cpe:2.3:a:ibm:maximo_application_suite:*:*:*:*:*:*:*:*",
"IBM=Maximo Mobile":"cpe:2.3:a:ibm:maximo_application_suite:*:*:*:*:*:*:*:*",
"IBM Corporation=Maximo Asset Management":"cpe:2.3:a:ibm:maximo_asset_management:*:*:*:*:*:*:*:*",
"IBM=Maximo Asset Management":"cpe:2.3:a:ibm:maximo_asset_management:*:*:*:*:*:*:*:*",
"IBM=Maximo Spatial Asset Management":"cpe:2.3:a:ibm:maximo_spatial_asset_management:*:*:*:*:*:*:*:*",
"IBM Merge Healthcare= eFilm Workstation":"cpe:2.3:a:ibm:merge_efilm_workstation:*:*:*:*:*:*:*:*",
"IBM Merge Healthcare=eFilm Workstation":"cpe:2.3:a:ibm:merge_efilm_workstation:*:*:*:*:*:*:*:*",
"IBM Merge Healthcare=eFilm Workstation ":"cpe:2.3:a:ibm:merge_efilm_workstation:*:*:*:*:*:*:*:*",
"IBM=MobileFirst Platform Foundation":"cpe:2.3:a:ibm:mobile_foundation:*:*:*:*:*:*:*:*",
"IBM=MobileFirst Platform Foundation":"cpe:2.3:a:ibm:mobilefirst_platform_foundation:*:*:*:*:*:*:*:*",
"IBM=Monitoring":"cpe:2.3:a:ibm:monitoring:*:*:*:*:*:*:*:*",
"IBM=MQ":"cpe:2.3:a:ibm:mq:*:*:*:*:*:*:*:*",
"IBM=MQ Appliance":"cpe:2.3:a:ibm:mq_appliance:*:*:*:*:*:*:*:*",
"IBM=MQ for HPE NonStop":"cpe:2.3:a:ibm:mq_for_hpe_nonstop:*:*:*:*:*:*:*:*",
"IBM=MQ Internet Pass-Thru":"cpe:2.3:a:ibm:mq_internet_pass-thru:*:*:*:*:*:*:*:*",
"IBM=Multi-Cloud Data Encryption":"cpe:2.3:a:ibm:multi-cloud_data_encryption:*:*:*:*:*:*:*:*",
"IBM=Multicloud Manager":"cpe:2.3:a:ibm:multicloud_manager:*:*:*:*:*:*:*:*",
"IBM=Notes":"cpe:2.3:a:ibm:notes:*:*:*:*:*:*:*:*",
"IBM=Observability with Instana":"cpe:2.3:a:ibm:observability_with_instana:*:*:*:*:*:*:*:*",
"IBM=OpenPages GRC Platform":"cpe:2.3:a:ibm:openpages_grc_platform:*:*:*:*:*:*:*:*",
"IBM=Operational Decision Management":"cpe:2.3:a:ibm:operational_decision_manager:*:*:*:*:*:*:*:*",
"IBM=Operational Decision Manager":"cpe:2.3:a:ibm:operational_decision_manager:*:*:*:*:*:*:*:*",
"IBM=Operations Analytics Predictive Insights":"cpe:2.3:a:ibm:operations_analytics_predictive_insights:*:*:*:*:*:*:*:*",
"IBM=Person Communications":"cpe:2.3:a:ibm:person_communications:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics":"cpe:2.3:a:ibm:planning_analytics:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics ":"cpe:2.3:a:ibm:planning_analytics:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics Local":"cpe:2.3:a:ibm:planning_analytics:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics":"cpe:2.3:a:ibm:planning_analytics_local:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics Local":"cpe:2.3:a:ibm:planning_analytics_local:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics on Cloud Pak for Data":"cpe:2.3:a:ibm:planning_analytics_on_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Planning Analytics Workspace":"cpe:2.3:a:ibm:planning_analytics_workspace:*:*:*:*:*:*:*:*",
"IBM Corporation=Tivoli System Automation for Multiplatforms":"cpe:2.3:a:ibm:power_hardware_management_console:*:*:*:*:*:*:*:*",
"IBM=PowerSC":"cpe:2.3:a:ibm:powersc:*:*:*:*:*:*:*:*",
"IBM=PowerVM VIOS":"cpe:2.3:a:ibm:powervm_virtual_i\/o_server:*:*:*:*:*:*:*:*",
"IBM=PureApplication System":"cpe:2.3:a:ibm:pureapplication_system:*:*:*:*:*:*:*:*",
"IBM=PureData System for Analytics":"cpe:2.3:a:ibm:puredata_system_for_analytics:*:*:*:*:*:*:*:*",
"IBM=Qradar Advisor":"cpe:2.3:a:ibm:qradar_advisor:*:*:*:*:*:*:*:*",
"IBM=QRadar SIEM":"cpe:2.3:a:ibm:qradar_advisor_with_watson:*:*:*:*:*:*:*:*",
"IBM=Qradar Advisor":"cpe:2.3:a:ibm:qradar_advisor_with_watson:*:*:*:*:*:*:*:*",
"IBM=Qradar Advisor":"cpe:2.3:a:ibm:qradar_advisory:*:*:*:*:*:*:*:*",
"IBM=QRadar Data Synchronization App":"cpe:2.3:a:ibm:qradar_data_synchronization:*:*:*:*:*:*:*:*",
"IBM=QRadar Network Security":"cpe:2.3:a:ibm:qradar_network_security:*:*:*:*:*:*:*:*",
"IBM=Pulse for QRadar":"cpe:2.3:a:ibm:qradar_pulse:*:*:*:*:*:*:*:*",
"IBM=QRadar SIEM":"cpe:2.3:a:ibm:qradar_security_information_and_event_manager:*:*:*:*:*:*:*:*",
"IBM=QRadar Wincollect":"cpe:2.3:a:ibm:qradar_security_information_and_event_manager:*:*:*:*:*:*:*:*",
"IBM=Security QRadar SIEM":"cpe:2.3:a:ibm:qradar_security_information_and_event_manager:*:*:*:*:*:*:*:*",
"IBM=QRadar SIEM":"cpe:2.3:a:ibm:qradar_user_behavior_analytics:*:*:*:*:*:*:*:*",
"IBM=QRadar User Behavior Analytics":"cpe:2.3:a:ibm:qradar_user_behavior_analytics:*:*:*:*:*:*:*:*",
"IBM=QRadar WinCollect Agent":"cpe:2.3:a:ibm:qradar_wincollect:*:*:*:*:*:*:*:*",
"IBM=Rational ClearCase":"cpe:2.3:a:ibm:rational_clearcase:*:*:*:*:*:*:*:*",
"IBM=Rational Collaborative Lifecycle Management":"cpe:2.3:a:ibm:rational_collaborative_lifecycle_management:*:*:*:*:*:*:*:*",
"IBM=Rational DOORS":"cpe:2.3:a:ibm:rational_doors:*:*:*:*:*:*:*:*",
"IBM=Rational DOORS Next Generation":"cpe:2.3:a:ibm:rational_doors_next_generation:*:*:*:*:*:*:*:*",
"IBM=Rational DOORS Web Access":"cpe:2.3:a:ibm:rational_doors_web_access:*:*:*:*:*:*:*:*",
"IBM=Rational Engineering Lifecycle Manager":"cpe:2.3:a:ibm:rational_engineering_lifecycle_manager:*:*:*:*:*:*:*:*",
"IBM=Rational Publishing Engine":"cpe:2.3:a:ibm:rational_publishing_engine:*:*:*:*:*:*:*:*",
"IBM=Rational Quality Manager":"cpe:2.3:a:ibm:rational_quality_manager:*:*:*:*:*:*:*:*",
"IBM=Rational Team Concert":"cpe:2.3:a:ibm:rational_team_concert:*:*:*:*:*:*:*:*",
"IBM=Resilient OnPrem":"cpe:2.3:a:ibm:resilient:*:*:*:*:*:*:*:*",
"IBM=Resilient":"cpe:2.3:a:ibm:resilient_security_orchestration_automation_and_response:*:*:*:*:*:*:*:*",
"IBM=Resilient OnPrem":"cpe:2.3:a:ibm:resilient_security_orchestration_automation_and_response:*:*:*:*:*:*:*:*",
"IBM=Security":"cpe:2.3:a:ibm:resilient_security_orchestration_automation_and_response:*:*:*:*:*:*:*:*",
"IBM=Rhapsody Model Manager":"cpe:2.3:a:ibm:rhapsody_model_manager:*:*:*:*:*:*:*:*",
"IBM=Robotic Process Automation":"cpe:2.3:a:ibm:robotic_process_automation:*:*:*:*:*:*:*:*",
"IBM=Robotic Process Automation with Automation Anywhere":"cpe:2.3:a:ibm:robotic_process_automation_with_automation_anywhere:*:*:*:*:*:*:*:*",
"IBM=Runbook Automation":"cpe:2.3:a:ibm:runbook_automation:*:*:*:*:*:*:*:*",
"IBM=Safer Payments":"cpe:2.3:a:ibm:safer_payments:*:*:*:*:*:*:*:*",
"IBM=Security Access Manager":"cpe:2.3:a:ibm:security_access_manager:*:*:*:*:*:*:*:*",
"IBM=Security Access Manager Appliance":"cpe:2.3:a:ibm:security_access_manager:*:*:*:*:*:*:*:*",
"IBM=Security Identity Manager":"cpe:2.3:a:ibm:security_access_manager:*:*:*:*:*:*:*:*",
"IBM=Security Access Manager Appliance":"cpe:2.3:a:ibm:security_access_manager_appliance:*:*:*:*:*:*:*:*",
"IBM=Security Access Manager for Enterprise Single Sign-On":"cpe:2.3:a:ibm:security_access_manager_for_enterprise_single_sign-on:*:*:*:*:*:*:*:*",
"IBM=Security Directory Server":"cpe:2.3:a:ibm:security_directory_integrator:*:*:*:*:*:*:*:*",
"IBM=Security Directory Integrator":"cpe:2.3:a:ibm:security_directory_server:*:*:*:*:*:*:*:*",
"IBM=Security Directory Server":"cpe:2.3:a:ibm:security_directory_server:*:*:*:*:*:*:*:*",
"IBM=Security Directory Suite":"cpe:2.3:a:ibm:security_directory_suite_va:*:*:*:*:*:*:*:*",
"IBM=Security Directory Suite VA":"cpe:2.3:a:ibm:security_directory_suite_va:*:*:*:*:*:*:*:*",
"IBM=Security Guardium":"cpe:2.3:a:ibm:security_guardium:*:*:*:*:*:*:*:*",
"IBM=Security Guardium Big Data Intelligence":"cpe:2.3:a:ibm:security_guardium_big_data_intelligence:*:*:*:*:*:*:*:*",
"IBM=Security Guardium Data Encryption":"cpe:2.3:a:ibm:security_guardium_data_encrpytion:*:*:*:*:*:*:*:*",
"IBM=Security Guardium":"cpe:2.3:a:ibm:security_guardium_database_activity_monitor:*:*:*:*:*:*:*:*",
"IBM=Security Guardium Insights":"cpe:2.3:a:ibm:security_guardium_insights:*:*:*:*:*:*:*:*",
"IBM=Security Identity Governance and Intelligence":"cpe:2.3:a:ibm:security_identity_governance_and_intelligence:*:*:*:*:*:*:*:*",
"IBM=Security Identity Governance and Intelligence":"cpe:2.3:a:ibm:security_identity_manager:*:*:*:*:*:*:*:*",
"IBM=Security Identity Manager":"cpe:2.3:a:ibm:security_identity_manager:*:*:*:*:*:*:*:*",
"IBM=Security Identity Manager Adapters":"cpe:2.3:a:ibm:security_identity_manager_adapter:*:*:*:*:*:*:*:*",
"IBM=Security Identity Manager":"cpe:2.3:a:ibm:security_identity_manager_virtual_appliance:*:*:*:*:*:*:*:*",
"IBM=Security Identity Manager Virtual Appliance":"cpe:2.3:a:ibm:security_identity_manager_virtual_appliance:*:*:*:*:*:*:*:*",
"IBM=Security Information Queue":"cpe:2.3:a:ibm:security_information_queue:*:*:*:*:*:*:*:*",
"IBM=Security Key Lifecycle Manager":"cpe:2.3:a:ibm:security_key_lifecycle_manager:*:*:*:*:*:*:*:*",
"IBM=Security Privileged Identity Manager":"cpe:2.3:a:ibm:security_privileged_identity_manager:*:*:*:*:*:*:*:*",
"IBM=QRadar Analyst Workflow":"cpe:2.3:a:ibm:security_qradar_analyst_workflow:*:*:*:*:*:*:*:*",
"IBM=Security Secret Server":"cpe:2.3:a:ibm:security_secret_server:*:*:*:*:*:*:*:*",
"IBM=Security SiteProtector System":"cpe:2.3:a:ibm:security_siteprotector_system:*:*:*:*:*:*:*:*",
"IBM=Security Trusteer Pinpoint Detect":"cpe:2.3:a:ibm:security_trusteer_pinpoint_detect:*:*:*:*:*:*:*:*",
"IBM=Security Verify Privilege Vault":"cpe:2.3:a:ibm:security_verify:*:*:*:*:*:*:*:*",
"IBM=Security Verify Access":"cpe:2.3:a:ibm:security_verify_access:*:*:*:*:*:*:*:*",
"IBM=Security Verify Access Docker":"cpe:2.3:a:ibm:security_verify_access:*:*:*:*:*:*:*:*",
"IBM=Security Verify Access":"cpe:2.3:a:ibm:security_verify_access_oidc_provider:*:*:*:*:*:*:*:*",
"IBM=Security Verify Bridge":"cpe:2.3:a:ibm:security_verify_bridge:*:*:*:*:*:*:*:*",
"IBM=Security Verify Directory":"cpe:2.3:a:ibm:security_verify_directory:*:*:*:*:*:*:*:*",
"IBM=Security Verify Governance":"cpe:2.3:a:ibm:security_verify_governance:*:*:*:*:*:*:*:*",
"IBM=Security Verify Governance, Identity Manager":"cpe:2.3:a:ibm:security_verify_governance:*:*:*:*:*:*:*:*",
"IBM=Security Verify Information Queue":"cpe:2.3:a:ibm:security_verify_information_queue:*:*:*:*:*:*:*:*",
"IBM=Security Verify Privilege Manager":"cpe:2.3:a:ibm:security_verify_privilege_manager:*:*:*:*:*:*:*:*",
"IBM=Semeru Runtime":"cpe:2.3:a:ibm:semeru_runtime:*:*:*:*:*:*:*:*",
"SevOne=Network Management System":"cpe:2.3:a:ibm:sevone_network_performance_management:*:*:*:*:*:*:*:*",
"IBM=SmartCloud Analytics":"cpe:2.3:a:ibm:smartcloud_analytics_log_analysis:*:*:*:*:*:*:*:*",
"IBM=SOAR QRadar Plugin App":"cpe:2.3:a:ibm:soar_qradar_plugin_app:*:*:*:*:*:*:*:*",
"IBM=Spectrum Control Standard Edition":"cpe:2.3:a:ibm:spectrum_control:*:*:*:*:*:*:*:*",
"IBM=Spectrum Copy Data Management":"cpe:2.3:a:ibm:spectrum_copy_data_management:*:*:*:*:*:*:*:*",
"IBM Corporation=Spectrum LSF":"cpe:2.3:a:ibm:spectrum_lsf:*:*:*:*:*:*:*:*",
"IBM=Spectrum LSF":"cpe:2.3:a:ibm:spectrum_lsf:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect":"cpe:2.3:a:ibm:spectrum_protect:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect Server":"cpe:2.3:a:ibm:spectrum_protect:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect Backup-Archive Client":"cpe:2.3:a:ibm:spectrum_protect_backup-archive_client:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect Client":"cpe:2.3:a:ibm:spectrum_protect_client:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect":"cpe:2.3:a:ibm:spectrum_protect_operations_center:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect Operations Center":"cpe:2.3:a:ibm:spectrum_protect_operations_center:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect Plus":"cpe:2.3:a:ibm:spectrum_protect_plus:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect":"cpe:2.3:a:ibm:spectrum_protect_server:*:*:*:*:*:*:*:*",
"IBM=Spectrum Scale":"cpe:2.3:a:ibm:spectrum_scale:*:*:*:*:*:*:*:*",
"IBM=Spectrum Scale Container Native Storage Access":"cpe:2.3:a:ibm:spectrum_scale_container_native_storage_access:*:*:*:*:*:*:*:*",
"IBM=Storage Scale Container Native Storage Access":"cpe:2.3:a:ibm:spectrum_scale_container_native_storage_access:*:*:*:*:*:*:*:*",
"IBM=Spectrum Symphony":"cpe:2.3:a:ibm:spectrum_symphony:*:*:*:*:*:*:*:*",
"IBM=Spectrum Virtualize":"cpe:2.3:a:ibm:spectrum_virtualize:*:*:*:*:*:*:*:*",
"IBM=SPSS Modeler":"cpe:2.3:a:ibm:spss_modeler:*:*:*:*:*:*:*:*",
"IBM=Sterling B2B Integrator":"cpe:2.3:a:ibm:sterling_b2b_integrator:*:*:*:*:*:*:*:*",
"IBM=Sterling File Gateway":"cpe:2.3:a:ibm:sterling_b2b_integrator:*:*:*:*:*:*:*:*",
"IBM=Sterling External Authentication Server":"cpe:2.3:a:ibm:sterling_external_authentication_server:*:*:*:*:*:*:*:*",
"IBM=Sterling Secure Proxy":"cpe:2.3:a:ibm:sterling_external_authentication_server:*:*:*:*:*:*:*:*",
"IBM=Sterling File Gateway":"cpe:2.3:a:ibm:sterling_file_gateway:*:*:*:*:*:*:*:*",
"IBM=Sterling Order Management":"cpe:2.3:a:ibm:sterling_order_management:*:*:*:*:*:*:*:*",
"IBM=Sterling Partner Engagement Manager":"cpe:2.3:a:ibm:sterling_partner_engagement_manager:*:*:*:*:*:*:*:*",
"IBM=Secure Proxy":"cpe:2.3:a:ibm:sterling_secure_proxy:*:*:*:*:*:*:*:*",
"IBM=Storage Ceph":"cpe:2.3:a:ibm:storage_ceph:*:*:*:*:*:*:*:*",
"IBM=Spectrum Copy Data Management":"cpe:2.3:a:ibm:storage_copy_data_management:*:*:*:*:*:*:*:*",
"IBM=Storage Defender - Data Protect":"cpe:2.3:a:ibm:storage_defender_data_protect:*:*:*:*:*:*:*:*",
"IBM=Storage Defender - Resiliency Service":"cpe:2.3:a:ibm:storage_defender_resiliency_service:*:*:*:*:*:*:*:*",
"IBM=Storage Fusion HCI":"cpe:2.3:a:ibm:storage_fusion_hci:*:*:*:*:*:*:*:*",
"IBM=Storage Protect Plus Server":"cpe:2.3:a:ibm:storage_protect_plus:*:*:*:*:*:*:*:*",
"IBM=Storage Virtualize":"cpe:2.3:a:ibm:storage_virtualize:*:*:*:*:*:*:*:*",
"IBM=StoredIQ":"cpe:2.3:a:ibm:storediq:*:*:*:*:*:*:*:*",
"IBM=Storwize V7000 Unified (2073)":"cpe:2.3:a:ibm:storwize_unified_v7000_software:*:*:*:*:*:*:*:*",
"IBM=Tealeaf Customer Experience":"cpe:2.3:a:ibm:tealeaf_customer_experience:*:*:*:*:*:*:*:*",
"IBM=Tivoli Application Dependency Discovery Manager":"cpe:2.3:a:ibm:tivoli_application_dependency_discovery_manager:*:*:*:*:*:*:*:*",
"IBM=Tivoli Business Service Manager":"cpe:2.3:a:ibm:tivoli_business_service_manager:*:*:*:*:*:*:*:*",
"IBM Corporation=Tivoli Federated Identity Manager":"cpe:2.3:a:ibm:tivoli_federated_identity_manager:*:*:*:*:*:*:*:*",
"IBM=Tivoli Federated Identity Manager":"cpe:2.3:a:ibm:tivoli_federated_identity_manager:*:*:*:*:*:*:*:*",
"IBM=Tivoli Monitoring":"cpe:2.3:a:ibm:tivoli_monitoring:*:*:*:*:*:*:*:*",
"IBM=Tivoli Monitoring V6":"cpe:2.3:a:ibm:tivoli_monitoring:*:*:*:*:*:*:*:*",
"IBM=Tivoli Netcool Impact":"cpe:2.3:a:ibm:tivoli_netcool\/impact:*:*:*:*:*:*:*:*",
"IBM=Tivoli Netcool/Impact":"cpe:2.3:a:ibm:tivoli_netcool\/impact:*:*:*:*:*:*:*:*",
"IBM=Tivoli Netcool/OMNIbus":"cpe:2.3:a:ibm:tivoli_netcool\/omnibus:*:*:*:*:*:*:*:*",
"IBM=Tivoli Netcool/OMNIbus":"cpe:2.3:a:ibm:tivoli_netcool\/omnibus_gui:*:*:*:*:*:*:*:*",
"IBM=Tivoli Netcool/OMNIbus":"cpe:2.3:a:ibm:tivoli_netcool\/omnibus_webgui:*:*:*:*:*:*:*:*",
"IBM=Tivoli Netcool/OMNIbus_GUI":"cpe:2.3:a:ibm:tivoli_netcool\/omnibus_webgui:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect":"cpe:2.3:a:ibm:tivoli_storage_manager:*:*:*:*:*:*:*:*",
"IBM=Spectrum Protect for Virtual Environments":"cpe:2.3:a:ibm:tivoli_storage_manager:*:*:*:*:*:*:*:*",
"IBM=Tivoli Workload Scheduler":"cpe:2.3:a:ibm:tivoli_workload_scheduler:*:*:*:*:*:*:*:*",
"IBM=Workload Scheduler":"cpe:2.3:a:ibm:tivoli_workload_scheduler:*:*:*:*:*:*:*:*",
"IBM=Workload Scheduler Distributed":"cpe:2.3:a:ibm:tivoli_workload_scheduler:*:*:*:*:*:*:*:*",
"IBM=Transformation Extender Advanced":"cpe:2.3:a:ibm:transformation_extender_advanced:*:*:*:*:*:*:*:*",
"IBM Corporation=TRIRIGA Application Platform":"cpe:2.3:a:ibm:tririga_application_platform:*:*:*:*:*:*:*:*",
"IBM=TRIRIGA Application Platform":"cpe:2.3:a:ibm:tririga_application_platform:*:*:*:*:*:*:*:*",
"IBM=UrbanCode Deploy":"cpe:2.3:a:ibm:urbancode_deploy:*:*:*:*:*:*:*:*",
"IBM=UrbanCode Deploy (UCD)":"cpe:2.3:a:ibm:urbancode_deploy:*:*:*:*:*:*:*:*",
"IBM=Verify Gateway (IVG)":"cpe:2.3:a:ibm:verify_gateway:*:*:*:*:*:*:*:*",
"IBM=Watson Assistant for IBM Cloud Pak for Data":"cpe:2.3:a:ibm:watson_assistant_for_ibm_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Watson Knowledge Catalog on Cloud Pak for Data":"cpe:2.3:a:ibm:watson_knowledge_catalog_on_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Watson Machine Learning on Cloud Pak for Data":"cpe:2.3:a:ibm:watson_machine_learning_on_cloud_pak_for_data:*:*:*:*:*:*:*:*",
"IBM=Watson Studio Local":"cpe:2.3:a:ibm:watson_studio_local:*:*:*:*:*:*:*:*",
"IBM=Watson Studio Local":"cpe:2.3:a:ibm:watston_studio_local:*:*:*:*:*:*:*:*",
"IBM Corporation=IBM WebSphere Application Server":"cpe:2.3:a:ibm:websphere_application_server:*:*:*:*:*:*:*:*",
"IBM Corporation=WebSphere Application Server":"cpe:2.3:a:ibm:websphere_application_server:*:*:*:*:*:*:*:*",
"IBM=IBM WebSphere Application Server ":"cpe:2.3:a:ibm:websphere_application_server:*:*:*:*:*:*:*:*",
"IBM=WebSphere Application Server":"cpe:2.3:a:ibm:websphere_application_server:*:*:*:*:*:*:*:*",
"IBM=WebSphere Application Server Liberty":"cpe:2.3:a:ibm:websphere_application_server_liberty:*:*:*:*:*:*:*:*",
"IBM=WebSphere Application Server ND":"cpe:2.3:a:ibm:websphere_application_server_nd:*:*:*:*:*:*:*:*",
" IBM=WebSphere Automation for IBM Cloud Pak for Watson AIOps":"cpe:2.3:a:ibm:websphere_automation_for_ibm_cloud_pak_for_watson_aiops:*:*:*:*:*:*:*:*",
"IBM=WebSphere Automation for Cloud Pak for Watson AIOps":"cpe:2.3:a:ibm:websphere_automation_for_ibm_cloud_pak_for_watson_aiops:*:*:*:*:*:*:*:*",
"IBM Corporation=WebSphere Commerce Enterprise":"cpe:2.3:a:ibm:websphere_commerce:*:*:*:*:*:*:*:*",
"IBM=WebSphere Commerce":"cpe:2.3:a:ibm:websphere_commerce:*:*:*:*:*:*:*:*",
"IBM=WebSphere Commerce Enterprise":"cpe:2.3:a:ibm:websphere_commerce:*:*:*:*:*:*:*:*",
"IBM=WebSphere eXtreme Scale":"cpe:2.3:a:ibm:websphere_extreme_scale:*:*:*:*:*:*:*:*",
"IBM Corporation=WebSphere MQ":"cpe:2.3:a:ibm:websphere_mq:*:*:*:*:*:*:*:*",
"IBM=MQ":"cpe:2.3:a:ibm:websphere_mq:*:*:*:*:*:*:*:*",
"IBM=WebSphere MQ":"cpe:2.3:a:ibm:websphere_mq:*:*:*:*:*:*:*:*",
"IBM=WebSphere MQ":"cpe:2.3:a:ibm:websphere_mq_internet_pass-thru:*:*:*:*:*:*:*:*",
"IBM Corporation=WebSphere Portal":"cpe:2.3:a:ibm:websphere_portal:*:*:*:*:*:*:*:*",
"IBM=WebSphere Portal":"cpe:2.3:a:ibm:websphere_portal:*:*:*:*:*:*:*:*",
"IBM=WebSphere Service Registry and Repository":"cpe:2.3:a:ibm:websphere_service_registry_and_repository:*:*:*:*:*:*:*:*",
"IBM=Workload Automation":"cpe:2.3:a:ibm:workload_automation:*:*:*:*:*:*:*:*",
"IBM=Workload Scheduler":"cpe:2.3:a:ibm:workload_scheduler:*:*:*:*:*:*:*:*",
"IBOS=OA":"cpe:2.3:a:ibos:ibos:*:*:*:*:*:*:*:*",
"iboss=Secure Web Gateway":"cpe:2.3:a:iboss:secure_web_gateway:*:*:*:*:*:*:*:*",
"iCatch Inc.=DVR firmware":"cpe:2.3:a:icatchinc:dvr_interface:*:*:*:*:*:*:*:*",
"icecoder=icecoder/icecoder":"cpe:2.3:a:icecoder:icecoder:*:*:*:*:*:*:*:*",
"IceHrm=IceHrm":"cpe:2.3:a:icehrm:icehrm:*:*:*:*:*:*:*:*",
"Iceni=Argus":"cpe:2.3:a:iceni:argus:*:*:*:*:*:*:*:*",
"Iceni=Infix":"cpe:2.3:a:iceni:infix:*:*:*:*:*:*:*:*",
"Icinga=icingaweb2":"cpe:2.3:a:icinga:icinga:*:*:*:*:*:*:*:*",
"Icinga=icingaweb2-module-director":"cpe:2.3:a:icinga:icinga:*:*:*:*:*:*:*:*",
"Icinga=icingaweb2":"cpe:2.3:a:icinga:icinga_web_2:*:*:*:*:*:*:*:*",
"Icinga=icingaweb2-module-jira":"cpe:2.3:a:icinga:icinga_web_jira_integration:*:*:*:*:*:*:*:*",
"Icinga=icingaweb2-module-incubator":"cpe:2.3:a:icinga:icingaweb2-module-incubator:*:*:*:*:*:*:*:*",
"ICONICS and Mitsubishi Electric Corporation=GENESIS64":"cpe:2.3:a:iconics:genesis64:*:*:*:*:*:*:*:*",
"ICONICS=SCADA software Iconics Suite":"cpe:2.3:a:iconics:iconics_suite:*:*:*:*:*:*:*:*",
"ICSSolution=ICS Business Manager":"cpe:2.3:a:icssolution:ics_business_manager:*:*:*:*:*:*:*:*",
"SourceCodester=ICT Laboratory Management System":"cpe:2.3:a:ict_laboratory_management_system_project:ict_laboratory_management_system:*:*:*:*:*:*:*:*",
"IDAttend Pty Ltd=IDWeb":"cpe:2.3:a:idattend:idweb:*:*:*:*:*:*:*:*",
"  ekino Bilgi Teknolojileri=  ekino Bilgi Teknolojileri":"cpe:2.3:a:identity_and_directory_management_system_project:identity_and_directory_management_system:*:*:*:*:*:*:*:*",
"iDisplay=PlatPlay DS":"cpe:2.3:a:idisplay:platplay_ds:*:*:*:*:*:*:*:*",
"IDMSistemas=Sinergia, Sinergia 2.0, and Sinergia Corporativo":"cpe:2.3:a:idmsistemas:sinergia:*:*:*:*:*:*:*:*",
"A.T.WORKS, Inc.=iDoors Reader":"cpe:2.3:a:idoors:idoors_reader:*:*:*:*:*:*:*:*",
"ID Software Project and Consultancy Services=B2B Customer Ordering System":"cpe:2.3:a:idyazilim:b2b_dealer_order_system:*:*:*:*:*:*:*:*",
"Internet Engineering Task Force=Internet Key Exchange Protocol":"cpe:2.3:a:ietf:internet_key_exchange:*:*:*:*:*:*:*:*",
"ifmeorg=ifme":"cpe:2.3:a:if-me:ifme:*:*:*:*:*:*:*:*",
"igniterealtime=Openfire":"cpe:2.3:a:igniterealtime:openfire:*:*:*:*:*:*:*:*",
"Intelligent global technology Ltd=igt+":"cpe:2.3:a:igt\+_project:igt\+:*:*:*:*:*:*:*:*",
"spiral-project=ihatemoney":"cpe:2.3:a:ihatemoney:i_hate_money:*:*:*:*:*:*:*:*",
"IKaIKa Software Co.,LTD.=IkaIka RSS Reader":"cpe:2.3:a:ikalka_rss_reader_project:ikalka_rss_reader:*:*:*:*:*:*:*:*",
"  zmir Katip   elebi University=University Information Management System":"cpe:2.3:a:ikcu:university_information_management_system:*:*:*:*:*:*:*:*",
"ikus060=ikus060/minarca":"cpe:2.3:a:ikus-soft:minarca:*:*:*:*:*:*:*:*",
"ikus060=ikus060/rdiffweb":"cpe:2.3:a:ikus-soft:rdiffweb:*:*:*:*:*:*:*:*",
"Ilias=Ilias":"cpe:2.3:a:ilias:ilias:*:*:*:*:*:*:*:*",
"fornyhucker=Simple Like Page Plugin":"cpe:2.3:a:illia:simple_like_page:*:*:*:*:*:*:*:*",
"Illumio=Core PCE":"cpe:2.3:a:illumio:core_policy_compute_engine:*:*:*:*:*:*:*:*",
"xiaozhuai=imageinfo":"cpe:2.3:a:imageinfo_project:imageinfo:*:*:*:*:*:*:*:*",
"ImageMagick=ImageMagick":"cpe:2.3:a:imagemagick:imagemagick:*:*:*:*:*:*:*:*",
"aerouk=imageserve":"cpe:2.3:a:imageserve_project:imageserve:*:*:*:*:*:*:*:*",
"IMGTech Co,Ltd=ZInsVX.dll ActiveX Control":"cpe:2.3:a:imgtech:zoneplayer:*:*:*:*:*:*:*:*",
"Imou=Imou Life app":"cpe:2.3:a:imoulife:imou_life:*:*:*:*:*:*:*:*",
"Imperva=SecureSphere":"cpe:2.3:a:imperva:securesphere:*:*:*:*:*:*:*:*",
"unspecified=Imprint CMS":"cpe:2.3:a:imprint_cms_project:imprint_cms:*:*:*:*:*:*:*:*",
"Kashipara Group=Student Information System":"cpe:2.3:a:imsurajghosh:student_information_system:*:*:*:*:*:*:*:*",
"in-toto=in-toto-golang":"cpe:2.3:a:in-toto:in-toto-golang:*:*:*:*:*:*:*:*",
"in-toto=in-toto":"cpe:2.3:a:in-toto_project:in-toto:*:*:*:*:*:*:*:*",
"Inavitas=Inavitas Solar Log":"cpe:2.3:a:inavitas:solar_log:*:*:*:*:*:*:*:*",
"Indeed Engineering=util":"cpe:2.3:a:indeed:util:*:*:*:*:*:*:*:*",
"Inductive Automation=Ignition":"cpe:2.3:a:inductiveautomation:ignition:*:*:*:*:*:*:*:*",
"INEX=IPX-Manager":"cpe:2.3:a:inex:ixp_manager:*:*:*:*:*:*:*:*",
"infinitietech=taskhub":"cpe:2.3:a:infinitietech:taskhub:*:*:*:*:*:*:*:*",
"InfoDoc=Document On-line Submission and Approval System":"cpe:2.3:a:infodoc:document_on-line_submission_and_approval_system:*:*:*:*:*:*:*:*",
"Infodrom Software=E-Invoice Approval System":"cpe:2.3:a:infodrom:e-invoice_approval_system:*:*:*:*:*:*:*:*",
"infogami=infogami":"cpe:2.3:a:infogami:infogami:*:*:*:*:*:*:*:*",
"Infoline=Project Management System":"cpe:2.3:a:infoline-tr:project_management_system:*:*:*:*:*:*:*:*",
"INFORMATION-TECHNOLOGY PROMOTION AGENCY, JAPAN (IPA)=Security guide for website operators":"cpe:2.3:a:information-technology_promotion_agency:introduction_to_safe_website_operation:*:*:*:*:*:*:*:*",
"Infosoftbd=Clcknshop":"cpe:2.3:a:infosoftbd:clcknshop:*:*:*:*:*:*:*:*",
"Infraware=ML Report":"cpe:2.3:a:infraware-global:ml_report:*:*:*:*:*:*:*:*",
"rawiroaisen=ini-parser":"cpe:2.3:a:ini-parser_project:ini-parser:*:*:*:*:*:*:*:*",
"Inist-CNRS=ezmaster":"cpe:2.3:a:inist:ezmaster:*:*:*:*:*:*:*:*",
"Takuya Matsuyama=Inkdrop":"cpe:2.3:a:inkdrop:inkdrop:*:*:*:*:*:*:*:*",
"Inkscape=Inkscape":"cpe:2.3:a:inkscape:inkscape:*:*:*:*:*:*:*:*",
"jamesmartin=Inline SVG":"cpe:2.3:a:inline_svg_project:inline_svg:*:*:*:*:*:*:*:*",
"Inmarsat=AmosConnect":"cpe:2.3:a:inmarsat:amosconnect:*:*:*:*:*:*:*:*",
"Inmarsat=AmosConnect":"cpe:2.3:a:inmarsat:amosconnect_8:*:*:*:*:*:*:*:*",
"Innosa=Probbys":"cpe:2.3:a:innosa_probbys_project:innosa_probbys:*:*:*:*:*:*:*:*",
"Inoguard=ExECM CoreB2B":"cpe:2.3:a:inoguard:execm_coreb2b:*:*:*:*:*:*:*:*",
"Inohom=Home Manager Gateway":"cpe:2.3:a:inohom:home_manager_gateway:*:*:*:*:*:*:*:*",
"InPage=InPage reader":"cpe:2.3:a:inpage:inpage:*:*:*:*:*:*:*:*",
"ProMIS Process Co.=inSCADA":"cpe:2.3:a:inscada_project:inscada:*:*:*:*:*:*:*:*",
"instantsoft=instantsoft/icms2":"cpe:2.3:a:instantcms:icms2:*:*:*:*:*:*:*:*",
"instantsoft=instantsoft/icms2":"cpe:2.3:a:instantcms:instantcms:*:*:*:*:*:*:*:*",
"InSTEDD=Nuntium":"cpe:2.3:a:instedd:nuntium:*:*:*:*:*:*:*:*",
"InSTEDD=Pollit":"cpe:2.3:a:instedd:pollit:*:*:*:*:*:*:*:*",
"Integration Objects=OPC UA Server Toolkit":"cpe:2.3:a:integrationobjects:opc_ua_server_toolkit:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) CSME, Server Platform Services, Trusted Execution Engine and Intel(R) Active Management Technology":"cpe:2.3:a:intel:capability_licensing_service:*:*:*:*:*:*:*:*",
"Intel=Intel(R) Chipset Device Software INF Utility":"cpe:2.3:a:intel:chipset_inf_utility:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Computing Improvement Program":"cpe:2.3:a:intel:computing_improvement_program:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Data Center Manager SDK":"cpe:2.3:a:intel:data_center_manager:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Data Migration Software":"cpe:2.3:a:intel:data_migration_software:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Distribution for Python 2018":"cpe:2.3:a:intel:distribution_for_python:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Driver & Support Assistant":"cpe:2.3:a:intel:driver\&support_assistant:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Driver and Support Assistant":"cpe:2.3:a:intel:driver_\&_support_assistant:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Driver & Support Assistant":"cpe:2.3:a:intel:driver_\&_support_assistant:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Extreme Tuning Utility":"cpe:2.3:a:intel:extreme_tuning_utility:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Graphics Driver":"cpe:2.3:a:intel:graphics_driver:*:*:*:*:*:*:*:*",
"Intel Corporation=Mobile, Desktop, Server, Workstation, and Embedded processors based on Intel Core and Atom Processors using an affected driver.":"cpe:2.3:a:intel:graphics_driver:*:*:*:*:*:*:*:*",
"Intel=Intel(R) Graphics Driver":"cpe:2.3:a:intel:graphics_driver:*:*:*:*:*:*:*:*",
"Intel=Intel(R) Graphics Drivers":"cpe:2.3:a:intel:graphics_driver:*:*:*:*:*:*:*:*",
"Intel=Intel Hardware Accelerated Execution Manager":"cpe:2.3:a:intel:hardware_accelerated_execution_manager:*:*:*:*:*:*:*:*",
"Intel Corporation=Integrated Performance Primitives Cryptography Library":"cpe:2.3:a:intel:integrated_performance_primitives_cryptography:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel IoT Developers Kit":"cpe:2.3:a:intel:iot_developers_kit:*:*:*:*:*:*:*:*",
"Intel=lldptool":"cpe:2.3:a:intel:lldptool:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Matrix Storage Manager":"cpe:2.3:a:intel:matrix_storage_manager:*:*:*:*:*:*:*:*",
"Intel Corporation=Online Connect Access":"cpe:2.3:a:intel:online_connect_access:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Processor Diagnostic Tool":"cpe:2.3:a:intel:processor_diagnostic_tool:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) Processor Diagnostic Tool Advisory":"cpe:2.3:a:intel:processor_diagnostic_tool:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Quartus II":"cpe:2.3:a:intel:quartus_ii:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Quartus II Programmer and Tools":"cpe:2.3:a:intel:quartus_ii_programmer_and_tools:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Quartus Prime":"cpe:2.3:a:intel:quartus_prime:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Quartus Prime Programmer and Tools":"cpe:2.3:a:intel:quartus_prime_programmer_and_tools:*:*:*:*:*:*:*:*",
"Intel=Intel Quick Assist Technology":"cpe:2.3:a:intel:quickassist_technology_engine:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel QuickAssist Technology for Linux":"cpe:2.3:a:intel:quickassist_technology_for_linux:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Rapid Store Technology":"cpe:2.3:a:intel:rapid_storage_technology:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Ready Mode Technology":"cpe:2.3:a:intel:ready_mode_technology:*:*:*:*:*:*:*:*",
"Intel Corporation=INTEL-SA-00086 Detection Tool":"cpe:2.3:a:intel:sa-00086_detection_tool:*:*:*:*:*:*:*:*",
"Intel Corporation=Saffron MemoryBase":"cpe:2.3:a:intel:saffron_memorybase:*:*:*:*:*:*:*:*",
"Intel Corporation=Software Guard Extensions Platform Software Component":"cpe:2.3:a:intel:software_guard_extensions_platform_software_component:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Solid State Drive Toolbox":"cpe:2.3:a:intel:solid_state_drive_toolbox:*:*:*:*:*:*:*:*",
"Intel Corporation=Solid State Drive Toolbox":"cpe:2.3:a:intel:solid_state_drive_toolbox:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel System Defense Utility":"cpe:2.3:a:intel:system_defense_utility:*:*:*:*:*:*:*:*",
"Intel Corporation=Unite App":"cpe:2.3:a:intel:unite:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) USB 3.0 Creator Utility":"cpe:2.3:a:intel:usb_3.0_creator_utility:*:*:*:*:*:*:*:*",
"IntellectualSites=FastAsyncWorldEdit":"cpe:2.3:a:intellectualsites:fastasyncworldedit:*:*:*:*:*:*:*:*",
"certtools=IntelMQ Manager":"cpe:2.3:a:intelmq_manager_project:intelmq_manager:*:*:*:*:*:*:*:*",
"cosmos=cosmos-sdk":"cpe:2.3:a:interchain:cosmos_sdk:*:*:*:*:*:*:*:*",
"Intergard=SGS":"cpe:2.3:a:intergard:smartgard_silver_with_matrix_keyboard:*:*:*:*:*:*:*:*",
"irrdnet=irrd":"cpe:2.3:a:internet_routing_registry_daemon_project:internet_routing_registry_daemon:*:*:*:*:*:*:*:*",
"SourceCodester=Interview Management System":"cpe:2.3:a:interview_management_system_project:interview_management_system:*:*:*:*:*:*:*:*",
"intranda=goobi-viewer-core":"cpe:2.3:a:intranda:goobi_viewer_core:*:*:*:*:*:*:*:*",
"Invenio=invenio-app":"cpe:2.3:a:inveniosoftware:invenio-app:*:*:*:*:*:*:*:*",
"inveniosoftware=invenio-drafts-resources":"cpe:2.3:a:inveniosoftware:invenio-drafts-resources:*:*:*:*:*:*:*:*",
"Invenio=invenio-records":"cpe:2.3:a:inveniosoftware:invenio-records:*:*:*:*:*:*:*:*",
"SourceCodester=Free and Open Source Inventory Management System":"cpe:2.3:a:inventory_management_system_project:inventory_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Inventory Management System":"cpe:2.3:a:inventory_management_system_project:inventory_management_system:*:*:*:*:*:*:*:*",
"inventree=inventree/inventree":"cpe:2.3:a:inventree_project:inventree:*:*:*:*:*:*:*:*",
"invernyx=smartcars-3-bugs":"cpe:2.3:a:invernyx:smartcars_3:*:*:*:*:*:*:*:*",
"Kaspersky Lab=Invision Power Board":"cpe:2.3:a:invisioncommunity:invision_power_board:*:*:*:*:*:*:*:*",
"invoiceninja=invoiceninja/invoiceninja":"cpe:2.3:a:invoiceninja:invoice_ninja:*:*:*:*:*:*:*:*",
"iTop=VPN":"cpe:2.3:a:iobit:itop_vpn:*:*:*:*:*:*:*:*",
"IObit=IObit Malware Fighter":"cpe:2.3:a:iobit:malware_fighter:*:*:*:*:*:*:*:*",
"IObit=Malware Fighter":"cpe:2.3:a:iobit:malware_fighter:*:*:*:*:*:*:*:*",
"input-output-hk=hydra":"cpe:2.3:a:iohk:hydra:*:*:*:*:*:*:*:*",
"INFORMATION-TECHNOLOGY PROMOTION AGENCY, JAPAN (IPA)=Hands-on Vulnerability Learning Tool AppGoat for Web Application":"cpe:2.3:a:ipa:appgoat:*:*:*:*:*:*:*:*",
"INFORMATION-TECHNOLOGY PROMOTION AGENCY, JAPAN (IPA)=Installer of CASL II simulator(self-extract format)":"cpe:2.3:a:ipa:casl_ii_simulator:*:*:*:*:*:*:*:*",
"INFORMATION-TECHNOLOGY PROMOTION AGENCY, JAPAN (IPA)=Empirical Project Monitor - eXtended":"cpe:2.3:a:ipa:empirical_project_monitor_-_extended:*:*:*:*:*:*:*:*",
"INFORMATION-TECHNOLOGY PROMOTION AGENCY, JAPAN (IPA)=Source code security studying tool iCodeChecker":"cpe:2.3:a:ipa:icodechecker:*:*:*:*:*:*:*:*",
"INFORMATION-TECHNOLOGY PROMOTION AGENCY, JAPAN (IPA)=STAMP Workbench installer":"cpe:2.3:a:ipa:stamp_workbench:*:*:*:*:*:*:*:*",
"Ipack Automation Systems=Ipack SCADA Software":"cpe:2.3:a:ipack:scada_automation:*:*:*:*:*:*:*:*",
"yaBobJonez=iPear":"cpe:2.3:a:ipear_project:ipear:*:*:*:*:*:*:*:*",
"Ipekyolu Software=Auto Damage Tracking Software":"cpe:2.3:a:ipekyolunet:software_auto_damage_tracking_software:*:*:*:*:*:*:*:*",
"IProom=MMC+ Server":"cpe:2.3:a:iproom:mmc\+:*:*:*:*:*:*:*:*",
"ipti=br.tag":"cpe:2.3:a:ipti:tag:*:*:*:*:*:*:*:*",
"ipython=ipython":"cpe:2.3:a:ipython:ipython:*:*:*:*:*:*:*:*",
"Irfanview=Irfanview":"cpe:2.3:a:irfanview:irfanview:*:*:*:*:*:*:*:*",
"arasatasaygin=is.js":"cpe:2.3:a:is.js_project:is.js:*:*:*:*:*:*:*:*",
"ISC=BIND 9":"cpe:2.3:a:isc:bind:*:*:*:*:*:*:*:*",
"ISC=Kea DHCP":"cpe:2.3:a:isc:kea:*:*:*:*:*:*:*:*",
"Issabel=PBX":"cpe:2.3:a:issabel:pbx:*:*:*:*:*:*:*:*",
"istio.io=istio/envoy":"cpe:2.3:a:istio:istio:*:*:*:*:*:*:*:*",
"istio=istio":"cpe:2.3:a:istio:istio:*:*:*:*:*:*:*:*",
"Gen Technology=Four Mountain Torrent Disaster Prevention and Control of Monitoring and Early Warning System":"cpe:2.3:a:istrong:four_mountain_torrent_disaster_prevention\,_control_monitoring_and_early_warning_system:*:*:*:*:*:*:*:*",
"Suncreate=Mountain Flood Disaster Prevention Monitoring and Early Warning System":"cpe:2.3:a:istrong:mountain_flood_disaster_prevention_monitoring_and_early_warning_system:*:*:*:*:*:*:*:*",
"it-novum=it-novum/openitcockpit":"cpe:2.3:a:it-novum:openitcockpit:*:*:*:*:*:*:*:*",
"Dreamer=CMS":"cpe:2.3:a:iteachyou:dreamer_cms:*:*:*:*:*:*:*:*",
"Itech=Auction Script":"cpe:2.3:a:itechscripts:auction_script:*:*:*:*:*:*:*:*",
"Itech=B2B Script":"cpe:2.3:a:itechscripts:b2b_script:*:*:*:*:*:*:*:*",
"Itech=Classifieds Script":"cpe:2.3:a:itechscripts:classifieds_script:*:*:*:*:*:*:*:*",
"Itech=Dating Script":"cpe:2.3:a:itechscripts:dating_script:*:*:*:*:*:*:*:*",
"Itech=Freelancer Script":"cpe:2.3:a:itechscripts:freelancer_script:*:*:*:*:*:*:*:*",
"Itech=Job Portal Script":"cpe:2.3:a:itechscripts:job_portal_script:*:*:*:*:*:*:*:*",
"Itech=Multi Vendor Script":"cpe:2.3:a:itechscripts:multi_vendor_script:*:*:*:*:*:*:*:*",
"Itech=News Portal":"cpe:2.3:a:itechscripts:news_portal_script:*:*:*:*:*:*:*:*",
"Itech=Real Estate Script":"cpe:2.3:a:itechscripts:real_estate_script:*:*:*:*:*:*:*:*",
"iterative=PyDrive2":"cpe:2.3:a:iterative:pydrive2:*:*:*:*:*:*:*:*",
"iTerm2=iTerm2":"cpe:2.3:a:iterm2:iterm2:*:*:*:*:*:*:*:*",
"Apryse=iText":"cpe:2.3:a:itextpdf:itext:*:*:*:*:*:*:*:*",
"iText=RUPS":"cpe:2.3:a:itextpdf:rups:*:*:*:*:*:*:*:*",
"i3thuan5=TuiTse-TsuSin":"cpe:2.3:a:ithuan:tuitse-tsusin:*:*:*:*:*:*:*:*",
" ITPison=Contact ITPison":"cpe:2.3:a:itpison:omicard_edm:*:*:*:*:*:*:*:*",
"ITPison=OMICARD EDM":"cpe:2.3:a:itpison:omicard_edm:*:*:*:*:*:*:*:*",
"ITPison=OMICARD EDM 's SMS":"cpe:2.3:a:itpison:omicard_edm:*:*:*:*:*:*:*:*",
"ITRS Group=monitor-ninja":"cpe:2.3:a:itrsgroup:ninja:*:*:*:*:*:*:*:*",
"bildsben=iTunesRPC-Remastered":"cpe:2.3:a:itunesrpc-remastered_project:itunesrpc-remastered:*:*:*:*:*:*:*:*",
"Ivanti=Automation":"cpe:2.3:a:ivanti:automation:*:*:*:*:*:*:*:*",
"Ivanti=Avalanche":"cpe:2.3:a:ivanti:avalanche:*:*:*:*:*:*:*:*",
"Ivanti=Wavelink":"cpe:2.3:a:ivanti:avalanche:*:*:*:*:*:*:*:*",
"Ivanti=Ivanti Endpoint Manager":"cpe:2.3:a:ivanti:endpoint_manager:*:*:*:*:*:*:*:*",
"Ivanti=EPMM":"cpe:2.3:a:ivanti:endpoint_manager_mobile:*:*:*:*:*:*:*:*",
"Ivanti=Endpoint Manager Mobile":"cpe:2.3:a:ivanti:endpoint_manager_mobile:*:*:*:*:*:*:*:*",
"Ivanti=MobileIron Core":"cpe:2.3:a:ivanti:mobileiron:*:*:*:*:*:*:*:*",
"Ivanti=MobileIron Sentry":"cpe:2.3:a:ivanti:mobileiron_sentry:*:*:*:*:*:*:*:*",
"Ivanti=ITSM":"cpe:2.3:a:ivanti:neurons_for_itsm:*:*:*:*:*:*:*:*",
"Ivanti=Sentry":"cpe:2.3:a:ivanti:standalone_sentry:*:*:*:*:*:*:*:*",
"npm=iziModal":"cpe:2.3:a:izimodal_project:izimodal:*:*:*:*:*:*:*:*",
"Japan Agency for Local Authority Information Systems=The Public Certification Service for Individuals The JPKI user's software":"cpe:2.3:a:j-lis:the_public_certification_service_for_individuals:*:*:*:*:*:*:*:*",
"Dromara=J2eeFAST":"cpe:2.3:a:j2eefast:j2eefast:*:*:*:*:*:*:*:*",
"jackalope=jackalope-doctrine-dbal":"cpe:2.3:a:jackalope_doctrine-dbal_project:jackalope_doctrine-dbal:*:*:*:*:*:*:*:*",
"skylot=jadx":"cpe:2.3:a:jadx_project:jadx:*:*:*:*:*:*:*:*",
"skylot=skylot/jadx":"cpe:2.3:a:jadx_project:jadx:*:*:*:*:*:*:*:*",
"Japan Atomic Energy Agency=Installer of Mapping Tool":"cpe:2.3:a:jaea:mapping_tool:*:*:*:*:*:*:*:*",
"JAL Information Technology Co., Ltd.=PALLET CONTROL":"cpe:2.3:a:jalinfotec:pallet_control:*:*:*:*:*:*:*:*",
"SourceCodester=Engineers Online Portal":"cpe:2.3:a:janobe:engineers_online_portal:*:*:*:*:*:*:*:*",
"SourceCodester=Life Insurance Management System":"cpe:2.3:a:janobe:life_insurance_management_system:*:*:*:*:*:*:*:*",
"TooTallNate=Java-WebSocket":"cpe:2.3:a:java-websocket_project:java-websocket:*:*:*:*:*:*:*:*",
"JavaEZLib=JavaEZ":"cpe:2.3:a:javaez_project:javaez:*:*:*:*:*:*:*:*",
"Justice AV Solutions=Viewer":"cpe:2.3:a:javs:javs_viewer:*:*:*:*:*:*:*:*",
"tanghaibao=jcvi":"cpe:2.3:a:jcvi_project:jcvi:*:*:*:*:*:*:*:*",
"unspecified=jeecg-boot":"cpe:2.3:a:jeecg:jeecg_boot:*:*:*:*:*:*:*:*",
"jeecgboot=JimuReport":"cpe:2.3:a:jeecg:jimureport:*:*:*:*:*:*:*:*",
"Jeesite=Jeesite":"cpe:2.3:a:jeesite:jeesite:*:*:*:*:*:*:*:*",
"sviehb=jefferson":"cpe:2.3:a:jefferson_project:jefferson:*:*:*:*:*:*:*:*",
"jellyfin=jellyfin":"cpe:2.3:a:jellyfin:jellyfin:*:*:*:*:*:*:*:*",
"jellyfin=jellyfin-web":"cpe:2.3:a:jellyfin:jellyfin:*:*:*:*:*:*:*:*",
"Jenkins project=Jenkins eggPlant Plugin":"cpe:2.3:a:jenkins:eggplant:*:*:*:*:*:*:*:*",
"Jenkins project=Jenkins Flaky Test Handler Plugin":"cpe:2.3:a:jenkins:flaky_test_handler:*:*:*:*:*:*:*:*",
"Jenkins Project=Jenkins HTMLResource Plugin":"cpe:2.3:a:jenkins:html_resource:*:*:*:*:*:*:*:*",
"Jenkins project=Jenkins":"cpe:2.3:a:jenkins:jenkins:*:*:*:*:*:*:*:*",
"[UNKNOWN]=jenkins":"cpe:2.3:a:jenkins:jenkins:*:*:*:*:*:*:*:*",
"Jenkins project=Jenkins Yet Another Build Visualizer Plugin":"cpe:2.3:a:jenkins:yet_another_build_visualizer:*:*:*:*:*:*:*:*",
"JerryScript=JerryScript":"cpe:2.3:a:jerryscript:jerryscript:*:*:*:*:*:*:*:*",
"JetBrains=Hub":"cpe:2.3:a:jetbrains:hub:*:*:*:*:*:*:*:*",
"JetBrains=IntelliJ IDEA":"cpe:2.3:a:jetbrains:intellij_idea:*:*:*:*:*:*:*:*",
"JetBrains=JetBrains Gateway":"cpe:2.3:a:jetbrains:jetbrains_gateway:*:*:*:*:*:*:*:*",
"JetBrains=Ktor":"cpe:2.3:a:jetbrains:ktor:*:*:*:*:*:*:*:*",
"Ktor.io=Ktor":"cpe:2.3:a:jetbrains:ktor:*:*:*:*:*:*:*:*",
"JetBrains=PhpStorm":"cpe:2.3:a:jetbrains:phpstorm:*:*:*:*:*:*:*:*",
"JetBrains=PyCharm":"cpe:2.3:a:jetbrains:pycharm:*:*:*:*:*:*:*:*",
"JetBrains=Rider":"cpe:2.3:a:jetbrains:pycharm:*:*:*:*:*:*:*:*",
"JetBrains=Rider":"cpe:2.3:a:jetbrains:rider:*:*:*:*:*:*:*:*",
"JetBrains=TeamCity":"cpe:2.3:a:jetbrains:teamcity:*:*:*:*:*:*:*:*",
"JetBrains=Toolbox App":"cpe:2.3:a:jetbrains:toolbox:*:*:*:*:*:*:*:*",
"JetBrains=YouTrack":"cpe:2.3:a:jetbrains:youtrack:*:*:*:*:*:*:*:*",
"jettison=jettison":"cpe:2.3:a:jettison_project:jettison:*:*:*:*:*:*:*:*",
"SourceCodester=Jewelry Store System":"cpe:2.3:a:jewelry_store_system_project:jewelry_store_system:*:*:*:*:*:*:*:*",
"glorylion=JFinalOA":"cpe:2.3:a:jfinaloa_project:jfinaloa:*:*:*:*:*:*:*:*",
"JFrog=Artifactory":"cpe:2.3:a:jfrog:artifactory:*:*:*:*:*:*:*:*",
"centic9=jgit-cookbook":"cpe:2.3:a:jgit-cookbook_project:jgit-cookbook:*:*:*:*:*:*:*:*",
"jhipster=jhipster-kotlin":"cpe:2.3:a:jhipster:generator-jhipster-kotlin:*:*:*:*:*:*:*:*",
"JiangMin=Antivirus":"cpe:2.3:a:jiangmin:jiangmin_antivirus:*:*:*:*:*:*:*:*",
"Jianming=Antivirus":"cpe:2.3:a:jiangmin:jiangmin_antivirus:*:*:*:*:*:*:*:*",
"Jiransecurity=Spamsniper":"cpe:2.3:a:jiransecurity:spamsniper:*:*:*:*:*:*:*:*",
"Jiusi=OA":"cpe:2.3:a:jiusi:jiusi_oa:*:*:*:*:*:*:*:*",
"trampgeek=jobe":"cpe:2.3:a:jobe_project:jobe:*:*:*:*:*:*:*:*",
"johnkerl=miller":"cpe:2.3:a:johnkerl:miller:*:*:*:*:*:*:*:*",
"Johnson Controls=CEVAS":"cpe:2.3:a:johnsoncontrols:cevas:*:*:*:*:*:*:*:*",
"Johnson Controls=exacqVision Enterprise Manager":"cpe:2.3:a:johnsoncontrols:exacqvision_enterprise_manager:*:*:*:*:*:*:*:*",
"Exacq Technologies, Inc.=exacqVision Server":"cpe:2.3:a:johnsoncontrols:exacqvision_server:*:*:*:*:*:*:*:*",
"Johnson Controls=exacqVision Web Service":"cpe:2.3:a:johnsoncontrols:exacqvision_web_service:*:*:*:*:*:*:*:*",
"Johnson Controls=exacqVision Web Service version 20.12.2.0 and prior":"cpe:2.3:a:johnsoncontrols:exacqvision_web_service:*:*:*:*:*:*:*:*",
"Johnson Controls=Metasys":"cpe:2.3:a:johnsoncontrols:metasys:*:*:*:*:*:*:*:*",
"Johnson Controls=Metasys Reporting Engine (MRE) Web Services versions 2.0 and 2.1":"cpe:2.3:a:johnsoncontrols:metasys_reporting_engine:*:*:*:*:*:*:*:*",
"Johnson Controls=Metasys versions prior to 9.0":"cpe:2.3:a:johnsoncontrols:metasys_system:*:*:*:*:*:*:*:*",
"Johnson Controls=System Configuration Tool (SCT)":"cpe:2.3:a:johnsoncontrols:metasys_system_configuration_tool:*:*:*:*:*:*:*:*",
"bookwyrm-social=bookwyrm":"cpe:2.3:a:joinbookwyrm:bookwyrm:*:*:*:*:*:*:*:*",
"bookwyrm-social=bookwyrm-social/bookwyrm":"cpe:2.3:a:joinbookwyrm:bookwyrm:*:*:*:*:*:*:*:*",
"mastodon=mastodon":"cpe:2.3:a:joinmastodon:mastodon:*:*:*:*:*:*:*:*",
"mastodon=mastodon/mastodon":"cpe:2.3:a:joinmastodon:mastodon:*:*:*:*:*:*:*:*",
"Joomla! Project=Joomla! CMS":"cpe:2.3:a:joomla:joomla\!:*:*:*:*:*:*:*:*",
"Jorani=Jorani":"cpe:2.3:a:jorani:jorani:*:*:*:*:*:*:*:*",
"SiteBridge Inc.=Joruri CMS 2017":"cpe:2.3:a:joruri:joruri_cms_2017:*:*:*:*:*:*:*:*",
"SiteBridge Inc.=Joruri Mail":"cpe:2.3:a:joruri:joruri_mail:*:*:*:*:*:*:*:*",
"Joyent=Joyent Smart Data Center":"cpe:2.3:a:joyent:triton_datacenter:*:*:*:*:*:*:*:*",
"JPCERT Coordination Center=LogonTracer":"cpe:2.3:a:jpcert:logontracer:*:*:*:*:*:*:*:*",
" Japan Computer Emergency Response Team Coordination Center (JPCERT/CC)=Special Interest Group Network for Analysis and Liaison":"cpe:2.3:a:jpcert:special_interest_group_network_for_analysis_and_liaison:*:*:*:*:*:*:*:*",
"Japan Computer Emergency Response Team Coordination Center (JPCERT/CC)=Special Interest Group Network for Analysis and Liaison ":"cpe:2.3:a:jpcert:special_interest_group_network_for_analysis_and_liaison:*:*:*:*:*:*:*:*",
"jqlang=jq":"cpe:2.3:a:jqlang:jq:*:*:*:*:*:*:*:*",
"npm=@claviska/jquery-minicolors":"cpe:2.3:a:jquery-minicolors_project:jquery-minicolors:*:*:*:*:*:*:*:*",
"jcubic=jquery.terminal":"cpe:2.3:a:jquery.terminal_project:jquery.terminal:*:*:*:*:*:*:*:*",
"Blueimp=Blueimp jQuery-File-Upload":"cpe:2.3:a:jquery_file_upload_project:jquery_file_upload:*:*:*:*:*:*:*:*",
"zeroturnaround=zt-zip":"cpe:2.3:a:jrebel:zt-zip:*:*:*:*:*:*:*:*",
"unspecified=SpringBootCMS":"cpe:2.3:a:jrecms:springbootcms:*:*:*:*:*:*:*:*",
"InnoSetup=Installer":"cpe:2.3:a:jrsoftware:inno_setup:*:*:*:*:*:*:*:*",
"J   s Communication Co., Ltd.=RevoWorks Browser":"cpe:2.3:a:jscom:revoworks_browser:*:*:*:*:*:*:*:*",
"Jsish=Jsi":"cpe:2.3:a:jsish:jsish:*:*:*:*:*:*:*:*",
"Jsish=Jsish":"cpe:2.3:a:jsish:jsish:*:*:*:*:*:*:*:*",
"jsi=jsi":"cpe:2.3:a:jsish:jsish:*:*:*:*:*:*:*:*",
"json-smart=json-smart":"cpe:2.3:a:json-smart_project:json-smart:*:*:*:*:*:*:*:*",
"moappi=Json2html":"cpe:2.3:a:json2html:json2html:*:*:*:*:*:*:*:*",
"hjiang=Jsonxx":"cpe:2.3:a:json\+\+_project:json\+\+:*:*:*:*:*:*:*:*",
"josdejong=josdejong/jsoneditor":"cpe:2.3:a:jsoneditoronline:jsoneditor:*:*:*:*:*:*:*:*",
"jstachio=jstachio":"cpe:2.3:a:jstachio_project:jstachio:*:*:*:*:*:*:*:*",
"jsuites=jsuites":"cpe:2.3:a:jsuites:jsuites:*:*:*:*:*:*:*:*",
"JTEKT ELECTRONICS CORPORATION=Kostac PLC Programming Software":"cpe:2.3:a:jtekt:kostac_plc:*:*:*:*:*:*:*:*",
"JTEKT ELECTRONICS CORPORATION=Kostac PLC Programming Software (Former name: Koyo PLC Programming Software)":"cpe:2.3:a:jtekt:kostac_plc_programming_software:*:*:*:*:*:*:*:*",
"JTEKT ELECTRONICS CORPORATION=OnSinView2":"cpe:2.3:a:jtekt:onsinview2:*:*:*:*:*:*:*:*",
"Juanpao=JPShop":"cpe:2.3:a:juanpao:jpshop:*:*:*:*:*:*:*:*",
"Jubatus Community=Jubatus":"cpe:2.3:a:jubat:jubatus:*:*:*:*:*:*:*:*",
"Jubei Inc.=JB Inquiry form":"cpe:2.3:a:jubei:jb_inquiry_form:*:*:*:*:*:*:*:*",
"SourceCodester=Judging Management System":"cpe:2.3:a:judging_management_system_project:judging_management_system:*:*:*:*:*:*:*:*",
"Jun-He Technology Ltd.= ERP POS":"cpe:2.3:a:junhetec:enterprise_resource_planning_point_of_sale_system:*:*:*:*:*:*:*:*",
"Jun-He Technology Ltd.=Quan-Fang-Wei-Tong-Xun system":"cpe:2.3:a:junhetec:omnidirectional_communication_system:*:*:*:*:*:*:*:*",
"Juniper Networks=AppFormix":"cpe:2.3:a:juniper:appformix:*:*:*:*:*:*:*:*",
"Juniper Networks=Contrail Insights":"cpe:2.3:a:juniper:appformix:*:*:*:*:*:*:*:*",
"Juniper Networks=Contrail":"cpe:2.3:a:juniper:contrail:*:*:*:*:*:*:*:*",
"Juniper Networks=Contrail Cloud":"cpe:2.3:a:juniper:contrail_cloud:*:*:*:*:*:*:*:*",
"Juniper Networks=Contrail Networking":"cpe:2.3:a:juniper:contrail_networking:*:*:*:*:*:*:*:*",
"Juniper Networks=Contrail Service Orchestration":"cpe:2.3:a:juniper:contrail_service_orchestration:*:*:*:*:*:*:*:*",
"Juniper Networks=Junos Snapshot Administrator (JSNAPy)":"cpe:2.3:a:juniper:jsnapy:*:*:*:*:*:*:*:*",
"Juniper Networks=Junos Space":"cpe:2.3:a:juniper:junos_space:*:*:*:*:*:*:*:*",
"Juniper=juniper/libslax":"cpe:2.3:a:juniper:libslax:*:*:*:*:*:*:*:*",
"Juniper Networks=MIST Cloud UI":"cpe:2.3:a:juniper:mist_cloud_ui:*:*:*:*:*:*:*:*",
"Juniper Networks=NorthStar Controller Application":"cpe:2.3:a:juniper:northstar_controller:*:*:*:*:*:*:*:*",
"Juniper Networks=Paragon Active Assurance":"cpe:2.3:a:juniper:paragon_active_assurance:*:*:*:*:*:*:*:*",
"Juniper Networks=Paragon Active Assurance":"cpe:2.3:a:juniper:paragon_active_assurance_control_center:*:*:*:*:*:*:*:*",
"Juniper Networks=Paragon Active Assurance (Formerly Netrounds)":"cpe:2.3:a:juniper:paragon_active_assurance_control_center:*:*:*:*:*:*:*:*",
"Juniper Networks=SRC Series":"cpe:2.3:a:juniper:session_and_resource_control:*:*:*:*:*:*:*:*",
"jupyterhub=binderhub":"cpe:2.3:a:jupyter:binderhub:*:*:*:*:*:*:*:*",
"jupyterhub=dockerspawner":"cpe:2.3:a:jupyter:dockerspawner:*:*:*:*:*:*:*:*",
"jupyter-server=jupyter_server":"cpe:2.3:a:jupyter:jupyter_server:*:*:*:*:*:*:*:*",
"jupyter=jupyter_server":"cpe:2.3:a:jupyter:jupyter_server:*:*:*:*:*:*:*:*",
"jupyterhub=jupyter-server-proxy":"cpe:2.3:a:jupyter:jupyter_server_proxy:*:*:*:*:*:*:*:*",
"jupyterhub=jupyterhub":"cpe:2.3:a:jupyter:jupyterhub:*:*:*:*:*:*:*:*",
"jupyterlab=jupyterlab":"cpe:2.3:a:jupyter:jupyterlab:*:*:*:*:*:*:*:*",
"jupyter=notebook":"cpe:2.3:a:jupyter:notebook:*:*:*:*:*:*:*:*",
"jupyterhub=oauthenticator":"cpe:2.3:a:jupyter:oauthenticator:*:*:*:*:*:*:*:*",
"jupyterhub=firstuseauthenticator":"cpe:2.3:a:jupyterhub:first_use_authenticator:*:*:*:*:*:*:*:*",
"jupyterhub=kubespawner":"cpe:2.3:a:jupyterhub:kubespawner:*:*:*:*:*:*:*:*",
"jupyterhub=nbgitpuller":"cpe:2.3:a:jupyterhub:nbgitpuller:*:*:*:*:*:*:*:*",
"jupyterhub=systemdspawner":"cpe:2.3:a:jupyterhub:systemdspawner:*:*:*:*:*:*:*:*",
"JustSystems=Ichitaro":"cpe:2.3:a:justsystems:ichitaro:*:*:*:*:*:*:*:*",
"Ichitaro=Ichitaro":"cpe:2.3:a:justsystems:ichitaro_2022:*:*:*:*:*:*:*:*",
"lcobucci=jwt":"cpe:2.3:a:jwt_project:jwt:*:*:*:*:*:*:*:*",
"k-box=k-box":"cpe:2.3:a:k-link:k-box:*:*:*:*:*:*:*:*",
"SUSE=k3s":"cpe:2.3:a:k3s:k3s:*:*:*:*:*:*:*:*",
"Kaifa Technology=WebITR":"cpe:2.3:a:kaifa:webitr_attendance_system:*:*:*:*:*:*:*:*",
"Kakadu Software Pty Ltd=Kakadu SDK":"cpe:2.3:a:kakadusoftware:kakadu_sdk:*:*:*:*:*:*:*:*",
"Kakadu Software=Kakadu SDK":"cpe:2.3:a:kakadusoftware:kakadu_sdk:*:*:*:*:*:*:*:*",
"Kakao=PotPlayer":"cpe:2.3:a:kakaocorp:potplayer:*:*:*:*:*:*:*:*",
"kaltura=mwEmbed":"cpe:2.3:a:kaltura:mwembed:*:*:*:*:*:*:*:*",
"charleskorn=kaml":"cpe:2.3:a:kaml_project:kaml:*:*:*:*:*:*:*:*",
"kanboard=kanboard":"cpe:2.3:a:kanboard:kanboard:*:*:*:*:*:*:*:*",
"Kaoni=ezHTTPTrans":"cpe:2.3:a:kaoni:ezhttptrans:*:*:*:*:*:*:*:*",
"kapetan=dns":"cpe:2.3:a:kapetan_dns_project:kapetan_dns:*:*:*:*:*:*:*:*",
"Karjasoft=Sami HTTP Server":"cpe:2.3:a:karjasoft:sami_http_server:*:*:*:*:*:*:*:*",
"Karmasis Informatics=Infraskope SIEM+":"cpe:2.3:a:karmasis:infraskope_siem\+:*:*:*:*:*:*:*:*",
"kartverket=github-workflows":"cpe:2.3:a:kartverket:github-workflows:*:*:*:*:*:*:*:*",
"Kashipara Group=Billing Software":"cpe:2.3:a:kashipara:billing_software:*:*:*:*:*:*:*:*",
"Kashipara=Billing Software":"cpe:2.3:a:kashipara:billing_software:*:*:*:*:*:*:*:*",
"Kashipara Group=Billing Software":"cpe:2.3:a:kashipara:billing_system:*:*:*:*:*:*:*:*",
"Kashipara=Food Management System":"cpe:2.3:a:kashipara:food_management_system:*:*:*:*:*:*:*:*",
"Kashipara Group=Hotel Management":"cpe:2.3:a:kashipara:hotel_management:*:*:*:*:*:*:*:*",
"Kashipara Group=Job Portal":"cpe:2.3:a:kashipara:job_portal:*:*:*:*:*:*:*:*",
"Kashipara Group=Online Notice Board System":"cpe:2.3:a:kashipara:online_notice_board_system:*:*:*:*:*:*:*:*",
"Kashipara Group=Travel Website":"cpe:2.3:a:kashipara:travel_website:*:*:*:*:*:*:*:*",
"Kaspersky Labs=Kaspersky Embedded Systems Security":"cpe:2.3:a:kaspersky:embedded_systems_security:*:*:*:*:*:*:*:*",
"Kaspersky Lab=Kaspersky Password Manager":"cpe:2.3:a:kaspersky:password_manager:*:*:*:*:*:*:*:*",
"Kaspersky Labs=Kaspersky Secure Mail Gateway":"cpe:2.3:a:kaspersky:secure_mail_gateway:*:*:*:*:*:*:*:*",
"Kaspersky=TinyCheck":"cpe:2.3:a:kaspersky:tinycheck:*:*:*:*:*:*:*:*",
"Kata Containers=Kata Containers":"cpe:2.3:a:katacontainers:runtime:*:*:*:*:*:*:*:*",
"kareadita=kareadita/kavita":"cpe:2.3:a:kavitareader:kavita:*:*:*:*:*:*:*:*",
"Kayrasoft=Kayrasoft":"cpe:2.3:a:kayrasoft:kayrasoft:*:*:*:*:*:*:*:*",
"unspecified=KB Affiliate Referral Script":"cpe:2.3:a:kb_affiliate_referral_script_project:kb_affiliate_referral_script:*:*:*:*:*:*:*:*",
"unspecified=KB Login Authentication Script":"cpe:2.3:a:kb_login_authentication_script_project:kb_login_authentication_script:*:*:*:*:*:*:*:*",
"unspecified=KB Messages PHP Script":"cpe:2.3:a:kb_messages_php_script_project:kb_messages_php_script:*:*:*:*:*:*:*:*",
"KBase=Metrics":"cpe:2.3:a:kbase:metrics:*:*:*:*:*:*:*:*",
"KC Group=E-Commerce Software":"cpe:2.3:a:kc_group_e-commerce_software_project:kc_group_e-commerce_software:*:*:*:*:*:*:*:*",
"Mash room - Free CGI -=Keijiban Tsumiki":"cpe:2.3:a:keijiban_tsumiki_project:keijiban_tsumiki:*:*:*:*:*:*:*:*",
"kennnyshiwa=kennnyshiwa-cogs":"cpe:2.3:a:kennnyshiwa-cogs_project:kennnyshiwa-cogs:*:*:*:*:*:*:*:*",
"Kenny2github=kenny2automate":"cpe:2.3:a:kenny2automate_project:kenny2automate:*:*:*:*:*:*:*:*",
"Kentico=Kentico Xperience XMS":"cpe:2.3:a:kentico:xperience:*:*:*:*:*:*:*:*",
"PTC=KEPServerEX":"cpe:2.3:a:kepware:kepserverex:*:*:*:*:*:*:*:*",
"tiann=KernelSU":"cpe:2.3:a:kernelsu:kernelsu:*:*:*:*:*:*:*:*",
"tiann=tiann/kernelsu":"cpe:2.3:a:kernelsu:kernelsu:*:*:*:*:*:*:*:*",
"snoyberg=keter":"cpe:2.3:a:keter_project:keter:*:*:*:*:*:*:*:*",
"jdennis=keycloak-httpd-client-install":"cpe:2.3:a:keycloak-httpd-client-install_project:keycloak-httpd-client-install:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=Keycloak":"cpe:2.3:a:keycloak:keycloak:*:*:*:*:*:*:*:*",
"keymaker-mx=keymaker":"cpe:2.3:a:keymaker_project:keymaker:*:*:*:*:*:*:*:*",
"juliangruber=keypair":"cpe:2.3:a:keypair_project:keypair:*:*:*:*:*:*:*:*",
"Keysight Technologies=N6845A Geolocation Server":"cpe:2.3:a:keysight:geolocation_server:*:*:*:*:*:*:*:*",
"Keysight=IXIA Hawkeye":"cpe:2.3:a:keysight:hawkeye:*:*:*:*:*:*:*:*",
"Keysight=N8844A Data Analytics Web Service":"cpe:2.3:a:keysight:n8844a:*:*:*:*:*:*:*:*",
"kevinpapst=kevinpapst/kimai2":"cpe:2.3:a:kimai2_project:kimai2:*:*:*:*:*:*:*:*",
"kevinpapst=kevinpapst/kimai2":"cpe:2.3:a:kimai:kimai2:*:*:*:*:*:*:*:*",
"kimai=kimai":"cpe:2.3:a:kimai:kimai:*:*:*:*:*:*:*:*",
"kevinpapst=kevinpapst/kimai2":"cpe:2.3:a:kimai:kimai_2:*:*:*:*:*:*:*:*",
"Frhed =Frhed ":"cpe:2.3:a:kimmov:frhed:*:*:*:*:*:*:*:*",
"Project Kinaga=KinagaCMS":"cpe:2.3:a:kinagacms_project:kinagacms:*:*:*:*:*:*:*:*",
"KINGSOFT JAPAN, INC.=KINGSOFT Internet Security 9 Plus":"cpe:2.3:a:kingsoft:internet_security_9_plus:*:*:*:*:*:*:*:*",
"KINGSOFT JAPAN, INC.=The installer of WPS Office":"cpe:2.3:a:kingsoft:wps_office:*:*:*:*:*:*:*:*",
"KINGSOFT JAPAN, INC.=WPS Office":"cpe:2.3:a:kingsoft:wps_office:*:*:*:*:*:*:*:*",
"WPS=WPS Office":"cpe:2.3:a:kingsoft:wps_office:*:*:*:*:*:*:*:*",
"KINGSOFT JAPAN, INC.=WPS Presentation":"cpe:2.3:a:kingsoft:wps_presentation:*:*:*:*:*:*:*:*",
"tktchurch=website":"cpe:2.3:a:kingstemple:the_king\'s_temple_church_website:*:*:*:*:*:*:*:*",
"Kiri=Tween":"cpe:2.3:a:kiri:tween:*:*:*:*:*:*:*:*",
"kitabisa=teler-waf":"cpe:2.3:a:kitabisa:teler-waf:*:*:*:*:*:*:*:*",
"kiwitcms=Kiwi":"cpe:2.3:a:kiwitcms:kiwi_tcms:*:*:*:*:*:*:*:*",
"kiwitcms=kiwi":"cpe:2.3:a:kiwitcms:kiwi_tcms:*:*:*:*:*:*:*:*",
"kiwitcms=kiwitcms/kiwi":"cpe:2.3:a:kiwitcms:kiwi_tcms:*:*:*:*:*:*:*:*",
"kkcal=epg search result viewer(kkcald)":"cpe:2.3:a:kkcald_project:kkcald:*:*:*:*:*:*:*:*",
"Klapp=App":"cpe:2.3:a:klapp:app:*:*:*:*:*:*:*:*",
"knative=serving":"cpe:2.3:a:knative:serving:*:*:*:*:*:*:*:*",
"KNIME=KNIME Business Hub":"cpe:2.3:a:knime:business_hub:*:*:*:*:*:*:*:*",
"KNIME=KNIME Analytics Platform":"cpe:2.3:a:knime:knime_analytics_platform:*:*:*:*:*:*:*:*",
"KNIME=KNIME Server":"cpe:2.3:a:knime:knime_server:*:*:*:*:*:*:*:*",
"KnpLabs=snappy":"cpe:2.3:a:knplabs:snappy:*:*:*:*:*:*:*:*",
"KNX Association=KNX Protocol Connection Authorization Option 1":"cpe:2.3:a:knx:connection_authorization:*:*:*:*:*:*:*:*",
"kalcaddle=kodbox":"cpe:2.3:a:kodcloud:kodbox:*:*:*:*:*:*:*:*",
"kalcaddle=KodExplorer":"cpe:2.3:a:kodcloud:kodexplorer:*:*:*:*:*:*:*:*",
"Koji Project=Koji":"cpe:2.3:a:koji_project:koji:*:*:*:*:*:*:*:*",
"Fleet=Fleet":"cpe:2.3:a:kolide:fleet:*:*:*:*:*:*:*:*",
"kongchuanhujiao=server":"cpe:2.3:a:kongchuanhujiao_project:kongchuanhujiao:*:*:*:*:*:*:*:*",
"                                                =Online Accounting System":"cpe:2.3:a:kotchasan:online_accounting_system:*:*:*:*:*:*:*:*",
"kphrx=pleroma":"cpe:2.3:a:kpherox:pleroma:*:*:*:*:*:*:*:*",
"kromitgmbh=kromitgmbh/titra":"cpe:2.3:a:kromit:titra:*:*:*:*:*:*:*:*",
"Kron =Single Connect":"cpe:2.3:a:krontech:single_connect:*:*:*:*:*:*:*:*",
"ks-soft=Advanced Host Monitor":"cpe:2.3:a:ks-soft:advanced_host_monitor:*:*:*:*:*:*:*:*",
"ktlint=ktlint":"cpe:2.3:a:ktlint_project:ktlint:*:*:*:*:*:*:*:*",
"kubeflow=kubeflow/kubeflow":"cpe:2.3:a:kubeflow:kubeflow:*:*:*:*:*:*:*:*",
"Kubernetes=kube-apiserver":"cpe:2.3:a:kubernetes:apiserver:*:*:*:*:*:*:*:*",
"Kubernetes=CSI Snapshotter":"cpe:2.3:a:kubernetes:container_storage_interface_snapshotter:*:*:*:*:*:*:*:*",
"Kubernetes=Kubernetes ingress-nginx":"cpe:2.3:a:kubernetes:ingress-nginx:*:*:*:*:*:*:*:*",
"Kubernetes=ingress-nginx":"cpe:2.3:a:kubernetes:ingress-nginx:*:*:*:*:*:*:*:*",
"Kubernetes=Kubernetes Java Client":"cpe:2.3:a:kubernetes:java:*:*:*:*:*:*:*:*",
"Kubernetes=Kubernetes":"cpe:2.3:a:kubernetes:kubernetes:*:*:*:*:*:*:*:*",
"Kubernetes=Minikube":"cpe:2.3:a:kubernetes:minikube:*:*:*:*:*:*:*:*",
"Kubernetes=minikube":"cpe:2.3:a:kubernetes:minikube:*:*:*:*:*:*:*:*",
"Kubernetes=k8s.gcr.io/defaultbackend":"cpe:2.3:a:kubernetes:nginx_ingress_controller:*:*:*:*:*:*:*:*",
"Kubernetes=kops":"cpe:2.3:a:kubernetes:operations:*:*:*:*:*:*:*:*",
"Kubernetes=secrets-store-csi-driver":"cpe:2.3:a:kubernetes:secrets-store-csi-driver:*:*:*:*:*:*:*:*",
"Kubernetes=Kubernetes Secrets Store CSI Driver":"cpe:2.3:a:kubernetes:secrets_store_csi_driver:*:*:*:*:*:*:*:*",
"kubevela=kubevela":"cpe:2.3:a:kubevela:kubevela:*:*:*:*:*:*:*:*",
"KubeVirt=containerized-data-importer":"cpe:2.3:a:kubevirt:containerized-data-importer:*:*:*:*:*:*:*:*",
"The Kubevirt project=kubevirt/virt-cdi-importer":"cpe:2.3:a:kubevirt:containerized_data_importer:*:*:*:*:*:*:*:*",
"Sichuan Yougou Technology=KuERP":"cpe:2.3:a:kuerp_project:kuerp:*:*:*:*:*:*:*:*",
"kujirahand=KonaWiki":"cpe:2.3:a:kujirahand:konawiki:*:*:*:*:*:*:*:*",
"kujirahand=KonaWiki2":"cpe:2.3:a:kujirahand:konawiki:*:*:*:*:*:*:*:*",
"kujirahand=Nadesiko3 (PC Version)":"cpe:2.3:a:kujirahand:nadesiko3:*:*:*:*:*:*:*:*",
"kujirahand=Nako3edit, editor component of nadesiko3 (PC Version)":"cpe:2.3:a:kujirahand:nadesiko3:*:*:*:*:*:*:*:*",
"KUKA=Sim Pro":"cpe:2.3:a:kuka:sim_pro:*:*:*:*:*:*:*:*",
"KUKA=SystemSoftware V/KSS":"cpe:2.3:a:kuka:systemsoftware_v\/kss:*:*:*:*:*:*:*:*",
"KUKA Roboter GmbH=Visual Components Network License Server 2.0.8":"cpe:2.3:a:kuka:visual_components_network_license_server:*:*:*:*:*:*:*:*",
"Sifir Bes Education and Informatics=Kunduz - Homework Helper App":"cpe:2.3:a:kunduz:kunduz:*:*:*:*:*:*:*:*",
"KylinSoft=hedron-domain-hook":"cpe:2.3:a:kylinos:hedron-domain-hook:*:*:*:*:*:*:*:*",
"KylinSoft=kylin-software-properties":"cpe:2.3:a:kylinos:kylin-software-properties:*:*:*:*:*:*:*:*",
"KylinSoft=youker-assistant":"cpe:2.3:a:kylinos:youker-assistant:*:*:*:*:*:*:*:*",
"SAP SE=Kyma":"cpe:2.3:a:kyma-project:kyma:*:*:*:*:*:*:*:*",
"Kyocera=Multifunction Printer Net Viewer":"cpe:2.3:a:kyocera:net_viewer:*:*:*:*:*:*:*:*",
"kyverno=kyverno":"cpe:2.3:a:kyverno:kyverno:*:*:*:*:*:*:*:*",
"Lab Digital=wagtail-2fa":"cpe:2.3:a:labdigital:wagtail-2fa:*:*:*:*:*:*:*:*",
"Ladon=Ladon":"cpe:2.3:a:ladon_project:ladon:*:*:*:*:*:*:*:*",
"Ladybirdweb=Faveo Helpdesk":"cpe:2.3:a:ladybirdweb:faveo_helpdesk:*:*:*:*:*:*:*:*",
"Bettershop=LaikeTui":"cpe:2.3:a:laiketui:laiketui:*:*:*:*:*:*:*:*",
"lambdaisland=uri":"cpe:2.3:a:lambdaisland:uri:*:*:*:*:*:*:*:*",
"Lanaccess=ONSAFE MonitorHM":"cpe:2.3:a:lanaccess:onsafe_monitorhm:*:*:*:*:*:*:*:*",
"Lansweeper=lansweeper":"cpe:2.3:a:lansweeper:lansweeper:*:*:*:*:*:*:*:*",
"Lapce=Lapce":"cpe:2.3:a:lapce:lapce:*:*:*:*:*:*:*:*",
"LCDS Laquis=LCDS Laquis SCADA":"cpe:2.3:a:laquisscada:laquis_scada:*:*:*:*:*:*:*:*",
"jsdecena=jsdecena/laracom":"cpe:2.3:a:laracom_project:laracom:*:*:*:*:*:*:*:*",
"laravel=framework":"cpe:2.3:a:laravel:framework:*:*:*:*:*:*:*:*",
"laravel=framework":"cpe:2.3:a:laravel:laravel:*:*:*:*:*:*:*:*",
"unspecified=Laravel":"cpe:2.3:a:laravel:laravel:*:*:*:*:*:*:*:*",
"unspecified=laravel":"cpe:2.3:a:laravel:laravel:*:*:*:*:*:*:*:*",
"unspecified=laravel-jqgrid":"cpe:2.3:a:laravel_jqgrid_project:laravel_jqgrid:*:*:*:*:*:*:*:*",
"Larvata Digital Technology Co. Ltd.=FLYGO":"cpe:2.3:a:larvata:flygo:*:*:*:*:*:*:*:*",
"thisAAY=Lazy Mouse":"cpe:2.3:a:lazy_mouse_project:lazy_mouse:*:*:*:*:*:*:*:*",
"ICS-CERT=LCDS - Le&#195;&#163;o Consultoria e Desenvolvimento de Sistemas LTDA ME LAquis SCADA":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"ICS-CERT=LCDS Laquis SCADA":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"LCDS   Le  o Consultoria e Desenvolvimento de Sistemas Ltda ME=LAquis SCADA":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"LCDS - Le    o Consultoria e Desenvolvimento de Sistemas LTDA ME=LAquis SCADA software":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"LCDS - Le  o Consultoria e Desenvolvimento de Sistemas LTDA ME=LAquis SCADA":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"LCDS - Le  o Consultoria e Desenvolvimento de Sistemas Ltda ME=LAquis SCADA":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"LCDS=LCDS Laquis SCADA":"cpe:2.3:a:lcds:laquis_scada:*:*:*:*:*:*:*:*",
"Smart eVision Information Technology Inc.=Smart eVision":"cpe:2.3:a:lcnet:smart_evision:*:*:*:*:*:*:*:*",
"kakwa=LdapCherry":"cpe:2.3:a:ldapcherry_project:ldapcherry:*:*:*:*:*:*:*:*",
"Le-yan Co., Ltd.=Dental Management System":"cpe:2.3:a:le-yan_dental_management_system_project:le-yan_dental_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Lead Management System":"cpe:2.3:a:lead_management_system_project:lead_management_system:*:*:*:*:*:*:*:*",
"Hecheng=Leadshop":"cpe:2.3:a:leadshop:leadshop:*:*:*:*:*:*:*:*",
"qmpaas=qmpaas/leadshop":"cpe:2.3:a:leadshop:leadshop:*:*:*:*:*:*:*:*",
"Leadtools=Leadtools":"cpe:2.3:a:leadtools:leadtools:*:*:*:*:*:*:*:*",
"Leanote=Leanote":"cpe:2.3:a:leanote:desktop:*:*:*:*:*:*:*:*",
"Leanote=Leanote":"cpe:2.3:a:leanote:leanote:*:*:*:*:*:*:*:*",
"Leantime=Leantime":"cpe:2.3:a:leantime:leantime:*:*:*:*:*:*:*:*",
"Leantime=leantime":"cpe:2.3:a:leantime:leantime:*:*:*:*:*:*:*:*",
"Learningdigital.com, Inc.=Orca HCM":"cpe:2.3:a:learningdigital:orca_hcm:*:*:*:*:*:*:*:*",
"Ledger=Ledger CLI":"cpe:2.3:a:ledger-cli:ledger:*:*:*:*:*:*:*:*",
"ledgersmb=LedgerSMB":"cpe:2.3:a:ledgersmb:ledgersmb:*:*:*:*:*:*:*:*",
"ledgersmb=ledgersmb/ledgersmb":"cpe:2.3:a:ledgersmb:ledgersmb:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=Active Protection System":"cpe:2.3:a:lenovo:active_protection_system:*:*:*:*:*:*:*:*",
"Antilles=Antilles":"cpe:2.3:a:lenovo:antilles:*:*:*:*:*:*:*:*",
"Lenovo=Lenovo App Store Application":"cpe:2.3:a:lenovo:app_store:*:*:*:*:*:*:*:*",
"Lenovo=App Store":"cpe:2.3:a:lenovo:app_store_app:*:*:*:*:*:*:*:*",
"Lenovo=Customer Engagement Service (CCSDK)":"cpe:2.3:a:lenovo:customer_engagement_service:*:*:*:*:*:*:*:*",
"Lenovo=Diagnostics":"cpe:2.3:a:lenovo:diagnostics:*:*:*:*:*:*:*:*",
"Lenovo=Driver Management":"cpe:2.3:a:lenovo:drivers_management:*:*:*:*:*:*:*:*",
"Lenovo=Drivers Management":"cpe:2.3:a:lenovo:drivers_management:*:*:*:*:*:*:*:*",
"Lenovo=Lenovo Drivers Management":"cpe:2.3:a:lenovo:drivers_management:*:*:*:*:*:*:*:*",
"Lenovo=Installation Packages":"cpe:2.3:a:lenovo:installation_package:*:*:*:*:*:*:*:*",
"Lenovo=LeCloud Application":"cpe:2.3:a:lenovo:lecloud:*:*:*:*:*:*:*:*",
"Lenovo=Leyun":"cpe:2.3:a:lenovo:leyun:*:*:*:*:*:*:*:*",
"Lenovo=LenovoPaper":"cpe:2.3:a:lenovo:paper:*:*:*:*:*:*:*:*",
"Lenovo=PCManager":"cpe:2.3:a:lenovo:pcmanager:*:*:*:*:*:*:*:*",
"Lenovo=1Lenovo Preload Directory":"cpe:2.3:a:lenovo:preload_directory:*:*:*:*:*:*:*:*",
"Lenovo=Safecenter":"cpe:2.3:a:lenovo:safecenter:*:*:*:*:*:*:*:*",
"Lenovo=Smart Standby Driver":"cpe:2.3:a:lenovo:smart_standby_driver:*:*:*:*:*:*:*:*",
"Lenovo=Solution Center":"cpe:2.3:a:lenovo:solution_center:*:*:*:*:*:*:*:*",
"Lenovo=IMController":"cpe:2.3:a:lenovo:system_interface_foundation:*:*:*:*:*:*:*:*",
"Lenovo=Lenovo System Interface Foundation":"cpe:2.3:a:lenovo:system_interface_foundation:*:*:*:*:*:*:*:*",
"Lenovo=LenovoAppScenarioPluginSystem for Lenovo System Interface Foundation":"cpe:2.3:a:lenovo:system_interface_foundation:*:*:*:*:*:*:*:*",
"Lenovo=LenovoSystemUpdatePlugin for Lenovo System Interface Foundation":"cpe:2.3:a:lenovo:system_interface_foundation:*:*:*:*:*:*:*:*",
"Lenovo=System Interface Foundation":"cpe:2.3:a:lenovo:system_interface_foundation:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=Lenovo System Update":"cpe:2.3:a:lenovo:system_update:*:*:*:*:*:*:*:*",
"Lenovo=Lenovo System Update":"cpe:2.3:a:lenovo:system_update:*:*:*:*:*:*:*:*",
"Lenovo=System Update":"cpe:2.3:a:lenovo:system_update:*:*:*:*:*:*:*:*",
"Lenovo=Thin Installer":"cpe:2.3:a:lenovo:thin_installer:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=ThinkPad Compact USB Keyboard with TrackPoint Driver":"cpe:2.3:a:lenovo:thinkpad_compact_usb_keyboard_driver:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=ThinkPad USB 3.0 Ethernet Adapter Driver":"cpe:2.3:a:lenovo:thinkpad_usb_3.0_ethernet_adapter_driver:*:*:*:*:*:*:*:*",
"Lenovo=Universal Device Client (UDC)":"cpe:2.3:a:lenovo:universal_device_client:*:*:*:*:*:*:*:*",
"Lenovo=Vantage":"cpe:2.3:a:lenovo:vantage:*:*:*:*:*:*:*:*",
"Lenovo=Vantage ":"cpe:2.3:a:lenovo:vantage:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=Lenovo XClarity Administrator (LXCA)":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=Lenovo xClarity Administrator":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=XClarity Administrator":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo Group Ltd.=xClarity Administrator":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo=Lenovo XClarity Administrator":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo=Lenovo XClarity Administrator ":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo=XClarity Administrator":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo=XClarity Administrator (LXCA)":"cpe:2.3:a:lenovo:xclarity_administrator:*:*:*:*:*:*:*:*",
"Lenovo=XClarity Orchestrator ":"cpe:2.3:a:lenovo:xclarity_orchestrator:*:*:*:*:*:*:*:*",
"lestrrat-go=jwx":"cpe:2.3:a:lestrrat-go:jwx:*:*:*:*:*:*:*:*",
"Lexmark=Perceptive Document Filters":"cpe:2.3:a:lexmark:perceptive_document_filters:*:*:*:*:*:*:*:*",
"Lexmark=Perceptive Document Filters ":"cpe:2.3:a:lexmark:perceptive_document_filters:*:*:*:*:*:*:*:*",
"Le-yan Co., Ltd.=Personnel and Salary Management System":"cpe:2.3:a:leyan:salary_management_system:*:*:*:*:*:*:*:*",
"mlflow=mlflow/mlflow":"cpe:2.3:a:lfprojects:mlflow:*:*:*:*:*:*:*:*",
"LG Electronics=LG-LED Assistant":"cpe:2.3:a:lg:lg_led_assistant:*:*:*:*:*:*:*:*",
"Claybird=LhaForge":"cpe:2.3:a:lhaforge_project:lhaforge:*:*:*:*:*:*:*:*",
"Schezo=Lhaplus":"cpe:2.3:a:lhaplus_project:lhaplus:*:*:*:*:*:*:*:*",
"Fabrice Bellard=libbpg":"cpe:2.3:a:libbpg_project:libbpg:*:*:*:*:*:*:*:*",
"obgm=libcoap":"cpe:2.3:a:libcoap:libcoap:*:*:*:*:*:*:*:*",
"Flexera Software LLC=libexif":"cpe:2.3:a:libexif_project:libexif:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=libexif":"cpe:2.3:a:libexif_project:libexif:*:*:*:*:*:*:*:*",
"libgit2=libgit2":"cpe:2.3:a:libgit2:libgit2:*:*:*:*:*:*:*:*",
"Google LLC=libjxl":"cpe:2.3:a:libjxl_project:libjxl:*:*:*:*:*:*:*:*",
"Libjxl=Libjxl":"cpe:2.3:a:libjxl_project:libjxl:*:*:*:*:*:*:*:*",
"bfabiszewski=bfabiszewski/libmobi":"cpe:2.3:a:libmobi_project:libmobi:*:*:*:*:*:*:*:*",
"libnmap=libnmap":"cpe:2.3:a:libnmap:libnmap:*:*:*:*:*:*:*:*",
"libpano13=libpano13":"cpe:2.3:a:libpano13_project:libpano13:*:*:*:*:*:*:*:*",
"Hasherezade (github)=Libpeconv":"cpe:2.3:a:libpeconv_project:libpeconv:*:*:*:*:*:*:*:*",
"[UNKNOWN]=podman":"cpe:2.3:a:libpod_project:libpod:*:*:*:*:*:*:*:*",
"SourceCodester=Library Management System":"cpe:2.3:a:library_management_system_project:library_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Library System":"cpe:2.3:a:library_system_project:library_system:*:*:*:*:*:*:*:*",
"LibRaw=LibRaw-demosaic-pack-GPL2":"cpe:2.3:a:libraw:libraw-demosaic-pack-gpl2:*:*:*:*:*:*:*:*",
"LibRaw=LibRaw":"cpe:2.3:a:libraw:libraw:*:*:*:*:*:*:*:*",
"librenms=librenms":"cpe:2.3:a:librenms:librenms:*:*:*:*:*:*:*:*",
"librenms=librenms/librenms":"cpe:2.3:a:librenms:librenms:*:*:*:*:*:*:*:*",
"Document Foundation=LibreOffice":"cpe:2.3:a:libreoffice:libreoffice:*:*:*:*:*:*:*:*",
"The Document Foundation=LibreOffice":"cpe:2.3:a:libreoffice:libreoffice:*:*:*:*:*:*:*:*",
"[UNKNOWN]=libreoffice":"cpe:2.3:a:libreoffice:libreoffice:*:*:*:*:*:*:*:*",
"librespeed=speedtest":"cpe:2.3:a:librespeed:speedtest:*:*:*:*:*:*:*:*",
"the libreswan Project=libreswan":"cpe:2.3:a:libreswan:libreswan:*:*:*:*:*:*:*:*",
"Simple DirectMedia Layer=Simple DirectMedia Layer":"cpe:2.3:a:libsdl:sdl_image:*:*:*:*:*:*:*:*",
"Flexera Software LLC=libsndfile":"cpe:2.3:a:libsndfile_project:libsndfile:*:*:*:*:*:*:*:*",
"Sound Exchange=libsox":"cpe:2.3:a:libsox_project:libsox:*:*:*:*:*:*:*:*",
"libtiff=libtiff":"cpe:2.3:a:libtiff:libtiff:*:*:*:*:*:*:*:*",
"libuv=libuv":"cpe:2.3:a:libuv:libuv:*:*:*:*:*:*:*:*",
"libvips=libvips":"cpe:2.3:a:libvips:libvips:*:*:*:*:*:*:*:*",
"libxls=libxls":"cpe:2.3:a:libxls_project:libxls:*:*:*:*:*:*:*:*",
"LifePoint Informatics=Patient Portal":"cpe:2.3:a:lifepoint:patient_portal:*:*:*:*:*:*:*:*",
"Liferay=Portal":"cpe:2.3:a:liferay:liferay_portal:*:*:*:*:*:*:*:*",
"Lif-Platforms=Lif-Auth-Server":"cpe:2.3:a:lifplatforms:lif_auth_server:*:*:*:*:*:*:*:*",
"playframework=playframework":"cpe:2.3:a:lightbend:play_framework:*:*:*:*:*:*:*:*",
"Lightxun=IPTV Gateway":"cpe:2.3:a:lightxun:iptv_gateway:*:*:*:*:*:*:*:*",
"Liman Central Management System=Liman Central Management System":"cpe:2.3:a:liman:port_mys:*:*:*:*:*:*:*:*",
"erelsgl=Limdu":"cpe:2.3:a:limdu_project:limdu:*:*:*:*:*:*:*:*",
"LINAGORA=hublin":"cpe:2.3:a:linagora:hublin:*:*:*:*:*:*:*:*",
"linagora=linagora/twake":"cpe:2.3:a:linagora:twake:*:*:*:*:*:*:*:*",
"Lindell TSS Implementations=Wallet":"cpe:2.3:a:lindell17_project:lindell17:*:*:*:*:*:*:*:*",
"apng-drawable=1.0.0 to 1.6.0":"cpe:2.3:a:linecorp:apng-drawable:*:*:*:*:*:*:*:*",
"LINE=Armeria":"cpe:2.3:a:linecorp:armeria:*:*:*:*:*:*:*:*",
"line=armeria":"cpe:2.3:a:linecorp:armeria:*:*:*:*:*:*:*:*",
"LINE Corporation=Central Dogma":"cpe:2.3:a:linecorp:central_dogma:*:*:*:*:*:*:*:*",
"LinkedIn=dustjs":"cpe:2.3:a:linkedin:dustjs:*:*:*:*:*:*:*:*",
"linkstackorg=linkstackorg/linkstack":"cpe:2.3:a:linkstack:linkstack:*:*:*:*:*:*:*:*",
"qwdigital=LinkWechat":"cpe:2.3:a:linkwechat:linkwechat:*:*:*:*:*:*:*:*",
"argoproj=argo-cd":"cpe:2.3:a:linuxfoundation:argo-cd:*:*:*:*:*:*:*:*",
"backstage=backstage":"cpe:2.3:a:linuxfoundation:backstage:*:*:*:*:*:*:*:*",
"metal3-io=baremetal-operator":"cpe:2.3:a:linuxfoundation:baremetal_operator:*:*:*:*:*:*:*:*",
"hyperledger=besu":"cpe:2.3:a:linuxfoundation:besu:*:*:*:*:*:*:*:*",
"[UNKNOWN]=ceph":"cpe:2.3:a:linuxfoundation:ceph:*:*:*:*:*:*:*:*",
"containerd=containerd":"cpe:2.3:a:linuxfoundation:containerd:*:*:*:*:*:*:*:*",
"cortexproject=cortex":"cpe:2.3:a:linuxfoundation:cortex:*:*:*:*:*:*:*:*",
"cubefs=cubefs":"cpe:2.3:a:linuxfoundation:cubefs:*:*:*:*:*:*:*:*",
"dapr=dapr":"cpe:2.3:a:linuxfoundation:dapr:*:*:*:*:*:*:*:*",
"dexidp=dex":"cpe:2.3:a:linuxfoundation:dex:*:*:*:*:*:*:*:*",
"fluid-cloudnative=fluid":"cpe:2.3:a:linuxfoundation:fluid:*:*:*:*:*:*:*:*",
"grpc=grpc-swift":"cpe:2.3:a:linuxfoundation:grpc_swift:*:*:*:*:*:*:*:*",
"Harbor=Project":"cpe:2.3:a:linuxfoundation:harbor:*:*:*:*:*:*:*:*",
"Hyperledger=Indy Node":"cpe:2.3:a:linuxfoundation:indy-node:*:*:*:*:*:*:*:*",
"hyperledger=indy-node":"cpe:2.3:a:linuxfoundation:indy-node:*:*:*:*:*:*:*:*",
"the Jager project=jaegertracing/jaeger":"cpe:2.3:a:linuxfoundation:jaeger:*:*:*:*:*:*:*:*",
"knative=func":"cpe:2.3:a:linuxfoundation:knative_func:*:*:*:*:*:*:*:*",
"kubeedge=kubeedge":"cpe:2.3:a:linuxfoundation:kubeedge:*:*:*:*:*:*:*:*",
"kubevela=kubevela":"cpe:2.3:a:linuxfoundation:kubevela:*:*:*:*:*:*:*:*",
"SUSE=kubewarden":"cpe:2.3:a:linuxfoundation:kubewarden-controller:*:*:*:*:*:*:*:*",
"lima-vm=lima":"cpe:2.3:a:linuxfoundation:lima:*:*:*:*:*:*:*:*",
"open-telemetry=opentelemetry-java-instrumentation":"cpe:2.3:a:linuxfoundation:opentelemetry_instrumentation_for_java:*:*:*:*:*:*:*:*",
"Facebook=Osquery":"cpe:2.3:a:linuxfoundation:osquery:*:*:*:*:*:*:*:*",
"Facebook=osquery":"cpe:2.3:a:linuxfoundation:osquery:*:*:*:*:*:*:*:*",
"osquery=osquery":"cpe:2.3:a:linuxfoundation:osquery:*:*:*:*:*:*:*:*",
"sigstore=rekor":"cpe:2.3:a:linuxfoundation:rekor:*:*:*:*:*:*:*:*",
"opencontainers=runc":"cpe:2.3:a:linuxfoundation:runc:*:*:*:*:*:*:*:*",
"Linux Foundation=Software for Open Networking in the Cloud (SONiC)":"cpe:2.3:a:linuxfoundation:software_for_open_networking_in_the_cloud:*:*:*:*:*:*:*:*",
"spinnaker=spinnaker":"cpe:2.3:a:linuxfoundation:spinnaker:*:*:*:*:*:*:*:*",
"theupdateframework=tuf":"cpe:2.3:a:linuxfoundation:the_update_framework:*:*:*:*:*:*:*:*",
"tremor-rs=tremor-runtime":"cpe:2.3:a:linuxfoundation:tremor:*:*:*:*:*:*:*:*",
"vitessio=vitess":"cpe:2.3:a:linuxfoundation:vitess:*:*:*:*:*:*:*:*",
"Open Mainframe Project=Zowe":"cpe:2.3:a:linuxfoundation:zowe:*:*:*:*:*:*:*:*",
"Open Mainframe Project=Zowe":"cpe:2.3:a:linuxfoundation:zowe_api_mediation_layer:*:*:*:*:*:*:*:*",
"lfs-book=make-ca":"cpe:2.3:a:linuxfromscratch:make-ca:*:*:*:*:*:*:*:*",
"LiquidFiles Pty Ltd=LiquidFiles":"cpe:2.3:a:liquidfiles:liquidfiles:*:*:*:*:*:*:*:*",
"Lisa Software=Florist Site":"cpe:2.3:a:lisayazilim:florist_site:*:*:*:*:*:*:*:*",
"LiteCart=LiteCart":"cpe:2.3:a:litecart:litecart:*:*:*:*:*:*:*:*",
"LittleBigRefresh=Bunkum":"cpe:2.3:a:littlebigfresh:bunkum:*:*:*:*:*:*:*:*",
"LiuWoodsCode=LiuOS":"cpe:2.3:a:liuos_project:liuos:*:*:*:*:*:*:*:*",
"liuwy-dlsdys=zhglxt":"cpe:2.3:a:liuwy-dlsdys:zhglxt:*:*:*:*:*:*:*:*",
"livehelperchat=livehelperchat/livehelperchat":"cpe:2.3:a:livehelperchat:live_helper_chat:*:*:*:*:*:*:*:*",
"livehelperchat=livehelperchat/livehelperchat":"cpe:2.3:a:livehelperchat:livehelperchat:*:*:*:*:*:*:*:*",
"LivelyWorks=Articart":"cpe:2.3:a:livelyworks:articart:*:*:*:*:*:*:*:*",
"Lawrence Livermore National Laboratory=msr-safe":"cpe:2.3:a:llnl:model_specific_registers-safe:*:*:*:*:*:*:*:*",
"roxlukas=LMeve":"cpe:2.3:a:lmeve_project:lmeve:*:*:*:*:*:*:*:*",
"SourceCodester=Loan Management System":"cpe:2.3:a:loan_management_system_project:loan_management_system:*:*:*:*:*:*:*:*",
"lobehub=lobe-chat":"cpe:2.3:a:lobehub:lobe_chat:*:*:*:*:*:*:*:*",
"SourceCodester=Local Service Search Engine Management System":"cpe:2.3:a:local_service_search_engine_management_system_project:local_service_search_engine_management_system:*:*:*:*:*:*:*:*",
"LOCKON CO.,LTD.=EC-CUBE":"cpe:2.3:a:lockon:ec-cube:*:*:*:*:*:*:*:*",
"unspecified=L  gico y Creativo":"cpe:2.3:a:logicoycreativo:logico_y_creativo:*:*:*:*:*:*:*:*",
"Logicool Co Ltd.=the installer of LOGICOOL CONNECTION UTILITY SOFTWARE":"cpe:2.3:a:logitech:connection_utility_software:*:*:*:*:*:*:*:*",
"Logicool Co Ltd.=the installer of LOGICOOL Game Software":"cpe:2.3:a:logitech:game_software:*:*:*:*:*:*:*:*",
"Logitech=Options":"cpe:2.3:a:logitech:options:*:*:*:*:*:*:*:*",
"unspecified=LogoStore":"cpe:2.3:a:logostore_project:logostore:*:*:*:*:*:*:*:*",
"lojban=jbovlaste":"cpe:2.3:a:lojban:jbovlaste:*:*:*:*:*:*:*:*",
"loklak=loklak":"cpe:2.3:a:loklak_project:loklak:*:*:*:*:*:*:*:*",
"Shaanxi Chanming Education Technology=Score Query System":"cpe:2.3:a:longmenedutech:score_query_system:*:*:*:*:*:*:*:*",
"d0c-s4vage=lookatme":"cpe:2.3:a:lookatme_project:lookatme:*:*:*:*:*:*:*:*",
"Kashipara=Dynamic Lab Management System":"cpe:2.3:a:lopalopa:dynamic_lab_management_system:*:*:*:*:*:*:*:*",
"Losant=Losant Arduino MQTT Client":"cpe:2.3:a:losant:arduino_mqtt_client:*:*:*:*:*:*:*:*",
"lovasoa=SQLpage":"cpe:2.3:a:lovasoa:sqlpage:*:*:*:*:*:*:*:*",
"lucee=Lucee":"cpe:2.3:a:lucee:lucee_server:*:*:*:*:*:*:*:*",
"lunary-ai=lunary-ai/lunary":"cpe:2.3:a:lunary:lunary:*:*:*:*:*:*:*:*",
"luyadev=luyadev/yii-helpers":"cpe:2.3:a:luya:yii-helpers:*:*:*:*:*:*:*:*",
"LXR Project=LXR":"cpe:2.3:a:lxr_project:lxr:*:*:*:*:*:*:*:*",
"LycheeOrg=Lychee":"cpe:2.3:a:lycheeorg:lychee:*:*:*:*:*:*:*:*",
"thisAAY=Lazy Mouse":"cpe:2.3:a:lzmouse:lazy_mouse:*:*:*:*:*:*:*:*",
"M-Files=Hubshare":"cpe:2.3:a:m-files:hubshare:*:*:*:*:*:*:*:*",
"M-Files=M-Files Client":"cpe:2.3:a:m-files:m-files:*:*:*:*:*:*:*:*",
"M-Files=M-Files Web":"cpe:2.3:a:m-files:m-files:*:*:*:*:*:*:*:*",
"M-Files=M-Files Client":"cpe:2.3:a:m-files:m-files_client:*:*:*:*:*:*:*:*",
"M-Files Corporation=M-Files Server":"cpe:2.3:a:m-files:m-files_server:*:*:*:*:*:*:*:*",
"M-Files=M-Files Desktop":"cpe:2.3:a:m-files:m-files_server:*:*:*:*:*:*:*:*",
"M-Files=M-Files New Web":"cpe:2.3:a:m-files:m-files_server:*:*:*:*:*:*:*:*",
"M-Files=M-Files Server":"cpe:2.3:a:m-files:m-files_server:*:*:*:*:*:*:*:*",
"M-Files=M-Files Web":"cpe:2.3:a:m-files:m-files_server:*:*:*:*:*:*:*:*",
"M-Files Corporation=M-Files Server":"cpe:2.3:a:m-files:server:*:*:*:*:*:*:*:*",
"m0ver=bible-online":"cpe:2.3:a:m0ver:bible-online:*:*:*:*:*:*:*:*",
"rofl0r=MacGeiger":"cpe:2.3:a:macgeiger_project:macgeiger:*:*:*:*:*:*:*:*",
"Macrium=Reflect":"cpe:2.3:a:macrium:reflect:*:*:*:*:*:*:*:*",
"Macroturk Software and Internet Technologies=Macro-Bel":"cpe:2.3:a:macroturk:macro-bel:*:*:*:*:*:*:*:*",
"foxcpp=maddy":"cpe:2.3:a:maddy_project:maddy:*:*:*:*:*:*:*:*",
"Ministry of Agreculture, Foresty and Fishery=Installer of Denshinouhin Check System (for Ministry of Agriculture, Forestry and Fisheries Nouson Seibi Jigyou) 2014 March Edition":"cpe:2.3:a:maff:denshinouhin_check_system:*:*:*:*:*:*:*:*",
"Mafiatic=Blue Server":"cpe:2.3:a:mafiatic:blue_server:*:*:*:*:*:*:*:*",
"CGI Script Market=MagazinegerZ":"cpe:2.3:a:magazinegerz_project:magazinegerz:*:*:*:*:*:*:*:*",
"MAIF=izanami":"cpe:2.3:a:maif:izanami:*:*:*:*:*:*:*:*",
"MailCleaner=MailCleaner":"cpe:2.3:a:mailcleaner:mailcleaner:*:*:*:*:*:*:*:*",
"mailcow=mailcow-dockerized":"cpe:2.3:a:mailcow:mailcow\:*:*:*:*:*:*:*:*",
"keitai-site.net=mailform":"cpe:2.3:a:mailform:mailform:*:*:*:*:*:*:*:*",
"Mailu=Mailu":"cpe:2.3:a:mailu:mailu:*:*:*:*:*:*:*:*",
"Maiwei=Safety Production Control Platform":"cpe:2.3:a:maiwei_safety_production_control_platform_project:maiwei_safety_production_control_platform:*:*:*:*:*:*:*:*",
"makedeb=mist":"cpe:2.3:a:makedeb:mist:*:*:*:*:*:*:*:*",
"makedeb=mprweb":"cpe:2.3:a:makedp:mprweb:*:*:*:*:*:*:*:*",
"unspecified=maku-boot":"cpe:2.3:a:maku:maku-boot:*:*:*:*:*:*:*:*",
"Malwarebytes=Antimalware":"cpe:2.3:a:malwarebytes:antimalware:*:*:*:*:*:*:*:*",
"man-group=dtale":"cpe:2.3:a:man:d-tale:*:*:*:*:*:*:*:*",
"Zoho=ManageEngine ServiceDesk":"cpe:2.3:a:manageengine:servicedesk:*:*:*:*:*:*:*:*",
"Mandelo=ssm_shiro_blog":"cpe:2.3:a:mandelo:ssm_shiro_blog:*:*:*:*:*:*:*:*",
"mansuf=mangadex-downloader":"cpe:2.3:a:mangadex-downloader_project:mangadex-downloader:*:*:*:*:*:*:*:*",
"Hometory Co.,Ltd=Mangboard commerce package":"cpe:2.3:a:mangboard:commerce:*:*:*:*:*:*:*:*",
"mantisbt=mantisbt":"cpe:2.3:a:mantisbt:mantisbt:*:*:*:*:*:*:*:*",
"ManyDesigns=Portofino":"cpe:2.3:a:manydesigns:portofino:*:*:*:*:*:*:*:*",
"mapfish=mapfish-print":"cpe:2.3:a:mapfish:print:*:*:*:*:*:*:*:*",
"marginalia=marginalia":"cpe:2.3:a:marginalia_project:marginalia:*:*:*:*:*:*:*:*",
"MarkAny=MaEPSBroker":"cpe:2.3:a:markany:maepsbroker:*:*:*:*:*:*:*:*",
"JP1016=Markdown-Electron":"cpe:2.3:a:markdown-electron_project:markdown-electron:*:*:*:*:*:*:*:*",
"markdown-it=markdown-it":"cpe:2.3:a:markdown-it_project:markdown-it:*:*:*:*:*:*:*:*",
"HackerOne=marked node module":"cpe:2.3:a:marked_project:marked:*:*:*:*:*:*:*:*",
"Antenna House=DMC HTMLFilter":"cpe:2.3:a:marklogic:marklogic:*:*:*:*:*:*:*:*",
"MarkText=MarkText":"cpe:2.3:a:marktext:marktext:*:*:*:*:*:*:*:*",
"Yuki Hattori=Marp":"cpe:2.3:a:marp:marp:*:*:*:*:*:*:*:*",
"CodeAstro=Internet Banking System":"cpe:2.3:a:martinmbithi:internet_banking_system:*:*:*:*:*:*:*:*",
"CodeAstro=Internet Banking System":"cpe:2.3:a:martmbithi:internet_banking_system:*:*:*:*:*:*:*:*",
"Marvell=QConvergeConsole":"cpe:2.3:a:marvell:qconvergeconsole:*:*:*:*:*:*:*:*",
"gopeak=MasterLab":"cpe:2.3:a:masterlab:masterlab:*:*:*:*:*:*:*:*",
"mate-desktop=atril":"cpe:2.3:a:mate-desktop:atril:*:*:*:*:*:*:*:*",
"mate-desktop=engrampa":"cpe:2.3:a:mate-desktop:engrampa:*:*:*:*:*:*:*:*",
"math.js=math.js":"cpe:2.3:a:mathjs:math.js:*:*:*:*:*:*:*:*",
"math.js=math.js":"cpe:2.3:a:mathjs_project:mathjs:*:*:*:*:*:*:*:*",
"turt2live=matrix-media-repo":"cpe:2.3:a:matrix-media-repo_project:matrix-media-repo:*:*:*:*:*:*:*:*",
"poljar=matrix-nio":"cpe:2.3:a:matrix-nio_project:matrix-nio:*:*:*:*:*:*:*:*",
"matrix-org=dendrite":"cpe:2.3:a:matrix:dendrite:*:*:*:*:*:*:*:*",
"matrix-org=matrix-hookshot":"cpe:2.3:a:matrix:hookshot:*:*:*:*:*:*:*:*",
"matrix-org=matrix-rust-sdk":"cpe:2.3:a:matrix:matrix-rust-sdk:*:*:*:*:*:*:*:*",
"matrix-org=sydent":"cpe:2.3:a:matrix:sydent:*:*:*:*:*:*:*:*",
"matrix-org=synapse":"cpe:2.3:a:matrix:synapse:*:*:*:*:*:*:*:*",
"Inside Secure=MatrixSSL":"cpe:2.3:a:matrixssl:matrixssl:*:*:*:*:*:*:*:*",
"matter-labs=era-compiler-vyper":"cpe:2.3:a:matter-labs:zkvyper:*:*:*:*:*:*:*:*",
"Mattermost =Mattermost":"cpe:2.3:a:mattermost:mattermost:*:*:*:*:*:*:*:*",
"Mattermost =Mattermost ":"cpe:2.3:a:mattermost:mattermost:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost":"cpe:2.3:a:mattermost:mattermost:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost App Framework":"cpe:2.3:a:mattermost:mattermost:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost Github Plugin":"cpe:2.3:a:mattermost:mattermost:*:*:*:*:*:*:*:*",
"Mattermost=Playbooks Plugin":"cpe:2.3:a:mattermost:mattermost:*:*:*:*:*:*:*:*",
"Mattermost =Mattermost Boards":"cpe:2.3:a:mattermost:mattermost_boards:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost":"cpe:2.3:a:mattermost:mattermost_desktop:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost Desktop":"cpe:2.3:a:mattermost:mattermost_desktop:*:*:*:*:*:*:*:*",
"Mattermost =Mattermost":"cpe:2.3:a:mattermost:mattermost_server:*:*:*:*:*:*:*:*",
"Mattermost =Mattermost ":"cpe:2.3:a:mattermost:mattermost_server:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost":"cpe:2.3:a:mattermost:mattermost_server:*:*:*:*:*:*:*:*",
"Mattermost=Mattermost Plugins":"cpe:2.3:a:mattermost:mattermost_server:*:*:*:*:*:*:*:*",
"Mattermost =Mattermost Playbooks":"cpe:2.3:a:mattermost:playbooks:*:*:*:*:*:*:*:*",
"Mava Software=Hotel Management System":"cpe:2.3:a:mava:hotel_management_system:*:*:*:*:*:*:*:*",
"MaximaTech=Portal Executivo":"cpe:2.3:a:maximatech:portal_executivo:*:*:*:*:*:*:*:*",
"Maxon=ERP":"cpe:2.3:a:maxonerp:maxon:*:*:*:*:*:*:*:*",
"Max Secure=Anti Virus Plus":"cpe:2.3:a:maxpcsecure:anti_virus_plus:*:*:*:*:*:*:*:*",
"Rumpus=FTP server":"cpe:2.3:a:maxum:rumpus:*:*:*:*:*:*:*:*",
"Mayan EDMS=Mayan EDMS":"cpe:2.3:a:mayan-edms:mayan_edms:*:*:*:*:*:*:*:*",
"mAyaNet=E-Commerce Software":"cpe:2.3:a:mayanets:e-commerce:*:*:*:*:*:*:*:*",
"open-formulieren=open-forms":"cpe:2.3:a:maykinmedia:open_forms:*:*:*:*:*:*:*:*",
"SourceCodester=Best Courier Management System":"cpe:2.3:a:mayuri_k:best_courier_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Best Courier Management System":"cpe:2.3:a:mayurik:best_courier_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Free and Open Source Inventory Management System":"cpe:2.3:a:mayurik:free_and_open_source_inventory_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Free Hospital Management System for Small Practices":"cpe:2.3:a:mayurik:free_hospital_management_system_for_small_practices:*:*:*:*:*:*:*:*",
"SourceCodester=Inventory Management System":"cpe:2.3:a:mayurik:inventory_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Hospital Management System":"cpe:2.3:a:mayurik:online_hospital_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Student Management System":"cpe:2.3:a:mayurik:online_student_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Tours & Travels Management System":"cpe:2.3:a:mayurik:online_tours_\&_travels_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Tours & Travels Management System":"cpe:2.3:a:mayurik:online_tours_\&travels_management_system:*:*:*:*:*:*:*:*",
"MB connect line=mbDIALUP":"cpe:2.3:a:mbconnectline:mbdialup:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Active Response ":"cpe:2.3:a:mcafee:active_response:*:*:*:*:*:*:*:*",
"Intel=Advanced Threat Defense (ATD)":"cpe:2.3:a:mcafee:advanced_threat_defense:*:*:*:*:*:*:*:*",
"McAfee, LLC= McAfee Advanced Threat Defense (ATD)":"cpe:2.3:a:mcafee:advanced_threat_defense:*:*:*:*:*:*:*:*",
"McAfee, LLC= McAfee Advanced Threat Defense (ATD) ":"cpe:2.3:a:mcafee:advanced_threat_defense:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Advanced Threat Defense (ATD)":"cpe:2.3:a:mcafee:advanced_threat_defense:*:*:*:*:*:*:*:*",
"McAfee=Advanced Threat Defense (ATD)":"cpe:2.3:a:mcafee:advanced_threat_defense:*:*:*:*:*:*:*:*",
"McAfee, LLC= McAfee Agent (MA)":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Agent":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Agent (MA)":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Agent":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Agent ePO extension":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee=McAfee Agent (MA) for Linux":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee=McAfee Agent (MA) non-Windows non-Windows versions ":"cpe:2.3:a:mcafee:agent:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Application and Change Control ":"cpe:2.3:a:mcafee:application_and_change_control:*:*:*:*:*:*:*:*",
"McAfee, LLC=Mcafee Application and Change Control (MACC)":"cpe:2.3:a:mcafee:application_and_change_control:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Application and Change Control (MACC)":"cpe:2.3:a:mcafee:application_and_change_control:*:*:*:*:*:*:*:*",
"Trellix=Application and Change Control":"cpe:2.3:a:mcafee:application_and_change_control:*:*:*:*:*:*:*:*",
"McAfee=Application and Change Control":"cpe:2.3:a:mcafee:application_change_control:*:*:*:*:*:*:*:*",
"McAfee=Common UI (CUI)":"cpe:2.3:a:mcafee:common_catalog:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Consumer Product Removal Tool":"cpe:2.3:a:mcafee:consumer_product_removal_tool:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Content Security Reporter (CSR)":"cpe:2.3:a:mcafee:content_security_reporter:*:*:*:*:*:*:*:*",
"McAfee=DLP ePO extension":"cpe:2.3:a:mcafee:data_loss_prevention:*:*:*:*:*:*:*:*",
"McAfee=Data Loss Prevention":"cpe:2.3:a:mcafee:data_loss_prevention:*:*:*:*:*:*:*:*",
"McAfee=Data Loss Prevention(DLP)":"cpe:2.3:a:mcafee:data_loss_prevention:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Data Loss Prevention (DLP) Discover":"cpe:2.3:a:mcafee:data_loss_prevention_discover:*:*:*:*:*:*:*:*",
"McAfee, LLC=DLP Endpoint ePO extension":"cpe:2.3:a:mcafee:data_loss_prevention_endpoint:*:*:*:*:*:*:*:*",
"McAfee=Data Loss Prevention (DLP) ePO extension":"cpe:2.3:a:mcafee:data_loss_prevention_endpoint:*:*:*:*:*:*:*:*",
"McAfee=Data Loss Prevention Endpoint (DLPe)":"cpe:2.3:a:mcafee:data_loss_prevention_endpoint:*:*:*:*:*:*:*:*",
"McAfee, LCC=McAfee Database Security (DAM)":"cpe:2.3:a:mcafee:database_security:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Database Security (DBSec)":"cpe:2.3:a:mcafee:database_security:*:*:*:*:*:*:*:*",
"McAfee=Database Security":"cpe:2.3:a:mcafee:database_security:*:*:*:*:*:*:*:*",
"McAfee=Drive Encryption (MDE)":"cpe:2.3:a:mcafee:drive_encryption:*:*:*:*:*:*:*:*",
"McAfee=McAfee Email Gateway (MEG)":"cpe:2.3:a:mcafee:email_gateway:*:*:*:*:*:*:*:*",
"McAfee, LLC=Endpoint Product Removal Tool":"cpe:2.3:a:mcafee:endpoint_product_removal_tool:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Endpoint Security (ENS)":"cpe:2.3:a:mcafee:endpoint_security:*:*:*:*:*:*:*:*",
"McAfee, LLC=Mcafee Endpoint Security (ENS)":"cpe:2.3:a:mcafee:endpoint_security:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Enterprise Security Manager (ESM)":"cpe:2.3:a:mcafee:enterprise_security_manager:*:*:*:*:*:*:*:*",
"Intel=ePO":"cpe:2.3:a:mcafee:epolicy_orchestrator:*:*:*:*:*:*:*:*",
"McAfee, LLC=ePolicy Orchestrator Cloud":"cpe:2.3:a:mcafee:epolicy_orchestrator:*:*:*:*:*:*:*:*",
"McAfee=ePolicy Orchestrator (ePO)":"cpe:2.3:a:mcafee:epolicy_orchestrator:*:*:*:*:*:*:*:*",
"McAfee=ePolicy Orchistrator (ePO)":"cpe:2.3:a:mcafee:epolicy_orchestrator:*:*:*:*:*:*:*:*",
"McAfee, LLC= McAfee FRP":"cpe:2.3:a:mcafee:file_and_removable_media_protection:*:*:*:*:*:*:*:*",
"McAfee=File & Removable Media Protection (FRP)":"cpe:2.3:a:mcafee:file_and_removable_media_protection:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee GetSusp":"cpe:2.3:a:mcafee:getsusp:*:*:*:*:*:*:*:*",
"McAfee=Live Safe":"cpe:2.3:a:mcafee:livesafe:*:*:*:*:*:*:*:*",
"Intel=McAfee Agent":"cpe:2.3:a:mcafee:mcafee_agent:*:*:*:*:*:*:*:*",
"McAfee=Threat Intelligence Exchange (TIE) Server":"cpe:2.3:a:mcafee:mcafee_threat_intelligence_exchange:*:*:*:*:*:*:*:*",
"McAfee=McAfee Web Gateway":"cpe:2.3:a:mcafee:mcafee_web_gateway:*:*:*:*:*:*:*:*",
"McAfee=McAfee Web Gateway (MWG)":"cpe:2.3:a:mcafee:mcafee_web_gateway:*:*:*:*:*:*:*:*",
"McAfee=Web Gateway":"cpe:2.3:a:mcafee:mcafee_web_gateway:*:*:*:*:*:*:*:*",
"McAfee,LLC=MVISION EDR":"cpe:2.3:a:mcafee:mvision_edr:*:*:*:*:*:*:*:*",
"McAfee LLC=MVISION Endpoint":"cpe:2.3:a:mcafee:mvision_endpoint:*:*:*:*:*:*:*:*",
"McAfee, LLC=MVISION Endpoint ePO extension   ":"cpe:2.3:a:mcafee:mvision_endpoint:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee MVISION Endpoint":"cpe:2.3:a:mcafee:mvision_endpoint:*:*:*:*:*:*:*:*",
"Mcafee=MVision Endpoint":"cpe:2.3:a:mcafee:mvision_endpoint:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee MVISION Endpoint Detection and Response ":"cpe:2.3:a:mcafee:mvision_endpoint_detection_and_response:*:*:*:*:*:*:*:*",
"McAfee=Network Data Loss Prevention":"cpe:2.3:a:mcafee:network_data_loss_prevention:*:*:*:*:*:*:*:*",
"McAfee=Network Data Loss Prevention (NDLP)":"cpe:2.3:a:mcafee:network_data_loss_prevention:*:*:*:*:*:*:*:*",
"McAfee=Network Security Management (NSM)":"cpe:2.3:a:mcafee:network_security_management:*:*:*:*:*:*:*:*",
"McAfee LLC=McAfee Network Security Manager (NSM)":"cpe:2.3:a:mcafee:network_security_manager:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Network Security Manager (NSM)":"cpe:2.3:a:mcafee:network_security_manager:*:*:*:*:*:*:*:*",
"McAfee=Network Security Management (NSM)":"cpe:2.3:a:mcafee:network_security_manager:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Policy Auditor":"cpe:2.3:a:mcafee:policy_auditor:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee TechCheck":"cpe:2.3:a:mcafee:techcheck:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee TechCheck":"cpe:2.3:a:mcafee:techcheck:*:*:*:*:*:*:*:*",
"McAfee,LLC=Threat Intelligence Exchange Server (TIE Server)":"cpe:2.3:a:mcafee:threat_intelligence_exchange_server:*:*:*:*:*:*:*:*",
"McAfee=Threat Intelligence Exchange Server (TIE Server) ":"cpe:2.3:a:mcafee:threat_intelligence_exchange_server:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Total Protection (MTP)":"cpe:2.3:a:mcafee:total_protection:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Total Protection (MTP) Trial":"cpe:2.3:a:mcafee:total_protection:*:*:*:*:*:*:*:*",
"McAfee=McAfee Total Protection (MTP)":"cpe:2.3:a:mcafee:total_protection:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Web Gateway (MWG)":"cpe:2.3:a:mcafee:web_gateway:*:*:*:*:*:*:*:*",
"McAfee,LLC=McAfee Web Gateway (MWG)":"cpe:2.3:a:mcafee:web_gateway:*:*:*:*:*:*:*:*",
"McAfee,LLC=Secure Web Gateway":"cpe:2.3:a:mcafee:web_gateway:*:*:*:*:*:*:*:*",
"McAfee=McAfee Web Gateway (MWG)":"cpe:2.3:a:mcafee:web_gateway:*:*:*:*:*:*:*:*",
"McAfee=Web Gateway(MWG)":"cpe:2.3:a:mcafee:web_gateway:*:*:*:*:*:*:*:*",
"McAfee, LLC=McAfee Web Advisor (WA)":"cpe:2.3:a:mcafee:webadvisor:*:*:*:*:*:*:*:*",
"McAfee=McAfee WebAdvisor":"cpe:2.3:a:mcafee:webadvisor:*:*:*:*:*:*:*:*",
"bspkrs=MCPMappingViewer":"cpe:2.3:a:mcp_mapping_viewer_project:mcp_mapping_viewer:*:*:*:*:*:*:*:*",
"MCU Technologies=MCUsystem":"cpe:2.3:a:mcusystem:mcusystem:*:*:*:*:*:*:*:*",
"Measuresoft =ScadaPro Server":"cpe:2.3:a:measuresoft:scadapro_server:*:*:*:*:*:*:*:*",
"Medart Health Services=Medart Notification Panel":"cpe:2.3:a:medart_notification_panel_project:medart_notification_panel:*:*:*:*:*:*:*:*",
"MedData=HBYS":"cpe:2.3:a:meddata:hbys:*:*:*:*:*:*:*:*",
"MedData=MedDataPACS ":"cpe:2.3:a:meddatapacs:meddatapacs:*:*:*:*:*:*:*:*",
"Unknown=Media Tags":"cpe:2.3:a:media-tags_project:media-tags:*:*:*:*:*:*:*:*",
"MediaArea=ZenLib":"cpe:2.3:a:mediaarea:zenlib:*:*:*:*:*:*:*:*",
"Media CP =Media Control Panel":"cpe:2.3:a:mediacp:media_control_panel:*:*:*:*:*:*:*:*",
"mediawiki=mediawiki":"cpe:2.3:a:mediawiki:mediawiki:*:*:*:*:*:*:*:*",
"SourceCodester=Medical Certificate Generator App":"cpe:2.3:a:medical_certificate_generator_app_project:medical_certificate_generator_app:*:*:*:*:*:*:*:*",
"SourceCodester=Medicine Tracker System":"cpe:2.3:a:medicine_tracker_system_project:medicine_tracker_system:*:*:*:*:*:*:*:*",
"meetecho=meetecho/janus-gateway":"cpe:2.3:a:meetecho:janus:*:*:*:*:*:*:*:*",
"megaease=easeprobe":"cpe:2.3:a:megaease:easeprobe:*:*:*:*:*:*:*:*",
"mehah=otclient":"cpe:2.3:a:mehah:otclient:*:*:*:*:*:*:*:*",
"meetyoucrop=big-whale":"cpe:2.3:a:meiyou:big_whale:*:*:*:*:*:*:*:*",
"melnaron=mel-spintax":"cpe:2.3:a:mel-spintax_project:mel-spintax:*:*:*:*:*:*:*:*",
"jucktnich=meldekarten-generator":"cpe:2.3:a:meldekarten_generator_project:meldekarten_generator:*:*:*:*:*:*:*:*",
"melisplatform=melis-asset-manager":"cpe:2.3:a:melistechnology:melis-asset-manager:*:*:*:*:*:*:*:*",
"melisplatform=melis-cms":"cpe:2.3:a:melistechnology:meliscms:*:*:*:*:*:*:*:*",
"melisplatform=melis-front":"cpe:2.3:a:melistechnology:meliscms:*:*:*:*:*:*:*:*",
"Nakiami=Mellivora":"cpe:2.3:a:mellivora_project:mellivora:*:*:*:*:*:*:*:*",
"WhiteSource=CureKit":"cpe:2.3:a:mend:curekit:*:*:*:*:*:*:*:*",
"Siemens=Mendix Excel Importer Module":"cpe:2.3:a:mendix:excel_importer:*:*:*:*:*:*:*:*",
"Siemens=Mendix Forgot Password Appstore module":"cpe:2.3:a:mendix:forgot_password:*:*:*:*:*:*:*:*",
"Siemens=Mendix Applications using Mendix 7":"cpe:2.3:a:mendix:mendix:*:*:*:*:*:*:*:*",
"Siemens=Mendix SAML Module":"cpe:2.3:a:mendix:saml:*:*:*:*:*:*:*:*",
"Mepsan=USC+":"cpe:2.3:a:mepsan:stawiz_usc\+\+:*:*:*:*:*:*:*:*",
"Mergen Software=Quality Management System":"cpe:2.3:a:mergentech:quality_management_system:*:*:*:*:*:*:*:*",
"Mesa Labs=AmegaView":"cpe:2.3:a:mesalabs:amegaview:*:*:*:*:*:*:*:*",
"Mestav Software=E-commerce Software":"cpe:2.3:a:mestav:e-commerce_software:*:*:*:*:*:*:*:*",
"Metabase, Inc.=Metabase":"cpe:2.3:a:metabase:metabase:*:*:*:*:*:*:*:*",
"metabase=metabase":"cpe:2.3:a:metabase:metabase:*:*:*:*:*:*:*:*",
"metal3-io=ironic-image":"cpe:2.3:a:metal3:ironic-image:*:*:*:*:*:*:*:*",
"metersphere=metersphere":"cpe:2.3:a:metersphere:metersphere:*:*:*:*:*:*:*:*",
"Ministry of Education, Culture, Sports, Science and Technology (MEXT)=EbidSettingChecker.exe":"cpe:2.3:a:mext:ebidsettingchecker:*:*:*:*:*:*:*:*",
"mgetty=mgetty":"cpe:2.3:a:mgetty_project:mgetty:*:*:*:*:*:*:*:*",
"MGT-COMMERCE=CloudPanel":"cpe:2.3:a:mgt-commerce:cloudpanel:*:*:*:*:*:*:*:*",
"examknow=MH-WikiBot":"cpe:2.3:a:mh-wikibot_project:mh-wikibot:*:*:*:*:*:*:*:*",
"Ministry of Health, Labour and Welfare=FD Application":"cpe:2.3:a:mhlw:fd_application:*:*:*:*:*:*:*:*",
"Xiaomi=Browser":"cpe:2.3:a:mi:mi6_browser:*:*:*:*:*:*:*:*",
"Xiaomi=Browser":"cpe:2.3:a:mi:mi_browser:*:*:*:*:*:*:*:*",
"Mia Technology Inc.=M  A-MED":"cpe:2.3:a:miateknoloji:mia-med:*:*:*:*:*:*:*:*",
"Mia Technology=Mia-Med":"cpe:2.3:a:miateknoloji:mia-med:*:*:*:*:*:*:*:*",
"Micro Focus=Vibe":"cpe:2.3:a:micro_focus:vibe:*:*:*:*:*:*:*:*",
"MicroEngine Inc.=MicroEngine Mailform":"cpe:2.3:a:microengine:mailform:*:*:*:*:*:*:*:*",
"SourceCodester=Microfinance Management System":"cpe:2.3:a:microfinance_management_system_project:microfinance_management_system:*:*:*:*:*:*:*:*",
"unspecified=Microfinance Management System":"cpe:2.3:a:microfinance_management_system_project:microfinance_management_system:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Access Manager":"cpe:2.3:a:microfocus:access_manager:*:*:*:*:*:*:*:*",
"Micro Focus International=AcuToWeb":"cpe:2.3:a:microfocus:acutoweb:*:*:*:*:*:*:*:*",
"Micro Focus International=ArcSight Logger":"cpe:2.3:a:microfocus:arcsight_logger:*:*:*:*:*:*:*:*",
"Micro Focus=ArcSight Logger":"cpe:2.3:a:microfocus:arcsight_logger:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus ArcSight Logger":"cpe:2.3:a:microfocus:arcsight_logger:*:*:*:*:*:*:*:*",
"ArcSight=Management Center":"cpe:2.3:a:microfocus:arcsight_management_center:*:*:*:*:*:*:*:*",
"Micro Focus=ArcSight Management Center":"cpe:2.3:a:microfocus:arcsight_management_center:*:*:*:*:*:*:*:*",
"Micro Focus=Content Manager":"cpe:2.3:a:microfocus:content_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus Content Manager":"cpe:2.3:a:microfocus:content_manager:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ eDirectory 9.1 SP2":"cpe:2.3:a:microfocus:edirectory:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ eDirectory Versions prior to 9.1.1 HF1":"cpe:2.3:a:microfocus:edirectory:*:*:*:*:*:*:*:*",
"Micro Focus=eDirectory (ZDI)":"cpe:2.3:a:microfocus:edirectory:*:*:*:*:*:*:*:*",
"Micro Focus=Filr":"cpe:2.3:a:microfocus:filr:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus Filr ":"cpe:2.3:a:microfocus:filr:*:*:*:*:*:*:*:*",
"OpenText=Filr":"cpe:2.3:a:microfocus:filr:*:*:*:*:*:*:*:*",
"opentext=Fortify ScanCentral DAST":"cpe:2.3:a:microfocus:fortify_scancentral_dast:*:*:*:*:*:*:*:*",
"Micro Focus =Micro Focus Fortify Software Security Center Server":"cpe:2.3:a:microfocus:fortify_software_security_center:*:*:*:*:*:*:*:*",
"Micro Focus=Fortify Software Security Center (SSC)":"cpe:2.3:a:microfocus:fortify_software_security_center:*:*:*:*:*:*:*:*",
"Micro Focus=GroupWise":"cpe:2.3:a:microfocus:groupwise:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus GroupWise Web":"cpe:2.3:a:microfocus:groupwise:*:*:*:*:*:*:*:*",
"Micro Fosus=Identity Manager ":"cpe:2.3:a:microfocus:identity_manager:*:*:*:*:*:*:*:*",
"Micro Focus=IDOL":"cpe:2.3:a:microfocus:idol:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ iManager 3.1 SP2":"cpe:2.3:a:microfocus:imanager:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Access Manager":"cpe:2.3:a:microfocus:netiq_access_manager:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Advanced Authentication Framework":"cpe:2.3:a:microfocus:netiq_advanced_authentication:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus NetIQ Self Service Password Reset.":"cpe:2.3:a:microfocus:netiq_self_service_password_reset:*:*:*:*:*:*:*:*",
"Micro Focus=Self Service Password Reset (SSPR)":"cpe:2.3:a:microfocus:netiq_self_service_password_reset:*:*:*:*:*:*:*:*",
"OES=Netstorage component of Open Enterprise Server":"cpe:2.3:a:microfocus:open_enterprise_server:*:*:*:*:*:*:*:*",
"Micro Focus International=Operations Agent":"cpe:2.3:a:microfocus:operations_agent:*:*:*:*:*:*:*:*",
"Micro Focus=Operations Manager i":"cpe:2.3:a:microfocus:operations_manager_i:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Project and Portfolio Management (PPM)":"cpe:2.3:a:microfocus:project_and_portfolio_management:*:*:*:*:*:*:*:*",
"Micro Focus=Project and Portfolio Management Center":"cpe:2.3:a:microfocus:project_and_portfolio_management:*:*:*:*:*:*:*:*",
"Micro Focus=Microfocus Real User Monitoring":"cpe:2.3:a:microfocus:real_user_monitoring:*:*:*:*:*:*:*:*",
"Micro Focus=Secure Messaging Gateway":"cpe:2.3:a:microfocus:secure_messaging_gateway:*:*:*:*:*:*:*:*",
"NetIQ Corporation=Sentinel":"cpe:2.3:a:microfocus:sentinel:*:*:*:*:*:*:*:*",
"Micro Focus International=Service Manager (Server).":"cpe:2.3:a:microfocus:service_manager:*:*:*:*:*:*:*:*",
"Micro Focus International=Service Manager (Web Tier).":"cpe:2.3:a:microfocus:service_manager:*:*:*:*:*:*:*:*",
"Micro Focus=HP Service Manager Software":"cpe:2.3:a:microfocus:service_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus Service Manager":"cpe:2.3:a:microfocus:service_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Service Manager":"cpe:2.3:a:microfocus:service_manager:*:*:*:*:*:*:*:*",
"Micro Focus International=Micro Focus - Service Manager Automation (SMA)":"cpe:2.3:a:microfocus:service_manager_automation:*:*:*:*:*:*:*:*",
"Micro Focus=Solutions Business Manager":"cpe:2.3:a:microfocus:solutions_business_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Solutions Business Manager (SBM)":"cpe:2.3:a:microfocus:solutions_business_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Solutions Business Manager 11.4":"cpe:2.3:a:microfocus:solutions_business_manager:*:*:*:*:*:*:*:*",
"NetIQ eDirectory=NetIQ Access Manager (NAM)":"cpe:2.3:a:microfocus:solutions_business_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus Universal CMDB":"cpe:2.3:a:microfocus:ucmdb_configuration_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Universal CMDB":"cpe:2.3:a:microfocus:ucmdb_configuration_manager:*:*:*:*:*:*:*:*",
"Micro Focus=UCMDB Browser":"cpe:2.3:a:microfocus:universal_cmbd_browser:*:*:*:*:*:*:*:*",
"Micro Focus=Universal CMDB Foundation Software":"cpe:2.3:a:microfocus:universal_cmdb_foundation_software:*:*:*:*:*:*:*:*",
"Micro Focus International=Micro Focus Vibe.":"cpe:2.3:a:microfocus:vibe:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus VisiBroker":"cpe:2.3:a:microfocus:visibroker:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft .NET Framework 4.6, 4.6.1, 4.6.2, and 4.7":"cpe:2.3:a:microsoft:.net_framework:*:*:*:*:*:*:*:*",
"Microsoft=3D Builder":"cpe:2.3:a:microsoft:3d_builder:*:*:*:*:*:*:*:*",
"Microsoft=3D Viewer":"cpe:2.3:a:microsoft:3d_viewer:*:*:*:*:*:*:*:*",
"Microsoft=Accessibility Insights for Android":"cpe:2.3:a:microsoft:accessibility_insights_for_android:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Accessibility Insights for Web":"cpe:2.3:a:microsoft:accessibility_insights_for_web:*:*:*:*:*:*:*:*",
"Microsoft=Application Inspector":"cpe:2.3:a:microsoft:application_inspector:*:*:*:*:*:*:*:*",
"Microsoft Corporation=ASP.NET Core":"cpe:2.3:a:microsoft:asp.net_core:*:*:*:*:*:*:*:*",
"Microsoft=.NET Core":"cpe:2.3:a:microsoft:asp.net_core:*:*:*:*:*:*:*:*",
"Microsoft=ASP.NET Core":"cpe:2.3:a:microsoft:asp.net_core:*:*:*:*:*:*:*:*",
"Microsoft Corporation=.NET Core":"cpe:2.3:a:microsoft:aspnetcore:*:*:*:*:*:*:*:*",
"Microsoft Corporation=ASP.NET":"cpe:2.3:a:microsoft:aspnetcore:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Authenticator":"cpe:2.3:a:microsoft:authenticator:*:*:*:*:*:*:*:*",
"Microsoft=AV1 Video Extension":"cpe:2.3:a:microsoft:av1_video_extension:*:*:*:*:*:*:*:*",
"Microsoft=azure-umqtt-c":"cpe:2.3:a:microsoft:azure-umqtt-c:*:*:*:*:*:*:*:*",
"Microsoft=Entra":"cpe:2.3:a:microsoft:azure_active_directory:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Azure AD Connect":"cpe:2.3:a:microsoft:azure_active_directory_connect:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Azure Active Directory Connect":"cpe:2.3:a:microsoft:azure_active_directory_connect:*:*:*:*:*:*:*:*",
"Azure=aad-pod-identity":"cpe:2.3:a:microsoft:azure_ad_pod_identity:*:*:*:*:*:*:*:*",
"Microsoft=Azure App":"cpe:2.3:a:microsoft:azure_app_service_on_azure_stack:*:*:*:*:*:*:*:*",
"Microsoft=Azure App Service on Azure Stack":"cpe:2.3:a:microsoft:azure_app_service_on_azure_stack:*:*:*:*:*:*:*:*",
"Microsoft=Azure App Service on Azure Stack Hub":"cpe:2.3:a:microsoft:azure_app_service_on_azure_stack:*:*:*:*:*:*:*:*",
"Microsoft=Azure Arc-Enabled Servers":"cpe:2.3:a:microsoft:azure_arc-enabled_servers:*:*:*:*:*:*:*:*",
"Microsoft=Azure Arc Jumpstart":"cpe:2.3:a:microsoft:azure_arc_jumpstart:*:*:*:*:*:*:*:*",
"Microsoft=Azure Automation":"cpe:2.3:a:microsoft:azure_automation:*:*:*:*:*:*:*:*",
"Microsoft=Azure Batch":"cpe:2.3:a:microsoft:azure_batch:*:*:*:*:*:*:*:*",
"Microsoft=Azure Connected Machine Agent":"cpe:2.3:a:microsoft:azure_connected_machine_agent:*:*:*:*:*:*:*:*",
"Microsoft=Azure CycleCloud 8.2.0":"cpe:2.3:a:microsoft:azure_cyclecloud:*:*:*:*:*:*:*:*",
"Microsoft=Azure Data Explorer":"cpe:2.3:a:microsoft:azure_data_explorer:*:*:*:*:*:*:*:*",
"Microsoft=Azure Functions":"cpe:2.3:a:microsoft:azure_functions:*:*:*:*:*:*:*:*",
"Microsoft=Azure HDInsight":"cpe:2.3:a:microsoft:azure_hdinsights:*:*:*:*:*:*:*:*",
"Azure=ipam":"cpe:2.3:a:microsoft:azure_ipam:*:*:*:*:*:*:*:*",
"Microsoft=Azure Kubernetes Service":"cpe:2.3:a:microsoft:azure_kubernetes_service:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Azure Kubernetes Service":"cpe:2.3:a:microsoft:azure_kubernetes_service:*:*:*:*:*:*:*:*",
"Microsoft=Azure Machine Learning":"cpe:2.3:a:microsoft:azure_machine_learning:*:*:*:*:*:*:*:*",
"Microsoft=Azure Machine Learning":"cpe:2.3:a:microsoft:azure_machine_learning_software_development_kit:*:*:*:*:*:*:*:*",
"Microsoft=Azure Network Watcher VM Extension":"cpe:2.3:a:microsoft:azure_network_watcher:*:*:*:*:*:*:*:*",
"Microsoft=Azure Pipelines Agent":"cpe:2.3:a:microsoft:azure_pipelines_agent:*:*:*:*:*:*:*:*",
"Microsoft=Azure RTOS GUIX Studio":"cpe:2.3:a:microsoft:azure_real_time_operating_system_guix_studio:*:*:*:*:*:*:*:*",
"Microsoft=Azure Real Time Operating System":"cpe:2.3:a:microsoft:azure_real_time_operating_system_guix_studio:*:*:*:*:*:*:*:*",
"Microsoft=Azure RTOS GUIX Studio":"cpe:2.3:a:microsoft:azure_rtos_guix_studio:*:*:*:*:*:*:*:*",
"azure-rtos=usbx":"cpe:2.3:a:microsoft:azure_rtos_usbx:*:*:*:*:*:*:*:*",
"Microsoft=Azure SDK for .Net":"cpe:2.3:a:microsoft:azure_sdk_for_.net:*:*:*:*:*:*:*:*",
"Microsoft=Azure SDK for Java":"cpe:2.3:a:microsoft:azure_sdk_for_java:*:*:*:*:*:*:*:*",
"Microsoft=Azure Service Connector":"cpe:2.3:a:microsoft:azure_service_connector:*:*:*:*:*:*:*:*",
"Microsoft=Azure Service Fabric Explorer":"cpe:2.3:a:microsoft:azure_service_fabric:*:*:*:*:*:*:*:*",
"Azure=setup-kubectl":"cpe:2.3:a:microsoft:azure_setup_kubectl:*:*:*:*:*:*:*:*",
"Microsoft=Azure Site Recovery":"cpe:2.3:a:microsoft:azure_site_recovery:*:*:*:*:*:*:*:*",
"Microsoft=Azure Site Recovery VMWare to Azure":"cpe:2.3:a:microsoft:azure_site_recovery:*:*:*:*:*:*:*:*",
"Microsoft=Azure Site Recovery VMWare to Azure":"cpe:2.3:a:microsoft:azure_site_recovery_vmware_to_azure:*:*:*:*:*:*:*:*",
"Microsoft=Azure Sphere":"cpe:2.3:a:microsoft:azure_sphere:*:*:*:*:*:*:*:*",
"Microsoft=Azure Stack":"cpe:2.3:a:microsoft:azure_stack:*:*:*:*:*:*:*:*",
"Microsoft=Azure Stack Hub":"cpe:2.3:a:microsoft:azure_stack_hub:*:*:*:*:*:*:*:*",
"Microsoft=Azure Storage Mover":"cpe:2.3:a:microsoft:azure_storage_mover:*:*:*:*:*:*:*:*",
"Azure=azure-uamqp-c":"cpe:2.3:a:microsoft:azure_uamqp:*:*:*:*:*:*:*:*",
"ReFirm Labs=binwalk":"cpe:2.3:a:microsoft:binwalk:*:*:*:*:*:*:*:*",
"Refirm Labs=binwalk":"cpe:2.3:a:microsoft:binwalk:*:*:*:*:*:*:*:*",
"Microsoft=Bond 9.0.1":"cpe:2.3:a:microsoft:bond:*:*:*:*:*:*:*:*",
"Microsoft Corporation=ChakraCore":"cpe:2.3:a:microsoft:chakracore:*:*:*:*:*:*:*:*",
"Microsoft=ChakraCore":"cpe:2.3:a:microsoft:chakracore:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Defender for IoT":"cpe:2.3:a:microsoft:defender_for_iot:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Defender Security Intelligence Updates":"cpe:2.3:a:microsoft:defender_security_intelligence_updates:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics 365":"cpe:2.3:a:microsoft:dynamics_365:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics 365 (on-premises)":"cpe:2.3:a:microsoft:dynamics_365:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics 365 (on-premises) version 8":"cpe:2.3:a:microsoft:dynamics_365:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics 365 (on-premises) version 9.0":"cpe:2.3:a:microsoft:dynamics_365:*:*:*:*:*:*:*:*",
"Microsoft=Dynamics 365 for Finance and Operations":"cpe:2.3:a:microsoft:dynamics_365_for_finance_and_operations:*:*:*:*:*:*:*:*",
"Microsoft=Dynamics 365 Sales":"cpe:2.3:a:microsoft:dynamics_365_sales:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics CRM 2015 (on-premises) version 7.0":"cpe:2.3:a:microsoft:dynamics_crm_2015:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics GP":"cpe:2.3:a:microsoft:dynamics_gp:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics NAV":"cpe:2.3:a:microsoft:dynamics_nav:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Dynamics NAV 2015":"cpe:2.3:a:microsoft:dynamics_nav:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Chakra Core":"cpe:2.3:a:microsoft:edge:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Edge":"cpe:2.3:a:microsoft:edge:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft ChakraCore":"cpe:2.3:a:microsoft:edge:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft Edge":"cpe:2.3:a:microsoft:edge:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Edge":"cpe:2.3:a:microsoft:edge:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Edge (Chromium-based)":"cpe:2.3:a:microsoft:edge:*:*:*:*:*:*:*:*",
"Adobe=Acrobat for Edge":"cpe:2.3:a:microsoft:edge_chromium:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Edge (Chromium-based)":"cpe:2.3:a:microsoft:edge_chromium:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Edge (Chromium-based) Updater":"cpe:2.3:a:microsoft:edge_chromium:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Edge for Android":"cpe:2.3:a:microsoft:edge_chromium:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Edge for iOS":"cpe:2.3:a:microsoft:edge_ios:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Endpoint Configuration Manager":"cpe:2.3:a:microsoft:endpoint_configuration_manager:*:*:*:*:*:*:*:*",
"Microsoft=Entra":"cpe:2.3:a:microsoft:entra_jira_sso_plugin:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft Office":"cpe:2.3:a:microsoft:excel_for_mac:*:*:*:*:*:*:*:*",
"Microsoft=FSLogix":"cpe:2.3:a:microsoft:fslogix:*:*:*:*:*:*:*:*",
"microsoftgraph=msgraph-sdk-php":"cpe:2.3:a:microsoft:graph:*:*:*:*:*:*:*:*",
"microsoftgraph=msgraph-sdk-php-core":"cpe:2.3:a:microsoft:graph:*:*:*:*:*:*:*:*",
"Microsoft=HEIF Image Extension":"cpe:2.3:a:microsoft:heif_image_extension:*:*:*:*:*:*:*:*",
"Microsoft=HEVC Video Extensions":"cpe:2.3:a:microsoft:hevc_video_extensions:*:*:*:*:*:*:*:*",
"Microsoft=HEVC Video Extensions":"cpe:2.3:a:microsoft:high_efficiency_video_coding:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Identity Linux Broker":"cpe:2.3:a:microsoft:identity_linux_broker:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Browser":"cpe:2.3:a:microsoft:internet_explorer:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Internet Explorer":"cpe:2.3:a:microsoft:internet_explorer:*:*:*:*:*:*:*:*",
"Microsoft=Internet Explorer 11":"cpe:2.3:a:microsoft:internet_explorer:*:*:*:*:*:*:*:*",
"Microsoft=Intune management extension":"cpe:2.3:a:microsoft:intune_management_extension:*:*:*:*:*:*:*:*",
"Microsoft=Visual Studio Code - Kubernetes Tools":"cpe:2.3:a:microsoft:kubernetes_tools:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Lync Server":"cpe:2.3:a:microsoft:lync:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Lync for Mac":"cpe:2.3:a:microsoft:lync_for_mac:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Lync":"cpe:2.3:a:microsoft:lync_for_mac:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Lync Server":"cpe:2.3:a:microsoft:lync_server:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Malware Protection Engine":"cpe:2.3:a:microsoft:malware_protection_engine:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Malware Protection Platform":"cpe:2.3:a:microsoft:malware_protection_platform:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft.Data.OData":"cpe:2.3:a:microsoft:microsoft.data.odata:*:*:*:*:*:*:*:*",
"Microsoft=Mono 6.12.0":"cpe:2.3:a:microsoft:mono:*:*:*:*:*:*:*:*",
"Microsoft=MPEG-2 Video Extension":"cpe:2.3:a:microsoft:mpeg-2_video_extension:*:*:*:*:*:*:*:*",
"Microsoft=common_utils.py":"cpe:2.3:a:microsoft:neural_network_intelligence:*:*:*:*:*:*:*:*",
"Microsoft=NuGetGallery":"cpe:2.3:a:microsoft:nugetgallery:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft Office":"cpe:2.3:a:microsoft:office:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Office":"cpe:2.3:a:microsoft:office:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Office 2019":"cpe:2.3:a:microsoft:office:*:*:*:*:*:*:*:*",
"Microsoft=Office app":"cpe:2.3:a:microsoft:office:*:*:*:*:*:*:*:*",
"Microsoft=Office 365 ProPlus":"cpe:2.3:a:microsoft:office_365_proplus:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Office":"cpe:2.3:a:microsoft:office_for_mac:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Office Online Server":"cpe:2.3:a:microsoft:office_online_server:*:*:*:*:*:*:*:*",
"Microsoft=Office Online Server":"cpe:2.3:a:microsoft:office_online_server:*:*:*:*:*:*:*:*",
"Microsoft=On-Prem Data Gateway":"cpe:2.3:a:microsoft:on-prem_data_gateway:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft On-Premises Data Gateway":"cpe:2.3:a:microsoft:on-premises_data_gateway:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft OneDrive":"cpe:2.3:a:microsoft:onedrive:*:*:*:*:*:*:*:*",
"Microsoft=OneDrive for Android":"cpe:2.3:a:microsoft:onedrive:*:*:*:*:*:*:*:*",
"Microsoft=The installer of Microsoft OneDrive":"cpe:2.3:a:microsoft:onedrive:*:*:*:*:*:*:*:*",
"microsoft=onefuzz":"cpe:2.3:a:microsoft:onefuzz:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft OneNote for Universal":"cpe:2.3:a:microsoft:onenote:*:*:*:*:*:*:*:*",
"Microsoft=Open Enclave SDK":"cpe:2.3:a:microsoft:open_enclave_software_development_kit:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft Outlook":"cpe:2.3:a:microsoft:outlook:*:*:*:*:*:*:*:*",
"Microsoft=Package Manager Configurations":"cpe:2.3:a:microsoft:package_manager_configurations:*:*:*:*:*:*:*:*",
"Microsoft=Paint 3D":"cpe:2.3:a:microsoft:paint_3d:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Photos":"cpe:2.3:a:microsoft:photos:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Power Apps":"cpe:2.3:a:microsoft:power_apps:*:*:*:*:*:*:*:*",
"Microsoft=Power BI Desktop":"cpe:2.3:a:microsoft:power_bi_report_server:*:*:*:*:*:*:*:*",
"Microsoft=Power BI Report Server":"cpe:2.3:a:microsoft:power_bi_report_server:*:*:*:*:*:*:*:*",
"Microsoft=Power BI Report Server - January 2023":"cpe:2.3:a:microsoft:power_bi_report_server:*:*:*:*:*:*:*:*",
"Microsoft=PowerBI-client JS SDK":"cpe:2.3:a:microsoft:powerbi-client_js_sdk:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft Office":"cpe:2.3:a:microsoft:powerpoint_for_mac:*:*:*:*:*:*:*:*",
"Microsoft=PowerShell Core":"cpe:2.3:a:microsoft:powershell_core:*:*:*:*:*:*:*:*",
"Microsoft=PowerShellGet 2.2.5":"cpe:2.3:a:microsoft:powershellget:*:*:*:*:*:*:*:*",
"Microsoft=Print 3D":"cpe:2.3:a:microsoft:print_3d:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Printer Metadata Troubleshooter Tool":"cpe:2.3:a:microsoft:printer_metadata_troubleshooter_tool:*:*:*:*:*:*:*:*",
"Microsoft=Rome SDK":"cpe:2.3:a:microsoft:project_rome:*:*:*:*:*:*:*:*",
"Microsoft=PsExec":"cpe:2.3:a:microsoft:psexec:*:*:*:*:*:*:*:*",
"Microsoft=Raw Image Extension":"cpe:2.3:a:microsoft:raw_image_extension:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Remote Desktop":"cpe:2.3:a:microsoft:remote_desktop:*:*:*:*:*:*:*:*",
"Microsoft=Remote Desktop Connection Manager 2.7":"cpe:2.3:a:microsoft:remote_desktop_connection_manager:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Research JavaScript Cryptography Library":"cpe:2.3:a:microsoft:research_javascript_cryptography_library:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Research JavaScript Cryptography Library V1.4":"cpe:2.3:a:microsoft:research_javascript_cryptography_library:*:*:*:*:*:*:*:*",
"Microsoft=Send Customer Voice survey from Dynamics 365":"cpe:2.3:a:microsoft:send_customer_voice_survey_from_dynamics_365:*:*:*:*:*:*:*:*",
"Microsoft=Send Customer Voice survey from Dynamics 365 app":"cpe:2.3:a:microsoft:send_customer_voice_survey_from_dynamics_365:*:*:*:*:*:*:*:*",
"Microsoft=Service Fabric":"cpe:2.3:a:microsoft:service_fabric:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft SharePoint":"cpe:2.3:a:microsoft:sharepoint_enterprise_server:*:*:*:*:*:*:*:*",
"Microsoft Corporation=SharePoint Server":"cpe:2.3:a:microsoft:sharepoint_enterprise_server:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft SharePoint":"cpe:2.3:a:microsoft:sharepoint_enterprise_server:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft SharePoint":"cpe:2.3:a:microsoft:sharepoint_enterprise_server_2016:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Microsoft SharePoint Enterprise Server 2016":"cpe:2.3:a:microsoft:sharepoint_server:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft SharePoint Server":"cpe:2.3:a:microsoft:sharepoint_server:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft SharePoint Server 2019":"cpe:2.3:a:microsoft:sharepoint_server:*:*:*:*:*:*:*:*",
"Microsoft=SQL Server Management Studio":"cpe:2.3:a:microsoft:sql_server_management_studio:*:*:*:*:*:*:*:*",
"Microsoft=SQL Server Management Studio 18.6":"cpe:2.3:a:microsoft:sql_server_management_studio:*:*:*:*:*:*:*:*",
"Microsoft=System Center 2016 Operations Manager":"cpe:2.3:a:microsoft:system_center_operations_manager:*:*:*:*:*:*:*:*",
"Microsoft=System Center 2019 Operations Manager":"cpe:2.3:a:microsoft:system_center_operations_manager:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Teams":"cpe:2.3:a:microsoft:teams:*:*:*:*:*:*:*:*",
"Microsoft=Teams":"cpe:2.3:a:microsoft:teams:*:*:*:*:*:*:*:*",
"Microsoft=The installer of Microsoft Teams":"cpe:2.3:a:microsoft:teams:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Visual Studio":"cpe:2.3:a:microsoft:visual_studio_2017:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Visual Studio 2017":"cpe:2.3:a:microsoft:visual_studio_2017:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Visual Studio 2017 version 15.9 (includes 15.0 - 15.8)":"cpe:2.3:a:microsoft:visual_studio_2017:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Visual Studio 2022 version 17.7":"cpe:2.3:a:microsoft:visual_studio_2022:*:*:*:*:*:*:*:*",
"Microsoft=Python extension for Visual Studio Code":"cpe:2.3:a:microsoft:visual_studio_code:*:*:*:*:*:*:*:*",
"Microsoft=The installer of Visual Studio Code":"cpe:2.3:a:microsoft:visual_studio_code:*:*:*:*:*:*:*:*",
"Microsoft=Visual Studio Code":"cpe:2.3:a:microsoft:visual_studio_code:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Visual Studio Code ESLint extension":"cpe:2.3:a:microsoft:visual_studio_code_eslint_extension:*:*:*:*:*:*:*:*",
"Microsoft=Visual Studio Code - GitHub Pull Requests and Issues Extension":"cpe:2.3:a:microsoft:visual_studio_code_github_pull_requests_and_issues:*:*:*:*:*:*:*:*",
"Microsoft=Visual Studio Code - Kubernetes Tools":"cpe:2.3:a:microsoft:visual_studio_code_kubernetes_tools:*:*:*:*:*:*:*:*",
"Microsoft=Visual Studio Code - npm-script Extension":"cpe:2.3:a:microsoft:visual_studio_code_npm-script_extension:*:*:*:*:*:*:*:*",
"Microsoft=The installer of Visual Studio Community":"cpe:2.3:a:microsoft:visual_studio_community:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Visual Studio Code Live Share extension":"cpe:2.3:a:microsoft:visual_studio_live_share:*:*:*:*:*:*:*:*",
"Microsoft=VP9 Video Extensions":"cpe:2.3:a:microsoft:vp9_video_extensions:*:*:*:*:*:*:*:*",
"Microsoft=Visual Studio Code - Maven for Java Extension":"cpe:2.3:a:microsoft:vscode-maven:*:*:*:*:*:*:*:*",
"Microsoft=Azure":"cpe:2.3:a:microsoft:walinuxagent:*:*:*:*:*:*:*:*",
"Microsoft=Web Media Extensions":"cpe:2.3:a:microsoft:web_media_extensions:*:*:*:*:*:*:*:*",
"Microsoft=WebP Image Extension":"cpe:2.3:a:microsoft:webp_image_extension:*:*:*:*:*:*:*:*",
"Microsoft=Windows Update Assistant":"cpe:2.3:a:microsoft:windows_10_update_assistant:*:*:*:*:*:*:*:*",
"Microsoft=Windows Admin Center":"cpe:2.3:a:microsoft:windows_admin_center:*:*:*:*:*:*:*:*",
"Microsoft=Windows Azure Pack Rollup 13.1":"cpe:2.3:a:microsoft:windows_azure_pack_rollup:*:*:*:*:*:*:*:*",
"Microsoft=Windows Defender Antimalware Platform":"cpe:2.3:a:microsoft:windows_defender:*:*:*:*:*:*:*:*",
"Microsoft=Windows Defender antimalware platform":"cpe:2.3:a:microsoft:windows_defender:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Defender Security Intelligence Updates":"cpe:2.3:a:microsoft:windows_defender_security_intelligence_updates:*:*:*:*:*:*:*:*",
"Microsoft=Windows Host Compute":"cpe:2.3:a:microsoft:windows_host_compute_service_shim:*:*:*:*:*:*:*:*",
"Microsoft=Windows Malicious Software Removal Tool":"cpe:2.3:a:microsoft:windows_malicious_software_removal_tool:*:*:*:*:*:*:*:*",
"Microsoft=Windows Sysmon":"cpe:2.3:a:microsoft:windows_sysmon:*:*:*:*:*:*:*:*",
"Microsoft=Windows Upgrade Assistant":"cpe:2.3:a:microsoft:windows_upgrade_assistant:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Office":"cpe:2.3:a:microsoft:word:*:*:*:*:*:*:*:*",
"Microsoft=Yammer Desktop App":"cpe:2.3:a:microsoft:yammer:*:*:*:*:*:*:*:*",
"microweber=microweber":"cpe:2.3:a:microweber:microweber:*:*:*:*:*:*:*:*",
"microweber=microweber/microweber":"cpe:2.3:a:microweber:microweber:*:*:*:*:*:*:*:*",
"microweber-dev=microweber-dev/whmcs_plugin":"cpe:2.3:a:microweber:whmcs:*:*:*:*:*:*:*:*",
"ETSI=TETRA Standard":"cpe:2.3:a:midnightblue:tetra\:*:*:*:*:*:*:*:*",
"Miek Gieben=miekg-dns":"cpe:2.3:a:miekg-dns_prject:miekg-dns:*:*:*:*:*:*:*:*",
"Miele=appWash":"cpe:2.3:a:miele:appwash:*:*:*:*:*:*:*:*",
"Miele=Benchmark Programming Tool":"cpe:2.3:a:miele:benchmark_programming_tool:*:*:*:*:*:*:*:*",
"Milesight=MilesightVPN":"cpe:2.3:a:milesight:milesightvpn:*:*:*:*:*:*:*:*",
"minio=console":"cpe:2.3:a:min:minio_console:*:*:*:*:*:*:*:*",
"1j01=mind-map":"cpe:2.3:a:mind-map_project:mind-map:*:*:*:*:*:*:*:*",
"mindsdb=mindsdb":"cpe:2.3:a:mindsdb:mindsdb:*:*:*:*:*:*:*:*",
"minetest=minetest":"cpe:2.3:a:minetest:minetest:*:*:*:*:*:*:*:*",
"mineweb=mineweb/minewebcms":"cpe:2.3:a:mineweb:minewebcms:*:*:*:*:*:*:*:*",
"MingoCommerce=WooCommerce Product Enquiry":"cpe:2.3:a:mingocommerce:woocommerce_product_enquiry:*:*:*:*:*:*:*:*",
"Mingsoft=MCMS":"cpe:2.3:a:mingsoft:mcms:*:*:*:*:*:*:*:*",
"mingw=mingw-w64":"cpe:2.3:a:mingw:mingw-w64:*:*:*:*:*:*:*:*",
"[UNKNOWN]=minicom":"cpe:2.3:a:minicom_project:minicom:*:*:*:*:*:*:*:*",
"minio=console":"cpe:2.3:a:minio:console:*:*:*:*:*:*:*:*",
"MinIO=minio":"cpe:2.3:a:minio:minio:*:*:*:*:*:*:*:*",
"minio=minio":"cpe:2.3:a:minio:minio:*:*:*:*:*:*:*:*",
"unspecified=MINMAX":"cpe:2.3:a:minmax:minmax:*:*:*:*:*:*:*:*",
"Minova Technology=eTrace":"cpe:2.3:a:minovateknoloji:etrace:*:*:*:*:*:*:*:*",
"mintplex-labs=mintplex-labs/anything-llm":"cpe:2.3:a:mintplexlabs:anything-llm:*:*:*:*:*:*:*:*",
"Mintplex-Labs=anything-llm":"cpe:2.3:a:mintplexlabs:anythingllm:*:*:*:*:*:*:*:*",
"mintplex-labs=mintplex-labs/anything-llm":"cpe:2.3:a:mintplexlabs:anythingllm:*:*:*:*:*:*:*:*",
"Mintplex-Labs=vector-admin":"cpe:2.3:a:mintplexlabs:vector_admin:*:*:*:*:*:*:*:*",
"miraheze=CreateWiki":"cpe:2.3:a:miraheze:createwiki:*:*:*:*:*:*:*:*",
"miraheze=DataDump":"cpe:2.3:a:miraheze:datadump:*:*:*:*:*:*:*:*",
"miraheze=ManageWiki":"cpe:2.3:a:miraheze:managewiki:*:*:*:*:*:*:*:*",
"miraheze=WikiDiscover":"cpe:2.3:a:miraheze:wikidiscover:*:*:*:*:*:*:*:*",
"Mirantis=bored-agent":"cpe:2.3:a:mirantis:bored-agent:*:*:*:*:*:*:*:*",
"Mirantis=Mirantis Container Cloud Lens Extension":"cpe:2.3:a:mirantis:container_cloud_lens_extension:*:*:*:*:*:*:*:*",
"Mirantis=Lens":"cpe:2.3:a:mirantis:lens:*:*:*:*:*:*:*:*",
"Mirantis=Mirantis Container Runtime":"cpe:2.3:a:mirantis:mirantis_container_runtime:*:*:*:*:*:*:*:*",
"Saleor=Saleor":"cpe:2.3:a:mirumee:saleor:*:*:*:*:*:*:*:*",
"mirumee=saleor-storefront":"cpe:2.3:a:mirumee:saleor:*:*:*:*:*:*:*:*",
"misskey-dev=misskey":"cpe:2.3:a:misskey:misskey:*:*:*:*:*:*:*:*",
"Mitake=Mitake Smart Stock Selection System":"cpe:2.3:a:mitake:smart_stock_selection:*:*:*:*:*:*:*:*",
"mitmproxy=mitmproxy":"cpe:2.3:a:mitmproxy:mitmproxy:*:*:*:*:*:*:*:*",
"CVEProject=cve-services":"cpe:2.3:a:mitre:cve-services:*:*:*:*:*:*:*:*",
"Mitsubishi Electric Europe B.V.=E-Designer":"cpe:2.3:a:mitsubishielectric:e-designer:*:*:*:*:*:*:*:*",
"Mitsubishi Electric=Mitsubishi Electric FR Configurator2":"cpe:2.3:a:mitsubishielectric:electric_fr_configurator2:*:*:*:*:*:*:*:*",
"Mitsubishi Electric Corporation=GX Works2":"cpe:2.3:a:mitsubishielectric:gx_works2:*:*:*:*:*:*:*:*",
"Mitsubishi Electric Corporation=GX Works3":"cpe:2.3:a:mitsubishielectric:gx_works3:*:*:*:*:*:*:*:*",
"Ministry of Land, Infrastructure, Transport and Tourism, Japan=National land numerical information data conversion tool":"cpe:2.3:a:mlit:national_land_numerical_information_data_conversion_tool:*:*:*:*:*:*:*:*",
"MLSoft=TCO!stream":"cpe:2.3:a:mlsoft:tco\!stream:*:*:*:*:*:*:*:*",
"brefphp=bref":"cpe:2.3:a:mnapoli:bref:*:*:*:*:*:*:*:*",
"MICRONET CORPORATION=INplc-RT":"cpe:2.3:a:mnc:inplc-rt:*:*:*:*:*:*:*:*",
"Mobatime=Mobatime web application":"cpe:2.3:a:mobatime:mobatime_web_application:*:*:*:*:*:*:*:*",
"Mobile Vikings=Django AJAX Utilities":"cpe:2.3:a:mobilevikings:django_ajax_utilities:*:*:*:*:*:*:*:*",
"MobiSoft=eharmony":"cpe:2.3:a:mobisoft_-_mobiplus_project:mobisoft_-_mobiplus:*:*:*:*:*:*:*:*",
"moby=buildkit":"cpe:2.3:a:mobyproject:buildkit:*:*:*:*:*:*:*:*",
"moby=hyperkit":"cpe:2.3:a:mobyproject:hyperkit:*:*:*:*:*:*:*:*",
"moby=moby":"cpe:2.3:a:mobyproject:moby:*:*:*:*:*:*:*:*",
"airtower-luna=mod_gnutls":"cpe:2.3:a:mod_gnutls_project:mod_gnutls:*:*:*:*:*:*:*:*",
"Modbus Tools=Modbus Poll":"cpe:2.3:a:modbustools:modbus_poll:*:*:*:*:*:*:*:*",
"Modbus Tools=Modbus Slave":"cpe:2.3:a:modbustools:modbus_slave:*:*:*:*:*:*:*:*",
"modoboa=modoboa/modoboa":"cpe:2.3:a:modoboa:modoboa:*:*:*:*:*:*:*:*",
"MODX Revolution=Gallery":"cpe:2.3:a:modx:modx_revolution:*:*:*:*:*:*:*:*",
"moxi624=Mogu Blog v2":"cpe:2.3:a:mogublog_project:mogublog:*:*:*:*:*:*:*:*",
"moinwiki=moin-1.9":"cpe:2.3:a:moinmo:moinmoin:*:*:*:*:*:*:*:*",
"The Ministry of Justice=Applicant Programme":"cpe:2.3:a:moj:applicant_programme:*:*:*:*:*:*:*:*",
"The Ministry of Justice=Installer of PDF Digital Signature Plugin":"cpe:2.3:a:moj:pdf_digital_signature:*:*:*:*:*:*:*:*",
"The Ministry of Justice=Installer of Shinseiyo Sogo Soft":"cpe:2.3:a:moj:shinseiyo_sogo_soft:*:*:*:*:*:*:*:*",
"MongoDB Inc=MongoDB Atlas Kubernetes Operator":"cpe:2.3:a:mongodb:atlas_kubernetes_operator:*:*:*:*:*:*:*:*",
"MongoDB Inc.=js-bson":"cpe:2.3:a:mongodb:js-bson:*:*:*:*:*:*:*:*",
"MongoDB Inc.=MongoDB Server":"cpe:2.3:a:mongodb:mongodb:*:*:*:*:*:*:*:*",
"MongoDB Inc.=MongoDB Enterprise Kubernetes Operator":"cpe:2.3:a:mongodb:mongodb_enterprise_kubernetes_operator:*:*:*:*:*:*:*:*",
"MongoDB Inc.=MongoDB Ops Manager":"cpe:2.3:a:mongodb:ops_manager:*:*:*:*:*:*:*:*",
"MongoDB Inc.=MongoDB Ops Manager":"cpe:2.3:a:mongodb:ops_manager_server:*:*:*:*:*:*:*:*",
"MongoDB Inc.=MongoDB Ops Manager ":"cpe:2.3:a:mongodb:ops_manager_server:*:*:*:*:*:*:*:*",
"SourceCodester=Monitoring of Students Cyber Accounts System":"cpe:2.3:a:monitoring_of_students_cyber_accounts_system_project:monitoring_of_students_cyber_accounts_system:*:*:*:*:*:*:*:*",
"directus=directus":"cpe:2.3:a:monospace:directus:*:*:*:*:*:*:*:*",
"Moodle=moodle":"cpe:2.3:a:moodle:moodle:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=Moodle":"cpe:2.3:a:moodle:moodle:*:*:*:*:*:*:*:*",
"The Moodle Project=moodle":"cpe:2.3:a:moodle:moodle:*:*:*:*:*:*:*:*",
"[UNKNOWN]=Moodle":"cpe:2.3:a:moodle:moodle:*:*:*:*:*:*:*:*",
"[UNKNOWN]=moodle":"cpe:2.3:a:moodle:moodle:*:*:*:*:*:*:*:*",
"SourceCodester=Moosikay E-Commerce System":"cpe:2.3:a:moosikay_e-commerce_system_project:moosikay_e-commerce_system:*:*:*:*:*:*:*:*",
"mooSocial=mooDating":"cpe:2.3:a:moosocial:moodating:*:*:*:*:*:*:*:*",
"mooSocial=mooStore":"cpe:2.3:a:moosocial:moostore:*:*:*:*:*:*:*:*",
"mooSocial=mooTravel":"cpe:2.3:a:moosocial:mootravel:*:*:*:*:*:*:*:*",
"mootools=mootools-core":"cpe:2.3:a:mootools:mootools:*:*:*:*:*:*:*:*",
"moses-smt=mosesdecoder":"cpe:2.3:a:moses-smt:mosesdecoder:*:*:*:*:*:*:*:*",
"mosparo=mosparo/mosparo":"cpe:2.3:a:mosparo:mosparo:*:*:*:*:*:*:*:*",
"unspecified=Movie Ticket Booking System":"cpe:2.3:a:movie_ticket_booking_system_project:movie_ticket_booking_system:*:*:*:*:*:*:*:*",
"Movim=Movim":"cpe:2.3:a:movim:movim:*:*:*:*:*:*:*:*",
"Movus=Movus":"cpe:2.3:a:movus:movus:*:*:*:*:*:*:*:*",
"Moxa=MXsecurity Series":"cpe:2.3:a:moxa:mxsecurity:*:*:*:*:*:*:*:*",
"ICS-CERT=Moxa MXview":"cpe:2.3:a:moxa:mxview:*:*:*:*:*:*:*:*",
"Moxa=MXView Series":"cpe:2.3:a:moxa:mxview:*:*:*:*:*:*:*:*",
"Moxa=MXview Network Management Software":"cpe:2.3:a:moxa:mxview:*:*:*:*:*:*:*:*",
"Moxa=ThingsPro IIoT Gateway and Device Management Software Solutions":"cpe:2.3:a:moxa:thingspro:*:*:*:*:*:*:*:*",
"Mozilla=Mozilla Bleach":"cpe:2.3:a:mozilla:bleach:*:*:*:*:*:*:*:*",
"Mozilla=Bugzilla":"cpe:2.3:a:mozilla:bugzilla:*:*:*:*:*:*:*:*",
"Mozilla=common-voice":"cpe:2.3:a:mozilla:common_voice:*:*:*:*:*:*:*:*",
"Mozilla=Firefox":"cpe:2.3:a:mozilla:firefox:*:*:*:*:*:*:*:*",
"Mozilla=geckodriver":"cpe:2.3:a:mozilla:geckodriver:*:*:*:*:*:*:*:*",
"Mozilla=Hubs Cloud":"cpe:2.3:a:mozilla:hubs_cloud:*:*:*:*:*:*:*:*",
"Mozilla=Hubs Cloud":"cpe:2.3:a:mozilla:hubs_cloud_reticulum:*:*:*:*:*:*:*:*",
"Mozilla=Mozilla VPN":"cpe:2.3:a:mozilla:mozilla_vpn:*:*:*:*:*:*:*:*",
"Mozilla=Network Security Services (NSS)":"cpe:2.3:a:mozilla:network_security_services:*:*:*:*:*:*:*:*",
"NSS project=nss":"cpe:2.3:a:mozilla:network_security_services:*:*:*:*:*:*:*:*",
"NSS=Network Security Services (NSS)":"cpe:2.3:a:mozilla:network_security_services:*:*:*:*:*:*:*:*",
"Mozilla=NSS":"cpe:2.3:a:mozilla:nss:*:*:*:*:*:*:*:*",
"mozilla=PollBot":"cpe:2.3:a:mozilla:pollbot:*:*:*:*:*:*:*:*",
"Mozilla=Thunderbird":"cpe:2.3:a:mozilla:thunderbird:*:*:*:*:*:*:*:*",
"Mozilla=Mozilla VPN":"cpe:2.3:a:mozilla:vpn:*:*:*:*:*:*:*:*",
"Mozilla=WebThings Gateway":"cpe:2.3:a:mozilla:webthings_gateway:*:*:*:*:*:*:*:*",
"mpedraza2020=Intranet del Monterroso":"cpe:2.3:a:mpedraza2020:intranet_del_monterroso:*:*:*:*:*:*:*:*",
"joniles=mpxj":"cpe:2.3:a:mpxj:mpxj:*:*:*:*:*:*:*:*",
"Amazing Little poll=Amazing Little poll":"cpe:2.3:a:mr-corner:amazing_little_poll:*:*:*:*:*:*:*:*",
"zmister2016=zmister2016/mrdoc":"cpe:2.3:a:mrdoc:mrdoc:*:*:*:*:*:*:*:*",
"mruby=mruby/mruby":"cpe:2.3:a:mruby:mruby:*:*:*:*:*:*:*:*",
"MRV Tech=Logging Administration Panel":"cpe:2.3:a:mrv:logging_administration_panel:*:*:*:*:*:*:*:*",
"rcmaehl=MSEdgeRedirect":"cpe:2.3:a:msedgeredirect_project:msedgeredirect:*:*:*:*:*:*:*:*",
"msyk=FMDataAPI":"cpe:2.3:a:msyk:fmdataapi:*:*:*:*:*:*:*:*",
"MTab=Bookmark":"cpe:2.3:a:mtab:bookmark:*:*:*:*:*:*:*:*",
"First Net Japan Inc.=EasyMail":"cpe:2.3:a:mubag:easymail:*:*:*:*:*:*:*:*",
"SourceCodester=Multi Language Hotel Management Software":"cpe:2.3:a:multi_language_hotel_management_software_project:multi_language_hotel_management_software:*:*:*:*:*:*:*:*",
"unspecified=multimon-ng":"cpe:2.3:a:multimon-ng_project:multimon-ng:*:*:*:*:*:*:*:*",
"Munsoft=Easy Archive Recovery":"cpe:2.3:a:munsoft:easy_archive_recovery:*:*:*:*:*:*:*:*",
"Munsoft=Easy Outlook Express Recovery":"cpe:2.3:a:munsoft:easy_outlook_express_recovery:*:*:*:*:*:*:*:*",
"Morgawr=Muon":"cpe:2.3:a:muon_project:muon:*:*:*:*:*:*:*:*",
"SourceCodester=Music Gallery Site":"cpe:2.3:a:music_gallery_site_project:music_gallery_site:*:*:*:*:*:*:*:*",
"zlatinb=muwire":"cpe:2.3:a:muwire_project:muwire:*:*:*:*:*:*:*:*",
"mikaelbr=mversion":"cpe:2.3:a:mversion_project:mversion:*:*:*:*:*:*:*:*",
"RainyGao=DocSys":"cpe:2.3:a:mxsdoc_project:mxsdoc:*:*:*:*:*:*:*:*",
"slawkens=MyAAC":"cpe:2.3:a:my-aac:myaac:*:*:*:*:*:*:*:*",
"zhenfeng13=My-Blog":"cpe:2.3:a:my-blog_project:my-blog:*:*:*:*:*:*:*:*",
"SourceCodester=My Food Recipe":"cpe:2.3:a:my_food_recipe_project:my_food_recipe:*:*:*:*:*:*:*:*",
"MyBB=MyBB":"cpe:2.3:a:mybb:mybb:*:*:*:*:*:*:*:*",
"mybb=mybb":"cpe:2.3:a:mybb:mybb:*:*:*:*:*:*:*:*",
"kizniche=Mycodo":"cpe:2.3:a:mycodo_project:mycodo:*:*:*:*:*:*:*:*",
"renlm=MyGraph":"cpe:2.3:a:mygraph_project:mygraph:*:*:*:*:*:*:*:*",
"mySCADA=myDESIGNER":"cpe:2.3:a:myscada:mydesigner:*:*:*:*:*:*:*:*",
"mySCADA Technologies=mySCADA myPRO":"cpe:2.3:a:myscada:mypro:*:*:*:*:*:*:*:*",
"mySCADA=myPRO":"cpe:2.3:a:myscada:mypro:*:*:*:*:*:*:*:*",
"Talos=MySql MMM":"cpe:2.3:a:mysql-mmm:mysql_multi-master_replication_manager:*:*:*:*:*:*:*:*",
"surveysolutions=surveysolutions":"cpe:2.3:a:mysurvey:survey_solutions:*:*:*:*:*:*:*:*",
"MZ Automation GmbH=libiec61850":"cpe:2.3:a:mz-automation:libiec61850:*:*:*:*:*:*:*:*",
"MZ Automation=libIEC61850":"cpe:2.3:a:mz-automation:libiec61850:*:*:*:*:*:*:*:*",
"MZ Automation=libiec61850":"cpe:2.3:a:mz-automation:libiec61850:*:*:*:*:*:*:*:*",
"mz-automation=libiec61850":"cpe:2.3:a:mz-automation:libiec61850:*:*:*:*:*:*:*:*",
"Nippon Institute of Agroinformatics Ltd.=SOY CMS":"cpe:2.3:a:n-i-agroinformatics:soy_cms:*:*:*:*:*:*:*:*",
"Nippon Institute of Agroinformatics Ltd.=SOY CMS with installer":"cpe:2.3:a:n-i-agroinformatics:soy_cms:*:*:*:*:*:*:*:*",
"TIS Inc.=Nablarch 5":"cpe:2.3:a:nablarch_project:nablarch:*:*:*:*:*:*:*:*",
"Nagios=NCPA":"cpe:2.3:a:nagios:nagios_cross_platform_agent:*:*:*:*:*:*:*:*",
"Nagios=Nagios XI":"cpe:2.3:a:nagios:nagios_xi:*:*:*:*:*:*:*:*",
"namelessmc=namelessmc/nameless":"cpe:2.3:a:namelessmc:nameless:*:*:*:*:*:*:*:*",
"nanopb=Nanopb":"cpe:2.3:a:nanopb_project:nanopb:*:*:*:*:*:*:*:*",
"nanopb=nanopb":"cpe:2.3:a:nanopb_project:nanopb:*:*:*:*:*:*:*:*",
"nanosvg library=nanosvg":"cpe:2.3:a:nanosvg_project:nanosvg:*:*:*:*:*:*:*:*",
"NASA=CFITSIO":"cpe:2.3:a:nasa:cfitsio:*:*:*:*:*:*:*:*",
"nasa=openmct ":"cpe:2.3:a:nasa:openmct:*:*:*:*:*:*:*:*",
"National Keep Cyber Security Services=CyberMath":"cpe:2.3:a:nationalkeep:cybermath:*:*:*:*:*:*:*:*",
"Natus Medical Incorporated=Natus":"cpe:2.3:a:natus:xltek_neuroworks:*:*:*:*:*:*:*:*",
"Talos=Natus":"cpe:2.3:a:natus:xltek_neuroworks:*:*:*:*:*:*:*:*",
"nautobot=nautobot-plugin-device-onboarding":"cpe:2.3:a:nautobot:nautobot-plugin-device-onboarding:*:*:*:*:*:*:*:*",
"Navarino=Infinity":"cpe:2.3:a:navarino:infinity:*:*:*:*:*:*:*:*",
"NAVER Corporation=NDrive":"cpe:2.3:a:naver:cloud_explorer:*:*:*:*:*:*:*:*",
"NAVER Corporation=Naver Cloud Explorer":"cpe:2.3:a:naver:cloud_explorer:*:*:*:*:*:*:*:*",
"NAVER=Naver Comic Viewer":"cpe:2.3:a:naver:comic_viewer:*:*:*:*:*:*:*:*",
"NAVER=NAVER Toolbar":"cpe:2.3:a:naver:toolbar:*:*:*:*:*:*:*:*",
"NAVER Corporation=Naver Vaccine":"cpe:2.3:a:naver:vaccine:*:*:*:*:*:*:*:*",
"NAVER Corporation=Whale Browser Installer":"cpe:2.3:a:naver:whale_browser_installer:*:*:*:*:*:*:*:*",
"NAVER Corporation=Whale Browser":"cpe:2.3:a:navercorp:whale:*:*:*:*:*:*:*:*",
"NAVER Corporation=Whale Browser Installer":"cpe:2.3:a:navercorp:whale:*:*:*:*:*:*:*:*",
"NAVER=NAVER Whale browser":"cpe:2.3:a:navercorp:whale:*:*:*:*:*:*:*:*",
"navidrome=navidrome":"cpe:2.3:a:navidrome:navidrome:*:*:*:*:*:*:*:*",
"NC3-LU=TestingPlatform":"cpe:2.3:a:nc3:testing_platform:*:*:*:*:*:*:*:*",
"Guangzhou Yingke Electronic Technology=Ncast":"cpe:2.3:a:ncast_project:ncast:*:*:*:*:*:*:*:*",
"NCSIST ManageEngine=MDM":"cpe:2.3:a:ncsist:mobile_device_manager:*:*:*:*:*:*:*:*",
"NCSOFT=NC Launcher2":"cpe:2.3:a:ncsoft:nc_launcher2:*:*:*:*:*:*:*:*",
"Hesburgh Libraries of Notre Dame=Sipity":"cpe:2.3:a:nd:sipity:*:*:*:*:*:*:*:*",
"solidusio=solidus":"cpe:2.3:a:nebulab:solidus:*:*:*:*:*:*:*:*",
"NEC=ESMPRO Manager":"cpe:2.3:a:nec:esmpro_manager:*:*:*:*:*:*:*:*",
"NEC Corporation=PC settings tool":"cpe:2.3:a:nec:pc_settings_tool:*:*:*:*:*:*:*:*",
"Necta LLC=WiFi Mouse (Mouse Server)":"cpe:2.3:a:necta:wifi_mouse_server:*:*:*:*:*:*:*:*",
"unspecified=Neetai Tech":"cpe:2.3:a:neetai:neetai_tech:*:*:*:*:*:*:*:*",
"neo4j-contrib=neo4j-apoc-procedures":"cpe:2.3:a:neo4j:awesome_procedures_on_cyper:*:*:*:*:*:*:*:*",
"neo4j=apoc":"cpe:2.3:a:neo4j:awesome_procedures_on_cyper:*:*:*:*:*:*:*:*",
"NEOJAPAN Inc.=Denbun POP version V3.3P R4.0 and earlier":"cpe:2.3:a:neo:debun_pop:*:*:*:*:*:*:*:*",
"sroehrl=neoan3-template":"cpe:2.3:a:neoan:neoan3-template:*:*:*:*:*:*:*:*",
"NeoMind=Fusion Platform":"cpe:2.3:a:neomind:fusion_platform:*:*:*:*:*:*:*:*",
"neos=form":"cpe:2.3:a:neos:form:*:*:*:*:*:*:*:*",
"Nesote=Inout Blockchain EasyPayments":"cpe:2.3:a:nesote:inout_blockchain_easypayments:*:*:*:*:*:*:*:*",
"Nesote=Inout Blockchain FiatExchanger":"cpe:2.3:a:nesote:inout_blockchain_fiatexchanger:*:*:*:*:*:*:*:*",
"Nesote=Inout Search Engine AI Edition":"cpe:2.3:a:nesote:inout_search_engine_ai_edition:*:*:*:*:*:*:*:*",
"NetApp =7-Mode Transition Tool":"cpe:2.3:a:netapp:7-mode_transition_tool:*:*:*:*:*:*:*:*",
"NetApp =AltaVault OST Plug-in":"cpe:2.3:a:netapp:altavault_ost_plug-in:*:*:*:*:*:*:*:*",
"NetApp =Clustered Data ONTAP":"cpe:2.3:a:netapp:clustered_data_ontap:*:*:*:*:*:*:*:*",
"NetApp=Clustered Data ONTAP":"cpe:2.3:a:netapp:clustered_data_ontap:*:*:*:*:*:*:*:*",
"NetApp=E-Series SANtricity OS Controller Software":"cpe:2.3:a:netapp:e-series_santricity_os_controller:*:*:*:*:*:*:*:*",
"NetApp=OnCommand Cloud Manager":"cpe:2.3:a:netapp:oncommand_cloud_manager:*:*:*:*:*:*:*:*",
"NetApp Inc.=OnCommand Insight":"cpe:2.3:a:netapp:oncommand_insight:*:*:*:*:*:*:*:*",
"NetApp=OnCommand Insight":"cpe:2.3:a:netapp:oncommand_insight:*:*:*:*:*:*:*:*",
"NetApp =OnCommand Unified Manager":"cpe:2.3:a:netapp:oncommand_unified_manager:*:*:*:*:*:*:*:*",
"NetApp =OnCommand Unified Manager for 7-Mode (core package)":"cpe:2.3:a:netapp:oncommand_unified_manager:*:*:*:*:*:*:*:*",
"NetApp=OnCommand Unified Manager for 7-Mode (core package)":"cpe:2.3:a:netapp:oncommand_unified_manager:*:*:*:*:*:*:*:*",
"NetApp=OnCommand Workflow Automation":"cpe:2.3:a:netapp:oncommand_workflow_automation:*:*:*:*:*:*:*:*",
"NetApp=ONTAP Mediator":"cpe:2.3:a:netapp:ontap_mediator:*:*:*:*:*:*:*:*",
"NetApp=ONTAP Select Deploy administration utility":"cpe:2.3:a:netapp:ontap_select_deploy_administration_utility:*:*:*:*:*:*:*:*",
"NetApp=SnapCenter":"cpe:2.3:a:netapp:snapcenter:*:*:*:*:*:*:*:*",
"NetApp =SnapCenter":"cpe:2.3:a:netapp:snapcenter_server:*:*:*:*:*:*:*:*",
"NetApp =SnapCenter Server":"cpe:2.3:a:netapp:snapcenter_server:*:*:*:*:*:*:*:*",
"NetApp=SnapCenter Server":"cpe:2.3:a:netapp:snapcenter_server:*:*:*:*:*:*:*:*",
"NetApp=SnapGathers ":"cpe:2.3:a:netapp:snapgathers:*:*:*:*:*:*:*:*",
"NetApp=StorageGRID (formerly StorageGRID Webscale)":"cpe:2.3:a:netapp:storagegrid:*:*:*:*:*:*:*:*",
"NetApp=StorageGRID (formerly StorageGRID Webscale) ":"cpe:2.3:a:netapp:storagegrid:*:*:*:*:*:*:*:*",
"NetApp=StorageGRID Webscale":"cpe:2.3:a:netapp:storagegrid_webscale:*:*:*:*:*:*:*:*",
"Netatalk=Netatalk":"cpe:2.3:a:netatalk:netatalk:*:*:*:*:*:*:*:*",
"The NetCommons Project=NetCommons":"cpe:2.3:a:netcommons:netcommons:*:*:*:*:*:*:*:*",
"netdata=netdata":"cpe:2.3:a:netdata:netdata:*:*:*:*:*:*:*:*",
"NetDataSoft=DivvyDrive":"cpe:2.3:a:netdatasoft:divvy_drive:*:*:*:*:*:*:*:*",
"Beijing Netcon=NS-ASG":"cpe:2.3:a:netentsec:application_security_gateway:*:*:*:*:*:*:*:*",
"Netentsec=NS-ASG Application Security Gateway":"cpe:2.3:a:netentsec:application_security_gateway:*:*:*:*:*:*:*:*",
"Netflix=Dial Reference Source Code Repo (https://github.com/Netflix/dial-reference)":"cpe:2.3:a:netflix:dial_reference:*:*:*:*:*:*:*:*",
"Netflix=dispatch":"cpe:2.3:a:netflix:dispatch:*:*:*:*:*:*:*:*",
"Netflix=Lemur":"cpe:2.3:a:netflix:lemur:*:*:*:*:*:*:*:*",
"NetGain Systems=NetGain Systems Enterprise Manager":"cpe:2.3:a:netgain-systems:enterprise_manager:*:*:*:*:*:*:*:*",
"Netgate=Netgate pfSense":"cpe:2.3:a:netgate:pfsense:*:*:*:*:*:*:*:*",
"NETGEAR=NETGEAR ProSAFE Network Management System":"cpe:2.3:a:netgear:prosafe_network_management_system:*:*:*:*:*:*:*:*",
"NETGEAR=ProSAFE Network Management System":"cpe:2.3:a:netgear:prosafe_network_management_system:*:*:*:*:*:*:*:*",
"NetHack=NetHack":"cpe:2.3:a:nethack:nethack:*:*:*:*:*:*:*:*",
"NethServer=phonenehome":"cpe:2.3:a:nethserver-phonenehome_project:nethserver-phonenehome:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Access Manager":"cpe:2.3:a:netiq:access_manager:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Access Manager, Administrative Console":"cpe:2.3:a:netiq:access_manager:*:*:*:*:*:*:*:*",
"NetIQ=Access Manager":"cpe:2.3:a:netiq:access_manager:*:*:*:*:*:*:*:*",
"NetIQ=NetIQ Access Manager (NAM) Admin Console":"cpe:2.3:a:netiq:access_manager:*:*:*:*:*:*:*:*",
"NetIQ= eDirectory":"cpe:2.3:a:netiq:edirectory:*:*:*:*:*:*:*:*",
"NetIQ=eDirectory":"cpe:2.3:a:netiq:edirectory:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Identity Manager":"cpe:2.3:a:netiq:identity_manager:*:*:*:*:*:*:*:*",
"NetIQ=IDM - Identity Applications":"cpe:2.3:a:netiq:identity_manager:*:*:*:*:*:*:*:*",
"NetIQ=Identity Manager":"cpe:2.3:a:netiq:identity_manager:*:*:*:*:*:*:*:*",
"NetIQ=Identity Manager Applications":"cpe:2.3:a:netiq:identity_manager:*:*:*:*:*:*:*:*",
"NetIQ=Identity Manager Oracle EBS driver":"cpe:2.3:a:netiq:identity_manager:*:*:*:*:*:*:*:*",
"NetIQ=Identity Manager Plug-ins":"cpe:2.3:a:netiq:identity_manager:*:*:*:*:*:*:*:*",
"NetIQ=Identity Reporting":"cpe:2.3:a:netiq:identity_reporting:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ iManager":"cpe:2.3:a:netiq:imanager:*:*:*:*:*:*:*:*",
"NetIQ=iManager":"cpe:2.3:a:netiq:imanager:*:*:*:*:*:*:*:*",
"Micro Focus=NetIQ Privileged Account Manager (PAM) 3.1 and 3.2":"cpe:2.3:a:netiq:privileged_account_manager:*:*:*:*:*:*:*:*",
"Micro Focus=Micro Focus NetIQ Self Service Password Reset.":"cpe:2.3:a:netiq:self_service_password_reset:*:*:*:*:*:*:*:*",
"Netpbm=netpbm":"cpe:2.3:a:netpbm_project:netpbm:*:*:*:*:*:*:*:*",
"NetSarang=XFTP":"cpe:2.3:a:netsarang:xftp:*:*:*:*:*:*:*:*",
"Netskope=NSClient":"cpe:2.3:a:netskope:netskope:*:*:*:*:*:*:*:*",
"Netskope=Netskope client":"cpe:2.3:a:netskope:netskope:*:*:*:*:*:*:*:*",
"nette=latte":"cpe:2.3:a:nette:latte:*:*:*:*:*:*:*:*",
"[UNKNOWN]=nettle":"cpe:2.3:a:nettle_project:nettle:*:*:*:*:*:*:*:*",
"netty=netty-incubator-codec-ohttp":"cpe:2.3:a:netty:netty-incubator-codec-ohttp:*:*:*:*:*:*:*:*",
"netty=netty":"cpe:2.3:a:netty:netty:*:*:*:*:*:*:*:*",
"NetVision Information=airPASS":"cpe:2.3:a:netvision:airpass:*:*:*:*:*:*:*:*",
"Network Optix=NxCloud":"cpe:2.3:a:networkoptix:nxcloud:*:*:*:*:*:*:*:*",
"nautobot=nautobot":"cpe:2.3:a:networktocode:nautobot:*:*:*:*:*:*:*:*",
"neutrinolabs=xrdp":"cpe:2.3:a:neutrinolabs:xrdp:*:*:*:*:*:*:*:*",
"Neutron=Neutron Smart VMS":"cpe:2.3:a:neutron:smart_vms:*:*:*:*:*:*:*:*",
"jja8=NewBingGoGo":"cpe:2.3:a:newbinggogo_project:newbinggogo:*:*:*:*:*:*:*:*",
"Red Hat=newlib":"cpe:2.3:a:newlib_project:newlib:*:*:*:*:*:*:*:*",
"nexB=scancode.io":"cpe:2.3:a:nexb:scancode.io:*:*:*:*:*:*:*:*",
"Nextcloud=Nextcloud Calendar application":"cpe:2.3:a:nextcloud:calendar:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:calendar:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:circles:*:*:*:*:*:*:*:*",
"Nextcloud=Nextcloud Contacts application":"cpe:2.3:a:nextcloud:contacts:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:contacts:*:*:*:*:*:*:*:*",
"nextcloud=cookbook":"cpe:2.3:a:nextcloud:cookbook:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:deck:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:desktop:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:end-to-end_encryption:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:files_access_control:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:global_site_selector:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:guests:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:mail:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:nextcloud_mail:*:*:*:*:*:*:*:*",
"HackerOne=Nextcloud Server":"cpe:2.3:a:nextcloud:nextcloud_server:*:*:*:*:*:*:*:*",
"NextCloud=nextcloud/server":"cpe:2.3:a:nextcloud:nextcloud_server:*:*:*:*:*:*:*:*",
"Nextcloud=Nextcloud Server":"cpe:2.3:a:nextcloud:nextcloud_server:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:nextcloud_server:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:nextcloud_talk:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:officeonline:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:password_policy:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:richdocuments:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:server:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:sso_\&_saml_authentication:*:*:*:*:*:*:*:*",
"NextCloud=nextcloud/talk":"cpe:2.3:a:nextcloud:talk:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:talk:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:user_oidc:*:*:*:*:*:*:*:*",
"nextcloud=security-advisories":"cpe:2.3:a:nextcloud:zipper:*:*:*:*:*:*:*:*",
"NFine=Rapid Development Platform":"cpe:2.3:a:nfine:nfine_rapid_development_platform:*:*:*:*:*:*:*:*",
"NFine=Rapid Development Platform":"cpe:2.3:a:nfine_rapid_development_platform_project:nfine_rapid_development_platform:*:*:*:*:*:*:*:*",
"ummmmm=nflpick-em.com":"cpe:2.3:a:nflpick-em:nflpick-em:*:*:*:*:*:*:*:*",
"nhn=tui.grid":"cpe:2.3:a:nhn:toast_ui_grid:*:*:*:*:*:*:*:*",
"NHN=TOAST UI Chart":"cpe:2.3:a:nhncloud:toast_ui_chart:*:*:*:*:*:*:*:*",
"NI Consulting CO.,Ltd.=Sales Force Assistant":"cpe:2.3:a:ni-consul:sales_force_assistant:*:*:*:*:*:*:*:*",
"National Instruments=LabVIEW 2016":"cpe:2.3:a:ni:labview:*:*:*:*:*:*:*:*",
"National Instruments=LabVIEW 2016 Evaluation":"cpe:2.3:a:ni:labview:*:*:*:*:*:*:*:*",
"NI=MeasurementLink ":"cpe:2.3:a:ni:measurementlink:*:*:*:*:*:*:*:*",
"RRJ=Nueva Ecija Engineer Online Portal":"cpe:2.3:a:nia:rrj_nueva_ecija_engineer_online_portal:*:*:*:*:*:*:*:*",
"cz.nic=knot-resolver":"cpe:2.3:a:nic:knot_resolver:*:*:*:*:*:*:*:*",
"NIKON=NIS-Elements Viewer":"cpe:2.3:a:nikon:nis-elements_viewer:*:*:*:*:*:*:*:*",
"nikooo777=ckSurf":"cpe:2.3:a:nikooo777:cksurf:*:*:*:*:*:*:*:*",
"National Institute for Land and Infrastructure Management=Douro Kouji Kanseizutou Check Program":"cpe:2.3:a:nilim:road_construction_completion_diagram_check_program:*:*:*:*:*:*:*:*",
"nim-lang=security":"cpe:2.3:a:nim-lang:nim:*:*:*:*:*:*:*:*",
"nimble-platform=common":"cpe:2.3:a:nimble-project:common:*:*:*:*:*:*:*:*",
"kyverno=kyverno":"cpe:2.3:a:nirmata:kyverno:*:*:*:*:*:*:*:*",
"Niushop=B2B2C":"cpe:2.3:a:niushop:b2b2c_multi-business:*:*:*:*:*:*:*:*",
"NixOS=calamares-nixos-extensions":"cpe:2.3:a:nixos:calamares-nixos-extensions:*:*:*:*:*:*:*:*",
"NLnet Labs=bcder":"cpe:2.3:a:nlnetlabs:bcder:*:*:*:*:*:*:*:*",
"NLnet Labs=Krill":"cpe:2.3:a:nlnetlabs:krill:*:*:*:*:*:*:*:*",
"NLnet Labs=Routinator":"cpe:2.3:a:nlnetlabs:routinator:*:*:*:*:*:*:*:*",
"nltk=nltk":"cpe:2.3:a:nltk:nltk:*:*:*:*:*:*:*:*",
"nltk=nltk/nltk":"cpe:2.3:a:nltk:nltk:*:*:*:*:*:*:*:*",
"Multiple Vendors All trailer power line communications are affected.=Trailer Power Line Communications":"cpe:2.3:a:nmfc:power_line_communications:*:*:*:*:*:*:*:*",
"TicklishHoneyBee=nodau":"cpe:2.3:a:nodau_project:nodau:*:*:*:*:*:*:*:*",
"khodakhah=NodCMS":"cpe:2.3:a:nodcms:nodcms:*:*:*:*:*:*:*:*",
"skoranga=node-dns-sync":"cpe:2.3:a:node-dns-sync_project:node-dns-sync:*:*:*:*:*:*:*:*",
"PeterMu=nodebatis":"cpe:2.3:a:nodebatis_project:nodebatis:*:*:*:*:*:*:*:*",
"NodeBB, Inc.=NodeBB":"cpe:2.3:a:nodebb:nodebb:*:*:*:*:*:*:*:*",
"NodeBB=NodeBB":"cpe:2.3:a:nodebb:nodebb:*:*:*:*:*:*:*:*",
"unspecified=NodeBB":"cpe:2.3:a:nodebb:nodebb:*:*:*:*:*:*:*:*",
"Node.js=Node.js":"cpe:2.3:a:nodejs:node.js:*:*:*:*:*:*:*:*",
"The Node.js Project=Node.js":"cpe:2.3:a:nodejs:node.js:*:*:*:*:*:*:*:*",
"unspecified=node-red-dashboard":"cpe:2.3:a:nodered:node-red-dashboard:*:*:*:*:*:*:*:*",
"youngerheart=nodeserver":"cpe:2.3:a:nodeserver_project:nodeserver:*:*:*:*:*:*:*:*",
"paijp=nophp":"cpe:2.3:a:nophp_project:nophp:*:*:*:*:*:*:*:*",
"Talos=NordVPN":"cpe:2.3:a:nordvpn:nordvpn:*:*:*:*:*:*:*:*",
"NUKnightLab=TimelineJS3":"cpe:2.3:a:northwestern:timelinejs:*:*:*:*:*:*:*:*",
"Symantec=Norton Power Eraser":"cpe:2.3:a:norton:power_eraser:*:*:*:*:*:*:*:*",
"Symantec Corporation=Norton Remove & Reinstall":"cpe:2.3:a:norton:remove_\&_reinstall:*:*:*:*:*:*:*:*",
"notaryproject=specifications":"cpe:2.3:a:notaryproject:notation-go:*:*:*:*:*:*:*:*",
"cxasm=notepad--":"cpe:2.3:a:notepad--_project:notepad--:*:*:*:*:*:*:*:*",
"notepad-plus-plus=notepad-plus-plus":"cpe:2.3:a:notepad-plus-plus:notepad\+\+:*:*:*:*:*:*:*:*",
"nothings=stb":"cpe:2.3:a:nothings:stb_image.h:*:*:*:*:*:*:*:*",
"nothings=stb":"cpe:2.3:a:nothings:stb_vorbis.c:*:*:*:*:*:*:*:*",
"Notifyvisitors=NotifyVisitors":"cpe:2.3:a:notifyvisitors:notifyvisitors:*:*:*:*:*:*:*:*",
"notrinos=notrinos/notrinoserp":"cpe:2.3:a:notrinos:notrinoserp:*:*:*:*:*:*:*:*",
"Novell=eDirectory":"cpe:2.3:a:novell:edirectory:*:*:*:*:*:*:*:*",
"novuhq=novu":"cpe:2.3:a:novu:novu:*:*:*:*:*:*:*:*",
"ConsoleTVs=Noxen":"cpe:2.3:a:noxen_project:noxen:*:*:*:*:*:*:*:*",
"Nozbe=WatermelonDB":"cpe:2.3:a:nozbe:watermelondb:*:*:*:*:*:*:*:*",
"National Renewable Energy Laboratory (NREL)=EnergyPlus":"cpe:2.3:a:nrel:energyplus:*:*:*:*:*:*:*:*",
"NationalSecurityAgency=emissary":"cpe:2.3:a:nsa:emissary:*:*:*:*:*:*:*:*",
"Nsasoft=NBMonitor Network Bandwidth Monitor":"cpe:2.3:a:nsasoft:network_bandwidth_monitor:*:*:*:*:*:*:*:*",
"Nsasoft=Network Sleuth":"cpe:2.3:a:nsasoft:network_sleuth:*:*:*:*:*:*:*:*",
"Nsasoft=Product Key Explorer":"cpe:2.3:a:nsasoft:product_key_explorer:*:*:*:*:*:*:*:*",
"Nsasoft=ShareAlarmPro":"cpe:2.3:a:nsasoft:sharealarmpro:*:*:*:*:*:*:*:*",
"National Tax Agency=Setup file of advance preparation for e-Tax software (WEB version)":"cpe:2.3:a:nta:e-tax:*:*:*:*:*:*:*:*",
"National Tax Agency=e-Tax software":"cpe:2.3:a:nta:e-tax:*:*:*:*:*:*:*:*",
"NTPsec=ntpsec":"cpe:2.3:a:ntpsec:ntpsec:*:*:*:*:*:*:*:*",
"NTT TechnoCross Corporation=Installer of MagicConnect Client program":"cpe:2.3:a:ntt-tx:magicconnect:*:*:*:*:*:*:*:*",
"NIPPON TELEGRAPH AND TELEPHONE WEST CORPORATION=Flets Install Tool":"cpe:2.3:a:ntt:flets_install_tool:*:*:*:*:*:*:*:*",
"NIPPON TELEGRAPH AND TELEPHONE WEST CORPORATION=Security Kinou Mihariban":"cpe:2.3:a:ntt:security_kinou_mihariban:*:*:*:*:*:*:*:*",
"NIPPON TELEGRAPH AND TELEPHONE WEST CORPORATION=Security Setup Tool":"cpe:2.3:a:ntt:security_setup_tool:*:*:*:*:*:*:*:*",
"NTT DOCOMO, INC.=Photo Collection PC Software":"cpe:2.3:a:nttdocomo:photo_collection_pc_software:*:*:*:*:*:*:*:*",
"nuitka=nuitka/nuitka":"cpe:2.3:a:nuitka:nuitka:*:*:*:*:*:*:*:*",
"NukeViet=CMS":"cpe:2.3:a:nukeviet:nukeviet:*:*:*:*:*:*:*:*",
"NUUO=NUUO CMS":"cpe:2.3:a:nuuo:nuuo_cms:*:*:*:*:*:*:*:*",
"caronc=apprise":"cpe:2.3:a:nuxref:apprise:*:*:*:*:*:*:*:*",
"nuxt=nuxt/framework":"cpe:2.3:a:nuxt:nuxt:*:*:*:*:*:*:*:*",
"nuxt=nuxt/nuxt":"cpe:2.3:a:nuxt:nuxt:*:*:*:*:*:*:*:*",
"nuxtlabs=nuxtlabs/github-module":"cpe:2.3:a:nuxtlabs:nuxt:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA Data Center GPU Manager (DCGM)":"cpe:2.3:a:nvidia:data_center_gpu_manager:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA FLARE":"cpe:2.3:a:nvidia:federated_learning_application_runtime_environment:*:*:*:*:*:*:*:*",
"NVIDIA Corporation=GeForce Experience":"cpe:2.3:a:nvidia:geforce_experience:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA GeForce Experience":"cpe:2.3:a:nvidia:geforce_experience:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA GeForce Experience Software":"cpe:2.3:a:nvidia:geforce_experience:*:*:*:*:*:*:*:*",
"Nvidia Corporation=GeForce Experience":"cpe:2.3:a:nvidia:geforce_experience:*:*:*:*:*:*:*:*",
"Nvidia Corporation=NVIDIA GeForce Experience":"cpe:2.3:a:nvidia:geforce_experience:*:*:*:*:*:*:*:*",
"Nvidia Corporation=GPU Display Driver":"cpe:2.3:a:nvidia:gpu_driver:*:*:*:*:*:*:*:*",
"Nvidia Corporation=GPU Graphics Driver":"cpe:2.3:a:nvidia:gpu_driver:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA Jetson AGX Xavier, TX1, TX2, and Nano L4T":"cpe:2.3:a:nvidia:jetpack_software_development_kit:*:*:*:*:*:*:*:*",
"NVIDIA=Jetson TX2":"cpe:2.3:a:nvidia:jetson_tx2:*:*:*:*:*:*:*:*",
"NVIDIA=Jetson Tegra TX2":"cpe:2.3:a:nvidia:jetson_tx2:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA License System":"cpe:2.3:a:nvidia:license_system:*:*:*:*:*:*:*:*",
"NVIDIA=caffe":"cpe:2.3:a:nvidia:nvcaffe:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA FLARE":"cpe:2.3:a:nvidia:nvflare:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA Virtual GPU Software":"cpe:2.3:a:nvidia:virtual_gpu:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA Virtual GPU Software and NVIDIA Cloud Gaming":"cpe:2.3:a:nvidia:virtual_gpu:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA GPU Display Driver":"cpe:2.3:a:nvidia:virtual_gpu_manager:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA GPU Display Driver ":"cpe:2.3:a:nvidia:virtual_gpu_manager:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA VGPU Display Driver":"cpe:2.3:a:nvidia:virtual_gpu_manager:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA vGPU Software":"cpe:2.3:a:nvidia:virtual_gpu_manager:*:*:*:*:*:*:*:*",
"Red Snapper=NView":"cpe:2.3:a:nview_project:nview:*:*:*:*:*:*:*:*",
"Jahastech=NxFilter":"cpe:2.3:a:nxfilter:nxfilter:*:*:*:*:*:*:*:*",
"NXLog=NXLog Manager":"cpe:2.3:a:nxlog:nxlog_manager:*:*:*:*:*:*:*:*",
"NXP=MCUXpresso SDK":"cpe:2.3:a:nxp:mcuxpresso_software_development_kit:*:*:*:*:*:*:*:*",
"OAuth2 Proxy=OAuth2 Proxy":"cpe:2.3:a:oauth2_proxy_project:oauth2_proxy:*:*:*:*:*:*:*:*",
"oauth2-proxy=oauth2-proxy":"cpe:2.3:a:oauth2_proxy_project:oauth2_proxy:*:*:*:*:*:*:*:*",
"pusher=OAuth2 Proxy":"cpe:2.3:a:oauth2_proxy_project:oauth2_proxy:*:*:*:*:*:*:*:*",
"micronaut-projects=micronaut-core":"cpe:2.3:a:objectcomputing:micronaut:*:*:*:*:*:*:*:*",
"micronaut-projects=micronaut-security":"cpe:2.3:a:objectcomputing:micronaut_security:*:*:*:*:*:*:*:*",
"OCI=OpenDDS":"cpe:2.3:a:objectcomputing:opendds:*:*:*:*:*:*:*:*",
"OpenDDS=OpenDDS":"cpe:2.3:a:objectcomputing:opendds:*:*:*:*:*:*:*:*",
"Objectplanet=Opinio":"cpe:2.3:a:objectplanet:opinio:*:*:*:*:*:*:*:*",
"karsany=OBridge":"cpe:2.3:a:obridge_project:obridge:*:*:*:*:*:*:*:*",
"Opencaching Deutschland=oc-server3":"cpe:2.3:a:oc-server3_project:oc-server3:*:*:*:*:*:*:*:*",
"OCSInventory=OCSInventory":"cpe:2.3:a:ocsinventory-ng:ocsinventory-ocsreports:*:*:*:*:*:*:*:*",
"VerneMQ=vernemq":"cpe:2.3:a:octavolabs:vernemq:*:*:*:*:*:*:*:*",
"rainlab=debugbar-plugin":"cpe:2.3:a:octobercms:debugbar:*:*:*:*:*:*:*:*",
"October CMS=October":"cpe:2.3:a:octobercms:october:*:*:*:*:*:*:*:*",
"octobercms=october":"cpe:2.3:a:octobercms:october:*:*:*:*:*:*:*:*",
"octobox=octobox":"cpe:2.3:a:octobox_project:octobox:*:*:*:*:*:*:*:*",
"OctoPrint=OctoPrint":"cpe:2.3:a:octoprint:octoprint:*:*:*:*:*:*:*:*",
"octoprint=octoprint/octoprint":"cpe:2.3:a:octoprint:octoprint:*:*:*:*:*:*:*:*",
"Octopus Deploy=Halibut":"cpe:2.3:a:octopus:halibut:*:*:*:*:*:*:*:*",
"Octopus Deploy=Octopus Server":"cpe:2.3:a:octopus:octopus_deploy:*:*:*:*:*:*:*:*",
"Octopus Deploy=Octopus Server":"cpe:2.3:a:octopus:octopus_server:*:*:*:*:*:*:*:*",
"Octopus Deploy=Octopus Tentacle":"cpe:2.3:a:octopus:octopus_server:*:*:*:*:*:*:*:*",
"OctopusDeploy=OctopusDSC":"cpe:2.3:a:octopus:octopusdsc:*:*:*:*:*:*:*:*",
"Octopus Deploy=Octopus Server":"cpe:2.3:a:octopus:server:*:*:*:*:*:*:*:*",
"Octopus Deploy=Octopus Tentacle":"cpe:2.3:a:octopus:tentacle:*:*:*:*:*:*:*:*",
"Facebook=Oculus Desktop":"cpe:2.3:a:oculus:desktop:*:*:*:*:*:*:*:*",
"Oculus=Oculus Browser":"cpe:2.3:a:oculus:oculus_browser:*:*:*:*:*:*:*:*",
"Oduyo Financial Technology=Online Collection":"cpe:2.3:a:oduyo:online_collection:*:*:*:*:*:*:*:*",
"Oduyo=Online Collection Software":"cpe:2.3:a:oduyo:online_collection:*:*:*:*:*:*:*:*",
"OFFIS=DCMTK":"cpe:2.3:a:offis:dcmtk:*:*:*:*:*:*:*:*",
"Open Information Security Foundation=Suricata":"cpe:2.3:a:oisf:suricata:*:*:*:*:*:*:*:*",
"ckan=ckan":"cpe:2.3:a:okfn:ckan:*:*:*:*:*:*:*:*",
"ckan=ckan-docker-base":"cpe:2.3:a:okfn:ckan:*:*:*:*:*:*:*:*",
"Okta=Okta Active Directory Agent":"cpe:2.3:a:okta:active_directory_agent:*:*:*:*:*:*:*:*",
"Okta=Advanced Server Access":"cpe:2.3:a:okta:advanced_server_access:*:*:*:*:*:*:*:*",
"Okta=Okta Advanced Server Access Client":"cpe:2.3:a:okta:advanced_server_access_client_for_windows:*:*:*:*:*:*:*:*",
"Okta=LDAP Agent":"cpe:2.3:a:okta:ldap_agent:*:*:*:*:*:*:*:*",
"Oliva Expertise=Oliva Expertise EKS":"cpe:2.3:a:olivaekspertiz:oliva_ekspertiz:*:*:*:*:*:*:*:*",
"Ombi-app=Ombi":"cpe:2.3:a:ombi:ombi:*:*:*:*:*:*:*:*",
"omeka=omeka/omeka-s":"cpe:2.3:a:omeka:omeka:*:*:*:*:*:*:*:*",
"omeka=omeka/omeka-s":"cpe:2.3:a:omeka:omeka_s:*:*:*:*:*:*:*:*",
"ITPison=OMICARD EDM":"cpe:2.3:a:omicard_edm_project:omicard_edm:*:*:*:*:*:*:*:*",
"OmniAuth=OmnitAuth-SAML":"cpe:2.3:a:omnitauth-saml_project:omnitauth-saml:*:*:*:*:*:*:*:*",
"OMRON Corporation=CX-Designer":"cpe:2.3:a:omrom:cx-designer:*:*:*:*:*:*:*:*",
"Omron=Sysmac Studio":"cpe:2.3:a:omron:automation_software_sysmac_studio:*:*:*:*:*:*:*:*",
"OMRON Corporation=CX-Drive":"cpe:2.3:a:omron:cx-drive:*:*:*:*:*:*:*:*",
"OMRON Corporation=CX-Drive All models":"cpe:2.3:a:omron:cx-drive:*:*:*:*:*:*:*:*",
"OMRON Corporation=CX-Motion Pro":"cpe:2.3:a:omron:cx-motion_pro:*:*:*:*:*:*:*:*",
"Omron=CX-One":"cpe:2.3:a:omron:cx-one:*:*:*:*:*:*:*:*",
"Omron=CX-Position":"cpe:2.3:a:omron:cx-position:*:*:*:*:*:*:*:*",
"OMRON Corporation=CX-Programmer":"cpe:2.3:a:omron:cx-programmer:*:*:*:*:*:*:*:*",
"OMRON=CX-Programmer":"cpe:2.3:a:omron:cx-programmer:*:*:*:*:*:*:*:*",
"Omron=CX-Programmer":"cpe:2.3:a:omron:cx-programmer:*:*:*:*:*:*:*:*",
"ICS-CERT=BeaconMed&#195;&#166;s TotalAlert Scroll Medical Air Systems web application":"cpe:2.3:a:omron:cx-supervisor:*:*:*:*:*:*:*:*",
"ICS-CERT=CX-Supervisor":"cpe:2.3:a:omron:cx-supervisor:*:*:*:*:*:*:*:*",
"ICS-CERT=Omron CX-Supervisor":"cpe:2.3:a:omron:cx-supervisor:*:*:*:*:*:*:*:*",
"OMRON Corporation=OMRON CX-Supervisor":"cpe:2.3:a:omron:cx-supervisor:*:*:*:*:*:*:*:*",
"Omron=CX-Supervisor":"cpe:2.3:a:omron:cx-supervisor:*:*:*:*:*:*:*:*",
"Omron=Sysmac Studio":"cpe:2.3:a:omron:sysmac_studio:*:*:*:*:*:*:*:*",
"SourceCodester=One Church Management System":"cpe:2.3:a:one_church_management_system_project:one_church_management_system:*:*:*:*:*:*:*:*",
"theonedev=onedev":"cpe:2.3:a:onedev_project:onedev:*:*:*:*:*:*:*:*",
" One= One":"cpe:2.3:a:oneidentity:password_manager:*:*:*:*:*:*:*:*",
"OneLogin=PythonSAML":"cpe:2.3:a:onelogin:pythonsaml:*:*:*:*:*:*:*:*",
"OneLogin=Ruby-SAML":"cpe:2.3:a:onelogin:ruby-saml:*:*:*:*:*:*:*:*",
"OneSignal=react-native-onesignal":"cpe:2.3:a:onesignal:react-native-onesignal:*:*:*:*:*:*:*:*",
"Onest=CRM":"cpe:2.3:a:onesttech:onest_customer_relation_management_system:*:*:*:*:*:*:*:*",
"SpiQe Software=OneThird CMS":"cpe:2.3:a:onethird:onethird:*:*:*:*:*:*:*:*",
"SpiQe Software=OneThird CMS":"cpe:2.3:a:onethird:onethird_cms:*:*:*:*:*:*:*:*",
"onionshare=onionshare":"cpe:2.3:a:onionshare:onionshare:*:*:*:*:*:*:*:*",
"PuneethReddyHC=online-shopping-system-advanced":"cpe:2.3:a:online-shopping-system-advanced_project:online-shopping-system-advanced:*:*:*:*:*:*:*:*",
"Project Worlds=Online Admission System":"cpe:2.3:a:online_admission_system_project:online_admission_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Admission System":"cpe:2.3:a:online_admission_system_project:online_admission_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Boat Reservation System":"cpe:2.3:a:online_boat_reservation_system_project:online_boat_reservation_system:*:*:*:*:*:*:*:*",
"Online Book Store Project=Online Book Store Project":"cpe:2.3:a:online_book_store_project_project:online_book_store_project:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Bus Booking System":"cpe:2.3:a:online_bus_booking_system_project:online_bus_booking_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Catering Reservation System":"cpe:2.3:a:online_catering_reservation_system_project:online_catering_reservation_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Class and Exam Scheduling System":"cpe:2.3:a:online_class_and_exam_scheduling_system_project:online_class_and_exam_scheduling_system:*:*:*:*:*:*:*:*",
"Campcodes=Online College Library System":"cpe:2.3:a:online_college_library_system_project:online_college_library_system:*:*:*:*:*:*:*:*",
"itsourcecode=Online Discussion Forum":"cpe:2.3:a:online_discussion_forum_project:online_discussion_forum:*:*:*:*:*:*:*:*",
"SourceCodester=Online Discussion Forum Site":"cpe:2.3:a:online_discussion_forum_site_project:online_discussion_forum_site:*:*:*:*:*:*:*:*",
"SourceCodester=Online DJ Management System":"cpe:2.3:a:online_dj_management_system_project:online_dj_management_system:*:*:*:*:*:*:*:*",
"codeprojects=Online Driving School":"cpe:2.3:a:online_driving_school_project_project:online_driving_school_project:*:*:*:*:*:*:*:*",
"SourceCodester=Online Employee Leave Management System":"cpe:2.3:a:online_employee_leave_management_system_project:online_employee_leave_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Exam Form Submission":"cpe:2.3:a:online_exam_form_submission_project:online_exam_form_submission:*:*:*:*:*:*:*:*",
"SourceCodester=Online Exam System":"cpe:2.3:a:online_exam_system_project:online_exam_system:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Examination System":"cpe:2.3:a:online_examination_system_project:online_examination_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Eyewear Shop":"cpe:2.3:a:online_eyewear_shop_project:online_eyewear_shop:*:*:*:*:*:*:*:*",
"SourceCodester=Online Flight Booking Management System":"cpe:2.3:a:online_flight_booking_management_system_project:online_flight_booking_management_system:*:*:*:*:*:*:*:*",
"CodeAstro=Online Food Ordering System":"cpe:2.3:a:online_food_ordering_system_project:online_food_ordering_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Food Ordering System":"cpe:2.3:a:online_food_ordering_system_project:online_food_ordering_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Food Ordering System":"cpe:2.3:a:online_food_ordering_system_v2_project:online_food_ordering_system_v2:*:*:*:*:*:*:*:*",
"SourceCodester=Online Graduate Tracer System":"cpe:2.3:a:online_graduate_tracer_system_project:online_graduate_tracer_system:*:*:*:*:*:*:*:*",
"unspecified=Online Hotel Booking System":"cpe:2.3:a:online_hotel_booking_project:online_hotel_booking:*:*:*:*:*:*:*:*",
"SourceCodester=Online Internship Management System":"cpe:2.3:a:online_internship_management_system_project:online_internship_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Jewelry Store":"cpe:2.3:a:online_jewelry_store_project:online_jewelry_store:*:*:*:*:*:*:*:*",
"SourceCodester=Online Payroll System":"cpe:2.3:a:online_payroll_system_project:online_payroll_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Pet Shop We App":"cpe:2.3:a:online_pet_shop_we_app_project:online_pet_shop_we_app:*:*:*:*:*:*:*:*",
"SourceCodester=Online Pizza Ordering System":"cpe:2.3:a:online_pizza_ordering_system_project:online_pizza_ordering_system:*:*:*:*:*:*:*:*",
"CodeAstro=Online Railway Reservation System":"cpe:2.3:a:online_railway_reservation_system_project:online_railway_reservation_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Reviewer Management System":"cpe:2.3:a:online_reviewer_management_system_project:online_reviewer_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Reviewer System":"cpe:2.3:a:online_reviewer_system_project:online_reviewer_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online School Fees System":"cpe:2.3:a:online_school_fees_system_project:online_school_fees_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Online Security Guards Hiring System":"cpe:2.3:a:online_security_guards_hiring_system_project:online_security_guards_hiring_system:*:*:*:*:*:*:*:*",
"NTN Information Technologies=Online Services Software":"cpe:2.3:a:online_services_project:online_services:*:*:*:*:*:*:*:*",
"PuneethReddyHC=Online Shopping System Advanced":"cpe:2.3:a:online_shopping_system_advanced_project:online_shopping_system_advanced:*:*:*:*:*:*:*:*",
"abcprintf=Online Store":"cpe:2.3:a:online_store_system_project:online_store_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Student Admission System":"cpe:2.3:a:online_student_admission_system_project:online_student_admission_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Student Management System":"cpe:2.3:a:online_student_management_system_project:online_student_management_system:*:*:*:*:*:*:*:*",
"Campcodes=Online Thesis Archiving System":"cpe:2.3:a:online_thesis_archiving_system_project:online_thesis_archiving_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Tours & Travels Management System":"cpe:2.3:a:online_tours_\&_travels_management_system_project:online_tours_\&_travels_management_system:*:*:*:*:*:*:*:*",
"Campcodes=Online Traffic Offense Management System":"cpe:2.3:a:online_traffic_offense_management_system_project:online_traffic_offense_management_system:*:*:*:*:*:*:*:*",
"dbijaya=OnlineVotingSystem":"cpe:2.3:a:onlinevotingsystem_project:onlinevotingsystem:*:*:*:*:*:*:*:*",
"ONLYOFFICE=Workspace":"cpe:2.3:a:onlyoffice:workspace:*:*:*:*:*:*:*:*",
"ONS Digital=RAS Collection Instrument":"cpe:2.3:a:ons:ras_collection_instrument:*:*:*:*:*:*:*:*",
"Talos=Ocularis":"cpe:2.3:a:onssi:ocularis:*:*:*:*:*:*:*:*",
"Nanning Ontall=Longxing Industrial Development Zone Project Construction and Installation Management System":"cpe:2.3:a:ontall:longxing_industrial_development_zone_project:*:*:*:*:*:*:*:*",
"OPC Foundation=UA .NET Standard":"cpe:2.3:a:opcfoundation:unified_architecture_.net-standard:*:*:*:*:*:*:*:*",
"OPC Labs=QuickOPC":"cpe:2.3:a:opclabs:quickopc:*:*:*:*:*:*:*:*",
"openemr=openemr/openemr":"cpe:2.3:a:open-emr:openemr:*:*:*:*:*:*:*:*",
"OX Software GmbH=OX App Suite":"cpe:2.3:a:open-xchange:open-xchange_appsuite_backend:*:*:*:*:*:*:*:*",
"OX Software GmbH=OX App Suite":"cpe:2.3:a:open-xchange:open-xchange_appsuite_frontend:*:*:*:*:*:*:*:*",
"OX Software GmbH=OX App Suite":"cpe:2.3:a:open-xchange:open-xchange_appsuite_office:*:*:*:*:*:*:*:*",
"Open5GS=Open5GS":"cpe:2.3:a:open5gs:open5gs:*:*:*:*:*:*:*:*",
"unspecified=Open5GS":"cpe:2.3:a:open5gs:open5gs:*:*:*:*:*:*:*:*",
"IET-OU=Open Media Player":"cpe:2.3:a:open:open_media_player:*:*:*:*:*:*:*:*",
"OpenAPITools=openapi-generator":"cpe:2.3:a:openapi-generator:openapi_generator:*:*:*:*:*:*:*:*",
"triaxtec=openapi-python-client":"cpe:2.3:a:openapi-python-client_project:openapi-python-client:*:*:*:*:*:*:*:*",
"Open Automation Software=OAS Platform":"cpe:2.3:a:openautomationsoftware:oas_platform:*:*:*:*:*:*:*:*",
"Open Babel=Open Babel":"cpe:2.3:a:openbabel:open_babel:*:*:*:*:*:*:*:*",
"OpenBMC Project=OpenBMC":"cpe:2.3:a:openbmc-project:openbmc:*:*:*:*:*:*:*:*",
"Opencaching Deutschland=oc-server3":"cpe:2.3:a:opencaching:oc-server3:*:*:*:*:*:*:*:*",
"Opencart=Opencart":"cpe:2.3:a:opencart:opencart:*:*:*:*:*:*:*:*",
"open source=OpenClinic GA":"cpe:2.3:a:openclinic_ga_project:openclinic_ga:*:*:*:*:*:*:*:*",
"OpenClinica=OpenClinica":"cpe:2.3:a:openclinica:openclinica:*:*:*:*:*:*:*:*",
"alfio-event=alfio-event/alf.io":"cpe:2.3:a:opencollective:alf.io:*:*:*:*:*:*:*:*",
"org.opencrx=opencrx-core-config":"cpe:2.3:a:opencrx:opencrx:*:*:*:*:*:*:*:*",
"OpenCV=wechat_qrcode Module":"cpe:2.3:a:opencv:opencv:*:*:*:*:*:*:*:*",
"[UNKNOWN]=opendaylight":"cpe:2.3:a:opendaylight:sdninterfaceapp:*:*:*:*:*:*:*:*",
"Open Dental=Open Dental":"cpe:2.3:a:opendental:opendental:*:*:*:*:*:*:*:*",
"Open Design Alliance=ODA Drawings SDK - All Versions < 2024.10":"cpe:2.3:a:opendesign:drawings_sdk:*:*:*:*:*:*:*:*",
"Open Design Alliance=ODA Drawings SDK - All Versions < 2024.12":"cpe:2.3:a:opendesign:drawings_sdk:*:*:*:*:*:*:*:*",
"Life Sciences Computing Corporation=OpenDolphin":"cpe:2.3:a:opendolphin:opendolphin:*:*:*:*:*:*:*:*",
"openedx=xblock-drag-and-drop-v2":"cpe:2.3:a:openedx:xblock-drag-and-drop-v2:*:*:*:*:*:*:*:*",
"openedx=xblock-lti-consumer":"cpe:2.3:a:openedx:xblock-lti-consumer:*:*:*:*:*:*:*:*",
"openenclave=openenclave":"cpe:2.3:a:openenclave:openenclave:*:*:*:*:*:*:*:*",
"OpenEnergyMonitor Project=Emoncms":"cpe:2.3:a:openenergymonitor:emoncms:*:*:*:*:*:*:*:*",
"EIP Stack Group=OpENer":"cpe:2.3:a:opener_project:opener:*:*:*:*:*:*:*:*",
"EIPStackGroup=OpENer EtherNet/IP":"cpe:2.3:a:opener_project:opener:*:*:*:*:*:*:*:*",
"openEuler=lcr":"cpe:2.3:a:openeuler:icr:*:*:*:*:*:*:*:*",
"openEuler=iSulad":"cpe:2.3:a:openeuler:isula:*:*:*:*:*:*:*:*",
"Academy Software Foundation=OpenEXR":"cpe:2.3:a:openexr:openexr:*:*:*:*:*:*:*:*",
"openfga=openfga":"cpe:2.3:a:openfga:openfga:*:*:*:*:*:*:*:*",
"Openfind=MAIL2000":"cpe:2.3:a:openfind:mail2000:*:*:*:*:*:*:*:*",
"Openfind=Mail2000":"cpe:2.3:a:openfind:mail2000:*:*:*:*:*:*:*:*",
"openhab=openhab-addons":"cpe:2.3:a:openhab:openhab:*:*:*:*:*:*:*:*",
"OpenHarmony=OpenHarmony":"cpe:2.3:a:openharmony:openharmony:*:*:*:*:*:*:*:*",
"OpenIDC=mod_auth_openidc":"cpe:2.3:a:openidc:mod_auth_openidc:*:*:*:*:*:*:*:*",
"ZmartZone IAM=mod_auth_openidc":"cpe:2.3:a:openidc:mod_auth_openidc:*:*:*:*:*:*:*:*",
"[UNKNOWN]=mod_auth_openidc":"cpe:2.3:a:openidc:mod_auth_openidc:*:*:*:*:*:*:*:*",
"OpenIdentityPlatform=OpenAM":"cpe:2.3:a:openidentityplatform:openam:*:*:*:*:*:*:*:*",
"OpenImageIO Project=OpenImageIO":"cpe:2.3:a:openimageio:openimageio:*:*:*:*:*:*:*:*",
"OPEN JOURNAL SYSTEMS=OPEN JOURNAL SYSTEMS":"cpe:2.3:a:openjournalsystems:open_journal_systems:*:*:*:*:*:*:*:*",
"OpenKM=OpenKM":"cpe:2.3:a:openkm:openkm:*:*:*:*:*:*:*:*",
"unspecified=OpenKM":"cpe:2.3:a:openkm:openkm:*:*:*:*:*:*:*:*",
"openkruise=kruise":"cpe:2.3:a:openkruise:kruise:*:*:*:*:*:*:*:*",
"openlibrary=openlibrary":"cpe:2.3:a:openlibrary:openlibrary:*:*:*:*:*:*:*:*",
"OpenMage=magento-lts":"cpe:2.3:a:openmage:magento:*:*:*:*:*:*:*:*",
"ome=omero-web":"cpe:2.3:a:openmicroscopy:omero.web:*:*:*:*:*:*:*:*",
"OpenModelica=OMCompiler":"cpe:2.3:a:openmodelica:omcompiler:*:*:*:*:*:*:*:*",
"OpenMRS=Admin UI Module":"cpe:2.3:a:openmrs:admin_ui_module:*:*:*:*:*:*:*:*",
"OpenMRS=Appointment Scheduling Module":"cpe:2.3:a:openmrs:appointment_scheduling_module:*:*:*:*:*:*:*:*",
"openmrs=openmrs-core":"cpe:2.3:a:openmrs:openmrs:*:*:*:*:*:*:*:*",
"OpenMRS=openmrs-module-referenceapplication":"cpe:2.3:a:openmrs:reference_application:*:*:*:*:*:*:*:*",
"unspecified=OpenNetAdmin":"cpe:2.3:a:opennetadmin:opennetadmin:*:*:*:*:*:*:*:*",
"Juniper Networks=Junos Space":"cpe:2.3:a:opennms:opennms:*:*:*:*:*:*:*:*",
"openobserve=openobserve":"cpe:2.3:a:openobserve:openobserve:*:*:*:*:*:*:*:*",
"openpgpjs=openpgpjs":"cpe:2.3:a:openpgpjs:openpgpjs:*:*:*:*:*:*:*:*",
"open-policy-agent=opa":"cpe:2.3:a:openpolicyagent:open_policy_agent:*:*:*:*:*:*:*:*",
"opf=openproject":"cpe:2.3:a:openproject:openproject:*:*:*:*:*:*:*:*",
"OpenRapid=RapidCMS":"cpe:2.3:a:openrapid:rapidcms:*:*:*:*:*:*:*:*",
"OpenRefine=OpenRefine":"cpe:2.3:a:openrefine:openrefine:*:*:*:*:*:*:*:*",
"openreplay=openreplay":"cpe:2.3:a:openreplay:openreplay:*:*:*:*:*:*:*:*",
"OpenSIPS=opensips":"cpe:2.3:a:opensips:opensips:*:*:*:*:*:*:*:*",
"OpenSlides=OpenSlides":"cpe:2.3:a:openslides:openslides:*:*:*:*:*:*:*:*",
"OpenSSL Software Foundation=OpenSSL":"cpe:2.3:a:openssl:openssl:*:*:*:*:*:*:*:*",
"OpenSSL=OpenSSL":"cpe:2.3:a:openssl:openssl:*:*:*:*:*:*:*:*",
"OpenStack=OpenStack":"cpe:2.3:a:openstack:kolla:*:*:*:*:*:*:*:*",
"SUSE=cryptctl":"cpe:2.3:a:opensuse:cryptctl:*:*:*:*:*:*:*:*",
"openSUSE=Factory":"cpe:2.3:a:opensuse:cyrus-sasl:*:*:*:*:*:*:*:*",
"SUSE=openSUSE Factory":"cpe:2.3:a:opensuse:factory:*:*:*:*:*:*:*:*",
"openSUSE=Factory":"cpe:2.3:a:opensuse:factory:*:*:*:*:*:*:*:*",
"openSUSE=libeconf":"cpe:2.3:a:opensuse:libeconf:*:*:*:*:*:*:*:*",
"SUSE=libzypp":"cpe:2.3:a:opensuse:libzypp:*:*:*:*:*:*:*:*",
"SUSE=obs-service-source_validator":"cpe:2.3:a:opensuse:obs-service-source_validator:*:*:*:*:*:*:*:*",
"Open Build Service=Open Build Service":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"SUSE=Open Build Service":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"SUSE=open build service":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"openSUSE=Build service":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"openSUSE=Open Build Service":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"openSUSE=open build service":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"opensuse=openbuildservice":"cpe:2.3:a:opensuse:open_build_service:*:*:*:*:*:*:*:*",
"openSUSE=Factory":"cpe:2.3:a:opensuse:openldap2:*:*:*:*:*:*:*:*",
"openSUSE=paste":"cpe:2.3:a:opensuse:paste:*:*:*:*:*:*:*:*",
"SUSE=supportutils":"cpe:2.3:a:opensuse:supportutils:*:*:*:*:*:*:*:*",
"openSUSE=travel-support-program":"cpe:2.3:a:opensuse:travel_support_program:*:*:*:*:*:*:*:*",
"openSUSE=Tumbleweed":"cpe:2.3:a:opensuse:tumbleweed:*:*:*:*:*:*:*:*",
"openSUSE=opensuse-welcome":"cpe:2.3:a:opensuse:welcome:*:*:*:*:*:*:*:*",
"SUSE=yast2-multipath":"cpe:2.3:a:opensuse:yast2-multipath:*:*:*:*:*:*:*:*",
"SUSE=yast2-printer":"cpe:2.3:a:opensuse:yast2-printer:*:*:*:*:*:*:*:*",
"SUSE=yast2-samba-provision":"cpe:2.3:a:opensuse:yast2-samba-provision:*:*:*:*:*:*:*:*",
"open-telemetry=opentelemetry-python-contrib":"cpe:2.3:a:opentelemetry:opentelemetry:*:*:*:*:*:*:*:*",
"OpenText=Brava! Desktop":"cpe:2.3:a:opentext:brava\!:*:*:*:*:*:*:*:*",
"OpenText=Brava! Desktop":"cpe:2.3:a:opentext:brava\!_desktop:*:*:*:*:*:*:*:*",
"Google LLC=wpantund":"cpe:2.3:a:openthread:wpantund:*:*:*:*:*:*:*:*",
"OpenTSDB=OpenTSDB":"cpe:2.3:a:opentsdb:opentsdb:*:*:*:*:*:*:*:*",
"OpenTSDB=opentsdb":"cpe:2.3:a:opentsdb:opentsdb:*:*:*:*:*:*:*:*",
"stakira=OpenUtau":"cpe:2.3:a:openutau:openutau:*:*:*:*:*:*:*:*",
"openwhyd=openwhyd/openwhyd":"cpe:2.3:a:openwhyd:openwhyd:*:*:*:*:*:*:*:*",
"open-zaak=open-zaak":"cpe:2.3:a:openzaak:open_zaak:*:*:*:*:*:*:*:*",
"OPPO=OPPO Store":"cpe:2.3:a:oppo:oppo_store:*:*:*:*:*:*:*:*",
"OPTiM Corporation=Optimal Guard":"cpe:2.3:a:optim:optimal_guard:*:*:*:*:*:*:*:*",
"Oracle Corporation=Access Manager":"cpe:2.3:a:oracle:access_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=COREid Access":"cpe:2.3:a:oracle:access_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Adaptive Access Manager":"cpe:2.3:a:oracle:adaptive_access_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Advanced Collections":"cpe:2.3:a:oracle:advanced_collections:*:*:*:*:*:*:*:*",
"Oracle Corporation=Advanced Inbound Telephony":"cpe:2.3:a:oracle:advanced_inbound_telephony:*:*:*:*:*:*:*:*",
"Oracle Corporation=Advanced Outbound Telephony":"cpe:2.3:a:oracle:advanced_outbound_telephony:*:*:*:*:*:*:*:*",
"Oracle=Advanced Outbound Telephony":"cpe:2.3:a:oracle:advanced_outbound_telephony:*:*:*:*:*:*:*:*",
"Oracle Corporation=Advanced Pricing":"cpe:2.3:a:oracle:advanced_pricing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Advanced Supply Chain Planning":"cpe:2.3:a:oracle:advanced_supply_chain_planning:*:*:*:*:*:*:*:*",
"Oracle Corporation=Agile Engineering Data Management":"cpe:2.3:a:oracle:agile_engineering_data_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Agile PLM Framework":"cpe:2.3:a:oracle:agile_plm:*:*:*:*:*:*:*:*",
"Oracle Corporation=Agile Product Governance and Compliance":"cpe:2.3:a:oracle:agile_plm:*:*:*:*:*:*:*:*",
"Oracle Corporation=Agile PLM Framework":"cpe:2.3:a:oracle:agile_product_lifecycle_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Agile Product Lifecycle Management for Process":"cpe:2.3:a:oracle:agile_product_lifecycle_management_for_process:*:*:*:*:*:*:*:*",
"Oracle Corporation=Agile PLM Framework":"cpe:2.3:a:oracle:agile_product_lifecycle_management_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=API Gateway":"cpe:2.3:a:oracle:api_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Express":"cpe:2.3:a:oracle:application_express:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Express (APEX)":"cpe:2.3:a:oracle:application_express:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Express (APEX)":"cpe:2.3:a:oracle:application_express_opportunity_tracker:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Express (APEX)":"cpe:2.3:a:oracle:application_express_survey_builder:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Object Library":"cpe:2.3:a:oracle:application_object_library:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications Framework":"cpe:2.3:a:oracle:application_object_library:*:*:*:*:*:*:*:*",
"Oracle=Application Object Library":"cpe:2.3:a:oracle:application_object_library:*:*:*:*:*:*:*:*",
"Oracle Corporation=APM - Application Performance Management":"cpe:2.3:a:oracle:application_performance_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Testing Suite":"cpe:2.3:a:oracle:application_testing_suite:*:*:*:*:*:*:*:*",
"Oracle=Application Testing Suite":"cpe:2.3:a:oracle:application_testing_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications DBA":"cpe:2.3:a:oracle:applications_dba:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Database":"cpe:2.3:a:oracle:applications_dba:*:*:*:*:*:*:*:*",
"Oracle Corporation=PL/SQL":"cpe:2.3:a:oracle:applications_dba:*:*:*:*:*:*:*:*",
"Oracle=Applications DBA":"cpe:2.3:a:oracle:applications_dba:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications Framework":"cpe:2.3:a:oracle:applications_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Project Planning and Control":"cpe:2.3:a:oracle:applications_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications Manager":"cpe:2.3:a:oracle:applications_manager:*:*:*:*:*:*:*:*",
"Oracle=Applications Manager":"cpe:2.3:a:oracle:applications_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Approvals Management":"cpe:2.3:a:oracle:approvals_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Argus Safety":"cpe:2.3:a:oracle:argus_safety:*:*:*:*:*:*:*:*",
"Oracle Corporation=Audit Vault and Database Firewall":"cpe:2.3:a:oracle:audit_vault_and_database_firewall:*:*:*:*:*:*:*:*",
"Oracle Corporation=Automatic Service Request (ASR)":"cpe:2.3:a:oracle:automatic_service_request:*:*:*:*:*:*:*:*",
"Oracle Corporation=AutoVue 3D Professional Advanced":"cpe:2.3:a:oracle:autovue:*:*:*:*:*:*:*:*",
"Oracle Corporation=AutoVue 3D Professional Advanced":"cpe:2.3:a:oracle:autovue_3d_professional_advanced:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Corporate Lending":"cpe:2.3:a:oracle:banking_corporate_lending:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Digital Experience":"cpe:2.3:a:oracle:banking_digital_experience:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Payments":"cpe:2.3:a:oracle:banking_payments:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Trade Finance":"cpe:2.3:a:oracle:banking_trade_finance:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Trade Finance":"cpe:2.3:a:oracle:banking_trade_finance_process_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Treasury Management":"cpe:2.3:a:oracle:banking_treasury_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Banking Virtual Account Management":"cpe:2.3:a:oracle:banking_virtual_account_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Berkeley DB":"cpe:2.3:a:oracle:berkeley_db:*:*:*:*:*:*:*:*",
"Oracle Corporation=BI Publisher (formerly XML Publisher)":"cpe:2.3:a:oracle:bi_publisher:*:*:*:*:*:*:*:*",
"Oracle Corporation=Bill Presentment Architecture":"cpe:2.3:a:oracle:bill_presentment_architecture:*:*:*:*:*:*:*:*",
"Oracle Corporation=Bills of Material":"cpe:2.3:a:oracle:bills_of_material:*:*:*:*:*:*:*:*",
"Oracle Corporation=BI Publisher (formerly XML Publisher)":"cpe:2.3:a:oracle:business_intelligence_publisher:*:*:*:*:*:*:*:*",
"Oracle Corporation=Business Process Management Suite":"cpe:2.3:a:oracle:business_process_management_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Cash Management":"cpe:2.3:a:oracle:cash_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Clinical Remote Data Capture Option":"cpe:2.3:a:oracle:clinical_remote_data_capture:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Cloud Infrastructure":"cpe:2.3:a:oracle:cloud_infrastructure:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Cloud Infrastructure Data Science Notebook Sessions":"cpe:2.3:a:oracle:cloud_infrastructure_data_science:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Cloud Infrastructure Identity and Access Management":"cpe:2.3:a:oracle:cloud_infrastructure_identity_and_access_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Cloud Infrastructure":"cpe:2.3:a:oracle:cloud_infrastructure_storage_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=System Utilities":"cpe:2.3:a:oracle:clusterware:*:*:*:*:*:*:*:*",
"Oracle Corporation=Coherence":"cpe:2.3:a:oracle:coherence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Collaborative Planning":"cpe:2.3:a:oracle:collaborative_planning:*:*:*:*:*:*:*:*",
"Oracle Corporation=Commerce Guided Search / Oracle Commerce Experience Manager":"cpe:2.3:a:oracle:commerce:*:*:*:*:*:*:*:*",
"Oracle Corporation=Commerce Guided Search / Oracle Commerce Experience Manager":"cpe:2.3:a:oracle:commerce_guided_search:*:*:*:*:*:*:*:*",
"Oracle Corporation=Commerce Merchandising":"cpe:2.3:a:oracle:commerce_merchandising:*:*:*:*:*:*:*:*",
"Oracle Corporation=Commerce Platform":"cpe:2.3:a:oracle:commerce_platform:*:*:*:*:*:*:*:*",
"Oracle=Commerce Platform":"cpe:2.3:a:oracle:commerce_platform:*:*:*:*:*:*:*:*",
"Oracle Corporation=Commerce Platform":"cpe:2.3:a:oracle:commerce_service_center:*:*:*:*:*:*:*:*",
"Oracle Corporation=Commerce Service Center":"cpe:2.3:a:oracle:commerce_service_center:*:*:*:*:*:*:*:*",
"Oracle Corporation=Common Applications":"cpe:2.3:a:oracle:common_applications:*:*:*:*:*:*:*:*",
"Oracle=Common Applications":"cpe:2.3:a:oracle:common_applications:*:*:*:*:*:*:*:*",
"Oracle Corporation=Common Applications Calendar":"cpe:2.3:a:oracle:common_applications_calendar:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Session Border Controller":"cpe:2.3:a:oracle:communications_applications:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Billing and Revenue Management":"cpe:2.3:a:oracle:communications_billing_and_revenue_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Converged Application Server":"cpe:2.3:a:oracle:communications_converged_application_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Convergence":"cpe:2.3:a:oracle:communications_convergence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Diameter Signaling Router (DSR)":"cpe:2.3:a:oracle:communications_diameter_signaling_router:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications EAGLE LNP Application Processor":"cpe:2.3:a:oracle:communications_eagle_local_number_portability_application_processor:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Interactive Session Recorder":"cpe:2.3:a:oracle:communications_interactive_session_recorder:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Operations Monitor":"cpe:2.3:a:oracle:communications_operations_monitor:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Order and Service Management":"cpe:2.3:a:oracle:communications_order_and_service_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Policy Management":"cpe:2.3:a:oracle:communications_policy_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Pricing Design Center":"cpe:2.3:a:oracle:communications_pricing_design_center:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Security Gateway":"cpe:2.3:a:oracle:communications_security_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Session Border Controller":"cpe:2.3:a:oracle:communications_session_border_controller:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications Unified Inventory Management":"cpe:2.3:a:oracle:communications_unified_inventory_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Communications WebRTC Session Controller":"cpe:2.3:a:oracle:communications_webrtc_session_controller:*:*:*:*:*:*:*:*",
"Oracle Corporation=Compensation Workbench":"cpe:2.3:a:oracle:compensation_workbench:*:*:*:*:*:*:*:*",
"Oracle Corporation=Complex Maintenance, Repair, and Overhaul":"cpe:2.3:a:oracle:complex_maintenance\,_repair\,_and_overhaul:*:*:*:*:*:*:*:*",
"Oracle Corporation=Complex Maintenance, Repair, and Overhaul":"cpe:2.3:a:oracle:complex_maintenance_repair_and_overhaul:*:*:*:*:*:*:*:*",
"Oracle Corporation=Concurrent Processing":"cpe:2.3:a:oracle:concurrent_processing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Configuration Manager":"cpe:2.3:a:oracle:configuration_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Configurator":"cpe:2.3:a:oracle:configurator:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Connectors":"cpe:2.3:a:oracle:connector\/j:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Connectors":"cpe:2.3:a:oracle:connector\/python:*:*:*:*:*:*:*:*",
"Oracle Corporation=Content Manager":"cpe:2.3:a:oracle:content_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:core_rdbms:*:*:*:*:*:*:*:*",
"Oracle Corporation=CRM Technical Foundation":"cpe:2.3:a:oracle:crm_technical_foundation:*:*:*:*:*:*:*:*",
"Oracle Corporation=Crystal Ball":"cpe:2.3:a:oracle:crystal_ball:*:*:*:*:*:*:*:*",
"Oracle=Customer Intelligence":"cpe:2.3:a:oracle:customer_intelligence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Customer Interaction History":"cpe:2.3:a:oracle:customer_interaction_history:*:*:*:*:*:*:*:*",
"Oracle=Customer Interaction History":"cpe:2.3:a:oracle:customer_interaction_history:*:*:*:*:*:*:*:*",
"Oracle Corporation=CRM Gateway for Mobile Devices":"cpe:2.3:a:oracle:customer_relationship_management_gateway_for_mobile_devices:*:*:*:*:*:*:*:*",
"Oracle Corporation=CRM Technical Foundation":"cpe:2.3:a:oracle:customer_relationship_management_technical_foundation:*:*:*:*:*:*:*:*",
"Oracle=CRM Technical Foundation":"cpe:2.3:a:oracle:customer_relationship_management_technical_foundation:*:*:*:*:*:*:*:*",
"Oracle Corporation=Customers Online":"cpe:2.3:a:oracle:customers_online:*:*:*:*:*:*:*:*",
"Oracle Corporation=Data Integrator":"cpe:2.3:a:oracle:data_integrator:*:*:*:*:*:*:*:*",
"Oracle Corporation=Data Masking and Subsetting":"cpe:2.3:a:oracle:data_masking_and_subsetting:*:*:*:*:*:*:*:*",
"Oracle Corporation=Data Visualization Desktop":"cpe:2.3:a:oracle:data_visualization_desktop:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:database:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Database":"cpe:2.3:a:oracle:database:*:*:*:*:*:*:*:*",
"Oracle=Oracle Database":"cpe:2.3:a:oracle:database:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:database_-_sharding:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:database_filesystem:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:database_recovery_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Advanced Networking Option":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Express":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Data Provider for .NET":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=ODBC Driver":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Database":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Services for Microsoft Transaction Server":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Text":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle=Oracle Database":"cpe:2.3:a:oracle:database_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:database_vault:*:*:*:*:*:*:*:*",
"Oracle Corporation=Deal Management":"cpe:2.3:a:oracle:deal_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Demantra Demand Management":"cpe:2.3:a:oracle:demantra_demand_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Depot Repair":"cpe:2.3:a:oracle:depot_repair:*:*:*:*:*:*:*:*",
"Oracle Corporation=Document Management and Collaboration":"cpe:2.3:a:oracle:document_management_and_collaboration:*:*:*:*:*:*:*:*",
"Oracle Corporation=E-Business Intelligence":"cpe:2.3:a:oracle:e-business_intelligence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Application Object Library":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications DBA":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications Framework":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Applications Manager":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=CRM Technical Foundation":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=E-Business Suite Technology Stack":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Email Center":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=General Ledger":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Marketing":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Mobile Field Service":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=One-to-One Fulfillment":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Partner Management":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Performance Management":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Scripting":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=User Management":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Web Applications Desktop Integrator":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=iRecruitment":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=iStore":"cpe:2.3:a:oracle:e-business_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=E-Business Suite Secure Enterprise Search":"cpe:2.3:a:oracle:e-business_suite_secure_enterprise_search:*:*:*:*:*:*:*:*",
"Oracle Corporation=E-Business Suite Technology Stack":"cpe:2.3:a:oracle:e-business_suite_technology_stack:*:*:*:*:*:*:*:*",
"Oracle Corporation=E-Business Tax":"cpe:2.3:a:oracle:e-business_tax:*:*:*:*:*:*:*:*",
"Oracle Corporation=E-Records":"cpe:2.3:a:oracle:e-records:*:*:*:*:*:*:*:*",
"Oracle Corporation=Email Center":"cpe:2.3:a:oracle:email_center:*:*:*:*:*:*:*:*",
"Oracle=Email Center":"cpe:2.3:a:oracle:email_center:*:*:*:*:*:*:*:*",
"Oracle Corporation=Endeca Information Discovery Integrator":"cpe:2.3:a:oracle:endeca_information_discovery_integrator:*:*:*:*:*:*:*:*",
"Oracle Corporation=Engineering":"cpe:2.3:a:oracle:engineering:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Asset Management":"cpe:2.3:a:oracle:enterprise_asset_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Command Center Framework":"cpe:2.3:a:oracle:enterprise_command_center_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Communications Broker":"cpe:2.3:a:oracle:enterprise_communications_broker:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Data Quality":"cpe:2.3:a:oracle:enterprise_data_quality:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Manager Base Platform":"cpe:2.3:a:oracle:enterprise_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Manager for Fusion Middleware":"cpe:2.3:a:oracle:enterprise_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Manager for Oracle Database":"cpe:2.3:a:oracle:enterprise_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Manager Base Platform":"cpe:2.3:a:oracle:enterprise_manager_base_platform:*:*:*:*:*:*:*:*",
"Oracle Corporation=APM - Application Performance Management":"cpe:2.3:a:oracle:enterprise_manager_for_fusion_middleware:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Manager Ops Center":"cpe:2.3:a:oracle:enterprise_manager_ops_center:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Repository":"cpe:2.3:a:oracle:enterprise_repository:*:*:*:*:*:*:*:*",
"Oracle Corporation=Enterprise Session Border Controller":"cpe:2.3:a:oracle:enterprise_session_border_controller:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Essbase":"cpe:2.3:a:oracle:essbase:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Essbase Administration Services":"cpe:2.3:a:oracle:essbase_administration_services:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Analytic Provider Services":"cpe:2.3:a:oracle:essbase_analytic_provider_services:*:*:*:*:*:*:*:*",
"Oracle Corporation=Field Service":"cpe:2.3:a:oracle:field_service:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Analytical Applications Infrastructure":"cpe:2.3:a:oracle:financial_services_analytical_applications_infrastructure:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Analytical Applications Reconciliation Framework":"cpe:2.3:a:oracle:financial_services_analytical_applications_reconciliation_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Asset Liability Management":"cpe:2.3:a:oracle:financial_services_asset_liability_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Balance Sheet Planning":"cpe:2.3:a:oracle:financial_services_balance_sheet_planning:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Basel Regulatory Capital Basic":"cpe:2.3:a:oracle:financial_services_basel_regulatory_capital_basic:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Basel Regulatory Capital Internal Ratings Based Approach":"cpe:2.3:a:oracle:financial_services_basel_regulatory_capital_internal_ratings_based_approach:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Behavior Detection Platform":"cpe:2.3:a:oracle:financial_services_behavior_detection_platform:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Crime and Compliance Investigation Hub":"cpe:2.3:a:oracle:financial_services_crime_and_compliance_investigation_hub:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Data Foundation":"cpe:2.3:a:oracle:financial_services_data_foundation:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Deposit Insurance Calculations for Liquidity Risk Management":"cpe:2.3:a:oracle:financial_services_deposit_insurance_calculations_for_liquidity_risk_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Funds Transfer Pricing":"cpe:2.3:a:oracle:financial_services_funds_transfer_pricing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Hedge Management and IFRS Valuations":"cpe:2.3:a:oracle:financial_services_hedge_management_and_ifrs_valuations:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Liquidity Risk Management":"cpe:2.3:a:oracle:financial_services_liquidity_risk_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Liquidity Risk Measurement and Management":"cpe:2.3:a:oracle:financial_services_liquidity_risk_measurement_and_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Loan Loss Forecasting and Provisioning":"cpe:2.3:a:oracle:financial_services_loan_loss_forecasting_and_provisioning:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Market Risk":"cpe:2.3:a:oracle:financial_services_market_risk:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Market Risk Measurement and Management":"cpe:2.3:a:oracle:financial_services_market_risk_measurement_and_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Price Creation and Discovery":"cpe:2.3:a:oracle:financial_services_price_creation_and_discovery:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Profitability Management":"cpe:2.3:a:oracle:financial_services_profitability_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Revenue Management and Billing":"cpe:2.3:a:oracle:financial_services_revenue_management_and_billing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financials Common Modules":"cpe:2.3:a:oracle:financials_common_modules:*:*:*:*:*:*:*:*",
"Oracle Corporation=FLEXCUBE Core Banking":"cpe:2.3:a:oracle:flexcube_core_banking:*:*:*:*:*:*:*:*",
"Oracle Corporation=FLEXCUBE Direct Banking":"cpe:2.3:a:oracle:flexcube_direct_banking:*:*:*:*:*:*:*:*",
"Oracle=FLEXCUBE Direct Banking":"cpe:2.3:a:oracle:flexcube_direct_banking:*:*:*:*:*:*:*:*",
"Oracle Corporation=FLEXCUBE Enterprise Limits and Collateral Management":"cpe:2.3:a:oracle:flexcube_enterprise_limits_and_collateral_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=FLEXCUBE Investor Servicing":"cpe:2.3:a:oracle:flexcube_investor_servicing:*:*:*:*:*:*:*:*",
"Oracle Corporation=FLEXCUBE Private Banking":"cpe:2.3:a:oracle:flexcube_private_banking:*:*:*:*:*:*:*:*",
"Oracle Corporation=FLEXCUBE Universal Banking":"cpe:2.3:a:oracle:flexcube_universal_banking:*:*:*:*:*:*:*:*",
"Oracle=FLEXCUBE Universal Banking":"cpe:2.3:a:oracle:flexcube_universal_banking:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Gift and Loyalty":"cpe:2.3:a:oracle:food_and_beverage_applications:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Reporting and Analytics":"cpe:2.3:a:oracle:food_and_beverage_applications:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Simphony":"cpe:2.3:a:oracle:food_and_beverage_applications:*:*:*:*:*:*:*:*",
"Oracle Corporation=Forms":"cpe:2.3:a:oracle:forms:*:*:*:*:*:*:*:*",
"Oracle Corporation=Fusion Middleware":"cpe:2.3:a:oracle:fusion_middleware:*:*:*:*:*:*:*:*",
"Oracle Corporation=Fusion Middleware MapViewer":"cpe:2.3:a:oracle:fusion_middleware:*:*:*:*:*:*:*:*",
"Oracle Corporation=Mobile Security Suite":"cpe:2.3:a:oracle:fusion_middleware:*:*:*:*:*:*:*:*",
"Oracle Corporation=WebLogic Server":"cpe:2.3:a:oracle:fusion_middleware:*:*:*:*:*:*:*:*",
"Oracle Corporation=Fusion Middleware MapViewer":"cpe:2.3:a:oracle:fusion_middleware_mapviewer:*:*:*:*:*:*:*:*",
"Oracle Corporation=General Ledger":"cpe:2.3:a:oracle:general_ledger:*:*:*:*:*:*:*:*",
"Oracle Corporation=GlassFish Server":"cpe:2.3:a:oracle:glassfish_server:*:*:*:*:*:*:*:*",
"Oracle=GlassFish Server":"cpe:2.3:a:oracle:glassfish_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Global Lifecycle Management NextGen OUI Framework":"cpe:2.3:a:oracle:global_lifecycle_management_nextgen_oui_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Global Order Promising":"cpe:2.3:a:oracle:global_order_promising:*:*:*:*:*:*:*:*",
"Oracle Corporation=GoldenGate":"cpe:2.3:a:oracle:goldengate:*:*:*:*:*:*:*:*",
"Oracle Corporation=GraalVM Enterprise Edition":"cpe:2.3:a:oracle:graalvm_for_jdk:*:*:*:*:*:*:*:*",
"Oracle Corporation=SSM - (ssm_host_apps) HMP: Hardware Management Pack":"cpe:2.3:a:oracle:hardware_management_pack:*:*:*:*:*:*:*:*",
"Oracle Corporation=HCM Common Architecture":"cpe:2.3:a:oracle:hcm_common_architecture:*:*:*:*:*:*:*:*",
"Oracle Corporation=Life Sciences Data Management Workbench":"cpe:2.3:a:oracle:health_sciences_applications:*:*:*:*:*:*:*:*",
"Oracle Corporation=Health Sciences Data Management Workbench":"cpe:2.3:a:oracle:health_sciences_data_management_workbench:*:*:*:*:*:*:*:*",
"Oracle Corporation=Health Sciences InForm":"cpe:2.3:a:oracle:health_sciences_inform:*:*:*:*:*:*:*:*",
"Oracle Corporation=Help Technologies":"cpe:2.3:a:oracle:help_technologies:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality 9700":"cpe:2.3:a:oracle:hospitality_9700:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Cruise AffairWhere":"cpe:2.3:a:oracle:hospitality_cruise_affairwhere:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Cruise Dining Room Management":"cpe:2.3:a:oracle:hospitality_cruise_dining_room_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Cruise Fleet Management":"cpe:2.3:a:oracle:hospitality_cruise_fleet_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Cruise Materials Management":"cpe:2.3:a:oracle:hospitality_cruise_materials_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Cruise Shipboard Property Management System":"cpe:2.3:a:oracle:hospitality_cruise_shipboard_property_management_system:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality e7":"cpe:2.3:a:oracle:hospitality_e7:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Gift and Loyalty":"cpe:2.3:a:oracle:hospitality_gift_and_loyalty:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Guest Access":"cpe:2.3:a:oracle:hospitality_guest_access:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Hotel Mobile":"cpe:2.3:a:oracle:hospitality_hotel_mobile:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Inventory Management":"cpe:2.3:a:oracle:hospitality_inventory_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Labor Management":"cpe:2.3:a:oracle:hospitality_labor_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Materials Control":"cpe:2.3:a:oracle:hospitality_materials_control:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality OPERA 5 Property Services":"cpe:2.3:a:oracle:hospitality_opera_5_property_services:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality OPERA 5 Property Services":"cpe:2.3:a:oracle:hospitality_opera_property_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Reporting and Analytics":"cpe:2.3:a:oracle:hospitality_reporting_and_analytics:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality RES 3700":"cpe:2.3:a:oracle:hospitality_res_3700:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Simphony":"cpe:2.3:a:oracle:hospitality_simphony:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Simphony First Edition Venue Management":"cpe:2.3:a:oracle:hospitality_simphony_first_edition_venue_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Suite8":"cpe:2.3:a:oracle:hospitality_suite8:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Suite8 Property Interfaces":"cpe:2.3:a:oracle:hospitality_suite8_property_interfaces:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Suite8":"cpe:2.3:a:oracle:hospitality_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality Suites Management":"cpe:2.3:a:oracle:hospitality_suites_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hospitality WebSuite8 Cloud Service":"cpe:2.3:a:oracle:hospitality_websuite8_cloud_service:*:*:*:*:*:*:*:*",
"Oracle Corporation=HTTP Server":"cpe:2.3:a:oracle:http_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Security Service":"cpe:2.3:a:oracle:http_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=HRMS (France)":"cpe:2.3:a:oracle:human_resource_management_software_for_france:*:*:*:*:*:*:*:*",
"Oracle Corporation=Human Resources":"cpe:2.3:a:oracle:human_resources:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Essbase Administration Services":"cpe:2.3:a:oracle:hyperion:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Financial Reporting":"cpe:2.3:a:oracle:hyperion:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Analytic Provider Services":"cpe:2.3:a:oracle:hyperion_analytic_provider_services:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion BI+":"cpe:2.3:a:oracle:hyperion_bi\+:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Common Events":"cpe:2.3:a:oracle:hyperion_common_events:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Data Relationship Management":"cpe:2.3:a:oracle:hyperion_data_relationship_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Profitability and Cost Management":"cpe:2.3:a:oracle:hyperion_enterprise_performance_management_architect:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Essbase Administration Services":"cpe:2.3:a:oracle:hyperion_essbase_administration_services:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Financial Close Management":"cpe:2.3:a:oracle:hyperion_financial_close_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Financial Management":"cpe:2.3:a:oracle:hyperion_financial_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Financial Reporting":"cpe:2.3:a:oracle:hyperion_financial_reporting:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Infrastructure Technology":"cpe:2.3:a:oracle:hyperion_infrastructure_technology:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Lifecycle Management":"cpe:2.3:a:oracle:hyperion_lifecycle_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion Planning":"cpe:2.3:a:oracle:hyperion_planning:*:*:*:*:*:*:*:*",
"Oracle Corporation=Hyperion BI+":"cpe:2.3:a:oracle:hyperion_workspace:*:*:*:*:*:*:*:*",
"Oracle Corporation=Identity Analytics":"cpe:2.3:a:oracle:identity_analytics:*:*:*:*:*:*:*:*",
"Oracle Corporation=Identity Manager":"cpe:2.3:a:oracle:identity_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Identity Manager Connector":"cpe:2.3:a:oracle:identity_manager_connector:*:*:*:*:*:*:*:*",
"Oracle Corporation=iLearning":"cpe:2.3:a:oracle:ilearning:*:*:*:*:*:*:*:*",
"Oracle Corporation=Incentive Compensation":"cpe:2.3:a:oracle:incentive_compensation:*:*:*:*:*:*:*:*",
"Oracle Corporation=Installed Base":"cpe:2.3:a:oracle:installed_base:*:*:*:*:*:*:*:*",
"Oracle=Installed Base":"cpe:2.3:a:oracle:installed_base:*:*:*:*:*:*:*:*",
"Oracle Corporation=Insurance Accounting Analyzer":"cpe:2.3:a:oracle:insurance_accounting_analyzer:*:*:*:*:*:*:*:*",
"Oracle=Interaction Blending":"cpe:2.3:a:oracle:interaction_blending:*:*:*:*:*:*:*:*",
"Oracle Corporation=Interaction Center Intelligence":"cpe:2.3:a:oracle:interaction_center_intelligence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Internet Directory":"cpe:2.3:a:oracle:internet_directory:*:*:*:*:*:*:*:*",
"Oracle Corporation=Internet Expenses":"cpe:2.3:a:oracle:internet_expenses:*:*:*:*:*:*:*:*",
"Oracle Corporation=iPlanet Web Server":"cpe:2.3:a:oracle:iplanet_web_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=iProcurement":"cpe:2.3:a:oracle:iprocurement:*:*:*:*:*:*:*:*",
"Oracle Corporation=iReceivables":"cpe:2.3:a:oracle:ireceivables:*:*:*:*:*:*:*:*",
"Oracle Corporation=iRecruitment":"cpe:2.3:a:oracle:irecruitment:*:*:*:*:*:*:*:*",
"Oracle Corporation=iSetup":"cpe:2.3:a:oracle:isetup:*:*:*:*:*:*:*:*",
"Oracle Corporation=iStore":"cpe:2.3:a:oracle:istore:*:*:*:*:*:*:*:*",
"Oracle=iStore":"cpe:2.3:a:oracle:istore:*:*:*:*:*:*:*:*",
"Oracle Corporation=iSupplier Portal":"cpe:2.3:a:oracle:isupplier_portal:*:*:*:*:*:*:*:*",
"Oracle Corporation=iSupport":"cpe:2.3:a:oracle:isupport:*:*:*:*:*:*:*:*",
"Oracle=iSupport":"cpe:2.3:a:oracle:isupport:*:*:*:*:*:*:*:*",
"Oracle Corporation=Java":"cpe:2.3:a:oracle:java_advanced_management_console:*:*:*:*:*:*:*:*",
"Oracle Corporation=Java ME - Specifications":"cpe:2.3:a:oracle:java_me:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:java_virtual_machine:*:*:*:*:*:*:*:*",
"Oracle Corporation=JD Edwards EnterpriseOne Orchestrator":"cpe:2.3:a:oracle:jd_edwards_enterpriseone_orchestrator:*:*:*:*:*:*:*:*",
"Oracle Corporation=JD Edwards EnterpriseOne Tools":"cpe:2.3:a:oracle:jd_edwards_enterpriseone_tools:*:*:*:*:*:*:*:*",
"Oracle Corporation=JD Edwards World Technical Foundation":"cpe:2.3:a:oracle:jd_edwards_world_technical_foundation:*:*:*:*:*:*:*:*",
"Oracle Corporation=JDeveloper":"cpe:2.3:a:oracle:jdeveloper:*:*:*:*:*:*:*:*",
"Oracle=JDeveloper":"cpe:2.3:a:oracle:jdeveloper:*:*:*:*:*:*:*:*",
"Oracle Corporation=Knowledge":"cpe:2.3:a:oracle:knowledge:*:*:*:*:*:*:*:*",
"Oracle Corporation=Knowledge":"cpe:2.3:a:oracle:knowledge_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Knowledge Management":"cpe:2.3:a:oracle:knowledge_management:*:*:*:*:*:*:*:*",
"Oracle=Knowledge Management":"cpe:2.3:a:oracle:knowledge_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Labor Distribution":"cpe:2.3:a:oracle:labor_distribution:*:*:*:*:*:*:*:*",
"Oracle Corporation=Landed Cost Management":"cpe:2.3:a:oracle:landed_cost_management:*:*:*:*:*:*:*:*",
"Oracle=Leads Management":"cpe:2.3:a:oracle:leads_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Learning Management":"cpe:2.3:a:oracle:learning_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Lease and Finance Management":"cpe:2.3:a:oracle:lease_and_finance_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Loans":"cpe:2.3:a:oracle:loans:*:*:*:*:*:*:*:*",
"Oracle Corporation=Managed File Transfer":"cpe:2.3:a:oracle:managed_file_transfer:*:*:*:*:*:*:*:*",
"Oracle Corporation=MES for Process Manufacturing":"cpe:2.3:a:oracle:manufacturing_execution_system_for_process_manufacturing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Marketing":"cpe:2.3:a:oracle:marketing:*:*:*:*:*:*:*:*",
"Oracle=Marketing":"cpe:2.3:a:oracle:marketing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Marketing Encyclopedia System":"cpe:2.3:a:oracle:marketing_encyclopedia_system:*:*:*:*:*:*:*:*",
"Oracle Corporation=MICROS BellaVita":"cpe:2.3:a:oracle:micros_bellavita:*:*:*:*:*:*:*:*",
"Oracle Corporation=MICROS Lucas":"cpe:2.3:a:oracle:micros_lucas:*:*:*:*:*:*:*:*",
"Oracle Corporation=MICROS Relate CRM Software":"cpe:2.3:a:oracle:micros_relate_customer_relationship_management_software:*:*:*:*:*:*:*:*",
"Oracle Corporation=MICROS Retail-J":"cpe:2.3:a:oracle:micros_retail-j:*:*:*:*:*:*:*:*",
"Oracle Corporation=Mobile Field Service":"cpe:2.3:a:oracle:mobile_field_service:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Installer":"cpe:2.3:a:oracle:mysql:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Server":"cpe:2.3:a:oracle:mysql:*:*:*:*:*:*:*:*",
"Oracle=MySQL Server":"cpe:2.3:a:oracle:mysql:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Cluster":"cpe:2.3:a:oracle:mysql_cluster:*:*:*:*:*:*:*:*",
"Oracle=MySQL Cluster":"cpe:2.3:a:oracle:mysql_cluster:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Connectors":"cpe:2.3:a:oracle:mysql_connector\/j:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Connectors":"cpe:2.3:a:oracle:mysql_connector\/net:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Connectors":"cpe:2.3:a:oracle:mysql_connectors:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Enterprise Monitor":"cpe:2.3:a:oracle:mysql_enterprise_monitor:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Installer":"cpe:2.3:a:oracle:mysql_installer:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Server":"cpe:2.3:a:oracle:mysql_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Server":"cpe:2.3:a:oracle:mysql_shell:*:*:*:*:*:*:*:*",
"Oracle Corporation=MySQL Workbench":"cpe:2.3:a:oracle:mysql_workbench:*:*:*:*:*:*:*:*",
"Oracle Corporation=One-to-One Fulfillment":"cpe:2.3:a:oracle:one-to-one_fulfillment:*:*:*:*:*:*:*:*",
"Oracle=One-to-One Fulfillment":"cpe:2.3:a:oracle:one-to-one_fulfillment:*:*:*:*:*:*:*:*",
"Oracle Corporation=OpenGrok":"cpe:2.3:a:oracle:opengrok:*:*:*:*:*:*:*:*",
"Oracle Corporation=Operations Intelligence":"cpe:2.3:a:oracle:operations_intelligence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Customer Management and Segmentation Foundation":"cpe:2.3:a:oracle:oracle_retail_customer_engagement:*:*:*:*:*:*:*:*",
"Oracle Corporation=Order Management":"cpe:2.3:a:oracle:order_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=OSS Support Tools":"cpe:2.3:a:oracle:oss_support_tools:*:*:*:*:*:*:*:*",
"Oracle Corporation=Outside In Technology":"cpe:2.3:a:oracle:outside_in_technology:*:*:*:*:*:*:*:*",
"Oracle Corporation=Text":"cpe:2.3:a:oracle:outside_in_technology:*:*:*:*:*:*:*:*",
"Oracle=Outside In Technology":"cpe:2.3:a:oracle:outside_in_technology:*:*:*:*:*:*:*:*",
"Oracle Corporation=Partner Management":"cpe:2.3:a:oracle:partner_management:*:*:*:*:*:*:*:*",
"Oracle=Partner Management":"cpe:2.3:a:oracle:partner_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Payables":"cpe:2.3:a:oracle:payables:*:*:*:*:*:*:*:*",
"Oracle Corporation=Payroll":"cpe:2.3:a:oracle:payables:*:*:*:*:*:*:*:*",
"Oracle Corporation=Payment Gateway Services":"cpe:2.3:a:oracle:payment_interface:*:*:*:*:*:*:*:*",
"Oracle Corporation=Payments":"cpe:2.3:a:oracle:payments:*:*:*:*:*:*:*:*",
"Oracle Corporation=Payroll":"cpe:2.3:a:oracle:payroll:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM eBill Payment":"cpe:2.3:a:oracle:peoplesoft_ebill_payment:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Cash Management":"cpe:2.3:a:oracle:peoplesoft_enterprise:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise PT PeopleTools":"cpe:2.3:a:oracle:peoplesoft_enterprise:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM Purchasing":"cpe:2.3:a:oracle:peoplesoft_enterprise:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM eProcurement":"cpe:2.3:a:oracle:peoplesoft_enterprise:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CS Campus Community":"cpe:2.3:a:oracle:peoplesoft_enterprise_campus_software_campus_community:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CC Common Application Objects":"cpe:2.3:a:oracle:peoplesoft_enterprise_common_components:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CC Common Application Objects":"cpe:2.3:a:oracle:peoplesoft_enterprise_cost_center_common_application_objects:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CS Academic Advisement":"cpe:2.3:a:oracle:peoplesoft_enterprise_cs_academic_advisement:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CS Campus Community":"cpe:2.3:a:oracle:peoplesoft_enterprise_cs_campus_community:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CS Financial Aid":"cpe:2.3:a:oracle:peoplesoft_enterprise_cs_financial_aid:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CS SA Integration Pack":"cpe:2.3:a:oracle:peoplesoft_enterprise_cs_sa_integration_pack:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise CS Student Records":"cpe:2.3:a:oracle:peoplesoft_enterprise_cs_student_records:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN eSettlements":"cpe:2.3:a:oracle:peoplesoft_enterprise_esettlements:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Payables":"cpe:2.3:a:oracle:peoplesoft_enterprise_fin_payables:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Project Costing":"cpe:2.3:a:oracle:peoplesoft_enterprise_fin_project_costing:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Receivables":"cpe:2.3:a:oracle:peoplesoft_enterprise_fin_receivables:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Staffing Front Office":"cpe:2.3:a:oracle:peoplesoft_enterprise_fin_staffing_front_office:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Candidate Gateway":"cpe:2.3:a:oracle:peoplesoft_enterprise_hcm_candidate_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Shared Components":"cpe:2.3:a:oracle:peoplesoft_enterprise_hcm_shared_components:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise PT PeopleTools":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Absence Management":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_absence_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Candidate Gateway":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_candidate_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Time and Labor":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_candidate_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM eProfile Manager Desktop":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_eprofile_manager_desktop:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Global Payroll Core":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_global_payroll_core:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Human Resources":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_human_resources:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Shared Components":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_shared_components:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Talent Acquisition Manager":"cpe:2.3:a:oracle:peoplesoft_enterprise_human_capital_management_talent_acquisition_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise PRTL Interaction Hub":"cpe:2.3:a:oracle:peoplesoft_enterprise_interaction_hub:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise ELM Enterprise Learning Management":"cpe:2.3:a:oracle:peoplesoft_enterprise_learning_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise PT PeopleTools":"cpe:2.3:a:oracle:peoplesoft_enterprise_peopletools:*:*:*:*:*:*:*:*",
"Oracle=PeopleSoft Enterprise PT PeopleTools":"cpe:2.3:a:oracle:peoplesoft_enterprise_peopletools:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise PRTL Interaction Hub":"cpe:2.3:a:oracle:peoplesoft_enterprise_prtl_interaction_hub:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise PT PeopleTools":"cpe:2.3:a:oracle:peoplesoft_enterprise_pt_peopletools:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM eProcurement":"cpe:2.3:a:oracle:peoplesoft_enterprise_scm_eprocurement:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM eSupplier Connection":"cpe:2.3:a:oracle:peoplesoft_enterprise_scm_esupplier_connection:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM Purchasing":"cpe:2.3:a:oracle:peoplesoft_enterprise_scm_purchasing:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM Services Procurement":"cpe:2.3:a:oracle:peoplesoft_enterprise_scm_services_procurement:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise SCM Strategic Sourcing":"cpe:2.3:a:oracle:peoplesoft_enterprise_scm_strategic_sourcing:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Staffing Front Office":"cpe:2.3:a:oracle:peoplesoft_enterprise_staffing_front_office:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise HCM Candidate Gateway":"cpe:2.3:a:oracle:peoplesoft_human_resource_management_systems:*:*:*:*:*:*:*:*",
"Oracle Corporation=PeopleSoft Enterprise FIN Expenses":"cpe:2.3:a:oracle:peoplesoft_products:*:*:*:*:*:*:*:*",
"Oracle Corporation=Platform Security for Java":"cpe:2.3:a:oracle:platform_security_for_java:*:*:*:*:*:*:*:*",
"Oracle Corporation=Primavera Gateway":"cpe:2.3:a:oracle:primavera_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=Primavera P6 Enterprise Project Portfolio Management":"cpe:2.3:a:oracle:primavera_p6_enterprise_project_portfolio_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Primavera P6 Professional Project Management":"cpe:2.3:a:oracle:primavera_p6_enterprise_project_portfolio_management:*:*:*:*:*:*:*:*",
"Oracle=Primavera P6 Enterprise Project Portfolio Management":"cpe:2.3:a:oracle:primavera_p6_enterprise_project_portfolio_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Primavera Portfolio Management":"cpe:2.3:a:oracle:primavera_portfolio_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Primavera Unifier":"cpe:2.3:a:oracle:primavera_unifier:*:*:*:*:*:*:*:*",
"Oracle Corporation=Product Hub":"cpe:2.3:a:oracle:product_hub:*:*:*:*:*:*:*:*",
"Oracle Corporation=Project Contracts":"cpe:2.3:a:oracle:project_contracts:*:*:*:*:*:*:*:*",
"Oracle Corporation=Project Costing":"cpe:2.3:a:oracle:project_costing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Projects":"cpe:2.3:a:oracle:projects:*:*:*:*:*:*:*:*",
"Oracle Corporation=Public Sector Financials (International)":"cpe:2.3:a:oracle:public_sector_financials:*:*:*:*:*:*:*:*",
"Oracle Corporation=Purchasing":"cpe:2.3:a:oracle:purchasing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Quoting":"cpe:2.3:a:oracle:quoting:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:rdbms_scheduler:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:rdbms_sharding:*:*:*:*:*:*:*:*",
"Oracle Corporation=Real-Time Scheduler":"cpe:2.3:a:oracle:real-time_scheduler:*:*:*:*:*:*:*:*",
"Oracle Corporation=Receivables":"cpe:2.3:a:oracle:receivables:*:*:*:*:*:*:*:*",
"Oracle Corporation=Reports Developer":"cpe:2.3:a:oracle:reports_developer:*:*:*:*:*:*:*:*",
"gloriafood=Restaurant Menu     Food Ordering System     Table Reservation":"cpe:2.3:a:oracle:restaurant_menu_-_food_ordering_system_-_table_reservation:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Back Office":"cpe:2.3:a:oracle:retail_back_office:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Bulk Data Integration":"cpe:2.3:a:oracle:retail_bulk_data_integration:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Central Office":"cpe:2.3:a:oracle:retail_central_office:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Convenience Store Back Office":"cpe:2.3:a:oracle:retail_convenience_store_back_office:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Customer Management and Segmentation Foundation":"cpe:2.3:a:oracle:retail_customer_management_and_segmentation_foundation:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Integration Bus":"cpe:2.3:a:oracle:retail_integration_bus:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Invoice Matching":"cpe:2.3:a:oracle:retail_invoice_matching:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Merchandising System":"cpe:2.3:a:oracle:retail_merchandising_system:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Open Commerce Platform Cloud Service":"cpe:2.3:a:oracle:retail_open_commerce_platform:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Open Commerce Platform Cloud Service":"cpe:2.3:a:oracle:retail_open_commerce_platform_cloud_service:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Point-of-Service":"cpe:2.3:a:oracle:retail_point-of-service:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Returns Management":"cpe:2.3:a:oracle:retail_returns_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Store Inventory Management":"cpe:2.3:a:oracle:retail_store_inventory_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Warehouse Management System":"cpe:2.3:a:oracle:retail_warehouse_management_system:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Xstore Office":"cpe:2.3:a:oracle:retail_xstore_office:*:*:*:*:*:*:*:*",
"Oracle Corporation=MICROS Xstore Payment":"cpe:2.3:a:oracle:retail_xstore_payment:*:*:*:*:*:*:*:*",
"Oracle Corporation=Retail Xstore Point of Service":"cpe:2.3:a:oracle:retail_xstore_point_of_service:*:*:*:*:*:*:*:*",
"Oracle Corporation=Financial Services Revenue Management and Billing":"cpe:2.3:a:oracle:revenue_management_and_billing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sales for Handhelds":"cpe:2.3:a:oracle:sales_for_handhelds:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sales Offline":"cpe:2.3:a:oracle:sales_offline:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:scheduler:*:*:*:*:*:*:*:*",
"Oracle Corporation=Scripting":"cpe:2.3:a:oracle:scripting:*:*:*:*:*:*:*:*",
"Oracle Corporation=SD-WAN Aware":"cpe:2.3:a:oracle:sd-wan_aware:*:*:*:*:*:*:*:*",
"Oracle Corporation=SD-WAN Edge":"cpe:2.3:a:oracle:sd-wan_edge:*:*:*:*:*:*:*:*",
"Oracle Corporation=Secure Global Desktop":"cpe:2.3:a:oracle:secure_global_desktop:*:*:*:*:*:*:*:*",
"Oracle Corporation=Security Service":"cpe:2.3:a:oracle:security_service:*:*:*:*:*:*:*:*",
"Oracle Corporation=Self-Service Human Resources":"cpe:2.3:a:oracle:self-service_human_resources:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - Server Framework":"cpe:2.3:a:oracle:server_bizlogic_script:*:*:*:*:*:*:*:*",
"Oracle Corporation=Service Bus":"cpe:2.3:a:oracle:service_bus:*:*:*:*:*:*:*:*",
"Oracle Corporation=Service Contracts":"cpe:2.3:a:oracle:service_contracts:*:*:*:*:*:*:*:*",
"Oracle=Service Fulfillment Manager":"cpe:2.3:a:oracle:service_fulfillment_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=Service Intelligence":"cpe:2.3:a:oracle:service_intelligence:*:*:*:*:*:*:*:*",
"Oracle Corporation=Shipping Execution":"cpe:2.3:a:oracle:shipping_execution:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - Server Framework":"cpe:2.3:a:oracle:siebel_core-server_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - Common Components":"cpe:2.3:a:oracle:siebel_core_-_common_components:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - DB Deployment and Configuration":"cpe:2.3:a:oracle:siebel_core_-_db_deployment_and_configuration_accessible_data:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - Server Framework":"cpe:2.3:a:oracle:siebel_core_-_server_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - DB Deployment and Configuration":"cpe:2.3:a:oracle:siebel_crm:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Core - Server Framework":"cpe:2.3:a:oracle:siebel_crm:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel UI Framework":"cpe:2.3:a:oracle:siebel_crm:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel CRM Desktop":"cpe:2.3:a:oracle:siebel_customer_relationship_management_desktop:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Engineering - Installer and Deployment":"cpe:2.3:a:oracle:siebel_engineering-installer_and_deployment:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel Apps - Marketing":"cpe:2.3:a:oracle:siebel_marketing:*:*:*:*:*:*:*:*",
"Oracle Corporation=Siebel UI Framework":"cpe:2.3:a:oracle:siebel_ui_framework:*:*:*:*:*:*:*:*",
"Oracle=Siebel UI Framework":"cpe:2.3:a:oracle:siebel_ui_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Site Hub":"cpe:2.3:a:oracle:site_hub:*:*:*:*:*:*:*:*",
"Oracle Corporation=Business Process Management Suite":"cpe:2.3:a:oracle:soa_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=SOA Suite":"cpe:2.3:a:oracle:soa_suite:*:*:*:*:*:*:*:*",
"Oracle Corporation=Social Network":"cpe:2.3:a:oracle:social_network:*:*:*:*:*:*:*:*",
"Oracle Corporation=Solaris Operating System":"cpe:2.3:a:oracle:solaris:*:*:*:*:*:*:*:*",
"Oracle Corporation=Solaris Cluster":"cpe:2.3:a:oracle:solaris_cluster:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sourcing":"cpe:2.3:a:oracle:sourcing:*:*:*:*:*:*:*:*",
"Oracle Corporation=SPARC - Sun System Firmware - NPE":"cpe:2.3:a:oracle:sparc-sun_system_firmware:*:*:*:*:*:*:*:*",
"Oracle Corporation=SQL Developer":"cpe:2.3:a:oracle:sql_developer:*:*:*:*:*:*:*:*",
"Oracle Corporation=SQL*Plus":"cpe:2.3:a:oracle:sql_plus:*:*:*:*:*:*:*:*",
"Oracle Corporation=Cloud Infrastructure":"cpe:2.3:a:oracle:storage_cloud_software_appliance:*:*:*:*:*:*:*:*",
"Oracle Corporation=Subledger Accounting":"cpe:2.3:a:oracle:subledger_accounting:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle NetSuite service":"cpe:2.3:a:oracle:suitecommerce_advanced:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sun ZFS Storage Appliance Kit (AK) Software":"cpe:2.3:a:oracle:sun_zfs_storage_appliance_kit:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sun ZFS Storage Appliance Kit (AK) Software":"cpe:2.3:a:oracle:sun_zfs_storage_appliance_kit_software:*:*:*:*:*:*:*:*",
"Oracle Corporation=SuperCluster Specific Software":"cpe:2.3:a:oracle:supercluster_specific_software:*:*:*:*:*:*:*:*",
"Oracle Corporation=Territory Management":"cpe:2.3:a:oracle:territory_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Text":"cpe:2.3:a:oracle:text:*:*:*:*:*:*:*:*",
"Oracle Corporation=Time and Labor":"cpe:2.3:a:oracle:time_and_labor:*:*:*:*:*:*:*:*",
"Oracle Corporation=Trade Management":"cpe:2.3:a:oracle:trade_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Transportation Execution":"cpe:2.3:a:oracle:transportation_execution:*:*:*:*:*:*:*:*",
"Oracle Corporation=Transportation Management":"cpe:2.3:a:oracle:transportation_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Tuxedo":"cpe:2.3:a:oracle:tuxedo:*:*:*:*:*:*:*:*",
"Oracle Corporation=Unified Directory":"cpe:2.3:a:oracle:unified_directory:*:*:*:*:*:*:*:*",
"Oracle Corporation=Universal Work Queue":"cpe:2.3:a:oracle:universal_work_queue:*:*:*:*:*:*:*:*",
"Oracle=Universal Work Queue":"cpe:2.3:a:oracle:universal_work_queue:*:*:*:*:*:*:*:*",
"Oracle Corporation=User Management":"cpe:2.3:a:oracle:user_management:*:*:*:*:*:*:*:*",
"Oracle Corporation=Utilities Framework":"cpe:2.3:a:oracle:utilities_framework:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Virtual Directory":"cpe:2.3:a:oracle:virtual_directory:*:*:*:*:*:*:*:*",
"Oracle Corporation=Virtual Directory":"cpe:2.3:a:oracle:virtual_directory:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle VM VirtualBox":"cpe:2.3:a:oracle:vm_virtualbox:*:*:*:*:*:*:*:*",
"Oracle Corporation=VM VirtualBox":"cpe:2.3:a:oracle:vm_virtualbox:*:*:*:*:*:*:*:*",
"Oracle=VM VirtualBox":"cpe:2.3:a:oracle:vm_virtualbox:*:*:*:*:*:*:*:*",
"Oracle Corporation=Web Analytics":"cpe:2.3:a:oracle:web_analytics:*:*:*:*:*:*:*:*",
"Oracle Corporation=Web Applications Desktop Integrator":"cpe:2.3:a:oracle:web_applications_desktop_integrator:*:*:*:*:*:*:*:*",
"Oracle Corporation=Web Cache":"cpe:2.3:a:oracle:web_cache:*:*:*:*:*:*:*:*",
"Oracle Corporation=Web Services":"cpe:2.3:a:oracle:web_services:*:*:*:*:*:*:*:*",
"Oracle Corporation=Web Services Manager":"cpe:2.3:a:oracle:web_services_manager:*:*:*:*:*:*:*:*",
"Oracle Corporation=WebCenter Content":"cpe:2.3:a:oracle:webcenter_content:*:*:*:*:*:*:*:*",
"Oracle Corporation=WebCenter Portal":"cpe:2.3:a:oracle:webcenter_portal:*:*:*:*:*:*:*:*",
"Oracle Corporation=WebCenter Sites":"cpe:2.3:a:oracle:webcenter_sites:*:*:*:*:*:*:*:*",
"Oracle Corporation=WebCenter Sites":"cpe:2.3:a:oracle:webcenter_sites_support_tools:*:*:*:*:*:*:*:*",
"Oracle Corporation=TopLink":"cpe:2.3:a:oracle:weblogic_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=WebLogic Server":"cpe:2.3:a:oracle:weblogic_server:*:*:*:*:*:*:*:*",
"Oracle=WebLogic Server":"cpe:2.3:a:oracle:weblogic_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Work in Process":"cpe:2.3:a:oracle:work_in_process:*:*:*:*:*:*:*:*",
"Oracle Corporation=Work in Process":"cpe:2.3:a:oracle:work_in_progress:*:*:*:*:*:*:*:*",
"Oracle Corporation=Workflow":"cpe:2.3:a:oracle:workflow:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:xml_database:*:*:*:*:*:*:*:*",
"Oracle=XML Gateway":"cpe:2.3:a:oracle:xml_gateway:*:*:*:*:*:*:*:*",
"Oracle Corporation=Database - Enterprise Edition":"cpe:2.3:a:oracle:zero_downtime_db_migration_to_cloud:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sun ZFS Storage Appliance Kit (AK) Software":"cpe:2.3:a:oracle:zfs_storage_appliance_kit:*:*:*:*:*:*:*:*",
"Orangescrum=Orangescrum":"cpe:2.3:a:orangescrum:orangescrum:*:*:*:*:*:*:*:*",
"OrchardCore=OrchardCore":"cpe:2.3:a:orchardcore:orchardcore:*:*:*:*:*:*:*:*",
"orchardcms=orchardcms/orchardcore":"cpe:2.3:a:orchardcore:orchardcore:*:*:*:*:*:*:*:*",
"orchest=orchest":"cpe:2.3:a:orchest:orchest:*:*:*:*:*:*:*:*",
"orchidsoftware=platform":"cpe:2.3:a:orchid:platform:*:*:*:*:*:*:*:*",
"Orckestra=C1 CMS":"cpe:2.3:a:orckestra:c1_cms:*:*:*:*:*:*:*:*",
"Orckestra=C1-CMS-Foundation":"cpe:2.3:a:orckestra:c1_cms:*:*:*:*:*:*:*:*",
"SourceCodester=AC Repair and Services System":"cpe:2.3:a:oretnom23:ac_repair_and_services_system:*:*:*:*:*:*:*:*",
"SourceCodester=Budget and Expense Tracker System":"cpe:2.3:a:oretnom23:budget_and_expense_tracker_system:*:*:*:*:*:*:*:*",
"SourceCodester=Cashier Queuing System":"cpe:2.3:a:oretnom23:cashier_queuing_system:*:*:*:*:*:*:*:*",
"SourceCodester=Clinic Queuing System":"cpe:2.3:a:oretnom23:clinic_queuing_system:*:*:*:*:*:*:*:*",
"SourceCodester=Earnings and Expense Tracker App":"cpe:2.3:a:oretnom23:earnings_and_expense_tracker_application:*:*:*:*:*:*:*:*",
"CodeAstro=Expense Management System":"cpe:2.3:a:oretnom23:expense_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Expense Tracker App":"cpe:2.3:a:oretnom23:expense_tracker:*:*:*:*:*:*:*:*",
"SourceCodester=Facebook News Feed Like":"cpe:2.3:a:oretnom23:facebook_news_feed_like:*:*:*:*:*:*:*:*",
"SourceCodester=House Rental Management System":"cpe:2.3:a:oretnom23:house_rental_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Human Resource Management System":"cpe:2.3:a:oretnom23:human_resource_management_system:*:*:*:*:*:*:*:*",
"unspecified=Human Resource Management System":"cpe:2.3:a:oretnom23:human_resource_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Lost and Found Information System":"cpe:2.3:a:oretnom23:lost_and_found_information_system:*:*:*:*:*:*:*:*",
"SourceCodester=Medicine Tracker System":"cpe:2.3:a:oretnom23:medicine_tracker_system:*:*:*:*:*:*:*:*",
"SourceCodester=Medicine Tracking System":"cpe:2.3:a:oretnom23:medicine_tracker_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Computer and Laptop Store":"cpe:2.3:a:oretnom23:online_computer_and_laptop_store:*:*:*:*:*:*:*:*",
"SourceCodester=Online Medicine Ordering System":"cpe:2.3:a:oretnom23:online_medicine_ordering_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Motorcycle Rental System":"cpe:2.3:a:oretnom23:online_motorcycle_\(bike\)_rental_system:*:*:*:*:*:*:*:*",
"SourceCodester=Online Pizza Ordering System":"cpe:2.3:a:oretnom23:online_pizza_ordering_system:*:*:*:*:*:*:*:*",
"SourceCodester=Service Provider Management System":"cpe:2.3:a:oretnom23:service_provider_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Cold Storage Management System":"cpe:2.3:a:oretnom23:simple_cold_storage_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Image Stack Website":"cpe:2.3:a:oretnom23:simple_image_stack_website:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Invoice Generator System":"cpe:2.3:a:oretnom23:simple_invoice_generator_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Student Attendance System":"cpe:2.3:a:oretnom23:simple_student_attendance_system:*:*:*:*:*:*:*:*",
"SourceCodester=Task Reminder System":"cpe:2.3:a:oretnom23:task_reminder_system:*:*:*:*:*:*:*:*",
"SourceCodester=Vehicle Service Management System":"cpe:2.3:a:oretnom23:vehicle_service_management_system:*:*:*:*:*:*:*:*",
"Project Worlds=Visitor Management System":"cpe:2.3:a:oretnom23:visitor_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Medicine Tracking System":"cpe:2.3:a:oretnom:medicine_tracker_system:*:*:*:*:*:*:*:*",
"causefx=causefx/organizr":"cpe:2.3:a:organizr:organizr:*:*:*:*:*:*:*:*",
"OriginProtocol=origin-website":"cpe:2.3:a:originprotocol:origin_website:*:*:*:*:*:*:*:*",
"Origin Software=ATS Pro":"cpe:2.3:a:orjinyazilim:ats_pro:*:*:*:*:*:*:*:*",
"oroinc=crm":"cpe:2.3:a:oroinc:client_relationship_management:*:*:*:*:*:*:*:*",
"oroinc=orocommerce":"cpe:2.3:a:oroinc:orocommerce:*:*:*:*:*:*:*:*",
"oroinc=crm":"cpe:2.3:a:oroinc:oroplatform:*:*:*:*:*:*:*:*",
"oroinc=platform":"cpe:2.3:a:oroinc:oroplatform:*:*:*:*:*:*:*:*",
"Orthanc=Osimis DICOM Web Viewer":"cpe:2.3:a:orthanc-server:osimis_web_viewer:*:*:*:*:*:*:*:*",
"Ortus Solutions=ColdBox Elixir":"cpe:2.3:a:ortussolutions:coldbox_elixir:*:*:*:*:*:*:*:*",
"ory=fosite":"cpe:2.3:a:ory:fosite:*:*:*:*:*:*:*:*",
"ory=hydra":"cpe:2.3:a:ory:hydra:*:*:*:*:*:*:*:*",
"Red Hat=osbs-client":"cpe:2.3:a:osbs-client_project:osbs-client:*:*:*:*:*:*:*:*",
"Os Commerce=Os Commerce":"cpe:2.3:a:oscommerce:oscommerce:*:*:*:*:*:*:*:*",
"geoserver=geoserver":"cpe:2.3:a:osgeo:geoserver:*:*:*:*:*:*:*:*",
"geopython=OWSLib":"cpe:2.3:a:osgeo:owslib:*:*:*:*:*:*:*:*",
"OSIsoft=PI Coresight":"cpe:2.3:a:osisoft:pi_coresight:*:*:*:*:*:*:*:*",
"OSIsoft=PI Data Archive":"cpe:2.3:a:osisoft:pi_data_archive:*:*:*:*:*:*:*:*",
"OSIsoft=PI Vision":"cpe:2.3:a:osisoft:pi_vision:*:*:*:*:*:*:*:*",
"OSIsoft=PI Web API":"cpe:2.3:a:osisoft:pi_web_api:*:*:*:*:*:*:*:*",
"OSNEXUS=QuantaStor":"cpe:2.3:a:osnexus:quantastor:*:*:*:*:*:*:*:*",
"Osoft=Paint Production Management":"cpe:2.3:a:osoft:dyeing_-_printing_-_finishing_production_management:*:*:*:*:*:*:*:*",
"Thinkingreed Inc.=OSS Calendar":"cpe:2.3:a:oss-calendar:oss_calendar:*:*:*:*:*:*:*:*",
"ossrs=srs":"cpe:2.3:a:ossrs:simple_realtime_server:*:*:*:*:*:*:*:*",
"OpenAM Consortium=OpenAM":"cpe:2.3:a:osstech:openam:*:*:*:*:*:*:*:*",
"OpenAM Consortium=OpenAM (Open Source Edition)":"cpe:2.3:a:osstech:openam:*:*:*:*:*:*:*:*",
"OTRS AG=OTRSCIsInCustomerFrontend ":"cpe:2.3:a:otrs:cis_in_customer_frontend:*:*:*:*:*:*:*:*",
"OTRS AG=OTRSCustomContactFields":"cpe:2.3:a:otrs:custom_contact_fields:*:*:*:*:*:*:*:*",
"Accessing template content without permissions=OTRS":"cpe:2.3:a:otrs:otrs:*:*:*:*:*:*:*:*",
"OTRS AG=OTRS":"cpe:2.3:a:otrs:otrs:*:*:*:*:*:*:*:*",
"OTRS AG=Survey":"cpe:2.3:a:otrs:survey:*:*:*:*:*:*:*:*",
"OTRS AG= OTRSTicketForms":"cpe:2.3:a:otrs:ticket_forms:*:*:*:*:*:*:*:*",
"OTRS AG=Time Accounting":"cpe:2.3:a:otrs:time_accounting:*:*:*:*:*:*:*:*",
"Overwolf Ltd.=The Installer of Overwolf":"cpe:2.3:a:overwolf:overwolf:*:*:*:*:*:*:*:*",
"ovh=the-bastion":"cpe:2.3:a:ovh:the-bastion:*:*:*:*:*:*:*:*",
"ovirt=cockpit-ovirt":"cpe:2.3:a:ovirt:cockpit-ovirt:*:*:*:*:*:*:*:*",
"The ovirt Project=ovirt-engine":"cpe:2.3:a:ovirt:ovirt-engine:*:*:*:*:*:*:*:*",
"[UNKNOWN]=ovirt-engine":"cpe:2.3:a:ovirt:ovirt:*:*:*:*:*:*:*:*",
"Dependency-Track=Dependency-Track":"cpe:2.3:a:owasp:dependency-track:*:*:*:*:*:*:*:*",
"DependencyTrack=dependency-track":"cpe:2.3:a:owasp:dependency-track:*:*:*:*:*:*:*:*",
"DependencyTrack=frontend":"cpe:2.3:a:owasp:dependency-track_frontend:*:*:*:*:*:*:*:*",
"OWASP=NodeGoat":"cpe:2.3:a:owasp:nodegoat:*:*:*:*:*:*:*:*",
"owncast=owncast":"cpe:2.3:a:owncast_project:owncast:*:*:*:*:*:*:*:*",
"owncast=owncast/owncast":"cpe:2.3:a:owncast_project:owncast:*:*:*:*:*:*:*:*",
"biplob018=Image Hover Effects Ultimate (Image Gallery, Effects, Lightbox, Comparison or Magnifier)":"cpe:2.3:a:oxilab:image_hover_effects_ultimate:*:*:*:*:*:*:*:*",
"CapsAdmin=PAC3":"cpe:2.3:a:pac3_project:pac3:*:*:*:*:*:*:*:*",
"packbackbooks=lti-1-3-php-library":"cpe:2.3:a:packback:lti_1.3_tool_library:*:*:*:*:*:*:*:*",
"unspecified=pacman-canvas":"cpe:2.3:a:pacman-canvas_project:pacman-canvas:*:*:*:*:*:*:*:*",
"unspecified=pacparser":"cpe:2.3:a:pacparser_project:pacparser:*:*:*:*:*:*:*:*",
"Pacsrapor=Pacsrapor":"cpe:2.3:a:pacsrapor:pacsrapor:*:*:*:*:*:*:*:*",
"paddlepaddle=paddlepaddle/paddle":"cpe:2.3:a:paddlepaddle:paddle:*:*:*:*:*:*:*:*",
"PaddlePaddle=PaddlePaddle":"cpe:2.3:a:paddlepaddle:paddlepaddle:*:*:*:*:*:*:*:*",
"Paessler=PRTG Network Monitor":"cpe:2.3:a:paessler:prtg_network_monitor:*:*:*:*:*:*:*:*",
"rundeck=rundeck":"cpe:2.3:a:pagerduty:rundeck:*:*:*:*:*:*:*:*",
"pajbot=pajbot":"cpe:2.3:a:pajbot:pajbot:*:*:*:*:*:*:*:*",
"paijp=lte-pic32-writer":"cpe:2.3:a:pajip:lte-pic32-writer:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.apollo:autopilot":"cpe:2.3:a:palantir:apollo_autopilot:*:*:*:*:*:*:*:*",
"Palantir=AtlasDB":"cpe:2.3:a:palantir:atlasdb:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.contour:contour-dispatch":"cpe:2.3:a:palantir:contour:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.foundry:foundry-frontend":"cpe:2.3:a:palantir:foundry:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.lime:lime2":"cpe:2.3:a:palantir:foundry:*:*:*:*:*:*:*:*",
"Palantir=Foundry Blobster Service":"cpe:2.3:a:palantir:foundry_blobster:*:*:*:*:*:*:*:*",
"Palantir=Foundry Build2":"cpe:2.3:a:palantir:foundry_build2:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.campaigns:campaigns":"cpe:2.3:a:palantir:foundry_campaigns:*:*:*:*:*:*:*:*",
"Palantir=Foundry Code-Workbooks":"cpe:2.3:a:palantir:foundry_code-workbooks:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.comments:comments":"cpe:2.3:a:palantir:foundry_comments:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.foundry:foundry-frontend":"cpe:2.3:a:palantir:foundry_frontend:*:*:*:*:*:*:*:*",
"Palantir=Foundry Issues":"cpe:2.3:a:palantir:foundry_issues:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.issues:issues":"cpe:2.3:a:palantir:foundry_issues:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.foundry.jobtracker:job-tracker":"cpe:2.3:a:palantir:foundry_job-tracker:*:*:*:*:*:*:*:*",
"Palantir=Foundry Multipass":"cpe:2.3:a:palantir:foundry_multipass:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.workspace:workspace":"cpe:2.3:a:palantir:foundry_workspace-server:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.acme.cerberus:cerberus":"cpe:2.3:a:palantir:gotham_cerberus:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.magritte:magritte-rest-source-bundle":"cpe:2.3:a:palantir:magritte-rest-source-bundle:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.meta:orbital-simulator":"cpe:2.3:a:palantir:orbital_simulator:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.slate:slate":"cpe:2.3:a:palantir:slate:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.tiles:tiles":"cpe:2.3:a:palantir:tiles:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.video:video-application-server":"cpe:2.3:a:palantir:video-application-server:*:*:*:*:*:*:*:*",
"The Pallets Project=Flask":"cpe:2.3:a:palletsprojects:flask:*:*:*:*:*:*:*:*",
"pallets=flask":"cpe:2.3:a:palletsprojects:flask:*:*:*:*:*:*:*:*",
"pallets=jinja":"cpe:2.3:a:palletsprojects:jinja:*:*:*:*:*:*:*:*",
"pallets=werkzeug":"cpe:2.3:a:palletsprojects:werkzeug:*:*:*:*:*:*:*:*",
"pallidlight=online-course-selection-system":"cpe:2.3:a:pallidlight_online_course_selection_system_project:pallidlight_online_course_selection_system:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Bridgecrew Checkov":"cpe:2.3:a:paloaltonetworks:bridgecrew_checkov:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Cortex XDR Agent":"cpe:2.3:a:paloaltonetworks:cortex_xdr_agent:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Palo Alto Networks Expedition":"cpe:2.3:a:paloaltonetworks:expedition:*:*:*:*:*:*:*:*",
"Palo Alto=Palo Alto Networks Expedition Migration Tool":"cpe:2.3:a:paloaltonetworks:expedition:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Expedition":"cpe:2.3:a:paloaltonetworks:expedition_migration_tool:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Expedition Migration Tool":"cpe:2.3:a:paloaltonetworks:expedition_migration_tool:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Global Protect Agent":"cpe:2.3:a:paloaltonetworks:globalprotect:*:*:*:*:*:*:*:*",
"Palo Alto Networks=MineMeld":"cpe:2.3:a:paloaltonetworks:minemeld:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Traps":"cpe:2.3:a:paloaltonetworks:traps:*:*:*:*:*:*:*:*",
"Panasonic=Control FPWIN Pro":"cpe:2.3:a:panasonic:control_fpwin_pro:*:*:*:*:*:*:*:*",
"Panasonic=FPWIN Pro":"cpe:2.3:a:panasonic:control_fpwin_pro:*:*:*:*:*:*:*:*",
"Panasonic =FPWIN Pro":"cpe:2.3:a:panasonic:fpwin_pro:*:*:*:*:*:*:*:*",
"Panasonic=Control FPWIN Pro":"cpe:2.3:a:panasonic:fpwin_pro:*:*:*:*:*:*:*:*",
"Panasonic=KW Watcher":"cpe:2.3:a:panasonic:kw_watcher:*:*:*:*:*:*:*:*",
"Panasonic=KW Watcher on Windows":"cpe:2.3:a:panasonic:kw_watcher:*:*:*:*:*:*:*:*",
"Panasonic Corporation=Video Insight VMS":"cpe:2.3:a:panasonic:video_insight_vms:*:*:*:*:*:*:*:*",
"Video Insight VMS=7.3.2.5 and earlier":"cpe:2.3:a:panasonic:video_insight_vms:*:*:*:*:*:*:*:*",
"Pandora.tv=KMPlayer":"cpe:2.3:a:pandora:kmplayer:*:*:*:*:*:*:*:*",
"Artica PFMS=Pandora FMS":"cpe:2.3:a:pandorafms:pandora_fms:*:*:*:*:*:*:*:*",
"Pandora FMS=Pandora FMS":"cpe:2.3:a:pandorafms:pandora_fms:*:*:*:*:*:*:*:*",
"ICS-CERT=Pangea Communications Internet FAX ATA":"cpe:2.3:a:pangea-comm:fax_ata:*:*:*:*:*:*:*:*",
"px-org=PanIndex":"cpe:2.3:a:panindex_project:panindex:*:*:*:*:*:*:*:*",
"mpretroff=pannellum":"cpe:2.3:a:pannellum:pannellum:*:*:*:*:*:*:*:*",
"PanteraSoft=HDD Health":"cpe:2.3:a:panterasoft:hdd_health:*:*:*:*:*:*:*:*",
"mholt=PapaParse":"cpe:2.3:a:papaparse:papaparse:*:*:*:*:*:*:*:*",
"PaperCut=PaperCut NG":"cpe:2.3:a:papercut:papercut_ng:*:*:*:*:*:*:*:*",
"Parallels=Desktop":"cpe:2.3:a:parallels:parallels:*:*:*:*:*:*:*:*",
"Parallels=Access":"cpe:2.3:a:parallels:parallels_access:*:*:*:*:*:*:*:*",
"Parallels=Desktop":"cpe:2.3:a:parallels:parallels_desktop:*:*:*:*:*:*:*:*",
"Parallels=Parallels Remote Application Server (Client)":"cpe:2.3:a:parallels:remote_application_server:*:*:*:*:*:*:*:*",
"Parantez Teknoloji=Parantez Teknoloji":"cpe:2.3:a:parantezteknoloji:koha_library_automation:*:*:*:*:*:*:*:*",
"TUBITAK=Lider":"cpe:2.3:a:pardus:liderahenk:*:*:*:*:*:*:*:*",
"parisneo=parisneo/lollms-webui":"cpe:2.3:a:parisneo:lollms_web_ui:*:*:*:*:*:*:*:*",
"Talos=Parity":"cpe:2.3:a:parity:ethereum_client:*:*:*:*:*:*:*:*",
"paritytech=frontier":"cpe:2.3:a:parity:frontier:*:*:*:*:*:*:*:*",
"ollpu=parontalli":"cpe:2.3:a:parontalli_project:parontalli:*:*:*:*:*:*:*:*",
"ionicabizau=ionicabizau/parse-url":"cpe:2.3:a:parse-url_project:parse-url:*:*:*:*:*:*:*:*",
"Parse=parse-server":"cpe:2.3:a:parseplatform:parse-server:*:*:*:*:*:*:*:*",
"parse-community=parse-server":"cpe:2.3:a:parseplatform:parse-server:*:*:*:*:*:*:*:*",
"parse-community=parse-server":"cpe:2.3:a:parseplatform:parse_server:*:*:*:*:*:*:*:*",
"Part-DB=Part-DB-server":"cpe:2.3:a:part-db_project:part-db:*:*:*:*:*:*:*:*",
"part-db=part-db/part-db":"cpe:2.3:a:part-db_project:part-db:*:*:*:*:*:*:*:*",
"Patrowl=PatrowlManager":"cpe:2.3:a:patrowl:patrowlmanager:*:*:*:*:*:*:*:*",
"PaulPrinting=CMS":"cpe:2.3:a:paulprinting_project:paulprinting:*:*:*:*:*:*:*:*",
"LinZhaoguan=pb-cms":"cpe:2.3:a:pb-cms_project:pb-cms:*:*:*:*:*:*:*:*",
"jdhwpgmbca=pcapture":"cpe:2.3:a:pcapture_project:pcapture:*:*:*:*:*:*:*:*",
"PCMan=FTP Server":"cpe:2.3:a:pcman_ftp_server_project:pcman_ftp_server:*:*:*:*:*:*:*:*",
"ARC Informatique=PcVue":"cpe:2.3:a:pcvuesolutions:pcvue:*:*:*:*:*:*:*:*",
"michaelrsweet=pdfio":"cpe:2.3:a:pdfio_project:pdfio:*:*:*:*:*:*:*:*",
"bpampuch=pdfmake":"cpe:2.3:a:pdfmake_project:pdfmake:*:*:*:*:*:*:*:*",
"pedroetb=tts-api":"cpe:2.3:a:pedroetb:tts-api:*:*:*:*:*:*:*:*",
"Pega Systems=Pega Infinity":"cpe:2.3:a:pega:infinity:*:*:*:*:*:*:*:*",
"Pegasystems=Pega Infinity":"cpe:2.3:a:pega:infinity:*:*:*:*:*:*:*:*",
"Pegasystems=Pega Infinity":"cpe:2.3:a:pega:pega_platform:*:*:*:*:*:*:*:*",
"Pegasystems=Pega Platform":"cpe:2.3:a:pega:pega_platform:*:*:*:*:*:*:*:*",
"Pegasystems=Pega Platform":"cpe:2.3:a:pega:platform:*:*:*:*:*:*:*:*",
"Pegasystems=RPA: Synchronization Engine":"cpe:2.3:a:pega:synchronization_engine:*:*:*:*:*:*:*:*",
"VideoXpert=VideoXpert OpsCenter versions prior to 3.1":"cpe:2.3:a:pelco:videoxpert_opscenter:*:*:*:*:*:*:*:*",
"unspecified=Pengu":"cpe:2.3:a:pengu_project:pengu:*:*:*:*:*:*:*:*",
"PerFact=OpenVPN-Client":"cpe:2.3:a:perfact:openvpn-client:*:*:*:*:*:*:*:*",
"Helix=Helix Core":"cpe:2.3:a:perforce:helix_core:*:*:*:*:*:*:*:*",
"Helix=Sync":"cpe:2.3:a:perforce:helix_sync:*:*:*:*:*:*:*:*",
"Puppet=Bolt":"cpe:2.3:a:perforce:puppet_bolt:*:*:*:*:*:*:*:*",
"SourceCodester=Performance Indicator System":"cpe:2.3:a:performance_indicator_system_project:performance_indicator_system:*:*:*:*:*:*:*:*",
"Periscope Holdings=BuySpeed":"cpe:2.3:a:periscopeholdings:buyspeed:*:*:*:*:*:*:*:*",
"perl-CRYPT-JWT=perl-CRYPT-JWT":"cpe:2.3:a:perl-crypt-jwt_project:perl-crypt-jwt:*:*:*:*:*:*:*:*",
"Perl Crypt::JWT=Perl Crypt::JWT":"cpe:2.3:a:perl_crypt\:\:*:*:*:*:*:*:*:*",
"SourceCodester=Personnel Property Equipment System":"cpe:2.3:a:personnel_property_equipment_system_project:personnel_property_equipment_system:*:*:*:*:*:*:*:*",
"MdAlAmin-aol=Own Health Record":"cpe:2.3:a:petrk94:ownhealthrecord:*:*:*:*:*:*:*:*",
"CF Software=Oil Management Software":"cpe:2.3:a:petroleum_management_software_application_project:petroleum_management_software_application:*:*:*:*:*:*:*:*",
"pfSense=pfSense-pkg-WireGuard":"cpe:2.3:a:pfsense:pfsense-pkg-wireguard:*:*:*:*:*:*:*:*",
"PgPool Global Development Group=Pgpool-II":"cpe:2.3:a:pgpool:pgpool-ii:*:*:*:*:*:*:*:*",
"PgPool Global Development Group=PgpoolAdmin":"cpe:2.3:a:pgpool:pgpooladmin:*:*:*:*:*:*:*:*",
"SourceCodester=Pharmacy Management System":"cpe:2.3:a:pharmacy_management_system_project:pharmacy_management_system:*:*:*:*:*:*:*:*",
"codeprojects=Pharmacy Management System":"cpe:2.3:a:pharmacy_management_system_project:pharmacy_management_system:*:*:*:*:*:*:*:*",
"Pharos=PopUp Printer Client":"cpe:2.3:a:pharos:popup:*:*:*:*:*:*:*:*",
"Philips=DoseWise Portal":"cpe:2.3:a:philips:dosewise:*:*:*:*:*:*:*:*",
"Philips=EncoreAnywhere ":"cpe:2.3:a:philips:encoreanywhere:*:*:*:*:*:*:*:*",
"Philips=Engage Software":"cpe:2.3:a:philips:engage:*:*:*:*:*:*:*:*",
"Philips=Philips IntelliSpace Portal":"cpe:2.3:a:philips:intellispace_portal:*:*:*:*:*:*:*:*",
"Philips=Interoperability Solution XDS":"cpe:2.3:a:philips:interoperability_solution_xds:*:*:*:*:*:*:*:*",
"Philips =Patient Information Center iX (PICiX)":"cpe:2.3:a:philips:patient_information_center_ix:*:*:*:*:*:*:*:*",
"Philips=Patient Information Center iX (PIC iX)":"cpe:2.3:a:philips:patient_information_center_ix:*:*:*:*:*:*:*:*",
"Philips=Patient Information Center iX (PICiX)":"cpe:2.3:a:philips:patient_information_center_ix:*:*:*:*:*:*:*:*",
"Philips=SmartControl":"cpe:2.3:a:philips:smartcontrol:*:*:*:*:*:*:*:*",
"Philips=Philips Tasy EMR":"cpe:2.3:a:philips:tasy_emr:*:*:*:*:*:*:*:*",
"PHKP=PHKP":"cpe:2.3:a:phkp_project:phkp:*:*:*:*:*:*:*:*",
"ghostlander=Phoenixcoin":"cpe:2.3:a:phoenixcoin_project:phoenixcoin:*:*:*:*:*:*:*:*",
"PHOENIX CONTACT=FL MGUARD DM (2981974)":"cpe:2.3:a:phoenixcontact:fl_mguard_dm:*:*:*:*:*:*:*:*",
"PHOENIX CONTACT=PLCnext Engineer":"cpe:2.3:a:phoenixcontact:plcnext_engineer:*:*:*:*:*:*:*:*",
"SourceCodester=Phone Shop Sales Managements System":"cpe:2.3:a:phone_shop_sales_managements_system_project:phone_shop_sales_managements_system:*:*:*:*:*:*:*:*",
"sproctor=php-calendar":"cpe:2.3:a:php-calendar:php-calendar:*:*:*:*:*:*:*:*",
"PHPFusion=PHPFusion":"cpe:2.3:a:php-fusion:phpfusion:*:*:*:*:*:*:*:*",
"phpfusion=phpfusion/phpfusion":"cpe:2.3:a:php-fusion:phpfusion:*:*:*:*:*:*:*:*",
"PHP Group=PHP Imagick extension":"cpe:2.3:a:php:imagick:*:*:*:*:*:*:*:*",
"PHP Group=PHP":"cpe:2.3:a:php:php:*:*:*:*:*:*:*:*",
"Kagaminokuni=PHP 2chBBS":"cpe:2.3:a:php_2chbbs_project:php_2chbbs:*:*:*:*:*:*:*:*",
"OECMS=OECMS":"cpe:2.3:a:phpcoo:oecms:*:*:*:*:*:*:*:*",
"PHPSocialNetwork=phpfastcache":"cpe:2.3:a:phpfastcache:phpfastcache:*:*:*:*:*:*:*:*",
"PHPGurukul=Bank Locker Management System":"cpe:2.3:a:phpgurukul:bank_locker_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=BP Monitoring Management System":"cpe:2.3:a:phpgurukul:bp_monitoring_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Company Visitor Management System":"cpe:2.3:a:phpgurukul:company_visitor_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Dairy Farm Shop Management System":"cpe:2.3:a:phpgurukul:dairy_farm_shop_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Hospital Management System":"cpe:2.3:a:phpgurukul:hospital_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Nipah Virus Testing Management System":"cpe:2.3:a:phpgurukul:nipah_virus_testing_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Online Notes Sharing System":"cpe:2.3:a:phpgurukul:online_notes_sharing_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Online Railway Catering System":"cpe:2.3:a:phpgurukul:online_railway_catering_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Online Shopping Portal":"cpe:2.3:a:phpgurukul:online_shopping_portal:*:*:*:*:*:*:*:*",
"PHPGurukul=Rail Pass Management System":"cpe:2.3:a:phpgurukul:rail_pass_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Restaurant Table Booking System":"cpe:2.3:a:phpgurukul:restaurant_table_booking_system:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Student Result Management System":"cpe:2.3:a:phpgurukul:student_result_management_system:*:*:*:*:*:*:*:*",
"PHPGurukul=Teacher Subject Allocation Management System":"cpe:2.3:a:phpgurukul:teacher_subject_allocation_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Zoo Management System":"cpe:2.3:a:phpgurukul:zoo_management_system:*:*:*:*:*:*:*:*",
"unspecified=Zoo Management System":"cpe:2.3:a:phpgurukul:zoo_management_system:*:*:*:*:*:*:*:*",
"phpipam=phpipam/phpipam":"cpe:2.3:a:phpipam:phpipam:*:*:*:*:*:*:*:*",
"unspecified=phpipam":"cpe:2.3:a:phpipam:phpipam:*:*:*:*:*:*:*:*",
"PHP Jabbers=Availability Booking Calendar":"cpe:2.3:a:phpjabbers:availability_booking_calendar:*:*:*:*:*:*:*:*",
"PHP Jabbers=Bus Reservation System":"cpe:2.3:a:phpjabbers:bus_reservation_system:*:*:*:*:*:*:*:*",
"PHP Jabbers=Cleaning Business":"cpe:2.3:a:phpjabbers:cleaning_business_software:*:*:*:*:*:*:*:*",
"PHP Jabbers=Night Club Booking Software":"cpe:2.3:a:phpjabbers:night_club_booking_software:*:*:*:*:*:*:*:*",
"PHP Jabbers=Rental Property Booking":"cpe:2.3:a:phpjabbers:rental_property_booking_calendar:*:*:*:*:*:*:*:*",
"PHP Jabbers=Service Booking Script":"cpe:2.3:a:phpjabbers:service_booking_script:*:*:*:*:*:*:*:*",
"PHP Jabbers=Shuttle Booking Software":"cpe:2.3:a:phpjabbers:shuttle_booking_software:*:*:*:*:*:*:*:*",
"PHP Jabbers=Taxi Booking":"cpe:2.3:a:phpjabbers:taxi_booking_script:*:*:*:*:*:*:*:*",
"phpkobo=Ajax Poll Script":"cpe:2.3:a:phpkobo:ajax_poll_script:*:*:*:*:*:*:*:*",
"unspecified=PHPList":"cpe:2.3:a:phplist:phplist:*:*:*:*:*:*:*:*",
"phpMussel=phpMussel":"cpe:2.3:a:phpmussel_project:phpmussel:*:*:*:*:*:*:*:*",
"phpMyAdmin =phpMyAdmin ":"cpe:2.3:a:phpmyadmin:phpmyadmin:*:*:*:*:*:*:*:*",
"thorsten=phpMyFAQ":"cpe:2.3:a:phpmyfaq:phpmyfaq:*:*:*:*:*:*:*:*",
"thorsten=thorsten/phpmyfaq":"cpe:2.3:a:phpmyfaq:phpmyfaq:*:*:*:*:*:*:*:*",
"PHP Point of Sale LLC=PHP Point of Sale":"cpe:2.3:a:phppointofsale:php_point_of_sale:*:*:*:*:*:*:*:*",
"unspecified=phpRedisAdmin":"cpe:2.3:a:phpredisadmin_project:phpredisadmin:*:*:*:*:*:*:*:*",
"Alanaktion=Phproject":"cpe:2.3:a:phproject:phproject:*:*:*:*:*:*:*:*",
"phpscriptpoint=BloodBank":"cpe:2.3:a:phpscriptpoint:bloodbank:*:*:*:*:*:*:*:*",
"phpscriptpoint=Car Listing":"cpe:2.3:a:phpscriptpoint:car_listing:*:*:*:*:*:*:*:*",
"phpscriptpoint=Ecommerce":"cpe:2.3:a:phpscriptpoint:ecommerce:*:*:*:*:*:*:*:*",
"phpscriptpoint=Insurance":"cpe:2.3:a:phpscriptpoint:insurance:*:*:*:*:*:*:*:*",
"phpscriptpoint=JobSeeker":"cpe:2.3:a:phpscriptpoint:jobseeker:*:*:*:*:*:*:*:*",
"phpscriptpoint=Lawyer":"cpe:2.3:a:phpscriptpoint:lawyer:*:*:*:*:*:*:*:*",
"phpscriptpoint=RecipePoint":"cpe:2.3:a:phpscriptpoint:recipepoint:*:*:*:*:*:*:*:*",
"phpservermon=phpservermon/phpservermon":"cpe:2.3:a:phpservermonitor:php_server_monitor:*:*:*:*:*:*:*:*",
"unspecified=phpservermon":"cpe:2.3:a:phpservermonitor:php_server_monitor:*:*:*:*:*:*:*:*",
"Dojin Club MICMNIS=phpUploader":"cpe:2.3:a:phpuploader_project:phpuploader:*:*:*:*:*:*:*:*",
"slackero=phpwcms":"cpe:2.3:a:phpwcms:phpwcms:*:*:*:*:*:*:*:*",
"phpzag=phpzag live add edit delete data tables records with ajax php mysql":"cpe:2.3:a:phpzag:phpzag:*:*:*:*:*:*:*:*",
"PHZ76=RtspServer":"cpe:2.3:a:phz76:rtspserver:*:*:*:*:*:*:*:*",
"pi-hole=AdminLTE":"cpe:2.3:a:pi-hole:adminlte:*:*:*:*:*:*:*:*",
"pi-hole=AdminLTE":"cpe:2.3:a:pi-hole:pi-hole:*:*:*:*:*:*:*:*",
"pi-hole=pi-hole":"cpe:2.3:a:pi-hole:pi-hole:*:*:*:*:*:*:*:*",
"pi-hole=AdminLTE":"cpe:2.3:a:pi-hole:web_interface:*:*:*:*:*:*:*:*",
"pi-hole=pi-hole/adminlte":"cpe:2.3:a:pi-hole:web_interface:*:*:*:*:*:*:*:*",
"onlaj=Piano-LED-Visualizer":"cpe:2.3:a:piano_led_visualizer_project:piano_led_visualizer:*:*:*:*:*:*:*:*",
"piccolo-orm=piccolo":"cpe:2.3:a:piccolo-orm:piccolo:*:*:*:*:*:*:*:*",
"unspecified=pig-vector":"cpe:2.3:a:pig-vector_project:pig-vector:*:*:*:*:*:*:*:*",
"CODESYS=CODESYS V3  containing the CmpUserMgr":"cpe:2.3:a:pilz:pmc:*:*:*:*:*:*:*:*",
"Pilz=Pilz PNOZmulti Configurator":"cpe:2.3:a:pilz:pnozmulti_configurator:*:*:*:*:*:*:*:*",
"Pimcore=Pimcore AdminBundle":"cpe:2.3:a:pimcore:adminbundle:*:*:*:*:*:*:*:*",
"pimcore=pimcore/demo":"cpe:2.3:a:pimcore:core:*:*:*:*:*:*:*:*",
"pimcore=pimcore/customer-data-framework":"cpe:2.3:a:pimcore:customer-data-framework:*:*:*:*:*:*:*:*",
"pimcore=pimcore/customer-data-framework":"cpe:2.3:a:pimcore:customer_data_framework:*:*:*:*:*:*:*:*",
"Pimcore=Pimcore Customer Data Framework":"cpe:2.3:a:pimcore:customer_management_framework:*:*:*:*:*:*:*:*",
"pimcore=pimcore/data-hub":"cpe:2.3:a:pimcore:data-hub:*:*:*:*:*:*:*:*",
"pimcore=ecommerce-framework-bundle":"cpe:2.3:a:pimcore:e-commerce_framework:*:*:*:*:*:*:*:*",
"pimcore=perspective-editor":"cpe:2.3:a:pimcore:perspective_editor:*:*:*:*:*:*:*:*",
"pimcore=customer-data-framework":"cpe:2.3:a:pimcore:pimcore:*:*:*:*:*:*:*:*",
"pimcore=pimcore":"cpe:2.3:a:pimcore:pimcore:*:*:*:*:*:*:*:*",
"pimcore=pimcore/pimcore":"cpe:2.3:a:pimcore:pimcore:*:*:*:*:*:*:*:*",
"Cybonet=PineApp - Mail Secure":"cpe:2.3:a:pineapp:mail_secure:*:*:*:*:*:*:*:*",
"pingcap=pingcap/tidb":"cpe:2.3:a:pingcap:tidb:*:*:*:*:*:*:*:*",
"pingcap=tidb":"cpe:2.3:a:pingcap:tidb:*:*:*:*:*:*:*:*",
"Ping Identity=PingAccess":"cpe:2.3:a:pingidentity:pingaccess:*:*:*:*:*:*:*:*",
"Ping Identity=PingCentral":"cpe:2.3:a:pingidentity:pingcentral:*:*:*:*:*:*:*:*",
"Ping Identity=PingDirectory":"cpe:2.3:a:pingidentity:pingdirectory:*:*:*:*:*:*:*:*",
"Ping Identity=PingFederate":"cpe:2.3:a:pingidentity:pingfederate:*:*:*:*:*:*:*:*",
"Ping Identity=PingID Integration for Windows Login":"cpe:2.3:a:pingidentity:pingid_integration_for_windows_login:*:*:*:*:*:*:*:*",
"Ping Identity=PingID Windows Login":"cpe:2.3:a:pingidentity:pingid_integration_for_windows_login:*:*:*:*:*:*:*:*",
"Ping Identity=unspecified":"cpe:2.3:a:pingidentity:pingid_integration_for_windows_login:*:*:*:*:*:*:*:*",
"Ping Identity=PingID Radius PCV":"cpe:2.3:a:pingidentity:pingid_radius_pcv:*:*:*:*:*:*:*:*",
"Ping Identity=PingFederate PingOne MFA Integration Kit":"cpe:2.3:a:pingidentity:pingone_mfa_integration_kit:*:*:*:*:*:*:*:*",
"Ping Identity=PingOne MFA Integration Kit":"cpe:2.3:a:pingidentity:pingone_mfa_integration_kit:*:*:*:*:*:*:*:*",
"Ping Identity=Self-Service Account Manager":"cpe:2.3:a:pingidentity:self-service_account_manager:*:*:*:*:*:*:*:*",
"pinterest=querybook":"cpe:2.3:a:pinterest:querybook:*:*:*:*:*:*:*:*",
"pion=dtls":"cpe:2.3:a:pion:dtls:*:*:*:*:*:*:*:*",
"Cloud Foundry=Container Runtime":"cpe:2.3:a:pivotal:cloud_foundry_container_runtime:*:*:*:*:*:*:*:*",
"Pivotal=Reactor Netty":"cpe:2.3:a:pivotal:reactor_netty:*:*:*:*:*:*:*:*",
"Spring=Reactor Netty":"cpe:2.3:a:pivotal:reactor_netty:*:*:*:*:*:*:*:*",
"Pivotal/Spring=Spring-flex":"cpe:2.3:a:pivotal:spring-flex:*:*:*:*:*:*:*:*",
"Pivotal=Apps Manager":"cpe:2.3:a:pivotal_software:application_service:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Application Service (PAS)":"cpe:2.3:a:pivotal_software:application_service:*:*:*:*:*:*:*:*",
"Cloud Foundry=bits-service-release":"cpe:2.3:a:pivotal_software:bits_service:*:*:*:*:*:*:*:*",
"Cloud Foundry=log-cache-release":"cpe:2.3:a:pivotal_software:cloud_foundry_log_cache:*:*:*:*:*:*:*:*",
"Cloud Foundry=NFS Volume Release":"cpe:2.3:a:pivotal_software:cloud_foundry_nfs_volume:*:*:*:*:*:*:*:*",
"Cloud Foundry=UAA Release":"cpe:2.3:a:pivotal_software:cloud_foundry_uaa-release:*:*:*:*:*:*:*:*",
"Cloud Foundry=UAA Release (OSS)":"cpe:2.3:a:pivotal_software:cloud_foundry_uaa-release:*:*:*:*:*:*:*:*",
"Cloud Foundry =Cloud Foundry UAA":"cpe:2.3:a:pivotal_software:cloud_foundry_uaa:*:*:*:*:*:*:*:*",
"Cloud Foundry=UAA Release (OSS)":"cpe:2.3:a:pivotal_software:cloud_foundry_uaa:*:*:*:*:*:*:*:*",
"Pivotal=Concourse":"cpe:2.3:a:pivotal_software:concourse:*:*:*:*:*:*:*:*",
"VMware Tanzu=Concourse":"cpe:2.3:a:pivotal_software:concourse:*:*:*:*:*:*:*:*",
"Pivotal Cloud Foundry=CredHub Service Broker":"cpe:2.3:a:pivotal_software:credhub_service_broker:*:*:*:*:*:*:*:*",
"Pivotal=Greenplum Command Center":"cpe:2.3:a:pivotal_software:greenplum_command_center:*:*:*:*:*:*:*:*",
"Pivotal Cloud Foundry=Pivotal Operations Manager":"cpe:2.3:a:pivotal_software:operations_manager:*:*:*:*:*:*:*:*",
"Pivotal=Operations Manager":"cpe:2.3:a:pivotal_software:operations_manager:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Operations Manager":"cpe:2.3:a:pivotal_software:operations_manager:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Ops Manager":"cpe:2.3:a:pivotal_software:operations_manager:*:*:*:*:*:*:*:*",
"Pivotal=pivotal-ops-manager":"cpe:2.3:a:pivotal_software:operations_manager:*:*:*:*:*:*:*:*",
"Dell EMC=Apps Manager for PCF":"cpe:2.3:a:pivotal_software:pivotal_application_service:*:*:*:*:*:*:*:*",
"Pivotal =Application Service":"cpe:2.3:a:pivotal_software:pivotal_application_service:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Application Service":"cpe:2.3:a:pivotal_software:pivotal_application_service:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Application Service (PAS)":"cpe:2.3:a:pivotal_software:pivotal_application_service:*:*:*:*:*:*:*:*",
"Pivotal =Cloud Cache":"cpe:2.3:a:pivotal_software:pivotal_cloud_cache:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Container Service":"cpe:2.3:a:pivotal_software:pivotal_container_service:*:*:*:*:*:*:*:*",
"Pivotal=Pivotal Container Service (PKS)":"cpe:2.3:a:pivotal_software:pivotal_container_service:*:*:*:*:*:*:*:*",
"Spring by VMware=Spring Batch":"cpe:2.3:a:pivotal_software:spring_batch:*:*:*:*:*:*:*:*",
"Spring=Spring Batch":"cpe:2.3:a:pivotal_software:spring_batch:*:*:*:*:*:*:*:*",
"Spring by Pivotal=Spring Batch Admin":"cpe:2.3:a:pivotal_software:spring_batch_admin:*:*:*:*:*:*:*:*",
"Spring=Spring Data JPA":"cpe:2.3:a:pivotal_software:spring_data_java_persistance_api:*:*:*:*:*:*:*:*",
"Spring=Spring Boot":"cpe:2.3:a:pivotal_software:spring_data_java_persistence_api:*:*:*:*:*:*:*:*",
"Spring by VMware=Spring Security":"cpe:2.3:a:pivotal_software:spring_security:*:*:*:*:*:*:*:*",
"Pivotal=Spring Security OAuth":"cpe:2.3:a:pivotal_software:spring_security_oauth:*:*:*:*:*:*:*:*",
"Cloud Foundry=Windows2012R2 stemcell":"cpe:2.3:a:pivotal_software:windows_stemcells:*:*:*:*:*:*:*:*",
"Dell EMC=Windows Stemcells":"cpe:2.3:a:pivotal_software:windows_stemcells:*:*:*:*:*:*:*:*",
"jfm-so=piWallet":"cpe:2.3:a:piwallet_project:piwallet:*:*:*:*:*:*:*:*",
"Unknown=Export customers list csv for WooCommerce, WordPress users csv, export Guest customer list":"cpe:2.3:a:piwebsolution:export_customers_list_csv_for_woocommerce:*:*:*:*:*:*:*:*",
"Piwigo=Piwigo":"cpe:2.3:a:piwigo:piwigo:*:*:*:*:*:*:*:*",
"Talos=Pixar Renderman":"cpe:2.3:a:pixar:renderman:*:*:*:*:*:*:*:*",
"Pixar=Tractor":"cpe:2.3:a:pixar:tractor:*:*:*:*:*:*:*:*",
"pixee=java-security-toolkit":"cpe:2.3:a:pixee:java_code_security_toolkit:*:*:*:*:*:*:*:*",
"pixelfed=pixelfed/pixelfed":"cpe:2.3:a:pixelfed:pixelfed:*:*:*:*:*:*:*:*",
"Pixelpost.org=Pixelpost":"cpe:2.3:a:pixelpost:pixelpost:*:*:*:*:*:*:*:*",
"pjsip=pjproject":"cpe:2.3:a:pjsip:pjsip:*:*:*:*:*:*:*:*",
"pkp=pkp/pkp-lib":"cpe:2.3:a:pkp:pkb-lib:*:*:*:*:*:*:*:*",
"pkp=pkp/pkp-lib":"cpe:2.3:a:pkp:pkp_web_application_library:*:*:*:*:*:*:*:*",
"Computerinsel=Computerinsel Photoline":"cpe:2.3:a:pl32:photoline:*:*:*:*:*:*:*:*",
"Computerinsel=Photoline":"cpe:2.3:a:pl32:photoline:*:*:*:*:*:*:*:*",
"Talos=Computerinsel Photoline":"cpe:2.3:a:pl32:photoline:*:*:*:*:*:*:*:*",
"plaidweb=plaidweb/webmention.js":"cpe:2.3:a:plaidweb:webmention.js:*:*:*:*:*:*:*:*",
"Plane=Plane":"cpe:2.3:a:plane:plane:*:*:*:*:*:*:*:*",
"planetlabs=planet-client-python":"cpe:2.3:a:planet:planet:*:*:*:*:*:*:*:*",
"ohmyzsh=ohmyzsh/ohmyzsh":"cpe:2.3:a:planetargon:oh_my_zsh:*:*:*:*:*:*:*:*",
"plankanban=plankanban/planka":"cpe:2.3:a:planka:planka:*:*:*:*:*:*:*:*",
"Plataformatec=Devise ruby gem":"cpe:2.3:a:plataformatec:devise:*:*:*:*:*:*:*:*",
"unspecified=Platinum Mobile":"cpe:2.3:a:platinumchina:platinum_mobile:*:*:*:*:*:*:*:*",
"Implem Inc.=Pleasanter":"cpe:2.3:a:pleasanter:pleasanter:*:*:*:*:*:*:*:*",
"Implem Inc.=Pleasanter (Community Edition and Enterprise Edition)":"cpe:2.3:a:pleasanter:pleasanter:*:*:*:*:*:*:*:*",
"Plesk=Plesk":"cpe:2.3:a:plesk:plesk:*:*:*:*:*:*:*:*",
"Plesk=Plesk Installer":"cpe:2.3:a:plesk:plesk:*:*:*:*:*:*:*:*",
"Plone CMS=Plone CMS":"cpe:2.3:a:plone:plone:*:*:*:*:*:*:*:*",
"plone=plone.rest":"cpe:2.3:a:plone:rest:*:*:*:*:*:*:*:*",
"pluck-cms=PluckCMS":"cpe:2.3:a:pluck-cms:pluckcms:*:*:*:*:*:*:*:*",
"PluXml=PluXml":"cpe:2.3:a:pluxml:pluxml:*:*:*:*:*:*:*:*",
"PoDoFo=PoDoFo PoDoFo":"cpe:2.3:a:podofo_project:podofo:*:*:*:*:*:*:*:*",
"Poikosoft=EZ CD Audio Converter":"cpe:2.3:a:poikosoft:ez_cd_audio_converter:*:*:*:*:*:*:*:*",
"Pointware=EasyInventory":"cpe:2.3:a:pointware:easyinventory:*:*:*:*:*:*:*:*",
"Infraware=ML Report":"cpe:2.3:a:polarisoffice:polaris_ml_report:*:*:*:*:*:*:*:*",
"SourceCodester=Police Crime Record Management System":"cpe:2.3:a:police_crime_record_management_system_project:police_crime_record_management_system:*:*:*:*:*:*:*:*",
"cydrobolt=polr":"cpe:2.3:a:polrproject:polr:*:*:*:*:*:*:*:*",
"JmPotato=Pomash":"cpe:2.3:a:pomash_project:pomash:*:*:*:*:*:*:*:*",
"pomerium=pomerium":"cpe:2.3:a:pomerium:pomerium:*:*:*:*:*:*:*:*",
"pon software=Explzh":"cpe:2.3:a:ponsoftware:explzh:*:*:*:*:*:*:*:*",
"Port  bilis=i-Educar":"cpe:2.3:a:portabilis:i-educar:*:*:*:*:*:*:*:*",
"PORTY Smart Tech Technology Joint Stock Company=PowerBank Application":"cpe:2.3:a:porty:powerbank:*:*:*:*:*:*:*:*",
"Snawoot=postfix-mta-sts-resolver":"cpe:2.3:a:postfix-mta-sts-resolver_project:postfix-mta-sts-resolver:*:*:*:*:*:*:*:*",
"PostgreSQL Project=postgresql":"cpe:2.3:a:postgresql:postgresql:*:*:*:*:*:*:*:*",
"PostgreSQL=postgresql":"cpe:2.3:a:postgresql:postgresql:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=postgresql":"cpe:2.3:a:postgresql:postgresql:*:*:*:*:*:*:*:*",
"The PostgreSQL Global Development Group=PostgreSQL":"cpe:2.3:a:postgresql:postgresql:*:*:*:*:*:*:*:*",
"The PostgreSQL Global Development Group=postgresql":"cpe:2.3:a:postgresql:postgresql:*:*:*:*:*:*:*:*",
"PostHog=posthog-js":"cpe:2.3:a:posthog:posthog-js:*:*:*:*:*:*:*:*",
"PostHog=posthog":"cpe:2.3:a:posthog:posthog:*:*:*:*:*:*:*:*",
"posthog=posthog/posthog":"cpe:2.3:a:posthog:posthog:*:*:*:*:*:*:*:*",
"pouetnet=pouet":"cpe:2.3:a:pouet:pouet2.0:*:*:*:*:*:*:*:*",
"danschultzer=Pow":"cpe:2.3:a:powauth:pow:*:*:*:*:*:*:*:*",
"pow-auth=pow":"cpe:2.3:a:powauth:pow:*:*:*:*:*:*:*:*",
"pow-auth=pow_assent":"cpe:2.3:a:powauth:powassent:*:*:*:*:*:*:*:*",
"PowerDNS=PowerDNS Authoritative":"cpe:2.3:a:powerdns:authoritative:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=dnsdist":"cpe:2.3:a:powerdns:dnsdist:*:*:*:*:*:*:*:*",
"[UNKNOWN]=dnsdist":"cpe:2.3:a:powerdns:dnsdist:*:*:*:*:*:*:*:*",
"[UNKNOWN]=pdns":"cpe:2.3:a:powerdns:pdns:*:*:*:*:*:*:*:*",
"Power DNS=pdns-recursor":"cpe:2.3:a:powerdns:recursor:*:*:*:*:*:*:*:*",
"PowerDNS=PowerDNS":"cpe:2.3:a:powerdns:recursor:*:*:*:*:*:*:*:*",
"PowerDNS=PowerDNS Recursor":"cpe:2.3:a:powerdns:recursor:*:*:*:*:*:*:*:*",
"PowerDNS=Recursor":"cpe:2.3:a:powerdns:recursor:*:*:*:*:*:*:*:*",
"[UNKNOWN]=pdns":"cpe:2.3:a:powerdns:recursor:*:*:*:*:*:*:*:*",
"[UNKNOWN]=pdns-recursor":"cpe:2.3:a:powerdns:recursor:*:*:*:*:*:*:*:*",
"Power Software=PowerISO":"cpe:2.3:a:poweriso:poweriso:*:*:*:*:*:*:*:*",
"Power Software=PowerIso":"cpe:2.3:a:poweriso:poweriso:*:*:*:*:*:*:*:*",
"PowerISO=PowerISO":"cpe:2.3:a:poweriso:poweriso:*:*:*:*:*:*:*:*",
"AndrewBurian=powermux":"cpe:2.3:a:powermux_project:powermux:*:*:*:*:*:*:*:*",
"abhilash1985=PredictApp":"cpe:2.3:a:predictapp_project:predictapp:*:*:*:*:*:*:*:*",
"prefecthq=prefecthq/prefect":"cpe:2.3:a:prefect:prefect:*:*:*:*:*:*:*:*",
"Premium Software=CLEditor":"cpe:2.3:a:premiumsoftware:cleditor:*:*:*:*:*:*:*:*",
"PRESENTCAST INC.=TVer App for Android":"cpe:2.3:a:presentcast_inc:tver:*:*:*:*:*:*:*:*",
"PrestaShop=blockwishlist":"cpe:2.3:a:prestashop:blockwishlist:*:*:*:*:*:*:*:*",
"PrestaShop=dashproducts":"cpe:2.3:a:prestashop:dashboard_products:*:*:*:*:*:*:*:*",
"PrestaShop=ps_facetedsearch":"cpe:2.3:a:prestashop:faceted_search_module:*:*:*:*:*:*:*:*",
"PrestaShop=PrestaShop":"cpe:2.3:a:prestashop:prestashop:*:*:*:*:*:*:*:*",
"PrestaShop=productcomments":"cpe:2.3:a:prestashop:product_comments:*:*:*:*:*:*:*:*",
"prestosql=Presto":"cpe:2.3:a:prestosql:presto:*:*:*:*:*:*:*:*",
"Primion Digitek=Secure 8 (Evalos)":"cpe:2.3:a:primion-digitek:secure_8:*:*:*:*:*:*:*:*",
"Exciting=Printer":"cpe:2.3:a:printer_project:printer:*:*:*:*:*:*:*:*",
"PrinterLogic=Management Software":"cpe:2.3:a:printerlogic:print_management:*:*:*:*:*:*:*:*",
"Priority=Priority":"cpe:2.3:a:priority-software:priority:*:*:*:*:*:*:*:*",
"Priority=Priority Web":"cpe:2.3:a:priority-software:priority:*:*:*:*:*:*:*:*",
"Priority=Priority web":"cpe:2.3:a:priority-software:priority:*:*:*:*:*:*:*:*",
"SourceCodester=Prison Management System":"cpe:2.3:a:prison_management_system_project:prison_management_system:*:*:*:*:*:*:*:*",
"unspecified=Private Cloud Management Platform":"cpe:2.3:a:private_cloud_management_platform_project:private_cloud_management_platform:*:*:*:*:*:*:*:*",
"PrivateBin=PrivateBin":"cpe:2.3:a:privatebin:privatebin:*:*:*:*:*:*:*:*",
"[UNKNOWN]=procps-ng, procps":"cpe:2.3:a:procps_project:procps:*:*:*:*:*:*:*:*",
"SourceCodester=Product Show Room Site":"cpe:2.3:a:product_show_room_site_project:product_show_room_site:*:*:*:*:*:*:*:*",
"Microprogram=parking lot management system":"cpe:2.3:a:program:parking_lot_management_system:*:*:*:*:*:*:*:*",
"Progress Software Corporation=MOVEit Transfer":"cpe:2.3:a:progress:moveit_transfer:*:*:*:*:*:*:*:*",
"Progress Software Corporation=Sitefinity":"cpe:2.3:a:progress:sitefinity:*:*:*:*:*:*:*:*",
"Progress Software=Telerik JustDecompile":"cpe:2.3:a:progress:telerik_justdecompile:*:*:*:*:*:*:*:*",
"Progress Software=Telerik Reporting":"cpe:2.3:a:progress:telerik_reporting:*:*:*:*:*:*:*:*",
"Progress Software=Telerik Test Studio":"cpe:2.3:a:progress:telerik_test_studio:*:*:*:*:*:*:*:*",
"Progress Software Corporation=WhatsUp Gold":"cpe:2.3:a:progress:whatsup_gold:*:*:*:*:*:*:*:*",
"Progress Software Corporation=WS_FTP Server":"cpe:2.3:a:progress:ws_ftp_server:*:*:*:*:*:*:*:*",
"ProIntegra S.A.=UptimeDC":"cpe:2.3:a:prointegra:uptimedc:*:*:*:*:*:*:*:*",
"vinsdragonis=Project-Nexus":"cpe:2.3:a:project-nexus_project:project-nexus:*:*:*:*:*:*:*:*",
"ProjectDiscovery=Interactsh":"cpe:2.3:a:projectdiscovery:interactsh:*:*:*:*:*:*:*:*",
"projectdiscovery=interactsh":"cpe:2.3:a:projectdiscovery:interactsh:*:*:*:*:*:*:*:*",
"projectsend=projectsend/projectsend":"cpe:2.3:a:projectsend:projectsend:*:*:*:*:*:*:*:*",
"unspecified=ProjectSend":"cpe:2.3:a:projectsend:projectsend:*:*:*:*:*:*:*:*",
"Asset Management System=Asset Management System":"cpe:2.3:a:projectworlds:asset_management_system:*:*:*:*:*:*:*:*",
"Gym Management System Project=Gym Management System Project":"cpe:2.3:a:projectworlds:gym_management_system_project:*:*:*:*:*:*:*:*",
"Hospital-management-system=Hospital-management-system":"cpe:2.3:a:projectworlds:hospital_management_system_in_php:*:*:*:*:*:*:*:*",
"hospital-management-system=hospital-management-system":"cpe:2.3:a:projectworlds:hospital_management_system_in_php:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Leave Management System Project":"cpe:2.3:a:projectworlds:leave_management_system:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Art Gallery":"cpe:2.3:a:projectworlds:online_art_gallery:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Blood Donation Management System":"cpe:2.3:a:projectworlds:online_blood_donation_management_system:*:*:*:*:*:*:*:*",
"Online Book Store Project=Online Book Store Project":"cpe:2.3:a:projectworlds:online_book_store_project:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Examination System":"cpe:2.3:a:projectworlds:online_examination_system:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Food Ordering System":"cpe:2.3:a:projectworlds:online_food_ordering_script:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Food Ordering System":"cpe:2.3:a:projectworlds:online_food_ordering_system:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Job Portal":"cpe:2.3:a:projectworlds:online_job_portal:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Matrimonial Project":"cpe:2.3:a:projectworlds:online_matrimonial_project:*:*:*:*:*:*:*:*",
"Online Movie Ticket Booking System=Online Movie Ticket Booking System":"cpe:2.3:a:projectworlds:online_movie_ticket_booking_system:*:*:*:*:*:*:*:*",
"Project Worlds=Online Time Table Generator":"cpe:2.3:a:projectworlds:online_time_table_generator:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Online Voting System Project":"cpe:2.3:a:projectworlds:online_voting_system_project:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Railway Reservation System":"cpe:2.3:a:projectworlds:railway_reservation_system:*:*:*:*:*:*:*:*",
"Projectworlds Pvt. Limited=Student Result Management System":"cpe:2.3:a:projectworlds:student_result_management_system:*:*:*:*:*:*:*:*",
"Proliz=OBS":"cpe:2.3:a:prolizyazilim:student_affairs_information_system:*:*:*:*:*:*:*:*",
"prometheus=exporter-toolkit":"cpe:2.3:a:prometheus:exporter_toolkit:*:*:*:*:*:*:*:*",
"Proofpoint=Proofpoint Enterprise Protection":"cpe:2.3:a:proofpoint:enterprise_protection:*:*:*:*:*:*:*:*",
"Proofpoint=enterprise_protection":"cpe:2.3:a:proofpoint:enterprise_protection:*:*:*:*:*:*:*:*",
"proofpoint=enterprise_protection":"cpe:2.3:a:proofpoint:enterprise_protection:*:*:*:*:*:*:*:*",
"Proofpoint=ITM Server":"cpe:2.3:a:proofpoint:insider_threat_management:*:*:*:*:*:*:*:*",
"Proofpoint=Insider Threat Management":"cpe:2.3:a:proofpoint:insider_threat_management_server:*:*:*:*:*:*:*:*",
"Proofpoint=Threat Response Auto Pull":"cpe:2.3:a:proofpoint:threat_response_auto_pull:*:*:*:*:*:*:*:*",
"[UNKNOWN]=prosody":"cpe:2.3:a:prosody:prosody:*:*:*:*:*:*:*:*",
"ipfs=go-ipfs":"cpe:2.3:a:protocol:go-ipfs:*:*:*:*:*:*:*:*",
"ipfs=go-merkledag":"cpe:2.3:a:protocol:go-merkledag:*:*:*:*:*:*:*:*",
"ProtonMail=WebClient":"cpe:2.3:a:protonmail:protonmail:*:*:*:*:*:*:*:*",
"Talos=ProtonVPN":"cpe:2.3:a:protonvpn:protonvpn:*:*:*:*:*:*:*:*",
"proxyee-down-org=proxyee-down":"cpe:2.3:a:proxyee-down_project:proxyee-down:*:*:*:*:*:*:*:*",
"Facebook=Proxygen":"cpe:2.3:a:proxygen_project:proxygen:*:*:*:*:*:*:*:*",
"ELOG=Electronic Logbook":"cpe:2.3:a:psi:electronic_logbook:*:*:*:*:*:*:*:*",
"NYUCCL=psiTurk":"cpe:2.3:a:psiturk:psiturk:*:*:*:*:*:*:*:*",
"PTC =Vuforia Studio":"cpe:2.3:a:ptc:vuforia_studio:*:*:*:*:*:*:*:*",
"Pterodactyl=Pterodactyl Panel":"cpe:2.3:a:pterodactyl:panel:*:*:*:*:*:*:*:*",
"pterodactyl=panel":"cpe:2.3:a:pterodactyl:panel:*:*:*:*:*:*:*:*",
"pterodactyl=wings":"cpe:2.3:a:pterodactyl:wings:*:*:*:*:*:*:*:*",
"Ulkem Company=PtteM Kart":"cpe:2.3:a:pttemkart:pttem_kart:*:*:*:*:*:*:*:*",
"sanluan=PublicCMS":"cpe:2.3:a:publiccms:publiccms:*:*:*:*:*:*:*:*",
"publify=publify/publify":"cpe:2.3:a:publify_project:publify:*:*:*:*:*:*:*:*",
"publify_core=publify_core":"cpe:2.3:a:publify_project:publify:*:*:*:*:*:*:*:*",
"PuddingBot=pudding-bot":"cpe:2.3:a:puddingbot_project:puddingbot:*:*:*:*:*:*:*:*",
"PukiWiki Development Team=PukiWiki":"cpe:2.3:a:pukiwiki:pukiwiki:*:*:*:*:*:*:*:*",
"[UNKNOWN]=pulp":"cpe:2.3:a:pulpproject:pulp:*:*:*:*:*:*:*:*",
"pupnp=pupnp":"cpe:2.3:a:pupnp_project:pupnp:*:*:*:*:*:*:*:*",
"Puppet=Bolt":"cpe:2.3:a:puppet:bolt:*:*:*:*:*:*:*:*",
"Puppet=Chloride":"cpe:2.3:a:puppet:chloride:*:*:*:*:*:*:*:*",
"Puppet=Puppet Discovery":"cpe:2.3:a:puppet:discovery:*:*:*:*:*:*:*:*",
"Puppet=Firewall Module":"cpe:2.3:a:puppet:firewall:*:*:*:*:*:*:*:*",
"Puppet=Puppet Enterprise":"cpe:2.3:a:puppet:puppet_enterprise:*:*:*:*:*:*:*:*",
"Puppet=puppetlabs-mysql":"cpe:2.3:a:puppet:puppetlabs-mysql:*:*:*:*:*:*:*:*",
"unspecified=puppyCMS":"cpe:2.3:a:puppycms:puppycms:*:*:*:*:*:*:*:*",
"SourceCodester=Purchase Order Management System":"cpe:2.3:a:purchase_order_management_system_project:purchase_order_management_system:*:*:*:*:*:*:*:*",
"Pure Chat by Ruby=Pure Chat":"cpe:2.3:a:purechat:pure_chat:*:*:*:*:*:*:*:*",
"Pure Storage=FlashBlade":"cpe:2.3:a:purestorage:purity:*:*:*:*:*:*:*:*",
"Pure Storage=FlashBlade Purity":"cpe:2.3:a:purestorage:purity:*:*:*:*:*:*:*:*",
"Pure Storage=FlashArray Purity":"cpe:2.3:a:purestorage:purity\/\/fa:*:*:*:*:*:*:*:*",
"CTF-hacker=pwn":"cpe:2.3:a:pwn_project:pwn:*:*:*:*:*:*:*:*",
"katlings=pyambic-pentameter":"cpe:2.3:a:pyambic-pentameter_project:pyambic-pentameter:*:*:*:*:*:*:*:*",
"benjjvi=PyBB":"cpe:2.3:a:pybb_project:pybb:*:*:*:*:*:*:*:*",
"pyinstaller=pyinstaller":"cpe:2.3:a:pyinstaller:pyinstaller:*:*:*:*:*:*:*:*",
"pyload=pyload":"cpe:2.3:a:pyload:pyload:*:*:*:*:*:*:*:*",
"pyload=pyload/pyload":"cpe:2.3:a:pyload:pyload:*:*:*:*:*:*:*:*",
"pymatgen=pymatgen":"cpe:2.3:a:pymatgen:pymatgen:*:*:*:*:*:*:*:*",
"facelessuser=pymdown-extensions":"cpe:2.3:a:pymdown_extensions_project:pymdown_extensions:*:*:*:*:*:*:*:*",
"pymedusa=Medusa":"cpe:2.3:a:pymedusa:medusa:*:*:*:*:*:*:*:*",
"Pip maintainers=pip":"cpe:2.3:a:pypa:pip:*:*:*:*:*:*:*:*",
"py-pdf=pypdf":"cpe:2.3:a:pypdf_project:pypdf:*:*:*:*:*:*:*:*",
"IdentityPython=pysaml2":"cpe:2.3:a:pysaml2_project:pysaml2:*:*:*:*:*:*:*:*",
"cocagne=pysrp":"cpe:2.3:a:pysrp_project:pysrp:*:*:*:*:*:*:*:*",
"Guangdong=Pythagorean OA Office System":"cpe:2.3:a:pythagorean_oa_office_system_project:pythagorean_oa_office_system:*:*:*:*:*:*:*:*",
"[UNKNOWN]=python-ecdsa":"cpe:2.3:a:python-ecdsa_project:python-ecdsa:*:*:*:*:*:*:*:*",
"davedoesdev=python-jwt":"cpe:2.3:a:python-jwt_project:python-jwt:*:*:*:*:*:*:*:*",
"cleo=cleo":"cpe:2.3:a:python-poetry:cleo:*:*:*:*:*:*:*:*",
"Red Hat=python-novajoin":"cpe:2.3:a:python:novajoin:*:*:*:*:*:*:*:*",
"unspecified=Python":"cpe:2.3:a:python:python:*:*:*:*:*:*:*:*",
"Kenneth Reitz=Tablib":"cpe:2.3:a:python:tablib:*:*:*:*:*:*:*:*",
"PaulleDemon=tkVideoPlayer":"cpe:2.3:a:python:tkvideoplayer:*:*:*:*:*:*:*:*",
"OpenIDC=pyoidc":"cpe:2.3:a:python_openid_connect_project:python_openid_connect:*:*:*:*:*:*:*:*",
"python-discord=bot":"cpe:2.3:a:pythondiscord:bot:*:*:*:*:*:*:*:*",
"pytorch=serve":"cpe:2.3:a:pytorch:torchserve:*:*:*:*:*:*:*:*",
"pyupio=dparse":"cpe:2.3:a:pyup:dependency_parser:*:*:*:*:*:*:*:*",
"pyupio=safety":"cpe:2.3:a:pyup:safety:*:*:*:*:*:*:*:*",
"pyxtrlock=pyxtrlock":"cpe:2.3:a:pyxtrlock_project:pyxtrlock:*:*:*:*:*:*:*:*",
"qBittorrent=qBittorrent client":"cpe:2.3:a:qbittorrent:qbittorrent:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=Android for MSM, Firefox OS for MSM, QRD Android":"cpe:2.3:a:qcacld_2.0_project:qcacld_2.0:*:*:*:*:*:*:*:*",
"QDocs=Smart School":"cpe:2.3:a:qdocs:smart_school:*:*:*:*:*:*:*:*",
"QEMU=Qemu":"cpe:2.3:a:qemu:qemu:*:*:*:*:*:*:*:*",
"QEMU=Qemu:":"cpe:2.3:a:qemu:qemu:*:*:*:*:*:*:*:*",
"The QEMU Project=QEMU":"cpe:2.3:a:qemu:qemu:*:*:*:*:*:*:*:*",
"The QEMU Project=qemu":"cpe:2.3:a:qemu:qemu:*:*:*:*:*:*:*:*",
"QiboSoft=QiboCMS X1":"cpe:2.3:a:qibosoft:qibocms_x1:*:*:*:*:*:*:*:*",
"qkmc-rk=redbbs":"cpe:2.3:a:qkmc-rk:redbbs:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Helpdesk":"cpe:2.3:a:qnap:helpdesk:*:*:*:*:*:*:*:*",
"Linn Products Limited=Kazoo Server":"cpe:2.3:a:qnap:kazoo_server:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Kazoo Server":"cpe:2.3:a:qnap:kazoo_server:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Malware Remover":"cpe:2.3:a:qnap:malware_remover:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Multimedia Console":"cpe:2.3:a:qnap:multimedia_console:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Music Station":"cpe:2.3:a:qnap:music_station:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Photo Station":"cpe:2.3:a:qnap:photo_station:*:*:*:*:*:*:*:*",
"QNAP=Photo Station":"cpe:2.3:a:qnap:photo_station:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Q'center":"cpe:2.3:a:qnap:q\'center:*:*:*:*:*:*:*:*",
"QNAP=Q'center Virtual Appliance":"cpe:2.3:a:qnap:q\'center:*:*:*:*:*:*:*:*",
"QNAP=Q'center Virtual Appliance":"cpe:2.3:a:qnap:q\'center_virtual_appliance:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QcalAgent":"cpe:2.3:a:qnap:qcalagent:*:*:*:*:*:*:*:*",
"QNAP=Qfinder Pro":"cpe:2.3:a:qnap:qfinder_pro:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Qsync Central":"cpe:2.3:a:qnap:qsync_central:*:*:*:*:*:*:*:*",
"QNAP=QNAP Helpdesk APP":"cpe:2.3:a:qnap:qts_helpdesk:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QuLog Center":"cpe:2.3:a:qnap:qulog_center:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QuMagie":"cpe:2.3:a:qnap:qumagie:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QUSBCam2":"cpe:2.3:a:qnap:qusbcam2:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QVR":"cpe:2.3:a:qnap:qvr:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QVR Pro Client":"cpe:2.3:a:qnap:qvr_pro_client:*:*:*:*:*:*:*:*",
"Roon Labs=Roon Server":"cpe:2.3:a:qnap:roon_server:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Video Station":"cpe:2.3:a:qnap:video_station:*:*:*:*:*:*:*:*",
"Qognify=NiceVision":"cpe:2.3:a:qognify:nicevision:*:*:*:*:*:*:*:*",
"Qognify=Ocularis":"cpe:2.3:a:qognify:ocularis:*:*:*:*:*:*:*:*",
"QOS.CH Sarl=logback":"cpe:2.3:a:qos:logback:*:*:*:*:*:*:*:*",
"Gundam Cult QQQ=QQQ SYSTEMS":"cpe:2.3:a:qqq_systems_project:qqq_systems:*:*:*:*:*:*:*:*",
"QSAN=SANOS":"cpe:2.3:a:qsan:sanos:*:*:*:*:*:*:*:*",
"QSAN=Storage Manager":"cpe:2.3:a:qsan:storage_manager:*:*:*:*:*:*:*:*",
"QSAN=XEVO":"cpe:2.3:a:qsan:xevo:*:*:*:*:*:*:*:*",
"IDM Sistemas QSige=QSige":"cpe:2.3:a:qsige:qsige:*:*:*:*:*:*:*:*",
"QSige de IDM Sistemas=QSige":"cpe:2.3:a:qsige:qsige:*:*:*:*:*:*:*:*",
"Qt Project=Qt":"cpe:2.3:a:qt:qt:*:*:*:*:*:*:*:*",
"Quake3e=Quake3e":"cpe:2.3:a:quake3e_project:quake3e:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=IPS PDF releases prior to IPS System 2020.2":"cpe:2.3:a:qualcomm:ips_pdf:*:*:*:*:*:*:*:*",
"Qualys,Inc. =Container Scanning Connector Jenkins Plugin":"cpe:2.3:a:qualys:container_scanning_connector:*:*:*:*:*:*:*:*",
"Qualys=Qualysguard":"cpe:2.3:a:qualys:private_cloud_platform:*:*:*:*:*:*:*:*",
"quazip=quazip":"cpe:2.3:a:quazip_project:quazip:*:*:*:*:*:*:*:*",
"NextBX=QWAlerter":"cpe:2.3:a:quescom:nextbx_qwalerter:*:*:*:*:*:*:*:*",
"Quest=Foglight Evolve":"cpe:2.3:a:quest:foglight_evolve:*:*:*:*:*:*:*:*",
"Quest=Quest NetVault Backup":"cpe:2.3:a:quest:netvault_backup:*:*:*:*:*:*:*:*",
"quic-go=quic-go":"cpe:2.3:a:quic-go_project:quic-go:*:*:*:*:*:*:*:*",
"atampy25=quickentity-editor-next":"cpe:2.3:a:quickentity_editor_project:quickentity_editor:*:*:*:*:*:*:*:*",
"qutebrowser=cross-site request forgery flaw allows sites to access 'qute":"cpe:2.3:a:qutebrowser:qutebrowser:*:*:*:*:*:*:*:*",
"qutebrowser=qutebrowser":"cpe:2.3:a:qutebrowser:qutebrowser:*:*:*:*:*:*:*:*",
"Meizhou Qingyunke=QYKCMS":"cpe:2.3:a:qykcms:qykcms:*:*:*:*:*:*:*:*",
"Rack=Rack":"cpe:2.3:a:rack_project:rack:*:*:*:*:*:*:*:*",
"racket=racket":"cpe:2.3:a:racket-lang:racket:*:*:*:*:*:*:*:*",
"ractf=core":"cpe:2.3:a:ractf:core:*:*:*:*:*:*:*:*",
"radare2=radare2":"cpe:2.3:a:radare:radare2:*:*:*:*:*:*:*:*",
"radareorg=radareorg/radare2":"cpe:2.3:a:radare:radare2:*:*:*:*:*:*:*:*",
"Ragic=No-Code Database Builder ":"cpe:2.3:a:ragic:enterprise_cloud_database:*:*:*:*:*:*:*:*",
"Ragic, Inc.=Ragic":"cpe:2.3:a:ragic:ragic:*:*:*:*:*:*:*:*",
"TEAM JOHNLONG SOFTWARE CO., LTD.=MAILD Mail Server":"cpe:2.3:a:raidenmaild:raidenmaild:*:*:*:*:*:*:*:*",
"Rakuten Securities, Inc.=The installer of MARKET SPEED":"cpe:2.3:a:rakuten-sec:market_speed:*:*:*:*:*:*:*:*",
"Rakuten Mobile, Inc.=Rakuten Casa":"cpe:2.3:a:rakuten:casa:*:*:*:*:*:*:*:*",
"SUSE=Rancher":"cpe:2.3:a:rancher:rancher:*:*:*:*:*:*:*:*",
"directus=directus":"cpe:2.3:a:rangerstudio:directus:*:*:*:*:*:*:*:*",
"Raonwiz,inc=RAON K Upload":"cpe:2.3:a:raonwiz:raon_k_upload:*:*:*:*:*:*:*:*",
"RAONWIZ=RAON KUpload":"cpe:2.3:a:raonwiz:raon_kupload:*:*:*:*:*:*:*:*",
"Rapid7=AppSpider Pro":"cpe:2.3:a:rapid7:appspider_pro:*:*:*:*:*:*:*:*",
"Rapid7=Insight Agent":"cpe:2.3:a:rapid7:insight_agent:*:*:*:*:*:*:*:*",
"Rapid7=Insight Collector":"cpe:2.3:a:rapid7:insight_collector:*:*:*:*:*:*:*:*",
"Rapid7=InsightAppSec":"cpe:2.3:a:rapid7:insightappsec:*:*:*:*:*:*:*:*",
"Rapid7=InsightVM":"cpe:2.3:a:rapid7:insightvm:*:*:*:*:*:*:*:*",
"Rapid7=Nexpose":"cpe:2.3:a:rapid7:insightvm:*:*:*:*:*:*:*:*",
"Rapid7=Komand":"cpe:2.3:a:rapid7:komand:*:*:*:*:*:*:*:*",
"Rapid7=Metasploit":"cpe:2.3:a:rapid7:metasploit:*:*:*:*:*:*:*:*",
"Rapid7=Metasploit (Pro, Express, and Community editions)":"cpe:2.3:a:rapid7:metasploit:*:*:*:*:*:*:*:*",
"Rapid7=Metasploit Framework":"cpe:2.3:a:rapid7:metasploit:*:*:*:*:*:*:*:*",
"Rapid7=Metasploit Pro":"cpe:2.3:a:rapid7:metasploit:*:*:*:*:*:*:*:*",
"Rapid7=Nexpose":"cpe:2.3:a:rapid7:nexpose:*:*:*:*:*:*:*:*",
"Rapid7=Nexpose hardware appliance":"cpe:2.3:a:rapid7:nexpose:*:*:*:*:*:*:*:*",
"Rapid7=Nexpose/InsightVM Security Console":"cpe:2.3:a:rapid7:nexpose:*:*:*:*:*:*:*:*",
"Rapid7=Rapid7 Nexpose":"cpe:2.3:a:rapid7:nexpose:*:*:*:*:*:*:*:*",
"Rapid7=Velociraptor":"cpe:2.3:a:rapid7:velociraptor:*:*:*:*:*:*:*:*",
"Th3-822=Rapidleech":"cpe:2.3:a:rapidleech:rapidleech:*:*:*:*:*:*:*:*",
"Rapid Software LLC=Rapid SCADA":"cpe:2.3:a:rapidscada:rapid_scada:*:*:*:*:*:*:*:*",
"Check Point Software Technologies Ltd.=WinRAR":"cpe:2.3:a:rarlab:winrar:*:*:*:*:*:*:*:*",
"RARLAB=WinRAR":"cpe:2.3:a:rarlab:winrar:*:*:*:*:*:*:*:*",
"RasaHQ=rasa":"cpe:2.3:a:rasa:rasa:*:*:*:*:*:*:*:*",
"Michlol =Michlol - rashim web ":"cpe:2.3:a:rashim:michlol:*:*:*:*:*:*:*:*",
"ractf=core":"cpe:2.3:a:ratcf:ratcf:*:*:*:*:*:*:*:*",
"rAthena=FluxCP":"cpe:2.3:a:rathena:fluxcp:*:*:*:*:*:*:*:*",
"ratpack=ratpack":"cpe:2.3:a:ratpack_project:ratpack:*:*:*:*:*:*:*:*",
"ray-project=ray-project/ray":"cpe:2.3:a:ray_project:ray:*:*:*:*:*:*:*:*",
"SourceCodester=Employee Management System":"cpe:2.3:a:razormist:employee_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Loan Management System":"cpe:2.3:a:razormist:loan_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Membership System":"cpe:2.3:a:razormist:simple_membership_system:*:*:*:*:*:*:*:*",
"RDesktop=RDesktop":"cpe:2.3:a:rdesktop:rdesktop:*:*:*:*:*:*:*:*",
"reactphp=http":"cpe:2.3:a:reactphp:http:*:*:*:*:*:*:*:*",
"rozbb=readtomyshoe":"cpe:2.3:a:readtomyshoe_project:readtomyshoe:*:*:*:*:*:*:*:*",
"reallyl IO=jwt-scala":"cpe:2.3:a:really:jwt-scala:*:*:*:*:*:*:*:*",
"Realtek=HDA driver":"cpe:2.3:a:realtek:hda_driver:*:*:*:*:*:*:*:*",
"ulrikstrid=reason-jose":"cpe:2.3:a:reason-jose_project:reason-jose:*:*:*:*:*:*:*:*",
"dragonexpert=Recent Threads on Index":"cpe:2.3:a:recent_threads_on_index_project:recent_threads_on_index:*:*:*:*:*:*:*:*",
"Recurly=recurly python module":"cpe:2.3:a:recurly:recurly_client_python:*:*:*:*:*:*:*:*",
"getredash=redash":"cpe:2.3:a:redash:redash:*:*:*:*:*:*:*:*",
"reddit=snudown":"cpe:2.3:a:reddit:snudown:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat 3scale API Management Platform 2":"cpe:2.3:a:redhat:3scale_api_management:*:*:*:*:*:*:*:*",
"Red Hat=open-cluster-management":"cpe:2.3:a:redhat:advanced_cluster_management_for_kubernetes:*:*:*:*:*:*:*:*",
"redhat.com=RHACM":"cpe:2.3:a:redhat:advanced_cluster_management_for_kubernetes:*:*:*:*:*:*:*:*",
"Red Hat=Ansible":"cpe:2.3:a:redhat:ansible:*:*:*:*:*:*:*:*",
"Red Hat=ansible":"cpe:2.3:a:redhat:ansible_engine:*:*:*:*:*:*:*:*",
"Red Hat=Ansible Tower":"cpe:2.3:a:redhat:ansible_tower:*:*:*:*:*:*:*:*",
"Red Hat=Tower":"cpe:2.3:a:redhat:ansible_tower:*:*:*:*:*:*:*:*",
"Red Hat=ansible-tower":"cpe:2.3:a:redhat:ansible_tower:*:*:*:*:*:*:*:*",
"RedHat=Tower":"cpe:2.3:a:redhat:ansible_tower:*:*:*:*:*:*:*:*",
"[UNKNOWN]=Tower":"cpe:2.3:a:redhat:ansible_tower:*:*:*:*:*:*:*:*",
"Fedora Project Infrastructure=Bodhi":"cpe:2.3:a:redhat:bodhi:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat build of Quarkus":"cpe:2.3:a:redhat:build_of_quarkus:*:*:*:*:*:*:*:*",
"The Ceph Project=ceph":"cpe:2.3:a:redhat:ceph:*:*:*:*:*:*:*:*",
"Red Hat=Ceph Storage":"cpe:2.3:a:redhat:ceph_storage:*:*:*:*:*:*:*:*",
"Red Hat=Certificate System":"cpe:2.3:a:redhat:certificate_system:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=CloudForms":"cpe:2.3:a:redhat:cloudforms:*:*:*:*:*:*:*:*",
"Red Hat=CloudForms":"cpe:2.3:a:redhat:cloudforms_management_engine:*:*:*:*:*:*:*:*",
"Red Hat=cloudforms":"cpe:2.3:a:redhat:cloudforms_management_engine:*:*:*:*:*:*:*:*",
"[UNKNOWN]=CFME":"cpe:2.3:a:redhat:cloudforms_management_engine:*:*:*:*:*:*:*:*",
"[UNKNOWN]=CloudForms":"cpe:2.3:a:redhat:cloudforms_management_engine:*:*:*:*:*:*:*:*",
"redhat=CloudForms":"cpe:2.3:a:redhat:cloudforms_management_engine:*:*:*:*:*:*:*:*",
"Red Hat=ovirt-engine-extension-aaa-jdbc":"cpe:2.3:a:redhat:enterprise_virtualization:*:*:*:*:*:*:*:*",
"Fedora Project=CoreOS":"cpe:2.3:a:redhat:fedora_coreos:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat Gluster Storage":"cpe:2.3:a:redhat:gluster_storage:*:*:*:*:*:*:*:*",
"Red Hat=business-central":"cpe:2.3:a:redhat:jboss_bpm_suite:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=JBoss":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=JBoss Enterprise Application Platform":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=jbossas":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=resteasy":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=undertow as shipped in Jboss EAP 7.1.0.GA":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=JBoss":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=jboss":"cpe:2.3:a:redhat:jboss_enterprise_application_platform:*:*:*:*:*:*:*:*",
"RedHat=struts":"cpe:2.3:a:redhat:jboss_operations_network:*:*:*:*:*:*:*:*",
"Keycloak=keycloak":"cpe:2.3:a:redhat:keycloak:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=keycloak":"cpe:2.3:a:redhat:keycloak:*:*:*:*:*:*:*:*",
"Red Hat=keycloak":"cpe:2.3:a:redhat:keycloak:*:*:*:*:*:*:*:*",
"[UNKNOWN]=keycloak":"cpe:2.3:a:redhat:keycloak:*:*:*:*:*:*:*:*",
"keycloak=keycloak REST API":"cpe:2.3:a:redhat:keycloak:*:*:*:*:*:*:*:*",
"redhat.com=Keycloak":"cpe:2.3:a:redhat:keycloak:*:*:*:*:*:*:*:*",
"[UNKNOWN]=libnbd":"cpe:2.3:a:redhat:libnbd:*:*:*:*:*:*:*:*",
"The libvirt Project=libvirt":"cpe:2.3:a:redhat:libvirt:*:*:*:*:*:*:*:*",
"libvirt=libvirt":"cpe:2.3:a:redhat:libvirt:*:*:*:*:*:*:*:*",
"Fedora Modularity=modulemd":"cpe:2.3:a:redhat:modulemd:*:*:*:*:*:*:*:*",
"Red Hat=oddjob":"cpe:2.3:a:redhat:oddjob:*:*:*:*:*:*:*:*",
"Openshift Enterprise=openshift/apb-base":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"Openshift Enterprise=openshift/mariadb-apb":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"Red Hat=atomic-openshift":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"Red Hat=openshift":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"Red Hat=openshift/installer":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"[UNKNOWN]=atomic-openshift":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openshift/mediawiki":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openshift/mediawiki-apb":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openshift/postgresql-apb":"cpe:2.3:a:redhat:openshift:*:*:*:*:*:*:*:*",
"Openshift=openshift/openshift-apiserver":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=OpenShift":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=Openshift Container Platform":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=atomic-openshift":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=library-go":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=openshift":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=openshift-ansible":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=openshift/apb-tools-container":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=openshift/cluster-image-registry-operator":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=openshift/mysql-apb":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=web-console":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"RedHat=atomic-openshift":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"[Red Hat]=openshift-ansible":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=atomic-openshift":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openshift-ansible":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openshift/openshift-apiserver":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=routing":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"[UNKNOWN]=source-to-image":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"redhat=atomic-openshift":"cpe:2.3:a:redhat:openshift_container_platform:*:*:*:*:*:*:*:*",
"Red Hat=openshift-service-mesh/kiali-rhel7-operator":"cpe:2.3:a:redhat:openshift_service_mesh:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openstack-mistral":"cpe:2.3:a:redhat:openstack-mistral:*:*:*:*:*:*:*:*",
"OpenStack=OpenStack":"cpe:2.3:a:redhat:openstack:*:*:*:*:*:*:*:*",
"Red Hat=openstack-rabbitmq-container":"cpe:2.3:a:redhat:openstack:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openstack-keystone":"cpe:2.3:a:redhat:openstack:*:*:*:*:*:*:*:*",
"[UNKNOWN]=openstack-mistral":"cpe:2.3:a:redhat:openstack:*:*:*:*:*:*:*:*",
"[UNKNOWN]=rhosp-director":"cpe:2.3:a:redhat:openstack:*:*:*:*:*:*:*:*",
"oVirt=oVirt":"cpe:2.3:a:redhat:ovirt-engine:*:*:*:*:*:*:*:*",
"Pagure Project=Pagure":"cpe:2.3:a:redhat:pagure:*:*:*:*:*:*:*:*",
"RED HAT=quay":"cpe:2.3:a:redhat:quay:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat Quay 3":"cpe:2.3:a:redhat:quay:*:*:*:*:*:*:*:*",
"Red Hat=quay":"cpe:2.3:a:redhat:quay:*:*:*:*:*:*:*:*",
"[UNKNOWN]=quay":"cpe:2.3:a:redhat:quay:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=resteasy":"cpe:2.3:a:redhat:resteasy:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=rhnsd":"cpe:2.3:a:redhat:rhnsd:*:*:*:*:*:*:*:*",
"[UNKNOWN]=rkt":"cpe:2.3:a:redhat:rkt:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=Satellite 6":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat Satellite":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat Satellite 6":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"Red Hat=Satellite":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"Red Hat=candlepin":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"Red Hat=qpid-dispatch-router":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"[UNKNOWN]=Satellite":"cpe:2.3:a:redhat:satellite:*:*:*:*:*:*:*:*",
"Red Hat Bootloader Team=shim":"cpe:2.3:a:redhat:shim:*:*:*:*:*:*:*:*",
"Red Hat=Red Hat Single Sign-On":"cpe:2.3:a:redhat:single_sign-on:*:*:*:*:*:*:*:*",
"Openshift Enterprise=unsanitized paths in tar.go":"cpe:2.3:a:redhat:source-to-image:*:*:*:*:*:*:*:*",
"Red Hat=spacewalk":"cpe:2.3:a:redhat:spacewalk:*:*:*:*:*:*:*:*",
"Red Hat=subscription-manager":"cpe:2.3:a:redhat:subscription-manager:*:*:*:*:*:*:*:*",
"Red Hat=openshift/template-service-broker-operator":"cpe:2.3:a:redhat:template_service_broker_operator:*:*:*:*:*:*:*:*",
"undertow-io=undertow":"cpe:2.3:a:redhat:undertow:*:*:*:*:*:*:*:*",
"[UNKNOWN]=JBoss/WildFly":"cpe:2.3:a:redhat:wildfly:*:*:*:*:*:*:*:*",
"[UNKNOWN]=wildfly":"cpe:2.3:a:redhat:wildfly:*:*:*:*:*:*:*:*",
"redis=redis":"cpe:2.3:a:redis:redis:*:*:*:*:*:*:*:*",
"redis=redis":"cpe:2.3:a:redislabs:redis:*:*:*:*:*:*:*:*",
"redisson=redisson":"cpe:2.3:a:redisson:redisson:*:*:*:*:*:*:*:*",
"Redon-Tech=Roblox-Purchasing-Hub":"cpe:2.3:a:redon:roblox_purchasing_hub:*:*:*:*:*:*:*:*",
"Redwood Software=JSCAPE MFT Server":"cpe:2.3:a:redwood:jscape_mft:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Process Automation (BPA) By Redwood":"cpe:2.3:a:redwood:sap_business_process_automation:*:*:*:*:*:*:*:*",
"Ree6-Applications=Ree6":"cpe:2.3:a:ree6:ree6:*:*:*:*:*:*:*:*",
"kaidomc-pm-pl=RegionProtect":"cpe:2.3:a:regionprotect_project:regionprotect:*:*:*:*:*:*:*:*",
"rl-institut=NESP2":"cpe:2.3:a:reiner-lemoine-institut:nesp2:*:*:*:*:*:*:*:*",
"Remarshal-project=Remarshal":"cpe:2.3:a:remarshal_project:remarshal:*:*:*:*:*:*:*:*",
"REMISE Corporation=REMISE Payment Module (2.11, 2.12 and 2.13)":"cpe:2.3:a:remise:payment_module:*:*:*:*:*:*:*:*",
"SourceCodester=QR Code Login System":"cpe:2.3:a:rems:qr_code_login_system:*:*:*:*:*:*:*:*",
"SourceCodester=Book Borrower System":"cpe:2.3:a:remyandrade:book_borrower_system:*:*:*:*:*:*:*:*",
"SourceCodester=File Manager App":"cpe:2.3:a:remyandrade:file_manager_app:*:*:*:*:*:*:*:*",
"SourceCodester=Online Quiz System":"cpe:2.3:a:remyandrade:online_quiz_system:*:*:*:*:*:*:*:*",
"SourceCodester=Product Management System":"cpe:2.3:a:remyandrade:product_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=School Visitor Log e-Book":"cpe:2.3:a:remyandrade:school_visitor_log_e-book:*:*:*:*:*:*:*:*",
"SourceCodester=Sticky Notes App":"cpe:2.3:a:remyandrade:sticky_notes_app:*:*:*:*:*:*:*:*",
"SourceCodester=Testimonial Page Manager":"cpe:2.3:a:remyandrade:testimonial_page_manager:*:*:*:*:*:*:*:*",
"SourceCodester=User Registration and Login System":"cpe:2.3:a:remyandrade:user_registration_and_login_system:*:*:*:*:*:*:*:*",
"SourceCodester=Wedding Guest e-Book":"cpe:2.3:a:remyandrade:wedding_guest_e-book:*:*:*:*:*:*:*:*",
"yogeshojha=yogeshojha/rengine":"cpe:2.3:a:rengine_project:rengine:*:*:*:*:*:*:*:*",
"bjrjk=LinuxASMCallGraph":"cpe:2.3:a:renjikai:linuxasmcallgraph:*:*:*:*:*:*:*:*",
"Unknown=Rental Module":"cpe:2.3:a:rental_module_project:rental_module:*:*:*:*:*:*:*:*",
"replit=crosis":"cpe:2.3:a:replit:crosis:*:*:*:*:*:*:*:*",
"reportportal=reportportal":"cpe:2.3:a:reportportal:service-api:*:*:*:*:*:*:*:*",
"Requarks.io=Wiki.js":"cpe:2.3:a:requarks:wiki.js:*:*:*:*:*:*:*:*",
"Requarks=Wiki.js":"cpe:2.3:a:requarks:wiki.js:*:*:*:*:*:*:*:*",
"Requarks=wiki":"cpe:2.3:a:requarks:wiki.js:*:*:*:*:*:*:*:*",
"Requarks=wiki.js":"cpe:2.3:a:requarks:wiki.js:*:*:*:*:*:*:*:*",
"requarks=requarks/wiki":"cpe:2.3:a:requarks:wiki.js:*:*:*:*:*:*:*:*",
"SourceCodester=Resort Management System":"cpe:2.3:a:resort_management_system_project:resort_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Resort Reservation System":"cpe:2.3:a:resort_reservation_system_project:resort_reservation_system:*:*:*:*:*:*:*:*",
"Resort Data Processing, Inc. =IRM Next Generation":"cpe:2.3:a:resortdata:internet_reservation_module_next_generation:*:*:*:*:*:*:*:*",
"Resort Data Processing, Inc.=IRM Next Generation":"cpe:2.3:a:resortdata:internet_reservation_module_next_generation:*:*:*:*:*:*:*:*",
"code-projects=Responsive Hotel Site":"cpe:2.3:a:responsive_hotel_site_project:responsive_hotel_site:*:*:*:*:*:*:*:*",
"CodeAstro=Restaurant POS System":"cpe:2.3:a:restaurant_pos_system_project:restaurant_pos_system:*:*:*:*:*:*:*:*",
"Tomoki Fuke=Encrypted files in self-decryption format created by FileCapsule Deluxe Portable":"cpe:2.3:a:resume-next:filecapsule_deluxe_portable:*:*:*:*:*:*:*:*",
"Tomoki Fuke=FileCapsule Deluxe Portable":"cpe:2.3:a:resume-next:filecapsule_deluxe_portable:*:*:*:*:*:*:*:*",
"unspecified=retra-system":"cpe:2.3:a:retra-system_project:retra-system:*:*:*:*:*:*:*:*",
"Campcodes=Retro Basketball Shoes Online Store":"cpe:2.3:a:retro_basketball_shoes_online_store_project:retro_basketball_shoes_online_store:*:*:*:*:*:*:*:*",
"Campcodes=Retro Cellphone Online Store":"cpe:2.3:a:retro_cellphone_online_store_project:retro_cellphone_online_store:*:*:*:*:*:*:*:*",
"ReVanced=revanced-api":"cpe:2.3:a:revanced:revanced:*:*:*:*:*:*:*:*",
"Revive=Revive Adserver":"cpe:2.3:a:revive-adserver:revive_adserver:*:*:*:*:*:*:*:*",
"chbrown=rfc6902":"cpe:2.3:a:rfc6902_project:rfc6902:*:*:*:*:*:*:*:*",
"RICOH COMPANY, LTD.=Installer of Device Software Manager":"cpe:2.3:a:ricoh:device_software_manager:*:*:*:*:*:*:*:*",
"RICOH COMPANY, LTD.=Library Information Management System LIMEDIO":"cpe:2.3:a:ricoh:limedio:*:*:*:*:*:*:*:*",
"Ricoh Company, Ltd.=Printer Driver Packager NX":"cpe:2.3:a:ricoh:printer_driver_packager_nx:*:*:*:*:*:*:*:*",
"Rifartek=IOT Wall":"cpe:2.3:a:rifartek:iot_wall:*:*:*:*:*:*:*:*",
"Rigatur=Online Booking and Hotel Management System":"cpe:2.3:a:rigatur:online_booking_and_hotel_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Event Registration System":"cpe:2.3:a:rinvizle:event_registration_system:*:*:*:*:*:*:*:*",
"RISCV.org=Machine Trap Base Address (MTVEC) Register":"cpe:2.3:a:risc-v:instruction_set_manual:*:*:*:*:*:*:*:*",
"Aternity=SteelCentral AppInternals Dynamic Sampling Agent":"cpe:2.3:a:riverbed:steelcentral_appinternals_dynamic_sampling_agent:*:*:*:*:*:*:*:*",
"rizinorg=rizin":"cpe:2.3:a:rizin:rizin:*:*:*:*:*:*:*:*",
"Rizone Soft=Notepad3":"cpe:2.3:a:rizonesoft:notepad3:*:*:*:*:*:*:*:*",
"rmountjoy92=DashMachine":"cpe:2.3:a:rmountjoy92:dashmachine:*:*:*:*:*:*:*:*",
"RoadFlow=Visual Process Engine .NET Core Mvc":"cpe:2.3:a:roadflow:roadflow:*:*:*:*:*:*:*:*",
"RoboDK =RoboDK ":"cpe:2.3:a:robodk:robodk:*:*:*:*:*:*:*:*",
"Dell=RVTools ":"cpe:2.3:a:robware:rvtools:*:*:*:*:*:*:*:*",
"RocketChat=Rocket.Chat":"cpe:2.3:a:rocket.chat:rocket.chat:*:*:*:*:*:*:*:*",
"RocketSoft=Rocket LMS":"cpe:2.3:a:rocketsoft:rocket_lms:*:*:*:*:*:*:*:*",
"Xinhu=RockOA":"cpe:2.3:a:rockoa:rockoa:*:*:*:*:*:*:*:*",
"ICS-CERT=Rockwell Automation Arena":"cpe:2.3:a:rockwellautomation:arena:*:*:*:*:*:*:*:*",
"Rockwell Automation=Arena Simulation":"cpe:2.3:a:rockwellautomation:arena_simulation:*:*:*:*:*:*:*:*",
"Rockwell=Rockwell Automation Arena Simulation Software":"cpe:2.3:a:rockwellautomation:arena_simulation:*:*:*:*:*:*:*:*",
"Rockwell Automation=Connected Components Workbench":"cpe:2.3:a:rockwellautomation:connected_components_workbench:*:*:*:*:*:*:*:*",
"Rockwell Automation=Enhanced HIM":"cpe:2.3:a:rockwellautomation:enhanced_him:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk Activation":"cpe:2.3:a:rockwellautomation:factorytalk_activation:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk Alarm and Events Server":"cpe:2.3:a:rockwellautomation:factorytalk_alarms_and_events:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk AssetCentre":"cpe:2.3:a:rockwellautomation:factorytalk_assetcentre:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk Linx ":"cpe:2.3:a:rockwellautomation:factorytalk_linx:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk   Service Platform":"cpe:2.3:a:rockwellautomation:factorytalk_services_platform:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk   Services Platform":"cpe:2.3:a:rockwellautomation:factorytalk_services_platform:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk Services Platform":"cpe:2.3:a:rockwellautomation:factorytalk_services_platform:*:*:*:*:*:*:*:*",
"Rockwell=Rockwell Automation FactoryTalk Services Platform v2.90 and earlier":"cpe:2.3:a:rockwellautomation:factorytalk_services_platform:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk Transaction Manager":"cpe:2.3:a:rockwellautomation:factorytalk_transaction_manager:*:*:*:*:*:*:*:*",
"Rockwell Automation=FactoryTalk Vantagepoint":"cpe:2.3:a:rockwellautomation:factorytalk_vantagepoint:*:*:*:*:*:*:*:*",
"Rockwell Automation=ISaGRAF Workbench":"cpe:2.3:a:rockwellautomation:isagraf_workbench:*:*:*:*:*:*:*:*",
"Rockwell Automation=Modbus TCP Server Add On Instructions":"cpe:2.3:a:rockwellautomation:modbus_tcp_server_add_on_instructions:*:*:*:*:*:*:*:*",
"Rockwell Automation=Pavilion8":"cpe:2.3:a:rockwellautomation:pavilion8:*:*:*:*:*:*:*:*",
"Rockwell Automation=Studio 5000 Logix Emulate":"cpe:2.3:a:rockwellautomation:studio_5000_logix_emulate:*:*:*:*:*:*:*:*",
"Rockwell Automation=ThinManager ":"cpe:2.3:a:rockwellautomation:thinmanager:*:*:*:*:*:*:*:*",
"Rockwell Automation=ThinManager ThinServer":"cpe:2.3:a:rockwellautomation:thinmanager:*:*:*:*:*:*:*:*",
"Rockwell Automation=ThinManager ThinServer":"cpe:2.3:a:rockwellautomation:thinmanager_thinserver:*:*:*:*:*:*:*:*",
"RONDS=Equipment Predictive Maintenance Solution":"cpe:2.3:a:ronds:equipment_predictive_maintenance:*:*:*:*:*:*:*:*",
"Roon Labs=Roon Server":"cpe:2.3:a:roonlabs:roon_server:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=rkhunter":"cpe:2.3:a:rootkit_hunter_project:rootkit_hunter:*:*:*:*:*:*:*:*",
"openrobotics=ros_comm ROS communications packages":"cpe:2.3:a:ros:ros-comm:*:*:*:*:*:*:*:*",
"francoisjacquet=francoisjacquet/rosariosis":"cpe:2.3:a:rosariosis:rosariosis:*:*:*:*:*:*:*:*",
"RosarioSIS=RosarioSIS":"cpe:2.3:a:rosariosis:student_information_system:*:*:*:*:*:*:*:*",
"Rotem Dynamics=Rotem CRM":"cpe:2.3:a:rotem-dynamics:rotem_crm:*:*:*:*:*:*:*:*",
"hap-wi=roxy-wi":"cpe:2.3:a:roxy-wi:roxy-wi:*:*:*:*:*:*:*:*",
"ROZCOM=client":"cpe:2.3:a:rozcom:rozcom_client:*:*:*:*:*:*:*:*",
"ROZCOM=server framework":"cpe:2.3:a:rozcom:rozcom_client:*:*:*:*:*:*:*:*",
"libcomps=libcomps":"cpe:2.3:a:rpm:libcomps:*:*:*:*:*:*:*:*",
"Red Hat, Inc.=rpm":"cpe:2.3:a:rpm:rpm:*:*:*:*:*:*:*:*",
"Dell EMC=RSA Archer":"cpe:2.3:a:rsa:archer:*:*:*:*:*:*:*:*",
"Dell=RSA Archer":"cpe:2.3:a:rsa:archer:*:*:*:*:*:*:*:*",
"Dell=RSA Archer":"cpe:2.3:a:rsa:archer_grc_platform:*:*:*:*:*:*:*:*",
"Dell EMC=RSA Authentication Manager Security Console":"cpe:2.3:a:rsa:authentication_manager:*:*:*:*:*:*:*:*",
"Dell EMC=RSA Authentication Manager Security Console, Operation Console and Self-Service Console":"cpe:2.3:a:rsa:authentication_manager:*:*:*:*:*:*:*:*",
"Dell=RSA Authentication Manager":"cpe:2.3:a:rsa:authentication_manager:*:*:*:*:*:*:*:*",
"RSA=Web Threat Detection":"cpe:2.3:a:rsa:web_threat_detection:*:*:*:*:*:*:*:*",
"rtxteam=rtxteam/rtx":"cpe:2.3:a:rtx_project:rtx:*:*:*:*:*:*:*:*",
"Ruby Grape=ruby-grape ruby gem":"cpe:2.3:a:ruby-grape:grape:*:*:*:*:*:*:*:*",
"HackerOne=Ruby":"cpe:2.3:a:ruby-lang:ruby:*:*:*:*:*:*:*:*",
"rubygems=rubygems.org":"cpe:2.3:a:rubygems:rubygems.org:*:*:*:*:*:*:*:*",
"rubygems=rubygems.org":"cpe:2.3:a:rubygems:rubygems:*:*:*:*:*:*:*:*",
"unspecified=Ruby on Rails":"cpe:2.3:a:rubyonrails:rails:*:*:*:*:*:*:*:*",
"mayankmetha=Rucky":"cpe:2.3:a:rucky_project:rucky:*:*:*:*:*:*:*:*",
"rudderlabs=rudder-server":"cpe:2.3:a:rudderstack:rudder-server:*:*:*:*:*:*:*:*",
"RiyaLab Co., Ltd.=CloudISO":"cpe:2.3:a:ruiyanai:cloudiso:*:*:*:*:*:*:*:*",
"Rukovoditel=Project Management App":"cpe:2.3:a:rukovoditel:rukovoditel:*:*:*:*:*:*:*:*",
"y_project=RuoYi-Cloud":"cpe:2.3:a:ruoyi:ruoyi-cloud:*:*:*:*:*:*:*:*",
"y_project=RuoYi":"cpe:2.3:a:ruoyi:ruoyi:*:*:*:*:*:*:*:*",
"The Rust Programming Language=Standard Library":"cpe:2.3:a:rust-lang:rust:*:*:*:*:*:*:*:*",
"rust-lang=cargo":"cpe:2.3:a:rust-lang:rust:*:*:*:*:*:*:*:*",
"rust=cargo":"cpe:2.3:a:rust-lang:rust:*:*:*:*:*:*:*:*",
"Zack Scholl=rwtxt":"cpe:2.3:a:rwtxt_project:rwtxt:*:*:*:*:*:*:*:*",
"unspecified=S-CMS":"cpe:2.3:a:s-cms:s-cms:*:*:*:*:*:*:*:*",
"NetMove Corporation=The installer of SaAT Netizen":"cpe:2.3:a:saat:netizen:*:*:*:*:*:*:*:*",
"NetMove Corporation=The installer of SaAT Personal":"cpe:2.3:a:saat:personal:*:*:*:*:*:*:*:*",
"sabnzbd=sabnzbd":"cpe:2.3:a:sabnzbd:sabnzbd:*:*:*:*:*:*:*:*",
"Snyk=safer-eval":"cpe:2.3:a:safer-eval_project:safer-eval:*:*:*:*:*:*:*:*",
"Sage=Sage 200 Spain":"cpe:2.3:a:sage:sage_200_spain:*:*:*:*:*:*:*:*",
"Sage=XRT Treasury":"cpe:2.3:a:sage:xrt_treasury:*:*:*:*:*:*:*:*",
"salopensource=Sal":"cpe:2.3:a:sal_project:sal:*:*:*:*:*:*:*:*",
"saleor=saleor/react-storefront":"cpe:2.3:a:saleor:react-storefront:*:*:*:*:*:*:*:*",
"saleor=saleor":"cpe:2.3:a:saleor:saleor:*:*:*:*:*:*:*:*",
"saleor=saleor/saleor":"cpe:2.3:a:saleor:saleor:*:*:*:*:*:*:*:*",
"SourceCodester=Sales Tracker Management System":"cpe:2.3:a:sales_tracker_management_system_project:sales_tracker_management_system:*:*:*:*:*:*:*:*",
"Suite CRM=Suite CRM":"cpe:2.3:a:salesagility:suitecrm:*:*:*:*:*:*:*:*",
"salesagility=SuiteCRM":"cpe:2.3:a:salesagility:suitecrm:*:*:*:*:*:*:*:*",
"salesagility=SuiteCRM-Core":"cpe:2.3:a:salesagility:suitecrm:*:*:*:*:*:*:*:*",
"salesagility=salesagility/suitecrm":"cpe:2.3:a:salesagility:suitecrm:*:*:*:*:*:*:*:*",
"salesagility=salesagility/suitecrm-core":"cpe:2.3:a:salesagility:suitecrm:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Account":"cpe:2.3:a:samasung:account:*:*:*:*:*:*:*:*",
"The Samba Team=samba":"cpe:2.3:a:samba:samba:*:*:*:*:*:*:*:*",
"[UNKNOWN]=samba":"cpe:2.3:a:samba:samba:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Account":"cpe:2.3:a:samsung:account:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Account Web SDK":"cpe:2.3:a:samsung:account_web_software_development_kit:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Android USB Driver windows installer":"cpe:2.3:a:samsung:android_usb_driver_windows_installer:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Billing":"cpe:2.3:a:samsung:billing:*:*:*:*:*:*:*:*",
"Samsung Mobile=Bixby":"cpe:2.3:a:samsung:bixby:*:*:*:*:*:*:*:*",
"Samsung Mobile=Bixby Vision":"cpe:2.3:a:samsung:bixby_vision:*:*:*:*:*:*:*:*",
"Samsung Mobile=Bixby Voice":"cpe:2.3:a:samsung:bixby_voice:*:*:*:*:*:*:*:*",
"Samsung Mobile=Bixby Touch":"cpe:2.3:a:samsung:bixbytouch:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Blockchain Keystore":"cpe:2.3:a:samsung:blockchain_keystore:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Blockchain Wallet":"cpe:2.3:a:samsung:blockchain_wallet:*:*:*:*:*:*:*:*",
"Samsung Mobile=Calendar":"cpe:2.3:a:samsung:calendar:*:*:*:*:*:*:*:*",
"Samsung Mobile=Cameralyzer":"cpe:2.3:a:samsung:cameralyzer:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Capture":"cpe:2.3:a:samsung:capture:*:*:*:*:*:*:*:*",
"Samsung Mobile=Charm by Samsung":"cpe:2.3:a:samsung:charm:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Checkout":"cpe:2.3:a:samsung:checkout:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Cloud":"cpe:2.3:a:samsung:cloud:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Data Store":"cpe:2.3:a:samsung:cloud:*:*:*:*:*:*:*:*",
"Samsung Mobile=Contacts ":"cpe:2.3:a:samsung:contacts:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Mobile Devices":"cpe:2.3:a:samsung:dex:*:*:*:*:*:*:*:*",
"Samsung Mobile=SamsungDialer ":"cpe:2.3:a:samsung:dialer:*:*:*:*:*:*:*:*",
"Samsung Mobile=EasySetup":"cpe:2.3:a:samsung:easysetup:*:*:*:*:*:*:*:*",
"Samsung Mobile=Editor Lite":"cpe:2.3:a:samsung:editor_lite:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Email":"cpe:2.3:a:samsung:email:*:*:*:*:*:*:*:*",
"Samsung Open Source=Escargot":"cpe:2.3:a:samsung:escargot:*:*:*:*:*:*:*:*",
"Samsung Mobile=FactoryCamera":"cpe:2.3:a:samsung:factorycamera:*:*:*:*:*:*:*:*",
"Samsung Mobile=FactoryCameraFB":"cpe:2.3:a:samsung:factorycamerafb:*:*:*:*:*:*:*:*",
"Samsung Mobile=Find My Mobile":"cpe:2.3:a:samsung:find_my_mobile:*:*:*:*:*:*:*:*",
"Samsung Mobile=FindMyMobile":"cpe:2.3:a:samsung:find_my_mobile:*:*:*:*:*:*:*:*",
"Samsung=Samsung Galaxy Apps":"cpe:2.3:a:samsung:galaxy_apps:*:*:*:*:*:*:*:*",
"Samsung Mobile=Galaxy Buds Pro Manager":"cpe:2.3:a:samsung:galaxy_buds_pro_manage:*:*:*:*:*:*:*:*",
"Samsung Mobile=Galaxy Store":"cpe:2.3:a:samsung:galaxy_store:*:*:*:*:*:*:*:*",
"Samsung Mobile=Galaxy Themes":"cpe:2.3:a:samsung:galaxy_themes:*:*:*:*:*:*:*:*",
"Samsung Mobile=Galaxy Wearable":"cpe:2.3:a:samsung:galaxy_wearable:*:*:*:*:*:*:*:*",
"Samsung Mobile=GalaxyWatch4Plugin":"cpe:2.3:a:samsung:galaxywatch4plugin:*:*:*:*:*:*:*:*",
"Samsung Mobile=Gallery":"cpe:2.3:a:samsung:gallery:*:*:*:*:*:*:*:*",
"Samsung Mobile=Game Launcher":"cpe:2.3:a:samsung:game_launcher:*:*:*:*:*:*:*:*",
"Samsung Mobile=GameHomeCN ":"cpe:2.3:a:samsung:gamehomecn:*:*:*:*:*:*:*:*",
"Samsung Mobile=GameLauncher ":"cpe:2.3:a:samsung:gamelauncher:*:*:*:*:*:*:*:*",
"Samsung Mobile=Gear IconX PC Manager":"cpe:2.3:a:samsung:gear_iconx_pc_manager:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Gear IconX PC Manager":"cpe:2.3:a:samsung:gear_iconx_pc_manager:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Health":"cpe:2.3:a:samsung:health:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsug Internet":"cpe:2.3:a:samsung:internet:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Internet":"cpe:2.3:a:samsung:internet:*:*:*:*:*:*:*:*",
"Samsung Mobile=Kies":"cpe:2.3:a:samsung:kies:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Kies":"cpe:2.3:a:samsung:kies:*:*:*:*:*:*:*:*",
"Samsung Mobile=Knox Mobile Enrollment":"cpe:2.3:a:samsung:knox_cloud_services:*:*:*:*:*:*:*:*",
"Samsung Mobile=Link sharing":"cpe:2.3:a:samsung:link_sharing:*:*:*:*:*:*:*:*",
"Samsung Mobile=Link to Windows Service":"cpe:2.3:a:samsung:link_to_windows_service:*:*:*:*:*:*:*:*",
"Samsung Mobile=LiveWallpaper":"cpe:2.3:a:samsung:livewallpaperservice:*:*:*:*:*:*:*:*",
"Samsung=Magician":"cpe:2.3:a:samsung:magician:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Members":"cpe:2.3:a:samsung:members:*:*:*:*:*:*:*:*",
"Samsung Mobile=My Files":"cpe:2.3:a:samsung:my_files:*:*:*:*:*:*:*:*",
"Samsung Mobile=Nearby device scanning":"cpe:2.3:a:samsung:nearby_device_scanning:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Notes":"cpe:2.3:a:samsung:notes:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Notes ":"cpe:2.3:a:samsung:notes:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung notes":"cpe:2.3:a:samsung:notes:*:*:*:*:*:*:*:*",
"Samsung=Samsung Notes":"cpe:2.3:a:samsung:notes:*:*:*:*:*:*:*:*",
"Samsung Mobile=PackageInstallerCHN ":"cpe:2.3:a:samsung:packageinstallerchn:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Pass":"cpe:2.3:a:samsung:pass:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Pay":"cpe:2.3:a:samsung:pay:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Pay Mini":"cpe:2.3:a:samsung:pay_mini:*:*:*:*:*:*:*:*",
"Samsung Mobile=PENUP":"cpe:2.3:a:samsung:penup:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Push Service":"cpe:2.3:a:samsung:push_service:*:*:*:*:*:*:*:*",
"Samsung Mobile= Quick Share":"cpe:2.3:a:samsung:quick_share:*:*:*:*:*:*:*:*",
"Samsung Mobile=Quick Share":"cpe:2.3:a:samsung:quick_share:*:*:*:*:*:*:*:*",
"Samsung Mobile=QuickShare":"cpe:2.3:a:samsung:quick_share:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Recovery":"cpe:2.3:a:samsung:recovery:*:*:*:*:*:*:*:*",
"Samsung Mobile=Reminder":"cpe:2.3:a:samsung:reminder:*:*:*:*:*:*:*:*",
"Samsung Mobile=S Assistant":"cpe:2.3:a:samsung:s_assistant:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Assistant":"cpe:2.3:a:samsung:samsung_assistant:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Blockchain Keystore":"cpe:2.3:a:samsung:samsung_blockchain_keystore:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Core Service":"cpe:2.3:a:samsung:samsung_core_services:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Email":"cpe:2.3:a:samsung:samsung_email:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung email":"cpe:2.3:a:samsung:samsung_email:*:*:*:*:*:*:*:*",
"Samsung=Samsung Email":"cpe:2.3:a:samsung:samsung_email:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Flow":"cpe:2.3:a:samsung:samsung_flow:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Gallery":"cpe:2.3:a:samsung:samsung_gallery:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Internet Browser":"cpe:2.3:a:samsung:samsung_internet_browser:*:*:*:*:*:*:*:*",
"Samsung=Samsung Internet Browser":"cpe:2.3:a:samsung:samsung_internet_browser:*:*:*:*:*:*:*:*",
"Samsung=Samsung Members":"cpe:2.3:a:samsung:samsung_members:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Security Supporter":"cpe:2.3:a:samsung:samsung_security_supporter:*:*:*:*:*:*:*:*",
"Samsung Mobile=SAssistant":"cpe:2.3:a:samsung:sassistant:*:*:*:*:*:*:*:*",
"Samsung Mobile=Search Widget":"cpe:2.3:a:samsung:search_widget:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung SearchWidget":"cpe:2.3:a:samsung:searchwidget:*:*:*:*:*:*:*:*",
"Samsung Mobile=SearchWidget":"cpe:2.3:a:samsung:searchwidget:*:*:*:*:*:*:*:*",
"Samsung Mobile=ShareLive":"cpe:2.3:a:samsung:sharelive:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Capture":"cpe:2.3:a:samsung:smart_capture:*:*:*:*:*:*:*:*",
"Samsung Mobile=Smart Manager":"cpe:2.3:a:samsung:smart_manager:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Smart Switch":"cpe:2.3:a:samsung:smart_switch_pc:*:*:*:*:*:*:*:*",
"Samsung Mobile=Smart Switch PC":"cpe:2.3:a:samsung:smart_switch_pc:*:*:*:*:*:*:*:*",
"Samsung Mobile=Smart Things":"cpe:2.3:a:samsung:smart_things:*:*:*:*:*:*:*:*",
"Samsung Mobile=Smart Touch Call":"cpe:2.3:a:samsung:smart_touch_call:*:*:*:*:*:*:*:*",
"Samsung Mobile=SmartTagPlugin":"cpe:2.3:a:samsung:smarttagplugin:*:*:*:*:*:*:*:*",
"Samsung Mobile=SmartThings":"cpe:2.3:a:samsung:smartthings:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Theme":"cpe:2.3:a:samsung:themes:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Update":"cpe:2.3:a:samsung:update:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Update Setup":"cpe:2.3:a:samsung:update:*:*:*:*:*:*:*:*",
"Samsung Mobile=UPHelper":"cpe:2.3:a:samsung:uphelper_library:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Video Player":"cpe:2.3:a:samsung:video_player:*:*:*:*:*:*:*:*",
"Samsung Mobile=Voice Note":"cpe:2.3:a:samsung:voice_note:*:*:*:*:*:*:*:*",
"Sanalogy=Turasistan":"cpe:2.3:a:sanalogi:turasistan:*:*:*:*:*:*:*:*",
"SourceCodester=Shopping Website":"cpe:2.3:a:sanchitkmr:shopping_website:*:*:*:*:*:*:*:*",
"Sandsprite=Scdbg":"cpe:2.3:a:sandsprite:scdbg:*:*:*:*:*:*:*:*",
"Sangfor=Net-Gen Application Firewall":"cpe:2.3:a:sangfor:next-gen_application_firewall:*:*:*:*:*:*:*:*",
"FreePBX=arimanager":"cpe:2.3:a:sangoma:freepbx:*:*:*:*:*:*:*:*",
"FreePBX=cdr":"cpe:2.3:a:sangoma:freepbx:*:*:*:*:*:*:*:*",
"sanic-org=sanic":"cpe:2.3:a:sanic_project:sanic:*:*:*:*:*:*:*:*",
"SourceCodester=Sanitization Management System":"cpe:2.3:a:sanitization_management_system_project:sanitization_management_system:*:*:*:*:*:*:*:*",
"Ministry of the Environment=Installer of Houkokusyo Sakusei Shien Tool":"cpe:2.3:a:santeikohyo:installer_of_houkokusyo_sakusei_shien_tool:*:*:*:*:*:*:*:*",
"Sante=DICOM Viewer Pro":"cpe:2.3:a:santesoft:dicom_viewer_pro:*:*:*:*:*:*:*:*",
"Santesoft=Sante DICOM Viewer Pro":"cpe:2.3:a:santesoft:dicom_viewer_pro:*:*:*:*:*:*:*:*",
"Santesoft=Sante FFT Imaging":"cpe:2.3:a:santesoft:fft_imaging:*:*:*:*:*:*:*:*",
"Sante=PACS Server":"cpe:2.3:a:santesoft:sante_pacs_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP 3D Visual Enterprise Author":"cpe:2.3:a:sap:3d_visual_enterprise_author:*:*:*:*:*:*:*:*",
"SAP SE=SAP 3D Visual Enterprise Viewer":"cpe:2.3:a:sap:3d_visual_enterprise_viewer:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (ABAP Server) and ABAP Platform":"cpe:2.3:a:sap:abap_platform:*:*:*:*:*:*:*:*",
"SAP=ABAP  Platform":"cpe:2.3:a:sap:abap_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP ABAP Platform Kernel":"cpe:2.3:a:sap:abap_platform_kernel:*:*:*:*:*:*:*:*",
"SAP SE=SAP GRC Access Control":"cpe:2.3:a:sap:access_control:*:*:*:*:*:*:*:*",
"SAP SE=SAP GRC Access Control Emergency Access Management":"cpe:2.3:a:sap:access_control:*:*:*:*:*:*:*:*",
"SAP SE=SAP Adaptive Server Enterprise":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP SE=SAP Adaptive Server Enterprise (ASE)":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP SE=SAP Adaptive Server Enterprise (Web Services)":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP SE=SAP Adaptive Server Enterprise (XP Server on Windows Platform)":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP SE=SAP Financial Consolidation":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP=SAP Adaptive Server Enterprise":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP=SAP Adaptive Server Enterprise (ASE)":"cpe:2.3:a:sap:adaptive_server_enterprise:*:*:*:*:*:*:*:*",
"SAP SE=SAP Adaptive Server Enterprise (Backup Server)":"cpe:2.3:a:sap:adaptive_server_enterprise_backup_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP Adaptive Server Enterprise (Cockpit)":"cpe:2.3:a:sap:adaptive_server_enterprise_cockpit:*:*:*:*:*:*:*:*",
"SAP=SAP_ABA":"cpe:2.3:a:sap:advanced_business_application_programming:*:*:*:*:*:*:*:*",
"SAP SE=SAP Analysis for Microsoft Office":"cpe:2.3:a:sap:analysis_for_microsoft_office:*:*:*:*:*:*:*:*",
"SAP=Application Interface Framework (Message Monitoring)":"cpe:2.3:a:sap:application_interface:*:*:*:*:*:*:*:*",
"SAP=Application Interface Framework (ODATA service)":"cpe:2.3:a:sap:application_interface_framework:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Application Interface Framework (File Adapter)":"cpe:2.3:a:sap:application_interface_framework:*:*:*:*:*:*:*:*",
"SAP SE=SAP Application Server ABAP (ST-PI)":"cpe:2.3:a:sap:application_server:*:*:*:*:*:*:*:*",
"SAP=Bank Account Management (Manage Banks)":"cpe:2.3:a:sap:bank_account_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Banking Services":"cpe:2.3:a:sap:banking_services:*:*:*:*:*:*:*:*",
"SAP SE=SAP Banking Services (Generic Market Data)":"cpe:2.3:a:sap:banking_services:*:*:*:*:*:*:*:*",
"SAP SE=Automated Note Search Tool (SAP Basis)":"cpe:2.3:a:sap:basis:*:*:*:*:*:*:*:*",
"SAP=BASIS":"cpe:2.3:a:sap:basis:*:*:*:*:*:*:*:*",
"SAP=SAP Basis (TREX / BWA installation)":"cpe:2.3:a:sap:basis:*:*:*:*:*:*:*:*",
"SAP SE=SAP BI Launchpad":"cpe:2.3:a:sap:bi_launchpad:*:*:*:*:*:*:*:*",
"SAP SE=SAP Biller Direct":"cpe:2.3:a:sap:biller_direct:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Biller Direct":"cpe:2.3:a:sap:biller_direct:*:*:*:*:*:*:*:*",
"SAP SE=SAP BASIS":"cpe:2.3:a:sap:business_application_software_integrated_solution:*:*:*:*:*:*:*:*",
"SAP SE=SAP BASIS (ABAP File Interface)":"cpe:2.3:a:sap:business_application_software_integrated_solution:*:*:*:*:*:*:*:*",
"SAP=SAP Note Assistant":"cpe:2.3:a:sap:business_application_software_integrated_solution:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Client":"cpe:2.3:a:sap:business_client:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (AdminTools/Query Builder)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Program Objects)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Visual Difference Application)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=Business Objects (Adaptive Job Server)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=Business Objects Business Intelligence Platform (CMC)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=Business Objects Business Intelligence Platform (Web intelligence)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=SAP Business Objects Platform (Monitoring DB) ":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP_SE=Business Objects BI Platform":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence (Web Intelligence UI)":"cpe:2.3:a:sap:business_objects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business One":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business One (Backup service)":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business one":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP=SAP Business One":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Business One":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Business One (B1i Layer)":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Business One (B1i)":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Business One (Service Layer)":"cpe:2.3:a:sap:business_one:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business One Client":"cpe:2.3:a:sap:business_one_client:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business One License service API":"cpe:2.3:a:sap:business_one_license_service_api:*:*:*:*:*:*:*:*",
"SAP=SAP Business One Service Layer (B1_ON_HANA)":"cpe:2.3:a:sap:business_one_on_hana:*:*:*:*:*:*:*:*",
"Insteon=SAP":"cpe:2.3:a:sap:business_planning_and_consolidation:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Planning and Consolidation":"cpe:2.3:a:sap:business_planning_and_consolidation:*:*:*:*:*:*:*:*",
"SAP=Business Planning and Consolidation":"cpe:2.3:a:sap:business_planning_and_consolidation:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Business Planning and Consolidation":"cpe:2.3:a:sap:business_planning_and_consolidation:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Warehouse":"cpe:2.3:a:sap:business_warehouse:*:*:*:*:*:*:*:*",
"SAP=SAP Business Warehouse Universal Data Integration":"cpe:2.3:a:sap:business_warehouse_universal_data_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Administration Console)":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Fiori Launchpad)":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform Servers (Enterprise)":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence platform":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence platform (Central Management Server)":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Platform":"cpe:2.3:a:sap:businessobjects:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Analysis, (edition for OLAP)":"cpe:2.3:a:sap:businessobjects_analysis:*:*:*:*:*:*:*:*",
"SAP SE=SAP WebIntelligence BILaunchPad (Enterprise)":"cpe:2.3:a:sap:businessobjects_bi_platform:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects BI Platform Servers (Software Development Kit)":"cpe:2.3:a:sap:businessobjects_bi_platform:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_bi_platform:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Platform (BIWorkspace)":"cpe:2.3:a:sap:businessobjects_bi_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (CMC and BI Launchpad)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (CMC)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (Web Intelligence)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (BI LaunchPad)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (BI Workspace)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (CMC Module)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (CMC)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Commentary DB)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Crystal Report)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Info View)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Open Document)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (SAPUI5)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Version Management System)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Web Intelligence)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform - BI Workspace (Enterprise)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence platform (Analysis for OLAP)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence platform (Web Intelligence HTML interface)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=BusinessObjects Business Intelligence Platform (Promotion Management)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=BusinessObjects Business Intelligence Platform (Web Services)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence (BI Launchpad and Central Management Console)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Platform Server":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Suite":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence (Installer)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence Platform (Central Management Service)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence Platform (Promotion Management)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence Platform (Version Management System)":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (CMC and BI launchpad)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (CMC)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (Live Data Connect)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (BI Workspace)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (CMC)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Central management Console)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Crystal Reports)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Fiori BI Launchpad)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (LCM)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Monitoring Application)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Business Intelligence Platform (Web Intelligence)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=BusinessObjects Business Intelligence Platform (CMC)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=BusinessObjects Business Intelligence Platform (Central management console)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=BusinessObjects Business Intelligence Platform (Web Services)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence Platform":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Business Intelligence Platform (Web Intelligence HTML interface)":"cpe:2.3:a:sap:businessobjects_business_intelligence_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects (BW Publisher Service)":"cpe:2.3:a:sap:businessobjects_bw_publisher_service:*:*:*:*:*:*:*:*",
"SAP=SAP BusinessObjects Financial Consolidation":"cpe:2.3:a:sap:businessobjects_financial_consolidation:*:*:*:*:*:*:*:*",
"SAP SE=SAP BusinessObjects Mobile (MobileBIService)":"cpe:2.3:a:sap:businessobjects_mobile:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Web Intelligence (BI Launchpad)":"cpe:2.3:a:sap:businessobjects_web_intelligence:*:*:*:*:*:*:*:*",
"SAP_SE=SAP BusinessObjects Web Intelligence":"cpe:2.3:a:sap:businessobjects_web_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business One (Cookbooks)":"cpe:2.3:a:sap:chef_business-one-cookbook:*:*:*:*:*:*:*:*",
"SAP SE=CLA-Assistant":"cpe:2.3:a:sap:cla-assistant:*:*:*:*:*:*:*:*",
"SAP SE=SAP Cloud Connector":"cpe:2.3:a:sap:cloud_connector:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Cloud Connector":"cpe:2.3:a:sap:cloud_connector:*:*:*:*:*:*:*:*",
"SAP SE=SAP Cloud Platform Connector":"cpe:2.3:a:sap:cloud_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce":"cpe:2.3:a:sap:commerce:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce (Backoffice Search)":"cpe:2.3:a:sap:commerce:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud (Mediaconversion Extension)":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud (SmartEdit Extension)":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud (Testweb Extension)":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud (ex SAP Hybris Commerce) (HY_COM)":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud (virtualjdbc extension)":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Commerce Cloud":"cpe:2.3:a:sap:commerce_cloud:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce Cloud (Accelerator Payment Mock)":"cpe:2.3:a:sap:commerce_cloud_\(accelerator_payment_mock\):*:*:*:*:*:*:*:*",
"SAP=Commerce Webservices 2.0 (Swagger UI)":"cpe:2.3:a:sap:commerce_webservices_2.0:*:*:*:*:*:*:*:*",
"SAP SE=SAP CommonCryptoLib":"cpe:2.3:a:sap:commoncryptolib:*:*:*:*:*:*:*:*",
"SAP SE=SAP Contact Center":"cpe:2.3:a:sap:contact_center:*:*:*:*:*:*:*:*",
"SAP=Content Server":"cpe:2.3:a:sap:content_server:*:*:*:*:*:*:*:*",
"SAP SE=CLA Assistant":"cpe:2.3:a:sap:contributor_license_agreement_assistant:*:*:*:*:*:*:*:*",
"SAP_SE=CLA Assistant":"cpe:2.3:a:sap:contributor_license_agreement_assistant:*:*:*:*:*:*:*:*",
"SAP SE=SAP Business Objects Business Intelligence Platform (Crystal Reports)":"cpe:2.3:a:sap:crystal_reports:*:*:*:*:*:*:*:*",
"SAP SE=SAP CRM":"cpe:2.3:a:sap:customer_relationship_management:*:*:*:*:*:*:*:*",
"SAP=CRM":"cpe:2.3:a:sap:customer_relationship_management:*:*:*:*:*:*:*:*",
"SAP_SE=SAP CRM ABAP (Grantor Management)":"cpe:2.3:a:sap:customer_relationship_management_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP Data Intelligence":"cpe:2.3:a:sap:data_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP Data Services Management Console":"cpe:2.3:a:sap:data_services:*:*:*:*:*:*:*:*",
"SAP=SAP Data Services":"cpe:2.3:a:sap:data_services:*:*:*:*:*:*:*:*",
"SAP SE=SAP Data Hub (DH_Foundation)":"cpe:2.3:a:sap:diagnostics_agent:*:*:*:*:*:*:*:*",
"SAP SE=SAP Diagnostic Agent (LM-Service)":"cpe:2.3:a:sap:diagnostics_agent:*:*:*:*:*:*:*:*",
"SAP=Diagnostics Agent (OSCommand Bridge)":"cpe:2.3:a:sap:diagnostics_agent:*:*:*:*:*:*:*:*",
"SAP SE=SAP Disclosure Management":"cpe:2.3:a:sap:disclosure_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Disclosure Management.":"cpe:2.3:a:sap:disclosure_management:*:*:*:*:*:*:*:*",
"SAP=Disclosure Management":"cpe:2.3:a:sap:disclosure_management:*:*:*:*:*:*:*:*",
"SAP=SAP Disclosure Management":"cpe:2.3:a:sap:disclosure_management:*:*:*:*:*:*:*:*",
"SAP=SAP Dynamic Authorization Management (DAM) by NextLabs (Java Policy Controller versions) ":"cpe:2.3:a:sap:dynamic_authorization_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP E-Commerce (Business-to-Consumer application)":"cpe:2.3:a:sap:e-commerce:*:*:*:*:*:*:*:*",
"SAP SE=SAP E-Commerce (SAP-CRMJAV, SAP-CRMWEB, SAP-SHRWEB, SAP-SHRJAV, SAP-CRMAPP, SAP-SHRAPP)":"cpe:2.3:a:sap:e-commerce:*:*:*:*:*:*:*:*",
"SAP SE=SAP Employee Self Service (Fiori My Leave Request)":"cpe:2.3:a:sap:employee_self_service:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enable Now":"cpe:2.3:a:sap:enable_now:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enable Now (SAP Workforce Performance Builder - Manager)":"cpe:2.3:a:sap:enable_now:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Enable Now":"cpe:2.3:a:sap:enable_now:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enable Now Manager":"cpe:2.3:a:sap:enable_now_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enterprise Extension Defense Forces & Public Security (EA-DFPS)":"cpe:2.3:a:sap:enterprise_extension_defense_forces_\&_public_security:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enterprise Financial Services (Bank Customer Accounts)":"cpe:2.3:a:sap:enterprise_financial_services:*:*:*:*:*:*:*:*",
"SAP=SAP Enterprise Financial Services":"cpe:2.3:a:sap:enterprise_financial_services:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enterprise Portal":"cpe:2.3:a:sap:enterprise_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP Enterprise Threat Detection":"cpe:2.3:a:sap:enterprise_threat_detection:*:*:*:*:*:*:*:*",
"SAP SE=SAP ERP Client for E-Bilanz 1.0":"cpe:2.3:a:sap:erp_client_for_e-bilanz:*:*:*:*:*:*:*:*",
"SAP_SE=SAP ERP Defense Forces and Public Security":"cpe:2.3:a:sap:erp_defense_forces_and_public_security:*:*:*:*:*:*:*:*",
"SAP SE=SAP ERP Financial Accounting (RFOPENPOSTING_FR)":"cpe:2.3:a:sap:erp_financial_accounting:*:*:*:*:*:*:*:*",
"SAP SE=SAP ERP Financials Information System":"cpe:2.3:a:sap:erp_financials_information_system:*:*:*:*:*:*:*:*",
"SAP SE=SAP ERP HCM (SAP_HRCES) ":"cpe:2.3:a:sap:erp_hcm:*:*:*:*:*:*:*:*",
"SAP SE=SAP Financial Consolidation":"cpe:2.3:a:sap:financial_consolidation:*:*:*:*:*:*:*:*",
"SAP SE=SAP Fiori for SAP S/4HANA":"cpe:2.3:a:sap:fiori:*:*:*:*:*:*:*:*",
"SAP=Fiori apps 1.0 for travel management in SAP ERP (My Travel Requests)":"cpe:2.3:a:sap:fiori:*:*:*:*:*:*:*:*",
"SAP SE=SAP Fiori Apps 2.0 for Travel Management in SAP ERP":"cpe:2.3:a:sap:fiori_apps_2.0_for_travel_management_in_sap_erp:*:*:*:*:*:*:*:*",
"SAP=SAP Fiori Client":"cpe:2.3:a:sap:fiori_client:*:*:*:*:*:*:*:*",
"SAP SE=Fiori Launchpad":"cpe:2.3:a:sap:fiori_launchpad:*:*:*:*:*:*:*:*",
"SAP SE=SAP Fiori Launchpad":"cpe:2.3:a:sap:fiori_launchpad:*:*:*:*:*:*:*:*",
"SAP SE=SAP Fiori(Launchpad)":"cpe:2.3:a:sap:fiori_launchpad:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Fiori Launchpad":"cpe:2.3:a:sap:fiori_launchpad:*:*:*:*:*:*:*:*",
"SAP SE=SAP Fiori Launchpad (News Tile Application)":"cpe:2.3:a:sap:fiori_launchpad_\(news_tile_application\):*:*:*:*:*:*:*:*",
"SAP SE=SAP Focused RUN":"cpe:2.3:a:sap:focused_run:*:*:*:*:*:*:*:*",
"SAP SE=SAP Focused Run (Real User Monitoring)":"cpe:2.3:a:sap:focused_run:*:*:*:*:*:*:*:*",
"SAP SE=SAP Focused Run (Simple Diagnostics Agent)":"cpe:2.3:a:sap:focused_run:*:*:*:*:*:*:*:*",
"SAP SE=SAP Gateway":"cpe:2.3:a:sap:gateway:*:*:*:*:*:*:*:*",
"SAP SE=SAP Banking Services (Generic Market Data)":"cpe:2.3:a:sap:generic_market_data:*:*:*:*:*:*:*:*",
"SAP_SE=SAP GRC (Process Control)":"cpe:2.3:a:sap:grc_process_control:*:*:*:*:*:*:*:*",
"SAP SE=SAP HANA":"cpe:2.3:a:sap:hana:*:*:*:*:*:*:*:*",
"SAP=SAP HANA":"cpe:2.3:a:sap:hana:*:*:*:*:*:*:*:*",
"SAP SE=SAP HANA Database":"cpe:2.3:a:sap:hana_database:*:*:*:*:*:*:*:*",
"SAP=SAP HANA extended application services":"cpe:2.3:a:sap:hana_database:*:*:*:*:*:*:*:*",
"SAP SE=SAP HANA Extended Application Services":"cpe:2.3:a:sap:hana_extended_application_services:*:*:*:*:*:*:*:*",
"SAP SE=SAP HANA Extended Application Services (advanced model)":"cpe:2.3:a:sap:hana_extended_application_services:*:*:*:*:*:*:*:*",
"SAP SE=SAP HANA Extended Application Services, advanced model (XS advanced)":"cpe:2.3:a:sap:hana_extended_application_services:*:*:*:*:*:*:*:*",
"SAP=SAP HANA Extended Application Services":"cpe:2.3:a:sap:hana_extended_application_services:*:*:*:*:*:*:*:*",
"SAP=SAP HANA extended application services":"cpe:2.3:a:sap:hana_extended_application_services:*:*:*:*:*:*:*:*",
"SAP=HCM Fiori App My Forms (Fiori 2.0)":"cpe:2.3:a:sap:hcm_fiori_app_my_forms:*:*:*:*:*:*:*:*",
"SAP SE=SAP ERP (HCM Travel Management)":"cpe:2.3:a:sap:hcm_travel_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Host Agent":"cpe:2.3:a:sap:host_agent:*:*:*:*:*:*:*:*",
"SAP SE=SAP Host Agent (SAPOSCOL)":"cpe:2.3:a:sap:host_agent:*:*:*:*:*:*:*:*",
"SAP=Host Agent (SAPOSCOL)":"cpe:2.3:a:sap:host_agent:*:*:*:*:*:*:*:*",
"SAP=Host Agent Service":"cpe:2.3:a:sap:host_agent:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Host Agent":"cpe:2.3:a:sap:host_agent:*:*:*:*:*:*:*:*",
"SAP_SE=SAP HCM (SMART PAYE solution)":"cpe:2.3:a:sap:human_capital_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Commerce (ex. SAP Hybris Commerce)":"cpe:2.3:a:sap:hybris:*:*:*:*:*:*:*:*",
"SAP=SAP Commerce (SAP Hybris Commerce)":"cpe:2.3:a:sap:hybris:*:*:*:*:*:*:*:*",
"SAP=SAP Hybris Commerce":"cpe:2.3:a:sap:hybris:*:*:*:*:*:*:*:*",
"SAP SE=SAP Identity Management":"cpe:2.3:a:sap:identity_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Identity Management (REST Interface)":"cpe:2.3:a:sap:identity_management:*:*:*:*:*:*:*:*",
"SAP=SAP Identity Management":"cpe:2.3:a:sap:identity_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Information Steward":"cpe:2.3:a:sap:information_steward:*:*:*:*:*:*:*:*",
"SAP SE=InfraBox":"cpe:2.3:a:sap:infrabox:*:*:*:*:*:*:*:*",
"SAP SE=SAP InfraBox":"cpe:2.3:a:sap:infrabox:*:*:*:*:*:*:*:*",
"SAP SE=SAP Innovation management":"cpe:2.3:a:sap:innovation_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Internet Graphics Server":"cpe:2.3:a:sap:internet_graphics_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP Internet Graphics Server (IGS)":"cpe:2.3:a:sap:internet_graphics_server:*:*:*:*:*:*:*:*",
"SAP= SAP Internet Graphics Server (IGS)":"cpe:2.3:a:sap:internet_graphics_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (CA Introscope Enterprise Manager) and SAP Focused Run (CA Introscope Enterprise Manager)":"cpe:2.3:a:sap:introscope_enterprise_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Knowledge Warehouse":"cpe:2.3:a:sap:knowledge_warehouse:*:*:*:*:*:*:*:*",
"SAP SE=SAP HANA Extended Application Services":"cpe:2.3:a:sap:landscape_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Landscape Management":"cpe:2.3:a:sap:landscape_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP Landscape Management(VCM)":"cpe:2.3:a:sap:landscape_management:*:*:*:*:*:*:*:*",
"SAP_SE=SAP LT Replication Server":"cpe:2.3:a:sap:lt_replication_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP Lumira Server":"cpe:2.3:a:sap:lumira_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP Manufacturing Execution":"cpe:2.3:a:sap:manufacturing_execution:*:*:*:*:*:*:*:*",
"SAP SE=SAP Manufacturing Execution (System Rules)":"cpe:2.3:a:sap:manufacturing_execution:*:*:*:*:*:*:*:*",
"SAP SE=SAP Manufacturing Integration and Intelligence":"cpe:2.3:a:sap:manufacturing_integration_and_intelligence:*:*:*:*:*:*:*:*",
"SAP SE=SAP Marketing (Mobile Channel Servlet)":"cpe:2.3:a:sap:marketing:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Marketing (Contacts App)":"cpe:2.3:a:sap:marketing:*:*:*:*:*:*:*:*",
"SAP SE=SAP Master Data Governance":"cpe:2.3:a:sap:master_data_governance:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Master Data Governance":"cpe:2.3:a:sap:master_data_governance:*:*:*:*:*:*:*:*",
"SAP_SE=Master Data Synchronization (MDS COMPARE TOOL)":"cpe:2.3:a:sap:master_data_synchronization:*:*:*:*:*:*:*:*",
"SAP=SAP MaxDB (liveCache)":"cpe:2.3:a:sap:maxdb:*:*:*:*:*:*:*:*",
"SAP SE=SAP MaxDB ODBC driver":"cpe:2.3:a:sap:maxdb_odbc_driver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Message Server":"cpe:2.3:a:sap:message_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP Mobile Platform":"cpe:2.3:a:sap:mobile_platform:*:*:*:*:*:*:*:*",
"SAP=SAP Mobile Platform":"cpe:2.3:a:sap:mobile_platform:*:*:*:*:*:*:*:*",
"SAP SE=SAP Mobile Platform SDK":"cpe:2.3:a:sap:mobile_platform_sdk:*:*:*:*:*:*:*:*",
"SAP SE=SAP Mobile SDK Certificate Provider":"cpe:2.3:a:sap:mobile_sdk_certificate_provider:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (ABAP and Java application Servers)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (EP Web Page Composer)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (Visual Composer 7.0 RT)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver - XML Toolkit for JAVA (ENGINEAPI)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (MigrationService)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver UDDI Server (Services Registry)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP=Knowledge Management (XMLForms) in SAP NetWeaver":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP=NetWeaver (BI CONT ADDON)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP=SAP NetWeaver (forums)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP=SAP NetWeaver Application Server for ABAP":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP=SAP WebDynpro":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP ECC and SAP S/4HANA (IS-OIL)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver (Change and Transport System)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver (Design Time Repository)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver (Guided Procedures)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver (Internet Communication Manager)":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver Enterprise Portal":"cpe:2.3:a:sap:netweaver:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver ABAP Server and ABAP Platform":"cpe:2.3:a:sap:netweaver_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for ABAP (RFC Gateway)":"cpe:2.3:a:sap:netweaver_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver ABAP Server and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Banking Services)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Reconciliation Framework)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (SAP GUI for HTML within the Fiori Launchpad)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Web Dynpro)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Workplace Server)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP and ABAP Platform (SRM_RFC_SUBMIT_REPORT)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for ABAP":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for ABAP (Web Survey)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server ABAP":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server ABAP (Applications based on SAP GUI for HTML)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server ABAP (POWL test application)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server for ABAP":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server for ABAP (SAP Cloud Print Manager and SAPSprint)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server for ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP Netweaver AS ABAP":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS ABAP (BSP Framework)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS for ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS for ABAP and ABAP Platform (SAPRSBRO Program)":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP=NetWeaver Application Server for ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver AS ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver AS for ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver Application Server ABAP and ABAP Platform":"cpe:2.3:a:sap:netweaver_application_server_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for JAVA":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for Java (UserAdmin)":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server for Java":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS Java (Object Analyzing Service)":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS for Java":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP_SE=SAP AS NetWeaver JAVA":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver AS for Java (Log Viewer)":"cpe:2.3:a:sap:netweaver_application_server_for_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (Real Time Messaging Framework)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (Administrator applications)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (Enterprise Portal)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (Key Storage Service)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (LM Configuration Wizard)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (P2P Cluster Communication)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS JAVA (XML Forms)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS Java":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS Java (HTTP Service)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS Java (Heap Dump Application)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for JAVA (Customer Usage Provisioning Servlet)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for JAVA (HTTP Service)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for JAVA (Telnet Commands)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for Java (Applications based on HTMLB for Java)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS for Java (Http Service)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server Java":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server Java (Applications based on Web Dynpro Java)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server Java (J2EE-Framework)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server Java (JMS Connector Service)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server Java (User Management Engine)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server for Java (Administrator System Overview)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server for Java (Startup Framework)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Java Application Server (J2EE-APPS)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS Java for Classload Service":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS for Java (Http Provider Service)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP=SAP NetWeaver AS Java":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP=SAP NetWeaver AS Java (ServerCore)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP=SAP NetWeaver Application Server (Java Library)":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP_SE=NetWeaver AS Java":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver AS Java":"cpe:2.3:a:sap:netweaver_application_server_java:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver ABAP Server and ABAP Platform (Dispatcher)":"cpe:2.3:a:sap:netweaver_as_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver ABAP Server and ABAP Platform (Enqueue Server)":"cpe:2.3:a:sap:netweaver_as_abap:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (BSP Test Application)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Business Server Pages Test Application IT00)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Business Server Pages Test Application IT05)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Business Server Pages Test Application SBSPEXT_PHTMLB)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Business Server Pages Test Application SBSPEXT_TABLE)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Business Server Pages application CRM_BSP_FRAME)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Application Server ABAP (Smart Forms) - SAP_BASIS":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS ABAP (BSP Framework)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS ABAP (Business Server Pages application)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS for ABAP (Business Server Pages)":"cpe:2.3:a:sap:netweaver_as_abap_business_server_pages:*:*:*:*:*:*:*:*",
"SAP SE=SAP Internet Graphics Service":"cpe:2.3:a:sap:netweaver_as_internet_graphics_server:*:*:*:*:*:*:*:*",
"SAP=NetWeaver AS Java for Deploy Service":"cpe:2.3:a:sap:netweaver_as_java_for_deploy_service:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver (BI CONT ADD ON)":"cpe:2.3:a:sap:netweaver_bi_content:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (Compare Systems)":"cpe:2.3:a:sap:netweaver_compare_systems:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Composite Application Framework":"cpe:2.3:a:sap:netweaver_composite_application_framework:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (DI Design Time Repository)":"cpe:2.3:a:sap:netweaver_design_time_repository:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Developer Studio (NWDS)":"cpe:2.3:a:sap:netweaver_developer_studio:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Development Infrastructure (Component Build Service)":"cpe:2.3:a:sap:netweaver_development_infrastructure:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Development Infrastructure (Design Time Repository)":"cpe:2.3:a:sap:netweaver_development_infrastructure:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Development Infrastructure (Notification Service)":"cpe:2.3:a:sap:netweaver_development_infrastructure:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Enterprise Portal":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Enterprise Portal (Application Extensions)":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Enterprise Portal (Fiori Framework Page)":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Enterprise Portal (KMC)":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Enterprise Portal (WPC)":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP=NetWeaver (SAP Enterprise Portal)":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP=NetWeaver Enterprise Portal":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP=SAP NetWeaver Enterprise Portal":"cpe:2.3:a:sap:netweaver_enterprise_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (Guided Procedures)":"cpe:2.3:a:sap:netweaver_guided_procedures:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Guided Procedures (Administration Workset)":"cpe:2.3:a:sap:netweaver_guided_procedures:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Java Web Application":"cpe:2.3:a:sap:netweaver_java_web_application:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (Knowledge Management ICE Service)":"cpe:2.3:a:sap:netweaver_knowledge_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver (Knowledge Management)":"cpe:2.3:a:sap:netweaver_knowledge_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Knowledge Management":"cpe:2.3:a:sap:netweaver_knowledge_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Knowledge Management XML Forms":"cpe:2.3:a:sap:netweaver_knowledge_management_xml_forms:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Master Data Management":"cpe:2.3:a:sap:netweaver_master_data_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Master Data Management Server":"cpe:2.3:a:sap:netweaver_master_data_management_server:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Enterprise Portal":"cpe:2.3:a:sap:netweaver_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Portal WebDynpro RunTime":"cpe:2.3:a:sap:netweaver_portal:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration (Adapter Engine)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration (B2B Toolkit)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration (Java Proxy Runtime)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration (Messaging System)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration (Runtime Workbench)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration ABAP tests (SAP Basis)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration Runtime Workbench     MESSAGING and SAP_XIAF":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver Process Integration(SAP_XIESR and SAP_XITOOL)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP Process Integration (Enterprise Service Repository JAVA Mappings)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP Process Integration (Integration Builder Framework)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP=NetWeaver Process Integration":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver Process Integration":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver Process Integration (Message Display Tool)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP_SE=SAP NetWeaver Process Integration (Runtime Workbench)":"cpe:2.3:a:sap:netweaver_process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver System Landscape Directory, LM-Core":"cpe:2.3:a:sap:netweaver_system_landscape_directory:*:*:*:*:*:*:*:*",
"SAP SE=SAP OrientDB":"cpe:2.3:a:sap:orientdb:*:*:*:*:*:*:*:*",
"SAP SE=SAP Payment Engine":"cpe:2.3:a:sap:payment_engine:*:*:*:*:*:*:*:*",
"SAP=SAP Plant Connectivity":"cpe:2.3:a:sap:plant_connectivity:*:*:*:*:*:*:*:*",
"SAP_SE=SAP PowerDesigner":"cpe:2.3:a:sap:powerdesigner:*:*:*:*:*:*:*:*",
"SAP_SE=SAP PowerDesigner Client":"cpe:2.3:a:sap:powerdesigner:*:*:*:*:*:*:*:*",
"SAP SE=SAP PowerDesigner Proxy 16.7":"cpe:2.3:a:sap:powerdesigner_proxy:*:*:*:*:*:*:*:*",
"SAP_SE=SAP PowerDesigner (Proxy)":"cpe:2.3:a:sap:powerdesigner_proxy:*:*:*:*:*:*:*:*",
"SAP SE=SAP Process Integration - Rest Adapter (SAP_XIAF)":"cpe:2.3:a:sap:process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP Process Integration, business-to-business add-on":"cpe:2.3:a:sap:process_integration:*:*:*:*:*:*:*:*",
"SAP SE=SAP Process Integration (PGP - Module Business-to-Business Add On)":"cpe:2.3:a:sap:process_integration_\(pgp_module_-_business-to-business_add_on\):*:*:*:*:*:*:*:*",
"SAP SE=SAP Process Monitoring Infrastructure":"cpe:2.3:a:sap:process_monitoring_infrastructure:*:*:*:*:*:*:*:*",
"SAP SE=SAP Quality Management (S4CORE)":"cpe:2.3:a:sap:quality_management:*:*:*:*:*:*:*:*",
"SAP SE=SAP R/3 Enterprise Application":"cpe:2.3:a:sap:r\/3_enterprise:*:*:*:*:*:*:*:*",
"SAP= SAP R/3 Enterprise Retail":"cpe:2.3:a:sap:r\/3_enterprise_retail:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Manage Purchase Contracts App":"cpe:2.3:a:sap:s4core:*:*:*:*:*:*:*:*",
"SAP_SE=SAP S/4HANA (Manage Journal Entry Template)":"cpe:2.3:a:sap:s4core:*:*:*:*:*:*:*:*",
"SAP_SE=S4 HANA ABAP (Manage checkbook apps)":"cpe:2.3:a:sap:s\/4_hana:*:*:*:*:*:*:*:*",
"SAP_SE=SAP S/4HANA (Create Single Payment application)":"cpe:2.3:a:sap:s\/4_hana:*:*:*:*:*:*:*:*",
"SAP SE=SAP S/4 HANA (Fiori UI for General Ledger Accounting)":"cpe:2.3:a:sap:s\/4_hana_fiori_ui_for_general_ledger_accounting:*:*:*:*:*:*:*:*",
"SAP SE=SAP S/4HANA":"cpe:2.3:a:sap:s\/4hana:*:*:*:*:*:*:*:*",
"SAP SE=SAP S/4HANA (Manage Catalog Items and Cross-Catalog search)":"cpe:2.3:a:sap:s\/4hana:*:*:*:*:*:*:*:*",
"SAP SE=SAP S/4HANA (Supplier Factsheet and Enterprise Search for Business Partner, Supplier and Customer)":"cpe:2.3:a:sap:s\/4hana:*:*:*:*:*:*:*:*",
"SAP=S/4 HANA (Map Treasury Correspondence Format Data)":"cpe:2.3:a:sap:s\/4hana:*:*:*:*:*:*:*:*",
"SAP_SE=S/4HANA (Manage Withholding Tax Items)":"cpe:2.3:a:sap:s\/4hana:*:*:*:*:*:*:*:*",
"SAP_SE=SAP S/4HANA Core":"cpe:2.3:a:sap:s\/4hana:*:*:*:*:*:*:*:*",
"SAP SE=SAF-T Framework":"cpe:2.3:a:sap:saf-t_framework:*:*:*:*:*:*:*:*",
"SAP=SAP Change and Transport System (ABAP)":"cpe:2.3:a:sap:sap_kernel:*:*:*:*:*:*:*:*",
"SAP=Trusted RFC connection":"cpe:2.3:a:sap:sap_kernel:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager system (ST-PI)":"cpe:2.3:a:sap:sap_solution_manager_system:*:*:*:*:*:*:*:*",
"SAP SE=SAPCAR":"cpe:2.3:a:sap:sapcar:*:*:*:*:*:*:*:*",
"SAP=SapSetup (Software Installation Program)":"cpe:2.3:a:sap:sapsetup:*:*:*:*:*:*:*:*",
"SAP_SE=SAPUI5":"cpe:2.3:a:sap:sapui5:*:*:*:*:*:*:*:*",
"SAP SE=SAPUI5 (vbm library)":"cpe:2.3:a:sap:sapui5_library:*:*:*:*:*:*:*:*",
"SAP SE=SCIMono":"cpe:2.3:a:sap:scimono:*:*:*:*:*:*:*:*",
"SAP SE=SAP Setup":"cpe:2.3:a:sap:setup:*:*:*:*:*:*:*:*",
"SAP SE=SAP Focused Run (Simple Diagnostics Agent)":"cpe:2.3:a:sap:simple_diagnostics_agent:*:*:*:*:*:*:*:*",
"SAP SE=Simple Diagnostics Agent":"cpe:2.3:a:sap:simple_diagnostics_agent:*:*:*:*:*:*:*:*",
"SAP SE=SAP Software Provisioning Manager 1.0 (SAP NetWeaver Master Data Management Server 7.1)":"cpe:2.3:a:sap:software_provisioning_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (Diagnostics Agent)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (Diagnostics Root Cause Analysis Tools)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (JAVA stack)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (Problem Context Manager)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (Trace Analysis)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager (User Experience Monitoring)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP Solution Manager(Diagnostics Agent)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP=Solution Manager":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP=Solution Manager (BSP Application)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP=Solution Manager (Enterprise Search)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP=Solution Manager and ABAP managed systems":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Solution Manager":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Solution Manager (Diagnostic Agent)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Solution Manager (Diagnostics agent)":"cpe:2.3:a:sap:solution_manager:*:*:*:*:*:*:*:*",
"SAP SE=SAP SQL Anywhere":"cpe:2.3:a:sap:sql_anywhere:*:*:*:*:*:*:*:*",
"SAP SE=SAP SQL Anywhere Server":"cpe:2.3:a:sap:sql_anywhere:*:*:*:*:*:*:*:*",
"SAP_SE=SAP SQL Anywhere":"cpe:2.3:a:sap:sql_anywhere:*:*:*:*:*:*:*:*",
"SAP SE=SAP Supplier Relationship Management (Master Data Management Catalog) (SRM_MDM_CAT)":"cpe:2.3:a:sap:supplier_relationship_management:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Supplier Relationship Management":"cpe:2.3:a:sap:supplier_relationship_management:*:*:*:*:*:*:*:*",
"SAP=SAP SRM-MDM CATALOG":"cpe:2.3:a:sap:supplier_relationship_management_mdm_catalog:*:*:*:*:*:*:*:*",
"SAP=SAP Supplier Relationship Management Master Data Management Catalog":"cpe:2.3:a:sap:supplier_relationship_management_mdm_catalog:*:*:*:*:*:*:*:*",
"SAP SE=SAP UI5":"cpe:2.3:a:sap:ui5:*:*:*:*:*:*:*:*",
"SAP_SE=SAP UI5 Variant Management":"cpe:2.3:a:sap:ui:*:*:*:*:*:*:*:*",
"SAP SE=SAP Web Dispatcher":"cpe:2.3:a:sap:web_dispatcher:*:*:*:*:*:*:*:*",
"SAP_SE=SAP Web Dispatcher":"cpe:2.3:a:sap:web_dispatcher:*:*:*:*:*:*:*:*",
"SAP SE=SAP NetWeaver AS ABAP (Web Dynpro ABAP)":"cpe:2.3:a:sap:web_dynpro_abap:*:*:*:*:*:*:*:*",
"unspecified=SAP Information System":"cpe:2.3:a:sap_information_system_project:sap_information_system:*:*:*:*:*:*:*:*",
"Saphira=Saphira Connect":"cpe:2.3:a:saphira:connect:*:*:*:*:*:*:*:*",
"Satos=Satos Mobile":"cpe:2.3:a:satos:satos_mobile:*:*:*:*:*:*:*:*",
"unknown=CASE Suite":"cpe:2.3:a:sauter-controls:case_suite:*:*:*:*:*:*:*:*",
"Savoir-faire Linux=Jami":"cpe:2.3:a:savoirfairelinux:jami:*:*:*:*:*:*:*:*",
"Saysis=Starcities":"cpe:2.3:a:saysis:starcities:*:*:*:*:*:*:*:*",
"SBI SECURITIES Co.,Ltd.=HYPER SBI":"cpe:2.3:a:sbisec:hyper_sbi:*:*:*:*:*:*:*:*",
"sbs20=sbs20/scanservjs":"cpe:2.3:a:scanservjs_project:scanservjs:*:*:*:*:*:*:*:*",
"Snyk=schema-inspector":"cpe:2.3:a:schema-inspector_project:schema-inspector:*:*:*:*:*:*:*:*",
"Schneider Electric=Accutech Manager":"cpe:2.3:a:schneider-electric:accutech_manager:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Ampla MES":"cpe:2.3:a:schneider-electric:ampla_manufacturing_execution_system:*:*:*:*:*:*:*:*",
"Schneider Electric=C-Bus Toolkit":"cpe:2.3:a:schneider-electric:c-bus_toolkit:*:*:*:*:*:*:*:*",
"Schneider Electric=CanBRASS":"cpe:2.3:a:schneider-electric:canbrass:*:*:*:*:*:*:*:*",
"Schneider Electric=Data Center Expert":"cpe:2.3:a:schneider-electric:data_center_expert:*:*:*:*:*:*:*:*",
"Schneider Electric=Easergy Builder Installer":"cpe:2.3:a:schneider-electric:easergy_builder_installer:*:*:*:*:*:*:*:*",
"Schneider Electric=Easergy Studio":"cpe:2.3:a:schneider-electric:easergy_studio:*:*:*:*:*:*:*:*",
"Schneider Electric=EcoStruxure Control Expert":"cpe:2.3:a:schneider-electric:ecostruxure_control_expert:*:*:*:*:*:*:*:*",
"Schneider Electric=EcoStruxure    Cybersecurity Admin Expert (CAE)":"cpe:2.3:a:schneider-electric:ecostruxure_cybersecurity_admin_expert:*:*:*:*:*:*:*:*",
"Schneider Electric=EcoStruxure Foxboro DCS Control Core Services":"cpe:2.3:a:schneider-electric:ecostruxure_foxboro_dcs_control_core_services:*:*:*:*:*:*:*:*",
"Schneider Electric=EcoStruxure Power Commission":"cpe:2.3:a:schneider-electric:ecostruxure_power_commission:*:*:*:*:*:*:*:*",
"Schneider Electric=EcoStruxure Power Monitoring Expert":"cpe:2.3:a:schneider-electric:ecostruxure_power_monitoring_expert:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Eurotherm by Schneider Electric GUIcon V2.0 (Gold Build 683.0)":"cpe:2.3:a:schneider-electric:guicon:*:*:*:*:*:*:*:*",
"Schneider Electric=IGSS Dashboard (DashBoard.exe)":"cpe:2.3:a:schneider-electric:igss_dashboard:*:*:*:*:*:*:*:*",
"Schneider Electric SE=IIoT Monitor 3.1.38":"cpe:2.3:a:schneider-electric:iiot_monior:*:*:*:*:*:*:*:*",
"Schneider Electric SE=IIoT Monitor 3.1.38":"cpe:2.3:a:schneider-electric:iiot_monitor:*:*:*:*:*:*:*:*",
"Schneider Electric=IGSS Data Server (IGSSdataServer.exe)":"cpe:2.3:a:schneider-electric:interactive_graphical_scada_system:*:*:*:*:*:*:*:*",
"Schneider Electric=IGSS Update Service (IGSSupdateservice.exe)":"cpe:2.3:a:schneider-electric:interactive_graphical_scada_system:*:*:*:*:*:*:*:*",
"Schneider Electric SE=PowerSuite 2, All released versions (VW3A8104 & Patches)":"cpe:2.3:a:schneider-electric:powersuite_2:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Pro-Face GP-Pro EX v4.08 and previous versions":"cpe:2.3:a:schneider-electric:pro-face_gp-pro_ex:*:*:*:*:*:*:*:*",
"Schneider Electric SE=ProFace GP-Pro EX":"cpe:2.3:a:schneider-electric:pro-face_gp_pro_ex:*:*:*:*:*:*:*:*",
"ProClima=ProClima all versions prior to version 8.0.0":"cpe:2.3:a:schneider-electric:proclima:*:*:*:*:*:*:*:*",
"Schneider Electric=Ritto Wiser Door":"cpe:2.3:a:schneider-electric:ritto_wiser_door:*:*:*:*:*:*:*:*",
"Schneider Electric=SCADAPack Workbench":"cpe:2.3:a:schneider-electric:scadapack_workbench:*:*:*:*:*:*:*:*",
"Schneider Electric=Software Update (SESU)     SUT Service component":"cpe:2.3:a:schneider-electric:software_update:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Schneider Electric Software Update (SESU), all versions prior to V2.2.0":"cpe:2.3:a:schneider-electric:software_update_utility:*:*:*:*:*:*:*:*",
"Schneider Electric SE=SoMachine HVAC Programming Software":"cpe:2.3:a:schneider-electric:somachine:*:*:*:*:*:*:*:*",
"Schneider Electric SE=SoMachine Basic":"cpe:2.3:a:schneider-electric:somachine_basic:*:*:*:*:*:*:*:*",
"Schneider Electric SE=SoMachine HVAC":"cpe:2.3:a:schneider-electric:somachine_hvac:*:*:*:*:*:*:*:*",
"Schneider Electric SE=SoMachine HVAC Programming Software":"cpe:2.3:a:schneider-electric:somachine_hvac:*:*:*:*:*:*:*:*",
"Schneider Electric=C-Bus Toolkit":"cpe:2.3:a:schneider-electric:spacelogic_c-bus_toolkit:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Data Center Expert versions 7.5.0 and earlier":"cpe:2.3:a:schneider-electric:struxureware_data_center_expert:*:*:*:*:*:*:*:*",
"Schneider Electric=StruxureWare Data Center Expert":"cpe:2.3:a:schneider-electric:struxureware_data_center_expert:*:*:*:*:*:*:*:*",
"Schneider Electric=StruxureWare Data Center Expert ":"cpe:2.3:a:schneider-electric:struxureware_data_center_expert:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Data Center Operation all versions":"cpe:2.3:a:schneider-electric:struxureware_data_center_operation:*:*:*:*:*:*:*:*",
"Schneider Electric SE=U.Motion":"cpe:2.3:a:schneider-electric:u.motion:*:*:*:*:*:*:*:*",
"Schneider Electric SE=U.motion Builder":"cpe:2.3:a:schneider-electric:u.motion:*:*:*:*:*:*:*:*",
"Schneider Electric SE=U.Motion":"cpe:2.3:a:schneider-electric:u.motion_builder:*:*:*:*:*:*:*:*",
"Schneider Electric SE=U.motion Builder":"cpe:2.3:a:schneider-electric:u.motion_builder:*:*:*:*:*:*:*:*",
"U.motion=U.motion Builder software version 1.3.4":"cpe:2.3:a:schneider-electric:u.motion_builder:*:*:*:*:*:*:*:*",
"Schneider Electric SE=VAMPSET":"cpe:2.3:a:schneider-electric:vampset:*:*:*:*:*:*:*:*",
"Schneider Electric SE=Zelio Soft 2 v5.1 and prior versions":"cpe:2.3:a:schneider-electric:zelio_soft_2:*:*:*:*:*:*:*:*",
"Zelio Soft=Zelio Soft 2 V5.2 and prior":"cpe:2.3:a:schneider-electric:zelio_soft_2:*:*:*:*:*:*:*:*",
"schokokeksorg=freewvs":"cpe:2.3:a:schokokeks:freewvs:*:*:*:*:*:*:*:*",
"unspecified=School Club Application System":"cpe:2.3:a:school_club_application_system_project:school_club_application_system:*:*:*:*:*:*:*:*",
"PHP Scripts Mall Pvt. Ltd.=School College Portal with ERP Script":"cpe:2.3:a:school_college_portal_with_erp_script_project:school_college_portal_with_erp_script:*:*:*:*:*:*:*:*",
"SourceCodester=School Dormitory Management System":"cpe:2.3:a:school_dormitory_management_system_project:school_dormitory_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=School Registration and Fee System":"cpe:2.3:a:school_registration_and_fee_system_project:school_registration_and_fee_system:*:*:*:*:*:*:*:*",
"Schoolbox Pty Ltd=Schoolbox":"cpe:2.3:a:schoolbox:schoolbox:*:*:*:*:*:*:*:*",
"ScienceLogic=SL 1":"cpe:2.3:a:sciencelogic:sl1:*:*:*:*:*:*:*:*",
"unspecified=scifio":"cpe:2.3:a:scif:scifio:*:*:*:*:*:*:*:*",
"mkucej=mkucej/i-librarian-free":"cpe:2.3:a:scilico:i\,_librarian:*:*:*:*:*:*:*:*",
"mkucej=mkucej/i-librarian-free":"cpe:2.3:a:scilicot:i\,_librarian:*:*:*:*:*:*:*:*",
"MonikaBrzica=scm":"cpe:2.3:a:scm_project:scm:*:*:*:*:*:*:*:*",
"ScratchAddons=ScratchAddons":"cpe:2.3:a:scratchaddons:scratch_addons:*:*:*:*:*:*:*:*",
"STForScratch=ScratchTools":"cpe:2.3:a:scratchstatus:scratchtools:*:*:*:*:*:*:*:*",
"ScratchVerifier=ScratchVerifier":"cpe:2.3:a:scratchverifier:scratchverifier:*:*:*:*:*:*:*:*",
"Soar Cloud Ltd. =HR Portal":"cpe:2.3:a:scshr:hr_portal:*:*:*:*:*:*:*:*",
"ssbc=SSB-DB":"cpe:2.3:a:scuttlebutt:ssb-db:*:*:*:*:*:*:*:*",
"scylladb=scylladb":"cpe:2.3:a:scylladb:scylladb:*:*:*:*:*:*:*:*",
"SDD Computer Software=SDD-Baro":"cpe:2.3:a:sdd-baro_project:sdd-baro:*:*:*:*:*:*:*:*",
"SDG Technologies=PnPSCADA":"cpe:2.3:a:sdg:pnpscada:*:*:*:*:*:*:*:*",
"Tomoki Sanaki=sDNSProxy.exe":"cpe:2.3:a:sdnsproxy_project:sdnsproxy:*:*:*:*:*:*:*:*",
"spbu-se=spbu_se_site":"cpe:2.3:a:se.math.spbu:spbu_se_site:*:*:*:*:*:*:*:*",
"floragunn=Search Guard":"cpe:2.3:a:search-guard:search_guard:*:*:*:*:*:*:*:*",
"SearchBlox=SearchBlox":"cpe:2.3:a:searchblox:searchblox:*:*:*:*:*:*:*:*",
"TAIWAN SECOM CO., LTD.,=Door Access Control and Personnel Attendance Management system":"cpe:2.3:a:secom:dr.id_access_control:*:*:*:*:*:*:*:*",
"Secomea=GateManager":"cpe:2.3:a:secomea:gatemanager:*:*:*:*:*:*:*:*",
"Secomea=SiteManager Embedded":"cpe:2.3:a:secomea:sitemanager_embedded:*:*:*:*:*:*:*:*",
"Secomea=SiteManager Embedded (SM-E)":"cpe:2.3:a:secomea:sitemanager_embedded:*:*:*:*:*:*:*:*",
"Sumitomo Electric Information Systems Co., Ltd.=RakRak Document Plus":"cpe:2.3:a:sei-info:rakrak_document_plus:*:*:*:*:*:*:*:*",
"seleniumhq=seleniumhq/selenium":"cpe:2.3:a:selenium:selenium:*:*:*:*:*:*:*:*",
"Schweitzer Engineering Laboratories, Inc.=AcSELerator Architect":"cpe:2.3:a:selinc:acselerator_architect:*:*:*:*:*:*:*:*",
"Schweitzer Engineering Laboratories=SEL-5030 acSELerator QuickSet Software":"cpe:2.3:a:selinc:sel-5030_acselerator_quickset:*:*:*:*:*:*:*:*",
"Schweitzer Engineering Laboratories=SEL-5037 SEL Grid Configurator":"cpe:2.3:a:selinc:sel-5037_sel_grid_configurator:*:*:*:*:*:*:*:*",
"Schweitzer Engineering Laboratories, Inc.=Compass":"cpe:2.3:a:selinc:sel_compass:*:*:*:*:*:*:*:*",
"unspecified=SEMCMS":"cpe:2.3:a:sem-cms:semcms:*:*:*:*:*:*:*:*",
"semantic-release=semantic-release":"cpe:2.3:a:semantic-release_project:semantic-release:*:*:*:*:*:*:*:*",
"LoRa Basics=LoRa Basics Station":"cpe:2.3:a:semtech:lora_basics_station:*:*:*:*:*:*:*:*",
"LoRa=LoRaMac-node":"cpe:2.3:a:semtech:loramac-node:*:*:*:*:*:*:*:*",
"Lora-net=LoRaMac-node":"cpe:2.3:a:semtech:loramac-node:*:*:*:*:*:*:*:*",
"semver-regex=semver-regex":"cpe:2.3:a:semver-regex_project:semver-regex:*:*:*:*:*:*:*:*",
"sindresorhus=sindresorhus/semver-regex":"cpe:2.3:a:semver-regex_project:semver-regex:*:*:*:*:*:*:*:*",
"symfony=symfony":"cpe:2.3:a:sensiolabs:symfony:*:*:*:*:*:*:*:*",
"Senstar=Symphony":"cpe:2.3:a:senstar:symphony:*:*:*:*:*:*:*:*",
"getsentry=sentry":"cpe:2.3:a:sentry:sentry:*:*:*:*:*:*:*:*",
"getsentry=symbolicator":"cpe:2.3:a:sentry:symbolicator:*:*:*:*:*:*:*:*",
"SEO Panel=SEO Panel":"cpe:2.3:a:seopanel:seo_panel:*:*:*:*:*:*:*:*",
"sepidz=SepidzDigitalMenu":"cpe:2.3:a:sepidz:sepidzdigitalmenu:*:*:*:*:*:*:*:*",
"serde=serde_yaml":"cpe:2.3:a:serde-yaml_project:serde-yaml:*:*:*:*:*:*:*:*",
"Fuzzy=SWMP":"cpe:2.3:a:server_web_monitor_page_project:server_web_monitor_page:*:*:*:*:*:*:*:*",
"ServiceStack=ServiceStack Framework":"cpe:2.3:a:servicestack:servicestack:*:*:*:*:*:*:*:*",
"Setelsa Security=ConacWin":"cpe:2.3:a:setelsa-security:conacwin:*:*:*:*:*:*:*:*",
"Setelsa Security=ConacWin CB":"cpe:2.3:a:setelsa-security:conacwin:*:*:*:*:*:*:*:*",
"SEW-EURODRIVE=MOVITOOLS MotionStudio":"cpe:2.3:a:seweurodrive:movitools_motionstudio:*:*:*:*:*:*:*:*",
"Sewio=RTLS Studio":"cpe:2.3:a:sewio:real-time_location_system_studio:*:*:*:*:*:*:*:*",
"HackerOne=sexstatic":"cpe:2.3:a:sexstatic_project:sexstatic:*:*:*:*:*:*:*:*",
"drakkan=sftpgo":"cpe:2.3:a:sftpgo_project:sftpgo:*:*:*:*:*:*:*:*",
"pkp=pkp/customLocale":"cpe:2.3:a:sfu:customlocale:*:*:*:*:*:*:*:*",
"pkp=pkp/ojs":"cpe:2.3:a:sfu:open_journal_system:*:*:*:*:*:*:*:*",
"pkp=pkp/ojs":"cpe:2.3:a:sfu:open_journal_systems:*:*:*:*:*:*:*:*",
"pkp=pkp/pkp-lib":"cpe:2.3:a:sfu:pkp_web_application_library:*:*:*:*:*:*:*:*",
"Cyber Security Cloud , Inc.=Shadankun Server Security Type (excluding normal blocking method types)":"cpe:2.3:a:shadan-kun:server_security_type:*:*:*:*:*:*:*:*",
"Shanxi Diankeyun Technology=NODERP":"cpe:2.3:a:shanxi_tianneng_technology:noderp:*:*:*:*:*:*:*:*",
"sharetribe=sharetribe":"cpe:2.3:a:sharetribe:sharetribe:*:*:*:*:*:*:*:*",
"SharpCompress=SharpCompress":"cpe:2.3:a:sharpcompress_project:sharpcompress:*:*:*:*:*:*:*:*",
"adamhathcock=sharpcompress":"cpe:2.3:a:sharpcompress_project:sharpcompress:*:*:*:*:*:*:*:*",
"SharpZipLib=SharpZipLib":"cpe:2.3:a:sharpziplib_project:sharpziplib:*:*:*:*:*:*:*:*",
"icsharpcode=sharpziplib":"cpe:2.3:a:sharpziplib_project:sharpziplib:*:*:*:*:*:*:*:*",
"code-projects=Online FIR System":"cpe:2.3:a:sherlock:online_fir_system:*:*:*:*:*:*:*:*",
"ericcornelissen=shescape":"cpe:2.3:a:shescape_project:shescape:*:*:*:*:*:*:*:*",
"Shift Tech Inc.=bingo!CMS":"cpe:2.3:a:shift-tech:bingo\!cms:*:*:*:*:*:*:*:*",
"ShifuML=shifu":"cpe:2.3:a:shifuml:shifu:*:*:*:*:*:*:*:*",
"EKAKIN=Shihonkanri Plus GOOUT":"cpe:2.3:a:shihonkanri_plus_goout_project:shihonkanri_plus_goout:*:*:*:*:*:*:*:*",
"Ministry of Justice=Installer of Shogyo Touki Denshi Ninsho Software":"cpe:2.3:a:shogyo:touki_denshi:*:*:*:*:*:*:*:*",
"ShokoAnime=ShokoServer":"cpe:2.3:a:shokoanime:shokoserver:*:*:*:*:*:*:*:*",
"shopizer-ecommerce=Shopizer":"cpe:2.3:a:shopizer:shopizer:*:*:*:*:*:*:*:*",
"shopizer-ecommerce=shopizer":"cpe:2.3:a:shopizer:shopizer:*:*:*:*:*:*:*:*",
"shopware=platform":"cpe:2.3:a:shopware:shopware:*:*:*:*:*:*:*:*",
"shopware=shopware":"cpe:2.3:a:shopware:shopware:*:*:*:*:*:*:*:*",
"shopware=SwagPayPal":"cpe:2.3:a:shopware:swagpaypal:*:*:*:*:*:*:*:*",
"OSM Lab=show-me-the-way":"cpe:2.3:a:show-me-the-way_project:show-me-the-way:*:*:*:*:*:*:*:*",
"star7th=star7th/showdoc":"cpe:2.3:a:showdoc:showdoc:*:*:*:*:*:*:*:*",
"shred=cilla":"cpe:2.3:a:shredzone:cilla:*:*:*:*:*:*:*:*",
"shuup=shuup":"cpe:2.3:a:shuup:shuup:*:*:*:*:*:*:*:*",
"SialWeb=CMS":"cpe:2.3:a:sialweb:sialweb_cms:*:*:*:*:*:*:*:*",
"SiberianCMS=SiberianCMS":"cpe:2.3:a:siberiancms:siberiancms:*:*:*:*:*:*:*:*",
"SICK AG=EventCam App":"cpe:2.3:a:sick:sick_eventcam_app:*:*:*:*:*:*:*:*",
"SICUNET=Access Controller":"cpe:2.3:a:sicunet:access_control:*:*:*:*:*:*:*:*",
"SideQuestVR=SideQuest":"cpe:2.3:a:sidequestvr:sidequest:*:*:*:*:*:*:*:*",
"Siemens=syngo fastView":"cpe:2.3:a:siemens-healthineers:syngo_fastview:*:*:*:*:*:*:*:*",
"Siemens AG=Automation License Manager 5":"cpe:2.3:a:siemens:automation_license_manager:*:*:*:*:*:*:*:*",
"Siemens AG=Automation License Manager 5, Automation License Manager 6":"cpe:2.3:a:siemens:automation_license_manager:*:*:*:*:*:*:*:*",
"Siemens=COMOS":"cpe:2.3:a:siemens:comos:*:*:*:*:*:*:*:*",
"Siemens=Control Center Server (CCS)":"cpe:2.3:a:siemens:control_center_server:*:*:*:*:*:*:*:*",
"Siemens=CoreShield One-Way Gateway (OWG) Software":"cpe:2.3:a:siemens:coreshield_one-way_gateway:*:*:*:*:*:*:*:*",
"siemens=efibootguard":"cpe:2.3:a:siemens:efibootguard:*:*:*:*:*:*:*:*",
"Siemens=Industrial Edge Management":"cpe:2.3:a:siemens:industrial_edge_management:*:*:*:*:*:*:*:*",
"Siemens=JT Utilities":"cpe:2.3:a:siemens:jt_utilities:*:*:*:*:*:*:*:*",
"Siemens AG=License Management Utility (LMU)":"cpe:2.3:a:siemens:license_management_utility:*:*:*:*:*:*:*:*",
"Siemens=LOGO! Soft Comfort":"cpe:2.3:a:siemens:logo\!_soft_comfort:*:*:*:*:*:*:*:*",
"Siemens=Mendix Email Connector":"cpe:2.3:a:siemens:mendix_email_connector:*:*:*:*:*:*:*:*",
"Siemens=OpenV2G":"cpe:2.3:a:siemens:openv2g:*:*:*:*:*:*:*:*",
"Siemens=PLM Help Server V4.2":"cpe:2.3:a:siemens:plm_help_server:*:*:*:*:*:*:*:*",
"Siemens AG=Polarion":"cpe:2.3:a:siemens:polarion:*:*:*:*:*:*:*:*",
"Siemens=Polarion ALM":"cpe:2.3:a:siemens:polarion_alm:*:*:*:*:*:*:*:*",
"Siemens AG=Polarion Subversion Webclient":"cpe:2.3:a:siemens:polarion_subversion_webclient:*:*:*:*:*:*:*:*",
"Siemens=QMS Automotive":"cpe:2.3:a:siemens:qms_automotive:*:*:*:*:*:*:*:*",
"Siemens=RUGGEDCOM CROSSBOW":"cpe:2.3:a:siemens:ruggedcom_crossbow:*:*:*:*:*:*:*:*",
"Siemens=SENTRON powermanager V3":"cpe:2.3:a:siemens:sentron_powermanager_3:*:*:*:*:*:*:*:*",
"Siemens=SICAM PAS/PQS":"cpe:2.3:a:siemens:sicam_pas\/pqs:*:*:*:*:*:*:*:*",
"Siemens=SICAM TOOLBOX II":"cpe:2.3:a:siemens:sicam_toolbox_ii:*:*:*:*:*:*:*:*",
"Siemens=Siemens OPC UA Modelling Editor (SiOME)":"cpe:2.3:a:siemens:siemens_opc_ua_modeling_editor:*:*:*:*:*:*:*:*",
"Siemens=SIMARIS configuration":"cpe:2.3:a:siemens:simaris_configuration:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC CN 4100":"cpe:2.3:a:siemens:simatic_cn_4100:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC eaSie Core Package":"cpe:2.3:a:siemens:simatic_easie_core_package:*:*:*:*:*:*:*:*",
"Siemens AG=SIMATIC IT UADM":"cpe:2.3:a:siemens:simatic_it_uadm:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC PCS neo":"cpe:2.3:a:siemens:simatic_pcs_neo:*:*:*:*:*:*:*:*",
"Siemens AG=SIMATIC RTLS Locating Manager":"cpe:2.3:a:siemens:simatic_rtls_locating_manager:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC RTLS Locating Manager":"cpe:2.3:a:siemens:simatic_rtls_locating_manager:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC S7-PLCSIM V5.4":"cpe:2.3:a:siemens:simatic_s7-plcsim:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC STEP 7 (TIA Portal)":"cpe:2.3:a:siemens:simatic_step_7:*:*:*:*:*:*:*:*",
"Siemens AG=SIMATIC STEP 7 (TIA Portal)":"cpe:2.3:a:siemens:simatic_step_7_\(tia_portal\):*:*:*:*:*:*:*:*",
"Siemens=Simcenter Amesim":"cpe:2.3:a:siemens:simcenter_amesim:*:*:*:*:*:*:*:*",
"Siemens=Simcenter Femap":"cpe:2.3:a:siemens:simcenter_femap:*:*:*:*:*:*:*:*",
"Siemens=Simcenter STAR-CCM+ Viewer":"cpe:2.3:a:siemens:simcenter_star-ccm\+:*:*:*:*:*:*:*:*",
"Siemens=Simcenter STAR-CCM+":"cpe:2.3:a:siemens:simcenter_star-ccm\+_viewer:*:*:*:*:*:*:*:*",
"Siemens=Simcenter STAR-CCM+ Viewer":"cpe:2.3:a:siemens:simcenter_star-ccm\+_viewer:*:*:*:*:*:*:*:*",
"Siemens=SINEC NMS":"cpe:2.3:a:siemens:sinec_nms:*:*:*:*:*:*:*:*",
"Siemens=SINEMA Remote Connect Server":"cpe:2.3:a:siemens:sinema_remote_connect_server:*:*:*:*:*:*:*:*",
"Siemens=SINEMA Server V14":"cpe:2.3:a:siemens:sinema_server:*:*:*:*:*:*:*:*",
"Siemens=SINUMERIK Edge":"cpe:2.3:a:siemens:sinumerik_edge:*:*:*:*:*:*:*:*",
"Siemens=SiPass integrated":"cpe:2.3:a:siemens:sipass_integrated:*:*:*:*:*:*:*:*",
"Siemens AG=SIPORT MP":"cpe:2.3:a:siemens:siport_mp:*:*:*:*:*:*:*:*",
"Siemens=SIPORT MP":"cpe:2.3:a:siemens:siport_mp:*:*:*:*:*:*:*:*",
"Siemens AG=Siveillance Video Client":"cpe:2.3:a:siemens:siveillance_video_client:*:*:*:*:*:*:*:*",
"Siemens=Siveillance Video Mobile Server V2022 R2":"cpe:2.3:a:siemens:siveillance_video_mobile_server:*:*:*:*:*:*:*:*",
"Siemens=Siemens Software Center":"cpe:2.3:a:siemens:software_center:*:*:*:*:*:*:*:*",
"Siemens AG=Spectrum Power    4":"cpe:2.3:a:siemens:spectrum_power_4:*:*:*:*:*:*:*:*",
"Siemens=Spectrum Power 7":"cpe:2.3:a:siemens:spectrum_power_7:*:*:*:*:*:*:*:*",
"Siemens=SPPA-T3000 MS3000 Migration Server":"cpe:2.3:a:siemens:sppa-t3000_ms3000_migration_server:*:*:*:*:*:*:*:*",
"Siemens=Simcenter STAR-CCM+":"cpe:2.3:a:siemens:star-ccm\+:*:*:*:*:*:*:*:*",
"Siemens=syngo Dynamics":"cpe:2.3:a:siemens:syngo_dynamics_cardiovascular_imaging_and_information_system:*:*:*:*:*:*:*:*",
"Siemens AG=SIEMENS TD Keypad Designer":"cpe:2.3:a:siemens:td_keypad_designer:*:*:*:*:*:*:*:*",
"Siemens AG=Siemens PLM Software TEAMCENTER":"cpe:2.3:a:siemens:teamcenter_product_lifecycle_management:*:*:*:*:*:*:*:*",
"Siemens=Tecnomatix Plant Simulation":"cpe:2.3:a:siemens:tecnomatix_plant_simulation:*:*:*:*:*:*:*:*",
"Siemens=Tecnomatix RobotExpert":"cpe:2.3:a:siemens:tecnomatix_robotexpert:*:*:*:*:*:*:*:*",
"Siemens AG=TeleControl Server Basic":"cpe:2.3:a:siemens:telecontrol_server_basic:*:*:*:*:*:*:*:*",
"Siemens=SIMATIC WinCC":"cpe:2.3:a:siemens:wincc:*:*:*:*:*:*:*:*",
"Siemens AG=XHQ":"cpe:2.3:a:siemens:xhq:*:*:*:*:*:*:*:*",
"Siemens=XHQ":"cpe:2.3:a:siemens:xhq:*:*:*:*:*:*:*:*",
"Siemens=Xpedition Layout Browser":"cpe:2.3:a:siemens:xpedition_layout_browser:*:*:*:*:*:*:*:*",
"freeswitch=sofia-sip":"cpe:2.3:a:signalwire:sofia-sip:*:*:*:*:*:*:*:*",
"Signiant=Signiant":"cpe:2.3:a:signiant:manager\+agents:*:*:*:*:*:*:*:*",
"SIGNKOREA=SKCertService":"cpe:2.3:a:signkorea:skcertservice:*:*:*:*:*:*:*:*",
"sigstore=cosign":"cpe:2.3:a:sigstore:cosign:*:*:*:*:*:*:*:*",
"sigstore=policy-controller":"cpe:2.3:a:sigstore:policy_controller:*:*:*:*:*:*:*:*",
"silabs.com=Bluetooth SDK":"cpe:2.3:a:silabs:bluetooth_low_energy_software_development_kit:*:*:*:*:*:*:*:*",
"Silicon Labs=Ember ZNet":"cpe:2.3:a:silabs:emberznet:*:*:*:*:*:*:*:*",
"silabs.com=Ember ZNet":"cpe:2.3:a:silabs:emberznet:*:*:*:*:*:*:*:*",
"silabs.com=Ember ZNet SDK":"cpe:2.3:a:silabs:emberznet_sdk:*:*:*:*:*:*:*:*",
"silabs.com=GSDK":"cpe:2.3:a:silabs:gecko_bootloader:*:*:*:*:*:*:*:*",
"silabs.com=Gecko Bootloader":"cpe:2.3:a:silabs:gecko_bootloader:*:*:*:*:*:*:*:*",
"silabs.com=GSDK":"cpe:2.3:a:silabs:gecko_software_development_kit:*:*:*:*:*:*:*:*",
"silabs.com=Gecko Bootloader":"cpe:2.3:a:silabs:gecko_software_development_kit:*:*:*:*:*:*:*:*",
"silabs.com=Gecko Platform":"cpe:2.3:a:silabs:gecko_software_development_kit:*:*:*:*:*:*:*:*",
"silabs.com=Gecko SDK":"cpe:2.3:a:silabs:gecko_software_development_kit:*:*:*:*:*:*:*:*",
"silabs.com=Gecko Platform":"cpe:2.3:a:silabs:micrium_uc-http:*:*:*:*:*:*:*:*",
"silabs.com=OpenThread SDK":"cpe:2.3:a:silabs:openthread_sdk:*:*:*:*:*:*:*:*",
"Silicon Labs=Unify Gateway":"cpe:2.3:a:silabs:unify_software_development_kit:*:*:*:*:*:*:*:*",
"silabs.com=Wi-SUN SDK":"cpe:2.3:a:silabs:wi-sun_software_development_kit:*:*:*:*:*:*:*:*",
"Silicon Labs=Z/IP Gateway":"cpe:2.3:a:silabs:z\/ip_gateway_sdk:*:*:*:*:*:*:*:*",
"silabs.com=Z/IP Gateway SDK":"cpe:2.3:a:silabs:z\/ip_gateway_sdk:*:*:*:*:*:*:*:*",
"Silver Peak Systems, Inc.=Unity Orchestrator":"cpe:2.3:a:silver-peak:unity_orchestrator:*:*:*:*:*:*:*:*",
"silverstripe=silverstripe-framework":"cpe:2.3:a:silverstripe:framework:*:*:*:*:*:*:*:*",
"silverstripe=silverstripe-graphql":"cpe:2.3:a:silverstripe:graphql:*:*:*:*:*:*:*:*",
"silverstripe=silverstripe-omnipay":"cpe:2.3:a:silverstripe:silverstripe-omnipay:*:*:*:*:*:*:*:*",
"mesosoi=silverwaregames-io-issue-tracker":"cpe:2.3:a:silverwaregames:silverwaregames:*:*:*:*:*:*:*:*",
"simonpedge=Slide Anything     Responsive Content / HTML Slider and Carousel":"cpe:2.3:a:simonpedge:slide_anything-responsive_content\/html_slider_and_carousel:*:*:*:*:*:*:*:*",
"SourceCodester=Simple and Beautiful Shopping Cart System":"cpe:2.3:a:simple_and_beautiful_shopping_cart_system_project:simple_and_beautiful_shopping_cart_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple and Nice Shopping Cart Script":"cpe:2.3:a:simple_and_nice_shopping_cart_script_project:simple_and_nice_shopping_cart_script:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Bakery Shop Management System":"cpe:2.3:a:simple_bakery_shop_management_system_project:simple_bakery_shop_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Book Catalog App":"cpe:2.3:a:simple_book_catalog_app_project:simple_book_catalog_app:*:*:*:*:*:*:*:*",
"Sourcecodester=Simple Cashiering System":"cpe:2.3:a:simple_cashiering_system_project:simple_cashiering_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Chat System":"cpe:2.3:a:simple_chat_system_project:simple_chat_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Cold Storage Management System":"cpe:2.3:a:simple_cold_storage_management_system_project:simple_cold_storage_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Customer Relationship Management System":"cpe:2.3:a:simple_customer_relationship_management_system_project:simple_customer_relationship_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple E-Learning System":"cpe:2.3:a:simple_e-learning_system_project:simple_e-learning_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple e-Learning System":"cpe:2.3:a:simple_e-learning_system_project:simple_e-learning_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Food Ordering System":"cpe:2.3:a:simple_food_ordering_system_project:simple_food_ordering_system:*:*:*:*:*:*:*:*",
"CodeAstro=Simple House Rental System":"cpe:2.3:a:simple_house_rental_system_project:simple_house_rental_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Mobile Comparison Website":"cpe:2.3:a:simple_mobile_comparison_website_project:simple_mobile_comparison_website:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Music Player":"cpe:2.3:a:simple_music_player_project:simple_music_player:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Online Book Store":"cpe:2.3:a:simple_online_book_store_system_project:simple_online_book_store_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Online Book Store System":"cpe:2.3:a:simple_online_book_store_system_project:simple_online_book_store_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Online Mens Salon Management System":"cpe:2.3:a:simple_online_mens_salon_management_system_project:simple_online_mens_salon_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Online Public Access Catalog":"cpe:2.3:a:simple_online_public_access_catalog_project:simple_online_public_access_catalog:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Parking Management System":"cpe:2.3:a:simple_parking_management_system_project:simple_parking_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Payroll System":"cpe:2.3:a:simple_payroll_system_with_dynamic_tax_bracket_project:simple_payroll_system_with_dynamic_tax_bracket:*:*:*:*:*:*:*:*",
"code-projects=Simple Photo Gallery":"cpe:2.3:a:simple_photo_gallery_project:simple_photo_gallery:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Real Estate Portal System":"cpe:2.3:a:simple_real_estate_portal_system_project:simple_real_estate_portal_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Responsive Tourism Website":"cpe:2.3:a:simple_responsive_tourism_website_project:simple_responsive_tourism_website:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Sales Management System":"cpe:2.3:a:simple_sales_management_system_project:simple_sales_management_system:*:*:*:*:*:*:*:*",
"Campcodes=Simple Student Information System":"cpe:2.3:a:simple_student_information_system_project:simple_student_information_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Student Information System":"cpe:2.3:a:simple_student_information_system_project:simple_student_information_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Task Allocation System":"cpe:2.3:a:simple_task_allocation_system_project:simple_task_allocation_system:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Task Managing System":"cpe:2.3:a:simple_task_managing_system_project:simple_task_managing_system:*:*:*:*:*:*:*:*",
"simpleledger=Electron-Cash-SLP":"cpe:2.3:a:simpleledger:electron-cash-slp:*:*:*:*:*:*:*:*",
"simpleledger=slp-validate":"cpe:2.3:a:simpleledger:slp-validate:*:*:*:*:*:*:*:*",
"simpleledger=slp-validate.js":"cpe:2.3:a:simpleledger:slp-validate:*:*:*:*:*:*:*:*",
"SimplePHPscripts=Classified Ads Script":"cpe:2.3:a:simplephpscripts:classified_ads_script_php:*:*:*:*:*:*:*:*",
"SimplePHPscripts=Event Script":"cpe:2.3:a:simplephpscripts:event_script:*:*:*:*:*:*:*:*",
"SimplePHPscripts=FAQ Script PHP":"cpe:2.3:a:simplephpscripts:faq_script_php:*:*:*:*:*:*:*:*",
"SimplePHPscripts=Funeral Script PHP":"cpe:2.3:a:simplephpscripts:funeral_script_php:*:*:*:*:*:*:*:*",
"SimplePHPscripts=GuestBook Script":"cpe:2.3:a:simplephpscripts:guestbook_script:*:*:*:*:*:*:*:*",
"SimplePHPscripts=News Script PHP Pro":"cpe:2.3:a:simplephpscripts:news_script_php_pro:*:*:*:*:*:*:*:*",
"SimplePHPscripts=NewsLetter Script PHP":"cpe:2.3:a:simplephpscripts:newsletter_script_php:*:*:*:*:*:*:*:*",
"SimplePHPscripts=Photo Gallery PHP":"cpe:2.3:a:simplephpscripts:photo_gallery_php:*:*:*:*:*:*:*:*",
"SimplePHPscripts=Simple Blog":"cpe:2.3:a:simplephpscripts:simple_blog:*:*:*:*:*:*:*:*",
"SimplePHPscripts=Simple Forum PHP":"cpe:2.3:a:simplephpscripts:simple_forum_php:*:*:*:*:*:*:*:*",
"unspecified=SimpleRisk":"cpe:2.3:a:simplerisk:simplerisk:*:*:*:*:*:*:*:*",
"simplesamlphp=SimpleSAMLphp":"cpe:2.3:a:simplesamlphp:simplesamlphp:*:*:*:*:*:*:*:*",
"simplesamlphp=simplesamlphp":"cpe:2.3:a:simplesamlphp:simplesamlphp:*:*:*:*:*:*:*:*",
"unspecified=Simplessus":"cpe:2.3:a:simplessus:simplessus:*:*:*:*:*:*:*:*",
"gnuboard=youngcart5":"cpe:2.3:a:sir:youngcart5:*:*:*:*:*:*:*:*",
"Teedy=Teedy":"cpe:2.3:a:sismics:teedy:*:*:*:*:*:*:*:*",
"sismics=docs":"cpe:2.3:a:sismics:teedy:*:*:*:*:*:*:*:*",
"SiteBridge Inc.=Joruri Gw":"cpe:2.3:a:sitebridge:joruri_gw:*:*:*:*:*:*:*:*",
"unspecified=siwapp-ror":"cpe:2.3:a:siwapp:siwapp-ror:*:*:*:*:*:*:*:*",
"six2dez=reconftw":"cpe:2.3:a:six2dez:reconftw:*:*:*:*:*:*:*:*",
"Six Apart, Ltd.=Movable Type":"cpe:2.3:a:sixapart:movable_type:*:*:*:*:*:*:*:*",
"Prestaul=skeemas":"cpe:2.3:a:skeemas_project:skeemas:*:*:*:*:*:*:*:*",
"  koda Auto=  koda Connect":"cpe:2.3:a:skoda-auto:skoda_connect:*:*:*:*:*:*:*:*",
"Skoda Auto=Skoda Connect":"cpe:2.3:a:skoda-auto:skoda_connect:*:*:*:*:*:*:*:*",
"Sky Co., LTD.=SKYSEA Client View":"cpe:2.3:a:skygroup:skysea_client_view:*:*:*:*:*:*:*:*",
"Skyhigh Security=Skyhigh Secure Web Gateway (SWG)":"cpe:2.3:a:skyhighsecurity:secure_web_gateway:*:*:*:*:*:*:*:*",
"Skyhigh Security=Skyhigh Secure Web Gateway (SWG) ":"cpe:2.3:a:skyhighsecurity:secure_web_gateway:*:*:*:*:*:*:*:*",
"skytable=skytable":"cpe:2.3:a:skytable:skytable:*:*:*:*:*:*:*:*",
"National Sleep Research Resource=sleepdata.org":"cpe:2.3:a:sleepdata:sleepdata:*:*:*:*:*:*:*:*",
"Slic3r=libslic3r":"cpe:2.3:a:slic3r:libslic3r:*:*:*:*:*:*:*:*",
"slimphp=Slim-Psr7":"cpe:2.3:a:slimframework:slim_psr-7:*:*:*:*:*:*:*:*",
"SLiMS=SLiMS":"cpe:2.3:a:slims:senayan_library_management_system:*:*:*:*:*:*:*:*",
"Unknown=Sloth Logo Customizer":"cpe:2.3:a:sloth_logo_customizer_project:sloth_logo_customizer:*:*:*:*:*:*:*:*",
"Ruby Programming Shounendan=smalruby-editor":"cpe:2.3:a:smalruby:smalruby-editor:*:*:*:*:*:*:*:*",
"swagger-api=swagger-codegen":"cpe:2.3:a:smartbear:swagger-codegen:*:*:*:*:*:*:*:*",
"SmarterTools=SmarterTrack":"cpe:2.3:a:smartertools:smartertrack:*:*:*:*:*:*:*:*",
"Elcomplus=SmartICS":"cpe:2.3:a:smartics:smartics:*:*:*:*:*:*:*:*",
"Elcomplus=SmartPTT SCADA Server":"cpe:2.3:a:smartptt:scada_server:*:*:*:*:*:*:*:*",
"Elcomplus=SmartPTT":"cpe:2.3:a:smartptt:smartptt_scada:*:*:*:*:*:*:*:*",
"SamrtSoft=SmartBPM.NET ":"cpe:2.3:a:smartsoft:smartbpm.net:*:*:*:*:*:*:*:*",
"SmartSoft=SmartBPM.NET ":"cpe:2.3:a:smartsoft:smartbpm.net:*:*:*:*:*:*:*:*",
"smartstore=SmartStoreNET":"cpe:2.3:a:smartstore:smartstore:*:*:*:*:*:*:*:*",
"SmartWeb Infotech=Job Board":"cpe:2.3:a:smartweb_infotech_job_board_project:smartweb_infotech_job_board:*:*:*:*:*:*:*:*",
"Jan Syski=SmodBIP":"cpe:2.3:a:smod:smodbip:*:*:*:*:*:*:*:*",
"SUN & MOON RISE CO., LTD.=Shockwall":"cpe:2.3:a:smr:shenwang_endpoint_protection_security_system:*:*:*:*:*:*:*:*",
"SnakeYAML=SnakeYAML":"cpe:2.3:a:snakeyaml_project:snakeyaml:*:*:*:*:*:*:*:*",
"snakeyaml=SnakeYAML":"cpe:2.3:a:snakeyaml_project:snakeyaml:*:*:*:*:*:*:*:*",
"brantburnett=Snappier":"cpe:2.3:a:snappier_project:snappier:*:*:*:*:*:*:*:*",
"snipe=snipe/snipe-it":"cpe:2.3:a:snipeitapp:snipe-it:*:*:*:*:*:*:*:*",
"SNIProxy=SNIProxy":"cpe:2.3:a:sniproxy_project:sniproxy:*:*:*:*:*:*:*:*",
"snowflake-connector-python=snowflake-connector-python":"cpe:2.3:a:snowflake:snowflake-connector-python:*:*:*:*:*:*:*:*",
"snowflakedb=snowflake-jdbc":"cpe:2.3:a:snowflake:snowflake_jdbc:*:*:*:*:*:*:*:*",
"Snow Software=Snow Inventory Agent":"cpe:2.3:a:snowsoftware:snow_inventory_agent:*:*:*:*:*:*:*:*",
"SNOW=Snow Inventory Java Scanner":"cpe:2.3:a:snowsoftware:snow_inventory_java_scanner:*:*:*:*:*:*:*:*",
"Snyk=Snyk Advisor":"cpe:2.3:a:snyk:advisor:*:*:*:*:*:*:*:*",
"Snyk=Snyk Kubernetes Monitor":"cpe:2.3:a:snyk:kubernetes_monitor:*:*:*:*:*:*:*:*",
"socketry=protocol-http1":"cpe:2.3:a:socketry:protocol-http1:*:*:*:*:*:*:*:*",
"sofastack=sofa-rpc":"cpe:2.3:a:sofastack:sofarpc:*:*:*:*:*:*:*:*",
"SoftBank Corp.=PrimeDrive Desktop Application":"cpe:2.3:a:softbank:primedrive_desktop_application:*:*:*:*:*:*:*:*",
"SoftBank Corporation=Installer for PrimeDrive Desktop Application":"cpe:2.3:a:softbank:primedrive_desktop_application:*:*:*:*:*:*:*:*",
"SoftEther VPN=SoftEther VPN":"cpe:2.3:a:softether:vpn:*:*:*:*:*:*:*:*",
"Softing=edgeAggregator":"cpe:2.3:a:softing:edgeaggregator:*:*:*:*:*:*:*:*",
"Softing Industrial Automation all versions prior to the latest build of version 4.47.0=OPC":"cpe:2.3:a:softing:opc:*:*:*:*:*:*:*:*",
"Softing=OPC UA C++ SDK (Software Development Kit)":"cpe:2.3:a:softing:opc_ua_c\+\+_software_development_kit:*:*:*:*:*:*:*:*",
"SoftIron=HyperCloud":"cpe:2.3:a:softiron:hypercloud:*:*:*:*:*:*:*:*",
"Softmed=SelfPatron ":"cpe:2.3:a:softmedyazilim:selfpatron:*:*:*:*:*:*:*:*",
"Softmotions=iowow":"cpe:2.3:a:softmotions:iowow:*:*:*:*:*:*:*:*",
"SOFTNEXT TECHNOLOGIES CORP.=Mail SQR Expert":"cpe:2.3:a:softnext:mail_sqr_expert:*:*:*:*:*:*:*:*",
"Softnext=Mail SQR Expert ":"cpe:2.3:a:softnext:mail_sqr_expert:*:*:*:*:*:*:*:*",
"Softnext=SPAM SQR":"cpe:2.3:a:softnext:spam_sqr:*:*:*:*:*:*:*:*",
"  stanbul Soft Informatics and Consultancy Limited Company=Softomi Advanced C2C Marketplace Software":"cpe:2.3:a:softomi:advanced_c2c_marketplace_software:*:*:*:*:*:*:*:*",
"  stanbul Soft Informatics and Consultancy Limited Company=Softomi Geli  mi   C2C Pazaryeri Yaz  l  m  ":"cpe:2.3:a:softomi:advanced_c2c_marketplace_software:*:*:*:*:*:*:*:*",
"Software AG=WebMethods":"cpe:2.3:a:softwareag:webmethods:*:*:*:*:*:*:*:*",
"solana-labs=rbpf":"cpe:2.3:a:solana:rbpf:*:*:*:*:*:*:*:*",
"solana-labs=solana-pay":"cpe:2.3:a:solanalabs:pay:*:*:*:*:*:*:*:*",
"SolarWinds=Access Rights Manager":"cpe:2.3:a:solarwinds:access_rights_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Access Rights Manager ":"cpe:2.3:a:solarwinds:access_rights_manager:*:*:*:*:*:*:*:*",
"SolarWinds=DPA":"cpe:2.3:a:solarwinds:database_performance_analyzer:*:*:*:*:*:*:*:*",
"SolarWinds=Database Performance Analyzer (DPA)":"cpe:2.3:a:solarwinds:database_performance_analyzer:*:*:*:*:*:*:*:*",
"SolarWinds=SolarWinds":"cpe:2.3:a:solarwinds:database_performance_analyzer:*:*:*:*:*:*:*:*",
"SolarWinds=Kiwi CatTools":"cpe:2.3:a:solarwinds:kiwi_cattools:*:*:*:*:*:*:*:*",
"SolarWinds=Kiwi Syslog Server":"cpe:2.3:a:solarwinds:kiwi_syslog_server:*:*:*:*:*:*:*:*",
"SolarWinds =Network Configuration Manager":"cpe:2.3:a:solarwinds:network_configuration_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Network Configuration Manager":"cpe:2.3:a:solarwinds:network_configuration_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Network Configuration Manager":"cpe:2.3:a:solarwinds:network_configuration_monitor:*:*:*:*:*:*:*:*",
"SolarWinds=Network Performance Monitor":"cpe:2.3:a:solarwinds:network_performance_monitor:*:*:*:*:*:*:*:*",
"SolarWinds Platform Command Injection Vulnerability=SolarWinds Platform":"cpe:2.3:a:solarwinds:orion_platform:*:*:*:*:*:*:*:*",
"SolarWinds=Orion Platform":"cpe:2.3:a:solarwinds:orion_platform:*:*:*:*:*:*:*:*",
"SolarWinds=Orion Platform ":"cpe:2.3:a:solarwinds:orion_platform:*:*:*:*:*:*:*:*",
"SolarWinds=Orion Virtual Infrastructure Monitor":"cpe:2.3:a:solarwinds:orion_platform:*:*:*:*:*:*:*:*",
"SolarWinds=Patch Manager ":"cpe:2.3:a:solarwinds:orion_platform:*:*:*:*:*:*:*:*",
"SolarWinds=SolarWinds Platform":"cpe:2.3:a:solarwinds:orion_platform:*:*:*:*:*:*:*:*",
"SolarWinds =Patch Manager ":"cpe:2.3:a:solarwinds:patch_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Orion Platform ":"cpe:2.3:a:solarwinds:patch_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Patch Manager":"cpe:2.3:a:solarwinds:patch_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Pingdom":"cpe:2.3:a:solarwinds:pingdom:*:*:*:*:*:*:*:*",
"SolarWinds =SolarWinds SEM ":"cpe:2.3:a:solarwinds:security_event_manager:*:*:*:*:*:*:*:*",
"SolarWinds=Serv-U":"cpe:2.3:a:solarwinds:serv-u:*:*:*:*:*:*:*:*",
"SolarWinds=Serv-U FTP Server":"cpe:2.3:a:solarwinds:serv-u:*:*:*:*:*:*:*:*",
"SolarWinds=Serv-U File Server":"cpe:2.3:a:solarwinds:serv-u:*:*:*:*:*:*:*:*",
"SolarWinds=Serv-U Server":"cpe:2.3:a:solarwinds:serv-u:*:*:*:*:*:*:*:*",
"SolarWinds=ServU":"cpe:2.3:a:solarwinds:serv-u:*:*:*:*:*:*:*:*",
"SolarWinds=Server & Application Monitor (SAM)":"cpe:2.3:a:solarwinds:server_and_application_monitor:*:*:*:*:*:*:*:*",
"SolarWinds =SolarWinds Platform":"cpe:2.3:a:solarwinds:solarwinds_platform:*:*:*:*:*:*:*:*",
"SolarWinds =SolarWinds Platform ":"cpe:2.3:a:solarwinds:solarwinds_platform:*:*:*:*:*:*:*:*",
"SolarWinds=Orion Platform ":"cpe:2.3:a:solarwinds:solarwinds_platform:*:*:*:*:*:*:*:*",
"SolarWinds=SolarWinds Platform":"cpe:2.3:a:solarwinds:solarwinds_platform:*:*:*:*:*:*:*:*",
"SolarWinds=SolarWinds Platform ":"cpe:2.3:a:solarwinds:solarwinds_platform:*:*:*:*:*:*:*:*",
"SolarWinds=SQL Sentry":"cpe:2.3:a:solarwinds:sql_sentry:*:*:*:*:*:*:*:*",
"SolarWinds =Web Help Desk ":"cpe:2.3:a:solarwinds:web_help_desk:*:*:*:*:*:*:*:*",
"SolarWinds=Web Help Desk":"cpe:2.3:a:solarwinds:web_help_desk:*:*:*:*:*:*:*:*",
"SolarWinds=Web Help Desk":"cpe:2.3:a:solarwinds:webhelpdesk:*:*:*:*:*:*:*:*",
"Solideo Systems Co,Ltd=Architectural Information System":"cpe:2.3:a:solideos:architectural_information_system:*:*:*:*:*:*:*:*",
"Soliton Systems K.K.=FileZen":"cpe:2.3:a:soliton:filezen:*:*:*:*:*:*:*:*",
"SonicWall=Directory Services Connector":"cpe:2.3:a:sonicwall:directory_services_connector:*:*:*:*:*:*:*:*",
"SonicWall=SonicWall Email Security":"cpe:2.3:a:sonicwall:email_security:*:*:*:*:*:*:*:*",
"SonicWall=Email Security Appliance":"cpe:2.3:a:sonicwall:email_security_appliance:*:*:*:*:*:*:*:*",
"SonicWall=Email Security Virtual Appliance":"cpe:2.3:a:sonicwall:email_security_virtual_appliance:*:*:*:*:*:*:*:*",
"SonicWall=GMS":"cpe:2.3:a:sonicwall:global_management_system:*:*:*:*:*:*:*:*",
"SonicWall=Global Management System (GMS)":"cpe:2.3:a:sonicwall:global_management_system:*:*:*:*:*:*:*:*",
"SonicWall=SonicWall GMS":"cpe:2.3:a:sonicwall:global_management_system:*:*:*:*:*:*:*:*",
"SonicWall=SonicWall Global VPN Client":"cpe:2.3:a:sonicwall:global_vpn_client:*:*:*:*:*:*:*:*",
"Sony Corporation=The installer of Digital Paper App":"cpe:2.3:a:sony:digital_paper_app:*:*:*:*:*:*:*:*",
"Sony Video & Sound Products Inc.=Media Go":"cpe:2.3:a:sony:media_go:*:*:*:*:*:*:*:*",
"Sony Video & Sound Products Inc.=Music Center for PC":"cpe:2.3:a:sony:music_center:*:*:*:*:*:*:*:*",
"Sony Video & Sound Products Inc.=Music Center for PC":"cpe:2.3:a:sony:music_center_for_pc:*:*:*:*:*:*:*:*",
"Sony Corporation=NFC Port Software remover":"cpe:2.3:a:sony:nfc_port_software_remover:*:*:*:*:*:*:*:*",
"Sony Corporation=VAIO Update":"cpe:2.3:a:sony:vaio_update:*:*:*:*:*:*:*:*",
"Sophos=Sophos Connect Client":"cpe:2.3:a:sophos:connect:*:*:*:*:*:*:*:*",
"Sophos=Sophos Email Appliance":"cpe:2.3:a:sophos:email_appliance:*:*:*:*:*:*:*:*",
"Sophos=Sophos Firewall":"cpe:2.3:a:sophos:firewall:*:*:*:*:*:*:*:*",
"Sophos=HitmanPro.Alert":"cpe:2.3:a:sophos:hitmanpro.alert:*:*:*:*:*:*:*:*",
"Talos=Sophos":"cpe:2.3:a:sophos:hitmanpro.alert:*:*:*:*:*:*:*:*",
"Sophos=HitmanPro":"cpe:2.3:a:sophos:hitmanpro:*:*:*:*:*:*:*:*",
"Sophos=Sophos Mobile managed on-premises":"cpe:2.3:a:sophos:mobile:*:*:*:*:*:*:*:*",
"Sophos=Sophos UTM":"cpe:2.3:a:sophos:unified_threat_management:*:*:*:*:*:*:*:*",
"Sophos=SG UTM":"cpe:2.3:a:sophos:unified_threat_management_up2date:*:*:*:*:*:*:*:*",
"Sophos=Sophos Web Appliance":"cpe:2.3:a:sophos:web_appliance:*:*:*:*:*:*:*:*",
"sos-berlin=joc-cockpit":"cpe:2.3:a:sos-berlin:jobscheduler:*:*:*:*:*:*:*:*",
"Sourceforge=SoX - Sound eXchange":"cpe:2.3:a:sound_exchange_project:sound_exchange:*:*:*:*:*:*:*:*",
"Soundminer=Soundminer":"cpe:2.3:a:soundminer:soundminer:*:*:*:*:*:*:*:*",
"ridhoq=soundslike":"cpe:2.3:a:soundslike_project:soundslike:*:*:*:*:*:*:*:*",
"SourceCodester=House Rental and Property Listing":"cpe:2.3:a:sourcecodester_house_rental_and_property_listing_project:house_rental_and_property_listing:*:*:*:*:*:*:*:*",
"sourcegraph=cody":"cpe:2.3:a:sourcegraph:cody:*:*:*:*:*:*:*:*",
"sourcegraph=sourcegraph":"cpe:2.3:a:sourcegraph:sourcegraph:*:*:*:*:*:*:*:*",
"SOURCENEXT CORPORATION=Self-extracting archive files created by File Compact":"cpe:2.3:a:sourcenext:file_compact:*:*:*:*:*:*:*:*",
"soxft=TimeMail":"cpe:2.3:a:soxft:timemail:*:*:*:*:*:*:*:*",
"inunosinsi=soycms":"cpe:2.3:a:soycms_project:soycms:*:*:*:*:*:*:*:*",
"SPA-Cart=eCommerce CMS":"cpe:2.3:a:spa-cart:ecommerce_cms:*:*:*:*:*:*:*:*",
"spree=spree":"cpe:2.3:a:sparksolutions:spree:*:*:*:*:*:*:*:*",
"Sparksuite=SimpleMDE":"cpe:2.3:a:sparksuite:simplemde:*:*:*:*:*:*:*:*",
"spassarop=antisamy-dotnet":"cpe:2.3:a:spassarop:owasp_antisamy_.net:*:*:*:*:*:*:*:*",
"SpeciesFileGroup=taxonworks":"cpe:2.3:a:speciesfilegroup:taxonworks:*:*:*:*:*:*:*:*",
"specklesystems=speckle-server":"cpe:2.3:a:specklesystems:speckle_server:*:*:*:*:*:*:*:*",
"freedesktop.org=spice-gtk":"cpe:2.3:a:spice-gtk_project:spice-gtk:*:*:*:*:*:*:*:*",
"Red Hat=spice-client":"cpe:2.3:a:spice_project:spice:*:*:*:*:*:*:*:*",
"The SPICE Project=spice":"cpe:2.3:a:spice_project:spice:*:*:*:*:*:*:*:*",
"iniNet=SCADAWebServer":"cpe:2.3:a:spidercontrol:scadawebserver:*:*:*:*:*:*:*:*",
"SpiQe Software=OneThird CMS":"cpe:2.3:a:spiqe:onethird_cms_show_off:*:*:*:*:*:*:*:*",
"nitely=nitely/spirit":"cpe:2.3:a:spirit-project:spirit:*:*:*:*:*:*:*:*",
"Splunk=Splunk Add-on Builder":"cpe:2.3:a:splunk:add-on_builder:*:*:*:*:*:*:*:*",
"Splunk=Splunk Enterprise Security (ES)":"cpe:2.3:a:splunk:enterprise_security:*:*:*:*:*:*:*:*",
"Splunk=Splunk ITSI":"cpe:2.3:a:splunk:it_service_intelligence:*:*:*:*:*:*:*:*",
"Splunk=Splunk App for Lookup File Editing":"cpe:2.3:a:splunk:splunk_app_for_lookup_file_editing:*:*:*:*:*:*:*:*",
"Splunk=Splunk App for Stream":"cpe:2.3:a:splunk:splunk_app_for_stream:*:*:*:*:*:*:*:*",
"unspecified=Sports Club Management System":"cpe:2.3:a:sports_club_management_system_project:sports_club_management_system:*:*:*:*:*:*:*:*",
"Spotify=Spotify Music Player":"cpe:2.3:a:spotify:spotify:*:*:*:*:*:*:*:*",
"spotipy-dev=spotipy":"cpe:2.3:a:spotipy_project:spotipy:*:*:*:*:*:*:*:*",
"spree=spree":"cpe:2.3:a:spreecommerce:spree:*:*:*:*:*:*:*:*",
"lukashinsch=spring-boot-actuator-logview":"cpe:2.3:a:spring-boot-actuator-logview_project:spring-boot-actuator-logview:*:*:*:*:*:*:*:*",
"sqlfluff=sqlfluff":"cpe:2.3:a:sqlfluff:sqlfluff:*:*:*:*:*:*:*:*",
"xerial=sqlite-jdbc":"cpe:2.3:a:sqlite_jdbc_project:sqlite_jdbc:*:*:*:*:*:*:*:*",
"sqlpad=sqlpad/sqlpad":"cpe:2.3:a:sqlpad:sqlpad:*:*:*:*:*:*:*:*",
"andialbrecht=sqlparse":"cpe:2.3:a:sqlparse_project:sqlparse:*:*:*:*:*:*:*:*",
"The Squid Software Foundation=The Squid Software Foundation Squid":"cpe:2.3:a:squid-cache:squid:*:*:*:*:*:*:*:*",
"squid-cache=squid":"cpe:2.3:a:squid-cache:squid:*:*:*:*:*:*:*:*",
"Squidex=squidex":"cpe:2.3:a:squidex.io:squidex:*:*:*:*:*:*:*:*",
"squidex=squidex/squidex":"cpe:2.3:a:squidex.io:squidex:*:*:*:*:*:*:*:*",
"Squirrel=Installers generated by Squirrel.Windows":"cpe:2.3:a:squirrel.windows_project:squirrel.windows:*:*:*:*:*:*:*:*",
"squirrellyjs=squirrelly":"cpe:2.3:a:squirrelly:squirrelly:*:*:*:*:*:*:*:*",
"momofoolish=sra-admin":"cpe:2.3:a:sra-admin_project:sra-admin:*:*:*:*:*:*:*:*",
"Sricam=IP CCTV Camera":"cpe:2.3:a:sricam:deviceviewer:*:*:*:*:*:*:*:*",
"SHIRASAGI Project =SHIRASAGI ":"cpe:2.3:a:ss-proj:shirasagi:*:*:*:*:*:*:*:*",
"SHIRASAGI Project=SHIRASAGI":"cpe:2.3:a:ss-proj:shirasagi:*:*:*:*:*:*:*:*",
"shirasagi=shirasagi":"cpe:2.3:a:ss-proj:shirasagi:*:*:*:*:*:*:*:*",
"SiteServer=CMS":"cpe:2.3:a:sscms:siteserver_cms:*:*:*:*:*:*:*:*",
"ZipArchive=SSZipArchive":"cpe:2.3:a:ssziparchive_project:ssziparchive:*:*:*:*:*:*:*:*",
"STMicroelectronics STM32Cube =STM32 USB Host Library":"cpe:2.3:a:st:stm32_mw_usb_host:*:*:*:*:*:*:*:*",
"StanfordVL=GibsonEnv":"cpe:2.3:a:standford:gibsonenv:*:*:*:*:*:*:*:*",
"stanfordnlp=stanfordnlp/corenlp":"cpe:2.3:a:stanford:corenlp:*:*:*:*:*:*:*:*",
"Starcounter-Jack=JSON-Patch":"cpe:2.3:a:starcounter-jack:json-patch:*:*:*:*:*:*:*:*",
"stargate-bukkit=Stargate-Bukkit":"cpe:2.3:a:stargate-bukkit_project:stargate-bukkit:*:*:*:*:*:*:*:*",
"starlite-api=starlite":"cpe:2.3:a:starliteproject:starlite:*:*:*:*:*:*:*:*",
"unspecified=starter-public-edition-4":"cpe:2.3:a:starter-public-edition-4_project:starter-public-edition-4:*:*:*:*:*:*:*:*",
"StarTrinity=Softswitch":"cpe:2.3:a:startrinity:softswitch:*:*:*:*:*:*:*:*",
"statamic=cms":"cpe:2.3:a:statamic:statamic:*:*:*:*:*:*:*:*",
"Status Internet Co.,Ltd.=PowerBPM":"cpe:2.3:a:status:powerbpm:*:*:*:*:*:*:*:*",
"Studio-42=elFinder":"cpe:2.3:a:std42:elfinder:*:*:*:*:*:*:*:*",
"stellar=freighter":"cpe:2.3:a:stellar:freighter:*:*:*:*:*:*:*:*",
"Step Tools=v18SP1 ifcmesh library":"cpe:2.3:a:steptools:ifcmesh_library:*:*:*:*:*:*:*:*",
"Sterc=Google Analytics Dashboard for MODX":"cpe:2.3:a:sterc:google_analytics_dashboard_for_modx:*:*:*:*:*:*:*:*",
"rickxy=Stock Management System":"cpe:2.3:a:stock_management_system_project:stock_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Storage Unit Rental Management System":"cpe:2.3:a:storage_unit_rental_management_system_project:storage_unit_rental_management_system:*:*:*:*:*:*:*:*",
"visegripped=Stracker":"cpe:2.3:a:stracker_project:stracker:*:*:*:*:*:*:*:*",
"strapi-community=strapi-plugin-protected-populate":"cpe:2.3:a:strapi:protected_populate:*:*:*:*:*:*:*:*",
"Strapi=Strapi":"cpe:2.3:a:strapi:strapi:*:*:*:*:*:*:*:*",
"strapi=strapi":"cpe:2.3:a:strapi:strapi:*:*:*:*:*:*:*:*",
"strapi=strapi/strapi":"cpe:2.3:a:strapi:strapi:*:*:*:*:*:*:*:*",
"streamlit=streamlit":"cpe:2.3:a:streamlit:streamlit:*:*:*:*:*:*:*:*",
"Tomoki Sanaki=StreamRelay.NET.exe":"cpe:2.3:a:streamrelay:streamrelay:*:*:*:*:*:*:*:*",
"stripe=smokescreen":"cpe:2.3:a:stripe:smokescreen:*:*:*:*:*:*:*:*",
"structurizr=structurizr/onpremises":"cpe:2.3:a:structurizr:on-premises_installation:*:*:*:*:*:*:*:*",
"strukturag=strukturag/libde265":"cpe:2.3:a:struktur:libde265:*:*:*:*:*:*:*:*",
"Struktur=libheif":"cpe:2.3:a:struktur:libheif:*:*:*:*:*:*:*:*",
"unspecified=Student Attendance Management System":"cpe:2.3:a:student_attendance_management_system_project:student_attendance_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Student Attendance System":"cpe:2.3:a:student_attendance_system_project:student_attendance_system:*:*:*:*:*:*:*:*",
"SourceCodester=Student Information System":"cpe:2.3:a:student_information_system_project:student_information_system:*:*:*:*:*:*:*:*",
"unspecified=Student Information System":"cpe:2.3:a:student_information_system_project:student_information_system:*:*:*:*:*:*:*:*",
"SourceCodester=Student Management System":"cpe:2.3:a:student_management_system_project:student_management_system:*:*:*:*:*:*:*:*",
"ningzichun=Student Management System":"cpe:2.3:a:student_management_system_project:student_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Student Study Center Desk Management System":"cpe:2.3:a:student_study_center_desk_management_system_project:student_study_center_desk_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Students Online Internship Timesheet Syste":"cpe:2.3:a:students_online_internship_timesheet_system_project:students_online_internship_timesheet_system:*:*:*:*:*:*:*:*",
"SourceCodester=Students Online Internship Timesheet System":"cpe:2.3:a:students_online_internship_timesheet_system_project:students_online_internship_timesheet_system:*:*:*:*:*:*:*:*",
"unspecified=studygolang":"cpe:2.3:a:studygolang:studygolang:*:*:*:*:*:*:*:*",
"sulu=sulu":"cpe:2.3:a:sulu:sulu:*:*:*:*:*:*:*:*",
"Summar Software=Mentor     Employee Portal":"cpe:2.3:a:summar:mentor:*:*:*:*:*:*:*:*",
"SUNNET=CTMS":"cpe:2.3:a:sun.net:ctms:*:*:*:*:*:*:*:*",
"SUNNET=WMPro":"cpe:2.3:a:sun.net:wmpro:*:*:*:*:*:*:*:*",
"Sunnet=eHRD":"cpe:2.3:a:sun:ehrd:*:*:*:*:*:*:*:*",
"Xintian=Smart Table Integrated Management System":"cpe:2.3:a:suntront:smart_table_integrated_management_system:*:*:*:*:*:*:*:*",
"Supcon=InPlant SCADA":"cpe:2.3:a:supcon:inplant_scada:*:*:*:*:*:*:*:*",
"4ra1n=super-xray":"cpe:2.3:a:super_xray_project:super_xray:*:*:*:*:*:*:*:*",
"SuperMailer=Newsletter Software":"cpe:2.3:a:supermailer:supermailer:*:*:*:*:*:*:*:*",
"Supersmart.me =Supersmart.me     Walk Through":"cpe:2.3:a:supersmart:supersmart.me_-_walk_through:*:*:*:*:*:*:*:*",
"Synel=eHarmony":"cpe:2.3:a:supersmart:supersmart.me_-_walk_through:*:*:*:*:*:*:*:*",
"code-projects=Supplier Management System":"cpe:2.3:a:supplier_management_system_project:supplier_management_system:*:*:*:*:*:*:*:*",
"Kashipara=Hospital Management System":"cpe:2.3:a:surajghosh:hospital_management_system:*:*:*:*:*:*:*:*",
"CodeAstro=Real Estate Management System":"cpe:2.3:a:surajkumarvishwakarma:real_estate_management_system:*:*:*:*:*:*:*:*",
"SourceCodester=Survey Application System":"cpe:2.3:a:survey_application_system_project:survey_application_system:*:*:*:*:*:*:*:*",
"SUSE=SUSE CaaS Platform 3.0":"cpe:2.3:a:suse:caas_platform:*:*:*:*:*:*:*:*",
"SUSE=SUSE CaaS Platform 4.5":"cpe:2.3:a:suse:caas_platform:*:*:*:*:*:*:*:*",
"SUSE=openQA":"cpe:2.3:a:suse:openqa:*:*:*:*:*:*:*:*",
"SUSE=Rancher":"cpe:2.3:a:suse:rancher:*:*:*:*:*:*:*:*",
"SUSE=Rancher":"cpe:2.3:a:suse:rancher_desktop:*:*:*:*:*:*:*:*",
"SUSE=yast2-rmt":"cpe:2.3:a:suse:repository_mirroring_tool:*:*:*:*:*:*:*:*",
"SUSE Linux=SMT":"cpe:2.3:a:suse:subscription_management_tool:*:*:*:*:*:*:*:*",
"SUSE=Rancher":"cpe:2.3:a:suse:wrangler:*:*:*:*:*:*:*:*",
"openSUSE=Factory":"cpe:2.3:a:suse:yast2-security:*:*:*:*:*:*:*:*",
"Sustainsys=Saml2":"cpe:2.3:a:sustainsys:saml2:*:*:*:*:*:*:*:*",
"darylldoyle=svg-sanitizer":"cpe:2.3:a:svg-sanitizer_project:svg-sanitizer:*:*:*:*:*:*:*:*",
"CodeAstro=Stock Management System":"cpe:2.3:a:swapnilsahu:stock_management_system:*:*:*:*:*:*:*:*",
"migueldeicaza=SwiftTerm":"cpe:2.3:a:swiftterm_project:swiftterm:*:*:*:*:*:*:*:*",
"sylabs=singularity":"cpe:2.3:a:sylabs:singularity:*:*:*:*:*:*:*:*",
"sylabs=scs-library-client":"cpe:2.3:a:sylabs:singularity_container_services_library:*:*:*:*:*:*:*:*",
"sylabs=sif":"cpe:2.3:a:sylabs:singularity_image_format:*:*:*:*:*:*:*:*",
"Sylius=Sylius":"cpe:2.3:a:sylius:sylius:*:*:*:*:*:*:*:*",
"Sylius=SyliusResourceBundle":"cpe:2.3:a:sylius:syliusresourcebundle:*:*:*:*:*:*:*:*",
"Syltek=Syltek":"cpe:2.3:a:syltek:syltek:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Encryption Desktop":"cpe:2.3:a:symantec:encryption_desktop:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Encryption Management Server (SEMS)":"cpe:2.3:a:symantec:encryption_management_server:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Endpoint Encryption":"cpe:2.3:a:symantec:endpoint_encryption:*:*:*:*:*:*:*:*",
"Symantec=Endpoint Encryption":"cpe:2.3:a:symantec:endpoint_encryption:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Endpoint Protection":"cpe:2.3:a:symantec:endpoint_protection:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Endpoint Protection (SEP)":"cpe:2.3:a:symantec:endpoint_protection:*:*:*:*:*:*:*:*",
"Symantec - A Division of Broadcom=Symantec Identity Governance And Administration":"cpe:2.3:a:symantec:identity_portal:*:*:*:*:*:*:*:*",
"Symantec Corporation=Industrial Control System Protection (ICSP)":"cpe:2.3:a:symantec:industrial_control_system_protection:*:*:*:*:*:*:*:*",
"Symantec Corporation=Management Center (MC)":"cpe:2.3:a:symantec:management_center:*:*:*:*:*:*:*:*",
"Symantec Corporation=Messaging Gateway":"cpe:2.3:a:symantec:message_gateway:*:*:*:*:*:*:*:*",
"Symantec Corporation=Messaging Gateway":"cpe:2.3:a:symantec:messaging_gateway:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Messaging Gateway":"cpe:2.3:a:symantec:messaging_gateway:*:*:*:*:*:*:*:*",
"Symantec=Symantec Messaging Gateway":"cpe:2.3:a:symantec:messaging_gateway:*:*:*:*:*:*:*:*",
"Symantec Corporation=Norton App Lock":"cpe:2.3:a:symantec:norton_app_lock:*:*:*:*:*:*:*:*",
"Symantec=Norton AppLock":"cpe:2.3:a:symantec:norton_app_lock:*:*:*:*:*:*:*:*",
"Symantec Corporation=Norton Utilities":"cpe:2.3:a:symantec:norton_utilities:*:*:*:*:*:*:*:*",
"Symantec=Symantec Protection Engine":"cpe:2.3:a:symantec:protection_engine:*:*:*:*:*:*:*:*",
"Symantec Corporation=Reporter":"cpe:2.3:a:symantec:reporter:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Reporter":"cpe:2.3:a:symantec:reporter:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Security Analytics (SA)":"cpe:2.3:a:symantec:security_analytics:*:*:*:*:*:*:*:*",
"Symantec=SONAR Component":"cpe:2.3:a:symantec:sonar:*:*:*:*:*:*:*:*",
"Symantec Corporation=My VIP Portal":"cpe:2.3:a:symantec:vip:*:*:*:*:*:*:*:*",
"Symantec Corporation=VIP Access for Desktop":"cpe:2.3:a:symantec:vip_access_for_desktop:*:*:*:*:*:*:*:*",
"Symantec=Symantec VIP Enterprise Gateway":"cpe:2.3:a:symantec:vip_enterprise_gateway:*:*:*:*:*:*:*:*",
"Symantec Corporation=Symantec Web Isolation":"cpe:2.3:a:symantec:web_isolation:*:*:*:*:*:*:*:*",
"symfony=ux-autocomplete":"cpe:2.3:a:symfony:ux_autocomplete:*:*:*:*:*:*:*:*",
"Synacor=Zimbra Collaboration Suite (ZCS)":"cpe:2.3:a:synacor:zimbra_collaboration_suite:*:*:*:*:*:*:*:*",
"Synaptics=Synaptics Fingerprint Driver":"cpe:2.3:a:synaptics:fingerprint_driver:*:*:*:*:*:*:*:*",
"SYNCK GRAPHICA=Mailform Pro CGI":"cpe:2.3:a:synck:mailform_pro_cgi:*:*:*:*:*:*:*:*",
"SYNCK GRAPHICA=Mailform Pro CGI":"cpe:2.3:a:synck_graphica:mailform_pro_cgi:*:*:*:*:*:*:*:*",
"syncthing=syncthing":"cpe:2.3:a:syncthing:syncthing:*:*:*:*:*:*:*:*",
"Synel=eHarmony":"cpe:2.3:a:synel:eharmony:*:*:*:*:*:*:*:*",
"Synerion =TimeNet version ":"cpe:2.3:a:synerion:timenet:*:*:*:*:*:*:*:*",
"Synology=Synology Antivirus Essential":"cpe:2.3:a:synology:antivirus_essential:*:*:*:*:*:*:*:*",
"Synology=Application Service":"cpe:2.3:a:synology:application_service:*:*:*:*:*:*:*:*",
"Synology=Audio Station":"cpe:2.3:a:synology:audio_station:*:*:*:*:*:*:*:*",
"Synology=Synology Audio Station":"cpe:2.3:a:synology:audio_station:*:*:*:*:*:*:*:*",
"Synology=Calendar":"cpe:2.3:a:synology:calendar:*:*:*:*:*:*:*:*",
"Synology=Synology Calendar":"cpe:2.3:a:synology:calendar:*:*:*:*:*:*:*:*",
"Synology=CardDAV Server":"cpe:2.3:a:synology:carddav_server:*:*:*:*:*:*:*:*",
"Synology=Synology CardDAV Server":"cpe:2.3:a:synology:carddav_server:*:*:*:*:*:*:*:*",
"Synology=Chat":"cpe:2.3:a:synology:chat:*:*:*:*:*:*:*:*",
"Synology=Synology Chat":"cpe:2.3:a:synology:chat:*:*:*:*:*:*:*:*",
"Synology=DiskStation Manager (DSM)":"cpe:2.3:a:synology:diskstation_manager:*:*:*:*:*:*:*:*",
"Synology=Synology DiskStation Manager (DSM)":"cpe:2.3:a:synology:diskstation_manager:*:*:*:*:*:*:*:*",
"Synology=DNS Server":"cpe:2.3:a:synology:dns_server:*:*:*:*:*:*:*:*",
"Synology=Synology DNS Server":"cpe:2.3:a:synology:dns_server:*:*:*:*:*:*:*:*",
"Synology=Synology Docker":"cpe:2.3:a:synology:docker:*:*:*:*:*:*:*:*",
"Synology=Download Station":"cpe:2.3:a:synology:download_station:*:*:*:*:*:*:*:*",
"Synology=Synology Download Station":"cpe:2.3:a:synology:download_station:*:*:*:*:*:*:*:*",
"Synology=Drive":"cpe:2.3:a:synology:drive:*:*:*:*:*:*:*:*",
"Synology=File Station":"cpe:2.3:a:synology:file_station:*:*:*:*:*:*:*:*",
"Synology=Synology File Station":"cpe:2.3:a:synology:file_station:*:*:*:*:*:*:*:*",
"Synology=Mail Station":"cpe:2.3:a:synology:mail_station:*:*:*:*:*:*:*:*",
"Synology=MailPlus Server":"cpe:2.3:a:synology:mailplus_server:*:*:*:*:*:*:*:*",
"Synology=Media Server":"cpe:2.3:a:synology:media_server:*:*:*:*:*:*:*:*",
"Synology=Synology Media Server":"cpe:2.3:a:synology:media_server:*:*:*:*:*:*:*:*",
"Synology=Photo Moments":"cpe:2.3:a:synology:moments:*:*:*:*:*:*:*:*",
"Synology=Note Station":"cpe:2.3:a:synology:note_station:*:*:*:*:*:*:*:*",
"Synology=Synology Note Station Client":"cpe:2.3:a:synology:note_station:*:*:*:*:*:*:*:*",
"Synology=Office":"cpe:2.3:a:synology:office:*:*:*:*:*:*:*:*",
"Synology=Photo Station":"cpe:2.3:a:synology:photo_station:*:*:*:*:*:*:*:*",
"Synology=Synology Photo Station":"cpe:2.3:a:synology:photo_station:*:*:*:*:*:*:*:*",
"Synology=Presto File Server":"cpe:2.3:a:synology:presto_file_server:*:*:*:*:*:*:*:*",
"Synology=Synology Router Manager (SRM)":"cpe:2.3:a:synology:router_manager:*:*:*:*:*:*:*:*",
"Synology=Safe Access":"cpe:2.3:a:synology:safeaccess:*:*:*:*:*:*:*:*",
"Synology=SSL VPN Client":"cpe:2.3:a:synology:ssl_vpn_client:*:*:*:*:*:*:*:*",
"Synology=Synology SSL VPN Client":"cpe:2.3:a:synology:ssl_vpn_client:*:*:*:*:*:*:*:*",
"Synology=SSO Server":"cpe:2.3:a:synology:sso_server:*:*:*:*:*:*:*:*",
"Synology=Surveillance Station":"cpe:2.3:a:synology:surveillance_station:*:*:*:*:*:*:*:*",
"Synology=Universal Search":"cpe:2.3:a:synology:universal_search:*:*:*:*:*:*:*:*",
"Synology=Synology Video Station":"cpe:2.3:a:synology:video_station:*:*:*:*:*:*:*:*",
"Synology=Web Station":"cpe:2.3:a:synology:web_station:*:*:*:*:*:*:*:*",
"Synology=WebDAV Server":"cpe:2.3:a:synology:webdav_server:*:*:*:*:*:*:*:*",
"Synopsys=Black Duck Hub":"cpe:2.3:a:synopsys:black_duck_hub:*:*:*:*:*:*:*:*",
"Synopsys=Code Dx":"cpe:2.3:a:synopsys:code_dx:*:*:*:*:*:*:*:*",
"Synopsys=Coverity":"cpe:2.3:a:synopsys:coverity:*:*:*:*:*:*:*:*",
"Synopsys=Seeker":"cpe:2.3:a:synopsys:seeker:*:*:*:*:*:*:*:*",
"SysAid =SysAid API ":"cpe:2.3:a:sysaid:application_programming_interface:*:*:*:*:*:*:*:*",
"Sysaid=SysAid - Okta SSO integration":"cpe:2.3:a:sysaid:okta_sso:*:*:*:*:*:*:*:*",
"Sysaid=Sysaid":"cpe:2.3:a:sysaid:sysaid_on-premises:*:*:*:*:*:*:*:*",
"SysJust= CTS Web":"cpe:2.3:a:sysjust:cts_web:*:*:*:*:*:*:*:*",
"CHANGING=Syuan-Gu-Da-Shih":"cpe:2.3:a:sysjust:syuan-gu-da-shin:*:*:*:*:*:*:*:*",
"nuxsmin=sysPass":"cpe:2.3:a:syspass:syspass:*:*:*:*:*:*:*:*",
"Shenzhen Youkate Industrial=Facial Love Cloud Payment System":"cpe:2.3:a:szjocat:facial_love_cloud_platform:*:*:*:*:*:*:*:*",
"Tabit =Tabit ":"cpe:2.3:a:tabit:tabit:*:*:*:*:*:*:*:*",
"hhurz=hhurz/tableexport.jquery.plugin":"cpe:2.3:a:tableexport.jquery.plugin_project:tableexport.jquery.plugin:*:*:*:*:*:*:*:*",
"Tad=Tad Book3":"cpe:2.3:a:tad_book3_project:tad_book3:*:*:*:*:*:*:*:*",
"unspecified=tad_discuss":"cpe:2.3:a:tad_discuss_project:tad_discuss:*:*:*:*:*:*:*:*",
"Tad=Tad Honor":"cpe:2.3:a:tad_honor_project:tad_honor:*:*:*:*:*:*:*:*",
"Tad=Uploader":"cpe:2.3:a:tad_uploader_project:tad_uploader:*:*:*:*:*:*:*:*",
"Tad=Tad Web":"cpe:2.3:a:tad_web_project:tad_web:*:*:*:*:*:*:*:*",
" Tadiran=Telecom Aeonix":"cpe:2.3:a:tadirantele:aeonix:*:*:*:*:*:*:*:*",
"Tadiran=Telecom Composit":"cpe:2.3:a:tadirantele:aeonix:*:*:*:*:*:*:*:*",
"Tad=TadTools":"cpe:2.3:a:tadtools_project:tadtools:*:*:*:*:*:*:*:*",
"tailscale=tailscale":"cpe:2.3:a:tailscale:tailscale:*:*:*:*:*:*:*:*",
"Talend=Open Studio for MDM":"cpe:2.3:a:talend:open_studio_for_mdm:*:*:*:*:*:*:*:*",
" Talent Software=ECOP":"cpe:2.3:a:talentyazilim:ecop:*:*:*:*:*:*:*:*",
"Talent Software=UNIS":"cpe:2.3:a:talentyazilim:unis:*:*:*:*:*:*:*:*",
"debiki=talkyard":"cpe:2.3:a:talkyard:talkyard:*:*:*:*:*:*:*:*",
"recipes=recipes":"cpe:2.3:a:tandoor:recipes:*:*:*:*:*:*:*:*",
"go-vela=compiler":"cpe:2.3:a:target:compiler:*:*:*:*:*:*:*:*",
"Datera, Inc=targetcli":"cpe:2.3:a:targetcli-fb_project:targetcli-fb:*:*:*:*:*:*:*:*",
"SourceCodester=Simple Task Allocation System":"cpe:2.3:a:task_allocation_system_project:task_allocation_system:*:*:*:*:*:*:*:*",
"SourceCodester=Task Reminder System":"cpe:2.3:a:task_reminder_system_project:task_reminder_system:*:*:*:*:*:*:*:*",
"TasmoAdmin=TasmoAdmin":"cpe:2.3:a:tasmoadmin:tasmoadmin:*:*:*:*:*:*:*:*",
"tastyigniter=tastyigniter/tastyigniter":"cpe:2.3:a:tastyigniter:tastyigniter:*:*:*:*:*:*:*:*",
"unspecified=tatoeba2":"cpe:2.3:a:tatoeba:tatoeba2:*:*:*:*:*:*:*:*",
"tauri-apps=tauri":"cpe:2.3:a:tauri:tauri:*:*:*:*:*:*:*:*",
"Any-Capture=Any Sound Recorder":"cpe:2.3:a:taurisoft:any_sound_recorder:*:*:*:*:*:*:*:*",
"TCMAN=GIM":"cpe:2.3:a:tcman:gim:*:*:*:*:*:*:*:*",
"The TCPdump Group=tcpdump":"cpe:2.3:a:tcpdump:tcpdump:*:*:*:*:*:*:*:*",
"The Tcpdump Group=tcpdump":"cpe:2.3:a:tcpdump:tcpdump:*:*:*:*:*:*:*:*",
"tcpdump.org=tcpdump":"cpe:2.3:a:tcpdump:tcpdump:*:*:*:*:*:*:*:*",
"The Tcpdump Group=tcpslice":"cpe:2.3:a:tcpdump:tcpslice:*:*:*:*:*:*:*:*",
"taosdata=TDengine":"cpe:2.3:a:tdengine:tdengine:*:*:*:*:*:*:*:*",
"tdevs=Hyip Rio":"cpe:2.3:a:tdevs:hyip_rio:*:*:*:*:*:*:*:*",
"PHPGurukul=Teachers Record Management System":"cpe:2.3:a:teachers_record_management_system_project:teachers_record_management_system:*:*:*:*:*:*:*:*",
"XiaoBingBy=TeaCMS":"cpe:2.3:a:teacms_project:teacms:*:*:*:*:*:*:*:*",
"nilsteampassnet=nilsteampassnet/teampass":"cpe:2.3:a:teampass:teampass:*:*:*:*:*:*:*:*",
"TeamViewer=TeamViewer":"cpe:2.3:a:teamviewer:teamviewer:*:*:*:*:*:*:*:*",
"TechPowerUp=RealTemp":"cpe:2.3:a:techpowerup:realtemp:*:*:*:*:*:*:*:*",
"Tecnick.com=TCExam":"cpe:2.3:a:tecnick:tcexam:*:*:*:*:*:*:*:*",
"Tecrail=Responsive Filemanger":"cpe:2.3:a:tecrail:responsive_filemanager:*:*:*:*:*:*:*:*",
"Teikoku Databank, Ltd.=TDB CA TypeA use software":"cpe:2.3:a:teikoku_databank:type_a:*:*:*:*:*:*:*:*",
"TekMonksGitHub=monkshu":"cpe:2.3:a:tekmonks:monkshu:*:*:*:*:*:*:*:*",
"TEL-STER=TelWin SCADA WebInterface":"cpe:2.3:a:tel-ster:telwin_scada_webinterface:*:*:*:*:*:*:*:*",
"ICS-CERT=Teledyne DALSA Sherlock":"cpe:2.3:a:teledynedalsa:sherlock:*:*:*:*:*:*:*:*",
"Telenot Electronic GmbH=CompasX":"cpe:2.3:a:telenot:compasx:*:*:*:*:*:*:*:*",
"Telepad=Telepad":"cpe:2.3:a:telepad-app:telepad:*:*:*:*:*:*:*:*",
"kitabisa=teler":"cpe:2.3:a:teler_project:teler:*:*:*:*:*:*:*:*",
"Progress Software Corporation=Telerik Report Server":"cpe:2.3:a:telerik:report_server_2024:*:*:*:*:*:*:*:*",
"Slanger=Slanger":"cpe:2.3:a:teller:slanger:*:*:*:*:*:*:*:*",
"Telos=Automated Message Handling System":"cpe:2.3:a:telos:automated_message_handling_system:*:*:*:*:*:*:*:*",
"Teltonika=Remote Management System":"cpe:2.3:a:teltonika:remote_management_system:*:*:*:*:*:*:*:*",
"pjsip=pjproject":"cpe:2.3:a:teluu:pjsip:*:*:*:*:*:*:*:*",
"Templatecookie=Adlisting":"cpe:2.3:a:templatecookie:adlisting:*:*:*:*:*:*:*:*",
"Temporal Technologies Inc.=Temporal Server":"cpe:2.3:a:temporal:temporal:*:*:*:*:*:*:*:*",
"Tenable=Tenable Appliance":"cpe:2.3:a:tenable:appliance:*:*:*:*:*:*:*:*",
"tenable=integration-jira-cloud":"cpe:2.3:a:tenable:jira_cloud:*:*:*:*:*:*:*:*",
" Tenable=Nessus":"cpe:2.3:a:tenable:nessus:*:*:*:*:*:*:*:*",
"Tenable Network Security, Inc.=Nessus":"cpe:2.3:a:tenable:nessus:*:*:*:*:*:*:*:*",
"Tenable, Inc.=Tenable Nessus":"cpe:2.3:a:tenable:nessus:*:*:*:*:*:*:*:*",
"Tenable=Nessus":"cpe:2.3:a:tenable:nessus:*:*:*:*:*:*:*:*",
"Tenable=Nessus Agent":"cpe:2.3:a:tenable:nessus:*:*:*:*:*:*:*:*",
"Tenable=Tenable Nessus":"cpe:2.3:a:tenable:nessus:*:*:*:*:*:*:*:*",
"Tenable=Nessus Network Monitor":"cpe:2.3:a:tenable:nessus_network_monitor:*:*:*:*:*:*:*:*",
"Tenable=SecurityCenter":"cpe:2.3:a:tenable:securitycenter:*:*:*:*:*:*:*:*",
"tenancy=multi-tenant":"cpe:2.3:a:tenancy:multi-tenant:*:*:*:*:*:*:*:*",
"Tendermint=Tendermint":"cpe:2.3:a:tendermint:tendermint:*:*:*:*:*:*:*:*",
"github.com/tendermint/tendermint=github.com/tendermint/tendermint/rpc/lib/client":"cpe:2.3:a:tendermint:tendermint:*:*:*:*:*:*:*:*",
"tendermint=tendermint":"cpe:2.3:a:tendermint:tendermint:*:*:*:*:*:*:*:*",
"TeraTerm Project=The installer of Tera Term":"cpe:2.3:a:tera_term_project:tera_term:*:*:*:*:*:*:*:*",
"Teradici=Management Console":"cpe:2.3:a:teradici:pcoip_management_console:*:*:*:*:*:*:*:*",
"cbeust=testng":"cpe:2.3:a:testng_project:testng:*:*:*:*:*:*:*:*",
"npm=textangular":"cpe:2.3:a:textangular:textangular:*:*:*:*:*:*:*:*",
"ahorner=text-helpers":"cpe:2.3:a:texthelpers_project:texthelpers:*:*:*:*:*:*:*:*",
"tgstation=tgstation-server":"cpe:2.3:a:tgstation13:tgstation-server:*:*:*:*:*:*:*:*",
"Thales DIS=SafeNet KeySecure":"cpe:2.3:a:thalesgroup:safenet_keysecure:*:*:*:*:*:*:*:*",
"Thales CPL=Safenet Authentication Service":"cpe:2.3:a:thalesgroup:safenet_windows_logon_agent:*:*:*:*:*:*:*:*",
"ALLE INFORMATION CO., LTD.=School Manage System":"cpe:2.3:a:the_school_manage_system_project:the_school_manage_system:*:*:*:*:*:*:*:*",
"CoreHR=Core Portal":"cpe:2.3:a:theaccessgroup:corehr_core_portal:*:*:*:*:*:*:*:*",
"Thecosy=IceCMS":"cpe:2.3:a:thecosy:icecms:*:*:*:*:*:*:*:*",
"Foreman=foreman":"cpe:2.3:a:theforeman:foreman:*:*:*:*:*:*:*:*",
"[UNKNOWN]=foreman":"cpe:2.3:a:theforeman:foreman:*:*:*:*:*:*:*:*",
"[UNKNOWN]=smart_proxy_dynflow":"cpe:2.3:a:theforeman:foreman:*:*:*:*:*:*:*:*",
"Red Hat=katello":"cpe:2.3:a:theforeman:katello:*:*:*:*:*:*:*:*",
"The Foreman Project=foreman katello plugin":"cpe:2.3:a:theforeman:katello:*:*:*:*:*:*:*:*",
"The Foreman Project=katello":"cpe:2.3:a:theforeman:katello:*:*:*:*:*:*:*:*",
"Simon Kelley=dnsmasq":"cpe:2.3:a:thekelleys:dnsmasq:*:*:*:*:*:*:*:*",
"SourceCodester=Theme Park Ticketing System":"cpe:2.3:a:theme_park_ticketing_system_project:theme_park_ticketing_system:*:*:*:*:*:*:*:*",
"Unknown=Multiple Shipping Address Woocommerce":"cpe:2.3:a:themehigh:multiple_shipping_addresses_for_woocommerce:*:*:*:*:*:*:*:*",
"Midnight Coders=WebORB for Java":"cpe:2.3:a:themidnightcoders:weborb_for_java:*:*:*:*:*:*:*:*",
"Unknown=Themify Shortcodes":"cpe:2.3:a:themify:shortcodes:*:*:*:*:*:*:*:*",
"thephpleague=oauth2-server":"cpe:2.3:a:thephpleague:oauth2-server:*:*:*:*:*:*:*:*",
"saemorris=TheRadSystem":"cpe:2.3:a:theradsystem_project:theradsystem:*:*:*:*:*:*:*:*",
"Los Trigos, Inc=The Receptionist for iPad":"cpe:2.3:a:thereceptionist:the_receptionist_for_ipad:*:*:*:*:*:*:*:*",
"TheThingsNetwork=lorawan-stack":"cpe:2.3:a:thethingsnetwork:lorawan-stack:*:*:*:*:*:*:*:*",
"theupdateframework=go-tuf":"cpe:2.3:a:theupdateframework:go-tuf:*:*:*:*:*:*:*:*",
"thinkst=canarytokens":"cpe:2.3:a:thinkst:canarytokens:*:*:*:*:*:*:*:*",
"ThinuTech=ThinuCMS":"cpe:2.3:a:thinutech:thinu-cms:*:*:*:*:*:*:*:*",
"thm-mni-ii=feedbacksystem":"cpe:2.3:a:thm:feedbacksystem:*:*:*:*:*:*:*:*",
"THM-Health=PILOS":"cpe:2.3:a:thm:pilos:*:*:*:*:*:*:*:*",
"gocd=gocd":"cpe:2.3:a:thoughtworks:gocd:*:*:*:*:*:*:*:*",
"VisitorPass=eVisitorPass":"cpe:2.3:a:thresholdsecurity:evisitorpass:*:*:*:*:*:*:*:*",
"ThroughTek=P2P SDK":"cpe:2.3:a:throughtek:kalay_p2p_software_development_kit:*:*:*:*:*:*:*:*",
"sni=Thruk":"cpe:2.3:a:thruk:thruk:*:*:*:*:*:*:*:*",
"StevenWeathers=thunderdome-planning-poker":"cpe:2.3:a:thunderdome:planning_poker:*:*:*:*:*:*:*:*",
"TianoCore=EDK II":"cpe:2.3:a:tianocore:edk2:*:*:*:*:*:*:*:*",
"TianoCore=edk2":"cpe:2.3:a:tianocore:edk2:*:*:*:*:*:*:*:*",
"Extensible Firmware Interface Development Kit (EDK II)=Extensible Firmware Interface Development Kit (EDK II)":"cpe:2.3:a:tianocore:edk_ii:*:*:*:*:*:*:*:*",
"TianoCore=EDK II":"cpe:2.3:a:tianocore:edk_ii:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO ActiveMatrix BusinessWorks":"cpe:2.3:a:tibco:activematrix_businessworks:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO AuditSafe":"cpe:2.3:a:tibco:auditsafe:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO BusinessConnect Trading Community Management":"cpe:2.3:a:tibco:businessconnect_trading_community_management:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO Data Virtualization":"cpe:2.3:a:tibco:data_virtualization:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO DataSynapse GridServer Manager":"cpe:2.3:a:tibco:datasynapse_gridserver_manager:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO EBX":"cpe:2.3:a:tibco:ebx:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO EBX Add-ons":"cpe:2.3:a:tibco:ebx_add-ons:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO iProcess Workspace (Browser)":"cpe:2.3:a:tibco:iprocess_workspace_browser:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO iWay Service Manager":"cpe:2.3:a:tibco:iway_service_manager:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO MDM":"cpe:2.3:a:tibco:master_data_management:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO Nimbus":"cpe:2.3:a:tibco:nimbus:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO PartnerExpress":"cpe:2.3:a:tibco:partnerexpress:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO Patterns - Search":"cpe:2.3:a:tibco:patterns_-_search:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO Silver Fabric":"cpe:2.3:a:tibco:silver_fabric:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO Spotfire Statistics Services":"cpe:2.3:a:tibco:spotfire_statistics_services:*:*:*:*:*:*:*:*",
"TIBCO Software Inc.=TIBCO Statistica Server":"cpe:2.3:a:tibco:statistica_server:*:*:*:*:*:*:*:*",
"Kaspersky=TigerVNC":"cpe:2.3:a:tigervnc:tigervnc:*:*:*:*:*:*:*:*",
"GlavSoft LLC=TightVNC Viewer":"cpe:2.3:a:tightvnc:tightvnc:*:*:*:*:*:*:*:*",
"Kaspersky=TightVNC":"cpe:2.3:a:tightvnc:tightvnc:*:*:*:*:*:*:*:*",
"Tiki-Wiki Groupware=Tiki-Wiki CMS":"cpe:2.3:a:tiki:tikiwiki_cms\/groupware:*:*:*:*:*:*:*:*",
"tildearrow=Furnace":"cpe:2.3:a:tildearrow:furnace:*:*:*:*:*:*:*:*",
"Tim Campus=Confession Wall":"cpe:2.3:a:tim_campus_confession_wall_project:tim_campus_confession_wall:*:*:*:*:*:*:*:*",
"timescale=timescaledb":"cpe:2.3:a:timescale:timescaledb:*:*:*:*:*:*:*:*",
"Timesheet Next Gen=Timesheet Next Gen":"cpe:2.3:a:timesheet_next_gen_project:timesheet_next_gen:*:*:*:*:*:*:*:*",
"anuko=timetracker":"cpe:2.3:a:timetracker_project:timetracker:*:*:*:*:*:*:*:*",
"Tinfoil=devise-two-factor":"cpe:2.3:a:tinfoilsecurity:devise-two-factor:*:*:*:*:*:*:*:*",
"tinymce=tinymce":"cpe:2.3:a:tiny:tinymce:*:*:*:*:*:*:*:*",
"Tiny File Manager=Tiny File Manager":"cpe:2.3:a:tiny_file_manager_project:tiny_file_manager:*:*:*:*:*:*:*:*",
"prasathmani=prasathmani/tinyfilemanager":"cpe:2.3:a:tiny_file_manager_project:tiny_file_manager:*:*:*:*:*:*:*:*",
"syoyo=tinydng":"cpe:2.3:a:tinydng_project:tinydng:*:*:*:*:*:*:*:*",
"Talos=Tinysvcmdns":"cpe:2.3:a:tinysvcmdns_project:tinysvcmdns:*:*:*:*:*:*:*:*",
"geekman=Tinysvcmdns":"cpe:2.3:a:tinysvcmdns_project:tinysvcmdns:*:*:*:*:*:*:*:*",
"Tise Technology=Parking Web Report":"cpe:2.3:a:tise:parking_web_report:*:*:*:*:*:*:*:*",
"tj-actions=branch-names":"cpe:2.3:a:tj-actions:branch-names:*:*:*:*:*:*:*:*",
"tj-actions=changed-files":"cpe:2.3:a:tj-actions:changed-files:*:*:*:*:*:*:*:*",
"drogatkin=TJWS2":"cpe:2.3:a:tjws2_project:tjws2:*:*:*:*:*:*:*:*",
"HackerOne=tkinter node module":"cpe:2.3:a:tkinter_package:tkinter:*:*:*:*:*:*:*:*",
"tmercswims=tmerc-cogs":"cpe:2.3:a:tmerc-cogs_project:tmerc-cogs:*:*:*:*:*:*:*:*",
"TMS-Plugins=wpDataTables Lite":"cpe:2.3:a:tms-outsource:wpdatatables_lite:*:*:*:*:*:*:*:*",
"TNB Mobile Solutions=Cockpit Software":"cpe:2.3:a:tnbmobil:cockpit:*:*:*:*:*:*:*:*",
"unspecified=The Next Generation of Genealogy Sitebuilding":"cpe:2.3:a:tngsitebuilding:the_next_generation_of_genealogy_sitebuilding:*:*:*:*:*:*:*:*",
"TOBESOFT=MiPlatform 320, 320U, 330, 330U":"cpe:2.3:a:tobesoft:miplatform:*:*:*:*:*:*:*:*",
"TOBESOFT=XPLATFORM XPlatformLib922.dll":"cpe:2.3:a:tobesoft:xplatform:*:*:*:*:*:*:*:*",
"Tobesoft=XPLATFORM":"cpe:2.3:a:tobesoft:xplatform:*:*:*:*:*:*:*:*",
"Tobesoft=XPlatform":"cpe:2.3:a:tobesoft:xplatform:*:*:*:*:*:*:*:*",
"tolgee=tolgee-platform":"cpe:2.3:a:tolgee:tolgee:*:*:*:*:*:*:*:*",
"GGGGGGGG=ToN-MasterServer":"cpe:2.3:a:ton-masterserver_project:ton-masterserver:*:*:*:*:*:*:*:*",
"Tongda=OA 2017":"cpe:2.3:a:tongda2000:office_anywhere:*:*:*:*:*:*:*:*",
"Tongda=OA 2017":"cpe:2.3:a:tongda2000:office_anywhere_2017:*:*:*:*:*:*:*:*",
"Tongda=OA":"cpe:2.3:a:tongda2000:tongda_office_anywhere:*:*:*:*:*:*:*:*",
"Tongda=OA 2017":"cpe:2.3:a:tongda2000:tongda_office_anywhere:*:*:*:*:*:*:*:*",
"GTKWave=GTKWave":"cpe:2.3:a:tonybybell:gtkwave:*:*:*:*:*:*:*:*",
"ToolJet=ToolJet":"cpe:2.3:a:tooljet:tooljet:*:*:*:*:*:*:*:*",
"tooljet=tooljet/tooljet":"cpe:2.3:a:tooljet:tooljet:*:*:*:*:*:*:*:*",
"Topaz=OFD":"cpe:2.3:a:topazevolution:ofd:*:*:*:*:*:*:*:*",
"TOPOO Technology=TOPMeeting":"cpe:2.3:a:topmeeting:topmeeting:*:*:*:*:*:*:*:*",
"wagtail=wagtail":"cpe:2.3:a:torchbox:wagtail:*:*:*:*:*:*:*:*",
"tornadoweb=Tornado":"cpe:2.3:a:tornadoweb:tornado:*:*:*:*:*:*:*:*",
"tortoise=tortoise-orm":"cpe:2.3:a:tortoise_orm_project:tortoise_orm:*:*:*:*:*:*:*:*",
"mschaef=toto":"cpe:2.3:a:toto_project:toto:*:*:*:*:*:*:*:*",
"TOTVS=Fluig Platform":"cpe:2.3:a:totvs:fluig:*:*:*:*:*:*:*:*",
"TOTVS=RM":"cpe:2.3:a:totvs:rm:*:*:*:*:*:*:*:*",
"puncsky=touchbase.ai":"cpe:2.3:a:touchbase.ai_project:touchbase.ai:*:*:*:*:*:*:*:*",
"mubarakalmehairbi=ToUI":"cpe:2.3:a:toui_project:toui:*:*:*:*:*:*:*:*",
"The Ministry of Justice=Shinseiyo Sogo Soft":"cpe:2.3:a:touki-kyoutaku-online:shinseiyo_sogo_soft:*:*:*:*:*:*:*:*",
"tovyblox=tovy":"cpe:2.3:a:tovyblox:tovy:*:*:*:*:*:*:*:*",
"TOYOTA MOTOR CORPORATION=Global TechStream (GTS) for TOYOTA dealers":"cpe:2.3:a:toyota:global_techstream:*:*:*:*:*:*:*:*",
"TP-LINK=EAP Controller":"cpe:2.3:a:tp-link:eap_controller:*:*:*:*:*:*:*:*",
"yuan1994=tpAdmin":"cpe:2.3:a:tpadmin_project:tpadmin:*:*:*:*:*:*:*:*",
"TPM 2.0 Tools=tpm2-tools":"cpe:2.3:a:tpm2-tools_project:tpm2.0-tools:*:*:*:*:*:*:*:*",
"tpm2-software=tpm2-tss":"cpe:2.3:a:tpm2_software_stack_project:tpm2_software_stack:*:*:*:*:*:*:*:*",
"Traccar=Traccar":"cpe:2.3:a:traccar:traccar:*:*:*:*:*:*:*:*",
"traccar=traccar":"cpe:2.3:a:traccar:traccar:*:*:*:*:*:*:*:*",
"PDF-XChange=PDF-XChange Editor":"cpe:2.3:a:tracker-software:pdf-xchange_editor:*:*:*:*:*:*:*:*",
"traefik=traefik":"cpe:2.3:a:traefik:traefik:*:*:*:*:*:*:*:*",
"trailofbits=uthenticode":"cpe:2.3:a:trailofbits:uthenticode:*:*:*:*:*:*:*:*",
"SourceCodester=Train Scheduler App":"cpe:2.3:a:train_scheduler_app_project:train_scheduler_app:*:*:*:*:*:*:*:*",
"SourceCodester=Train Station Ticketing System":"cpe:2.3:a:train_station_ticketing_system_project:train_station_ticketing_system:*:*:*:*:*:*:*:*",
"Travelmate=Travelable Trek Management Solution":"cpe:2.3:a:travelable_trek_management_solution_project:travelable_trek_management_solution:*:*:*:*:*:*:*:*",
"Trellix=Trellix Agent":"cpe:2.3:a:trellix:agent:*:*:*:*:*:*:*:*",
"Trellix=Trellix Application and Change Control (TACC)":"cpe:2.3:a:trellix:application_and_change_control:*:*:*:*:*:*:*:*",
"Trellix =Data Loss Prevention Endpoint for Windows":"cpe:2.3:a:trellix:data_loss_prevention:*:*:*:*:*:*:*:*",
"Trellix =Trellix Endpoint Security":"cpe:2.3:a:trellix:endpoint_security:*:*:*:*:*:*:*:*",
"Trellix=ESM":"cpe:2.3:a:trellix:enterprise_security_manager:*:*:*:*:*:*:*:*",
"Trellix=Enterprise Security Manager":"cpe:2.3:a:trellix:enterprise_security_manager:*:*:*:*:*:*:*:*",
"Trellix=Trellix Enterprise Security Manager (ESM)":"cpe:2.3:a:trellix:enterprise_security_manager:*:*:*:*:*:*:*:*",
"Trellix=GetSusp":"cpe:2.3:a:trellix:getsusp:*:*:*:*:*:*:*:*",
"Skyhigh Security=Secure Web Gateway (SWG)":"cpe:2.3:a:trellix:skyhigh_secure_web_gateway:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Antivirus for Mac":"cpe:2.3:a:trendmicro:antivirus_for_mac:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Control Manager":"cpe:2.3:a:trendmicro:control_manager:*:*:*:*:*:*:*:*",
"Trend Micro, Inc.=Trend Micro Deep Discovery Director":"cpe:2.3:a:trendmicro:deep_discovery_director:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Deep Discovery Email Inspector":"cpe:2.3:a:trendmicro:deep_discovery_email_inspector:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Deep Discovery Inspector":"cpe:2.3:a:trendmicro:deep_discovery_inspector:*:*:*:*:*:*:*:*",
"Trend Micro=Deep Security Manager":"cpe:2.3:a:trendmicro:deep_security:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Deep Security as a Service":"cpe:2.3:a:trendmicro:deep_security_as_a_service:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Email Encryption Gateway":"cpe:2.3:a:trendmicro:email_encryption_gateway:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Encryption for Email":"cpe:2.3:a:trendmicro:encryption_for_email:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Endpoint Application Control":"cpe:2.3:a:trendmicro:endpoint_application_control:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Home Network Security":"cpe:2.3:a:trendmicro:home_network_security:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro HouseCall for Home Networks":"cpe:2.3:a:trendmicro:housecall_for_home_networks:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro IM Security":"cpe:2.3:a:trendmicro:im_security:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro InterScan Messaging Security Virtual Appliance":"cpe:2.3:a:trendmicro:interscan_messaging_security_virtual_appliance:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro InterScan Web Security Virtual Appliance":"cpe:2.3:a:trendmicro:interscan_web_security_virtual_appliance:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro OfficeScan":"cpe:2.3:a:trendmicro:officescan:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro OfficeScan":"cpe:2.3:a:trendmicro:officescan_xg:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Password Manager":"cpe:2.3:a:trendmicro:password_manager:*:*:*:*:*:*:*:*",
"Trend Micro=Smart Protection Server (Standalone)":"cpe:2.3:a:trendmicro:smart_protection_server:*:*:*:*:*:*:*:*",
"Trend Micro=Trend Micro Smart Protection Server (Standalone)":"cpe:2.3:a:trendmicro:smart_protection_server:*:*:*:*:*:*:*:*",
"Triangle MicroWorks=SCADA Data Gateway":"cpe:2.3:a:trianglemicroworks:scada_data_gateway:*:*:*:*:*:*:*:*",
"Tribal Systems=Zenario CMS":"cpe:2.3:a:tribalsystems:zenario:*:*:*:*:*:*:*:*",
"Tribe29=Checkmk Appliance":"cpe:2.3:a:tribe29:checkmk:*:*:*:*:*:*:*:*",
"Tridactyl=Tridactyl":"cpe:2.3:a:tridactyl_project:tridactyl:*:*:*:*:*:*:*:*",
"Trihedral=VTScada":"cpe:2.3:a:trihedral:vtscada:*:*:*:*:*:*:*:*",
"zadam=zadam/trilium":"cpe:2.3:a:trilium_project:trilium:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=TripAdvisor":"cpe:2.3:a:tripadvisor:tamobileapp:*:*:*:*:*:*:*:*",
"tritonmc=Triton":"cpe:2.3:a:triton_project:triton:*:*:*:*:*:*:*:*",
"troglobit=uftpd":"cpe:2.3:a:troglobit:uftpd:*:*:*:*:*:*:*:*",
"PrivateUploader=PrivateUploader":"cpe:2.3:a:troplo:privateuploader:*:*:*:*:*:*:*:*",
"TRtek Software=Education Portal":"cpe:2.3:a:trteksolutions:education_portal:*:*:*:*:*:*:*:*",
"polonel=polonel/trudesk":"cpe:2.3:a:trudesk_project:trudesk:*:*:*:*:*:*:*:*",
"TrueConf=Server":"cpe:2.3:a:trueconf:server:*:*:*:*:*:*:*:*",
"TrueLayer=truelayer-dotnet":"cpe:2.3:a:truelayer:truelayer.net:*:*:*:*:*:*:*:*",
"OWASP ModSecurity=ModSecurity":"cpe:2.3:a:trustwave:modsecurity:*:*:*:*:*:*:*:*",
"Newcomer1989=TSN-Ranksystem":"cpe:2.3:a:ts-ranksystem:tsn-ranksystem:*:*:*:*:*:*:*:*",
"tsolucio=tsolucio/corebos":"cpe:2.3:a:tsolucio:corebos:*:*:*:*:*:*:*:*",
"[UNKNOWN]=ttembed":"cpe:2.3:a:ttembed_project:ttembed:*:*:*:*:*:*:*:*",
"TUBITAK=Pardus Software Center":"cpe:2.3:a:tubitak:pardus_software_center:*:*:*:*:*:*:*:*",
"Kofax=Capture":"cpe:2.3:a:tungstenautomation:kofax_capture:*:*:*:*:*:*:*:*",
"Tura=Signalix":"cpe:2.3:a:turaconsulting:signalix:*:*:*:*:*:*:*:*",
"OnShift=TurboGears":"cpe:2.3:a:turbogears_project:turbogears:*:*:*:*:*:*:*:*",
"Kaspersky=TurboVNC":"cpe:2.3:a:turbovnc:turbovnc:*:*:*:*:*:*:*:*",
"TurboWarp=desktop":"cpe:2.3:a:turbowarp:turbowarp_desktop:*:*:*:*:*:*:*:*",
"Unknown=Turn off all comments":"cpe:2.3:a:turn_off_all_comments_project:turn_off_all_comments:*:*:*:*:*:*:*:*",
"Turna=Advertising Administration Panel":"cpe:2.3:a:turnatasarim:advertising_administration_panel:*:*:*:*:*:*:*:*",
"turt2live=matrix-media-repo":"cpe:2.3:a:turt2live:matrix-media-repo:*:*:*:*:*:*:*:*",
"tutao=tutanota":"cpe:2.3:a:tuta:tutanota:*:*:*:*:*:*:*:*",
"camaleon_cms=camaleon_cms":"cpe:2.3:a:tuzitio:camaleon_cms:*:*:*:*:*:*:*:*",
"TAIWAN-CA(TWCA)=JCICSecurityTool":"cpe:2.3:a:twca:jcicsecuritytool:*:*:*:*:*:*:*:*",
"Twinkle Toes Software=Booked":"cpe:2.3:a:twinkletoessoftware:booked:*:*:*:*:*:*:*:*",
"TwinOaks Computing=CoreDX DDS":"cpe:2.3:a:twinoakscomputing:coredx_dds:*:*:*:*:*:*:*:*",
"twisted=twisted":"cpe:2.3:a:twistedmatrix:twisted:*:*:*:*:*:*:*:*",
"happyman=twmap":"cpe:2.3:a:twmap_project:twmap:*:*:*:*:*:*:*:*",
"txthinking=brook":"cpe:2.3:a:txthinking:brook:*:*:*:*:*:*:*:*",
"Tyler Technologies=Civil and Criminal Electronic Filing":"cpe:2.3:a:tylertech:court_case_management_plus:*:*:*:*:*:*:*:*",
"Tyler Technologies=Court Case Management Plus":"cpe:2.3:a:tylertech:court_case_management_plus:*:*:*:*:*:*:*:*",
"Tyler Technologies=Magistrate Court Case Management Plus":"cpe:2.3:a:tylertech:court_case_management_plus:*:*:*:*:*:*:*:*",
"typed-function=typed-function":"cpe:2.3:a:typed_function_project:typed_function:*:*:*:*:*:*:*:*",
"http4s=blaze":"cpe:2.3:a:typelevel:blaze:*:*:*:*:*:*:*:*",
"typelevel=fs2":"cpe:2.3:a:typelevel:fs2:*:*:*:*:*:*:*:*",
"typelevel=grackle":"cpe:2.3:a:typelevel:grackle:*:*:*:*:*:*:*:*",
"http4s=http4s":"cpe:2.3:a:typelevel:http4s:*:*:*:*:*:*:*:*",
"typelevel=jawn":"cpe:2.3:a:typelevel:jawn:*:*:*:*:*:*:*:*",
"TYPO3=Fluid":"cpe:2.3:a:typo3:fluid:*:*:*:*:*:*:*:*",
"TYPO3=html-sanitizer":"cpe:2.3:a:typo3:html_sanitizer:*:*:*:*:*:*:*:*",
"TYPO3GmbH=svg_sanitizer":"cpe:2.3:a:typo3:svg_sanitizer:*:*:*:*:*:*:*:*",
"TYPO3=TYPO3 CMS":"cpe:2.3:a:typo3:typo3:*:*:*:*:*:*:*:*",
"TYPO3=TYPO3.CMS":"cpe:2.3:a:typo3:typo3:*:*:*:*:*:*:*:*",
"TYPO3=typo3":"cpe:2.3:a:typo3:typo3:*:*:*:*:*:*:*:*",
"benjaminkott=bootstrap_package":"cpe:2.3:a:typo3:typo3:*:*:*:*:*:*:*:*",
"Typora=Typora":"cpe:2.3:a:typora:typora:*:*:*:*:*:*:*:*",
"ualbertalib=NEOSDiscovery":"cpe:2.3:a:ualberta:neosdiscovery:*:*:*:*:*:*:*:*",
"ubertidavide=fastbots":"cpe:2.3:a:ubertidavide:fastbots:*:*:*:*:*:*:*:*",
" jrspruitt=ubi_reader":"cpe:2.3:a:ubi_reader_project:ubi_reader:*:*:*:*:*:*:*:*",
"unspecified=UBI Reader":"cpe:2.3:a:ubi_reader_project:ubi_reader:*:*:*:*:*:*:*:*",
"UBIT Information Technologies=Student Information Management System":"cpe:2.3:a:ubit:student_information_management_system:*:*:*:*:*:*:*:*",
"Ubuntu Budgie=Budgie Extras":"cpe:2.3:a:ubuntubudgie:budgie_extras:*:*:*:*:*:*:*:*",
"University of Central Florida=Materia":"cpe:2.3:a:ucf:materia:*:*:*:*:*:*:*:*",
"UCHIDA YOKO CO., LTD.=ASSETBASE":"cpe:2.3:a:uchida:assetbase:*:*:*:*:*:*:*:*",
"uClibc-ng=uClibc-ng":"cpe:2.3:a:uclibc-ng_project:uclibc-ng:*:*:*:*:*:*:*:*",
"udecode=plate":"cpe:2.3:a:udecode:plate:*:*:*:*:*:*:*:*",
"uFactory=xArm5 Lite, xArm 6 and xArm 7":"cpe:2.3:a:ufactory:xarm_studio:*:*:*:*:*:*:*:*",
"Uffizio=GPS Tracker":"cpe:2.3:a:uffizio:gps_tracker:*:*:*:*:*:*:*:*",
"Ubiquiti Networks=EdgeMAX":"cpe:2.3:a:ui:edgeswitch_x:*:*:*:*:*:*:*:*",
"UniFi=UniFi":"cpe:2.3:a:ui:unifi_controller:*:*:*:*:*:*:*:*",
"Ubiquiti=UniFi Network Application":"cpe:2.3:a:ui:unifi_network_application:*:*:*:*:*:*:*:*",
"UniFi=Protect for UniFi Cloud Key Gen2 Plus":"cpe:2.3:a:ui:unifi_protect:*:*:*:*:*:*:*:*",
"uLaunchELF=uLaunchELF":"cpe:2.3:a:ulaunchelf_project:ulaunchelf:*:*:*:*:*:*:*:*",
"eprintsug=ulcc-core":"cpe:2.3:a:ulcc-core_project:ulcc-core:*:*:*:*:*:*:*:*",
"CyberSecurity Philippines - CERT=UmbracoCMS":"cpe:2.3:a:umbraco:umbraco_cms:*:*:*:*:*:*:*:*",
"Umbraco=Umbraco CMS":"cpe:2.3:a:umbraco:umbraco_cms:*:*:*:*:*:*:*:*",
"umbraco=Umbraco-CMS":"cpe:2.3:a:umbraco:umbraco_cms:*:*:*:*:*:*:*:*",
"Unified Automation=OPC UA C++ Demo Server":"cpe:2.3:a:unified-automation:opc_ua_c\+\+_demo_server:*:*:*:*:*:*:*:*",
"Univera Computer System =Panorama":"cpe:2.3:a:univera:panorama:*:*:*:*:*:*:*:*",
"Izmir Katip Celebi University=UBYS":"cpe:2.3:a:university_information_management_system_project:university_information_management_system:*:*:*:*:*:*:*:*",
"unknown-o=download-station":"cpe:2.3:a:unknown-o:download-station:*:*:*:*:*:*:*:*",
"unpoly=unpoly-rails":"cpe:2.3:a:unpoly:unpoly-rails:*:*:*:*:*:*:*:*",
"Karlatemp=UnsafeAccessor":"cpe:2.3:a:unsafe_accessor_project:unsafe_accessor:*:*:*:*:*:*:*:*",
"iSharer and upRedSun=File Sharing Wizard":"cpe:2.3:a:upredsun:file_sharing_wizard:*:*:*:*:*:*:*:*",
"POWERCOM CO., LTD.=UPSMON PRO":"cpe:2.3:a:upspowercom:upsmon_pro:*:*:*:*:*:*:*:*",
"louislam=uptime-kuma":"cpe:2.3:a:uptime-kuma_project:uptime-kuma:*:*:*:*:*:*:*:*",
"louislam=uptime-kuma":"cpe:2.3:a:uptime.kuma:uptime_kuma:*:*:*:*:*:*:*:*",
"Universitat Polit  cnica de Val  ncia (UPV)=UPV PEIX":"cpe:2.3:a:upv:peix:*:*:*:*:*:*:*:*",
"medialize=URI.js":"cpe:2.3:a:uri.js_project:uri.js:*:*:*:*:*:*:*:*",
"medialize=medialize/URI.js":"cpe:2.3:a:uri.js_project:uri.js:*:*:*:*:*:*:*:*",
"medialize=medialize/uri.js":"cpe:2.3:a:uri.js_project:uri.js:*:*:*:*:*:*:*:*",
"SourceCodester=URL Shortener":"cpe:2.3:a:url_shortener_project:url_shortener:*:*:*:*:*:*:*:*",
"Aaron-Junker=USOC":"cpe:2.3:a:useful_simple_open-source_cms_project:useful_simple_open-source_cms:*:*:*:*:*:*:*:*",
"usememos=usememos/memos":"cpe:2.3:a:usememos:memos:*:*:*:*:*:*:*:*",
"userfrosting=userfrosting":"cpe:2.3:a:userfrosting:userfrosting:*:*:*:*:*:*:*:*",
"Usta=AYBS":"cpe:2.3:a:usta:aybs:*:*:*:*:*:*:*:*",
"USVN Team=User-friendly SVN (USVN)":"cpe:2.3:a:usvn:usvn:*:*:*:*:*:*:*:*",
"Utarit Information Technologies=Persolus":"cpe:2.3:a:utarit:persolus:*:*:*:*:*:*:*:*",
"fabiocaccamo=fabiocaccamo/utils.js":"cpe:2.3:a:utils.js_project:utils.js:*:*:*:*:*:*:*:*",
"fredsmith=utils":"cpe:2.3:a:utils_project:utils:*:*:*:*:*:*:*:*",
"uTorrent=Web":"cpe:2.3:a:utorrent:web:*:*:*:*:*:*:*:*",
"unspecified=uTorrent":"cpe:2.3:a:utorrent:web:*:*:*:*:*:*:*:*",
"uvdesk=uvdesk/community-skeleton":"cpe:2.3:a:uvdesk:community-skeleton:*:*:*:*:*:*:*:*",
"Kaspersky Lab=UltraVNC":"cpe:2.3:a:uvnc:ultravnc:*:*:*:*:*:*:*:*",
"UltraVNC=UltraVNC":"cpe:2.3:a:uvnc:ultravnc:*:*:*:*:*:*:*:*",
"ultravnc=UltraVNC":"cpe:2.3:a:uvnc:ultravnc:*:*:*:*:*:*:*:*",
"Boom=CMS":"cpe:2.3:a:uxblondon:boom_cms:*:*:*:*:*:*:*:*",
" Uyumsoft Information System and Technologies=LioXERP":"cpe:2.3:a:uyumsoft:lioxerp:*:*:*:*:*:*:*:*",
"Uzay Baskul=Weighbridge Automation Software":"cpe:2.3:a:uzaybaskul:weighbridge_automation_software:*:*:*:*:*:*:*:*",
"v2fly=v2fly/v2ray-core":"cpe:2.3:a:v2fly:v2ray-core:*:*:*:*:*:*:*:*",
"Vaadin=Designer":"cpe:2.3:a:vaadin:designer:*:*:*:*:*:*:*:*",
"Vadi Corporate Information Systems=DigiKent":"cpe:2.3:a:vadi:digikent:*:*:*:*:*:*:*:*",
"Vaerys-Dawn=DiscordSailv2":"cpe:2.3:a:vaerys-dawn:discordsailv2:*:*:*:*:*:*:*:*",
"Valmet DNA=Valmet DNA":"cpe:2.3:a:valmet:dna:*:*:*:*:*:*:*:*",
"van_der_Schaar LAB=TemporAI":"cpe:2.3:a:vanderschaarlab:temporai:*:*:*:*:*:*:*:*",
"van_der_Schaar LAB=synthcity":"cpe:2.3:a:vanderschaarlab:temporai:*:*:*:*:*:*:*:*",
"Kaspersky Lab=Vanilla Forums":"cpe:2.3:a:vanillaforums:vanilla_forums:*:*:*:*:*:*:*:*",
"vantage6=vantage6-UI":"cpe:2.3:a:vantage6:vantage6-ui:*:*:*:*:*:*:*:*",
"vantage6=vantage6":"cpe:2.3:a:vantage6:vantage6:*:*:*:*:*:*:*:*",
"vapor=leaf-kit":"cpe:2.3:a:vapor:leafkit:*:*:*:*:*:*:*:*",
"vapor=vapor":"cpe:2.3:a:vapor:vapor:*:*:*:*:*:*:*:*",
"VCFTools=vcftools":"cpe:2.3:a:vcftools_project:vcftools:*:*:*:*:*:*:*:*",
"Vector 35=Binary Ninja":"cpe:2.3:a:vector35:binary_ninja:*:*:*:*:*:*:*:*",
"VEEAM=One Agent":"cpe:2.3:a:veeam:one:*:*:*:*:*:*:*:*",
"Veeam=One":"cpe:2.3:a:veeam:one:*:*:*:*:*:*:*:*",
"VegaGroup =Web Collection":"cpe:2.3:a:vegagroup:web_collection:*:*:*:*:*:*:*:*",
"Veragroup =Mobile Assistant":"cpe:2.3:a:vegayazilim:mobile_assistant:*:*:*:*:*:*:*:*",
"CodeAstro=Vehicle Booking System":"cpe:2.3:a:vehicle_booking_system_project:vehicle_booking_system:*:*:*:*:*:*:*:*",
"SourceCodester=Vehicle Service Management System":"cpe:2.3:a:vehicle_service_management_system_project:vehicle_service_management_system:*:*:*:*:*:*:*:*",
"Unknown=VK All in One Expansion Unit":"cpe:2.3:a:vektor-inc:vk_all_in_one_expansion_unit:*:*:*:*:*:*:*:*",
"Velneo=Velneo vClient":"cpe:2.3:a:velneo:vclient:*:*:*:*:*:*:*:*",
"Navetti=PricePoint":"cpe:2.3:a:vendavo:pricepoint:*:*:*:*:*:*:*:*",
"jlangch=venice":"cpe:2.3:a:venice_project:venice:*:*:*:*:*:*:*:*",
"Veon Computer=Service Tracking Software":"cpe:2.3:a:veom:service_tracking:*:*:*:*:*:*:*:*",
"Veribilim Software Computer=Veribase":"cpe:2.3:a:veribase:veribase:*:*:*:*:*:*:*:*",
"class.upload.php=class.upload.php":"cpe:2.3:a:verot:class.upload.php:*:*:*:*:*:*:*:*",
"Versiant=LYNX Customer Service Portal":"cpe:2.3:a:versiant:lynx_customer_service_portal:*:*:*:*:*:*:*:*",
"commenthol=versionn":"cpe:2.3:a:versionn_project:versionn:*:*:*:*:*:*:*:*",
"vertaai=vertaai/modeldb":"cpe:2.3:a:vertaai:modeldb:*:*:*:*:*:*:*:*",
"unspecified=Vesta Control Panel":"cpe:2.3:a:vestacp:control_panel:*:*:*:*:*:*:*:*",
"Grand Vice info Co.=webopac7":"cpe:2.3:a:vice:webopac:*:*:*:*:*:*:*:*",
"VideoLAN=VLC":"cpe:2.3:a:videolan:vlc_media_player:*:*:*:*:*:*:*:*",
"viewvc=viewvc":"cpe:2.3:a:viewvc:viewvc:*:*:*:*:*:*:*:*",
"viliusle=viliusle/minipaint":"cpe:2.3:a:viliusle:minipaint:*:*:*:*:*:*:*:*",
"OpenCloudOS=OpenCloudOS Stream":"cpe:2.3:a:vim:vim:*:*:*:*:*:*:*:*",
"vim=vim":"cpe:2.3:a:vim:vim:*:*:*:*:*:*:*:*",
"vim=vim/vim":"cpe:2.3:a:vim:vim:*:*:*:*:*:*:*:*",
"Vinchin=Backup and Recovery":"cpe:2.3:a:vinchin:vinchin_backup_and_recovery:*:*:*:*:*:*:*:*",
"yiwent=Vip Video Analysis":"cpe:2.3:a:vip_video_analysis_project:vip_video_analysis:*:*:*:*:*:*:*:*",
"unspecified=vexim2":"cpe:2.3:a:virtual_exim_project:virtual_exim_2:*:*:*:*:*:*:*:*",
"VISAM=VBASE":"cpe:2.3:a:visam:vbase:*:*:*:*:*:*:*:*",
"VISAM=VBASE":"cpe:2.3:a:visam:vbase_automation_base:*:*:*:*:*:*:*:*",
"VISAM=VBASE Pro-RT/ Server-RT (Web Remote)":"cpe:2.3:a:visam:vbase_web-remote:*:*:*:*:*:*:*:*",
"Visio Globe=Visioweb":"cpe:2.3:a:visioglobe:visioweb:*:*:*:*:*:*:*:*",
"SourceCodester=Visitor Management System":"cpe:2.3:a:visitor_management_system_project:visitor_management_system:*:*:*:*:*:*:*:*",
"Galaxy Software Services Corporation.=Vitals ESP":"cpe:2.3:a:vitalsesp:vitals_esp:*:*:*:*:*:*:*:*",
"Vivaldi Technologies=Vivaldi installer for Windows":"cpe:2.3:a:vivaldi:vivaldi_installer_for_windows:*:*:*:*:*:*:*:*",
"vivo=appstore":"cpe:2.3:a:vivo:appstore:*:*:*:*:*:*:*:*",
"vivo=Jovi Smart Scene":"cpe:2.3:a:vivo:jovi_smart_scene:*:*:*:*:*:*:*:*",
"K_OKADA=ViX":"cpe:2.3:a:vix_project:vix:*:*:*:*:*:*:*:*",
"VMware=AirWatch Console":"cpe:2.3:a:vmware:airwatch:*:*:*:*:*:*:*:*",
"VMware=VMware AirWatch Console (AWC)":"cpe:2.3:a:vmware:airwatch:*:*:*:*:*:*:*:*",
"VMware=Airwatch Console":"cpe:2.3:a:vmware:airwatch_console:*:*:*:*:*:*:*:*",
"VMware=VMware Workspace ONE Unified Endpoint Management Console (AirWatch Console)":"cpe:2.3:a:vmware:airwatch_console:*:*:*:*:*:*:*:*",
"VMware=Fusion":"cpe:2.3:a:vmware:fusion:*:*:*:*:*:*:*:*",
"VMware=VMware Fusion":"cpe:2.3:a:vmware:fusion:*:*:*:*:*:*:*:*",
"greenplum-db=gpdb":"cpe:2.3:a:vmware:greenplum_database:*:*:*:*:*:*:*:*",
"VMware=Horizon DaaS":"cpe:2.3:a:vmware:horizon_daas:*:*:*:*:*:*:*:*",
"VMware=Horizon View Client for Mac":"cpe:2.3:a:vmware:horizon_view:*:*:*:*:*:*:*:*",
"VMware=Horizon View Agent":"cpe:2.3:a:vmware:horizon_view_agents:*:*:*:*:*:*:*:*",
"VMware=InstallBuilder":"cpe:2.3:a:vmware:installbuilder:*:*:*:*:*:*:*:*",
"VMware=NSX-V Edge":"cpe:2.3:a:vmware:nsx-v_edge:*:*:*:*:*:*:*:*",
"VMware=NSX Edge":"cpe:2.3:a:vmware:nsx_edge:*:*:*:*:*:*:*:*",
"VMware=NSX SD-WAN by VeloCloud":"cpe:2.3:a:vmware:nsx_sd-wan_by_velocloud:*:*:*:*:*:*:*:*",
"VMware Tanzu=Pivotal Scheduler":"cpe:2.3:a:vmware:pivotal_scheduler:*:*:*:*:*:*:*:*",
"rabbitmq=rabbitmq-server":"cpe:2.3:a:vmware:rabbitmq:*:*:*:*:*:*:*:*",
"rabbitmq=rabbitmq-java-client":"cpe:2.3:a:vmware:rabbitmq_java_client:*:*:*:*:*:*:*:*",
"VMware=SD-WAN by VeloCloud":"cpe:2.3:a:vmware:sd-wan_by_velocloud:*:*:*:*:*:*:*:*",
"VMware Tanzu=Single Sign-On for VMware Tanzu":"cpe:2.3:a:vmware:single_sign-on_for_tanzu:*:*:*:*:*:*:*:*",
"Spring=Spring AMQP":"cpe:2.3:a:vmware:spring_advanced_message_queuing_protocol:*:*:*:*:*:*:*:*",
"Spring=Spring Boot":"cpe:2.3:a:vmware:spring_boot:*:*:*:*:*:*:*:*",
"Spring by VMware=Spring Cloud Config":"cpe:2.3:a:vmware:spring_cloud_config:*:*:*:*:*:*:*:*",
"Spring=Spring Cloud Contract":"cpe:2.3:a:vmware:spring_cloud_contract:*:*:*:*:*:*:*:*",
"Spring by VMware=Spring Cloud Data Flow":"cpe:2.3:a:vmware:spring_cloud_data_flow:*:*:*:*:*:*:*:*",
"Spring by VMware=Spring Cloud Netflix":"cpe:2.3:a:vmware:spring_cloud_netflix:*:*:*:*:*:*:*:*",
"Pivotal=Spring Cloud SSO Connector":"cpe:2.3:a:vmware:spring_cloud_sso_connector:*:*:*:*:*:*:*:*",
"Spring by VMware=Spring Cloud Task":"cpe:2.3:a:vmware:spring_cloud_task:*:*:*:*:*:*:*:*",
"Spring=Spring For Apache Kafka":"cpe:2.3:a:vmware:spring_for_apache_kafka:*:*:*:*:*:*:*:*",
"Spring=Spring for GraphQL":"cpe:2.3:a:vmware:spring_for_graphql:*:*:*:*:*:*:*:*",
"Spring by Pivotal=Spring Security":"cpe:2.3:a:vmware:spring_framework:*:*:*:*:*:*:*:*",
"Spring=Spring Framework":"cpe:2.3:a:vmware:spring_framework:*:*:*:*:*:*:*:*",
"Pivotal=Spring Integration Zip":"cpe:2.3:a:vmware:spring_integration_zip:*:*:*:*:*:*:*:*",
"N/A=Spring Security":"cpe:2.3:a:vmware:spring_security:*:*:*:*:*:*:*:*",
"Pivotal=VMware Tanzu Application Service for VMs":"cpe:2.3:a:vmware:tanzu_application_service_for_vms:*:*:*:*:*:*:*:*",
"VMware=VMware Tools":"cpe:2.3:a:vmware:tools:*:*:*:*:*:*:*:*",
"VMware=VMware vCenter Server":"cpe:2.3:a:vmware:vcenter_server:*:*:*:*:*:*:*:*",
"VMware=vCenter Server":"cpe:2.3:a:vmware:vcenter_server:*:*:*:*:*:*:*:*",
"VMware=VMware vCloud Director for Service Providers (vCD)":"cpe:2.3:a:vmware:vcloud_director:*:*:*:*:*:*:*:*",
"VMware=vRealize Automation":"cpe:2.3:a:vmware:vrealize_automation:*:*:*:*:*:*:*:*",
"VMware=VMware vRealize Log Insight":"cpe:2.3:a:vmware:vrealize_log_insight:*:*:*:*:*:*:*:*",
"VMware=vSphere Data Protection (VDP)":"cpe:2.3:a:vmware:vsphere_data_protection:*:*:*:*:*:*:*:*",
"VMware=Workstation":"cpe:2.3:a:vmware:workstation:*:*:*:*:*:*:*:*",
"vnotex=vnote":"cpe:2.3:a:vnote_project:vnote:*:*:*:*:*:*:*:*",
"VOICEYE=WSActiveBridgeES":"cpe:2.3:a:voiceye_wsactivebridgees_project:voiceye_wsactivebridges:*:*:*:*:*:*:*:*",
"voidtools=Everything":"cpe:2.3:a:voidtools:everything:*:*:*:*:*:*:*:*",
"vriteio=vriteio/vrite":"cpe:2.3:a:vrite:vrite:*:*:*:*:*:*:*:*",
"vyperlang=vyper":"cpe:2.3:a:vyperlang:vyper:*:*:*:*:*:*:*:*",
"yuda-lyu=yuda-lyu/w-zip":"cpe:2.3:a:w-zip_project:w-zip:*:*:*:*:*:*:*:*",
"panicsteve=w2wiki":"cpe:2.3:a:w2wiki_project:w2wiki:*:*:*:*:*:*:*:*",
"w3c=Unicorn":"cpe:2.3:a:w3:unicorn:*:*:*:*:*:*:*:*",
"World Wide Web Consortium (W3C)=CSS Validator":"cpe:2.3:a:w3c:css_validator:*:*:*:*:*:*:*:*",
"Wago=WAGO e!COCKPIT":"cpe:2.3:a:wago:e\!cockpit:*:*:*:*:*:*:*:*",
"Wago=WAGO e!Cockpit":"cpe:2.3:a:wago:e\!cockpit:*:*:*:*:*:*:*:*",
"wallabag=wallabag/wallabag":"cpe:2.3:a:wallabag:wallabag:*:*:*:*:*:*:*:*",
"xnx3=wangmarket":"cpe:2.3:a:wang.market:wangmarket:*:*:*:*:*:*:*:*",
"FeMiner=wms":"cpe:2.3:a:warehouse_management_system_project:warehouse_management_system:*:*:*:*:*:*:*:*",
"retke=Laggrons-Dumb-Cogs":"cpe:2.3:a:warnsystem_project:warnsystem:*:*:*:*:*:*:*:*",
"warp-tech=warpgate":"cpe:2.3:a:warpgate_project:warpgate:*:*:*:*:*:*:*:*",
"wasmCloud=wasmcloud-otp":"cpe:2.3:a:wasmcloud:host_runtime:*:*:*:*:*:*:*:*",
"Watchdog=Anti-Virus":"cpe:2.3:a:watchdog:anti-virus:*:*:*:*:*:*:*:*",
"wazuh=wazuh":"cpe:2.3:a:wazuh:wazuh:*:*:*:*:*:*:*:*",
"WBCE Team=WBCE CMS":"cpe:2.3:a:wbce:wbce_cms:*:*:*:*:*:*:*:*",
"WBCE=CMS":"cpe:2.3:a:wbce:wbce_cms:*:*:*:*:*:*:*:*",
"wbce=wbce/wbce_cms":"cpe:2.3:a:wbce:wbce_cms:*:*:*:*:*:*:*:*",
"WADE DIGITAL DESIGN CO, LTD.=FANTSY":"cpe:2.3:a:wddgroup:fantasy:*:*:*:*:*:*:*:*",
"WADE DIGITAL DESIGN CO, LTD.=FANTSY":"cpe:2.3:a:wddgroup:fantsy:*:*:*:*:*:*:*:*",
"ICS-CERT=WECON LeviStudioU":"cpe:2.3:a:we-con:levistudiou:*:*:*:*:*:*:*:*",
"WECON Technology Co., Ltd.=LeviStudioU":"cpe:2.3:a:we-con:levistudiou:*:*:*:*:*:*:*:*",
"WECON Technology Co., Ltd=LeviStudioU":"cpe:2.3:a:we-con:levistudiou:*:*:*:*:*:*:*:*",
"WECON=LeviStudioU":"cpe:2.3:a:we-con:levistudiou:*:*:*:*:*:*:*:*",
"WECON=PLC Editor":"cpe:2.3:a:we-con:plc_editor:*:*:*:*:*:*:*:*",
"Ncode=Ncep":"cpe:2.3:a:weather:ncode_ncep:*:*:*:*:*:*:*:*",
"weaveworks=weave":"cpe:2.3:a:weave:weave:*:*:*:*:*:*:*:*",
"weaveworks=weave-gitops":"cpe:2.3:a:weave:weave_gitops:*:*:*:*:*:*:*:*",
"weaveworks=Weave":"cpe:2.3:a:weave:weave_net:*:*:*:*:*:*:*:*",
"Weaver=e-cology":"cpe:2.3:a:weaver:e-cology:*:*:*:*:*:*:*:*",
"Weaver=E-Office":"cpe:2.3:a:weaver:e-office:*:*:*:*:*:*:*:*",
"Weaver=OA":"cpe:2.3:a:weaver:weaver_office_automation:*:*:*:*:*:*:*:*",
"SourceCodester=Web-Based Student Clearance System":"cpe:2.3:a:web-based_student_clearance_system_project:web-based_student_clearance_system:*:*:*:*:*:*:*:*",
"web2py=web2py":"cpe:2.3:a:web2py:web2py:*:*:*:*:*:*:*:*",
"craigk5n=craigk5n/webcalendar":"cpe:2.3:a:webcalendar_project:webcalendar:*:*:*:*:*:*:*:*",
"k5n.us=WebCalendar":"cpe:2.3:a:webcalendar_project:webcalendar:*:*:*:*:*:*:*:*",
"WHA=WHA Crossword (WordPress plugin)":"cpe:2.3:a:webhelpagency:wha_crossword:*:*:*:*:*:*:*:*",
"WHA=Word Search Puzzles game (WordPress plugin)":"cpe:2.3:a:webhelpagency:wha_wordsearch:*:*:*:*:*:*:*:*",
"Webklex=php-imap":"cpe:2.3:a:webklex:php-imap:*:*:*:*:*:*:*:*",
"Webkul=krayin crm":"cpe:2.3:a:webkul:krayin_crm:*:*:*:*:*:*:*:*",
"WeblateOrg=weblate":"cpe:2.3:a:weblate:weblate:*:*:*:*:*:*:*:*",
"dream0x01=weblogic-framework":"cpe:2.3:a:weblogic-framework_project:weblogic-framework:*:*:*:*:*:*:*:*",
"Webmin=Webmin":"cpe:2.3:a:webmin:webmin:*:*:*:*:*:*:*:*",
"webmin=webmin/webmin":"cpe:2.3:a:webmin:webmin:*:*:*:*:*:*:*:*",
"Chromium=libwebp":"cpe:2.3:a:webmproject:libwebp:*:*:*:*:*:*:*:*",
"LunarNight Laboratory=WebProxy":"cpe:2.3:a:webproxy_project:webproxy:*:*:*:*:*:*:*:*",
"Webroot=Secure Anywhere":"cpe:2.3:a:webroot:secureanywhere:*:*:*:*:*:*:*:*",
"webvendome=webvendome":"cpe:2.3:a:webvendome_project:webvendome:*:*:*:*:*:*:*:*",
"zwczou=WeChat SDK Python":"cpe:2.3:a:wechat_sdk_python_project:wechat_sdk_python:*:*:*:*:*:*:*:*",
"SourceCodester=Wedding Hall Booking System":"cpe:2.3:a:wedding_hall_booking_system_project:wedding_hall_booking_system:*:*:*:*:*:*:*:*",
"Weintek=EasyBuilder Pro":"cpe:2.3:a:weintek:easybuilder_pro:*:*:*:*:*:*:*:*",
"Weintek=EasyBuilder Pro cMT ":"cpe:2.3:a:weintek:easybuilder_pro:*:*:*:*:*:*:*:*",
"Weintek=Weincloud":"cpe:2.3:a:weintek:weincloud:*:*:*:*:*:*:*:*",
"WEKA=INTEREST Security Scanner":"cpe:2.3:a:weka:interest_security_scanner:*:*:*:*:*:*:*:*",
"Wekan Team=Wekan":"cpe:2.3:a:wekan_project:wekan:*:*:*:*:*:*:*:*",
"WellinTech=KingHistorian":"cpe:2.3:a:wellintech:kinghistorian:*:*:*:*:*:*:*:*",
"WEPA=Wepa Print Away":"cpe:2.3:a:wepanow:print_away:*:*:*:*:*:*:*:*",
"WESEEK, Inc.=GROWI":"cpe:2.3:a:weseek:growi:*:*:*:*:*:*:*:*",
"WESEEK, Inc.=GROWI (v4.2 Series)":"cpe:2.3:a:weseek:growi:*:*:*:*:*:*:*:*",
"WESEEK, Inc.=GROWI v4.2 Series":"cpe:2.3:a:weseek:growi:*:*:*:*:*:*:*:*",
"WESEEK, Inc.=GROWI v5 series and v4 series":"cpe:2.3:a:weseek:growi:*:*:*:*:*:*:*:*",
"weseek=weseek/growi":"cpe:2.3:a:weseek:growi:*:*:*:*:*:*:*:*",
"SanDisk=PrivateAccess":"cpe:2.3:a:westerndigital:sandisk_privateaccess:*:*:*:*:*:*:*:*",
"Western Digital=Sweet B Library":"cpe:2.3:a:westerndigital:sweet_b:*:*:*:*:*:*:*:*",
"Weston Embedded=uC-FTPs":"cpe:2.3:a:weston-embedded:uc-ftps:*:*:*:*:*:*:*:*",
"Wing FTP Server=Wing FTP Server":"cpe:2.3:a:wftpserver:wing_ftp_server:*:*:*:*:*:*:*:*",
"wger-project=wger-project/wger":"cpe:2.3:a:wger:wger:*:*:*:*:*:*:*:*",
"whaleal=IceFrog":"cpe:2.3:a:whaleal:icefrog:*:*:*:*:*:*:*:*",
"Facebook=WhatsApp Desktop":"cpe:2.3:a:whatsapp:whatsapp_desktop:*:*:*:*:*:*:*:*",
"Wanhu=ezOFFICE":"cpe:2.3:a:whir:ezoffice:*:*:*:*:*:*:*:*",
"unspecified=whohas":"cpe:2.3:a:whohas_project:whohas:*:*:*:*:*:*:*:*",
"Wikimedia=mediawiki-extensions-I18nTags":"cpe:2.3:a:wikimedia:mediawiki-extensions-i18ntags:*:*:*:*:*:*:*:*",
"Linbreux=wikmd":"cpe:2.3:a:wikmd_project:wikmd:*:*:*:*:*:*:*:*",
"SeppPenner=WindowsHello":"cpe:2.3:a:windowshello_project:windowshello:*:*:*:*:*:*:*:*",
"wintercms=winter":"cpe:2.3:a:wintercms:winter:*:*:*:*:*:*:*:*",
"embano1=wip":"cpe:2.3:a:wip_project:wip:*:*:*:*:*:*:*:*",
"wireapp=wire-avs":"cpe:2.3:a:wire:audio\,_video\,_and_signaling:*:*:*:*:*:*:*:*",
"wireapp=restund":"cpe:2.3:a:wire:restund:*:*:*:*:*:*:*:*",
"wireapp=wire-avs":"cpe:2.3:a:wire:wire-audio_video_signaling:*:*:*:*:*:*:*:*",
"wireapp=wire-server":"cpe:2.3:a:wire:wire-server:*:*:*:*:*:*:*:*",
"wireapp=wire-webapp":"cpe:2.3:a:wire:wire-webapp:*:*:*:*:*:*:*:*",
"wireapp=wire-desktop":"cpe:2.3:a:wire:wire:*:*:*:*:*:*:*:*",
"wireapp=wire-server":"cpe:2.3:a:wire:wire:*:*:*:*:*:*:*:*",
"wireapp=wire-server":"cpe:2.3:a:wire:wire_server:*:*:*:*:*:*:*:*",
"wiremock=wiremock":"cpe:2.3:a:wiremock:studio:*:*:*:*:*:*:*:*",
"Wireshark Foundation=Wireshark":"cpe:2.3:a:wireshark:wireshark:*:*:*:*:*:*:*:*",
"WiseCleaner=Wise Folder Hider":"cpe:2.3:a:wisecleaner:wise_folder_hider:*:*:*:*:*:*:*:*",
"Lespeed=WiseCleaner Wise Force Deleter":"cpe:2.3:a:wisecleaner:wise_force_deleter:*:*:*:*:*:*:*:*",
"Lespeed=WiseCleaner Wise System Monitor":"cpe:2.3:a:wisecleaner:wise_system_monitor:*:*:*:*:*:*:*:*",
"Wizkunde=SAMLBase":"cpe:2.3:a:wizkunde:samlbase:*:*:*:*:*:*:*:*",
" WJJ Software= InnoKB Server, InnoKB/Console":"cpe:2.3:a:wjjsoft:innokb:*:*:*:*:*:*:*:*",
"WonderLink=WL-Enq":"cpe:2.3:a:wl-enq_project:wl-enq:*:*:*:*:*:*:*:*",
"A3Sec =Wocu Monitoring":"cpe:2.3:a:wocu-monitoring:wocu_monitoring:*:*:*:*:*:*:*:*",
"wolfSSL=wolfSSL":"cpe:2.3:a:wolfssl:wolfssl:*:*:*:*:*:*:*:*",
"WonderCMS=2.6.0 and earlier":"cpe:2.3:a:wondercms:wondercms:*:*:*:*:*:*:*:*",
"woodpecker-ci=woodpecker":"cpe:2.3:a:woodpecker-ci:woodpecker:*:*:*:*:*:*:*:*",
"WoodyBells=Jtrim":"cpe:2.3:a:woodybells:jtrim:*:*:*:*:*:*:*:*",
"WoodyBells=WinShot":"cpe:2.3:a:woodybells:winshot:*:*:*:*:*:*:*:*",
"WordPress=Requests":"cpe:2.3:a:wordpress:requests:*:*:*:*:*:*:*:*",
"WordPress Foundation=WordPress":"cpe:2.3:a:wordpress:wordpress:*:*:*:*:*:*:*:*",
"WordPress.org=WordPress":"cpe:2.3:a:wordpress:wordpress:*:*:*:*:*:*:*:*",
"WordPress=WordPress":"cpe:2.3:a:wordpress:wordpress:*:*:*:*:*:*:*:*",
"John Brien=WordPress NextGen GalleryView":"cpe:2.3:a:wordpress_nextgen_galleryview_project:wordpress_nextgen_galleryview:*:*:*:*:*:*:*:*",
"unspecified=WoWonder":"cpe:2.3:a:wowonder:wowonder:*:*:*:*:*:*:*:*",
"wp-cli=wp-cli":"cpe:2.3:a:wp-cli:wp-cli:*:*:*:*:*:*:*:*",
"Unknown=WP Config File Editor":"cpe:2.3:a:wp_config_file_editor_project:wp_config_file_editor:*:*:*:*:*:*:*:*",
"Unknown=WP Custom Admin Interface":"cpe:2.3:a:wp_custom_admin_interface_project:wp_custom_admin_interface:*:*:*:*:*:*:*:*",
"Unknown=WP Custom Cursors ":"cpe:2.3:a:wp_custom_cursors_project:wp_custom_cursors:*:*:*:*:*:*:*:*",
"Unknown=WP Google Fonts":"cpe:2.3:a:wp_google_fonts_project:wp_google_fonts:*:*:*:*:*:*:*:*",
"Unknown=Remove Footer Credit":"cpe:2.3:a:wpchill:remove_footer_credit:*:*:*:*:*:*:*:*",
"WP Doctor=WooCommerce Login Redirect":"cpe:2.3:a:wpdoctor:woocommerce_login_redirect:*:*:*:*:*:*:*:*",
"Unknown=GeoDirectory":"cpe:2.3:a:wpgeodirectory:geodirectory:*:*:*:*:*:*:*:*",
"Unknown=WP Inventory Manager":"cpe:2.3:a:wpinventory:wp_inventory_manager:*:*:*:*:*:*:*:*",
"WPN-XM=Serverstack":"cpe:2.3:a:wpn-xm:wpn-xm:*:*:*:*:*:*:*:*",
"WPS=WPS Office":"cpe:2.3:a:wps:wps_office:*:*:*:*:*:*:*:*",
"parabirb=wrongthink":"cpe:2.3:a:wrongthink:wrongthink:*:*:*:*:*:*:*:*",
"birb-digital=wrongthink":"cpe:2.3:a:wrongthink_project:wrongthink:*:*:*:*:*:*:*:*",
"netristv=netristv/ws-scrcpy":"cpe:2.3:a:ws_scrcpy_project:ws_scrcpy:*:*:*:*:*:*:*:*",
"mar10=wsgidav":"cpe:2.3:a:wsgidav_project:wsgidav:*:*:*:*:*:*:*:*",
"WSO2=WSO2 API Manager":"cpe:2.3:a:wso2:api_manager:*:*:*:*:*:*:*:*",
"WSO2=carbon-registry":"cpe:2.3:a:wso2:carbon-registry:*:*:*:*:*:*:*:*",
"Snyk=WSO2 transport-http":"cpe:2.3:a:wso2:transport-http:*:*:*:*:*:*:*:*",
"WWBN=AVideo":"cpe:2.3:a:wwbn:avideo:*:*:*:*:*:*:*:*",
"X-WRT=luci":"cpe:2.3:a:x-wrt:luci:*:*:*:*:*:*:*:*",
"X.org=Server":"cpe:2.3:a:x.org:x_server:*:*:*:*:*:*:*:*",
"Twentysix26=x26-Cogs":"cpe:2.3:a:x26-cogs_project:x26-cogs:*:*:*:*:*:*:*:*",
"XACK, Inc.=XACK DNS":"cpe:2.3:a:xack:xack_dns:*:*:*:*:*:*:*:*",
"xArrow=xArrow SCADA":"cpe:2.3:a:xarrow:xarrow:*:*:*:*:*:*:*:*",
"shannah=Xataface":"cpe:2.3:a:xataface_project:xataface:*:*:*:*:*:*:*:*",
"brokercap=Bifrost":"cpe:2.3:a:xbifrost:bifrost:*:*:*:*:*:*:*:*",
"xcat2=xcat-core":"cpe:2.3:a:xcat_project:xcat:*:*:*:*:*:*:*:*",
"xdan=Jodit Editor":"cpe:2.3:a:xdsoft:jodit_editor:*:*:*:*:*:*:*:*",
"xerial=snappy-java":"cpe:2.3:a:xerial:snappy-java:*:*:*:*:*:*:*:*",
"nocodb=nocodb":"cpe:2.3:a:xgenecloud:nocodb:*:*:*:*:*:*:*:*",
"nocodb=nocodb/nocodb":"cpe:2.3:a:xgenecloud:nocodb:*:*:*:*:*:*:*:*",
"xhyve=xhyve":"cpe:2.3:a:xhyve_project:xhyve:*:*:*:*:*:*:*:*",
"xibosignage=xibo-cms":"cpe:2.3:a:xibosignage:xibo:*:*:*:*:*:*:*:*",
"ShinHer Information Co., LTD.=ShinHer StudyOnline System":"cpe:2.3:a:xinheinformation:xinhe_teaching_platform_system:*:*:*:*:*:*:*:*",
"XINJE=XD/E Series PLC Program Tool":"cpe:2.3:a:xinje:xd\/e_series_plc_program_tool:*:*:*:*:*:*:*:*",
"XINJE=XDPPro":"cpe:2.3:a:xinje:xdppro:*:*:*:*:*:*:*:*",
"Xintian=Smart Table Integrated Management System":"cpe:2.3:a:xintian_smart_table_integrated_management_system_project:xintian_smart_table_integrated_management_system:*:*:*:*:*:*:*:*",
"Hangzhou Xiongmai Technology Co., Ltd=XMeye P2P Cloud Server":"cpe:2.3:a:xiongmaitech:xmeye_p2p_cloud_server:*:*:*:*:*:*:*:*",
"unspecified=FastCMS":"cpe:2.3:a:xjd2020:fastcms:*:*:*:*:*:*:*:*",
"Yunjing=CMS":"cpe:2.3:a:xjyunjing:yunjing_content_management_system:*:*:*:*:*:*:*:*",
"Xlightftpd=Xlight FTP Server":"cpe:2.3:a:xlightftpd:xlight_ftp_server:*:*:*:*:*:*:*:*",
"XML-Sitemaps=Unlimited Sitemap Generator":"cpe:2.3:a:xml-sitemaps:unlimited_sitemap_generator:*:*:*:*:*:*:*:*",
"horazont=xmpp-http-upload":"cpe:2.3:a:xmpp-http-upload_project:xmpp-http-upload:*:*:*:*:*:*:*:*",
"Xoslab=Easy File Locker":"cpe:2.3:a:xoslab:easy_file_locker:*:*:*:*:*:*:*:*",
"Xpdf=Xpdf":"cpe:2.3:a:xpdfreader:xpdf:*:*:*:*:*:*:*:*",
"Shaoxing=Background Management System":"cpe:2.3:a:xsjczx:background_management_system:*:*:*:*:*:*:*:*",
"x-stream=xstream":"cpe:2.3:a:xstream_project:xstream:*:*:*:*:*:*:*:*",
"xstream=xstream":"cpe:2.3:a:xstream_project:xstream:*:*:*:*:*:*:*:*",
"Xunrui=CMS":"cpe:2.3:a:xunruicms:xunruicms:*:*:*:*:*:*:*:*",
"xwiki-contrib=application-admintools":"cpe:2.3:a:xwiki:admin_tools:*:*:*:*:*:*:*:*",
"xwikisas=application-collabora":"cpe:2.3:a:xwiki:application-collabora:*:*:*:*:*:*:*:*",
"xwiki-contrib=application-changerequest":"cpe:2.3:a:xwiki:change_request:*:*:*:*:*:*:*:*",
"xwiki=xwiki-commons":"cpe:2.3:a:xwiki:commons:*:*:*:*:*:*:*:*",
"xwikisas=identity-oauth":"cpe:2.3:a:xwiki:oauth_identity:*:*:*:*:*:*:*:*",
"xwiki-contrib=oidc":"cpe:2.3:a:xwiki:openid_connect:*:*:*:*:*:*:*:*",
"xwiki=xwiki-rendering":"cpe:2.3:a:xwiki:xwiki-rendering:*:*:*:*:*:*:*:*",
"xwiki-contrib=application-admintools":"cpe:2.3:a:xwiki:xwiki:*:*:*:*:*:*:*:*",
"xwiki=XWiki Platform":"cpe:2.3:a:xwiki:xwiki:*:*:*:*:*:*:*:*",
"xwiki=xwiki-commons":"cpe:2.3:a:xwiki:xwiki:*:*:*:*:*:*:*:*",
"xwiki=xwiki-platform":"cpe:2.3:a:xwiki:xwiki:*:*:*:*:*:*:*:*",
"xuexueli=xxl-rpc":"cpe:2.3:a:xxl-rpc_project:xxl-rpc:*:*:*:*:*:*:*:*",
"XYDAC=Ultimate Taxonomy Manager":"cpe:2.3:a:xydac:ultimate_taxonomy_manager:*:*:*:*:*:*:*:*",
"xzjie=cms":"cpe:2.3:a:xzjie_cms_project:xzjie_cms:*:*:*:*:*:*:*:*",
"devttys0=yaffshiv":"cpe:2.3:a:yaffshiv_project:yaffshiv:*:*:*:*:*:*:*:*",
"Verison Media=Athenz":"cpe:2.3:a:yahoo:athenz:*:*:*:*:*:*:*:*",
"yaml-rust=yaml-rust":"cpe:2.3:a:yaml-rust_project:yaml-rust:*:*:*:*:*:*:*:*",
"ClickHouse=ClickHouse":"cpe:2.3:a:yandex:clickhouse:*:*:*:*:*:*:*:*",
"yandex=clickhouse":"cpe:2.3:a:yandex:clickhouse:*:*:*:*:*:*:*:*",
"Yandex N.V.=Yandex Browser":"cpe:2.3:a:yandex:yandex_browser:*:*:*:*:*:*:*:*",
"Yandex N.V.=Yandex Browser for Desktop":"cpe:2.3:a:yandex:yandex_browser:*:*:*:*:*:*:*:*",
"Yappli, Inc.=Yappli":"cpe:2.3:a:yappli:yappli:*:*:*:*:*:*:*:*",
"yard=yard":"cpe:2.3:a:yardoc:yard:*:*:*:*:*:*:*:*",
"yarn=yarn":"cpe:2.3:a:yarnpkg:yarn:*:*:*:*:*:*:*:*",
"Yepas=Digital Yepas":"cpe:2.3:a:yepas:digital_yepas:*:*:*:*:*:*:*:*",
"YES24=YES24 PC VIEWER":"cpe:2.3:a:yes24:viewer_activex:*:*:*:*:*:*:*:*",
"nielsbasjes=yauaa":"cpe:2.3:a:yet_another_useragent_analyzer_project:yet_another_useragent_analyzer:*:*:*:*:*:*:*:*",
"yetiforcecompany=yetiforcecompany/yetiforcecrm":"cpe:2.3:a:yetiforce:yetiforce_customer_relationship_management:*:*:*:*:*:*:*:*",
"Yettiesoft=VestCert":"cpe:2.3:a:yettiesoft:vestcert:*:*:*:*:*:*:*:*",
"vova07=Yii2 FileAPI Widget":"cpe:2.3:a:yii2_fileapi_widget_project:yii2_fileapi_widget:*:*:*:*:*:*:*:*",
"yiisoft=yii2-authclient":"cpe:2.3:a:yiiframework:yii2-authclient:*:*:*:*:*:*:*:*",
"yiisoft=yii":"cpe:2.3:a:yiiframework:yii:*:*:*:*:*:*:*:*",
"yiisoft=yii2":"cpe:2.3:a:yiiframework:yii:*:*:*:*:*:*:*:*",
"yiisoft=yiisoft/yii2":"cpe:2.3:a:yiiframework:yii:*:*:*:*:*:*:*:*",
"YKM=YKM CRM":"cpe:2.3:a:ykmbilisim:ykm_crm:*:*:*:*:*:*:*:*",
"SourceCodester=Yoga Class Registration System":"cpe:2.3:a:yoga_class_registration_system_project:yoga_class_registration_system:*:*:*:*:*:*:*:*",
"Yoga Class Registration System=Yoga Class Registration System":"cpe:2.3:a:yoga_class_registration_system_project:yoga_class_registration_system:*:*:*:*:*:*:*:*",
"Yokogawa=WideField3":"cpe:2.3:a:yokogawa:widefield3:*:*:*:*:*:*:*:*",
"WonderLink=Yomi-Search":"cpe:2.3:a:yomi-search_project:yomi-search:*:*:*:*:*:*:*:*",
"Yontem Informatics=Vehicle Tracking System":"cpe:2.3:a:yontemizleme:vehicle_tracking_system:*:*:*:*:*:*:*:*",
"Yongyou=UFIDA-NC":"cpe:2.3:a:yonyou:ufida-nc:*:*:*:*:*:*:*:*",
"Yooslider=Yoo Slider     Image Slider & Video Slider (WordPress plugin)":"cpe:2.3:a:yooslider:yoo_slider:*:*:*:*:*:*:*:*",
"Centire=Yopify":"cpe:2.3:a:yopify:yopify:*:*:*:*:*:*:*:*",
"Yordam Bilgi Teknolojileri=Yordam Bilgi Teknolojileri":"cpe:2.3:a:yordam:library_automation_system:*:*:*:*:*:*:*:*",
"Yordam Informatics Systems=Yordam Library Information Document Automation Program":"cpe:2.3:a:yordam:library_automation_system:*:*:*:*:*:*:*:*",
"Yordam Information Technologies=Library Automation System":"cpe:2.3:a:yordam:library_automation_system:*:*:*:*:*:*:*:*",
"Yordam=MedasPro":"cpe:2.3:a:yordam:medaspro:*:*:*:*:*:*:*:*",
"SourceCodester=Young Entrepreneur E-Negosyo System":"cpe:2.3:a:young_entrepreneur_e-negosyo_system_project:young_entrepreneur_e-negosyo_system:*:*:*:*:*:*:*:*",
"yourls=yourls/yourls":"cpe:2.3:a:yourls:yourls:*:*:*:*:*:*:*:*",
"yt-dlp=yt-dlp":"cpe:2.3:a:yt-dlp_project:yt-dlp:*:*:*:*:*:*:*:*",
"YugaByte, Inc.=Yugabyte DB":"cpe:2.3:a:yugabyte:yugabytedb:*:*:*:*:*:*:*:*",
"YugabyteDB=Anywhere":"cpe:2.3:a:yugabyte:yugabytedb:*:*:*:*:*:*:*:*",
"YugabyteDB=YugabyteDB":"cpe:2.3:a:yugabyte:yugabytedb:*:*:*:*:*:*:*:*",
"YugabyteDB=YugabyteDB Anywhere":"cpe:2.3:a:yugabyte:yugabytedb:*:*:*:*:*:*:*:*",
"YugabyteDB=YugabyteDB Anywhere":"cpe:2.3:a:yugabyte:yugabytedb_managed:*:*:*:*:*:*:*:*",
"Project Worlds=Lawyer Management System":"cpe:2.3:a:yugeshverma:online_lawyer_management_system:*:*:*:*:*:*:*:*",
"Project Worlds=Online Lawyer Management System":"cpe:2.3:a:yugeshverma:online_lawyer_management_system:*:*:*:*:*:*:*:*",
"Project Worlds=Student Project Allocation System":"cpe:2.3:a:yugeshverma:student_project_allocation_system:*:*:*:*:*:*:*:*",
"YunoHost-Apps=transmission_ynh":"cpe:2.3:a:yunohost:transmission_ynh:*:*:*:*:*:*:*:*",
"Yunyou=CMS":"cpe:2.3:a:yunyou_cms_project:yunyou_cms:*:*:*:*:*:*:*:*",
"OpenRapid=RapidCMS":"cpe:2.3:a:yuque:rapidcms:*:*:*:*:*:*:*:*",
"yhz66=Sandbox":"cpe:2.3:a:yzh66:sandbox:*:*:*:*:*:*:*:*",
"Palantir=com.palantir.foundry:foundry-frontend":"cpe:2.3:a:zabbix:frontend:*:*:*:*:*:*:*:*",
"Zabbix=Zabbix":"cpe:2.3:a:zabbix:frontend:*:*:*:*:*:*:*:*",
"Zabbix=Zabbix":"cpe:2.3:a:zabbix:zabbix-agent2:*:*:*:*:*:*:*:*",
"Zabbix=Zabbix":"cpe:2.3:a:zabbix:zabbix:*:*:*:*:*:*:*:*",
"Zammad GmbH=Zammad":"cpe:2.3:a:zammad:zammad:*:*:*:*:*:*:*:*",
"zediious=raptor-web":"cpe:2.3:a:zediious:raptor-web:*:*:*:*:*:*:*:*",
"zeit=next.js":"cpe:2.3:a:zeit:next.js:*:*:*:*:*:*:*:*",
"morontt=zend-blog-number-2":"cpe:2.3:a:zend-blog-2_project:zend-blog-2:*:*:*:*:*:*:*:*",
"Zenphoto=Zenphoto":"cpe:2.3:a:zenphoto:zenphoto:*:*:*:*:*:*:*:*",
"zenstruck=collection":"cpe:2.3:a:zenstruck:collection:*:*:*:*:*:*:*:*",
"Zero-Channel BBS Plus Developers=Zero-channel BBS Plus":"cpe:2.3:a:zero-channel_plus_project:zero-channel_plus:*:*:*:*:*:*:*:*",
"Zerocoin=libzerocoin":"cpe:2.3:a:zerocoin:libzerocoin:*:*:*:*:*:*:*:*",
"Hendrik Erz=Zettlr":"cpe:2.3:a:zettlr:zettlr:*:*:*:*:*:*:*:*",
"ZhongFuCheng3y=Austin":"cpe:2.3:a:zhongfucheng3y:austin:*:*:*:*:*:*:*:*",
"Zimbra=zm-ajax":"cpe:2.3:a:zimbra:zm-ajax:*:*:*:*:*:*:*:*",
"zinc=zinc":"cpe:2.3:a:zinclabs:zinc:*:*:*:*:*:*:*:*",
"zip4j=zip4j":"cpe:2.3:a:zip4j_project:zip4j:*:*:*:*:*:*:*:*",
"zitadel=zitadel":"cpe:2.3:a:zitadel:zitadel:*:*:*:*:*:*:*:*",
"alagrede=alagrede/znote-app":"cpe:2.3:a:znote:znote:*:*:*:*:*:*:*:*",
"ManageEngine=Desktop Central":"cpe:2.3:a:zohocorp:manageengine_desktop_central:*:*:*:*:*:*:*:*",
"ZoneMinder=zoneminder":"cpe:2.3:a:zoneminder:zoneminder:*:*:*:*:*:*:*:*",
"Zoom Video Communications, Inc.=CleanZoom":"cpe:2.3:a:zoom:cleanzoom:*:*:*:*:*:*:*:*",
"Zoom Video Communications Inc=Zoom On-Premise Meeting Connector MMR":"cpe:2.3:a:zoom:meeting_connector:*:*:*:*:*:*:*:*",
"Zoom Video Communications Inc=Zoom On-Premise Meeting Connector Zone Controller (ZC)":"cpe:2.3:a:zoom:meeting_connector:*:*:*:*:*:*:*:*",
"Zoom Video Communications, Inc.=Zoom Meeting SDK":"cpe:2.3:a:zoom:meeting_sdk:*:*:*:*:*:*:*:*",
"Zoom Video Communications Inc=On-Premise Meeting Connectors":"cpe:2.3:a:zoom:on-premise_meeting_connector_multimedia_router:*:*:*:*:*:*:*:*",
"Zoom Video Communications Inc=Zoom (for Android, iOS, Linux, macOS, and Windows) clients before version 5.13.5":"cpe:2.3:a:zoom:zoom:*:*:*:*:*:*:*:*",
"Zoom Video Communications Inc=Zoom On-Premise Meeting Connector MMR":"cpe:2.3:a:zoom:zoom_on-premise_meeting_connector_mmr:*:*:*:*:*:*:*:*",
"Zoom Video Communications, Inc.=Zoom Client SDK":"cpe:2.3:a:zoom:zoom_software_development_kit:*:*:*:*:*:*:*:*",
"zopefoundation=AccessControl":"cpe:2.3:a:zope:accesscontrol:*:*:*:*:*:*:*:*",
"zopefoundation=Products.CMFCore":"cpe:2.3:a:zope:products.cmfcore:*:*:*:*:*:*:*:*",
"zopefoundation=Products.GenericSetup":"cpe:2.3:a:zope:products.genericsetup:*:*:*:*:*:*:*:*",
"zopefoundation=Products.PluggableAuthService":"cpe:2.3:a:zope:products.pluggableauthservice:*:*:*:*:*:*:*:*",
"zopefoundation=RestrictedPython":"cpe:2.3:a:zope:restrictedpython:*:*:*:*:*:*:*:*",
"zopefoundation=Products.SQLAlchemyDA":"cpe:2.3:a:zope:sqlalchemyda:*:*:*:*:*:*:*:*",
"zopefoundation=Zope":"cpe:2.3:a:zope:zope:*:*:*:*:*:*:*:*",
"Zscaler=ZIA":"cpe:2.3:a:zscaler:secure_internet_and_saas_access:*:*:*:*:*:*:*:*",
"Zscaler=ZIA Admin Portal":"cpe:2.3:a:zscaler:zscaler_internet_access_admin_portal:*:*:*:*:*:*:*:*",
"zstackio=zstack":"cpe:2.3:a:zstack:rest_api:*:*:*:*:*:*:*:*",
"zstackio=zstack":"cpe:2.3:a:zstack:zstack:*:*:*:*:*:*:*:*",
"ZTE=ZXCLOUD GoldenData VAP":"cpe:2.3:a:zte:zxcloud_goldendata_vap:*:*:*:*:*:*:*:*",
"ZTE=ZXIN10-European region":"cpe:2.3:a:zte:zxin10:*:*:*:*:*:*:*:*",
"zulip=zulip":"cpe:2.3:a:zulip:zulip:*:*:*:*:*:*:*:*",
"zulip=zulip/zulip":"cpe:2.3:a:zulip:zulip:*:*:*:*:*:*:*:*",
"Zulip=Zulip Server":"cpe:2.3:a:zulip:zulip_server:*:*:*:*:*:*:*:*",
"zulip=zulip":"cpe:2.3:a:zulip:zulip_server:*:*:*:*:*:*:*:*",
"zyantific=zydis":"cpe:2.3:a:zyantific:zydis:*:*:*:*:*:*:*:*",
"scrapy-plugins=scrapy-splash":"cpe:2.3:a:zyte:scrapy-splash:*:*:*:*:*:*:*:*",
"Zyxel=Zyxel AP Configurator (ZAC)":"cpe:2.3:a:zyxel:zyxel_ap_configurator:*:*:*:*:*:*:*:*",
"zzcms=zzcms":"cpe:2.3:a:zzcms:zzcms:*:*:*:*:*:*:*:*",
"zzcms=zzmcms":"cpe:2.3:a:zzcms:zzmcms:*:*:*:*:*:*:*:*",
"AMD=Processors":"cpe:2.3:h:amd:*:*:*:*:*:*:*:*:*",
"AMD=AMD Processors":"cpe:2.3:h:amd:cpu:*:*:*:*:*:*:*:*",
"Apple=iPhone":"cpe:2.3:h:apple:iphone_3gs:*:*:*:*:*:*:*:*",
"Avaya=Avaya Aura   System Platform":"cpe:2.3:h:avaya:avaya_aura_system_platform:*:*:*:*:*:*:*:*",
"Avaya=Session Border Controller for Enterprise":"cpe:2.3:h:avaya:session_border_controller_for_enterprise:*:*:*:*:*:*:*:*",
"Cisco Systems, Inc.=Web Security Appliance":"cpe:2.3:h:cisco:web_security_appliance:*:*:*:*:*:*:*:*",
"Dell=PowerStore":"cpe:2.3:h:dell:emc_powerstore:*:*:*:*:*:*:*:*",
"Dell=Dell EMC Repository Manager (DRM)":"cpe:2.3:h:dell:emc_repository_manager:*:*:*:*:*:*:*:*",
"hikvision=DS-2DE4xxxW,DS-2DE5xxxW,DS-2DE7xxxW":"cpe:2.3:h:hikvision:ip_cameras:*:*:*:*:*:*:*:*",
"Microsoft=Microsoft Wireless Keyboard 850":"cpe:2.3:h:microsoft:wireless_keyboard_850:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=unspecified":"cpe:2.3:h:qualcomm:qualcomm:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Mobile Devices":"cpe:2.3:h:samsung:exynos:*:*:*:*:*:*:*:*",
"TOYOTA MOTOR CORPORATION=DCU (Display Control Unit)":"cpe:2.3:h:toyota:display_control_unit:*:*:*:*:*:*:*:*",
"IEEE=Standard":"cpe:2.3:o:-:-:*:*:*:*:*:*:*:*",
"Dassault Syst  mes=SIMULIA 3DOrchestrate":"cpe:2.3:o:3ds:3dexperience:*:*:*:*:*:*:*:*",
"ACTi Corporation=ACTi D, B, I, and E series cameras":"cpe:2.3:o:acti:camera_firmware:*:*:*:*:*:*:*:*",
"AMD=AMD Processors":"cpe:2.3:o:amd:amd_generic_encapsulated_software_architecture:*:*:*:*:*:*:*:*",
" AMD=Ryzen    Master":"cpe:2.3:o:amd:ryzen_master:*:*:*:*:*:*:*:*",
"AMI=AptioV":"cpe:2.3:o:ami:aptio_v:*:*:*:*:*:*:*:*",
"AMI=MegaRAC_SPx":"cpe:2.3:o:ami:megarac_sp-x:*:*:*:*:*:*:*:*",
"Apple=AirPods Firmware Update E":"cpe:2.3:o:apple:airpods_firmware:*:*:*:*:*:*:*:*",
"Apple=iOS":"cpe:2.3:o:apple:iphone_os:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=Telephony":"cpe:2.3:o:apple:iphone_os:*:*:*:*:*:*:*:*",
"Apple=macOS":"cpe:2.3:o:apple:mac_os_x:*:*:*:*:*:*:*:*",
"Apple=macOS":"cpe:2.3:o:apple:macos:*:*:*:*:*:*:*:*",
"Apple=macOS":"cpe:2.3:o:apple:macos_server:*:*:*:*:*:*:*:*",
"Apple=tvOS":"cpe:2.3:o:apple:tvos:*:*:*:*:*:*:*:*",
"Apple=watchOS":"cpe:2.3:o:apple:watchos:*:*:*:*:*:*:*:*",
"Arista Networks=Arista EOS":"cpe:2.3:o:arista:eos:*:*:*:*:*:*:*:*",
"Arista Networks=EOS":"cpe:2.3:o:arista:eos:*:*:*:*:*:*:*:*",
"ARM=CMSIS RTOS2":"cpe:2.3:o:arm:cmsis-rtos:*:*:*:*:*:*:*:*",
"ARM=mbed":"cpe:2.3:o:arm:mbed:*:*:*:*:*:*:*:*",
"ASUSTOR=ADM":"cpe:2.3:o:asustor:data_master:*:*:*:*:*:*:*:*",
"Tenable=ASUSTOR Data Master":"cpe:2.3:o:asustor:data_master:*:*:*:*:*:*:*:*",
"Brocade=Brocade Fabric OS":"cpe:2.3:o:broadcom:brocade_fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade=Fabric OS":"cpe:2.3:o:broadcom:brocade_fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade Communications Systems, Inc.=Brocade Fabric OS":"cpe:2.3:o:broadcom:fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade=Brocade Fabric OS":"cpe:2.3:o:broadcom:fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade=Brocade Fabric OS ":"cpe:2.3:o:broadcom:fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade=Fabric OS":"cpe:2.3:o:broadcom:fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade=Fabric OS ":"cpe:2.3:o:broadcom:fabric_operating_system:*:*:*:*:*:*:*:*",
"Brocade=Fabric OS":"cpe:2.3:o:brocade:fabric_operating_system:*:*:*:*:*:*:*:*",
"Canonical=Ubuntu Kernel":"cpe:2.3:o:canonical:ubuntu_linux:*:*:*:*:*:*:*:*",
"Canonical=Ubuntu Server":"cpe:2.3:o:canonical:ubuntu_linux:*:*:*:*:*:*:*:*",
"Cesanta Software=Mongoose-OS":"cpe:2.3:o:cesanta:mongoose_os:*:*:*:*:*:*:*:*",
"Cisco=Cisco Aironet Access Point Software ":"cpe:2.3:o:cisco:access_points:*:*:*:*:*:*:*:*",
"Cisco=Cisco Adaptive Security Appliance (ASA) Software ":"cpe:2.3:o:cisco:adaptive_security_appliance_software:*:*:*:*:*:*:*:*",
"Cisco=Cisco Aironet Access Points ":"cpe:2.3:o:cisco:aironet_access_points:*:*:*:*:*:*:*:*",
"Cisco=Cisco Email Security Appliance (ESA) ":"cpe:2.3:o:cisco:asyncos:*:*:*:*:*:*:*:*",
"Cisco=Cisco Web Security Appliance (WSA) ":"cpe:2.3:o:cisco:asyncos:*:*:*:*:*:*:*:*",
"Cisco=Cisco Email Security Appliance (ESA) ":"cpe:2.3:o:cisco:email_security_appliance_firmware:*:*:*:*:*:*:*:*",
"Cisco=Cisco HyperFlex HX Data Platform":"cpe:2.3:o:cisco:hyperflex_hx_data_platform:*:*:*:*:*:*:*:*",
"Cisco=Cisco HyperFlex HX-Series ":"cpe:2.3:o:cisco:hyperflex_hx_data_platform:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS ":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS 12.2(15)B":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS 15.2(5a)E":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS Software":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software ":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 3.11.1S":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 3.16.8S":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 3.2.11aSG":"cpe:2.3:o:cisco:ios:*:*:*:*:*:*:*:*",
"Cisco=Cisco Catalyst 6800 Series Switches":"cpe:2.3:o:cisco:ios_rom_monitor:*:*:*:*:*:*:*:*",
"Cisco=Cisco Adaptive Security Appliance (ASA) Software ":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS ":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS Software":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software ":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.1.1":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.10.1":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.11.1":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.2.2":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.3.1":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.4.1":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.6.1":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 16.9.4":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 3.2.0SG":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 3.2.11aSG":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE Software 3.8.0S":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN Solution ":"cpe:2.3:o:cisco:ios_xe:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XE SD-WAN Software ":"cpe:2.3:o:cisco:ios_xe_sd-wan:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XR Software":"cpe:2.3:o:cisco:ios_xr:*:*:*:*:*:*:*:*",
"Cisco=Cisco IOS XR Software ":"cpe:2.3:o:cisco:ios_xr:*:*:*:*:*:*:*:*",
"Cisco=Cisco Network Convergence System 1000 Series ":"cpe:2.3:o:cisco:ios_xr:*:*:*:*:*:*:*:*",
"Cisco=Cisco NX-OS Software":"cpe:2.3:o:cisco:nx-os:*:*:*:*:*:*:*:*",
"Cisco=Cisco NX-OS Software for Nexus 3000 Series 7.0(3)I7(3)":"cpe:2.3:o:cisco:nx-os:*:*:*:*:*:*:*:*",
"Cisco=Cisco Prime Collaboration Assurance ":"cpe:2.3:o:cisco:prime_collaboration_assurance:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN Solution ":"cpe:2.3:o:cisco:sd-wan_firmware:*:*:*:*:*:*:*:*",
"Cisco=Cisco SD-WAN vManage ":"cpe:2.3:o:cisco:sd-wan_firmware:*:*:*:*:*:*:*:*",
"Cisco=Cisco vManage Software ":"cpe:2.3:o:cisco:sd-wan_firmware:*:*:*:*:*:*:*:*",
"Cisco=Cisco ASR 5000 Series Software ":"cpe:2.3:o:cisco:staros:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Contact Center Express ":"cpe:2.3:o:cisco:unified_intelligence_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Unified Intelligence Center ":"cpe:2.3:o:cisco:unified_intelligence_center:*:*:*:*:*:*:*:*",
"Cisco=Cisco Video Surveillance 7000 Series IP Cameras ":"cpe:2.3:o:cisco:video_surveillance_7000_ip_camera_firmware:*:*:*:*:*:*:*:*",
"Cisco=Cisco Wireless LAN Controller (WLC) ":"cpe:2.3:o:cisco:wireless_lan_controller_software:*:*:*:*:*:*:*:*",
"contiki-ng=contiki-ng":"cpe:2.3:o:contiki-ng:contiki-ng:*:*:*:*:*:*:*:*",
"DD-WRT=DD-WRT":"cpe:2.3:o:dd-wrt:dd-wrt:*:*:*:*:*:*:*:*",
"Debian=BIND9":"cpe:2.3:o:debian:debian_linux:*:*:*:*:*:*:*:*",
"Dell=CPG BIOS":"cpe:2.3:o:dell:cpg_bios:*:*:*:*:*:*:*:*",
"Dell=dbutil":"cpe:2.3:o:dell:dbutildrv2.sys_firmware:*:*:*:*:*:*:*:*",
"Dell=Integrated Dell Remote Access Controller (iDRAC)":"cpe:2.3:o:dell:emc_idrac9_firmware:*:*:*:*:*:*:*:*",
"Dell EMC=Dell EMC Networking OS10":"cpe:2.3:o:dell:emc_networking_os10:*:*:*:*:*:*:*:*",
"Dell=PowerScale OneFS":"cpe:2.3:o:dell:emc_powerscale_onefs:*:*:*:*:*:*:*:*",
"Dell=Enterprise SONiC OS":"cpe:2.3:o:dell:enterprise_sonic_distribution:*:*:*:*:*:*:*:*",
"Dell=Enterprise SONiC OS":"cpe:2.3:o:dell:enterprise_sonic_os:*:*:*:*:*:*:*:*",
"Dell=Integrated Dell Remote Access Controller (iDRAC)":"cpe:2.3:o:dell:idrac8_firmware:*:*:*:*:*:*:*:*",
"Dell EMC=iDRAC":"cpe:2.3:o:dell:idrac9_firmware:*:*:*:*:*:*:*:*",
"Dell EMC=iDRAC9":"cpe:2.3:o:dell:idrac9_firmware:*:*:*:*:*:*:*:*",
"Dell=Integrated Dell Remote Access Controller (iDRAC)":"cpe:2.3:o:dell:idrac9_firmware:*:*:*:*:*:*:*:*",
"Dell=Dell Networking OS10":"cpe:2.3:o:dell:networking_os10:*:*:*:*:*:*:*:*",
"Dell=Networking OS":"cpe:2.3:o:dell:networking_os10:*:*:*:*:*:*:*:*",
"Dell=CPG SW":"cpe:2.3:o:dell:os_recovery_image_for_microsoft_windows_10:*:*:*:*:*:*:*:*",
"Dell=Dell OS Recovery Tool":"cpe:2.3:o:dell:os_recovery_tool:*:*:*:*:*:*:*:*",
"Dell=PowerScale OneFS":"cpe:2.3:o:dell:powerscale_onefs:*:*:*:*:*:*:*:*",
"Dell=PowerStore":"cpe:2.3:o:dell:powerstoreos:*:*:*:*:*:*:*:*",
"Dell=Dell Networking OS10":"cpe:2.3:o:dell:smartfabric_os10:*:*:*:*:*:*:*:*",
"Delta Electronics=PMSoft":"cpe:2.3:o:deltaww:pmsoft:*:*:*:*:*:*:*:*",
"elvexys=ISOS":"cpe:2.3:o:elvexys:isos_firmware:*:*:*:*:*:*:*:*",
"F5=F5OS-A":"cpe:2.3:o:f5:f5os-a:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=FortiOS":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=Fortinet FortiOS":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=FortiGate":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=FortiOS":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiOS":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiOS and FortiProxy":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiOS, FortiProxy":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiOS, fortiADC":"cpe:2.3:o:fortinet:fortios:*:*:*:*:*:*:*:*",
"Fortinet=FortiPAM":"cpe:2.3:o:fortinet:fortipam:*:*:*:*:*:*:*:*",
"Fortinet=FortiRecorder":"cpe:2.3:o:fortinet:fortirecorder_firmware:*:*:*:*:*:*:*:*",
"Fortinet=Fortinet FortiSwitch":"cpe:2.3:o:fortinet:fortiswitch:*:*:*:*:*:*:*:*",
"Fortinet, Inc.=Fortinet FortiWLC-SD":"cpe:2.3:o:fortinet:fortiwlc-sd:*:*:*:*:*:*:*:*",
"FreeBSD=FreeBSD":"cpe:2.3:o:freebsd:freebsd:*:*:*:*:*:*:*:*",
"FreshTomato=FreshTomato":"cpe:2.3:o:freshtomato:freshtomato:*:*:*:*:*:*:*:*",
"Garuda Linux=Garuda Linux":"cpe:2.3:o:garudalinux:garuda_linux:*:*:*:*:*:*:*:*",
"Gigamon=GigaVUE-FM":"cpe:2.3:o:gigamon:gigavue-os:*:*:*:*:*:*:*:*",
"abode systems, inc.=iota All-In-One Security Kit":"cpe:2.3:o:goabode:iota_all-in-one_security_kit_firmware:*:*:*:*:*:*:*:*",
"*** n/a ***=Android":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Android=Android":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Google Inc.=Android":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Google=Android":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"NVIDIA=Tegra TLK Widevine Trust Application":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Nvidia Corporation=Android":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Nvidia Corporation=GPU Display Driver":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Nvidia Corporation=Pixel":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=All Qualcomm products":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=Android for MSM, Firefox OS for MSM, QRD Android":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Qualcomm, Inc.=Snapdragon IoT, Snapdragon Mobile":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Mobile Devices":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Mobile Devices ":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Mobile devices":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Samsung Mobile=SamsungContacts":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Samsung=Android OS":"cpe:2.3:o:google:android:*:*:*:*:*:*:*:*",
"Google LLC=Fuchsia":"cpe:2.3:o:google:fuchsia:*:*:*:*:*:*:*:*",
"Google LLC=Fuchsia Kernel":"cpe:2.3:o:google:fuchsia:*:*:*:*:*:*:*:*",
"Honor=Magic OS":"cpe:2.3:o:hihonor:magic_os:*:*:*:*:*:*:*:*",
"Honor=Magic OS ":"cpe:2.3:o:hihonor:magic_os:*:*:*:*:*:*:*:*",
"Honor=Magic UI":"cpe:2.3:o:hihonor:magic_ui:*:*:*:*:*:*:*:*",
"Honor=com.hihonor.phoneservice ":"cpe:2.3:o:hihonor:phoneservice:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=Aruba AirWave Glass":"cpe:2.3:o:hp:aruba_airwave_glass:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=ArubaOS":"cpe:2.3:o:hp:arubaos:*:*:*:*:*:*:*:*",
"HP Inc.=HP FutureSmart":"cpe:2.3:o:hp:futuresmart_5:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise (HPE)=HP-UX":"cpe:2.3:o:hp:hp-ux:*:*:*:*:*:*:*:*",
"HP Inc.=HP ThinPro":"cpe:2.3:o:hp:thinpro:*:*:*:*:*:*:*:*",
"HP=ThinPro Linux":"cpe:2.3:o:hp:thinpro:*:*:*:*:*:*:*:*",
"Hewlett Packard Enterprise=HPE 3PAR Service Processors":"cpe:2.3:o:hpe:3par_service_provider:*:*:*:*:*:*:*:*",
"Huawei= EMUI":"cpe:2.3:o:huawei:emui:*:*:*:*:*:*:*:*",
"Huawei=EMUI":"cpe:2.3:o:huawei:emui:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=FusionSphere":"cpe:2.3:o:huawei:fusionsphere:*:*:*:*:*:*:*:*",
"Huawei Technologies Co., Ltd.=FusionSphere OpenStack":"cpe:2.3:o:huawei:fusionsphere_openstack:*:*:*:*:*:*:*:*",
"Huawei=HarmonyOS":"cpe:2.3:o:huawei:harmonyos:*:*:*:*:*:*:*:*",
"IBM Corporation=AIX":"cpe:2.3:o:ibm:aix:*:*:*:*:*:*:*:*",
"IBM=AIX":"cpe:2.3:o:ibm:aix:*:*:*:*:*:*:*:*",
"IBM=Navigator for i":"cpe:2.3:o:ibm:i:*:*:*:*:*:*:*:*",
"IBM=i":"cpe:2.3:o:ibm:i:*:*:*:*:*:*:*:*",
"IBM=PowerVM Hypervisor":"cpe:2.3:o:ibm:powervm:*:*:*:*:*:*:*:*",
"IBM=Power FW":"cpe:2.3:o:ibm:powervm_hypervisor:*:*:*:*:*:*:*:*",
"IBM=PowerVM Hypervisor":"cpe:2.3:o:ibm:powervm_hypervisor:*:*:*:*:*:*:*:*",
"IBM=Security Access Manager":"cpe:2.3:o:ibm:security_access_manager_9.0_firmware:*:*:*:*:*:*:*:*",
"iCatch Inc.=DVR firmware":"cpe:2.3:o:icatchinc:dvr_firmware:*:*:*:*:*:*:*:*",
"IceWhaleTech=CasaOS":"cpe:2.3:o:icewhale:casaos:*:*:*:*:*:*:*:*",
"Intel Corporation=Active Mangement Technology":"cpe:2.3:o:intel:active_management_technology_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Active Mangement Technology, Intel Small Business Technology, Intel Standard Manageability":"cpe:2.3:o:intel:active_management_technology_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) CSME, Server Platform Services, Trusted Execution Engine and Intel(R) Active Management Technology":"cpe:2.3:o:intel:active_management_technology_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) CSME, Server Platform Services, Trusted Execution Engine and Intel(R) Active Management Technology":"cpe:2.3:o:intel:converged_security_management_engine_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel Graphics Driver":"cpe:2.3:o:intel:graphics_driver:*:*:*:*:*:*:*:*",
"Intel Corporation=Smart Sound Technology":"cpe:2.3:o:intel:intel_smart_sound_technology:*:*:*:*:*:*:*:*",
"Intel Corporation=Manageability Engine":"cpe:2.3:o:intel:manageability_engine_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel NUC Firmware Kits":"cpe:2.3:o:intel:next_unit_of_computing_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Intel(R) CSME, Server Platform Services, Trusted Execution Engine and Intel(R) Active Management Technology":"cpe:2.3:o:intel:server_platform_services_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Server Platform Services":"cpe:2.3:o:intel:server_platform_services_firmware:*:*:*:*:*:*:*:*",
"Intel Corporation=Trusted Execution Engine":"cpe:2.3:o:intel:trusted_execution_engine_firmware:*:*:*:*:*:*:*:*",
"unspecified=iPXE":"cpe:2.3:o:ipxe:ipxe:*:*:*:*:*:*:*:*",
"Johnson Controls=OpenBlue Enterprise Manager Data Collector":"cpe:2.3:o:johnsoncontrols:openblue_enterprise_manager_data_collector:*:*:*:*:*:*:*:*",
"Johnson Controls=VideoEdge":"cpe:2.3:o:johnsoncontrols:videoedge:*:*:*:*:*:*:*:*",
"Sensormatic Electronics, a subsidiary of Johnson Controls, Inc.=VideoEdge":"cpe:2.3:o:johnsoncontrols:videoedge:*:*:*:*:*:*:*:*",
"Joyent=Joyent SmartOS":"cpe:2.3:o:joyent:smartos:*:*:*:*:*:*:*:*",
"Juniper Networks=Junos OS":"cpe:2.3:o:juniper:junos:*:*:*:*:*:*:*:*",
"Juniper Networks=Junos OS Evolved":"cpe:2.3:o:juniper:junos_os_evolved:*:*:*:*:*:*:*:*",
"Juniper Networks=Junos Space":"cpe:2.3:o:juniper:junos_space:*:*:*:*:*:*:*:*",
"Juniper Networks=ScreenOS":"cpe:2.3:o:juniper:netscreen_screenos:*:*:*:*:*:*:*:*",
"Juniper Networks=ScreenOS":"cpe:2.3:o:juniper:screenos:*:*:*:*:*:*:*:*",
" LF-Edge, Zededa=EVE OS":"cpe:2.3:o:lfedge:eve:*:*:*:*:*:*:*:*",
"Linaro/OP-TEE=OP-TEE":"cpe:2.3:o:linaro:op-tee:*:*:*:*:*:*:*:*",
"LineageOS=LineageOS":"cpe:2.3:o:lineageos:lineageos:*:*:*:*:*:*:*:*",
"Flexera Software LLC=Linux Kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Google Inc.=Android":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux Kernel=Kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux Kernel=Linux Kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux Kernel=kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux=Kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux=Linux Kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux=Linux kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Linux=kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Nvidia Corporation=Android":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Red Hat=kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"RedHat=kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"The Linux Kernel Organization=linux":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"linux=linux_kernel":"cpe:2.3:o:linux:linux_kernel:*:*:*:*:*:*:*:*",
"Samsung Mobile=Tizen wearable devices":"cpe:2.3:o:linux:tizen:*:*:*:*:*:*:*:*",
" LF-Edge, Zededa=EVE OS":"cpe:2.3:o:linuxfoundation:edge_virtualization_engine:*:*:*:*:*:*:*:*",
"The Linux Foundation=ONOS":"cpe:2.3:o:linuxfoundation:open_network_operating_system:*:*:*:*:*:*:*:*",
"The Linux Foundation=ONOS SDN Controller":"cpe:2.3:o:linuxfoundation:open_network_operating_system:*:*:*:*:*:*:*:*",
"Circle Media=Circle":"cpe:2.3:o:meetcircle:circle_with_disney_firmware:*:*:*:*:*:*:*:*",
"Microsoft=Azure DevOps Server":"cpe:2.3:o:microsoft:azure_devops_server:*:*:*:*:*:*:*:*",
"Microsoft=Azure Real Time Operating System":"cpe:2.3:o:microsoft:azure_real_time_operating_system:*:*:*:*:*:*:*:*",
"Microsoft=Azure RTOS GUIX Studio":"cpe:2.3:o:microsoft:azure_real_time_operating_system_guix_studio:*:*:*:*:*:*:*:*",
"Microsoft=Azure Real Time Operating System":"cpe:2.3:o:microsoft:azure_rtos:*:*:*:*:*:*:*:*",
"azure-rtos=filex":"cpe:2.3:o:microsoft:azure_rtos_filex:*:*:*:*:*:*:*:*",
"azure-rtos=netxduo":"cpe:2.3:o:microsoft:azure_rtos_netx_duo:*:*:*:*:*:*:*:*",
"azure-rtos=threadx":"cpe:2.3:o:microsoft:azure_rtos_threadx:*:*:*:*:*:*:*:*",
"azure-rtos=usbx":"cpe:2.3:o:microsoft:azure_rtos_usbx:*:*:*:*:*:*:*:*",
"Microsoft=Self-extracting archive files created by IExpress bundled with Microsoft Windows":"cpe:2.3:o:microsoft:windows:*:*:*:*:*:*:*:*",
"Microsoft=The installer of Visual C++ Redistributable":"cpe:2.3:o:microsoft:windows:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Win32k":"cpe:2.3:o:microsoft:windows_10:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Windows Control Flow Guard":"cpe:2.3:o:microsoft:windows_10:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Windows RDP":"cpe:2.3:o:microsoft:windows_10:*:*:*:*:*:*:*:*",
"Microsoft Corporation=Windows Subsystem for Linux":"cpe:2.3:o:microsoft:windows_10:*:*:*:*:*:*:*:*",
"Microsoft=Windows 10 Version 2004":"cpe:2.3:o:microsoft:windows_10:*:*:*:*:*:*:*:*",
"Microsoft=Windows 10 Mobile":"cpe:2.3:o:microsoft:windows_10_mobile:*:*:*:*:*:*:*:*",
"Microsoft=Windows 11 version 22H2":"cpe:2.3:o:microsoft:windows_11_22h2:*:*:*:*:*:*:*:*",
"Microsoft=Windows 7":"cpe:2.3:o:microsoft:windows_7:*:*:*:*:*:*:*:*",
"Microsoft=Windows Server 2022":"cpe:2.3:o:microsoft:windows_server_2022:*:*:*:*:*:*:*:*",
"Microsoft=Windows Server 2022, 23H2 Edition (Server Core installation)":"cpe:2.3:o:microsoft:windows_server_2022_23h2:*:*:*:*:*:*:*:*",
"Motorola Mobility=Motorola smartphones":"cpe:2.3:o:motorola:smartphone_firmware:*:*:*:*:*:*:*:*",
"NVIDIA=Cumulus Linux":"cpe:2.3:o:nvidia:cumulus_linux:*:*:*:*:*:*:*:*",
"NVIDIA=NVIDIA Vibrante Linux":"cpe:2.3:o:nvidia:vibrante_linux:*:*:*:*:*:*:*:*",
"NXP=MQX":"cpe:2.3:o:nxp:mqx:*:*:*:*:*:*:*:*",
"OP-TEE=optee_os":"cpe:2.3:o:op-tee:op-tee_os:*:*:*:*:*:*:*:*",
"Open Robotics=ros":"cpe:2.3:o:openrobotics:robot_operating_system:*:*:*:*:*:*:*:*",
"SUSE=nextcloud":"cpe:2.3:o:opensuse:leap:*:*:*:*:*:*:*:*",
"SUSE=permissions":"cpe:2.3:o:opensuse:leap:*:*:*:*:*:*:*:*",
"openSUSE=Open Build Service":"cpe:2.3:o:opensuse:leap:*:*:*:*:*:*:*:*",
"openSUSE=Open Build Service":"cpe:2.3:o:opensuse:tar_scm:*:*:*:*:*:*:*:*",
"openSUSE=Tumbleweed":"cpe:2.3:o:opensuse:tumbleweed:*:*:*:*:*:*:*:*",
"OpenWRT=OpenWRT":"cpe:2.3:o:openwrt:openwrt:*:*:*:*:*:*:*:*",
"Oppo=Color OS":"cpe:2.3:o:oppo:coloros:*:*:*:*:*:*:*:*",
"Oracle Corporation=SSM - (hot-tamale) ILOM: Integrated Lights Out Manager":"cpe:2.3:o:oracle:integrated_lights_out_manager_firmware:*:*:*:*:*:*:*:*",
"Oracle Corporation=Legal Entity Configurator":"cpe:2.3:o:oracle:legal_entity_configurator:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Linux":"cpe:2.3:o:oracle:linux:*:*:*:*:*:*:*:*",
"Oracle Corporation=Solaris Operating System":"cpe:2.3:o:oracle:solaris:*:*:*:*:*:*:*:*",
"Oracle=Solaris Operating System":"cpe:2.3:o:oracle:solaris:*:*:*:*:*:*:*:*",
"Oracle Corporation=Solaris Operating System":"cpe:2.3:o:oracle:solaris_ak:*:*:*:*:*:*:*:*",
"Oracle Corporation=Sun ZFS Storage Appliance Kit (AK) Software":"cpe:2.3:o:oracle:zfs_storage_appliance:*:*:*:*:*:*:*:*",
"Palantir=Gotham":"cpe:2.3:o:palantir:gotham:*:*:*:*:*:*:*:*",
"Palo Alto Networks=PAN-OS":"cpe:2.3:o:paloaltonetworks:pan-os:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Palo Alto Networks":"cpe:2.3:o:paloaltonetworks:pan-os:*:*:*:*:*:*:*:*",
"Palo Alto Networks=Palo Alto Networks PAN-OS":"cpe:2.3:o:paloaltonetworks:pan-os:*:*:*:*:*:*:*:*",
"Palo Alto=Palo Alto Networks PAN-OS":"cpe:2.3:o:paloaltonetworks:pan-os:*:*:*:*:*:*:*:*",
"Philips=e-Alert Unit (non-medical device)":"cpe:2.3:o:philips:e-alert_firmware:*:*:*:*:*:*:*:*",
"Phoenix=SecureCore    Technology    4":"cpe:2.3:o:phoenix:securecore_technology:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=Container Station":"cpe:2.3:o:qnap:container_station:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=QTS":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP=App Center in QTS":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP=LDAP Server in QTS":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP=QNAP QTS":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP=QTS":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP=QTS Media Libary PRODUCT":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP=Qsync Central":"cpe:2.3:o:qnap:qts:*:*:*:*:*:*:*:*",
"QNAP Systems Inc.=VioStor NVR":"cpe:2.3:o:qnap:qvr_firmware:*:*:*:*:*:*:*:*",
"jens-maus=RaspberryMatic":"cpe:2.3:o:raspberrymatic:raspberrymatic:*:*:*:*:*:*:*:*",
"Realtek=ADSL/PON Modem SoC":"cpe:2.3:o:realtek:adsl_router_soc_firmware:*:*:*:*:*:*:*:*",
"Red Hat=389-ds-base":"cpe:2.3:o:redhat:389_directory_server:*:*:*:*:*:*:*:*",
"Oracle Corporation=Oracle Linux":"cpe:2.3:o:redhat:enterprise_linux:*:*:*:*:*:*:*:*",
"Red Hat=kernel":"cpe:2.3:o:redhat:enterprise_linux:*:*:*:*:*:*:*:*",
"DTS Electronics=Redline Router":"cpe:2.3:o:redline:router_firmware:*:*:*:*:*:*:*:*",
"RIOT OS=RIOT OS":"cpe:2.3:o:riot-os:riot:*:*:*:*:*:*:*:*",
"RIOT-OS=RIOT":"cpe:2.3:o:riot-os:riot:*:*:*:*:*:*:*:*",
"Samsung Mobile=Samsung Wearable Devices":"cpe:2.3:o:samsung:wear_os:*:*:*:*:*:*:*:*",
"SAP SE=SAP Startup Service":"cpe:2.3:o:sap:sap_kernel:*:*:*:*:*:*:*:*",
"SAP=SAP Startup Service":"cpe:2.3:o:sap:sap_kernel:*:*:*:*:*:*:*:*",
"SAUTER Controls=moduWeb":"cpe:2.3:o:sauter-controls:moduweb_firmware:*:*:*:*:*:*:*:*",
"labring=sealos":"cpe:2.3:o:sealos:sealos:*:*:*:*:*:*:*:*",
"Secomea=GateManager":"cpe:2.3:o:secomea:gatemanager_firmware:*:*:*:*:*:*:*:*",
"Gemalto=Gemalto's HASP SRM, Sentinel HASP and Sentinel LDK products prior to Sentinel LDK RTE":"cpe:2.3:o:sentinel:sentinel_ldk_rte_firmware:*:*:*:*:*:*:*:*",
"siderolabs=talos":"cpe:2.3:o:siderolabs:talos_linux:*:*:*:*:*:*:*:*",
"Siemens AG=SIMATIC WinCC OA V3.14 and prior":"cpe:2.3:o:siemens:simatic_wincc_open_architecture:*:*:*:*:*:*:*:*",
"sierrawireless=ALEOS":"cpe:2.3:o:sierrawireless:aleos:*:*:*:*:*:*:*:*",
"Micrium=Micrium OS":"cpe:2.3:o:silabs:micrium_os:*:*:*:*:*:*:*:*",
"Universal Robots=URx":"cpe:2.3:o:sintef:urx:*:*:*:*:*:*:*:*",
"SonicWall=SonicOS":"cpe:2.3:o:sonicwall:sonicos:*:*:*:*:*:*:*:*",
"Sophos=Sophos Firewall":"cpe:2.3:o:sophos:sfos:*:*:*:*:*:*:*:*",
"SUSE=SUSE Linux Enterprise Server":"cpe:2.3:o:suse:linux_enterprise_server:*:*:*:*:*:*:*:*",
"SUSE=openSUSE Factory":"cpe:2.3:o:suse:opensuse_factory:*:*:*:*:*:*:*:*",
"SWARCO=CPU LS4000":"cpe:2.3:o:swarco:cpu_ls4000_firmware:*:*:*:*:*:*:*:*",
"TencentOS-tiny=TencentOS-tiny":"cpe:2.3:o:tencent:tencentos-tiny:*:*:*:*:*:*:*:*",
"Totolink=X6000R":"cpe:2.3:o:totolink:x6000r_firmware:*:*:*:*:*:*:*:*",
"Checkmk GmbH=Checkmk Appliance":"cpe:2.3:o:tribe29:checkmk_appliance_firmware:*:*:*:*:*:*:*:*",
"Tribe29=Checkmk Appliance":"cpe:2.3:o:tribe29:checkmk_appliance_firmware:*:*:*:*:*:*:*:*",
"Ubiquiti Networks=EdgeRouter X":"cpe:2.3:o:ubnt:edgeos:*:*:*:*:*:*:*:*",
"Ubiquiti Networks=EdgeRouter X":"cpe:2.3:o:ui:edgeos:*:*:*:*:*:*:*:*",
"Univention Corporate Server=univention-directory-notifier":"cpe:2.3:o:univention:univention_corporate_server:*:*:*:*:*:*:*:*",
"HackerOne=url-parse":"cpe:2.3:o:url-parse_project:url-parse:*:*:*:*:*:*:*:*",
"umlaeute=umlaeute/v4l2loopback":"cpe:2.3:o:v4l2loopback_project:v4l2loopback:*:*:*:*:*:*:*:*",
"Xapi=Xapi":"cpe:2.3:o:xen:xapi:*:*:*:*:*:*:*:*",
"Xen=Xen":"cpe:2.3:o:xen:xen:*:*:*:*:*:*:*:*",
"Zephyr=getaddrinfo":"cpe:2.3:o:zephyrproject:zephyr:*:*:*:*:*:*:*:*",
"Zephyr=shell":"cpe:2.3:o:zephyrproject:zephyr:*:*:*:*:*:*:*:*",
"zephyrproject-rtos=Zephyr":"cpe:2.3:o:zephyrproject:zephyr:*:*:*:*:*:*:*:*",
"zephyrproject-rtos=zephyr":"cpe:2.3:o:zephyrproject:zephyr:*:*:*:*:*:*:*:*"
};


      const separator = "\u2b24";
      const assignerShortName = this.originalRecordData?.cveMetadata?.assignerShortName;
      const partnerUUID = this.originalRecordData?.cveMetadata?.assignerOrgId;
       const vcvss_cna = this.originalRecordData?.containers?.cna?.metrics?.[0]?.cvssV3_1?.vectorString;
            const ecvss_cna = this.originalRecordData?.containers?.cna?.metrics?.[0]?.cvssV3_1?.baseScore;
            const ycvss_cna = this.originalRecordData?.containers?.cna?.metrics?.[0]?.cvssV3_1?.baseSeverity;
            // const aff_cpe_cna = this.originalRecordData?.containers?.cna?.affected?.[0]?.cpes?.[0];





let aff_cpe_cna = "";
let cnaData = this.originalRecordData?.containers?.cna;

if (cnaData && cnaData.affected) {
  cnaData.affected.forEach(affectedElement => {
    if (affectedElement && affectedElement.cpes) {
      affectedElement.cpes.forEach(cpeElement => {
        if (cpeElement) {
          if (aff_cpe_cna) {
            aff_cpe_cna += ` ${separator} `;
          }
          aff_cpe_cna += cpeElement;
        }
      });
    }
  });
}





            let cwe_cna = this.originalRecordData?.containers?.cna?.problemTypes?.[0]?.descriptions?.[0]?.description;
	    if (cwe_cna === 'n/a') {
	        cwe_cna = '';
		}

let cisa_adp_name = "CISAADP";
if (this.$store.state.apiInstance === 'cveawg.mitre.org') {
    cisa_adp_name = "CISA-ADP";
}

const cisaref = this.originalRecordData?.containers?.adp
  ?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)
  ?.references?.map(ref => ref.url)
  ?.join(' ');
const mitreref = this.originalRecordData?.containers?.adp
  ?.find(adp => adp.providerMetadata?.shortName === "MITREADP")
  ?.references?.map(ref => ref.url)
  ?.join(' ');


const vcvss = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[0]?.cvssV3_1?.vectorString;
const ecvss = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[0]?.cvssV3_1?.baseScore;
const ycvss = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[0]?.cvssV3_1?.baseSeverity;

const str_nvd_cpeas = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "NVD-UDP")?.x_cpeas;

    let nvd_cpeas;
    if (str_nvd_cpeas) {
try {


  function removeEmptyArrays(obj) {
    if (Array.isArray(obj)) {
      return obj.map(item => removeEmptyArrays(item)).filter(item => !(Array.isArray(item) && item.length === 0));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const processedValue = removeEmptyArrays(value);
        if (!(Array.isArray(processedValue) && processedValue.length === 0)) {
          acc[key] = processedValue;
        }
        return acc;
      }, {});
    }
    return obj;
  }



    let abc = JSON.parse(str_nvd_cpeas);
    console.log(abc);
    let def = removeEmptyArrays(abc);
    console.log(def);
    if (def.length === 0) {
        nvd_cpeas = ''
    } else {
       nvd_cpeas = yaml.dump(def);
    }
    // console.log(nvd_cpeas);
//    if (nvd_cpeas === '[]') {
//        nvd_cpeas = '';
//    }

} catch (error) {
    console.error('remove empty array error', error.message);
    console.error(error.stack);
    nvd_cpeas = '';    
}
} else {
   nvd_cpeas = '';
}






    let b64_cpeas;
      let orig_b64_cpeas = this.originalRecordData?.containers?.cna?.affected?.[0]?.cpes?.[0];
      if (orig_b64_cpeas && orig_b64_cpeas.startsWith("cpe:2.3:a:base64ApplicabilityStatementIn:otherAttribute:*:*:*:*:*:*:*:")) {
            const b64_regex = /\\/g;
	    	    console.log('CPE-AS-FROM-CNA: phase1');
            let ending_part = orig_b64_cpeas.slice(70);      
            let tmp_b64_cpeas = ending_part.replace(b64_regex, '');
	    let decode_a = atob(tmp_b64_cpeas);
	    	    	    console.log('CPE-AS-FROM-CNA: phase2');
	    let str_b64_cpeas = decodeURIComponent(escape(decode_a));

	    console.log('CPE-AS-FROM-CNA: ' + str_b64_cpeas);



    if (str_b64_cpeas) {
try {


  function removeEmptyArrays(obj) {
    if (Array.isArray(obj)) {
      return obj.map(item => removeEmptyArrays(item)).filter(item => !(Array.isArray(item) && item.length === 0));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const processedValue = removeEmptyArrays(value);
        if (!(Array.isArray(processedValue) && processedValue.length === 0)) {
          acc[key] = processedValue;
        }
        return acc;
      }, {});
    }
    return obj;
  }



    let b64_abc = JSON.parse(str_b64_cpeas);
    console.log(b64_abc);
    let b64_def = removeEmptyArrays(b64_abc);
    console.log(b64_def);
    if (b64_def.length === 0) {
        b64_cpeas = ''
    } else {
       b64_cpeas = yaml.dump(b64_def);
    }
    // console.log(b64_cpeas);
//    if (b64_cpeas === '[]') {
//        b64_cpeas = '';
//    }

} catch (error) {
    console.error('remove empty array error', error.message);
    console.error(error.stack);
    b64_cpeas = '';    
}
} else {
   b64_cpeas = '';
}
}







let vend_cna = this.originalRecordData?.containers?.cna?.affected?.[0]?.vendor;
let prod_cna = this.originalRecordData?.containers?.cna?.affected?.[0]?.product;
let next_array_element = this.originalRecordData?.containers?.cna?.affected?.[1];

let cpet = [];
// let ccpe = "cpe:2.3:a:null:null:*:*:*:*:*:*:*:*";
if (next_array_element) {
      console.log('next array element found');
} else {
   let cpekey = vend_cna+'='+prod_cna;
   if (tcpe[cpekey]) {
      console.log(cpekey + ' mapped to ' + tcpe[cpekey])
      let ccpe = tcpe[cpekey];
      let version_cna = this.originalRecordData?.containers?.cna?.affected?.[0]?.versions?.[0]?.version;
      let version_than_cna = this.originalRecordData?.containers?.cna?.affected?.[0]?.versions?.[0]?.lessThan;
      let version_thane_cna = this.originalRecordData?.containers?.cna?.affected?.[0]?.versions?.[0]?.lessThanOrEqual;

      console.log('version data from RSUS: ' + version_than_cna + ' ' + version_cna);

      if (version_than_cna && version_cna && (version_cna === '0' || version_cna === 'unspecified')) {
         cpet = [ { "operator" : "OR", "children" : [ ], "cpe_match" : [ { "vulnerable" : true, "cpe23Uri" : "", "versionEndExcluding" : "", "cpe_name" : [ ] } ] } ];      
         cpet[0].cpe_match[0].versionEndExcluding = version_than_cna;
	 cpet[0].cpe_match[0].cpe23Uri = ccpe;
	 let ccww = 'version converted: ' + JSON.stringify(cpet);
	 console.log(ccww);
      }
   }
}

let ref_zero_u = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "cna-potpourri")?.references?.[0]?.url;
let ref_zero_n = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "cna-potpourri")?.references?.[0]?.name;
let ref_zero_t = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "cna-potpourri")?.references?.[0]?.tags?.[0];
let ref_zero;


if (!ref_zero_u) {
    ref_zero = '';
} else {
if (!ref_zero_n) { ref_zero_n = ''; }
if (!ref_zero_t) { ref_zero_t = ''; }

// Count the occurrences of 'uv-' and 'dv-'
let uvCount = (ref_zero_n.match(/uv-/g) || []).length;
let dvCount = (ref_zero_n.match(/dv-/g) || []).length;

// Create the new string with the desired format using HTML & codes
let resultString = `&#128077;&#127999; ${uvCount} &#128078;&#127999; ${dvCount}`;
if (ref_zero_t === 'x_dp-3174f9f2-2344-4a6f-8f91-31e3e260b236') {
   ref_zero_t = 'other-cna';
}
ref_zero = '(' + ref_zero_t + ') ' + ref_zero_u + ' ' + resultString;
}


    let gen_cpeas;
    if (cpet.length > 0) {
          console.log('cpet length OK');
try {


  function removeEmptyArrays(obj) {
    if (Array.isArray(obj)) {
      return obj.map(item => removeEmptyArrays(item)).filter(item => !(Array.isArray(item) && item.length === 0));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const processedValue = removeEmptyArrays(value);
        if (!(Array.isArray(processedValue) && processedValue.length === 0)) {
          acc[key] = processedValue;
        }
        return acc;
      }, {});
    }
    return obj;
  }


    let gen_def = removeEmptyArrays(cpet);
    console.log(gen_def);
    if (gen_def.length === 0) {
        gen_cpeas = ''
    } else {
              console.log('doing YAML dump');
       gen_cpeas = yaml.dump(gen_def);
    }

} catch (error) {
    console.error('remove empty array error', error.message);
    console.error(error.stack);
    gen_cpeas = '';    
}
} else {
   gen_cpeas = '';
}















const nvd_vcvss = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "NVD-UDP")?.metrics?.[0]?.cvssV3_1?.vectorString;
const nvd_ecvss = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "NVD-UDP")?.metrics?.[0]?.cvssV3_1?.baseScore;
const nvd_ycvss = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "NVD-UDP")?.metrics?.[0]?.cvssV3_1?.baseSeverity;

const opt_e = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[1]?.other?.content?.options?.[0]?.Exploitation;
const opt_a = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[1]?.other?.content?.options?.[1]?.Automatable;
const opt_t = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[1]?.other?.content?.options?.[2]?.["Technical Impact"];

const zero_opt_e = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[0]?.other?.content?.options?.[0]?.Exploitation;
const zero_opt_a = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[0]?.other?.content?.options?.[1]?.Automatable;
const zero_opt_t = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.metrics?.[0]?.other?.content?.options?.[2]?.["Technical Impact"];


let aff_cpe = "";
let adpData = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name);

if (adpData && adpData.affected) {
  adpData.affected.forEach(affectedElement => {
    if (affectedElement && affectedElement.cpes) {
      affectedElement.cpes.forEach(cpeElement => {
        if (cpeElement) {
          if (aff_cpe) {
            aff_cpe += ` ${separator} `;
          }
          aff_cpe += cpeElement;
        }
      });
    }
  });
}


const nvd_aff_cpe = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "NVD-UDP")?.affected?.[0]?.cpes?.[0];

const aff_ven = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.affected?.[0]?.vendor;
const aff_pro = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.affected?.[0]?.product;
const aff_ver = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.affected?.[0]?.versions?.[0]?.version;
const aff_lessthan = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.affected?.[0]?.versions?.[0]?.lessThan;
const aff_lessthane = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.affected?.[0]?.versions?.[0]?.lessThanOrEqual;

const cwe = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === cisa_adp_name)?.problemTypes?.[0].descriptions?.[0]?.description;
const nvd_cwe = this.originalRecordData?.containers?.adp?.find(adp => adp.providerMetadata?.shortName === "NVD-UDP")?.problemTypes?.[0].descriptions?.[0]?.description;

if (nvd_cpeas) {
  this.cveFieldList.nvd_cpeas = nvd_cpeas;
  }
else {
  this.cveFieldList.nvd_cpeas = '';
}

if (ref_zero) {
  this.cveFieldList.ref_zero = ref_zero;
  }
else {
  this.cveFieldList.ref_zero = '';
}




if (gen_cpeas) {
  this.cveFieldList.gen_cpeas = gen_cpeas;
  }
else {
  this.cveFieldList.gen_cpeas = '';
}


if (b64_cpeas) {
  this.cveFieldList.b64_cpeas = b64_cpeas;
  }
else {
  this.cveFieldList.b64_cpeas = '';
}


if (cwe) {
  this.cveFieldList.cwe = cwe;
  }
else {
  this.cveFieldList.cwe = '';
}

if (nvd_cwe) {
  this.cveFieldList.nvd_cwe = nvd_cwe;
  }
else {
  this.cveFieldList.nvd_cwe = '';
}


if (cwe_cna) {
  this.cveFieldList.cwe_cna = cwe_cna;
  }
  else {
    this.cveFieldList.cwe_cna = '';
  }
if (cisaref) {  
  this.cveFieldList.cisaref = cisaref;
  }
  else  {
    this.cveFieldList.cisaref = '';
  }
if (mitreref) {  
  this.cveFieldList.mitreref = mitreref;
  }
  else {
    this.cveFieldList.mitreref = '';
  }

let aff_multi = false;
if (aff_lessthan) {
  this.cveFieldList.aff_sym = '<';
    this.cveFieldList.aff_lim = aff_lessthan;
    aff_multi = true;
    }
if (aff_lessthane) {
  this.cveFieldList.aff_sym = '<=';
    this.cveFieldList.aff_lim = aff_lessthane;
        aff_multi = true;
    }

      if (this.hasData(aff_ven)) {
         this.cveFieldList.aff_ven = aff_ven;
	 } else {
	          this.cveFieldList.aff_ven = '?';
	 }
      if (this.hasData(aff_pro)) {
         this.cveFieldList.aff_pro = aff_pro;
	 } else {
	          this.cveFieldList.aff_pro = '?';
	 }
      if (this.hasData(aff_ver)) {
         this.cveFieldList.aff_ver = aff_ver;
	 } else {
	          this.cveFieldList.aff_ver = '?';
	 }

       this.cveFieldList.aff_all = 'Vendor=' + this.cveFieldList.aff_ven + ' Product='+ this.cveFieldList.aff_pro;

if (this.cveFieldList.aff_ven === '?' && this.cveFieldList.aff_pro === '?' && this.cveFieldList.aff_ver === '?') {
   this.cveFieldList.aff_all = '';
}

       let added_version = ' Version='+ this.cveFieldList.aff_ver;

       let start_version = true;
       if (this.cveFieldList.aff_ver === '-' || this.cveFieldList.aff_ver === '?') {
          start_version = false;
	  }
       if (this.cveFieldList.aff_ver === '0' || this.cveFieldList.aff_ver === '*') {
          start_version = false;
	  }

       let aff_range = '(' + this.cveFieldList.aff_sym + ' ' + this.cveFieldList.aff_lim + ')'
       if (aff_multi && start_version) {
         this.cveFieldList.aff_all += added_version;
	          this.cveFieldList.aff_all += ' ' + aff_range;
	 }
	 else {
   	 if (aff_multi)
	 {
	 this.cveFieldList.aff_all += ' Version=';
            this.cveFieldList.aff_all += aff_range;
	    }
	    }
        if (! aff_multi) {
	    if (start_version) {
	    	    this.cveFieldList.aff_all += added_version;
		    }
	    }

       if (aff_cpe) {
          this.cveFieldList.aff_cpe = aff_cpe;
	  }
	  else {
          this.cveFieldList.aff_cpe = '';
	  }

       if (nvd_aff_cpe) {
          this.cveFieldList.nvd_aff_cpe = nvd_aff_cpe;
	  }
	  else {
          this.cveFieldList.nvd_aff_cpe = '';
	  }

       if (aff_cpe_cna) {
          this.cveFieldList.aff_cpe_cna = aff_cpe_cna;
	  }
	  else {
          this.cveFieldList.aff_cpe_cna = '';
	  }

      if (this.hasData(vcvss)) {
         this.cveFieldList.vcvss = vcvss;
	 } else {
	          this.cveFieldList.vcvss = '';
	 }
      if (this.hasData(ecvss)) {
         this.cveFieldList.ecvss = '' + ecvss;
	 } else {
	          this.cveFieldList.ecvss = '';
	 }
      if (this.hasData(ycvss)) {
         this.cveFieldList.ycvss = ycvss;
	 } else {
	          this.cveFieldList.ycvss = '';
	 }
       this.cveFieldList.acvss = this.cveFieldList.ecvss + ' (' + this.cveFieldList.ycvss + ') ' + this.cveFieldList.vcvss + ' ';

      if (this.hasData(nvd_vcvss)) {
         this.cveFieldList.nvd_vcvss = nvd_vcvss;
	 } else {
	          this.cveFieldList.nvd_vcvss = '';
	 }
      if (this.hasData(nvd_ecvss)) {
         this.cveFieldList.nvd_ecvss = '' + nvd_ecvss;
	 } else {
	          this.cveFieldList.nvd_ecvss = '';
	 }
      if (this.hasData(nvd_ycvss)) {
         this.cveFieldList.nvd_ycvss = nvd_ycvss;
	 } else {
	          this.cveFieldList.nvd_ycvss = '';
	 }
       this.cveFieldList.nvd_acvss = this.cveFieldList.nvd_ecvss + ' (' + this.cveFieldList.nvd_ycvss + ') ' + this.cveFieldList.nvd_vcvss + ' ';



       if (this.hasData(vcvss_cna)) {
          this.cveFieldList.vcvss_cna = vcvss_cna;
 	 } else {
 	          this.cveFieldList.vcvss_cna = '';
 	 }
       if (this.hasData(ecvss_cna)) {
          this.cveFieldList.ecvss_cna = '' + ecvss_cna;
 	 } else {
 	          this.cveFieldList.ecvss_cna = '';
 	 }
       if (this.hasData(ycvss_cna)) {
          this.cveFieldList.ycvss_cna = ycvss_cna;
 	 } else {
 	          this.cveFieldList.ycvss_cna = '';
 	 }
        this.cveFieldList.acvss_cna = this.cveFieldList.ecvss_cna + ' (' + this.cveFieldList.ycvss_cna + ') ' + this.cveFieldList.vcvss_cna + ' ';


      if (this.hasData(opt_e)) {
         this.cveFieldList.opt_e = opt_e;
	 } else {
	          this.cveFieldList.opt_e = '?';
	 }
      if (this.hasData(opt_a)) {
         this.cveFieldList.opt_a = '' + opt_a;
	 } else {
	          this.cveFieldList.opt_a = '?';
	 }
      if (this.hasData(opt_t)) {
         this.cveFieldList.opt_t = opt_t;
	 } else {
	          this.cveFieldList.opt_t = '?';
	 }
       this.cveFieldList.opt_f = 'Exploitation=' + this.cveFieldList.opt_e + ' Automatable='+ this.cveFieldList.opt_a + ' TechnicalImpact=' + this.cveFieldList.opt_t;
       if (this.cveFieldList.opt_e === '?' && this.cveFieldList.opt_a === '?' && this.cveFieldList.opt_t === '?') {
              this.cveFieldList.opt_f = '';



      if (this.hasData(zero_opt_e)) {
         this.cveFieldList.opt_e = zero_opt_e;
	 } else {
	          this.cveFieldList.opt_e = '?';
	 }
      if (this.hasData(zero_opt_a)) {
         this.cveFieldList.opt_a = '' + zero_opt_a;
	 } else {
	          this.cveFieldList.opt_a = '?';
	 }
      if (this.hasData(zero_opt_t)) {
         this.cveFieldList.opt_t = zero_opt_t;
	 } else {
	          this.cveFieldList.opt_t = '?';
	 }
       this.cveFieldList.opt_f = 'Exploitation=' + this.cveFieldList.opt_e + ' Automatable='+ this.cveFieldList.opt_a + ' TechnicalImpact=' + this.cveFieldList.opt_t;
       if (this.cveFieldList.opt_e === '?' && this.cveFieldList.opt_a === '?' && this.cveFieldList.opt_t === '?') {
              this.cveFieldList.opt_f = '';
       }




       }
       
if (this.hasData(assignerShortName)) {
         const url = 'https://api.udp.info/api/cve/cve-partner-name-map';
         axios
           .get(url, { timeout: 30000 })
           .then((response) => {
             const shortLongNameMap = response.data;
             if (shortLongNameMap?.[partnerUUID]) {
               this.cveFieldList.assigner = shortLongNameMap?.[partnerUUID];
             }
           })
           .finally(() => {
             if (this.cveFieldList.assigner.length === 0) {
               this.cveFieldList.assigner = assignerShortName.replace('_', ' ');
             }
           });
       }

	 },


    getCredits() {
      // schema: https://github.com/CVEProject/cve-schema/blob/master/schema/v5.0/CVE_JSON_5.0_schema.json#L971-L1015
      const value = this.originalRecordData.containers?.cna?.credits;
      if (this.hasData(value)) {
        value.forEach((credit) => {
          if (this.isEnglishLanguage(credit.lang)) {
            this.cveFieldList.credits.push(credit);
          }
        });
      }
    },
    getRecordPublishedDate() {
      const value = this.originalRecordData.cveMetadata?.datePublished;
      if (this.hasData(value)) {
        this.cveFieldList.datePublishedCveMetadata = this.getDate(value);
      }
    },
    getRecordUpdatedDate() {
      const value = this.originalRecordData.cveMetadata?.dateUpdated;
      if (this.hasData(value)) {
        this.cveFieldList.dateUpdatedCveMetadata = this.getDate(value);
      }
    },
    getCVEid() {
      const value = this.originalRecordData.cveMetadata?.cveId;
      if (this.hasData(value)) {
        this.cveFieldList.cveId = value;
      } else {
        this.cveFieldList.cveId = this.$store.state.recordData.cveMetadata.cveId; // user-provided CVE ID
      }
    },
    getDescription() {
      const value = this.originalRecordData.containers?.cna?.descriptions;
      if (this.hasData(value)) {
        value.forEach((desc) => {
          if (this.isEnglishLanguage(desc.lang)) {
            this.cveFieldList.descriptions.push(desc.value);
          }
        });
      }
    },
    getReferences() {
      const value = this.originalRecordData.containers?.cna?.references;
      if (this.hasData(value)) {
        const filteredReferences = [];
        const regex = /^x_refsource/;

        value.forEach((reference) => {
          let newReference = {};
          const filteredTags = [];

          if (reference?.tags) {
            reference.tags.forEach((tag) => {
              if (!regex.test(tag)) filteredTags.push(tag);
            });
          }
          newReference = _.cloneDeep(reference);
          newReference.tags = filteredTags;
          if (newReference?.name && newReference.name.length > 0) newReference.hostname = (new URL(newReference.url)).hostname.replace('www.', '');
          filteredReferences.push(newReference);
        });

        Vue.set(this.cveFieldList, 'references', filteredReferences);
      }
    },
    getState() {
      const value = this.originalRecordData.cveMetadata?.state;
      if (this.hasData(value)) {
        this.cveFieldList.state = value;
      }
    },
    getTags() {
      const value = this.originalRecordData.containers?.cna?.tags;
      if (this.hasData(value)) {
        Vue.set(this.cveFieldList, 'tags', value);
      }
    },
    getTitle() {
      const value = this.originalRecordData.containers?.cna?.title;
      if (this.hasData(value)) {
        this.cveFieldList.title = value;
      }
    },
    // getProductsStatus() is adapted from https://github.com/Vulnogram/seaview/blob/main/script.js#L140-L253
    getProductsStatus() {
      const affectedList = this.originalRecordData.containers?.cna?.affected;
      if (this.hasData(affectedList)) {
        const prodStatusTemplate = {
          vendor: '',
          product: '',
          platforms: [],
          defaultStatus: '',
          versionsColumns: [],
        };

        /**
          Building the Product Status table each product in containers.cna.affected[]:
          - column 1: vendor, product, platforms
          - column 2: affected/unaffected/unknown list(s)/subcomlumn(s)
        */
        affectedList.forEach((affectedObj) => {
          const prodStatus = _.cloneDeep(prodStatusTemplate);
          // Building column 1
          if (this.hasData(affectedObj?.vendor)) prodStatus.vendor = affectedObj.vendor;
          if (this.hasData(affectedObj?.product)) prodStatus.product = affectedObj.product;
          if (this.hasData(affectedObj?.platforms)) prodStatus.platforms = _.cloneDeep(affectedObj.platforms);

          // Building column 2: an affected/unaffected/unknown subcolumn(s) for each product version in containers.cna.affected[i].versions[].
          const versionsColumns = [];
          // https://github.com/Vulnogram/seaview/blob/main/script.js#L175-L216
          if (this.hasData(affectedObj?.versions)) {
            const tableRowTemplate = {
              versionRange: {
                parentVersion: [],
                parentVersionRange: [],
                parentVersionStatus: '',
                versionsChanges: [],
              },
            };

            affectedObj.versions.forEach((versionObj) => {
              const tableRow = _.cloneDeep(tableRowTemplate);
              const versionsAndChangesTemplate = {
                versions: [],
                changes: [],
                changeColorList: [],
              };
              const productVersion = `${versionObj?.version || '(no version provided)'}`;
              const versionStatus = versionObj?.status || 'noVersionStatus'; // affected, unaffected, OR unknown

              if (this.hasData(versionObj?.changes)) {
                let range = [];

                if (productVersion !== 'unspecified' && productVersion !== '0') {
                  range.push('from');
                  range.push(productVersion);
                }

                if (versionObj?.lessThan) {
                  let rangeEnd = ['before', `${versionObj?.lessThan || '(no lessThan version provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThan')) rangeEnd = [];
                  if ((rangeEnd.length > 0) && (versionObj.lessThan !== productVersion)) {
                    range = range.concat(rangeEnd);
                  }
                } else if (versionObj?.lessThanOrEqual) {
                  let rangeEnd = ['through', `${versionObj?.lessThanOrEqual || '(no lessThan version provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThanOrEqual')) rangeEnd = '';
                  if ((rangeEnd.length > 0) && (versionObj.lessThanOrEqual !== productVersion)) {
                    range = range.concat(rangeEnd);
                  }
                } else {
                  range = [productVersion];
                }

                // save the version changes
                const versionsAndChangesCopy = _.cloneDeep(versionsAndChangesTemplate);

                // Build a temp 'changes' object by going through container.cna.affected[x].versions[x].changes[]
                //   and categorize by 'status' (affected, unaffected, or unknown)
                //   which can be different from the main 'status' (container.cna.affected[x].versions[x].status).
                versionObj.changes.forEach((change) => {
                  versionsAndChangesCopy.changeColorList.push([change.status, 'from', change.at]);
                });

                tableRow.versionRange.parentVersionStatus = versionStatus;
                tableRow.versionRange.parentVersion = ['from', productVersion];
                tableRow.versionRange.parentVersionRange = range;
                tableRow.versionRange.versionsChanges = versionsAndChangesCopy.changeColorList;
              } else {
                let rangeStart = [];
                if (productVersion !== 'unspecified' && productVersion !== '0') rangeStart = ['from', productVersion];

                tableRow.versionRange.parentVersion = [productVersion];
                if (this.hasData(versionObj?.lessThan)) {
                  let range = [];
                  let rangeEnd = ['before', `${versionObj?.lessThan || '(no version.lessThan provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThan')) rangeEnd = [];
                  if (rangeEnd.length > 0) {
                    range = range.concat(rangeStart, rangeEnd);
                  } else {
                    range = rangeStart;
                  }
                  tableRow.versionRange.parentVersionStatus = versionStatus;
                  tableRow.versionRange.parentVersionRange = range;
                } else if (this.hasData(versionObj?.lessThanOrEqual)) {
                  let range = [];
                  let rangeEnd = ['through', `${versionObj?.lessThanOrEqual || '(no version.lessThan provided)'}`];
                  if (this.isUnspecifiedOrWildCard(versionObj, 'lessThanOrEqual')) rangeEnd = [];
                  range = range.concat(rangeStart, rangeEnd);
                  tableRow.versionRange.parentVersionStatus = versionStatus;
                  tableRow.versionRange.parentVersionRange = range;
                } else {
                  tableRow.versionRange.parentVersionStatus = versionStatus;
                  tableRow.versionRange.parentVersion = ['at', productVersion];
                  tableRow.versionRange.parentVersionRange = ['at', productVersion];
                }
              }
              versionsColumns.push(tableRow.versionRange);
            });

            if (this.hasData(affectedObj?.defaultStatus)) {
              prodStatus.defaultStatus = `${affectedObj.defaultStatus}`;
            }
          } else {
            prodStatus.defaultStatus = this.hasData(affectedObj?.defaultStatus) ? `All versions are ${affectedObj.defaultStatus}` : '';
          }
          prodStatus.versionsColumns = this.create2DTable(versionsColumns);
          this.cveFieldList.productsStatus.push(_.cloneDeep(prodStatus));
          this.cveFieldList.showProductStatus = !this.isVendorProductVersionDefaultStatusNa(
            prodStatus.vendor, prodStatus.product, versionsColumns, prodStatus.defaultStatus);
        });
      }
    },
    getContentForField(field) {
      switch (field) {
        case 'CNA':
          this.getCNA();
          break;
        case 'Credits':
          this.getCredits();
          break;
        case 'Description':
          this.getDescription();
          break;
        case 'ID':
          this.getCVEid();
          break;
        case 'RecordPublishedDate':
          this.getRecordPublishedDate();
          break;
        case 'RecordUpdatedDate':
          this.getRecordUpdatedDate();
          break;
        case 'References':
          this.getReferences();
          break;
        case 'State':
          this.getState();
          break;
        case 'Tags':
          this.getTags();
          break;
        case 'Title':
          this.getTitle();
          break;
        case 'VendorsProductsVersions':
          this.getProductsStatus();
          break;
        default:
          break;
      }
    },
    hasData(value) {
      if (typeof value !== 'undefined') {
      let svalue = value;
      if (typeof value === 'number') {
          svalue = '' + value;
	  }
      if (svalue.length > 0) {
        return true;
      }
     }
      return false;
    },
    isEnglishLanguage(lang) {
      const regex = /^(en)/;
      const isEnglishLanguage = regex.test(lang);

      return isEnglishLanguage;
    },
    isUnspecifiedOrWildCard(field, key) {
      return (field[key] === 'unspecified' || field[key] === '*');
    },
    getDate(dateTime) {
      const [date] = dateTime.split('T');
      return date;
    },
    toggleRecord() {
      this.$store.commit('updateState', { showJsonRecord: !this.$store.state.showJsonRecord });
    },
    create2DTable(affectedUnaffectedUnknownTable) {
      const tableWithHeaders = {
        headers: [],
        twoDTable: {},
      };

      // Build table headers: affected, and/or unaffected, and/or unknown
      affectedUnaffectedUnknownTable.forEach((row) => {
        if (tableWithHeaders.headers.indexOf(row.parentVersionStatus) < 0) tableWithHeaders.headers.push(row.parentVersionStatus);
      });
      tableWithHeaders.headers.sort();

      // Build 2D table w/ 'version'&'changes' or '' for table cell w/ no value
      affectedUnaffectedUnknownTable.forEach((row) => {
        // for each row fill in 'version'&'changes' or ''
        if (Object.prototype.hasOwnProperty.call(tableWithHeaders.twoDTable, row.parentVersionStatus)) {
          tableWithHeaders.twoDTable[row.parentVersionStatus].push(row);
        } else {
          tableWithHeaders.twoDTable[row.parentVersionStatus] = [row];
        }
      });
      return tableWithHeaders;
    },
    isAversion(version) {
      const notAversion = ['affected', 'unaffected', 'unknown', 'from', 'before', 'through', 'at'];

      if (notAversion.indexOf(version) > -1) return false;
      return true;
    },
    isVendorProductVersionDefaultStatusNa(vendor, product, affectedUnaffectedUnknownTable, defaultStatus) {
      const isVendorNa = /n\/a/.test(vendor.toLowerCase());
      const isProductNa = /n\/a/.test(product.toLowerCase());
      const isDefaultStausNa = defaultStatus.length === 0;
      let isVersionNa = false;

      if (affectedUnaffectedUnknownTable.length === 1) {
        isVersionNa = /n\/a/.test(affectedUnaffectedUnknownTable[0].parentVersionRange.toString().toLowerCase());
      }

      if (isVendorNa && isProductNa && isVersionNa && isDefaultStausNa) return false;
      return true;
    },
  },
  beforeMount() {
      this.initializeFields();
  this.checkRenderOptF();

  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/globals.scss';
@import '@/assets/style/cveRecord.scss';
@import '../../assets/style/elements/cveTableStacked.scss';

.info-lines {
  text-align: left;
}

.info-lines div {
  margin-bottom: 8px; /* Adjust the spacing as needed */
}

.cve-light-gray-bg {
  background-color: #FFF;
  border-radius: $cve-border-radius;
  border: $white-ter solid 1px;
}

.columns {
  margin-top: 0rem !important;
}

.columns:not(:last-child) {
    margin-bottom: 0.5rem !important;
}

.cve-row {
  margin-left: unset !important;
  margin-right: unset !important;
}

.cve-versions-columns {
  padding-left: 4px !important;
}
.cve-versions-column:not(:last-child) {
  @include from($desktop) {
    border-right: $theme-color-base-light solid 2px;
  }

  @include until($tablet) {
    border-bottom: $theme-color-base-light solid 2px;
  }
}

td {
  width: 33% !important;
}

.cve-light-gray-table-header {
  text-transform: capitalize;
  background-color: $theme-color-base !important;
  border: 1px solid #F0F0F0 !important;
}

.cve-border-light-gray {
  border: 1px solid #F0F0F0;
}

.cve-version {
  color: $theme-color-violet-vivid-60;
  font-weight: 700;
}

.menu-list {
  margin-left: 1.5rem !important;
  margin-right: 0.25rem !important;
}

.cve-tag {
  background-color: $theme-color-violet-vivid-60 !important;
  color: white !important;
  font-weight: 600;
}

.cve-state-tag {
  background-color: $theme-color-blue-warm-20 !important;
  color: $theme-color-base-darkest !important;
}

.cisa-ssvc {
  font-size: 24px;
  color: #b30939;
}
</style>
