<template>
  <div id="cve-secondary-page-main-container">
    <div class="columns is-centered">
      <div class="column is-8-desktop cve-main-column-content-width">
        <main id="cve-main-page-content" role="main">
          <div class="content">
            <div class="cve-loading-message has-text-centered" v-if="this.$store.state.isSearching">
              <span class="icon-text">
                <span class="icon">
                  <span class="loader is-loading"/>
                </span>
                <span>Please wait. Loading...</span>
              </span>
            </div>
            <div v-else>
              <div v-if="this.$store.state.isIdOrRecordFound">
                <PublishedRecord :NVDBaseUrl="NVDBaseUrl" v-if="this.$store.state.isPublished"/>
                <RejectedRecordOrId :NVDBaseUrl="NVDBaseUrl" v-if="this.$store.state.isRejected"/>
                <ReservedId v-if="this.$store.state.isReserved"/>
              </div>
              <p v-if="this.$store.state.showHelpText" class="has-text-centered">
                Please use the search box above to find a CVE record by ID.
              </p>
              <div v-if="this.$store.state.serverError" class="has-text-centered">
                <h1 class="title is-4">Service is currently unavailable.</h1>
                <p style="text-align: center;">Please
                  <span class="icon-text">
                    <a href="https://cveform.mitre.org/" target="_blank">report the issue
                      <span class="icon is-size-7 cve-icon-xxs">
                        <p id="extenalLink1" class="is-hidden">external site</p>
                        <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink1" aria-hidden="false" focusable="false"/>
                      </span>
                    </a>
                  </span>
                  and try again later.<br/>Or, use
                  <a href="https://cve.mitre.org/cve/search_cve_list.html" target="_blank">Search CVE List on cve.mitre.org
                    <span class="icon is-size-7 cve-icon-xxs">
                      <p id="extenalLink1" class="is-hidden">external site</p>
                      <font-awesome-icon icon="external-link-alt" aria-labelledby="extenalLink1" aria-hidden="false" focusable="false"/>
                    </span>
                  </a>
                  to search CVE Records. Sorry for the inconvenience.
                </p>
              </div>
              <div v-if="!this.$store.state.isIdOrRecordFound" class="level-left">

        <div class="level-item">
          <div class="columns is-multiline">
             <div class="column is-full">
                 <label for="api-select">Choose an API instance:</label>
                 <select id="api-select" v-model="apiInstance" @change="updateApi">
                   <option value="">Select an API instance</option>
                   <option value="cveawg.mitre.org">cveawg.mitre.org</option>
                   <option value="cveawg-test.mitre.org">cveawg-test.mitre.org</option>
                   <option value="cveawg-adp-test.mitre.org">cveawg-adp-test.mitre.org</option>
                   <option value="cveawg-prod-staging.mitre.org">cveawg-prod-staging.mitre.org</option>
                   <option value="cveawg-dev.mitre.org">cveawg-dev.mitre.org</option>
                   <option value="cveawg-int.mitre.org">cveawg-int.mitre.org</option>
                </select>
            </div>

      	    <div class="column is-full">
                   <h1 class="title is-3 is-4 mb-2">{{this.$store.state.cveId}} not found.</h1>
	    </div>
      	    <div class="column is-full">	    
                <span class="icon-text">
                  <a href="https://cve.mitre.org/cve/search_cve_list.html" target="_blank">
                  Find CVE Record by Keyword
                  <span class="icon cve-icon-xxs" style="margin-left: 0px;">
                    <p id="CVERecordsKeywordSearch" class="is-hidden">external site</p>
                    <font-awesome-icon icon="external-link-alt" aria-labelledby="CVERecordsKeywordSearch">
                    </font-awesome-icon>
                  </span>
                  </a>
                </span>
            </div>
		
          </div>
        </div>

		
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import PublishedRecord from './PublishedRecord.vue';
import RejectedRecordOrId from './RejectedRecordOrId.vue';
import ReservedId from './ReservedId.vue';

export default {
  name: 'CVERecord',
  components: {
    PublishedRecord,
    RejectedRecordOrId,
    ReservedId,
  },


  computed: {
    apiInstance() {
      return this.$store.getters.getApiInstance;
    }
  },
  
  methods: {
    updateApi(event) {
      this.$store.dispatch('updateApiInstance', event.target.value);
    }
  },

  data() {
    return {
      NVDBaseUrl: 'https://nvd.nist.gov/view/vuln/detail?vulnId=',
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/style/globals.scss';
</style>
