import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import SiteSearch from '@/views/SiteSearch.vue';
import StaticFile from '@/views/StaticFile.vue';
import StaticFile1 from '@/views/StaticFile1.vue';
import StaticFile2 from '@/views/StaticFile2.vue';
import StaticFile3 from '@/views/StaticFile3.vue';
import StaticFile4 from '@/views/StaticFile4.vue';
import StaticFile5 from '@/views/StaticFile5.vue';
import StaticFile6 from '@/views/StaticFile6.vue';
import StaticFile7 from '@/views/StaticFile7.vue';
import StaticFile8 from '@/views/StaticFile8.vue';
import StaticFile9 from '@/views/StaticFile9.vue';
import StaticFile10 from '@/views/StaticFile10.vue';
import StaticFile11 from '@/views/StaticFile11.vue';
import StaticFile12 from '@/views/StaticFile12.vue';
import StaticFile13 from '@/views/StaticFile13.vue';
import StaticFile14 from '@/views/StaticFile14.vue';
import StaticFile15 from '@/views/StaticFile15.vue';
import StaticFile16 from '@/views/StaticFile16.vue';
import StaticFile17 from '@/views/StaticFile17.vue';
import StaticFile18 from '@/views/StaticFile18.vue';
import CVERecord from '@/views/CVERecord/CVERecord.vue';
import Downloads from '@/views/Downloads.vue';
import Overview from '@/views/About/Overview.vue';
import History from '@/views/About/History.vue';
import Process from '@/views/About/Process.vue';
import RelatedEfforts from '@/views/About/RelatedEfforts.vue';
import Metrics from '@/views/About/Metrics.vue';
import Partner from '@/views/PartnerInformation/Partner.vue';
import ListofPartners from '@/views/PartnerInformation/ListofPartners.vue';
import PartnerDetails from '@/views/PartnerInformation/PartnerDetails.vue';
import Structure from '@/views/ProgramOrganization/Structure.vue';
import ProgramRelationshipwithPartners from '@/views/ProgramOrganization/ProgramRelationshipwithPartners.vue';
import Board from '@/views/ProgramOrganization/Board.vue';
import WorkingGroups from '@/views/ProgramOrganization/WorkingGroups.vue';
import CNAs from '@/views/ProgramOrganization/CNAs.vue';
import ReportRequestForNonCNAs from '@/views/ReportRequest/ReportRequestForNonCNAs.vue';
import ReserveIDsPublishRecordsForCNAs from '@/views/ReportRequest/ReserveIDsPublishRecordsForCNAs.vue';
import Glossary from '@/views/ResourcesSupport/Glossary.vue';
import FAQs from '@/views/ResourcesSupport/FAQs.vue';
import Resources from '@/views/ResourcesSupport/Resources.vue';
import CNARules from '@/views/ResourcesSupport/AllResources/CNARules.vue';
import CveServices from '@/views/ResourcesSupport/AllResources/CveServices.vue';
import ProfessionalCodeOfConduct from '@/views/ResourcesSupport/AllResources/ProfessionalCodeOfConduct.vue';
import PrivacyPolicy from '@/views/Legal/PrivacyPolicy.vue';
import TermsOfUse from '@/views/Legal/TermsOfUse.vue';
import CookieNotice from '@/views/Legal/CookieNotice.vue';
import AllNews from '@/views/Media/News/AllNews.vue';
import News from '@/views/Media/News/News.vue';
import Blogs from '@/views/Media/News/Blogs.vue';
import Podcasts from '@/views/Media/News/Podcasts.vue';
import PressReleases from '@/views/Media/News/PressReleases.vue';
import PressReleaseArchives from '@/views/Media/News/PressReleaseArchives.vue';
import NewsItem from '@/views/Media/News/NewsItem.vue';
import NewsArchives from '@/views/Media/News/NewsArchives.vue';
import BlogArchives from '@/views/Media/News/BlogArchives.vue';
import Events from '@/views/Media/Events.vue';
import NewsletterSignup from '@/views/Media/News/NewsletterSignup.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home | CVE',
      metaTags: [
        {
          name: 'google-site-verification',
          content: 'CCHeQyhFnkXljGOxNEvI32d4sPgTNFvvEoXa4TAOoqs',
        },
      ],
    },
  },

{
  path: '/cve/:filePath*',  // for anything like /archive/cve/oldinitiative/oldpage.html
  name: 'cveArchiveRedirect',
  component: () => import('@/views/cveArchiveRedirect.vue'),
},
{
  path: '/data/:filePath*', 
  name: 'dataArchiveRedirect',
  component: () => import('@/views/dataArchiveRedirect.vue'),
},
{
  path: '/compatible/:filePath*', 
  name: 'compatibleArchiveRedirect',
  component: () => import('@/views/compatibleArchiveRedirect.vue'),
},
{
  path: '/docs/:filePath*', 
  name: 'docsArchiveRedirect',
  component: () => import('@/views/docsArchiveRedirect.vue'),
},
    
  {
    path: '/CVERecord',
    name: 'CVERecord',
    component: CVERecord,
    meta: {
      title: 'CVE Record | CVE',
    },
  },
  {
    path: '/SiteSearch',
    name: 'SiteSearch',
    component: SiteSearch,
    meta: {
      title: 'Site Search | CVE',
    },
  },
  {
    path: '/vulnogram/vulnogram.html',
    name: 'StaticFile',
    component: StaticFile,
  },
  {
    path: '/vulnogram/static/CVE.svg',
    component: StaticFile1,
    name: 'StaticFile1',
  },
  {
    path: '/vulnogram/static/cve5sw.js',
    component: StaticFile2,
    name: 'StaticFile2',
  },
  {
    path: '/vulnogram/js/editor.js',
    component: StaticFile3,
    name: 'StaticFile3',
  },
  {
    path: '/vulnogram/js/cvss.json',
    component: StaticFile4,
    name: 'StaticFile4',
  },
  {
    path: '/vulnogram/js/util.js',
    component: StaticFile5,
    name: 'StaticFile5',
  },
  {
    path: '/vulnogram/js/wy/wysihtml-toolbar.min.js',
    component: StaticFile6,
    name: 'StaticFile6',
  },
  {
    path: '/vulnogram/js/wy/simple.js',
    component: StaticFile7,
    name: 'StaticFile7',
  },
  {
    path: '/vulnogram/js/wy/wysihtml.min.js',
    component: StaticFile8,
    name: 'StaticFile8',
  },
  {
    path: '/vulnogram/js/mode-json.js',
    component: StaticFile9,
    name: 'StaticFile9',
  },
  {
    path: '/vulnogram/js/cwe-frequent.json',
    component: StaticFile10,
    name: 'StaticFile10',
  },
  {
    path: '/vulnogram/js/tablesort.min.js',
    component: StaticFile11,
    name: 'StaticFile11',
  },
  {
    path: '/vulnogram/js/capec.json',
    component: StaticFile12,
    name: 'StaticFile12',
  },
  {
    path: '/vulnogram/js/tagify.min.js',
    component: StaticFile13,
    name: 'StaticFile13',
  },
  {
    path: '/vulnogram/css/min.css',
    component: StaticFile14,
    name: 'StaticFile14',
  },
  {
    path: '/vulnogram/css/logo.png',
    component: StaticFile15,
    name: 'StaticFile15',
  },
  {
    path: '/vulnogram/css/tagify.css',
    component: StaticFile16,
    name: 'StaticFile16',
  },
  {
    path: '/vulnogram/css/logo.gif',
    component: StaticFile17,
    name: 'StaticFile17',
  },
  {
    path: '/vulnogram/css/vg-icons.css',
    component: StaticFile18,
    name: 'StaticFile18',
  },
  {
    path: '/Downloads',
    name: 'Downloads',
    component: Downloads,
    meta: {
      title: 'Downloads | CVE',
    },
  },
  {
    path: '/About/Overview',
    name: 'Overview',
    component: Overview,
    meta: {
      title: 'Overview | CVE',
    },
  },
  {
    path: '/About/History',
    name: 'History',
    component: History,
    meta: {
      title: 'History | CVE',
    },
  },
  {
    path: '/About/Process',
    name: 'Process',
    component: Process,
    meta: {
      title: 'Process | CVE',
    },
  },
  {
    path: '/About/RelatedEfforts',
    name: 'RelatedEfforts',
    component: RelatedEfforts,
    meta: {
      title: 'Related Efforts | CVE',
    },
  },
  {
    path: '/About/Metrics',
    name: 'Metrics',
    component: Metrics,
    meta: {
      title: 'Metrics | CVE',
    },
  },
  {
    path: '/PartnerInformation/Partner',
    name: 'Partner',
    component: Partner,
    meta: {
      title: 'Partner | CVE',
    },
  },
  {
    path: '/PartnerInformation/ListofPartners',
    name: 'ListofPartners',
    component: ListofPartners,
    meta: {
      title: 'List Of Partners | CVE',
    },
  },
  {
    path: '/PartnerInformation/ListofPartners/partner/:shortName',
    name: 'PartnerDetails',
    component: PartnerDetails,
    meta: {
      title: 'Partner Details | CVE',
    },
  },
  {
    path: '/ProgramOrganization/Structure',
    name: 'Structure',
    component: Structure,
    meta: {
      title: 'Structure | CVE',
    },
  },
  {
    path: '/ProgramOrganization/ProgramRelationshipwithPartners',
    name: 'ProgramRelationshipwithPartners',
    component: ProgramRelationshipwithPartners,
    meta: {
      title: 'Program Relationship With Partners | CVE',
    },
  },
  {
    path: '/ProgramOrganization/Board',
    name: 'Board',
    component: Board,
    meta: {
      title: 'Board | CVE',
    },
  },
  {
    path: '/ProgramOrganization/WorkingGroups',
    name: 'WorkingGroups',
    component: WorkingGroups,
    meta: {
      title: 'Working Groups | CVE',
    },
  },
  {
    path: '/ProgramOrganization/CNAs',
    name: 'CNAs',
    component: CNAs,
    meta: {
      title: 'CNAs | CVE',
    },
  },
  {
    path: '/ResourcesSupport/Resources',
    name: 'Resources',
    component: Resources,
    meta: {
      title: 'Resources | CVE',
    },
  },
  {
    path: '/ReportRequest/ReportRequestForNonCNAs',
    name: 'ReportRequestForNonCNAs',
    component: ReportRequestForNonCNAs,
    meta: {
      title: 'Report/Request For CNAs | CVE',
    },
  },
  {
    path: '/ReportRequest/ReserveIDsPublishRecordsForCNAs',
    name: 'ReserveIDsPublishRecordsForCNAs',
    component: ReserveIDsPublishRecordsForCNAs,
    meta: {
      title: 'Reserve IDs & Publish Records | CVE',
    },
  },
  {
    path: '/ResourcesSupport/Glossary',
    name: 'Glossary',
    component: Glossary,
    meta: {
      title: 'Glossary | CVE',
    },
  },
  {
    path: '/ResourcesSupport/FAQs',
    name: 'FAQs',
    component: FAQs,
    meta: {
      title: 'FAQs | CVE',
    },
  },
  {
    path: '/ResourcesSupport/AllResources/CNARules',
    name: 'CNARules',
    component: CNARules,
    meta: {
      title: 'CNA Rules | CVE',
    },
  },
  {
    path: '/ResourcesSupport/AllResources/ProfessionalCodeOfConduct',
    name: 'ProfessionalCodeOfConduct',
    component: ProfessionalCodeOfConduct,
    meta: {
      title: 'Professional Code of Conduct | CVE',
    },
  },
  {
    path: '/AllResources/CveServices',
    name: 'CveServices',
    component: CveServices,
    meta: {
      title: 'CVE Services | CVE',
    },
  },
  {
    path: '/Legal/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy | CVE',
    },
  },
  {
    path: '/Legal/TermsOfUse',
    name: 'TermsOfUse',
    component: TermsOfUse,
    meta: {
      title: 'Terms Of Use | CVE',
    },
  },
  {
    path: '/Legal/CookieNotice',
    name: 'CookieNotice',
    component: CookieNotice,
    meta: {
      title: 'Cookie Notice | CVE',
    },
  },
  {
    path: '/Media/News/AllNews',
    name: 'AllNews',
    component: AllNews,
    meta: {
      title: 'All News | CVE',
    },
    alias: '/Media/News/AllNews',
  },
  {
    path: '/Media/News/News',
    name: 'News',
    component: News,
    meta: {
      title: 'News | CVE',
    },
    alias: '/Media/News/News',
  },
  {
    path: '/Media/News/Blogs',
    name: 'Blogs',
    component: Blogs,
    meta: {
      title: 'Blogs | CVE',
    },
    alias: '/Media/News/Blogs',
  },
  {
    path: '/Media/News/Podcasts',
    name: 'Podcasts',
    component: Podcasts,
    meta: {
      title: 'Podcasts | CVE',
    },
  },
  {
    path: '/Media/News/PressReleases',
    name: 'Press Releases',
    component: PressReleases,
    meta: {
      title: 'Press Releases | CVE',
    },
  },
  {
    path: '/Media/News/item/:newsType/:year/:month/:day/:shortTitle',
    name: 'NewsItem',
    component: NewsItem,
    meta: {
      title: 'NewsItem | CVE',
    },
  },
  {
    path: '/Media/News/NewsArchives',
    name: 'NewsArchives',
    component: NewsArchives,
    meta: {
      title: 'News Archives | CVE',
    },
  },
  {
    path: '/Media/News/BlogArchives',
    name: 'BlogArchives',
    component: BlogArchives,
    meta: {
      title: 'Blog Archives | CVE',
    },
  },
  {
    path: '/Media/News/PressReleaseArchives',
    name: 'PressReleaseArchives',
    component: PressReleaseArchives,
    meta: {
      title: 'Press Release Archives | CVE',
    },
  },
  {
    path: '/Media/Events',
    name: 'Events',
    component: Events,
    meta: {
      title: 'Events | CVE',
    },
  },
  {
    path: '/Media/News/NewsletterSignup',
    name: 'Newsletter Signup',
    component: NewsletterSignup,
    meta: {
      title: 'Newsletter Signup | CVE',
    },
  },
  {
    path: '*',
    name: 'NotFound',
    meta: {
      title: 'Not Found | CVE',
    },
  },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 55 },
      };
    }
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]'), (el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  return next();
});

export default router;
